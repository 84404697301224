import PropTypes from 'prop-types';
import { RouteModel } from '../model/RouteModel';
import NavigationRouteContent from './NavigationRouteContent';

const NavigationRouteRender = ({ match, routeItem }) => {
	return <NavigationRouteContent routeItem={routeItem} matchParams={match.params} />;
};

NavigationRouteRender.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			spiritCode: PropTypes.string,
		}),
		path: PropTypes.string,
	}).isRequired,
	routeItem: PropTypes.instanceOf(RouteModel).isRequired,
};

export default NavigationRouteRender;
