/* eslint-disable no-tabs */
import { editMenuSetSelectedToEdit } from 'actions/actionCreators';
import classNames from 'classnames';
import { EDIT_MENU_TYPES } from 'components/begEditMenu/constants/begEditMenuConstants';
import { useEditMenuData } from 'hooks/useEditMenuData';
import useBrandDetails from 'hooks/useBrandDetails';
import PropTypes from 'prop-types';
import React, { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { InputEditNavigation } from './inputEditNavigation';
import { MenuEditNavigation } from './menuEditNavigation';
import './EditNavigation.scss';

const EditNavigation = ({ children }) => {
	const { selectedToEdit } = useEditMenuData();
	const dispatch = useDispatch();
	const { onUndoChapters } = useBrandDetails();

	const isEditNavigation = selectedToEdit === EDIT_MENU_TYPES.EDIT_NAVIGATION;

	const overlayClassNames = classNames('EditNavigationOverlay', {
		'EditNavigationOverlay--open': isEditNavigation,
	});

	const onClose = e => {
		e.preventDefault();
		onUndoChapters();
		dispatch(editMenuSetSelectedToEdit({ selectedToEdit: '' }));
	};

	useLayoutEffect(() => {
		const originalScrollTo = window.scrollTo;

		if (isEditNavigation) {
			window.scrollTo = () => {};
		}

		return () => {
			window.scrollTo = originalScrollTo;
		};
	}, [isEditNavigation]);

	return (
		<div>
			<div className={overlayClassNames} aria-hidden="true" onClick={onClose} />
			<div>{children}</div>
		</div>
	);
};

EditNavigation.propTypes = {
	children: PropTypes.node,
};

EditNavigation.defaultProps = {
	children: null,
};

EditNavigation.Menu = MenuEditNavigation;
EditNavigation.Input = InputEditNavigation;

export { EditNavigation };
