import { statusCodes } from 'API/constants/httpConstants';
import { redirectToLogin } from 'utils/redirectUtil';

export const addTokenValidationInterceptor = instance => {
	instance.interceptors.response.use(
		response => response,
		error => {
			if (error.response?.status === statusCodes.UNAUTHORIZED && !error.response.config.url.includes('/hyatt-services')) {
				redirectToLogin();
			}
			return Promise.reject(error);
		}
	);

	return instance;
};
