import styled from 'styled-components/macro';

export const Blockquote = styled.div(() => ({
	display: 'flex',
	flexDirection: 'column',
	margin: '20px 0px',
	padding: '10px 20px',
	position: 'relative',
	width: '100%',
	background: '#f7f7f7',
	borderLeft: 'solid 5px #636466',
	borderRadius: '5px',
}));
