import styled from 'styled-components/macro';

export const BoxDesktop = styled.div(({ theme: { breakpoint } }) => ({
	display: 'none',
	padding: '11px 20px',

	[breakpoint.lg()]: {
		display: 'block',
	},
}));

export const BoxMobile = styled.div(({ theme: { breakpoint } }) => ({
	display: 'block',

	[breakpoint.lg()]: {
		display: 'none',
	},
}));
