import BackgroundHeader from 'assets/img/bg-header-ziva.jpg';
import complementaryTheme from 'themes/complementary/complementary';

const { neutral } = complementaryTheme;

const colors = {
	background: {
		banner: {
			background: `url(${BackgroundHeader}) no-repeat`, // header
		},
		secondary: '#ededed', // sidebar and navbar (mobile)
		tertiary: '#F7F7F7', // footer
	},
	interactive: {
		// terms and conditions primary button modal. remove image primary button modal
		primary: {
			default: {
				background: '#ef4930',
				label: '#fff',
				border: '#ef4930',
			},
			hover: {
				background: '#fff',
				label: '#ef4930',
				border: '#ef4930',
			},
		},
		// search button
		primary02: {
			default: {
				background: '#3fb0b0',
				label: '#fff',
				border: '#3fb0b0',
				opacity: '0.65',
			},
		},
		// file button
		primary05: {
			default: {
				background: '#ef4930',
				label: '#fff',
				border: '#ef4930',
			},
			hover: {
				background: '#fff',
				label: '#ef4930',
				border: '#ef4930',
			},
		},
		// terms & conditions secondary button modal
		secondary: {
			default: {
				background: '#ef4930',
				label: '#fff',
				border: '#ef4930',
			},
			hover: {
				background: '#fff',
				label: '#ef4930',
				border: '#ef4930',
			},
		},
		// sign out button
		secondary02: {
			default: {
				background: '#ededed',
				label: '#58585a',
			},
			hover: {
				background: '#ededed',
				label: '#3fb0b0',
			},
		},
		// button approve modal
		secondary03: {
			default: {
				background: '#333333',
				label: '#fff',
				border: '#333333',
			},
			hover: {
				background: '#fff',
				label: '#333333',
				border: '#333333',
			},
		},
		// help button
		tertiary: {
			default: {
				background: '#fff',
				label: '#ED237C',
			},
		},
		printModalButtons: {
			default: {
				background: '#ef4930',
				label: '#fff',
				border: '#ef4930',
			},
			hover: {
				background: '#fff',
				label: '#ef4930',
				border: '#ef4930',
			},
		},
		// properties and edit image modals buttons
		tertiary03: {
			default: {
				background: '#fff',
				label: '#181818',
				border: '#ccc',
			},
			hover: {
				background: '#fff',
				label: '#181818',
				border: '#181818',
			},
		},
		// mobile
		outlined: {
			default: {
				label: '#3fb0b0', // mobile search and help buttons
				icon: '#b9b5aa', // mobile menu button
				border: '#b9b5aa', // mobile buttons
			},
		},
		// header buttons
		inverse: {
			default: {
				label: '#fff',
			},
		},
		discardChangesButtons: {
			default: {
				background: '#ef4930',
				label: '#fff',
				border: '#ef4930',
			},
			hover: {
				background: '#fff',
				label: '#ef4930',
				border: '#ef4930',
			},
		},
		printPageButtons: {
			default: {
				background: '#ef4930',
				label: '#fff',
				border: '#ef4930',
			},
			hover: {
				background: '#fff',
				label: '#ef4930',
				border: '#ef4930',
			},
		},
		dropDownButtons: {
			default: {
				label: '#ffffff',
			},
			active: {
				color: '#00000020',
				label: '#262626',
			},
			focusWithin: {
				background: 'transparent',
				label: '#ffffff',
				border: 'none',
			},
			hover: {
				background: 'transparent',
				label: '#ffffff',
				border: 'none',
			},
		},

		lightest: 'rgba(255, 255, 255, 0.1)',
	},
	field: {
		// search input
		primary: {
			text: '#433a38',
			placeholder: '#898b8e',
		},
		// inputs edit image modal
		secondary: {
			text: '#676767',
			placeholder: '#676767',
		},
		// input properties modal
		tertiary: {
			text: '#676767',
			placeholder: '#676767',
		},
	},
	text: {
		primary: '#636466', // footer
		primary02: '#3fb0b0', // title chapter and section page

		titleModule: '#58585a',
		headline01: '#3fb0b0',
		headline02: '#58585a',
		headline03: '#3fb0b0',
		headline04: '#58585a',
		headline05: '#58585a',
		headline06: '#333333',

		paragraph: '#373739', // paragraph content page
		specificationTitle: '#58585a', // specification title

		approveModalTitle: '#666666',
		approveModalDescription: '#999999',

		addFilesSubtitle: '#999999',

		secondary: '#58585a', // terms and conditions title modal
		secondary02: '#58585a', // title discard changes
		secondary03: '#181818', // title edit image modal

		tertiary: '#373739', // terms and conditions description modal
		tertiary03: '#181818', // title properties modal

		quaternary: '#333', // title print modal
		quaternary02: '#333', // labels subtitle edit image modal
		quaternary03: '#000', // labels justification edit image modal

		quinary: '#333', // subtitles items print modal
		quinary02: '#333', // subtitles properties modal
		quinary03: '#333', // title remove image modal
		senary: '#58585a', // title article page

		bullet: '#3fb0b0', // list item bullet

		imageCaption: '#58595b', // image caption
	},
	link: {
		// sidebar item chapter
		primary: {
			default: {
				label: '#3fb0b0 ',
			},
			hover: {
				label: '#3fb0b0',
			},
		},
		// sidebar item section
		secondary: {
			default: {
				label: '#58585a',

				mobile: {
					label: '#3fb0b0',
				},
			},
			hover: {
				background: 'rgba(255, 255, 255, 0.1)',
				label: '#3fb0b0',
			},
			active: {
				label: '#58585a',
			},
		},
		// sidebar item article
		tertiary: {
			default: {
				label: '#58585a',

				mobile: {
					label: '#3fb0b0',
				},
			},
			hover: {
				background: 'rgba(255, 255, 255, 0.1)',
				label: '#414142',

				mobile: {
					label: '#414142',
				},
			},
			active: {
				label: '#58585a',

				mobile: {
					label: '#3fb0b0',
				},
			},
		},
		// breadcrumb
		quaternary: {
			default: {
				label: '#333',
			},
			hover: {
				label: '#333',
			},
			active: {
				label: '#3fb0b0',
			},
		},
	},
	icon: {
		primary: '#fff', // header dropdown arrow icon
		primary02: '#3fb0b0', // print icon
		chapterArrow: '#3fb0b0', // chapter arrow icon
		sectionArrow: '#3fb0b0', // section arrow icon
		breadcrumbArrow: '#555555', // breadcrumb arrow icon
	},
	border: {
		primary: '#3fb0b0', // search input
		secondary: '#B9b5aa',
		tertiary: '#ef4930', // modal top border
		quaternary: '#3fb0b0', // module divider
	},
	shadow: {
		primary: 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6)', // search input
		secondary: 'inset 0 1px 0 rgba(255, 255, 255, 0.1)', // menu
	},
	neutral,
	table: {
		row: {
			background: {
				primary: '#ededed',
				secondary: 'transparent',
			},
			cell: {
				label: '#333333',
				border: '2px solid #3fb0b0',
			},
		},
	},
};

export default colors;
