import { useEffect, useMemo } from 'react';
import {
	getRowsByQuickSheet,
	fromChapterByQuickSheet,
	fromSectionByQuickSheet,
	fromArticleByQuickSheet,
} from 'services/rowsByQuickSheetService';
import { translations } from 'translation/en';
import { showToast } from 'utils/toastUtil';
import { useRequest } from './useRequest';
import useBrandData from './useBrandData';
import { useAuthData } from './useAuthData';

export const useRowsPrintByQuickSheet = () => {
	const {
		loading: loadingRows,
		data: response,
		error,
		run: fetchRows,
		clear: clearRows,
	} = useRequest(params => getRowsByQuickSheet(params));
	const { brandId } = useBrandData();
	const { user } = useAuthData();

	useEffect(() => {
		if (error) {
			clearRows();
			showToast({ type: 'error', message: translations.FAILED_FETCH_ARTICLE });
		}
	}, [error, clearRows]);

	const rowsChapter = useMemo(() => {
		return response?.data && fromChapterByQuickSheet(response?.data, { brandId }, user);
	}, [brandId, response?.data, user]);

	const rowsSection = useMemo(() => {
		return response?.data && fromSectionByQuickSheet(response?.data, { brandId }, user);
	}, [brandId, response?.data, user]);

	const rowsArticle = useMemo(() => {
		return response?.data && fromArticleByQuickSheet(response?.data, { brandId }, user);
	}, [brandId, response?.data, user]);

	return { loadingRows, rowsChapter, rowsSection, rowsArticle, fetchRows, clearRows };
};
