import styled from 'styled-components/macro';

export const SearchPageItemWrapper = styled.div(() => ({
	maxWidth: '900px',
	minHeight: '0',
	margin: '0',
}));

export const SearchPageItemDivider = styled.div(() => ({
	margin: '5px 0',
	border: '0',
	borderTop: '1px solid #eee',
}));

export const SearchPageItemInnerHtml = styled.div(({ theme: { color, typo } }) => ({
	'h1, h2, h3, h4, h5, h6, span': {
		all: 'unset',
		color: 'black',
		textDecorationColor: '#373a3c',
		...typo.body['02'],
		fontSize: '14px',
	},
	p: {
		color: color.text.paragraph,
		...typo.body['02'],
	},
}));
