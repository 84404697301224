import PropTypes from 'prop-types';
import React from 'react';
import * as S from './Icon.style';

const Icon = ({ icon }) => {
	return (
		<S.Box>
			<S.Icon className={`icon ${icon}`} />
		</S.Box>
	);
};

Icon.propTypes = {
	icon: PropTypes.string,
};

Icon.defaultProps = {
	icon: '',
};

export { Icon };
