import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { localStorageKeys } from 'constants/localStorageConstants';

const useUrlStorage = () => {
	const { pathname } = useLocation();

	const getUrlFromStorage = useCallback(() => {
		const storedUrl = sessionStorage.getItem(localStorageKeys.STORED_URL);
		return storedUrl?.includes(pathname) ? storedUrl : null;
	}, [pathname]);

	const storedUrl = getUrlFromStorage();

	return { storedUrl };
};

export { useUrlStorage };
