import React, { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { DAY, listTypes, LOCATION_REGION, REGION_VALUE } from '../../constants/modalAnalyticsConstants';
import { last30Days } from '../../constants/periodAnalyticsConstants';
import { translation } from '../../constants/translation';
import { useAnalyticsContext } from '../../hooks/useAnalyticsContext';
import { endDateFormat, startDateFormat } from '../../utils/dateUtils';
import { ButtonAnalytics } from '../buttonAnalytics/ButtonAnalytics';
import { FormFieldDateAnalytics } from '../formFieldDateAnalytics/FormFieldDateAnalytics';
import { FormFieldSelectAnalytics } from '../formFieldSelectAnalytics/FormFieldSelectAnalytics';
import './BarChartFormWrapper.scss';

export const BarChartFormWrapper = () => {
	const { getMetricsForChart, locationType, setLocationType } = useAnalyticsContext();
	const context = useFormContext();

	const { setValue, watch, control, handleSubmit } = context;

	const startDateWatch = watch('startDate');
	const endDateWatch = watch('endDate');
	const typeWatch = watch('type');

	const submit = useCallback(
		data => {
			const params = {
				startDate: startDateFormat(startDateWatch),
				endDate: endDateFormat(endDateWatch),
				type: data.type.value,
			};

			getMetricsForChart(params);
			setLocationType({ ...data.type, pageDay: true });
		},
		[endDateWatch, getMetricsForChart, setLocationType, startDateWatch]
	);

	useEffect(() => {
		if (locationType === DAY) {
			setValue('startDate', last30Days.startDate);
			setValue('endDate', last30Days.endDate);
			setValue('type', LOCATION_REGION);

			const params = {
				startDate: startDateFormat(last30Days.startDate),
				endDate: endDateFormat(last30Days.endDate),
				type: REGION_VALUE,
			};

			getMetricsForChart(params);
			setLocationType(prevLocation => ({
				...prevLocation,
				nameKey: LOCATION_REGION.nameKey,
			}));
		}
	}, [endDateWatch, getMetricsForChart, locationType, setLocationType, setValue, startDateWatch]);

	return (
		<div className="BarChartFormWrapper">
			<FormFieldDateAnalytics
				label={translation.START_DATE}
				control={control}
				name="startDate"
				value={startDateWatch}
				setValue={setValue}
				hasError={false}
			/>

			<FormFieldDateAnalytics
				label={translation.END_DATE}
				control={control}
				name="endDate"
				value={endDateWatch}
				setValue={setValue}
				hasError={false}
			/>

			<FormFieldSelectAnalytics
				label={translation.TYPE}
				options={listTypes}
				getOptionValue={current => current.value}
				getOptionLabel={current => current.text}
				onChange={location => setValue('type', location)}
				value={listTypes.find(option => option.value === typeWatch.value)}
			/>

			<ButtonAnalytics primary onClick={handleSubmit(submit)}>
				{translation.FILTER}
			</ButtonAnalytics>
		</div>
	);
};
