import { NavLink as RouterLink } from 'react-router-dom';
import styled from 'styled-components/macro';

const getMD = () => '@media(max-width: 880px)';

export const Label = styled.span(({ theme: { color, typo } }) => ({
	...typo.link['04'],
	color: `${color.link.secondary.default.label} !important`,
}));

export const Link = styled(RouterLink)(({ theme: { color }, $hasArticles, disabled }) => ({
	display: 'inline-flex',
	alignItems: 'center',
	width: '100%',
	minHeight: '40px',
	textDecoration: 'none !important',
	pointerEvents: disabled ? 'none' : 'auto',
	opacity: disabled ? '0.5' : '1 !important',

	'*': {
		color: `${color.link.secondary.default.label} `,
	},

	'&:hover': {
		background: `${color.link.secondary.hover.background} !important`,

		span: {
			color: `${color.link.secondary.hover.label} !important`,
		},
	},

	'&:active, &.active': {
		background: `${color.interactive.lightest}`,

		'*': {
			color: `${color.link.secondary.active.label} !important`,

			[getMD()]: {
				'*': {
					color: `${color.link.secondary.active?.mobile?.label} !important`,
				},
			},
		},
	},

	[getMD()]: {
		'*': {
			color: $hasArticles
				? `${color.link.secondary.default.label} !important`
				: `${color.link.secondary.default?.mobile?.label} !important`,
		},
	},
}));

export const Wrapper = styled.div(() => ({
	display: 'inline-flex',
	alignItems: 'center',
	width: '100%',
	height: 'inherit',
	padding: '10px 20px',
	justifyContent: 'flex-start',

	'> :first-child': {
		marginRight: '4px',
	},
}));
