/* eslint-disable no-plusplus */
import { FILE_PROPS } from 'containers/brandDetails/components/brandMain/components/listFiles/constants/listFilesConstants';
import { SpecialCharactersRegex } from 'constants/regexConstants';
import { moveArrItemDown, moveArrItemUp } from './contentFragmentUtil';
import { addItemFromArr, deleteItemFromArr, updateItemFromArr } from './arrayUtil';

const ALLOWED_EXTENSIONS = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
const MAX_UPLOAD_SIZE = 2;
const MAX_UPLOAD_FILES = 5;

const megabytesToBytes = mb => mb * 1024 * 1024;

export const hasExtension = str => {
	return /\.[0-9a-z]+$/i.test(str);
};

export const checkExtension = (extension = '', allowedTypes = ALLOWED_EXTENSIONS) => {
	if (allowedTypes === '*') {
		return true;
	}

	return allowedTypes.includes(extension.toLowerCase());
};

export const checkSize = file => file && megabytesToBytes(MAX_UPLOAD_SIZE) < file.size;

export const checkIfIsBase64File = file => file && file.includes('data:image/');

export const getFileExtension = filename => filename.split('.').pop();

export const removeFileExtension = filename => filename.split('.').slice(0, -1).join('.');

export const normalizeFileName = str => {
	let filename = str;

	if (hasExtension(filename)) {
		filename = removeFileExtension(filename);
	}

	return filename.replace(/\u2013|\u2014/g, '-');
};

export const extractFileNameFromPath = path => path.replace(/(\S)*\//g, '');

export const checkMaxFiles = length => length >= MAX_UPLOAD_FILES;

export const formatFileName = filename => {
	const extension = getFileExtension(filename);
	const fileName = removeFileExtension(filename).replace(SpecialCharactersRegex, '-');
	return `${fileName}.${extension}`;
};

export const toBase64 = file =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	});

export const fromBase64 = (base64, contentType, fileName) => {
	const a = window.document.createElement('a');
	a.href = `data:${contentType};base64,${base64}`;
	a.download = fileName;
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
};

export const convertToBase64Encode = file => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onloadend = () => resolve(`imageBytes=${encodeURIComponent(reader.result)}`);
		reader.readAsDataURL(file);
		reader.onerror = error => reject(new Error(error));
	});
};

export const downloadFileFromData = (data, filename) => {
	const blob = new Blob([data]);
	const link = document.createElement('a');
	link.href = window.URL.createObjectURL(blob);
	link.download = filename;
	link.click();
};

export const fromBase64toFile = (dataUrl, filename) => {
	const arr = dataUrl.split(',');
	const mime = arr[0].match(/:(.*?);/)[1];
	const bstr = atob(arr[1]);
	let n = bstr.length;
	const u8arr = new Uint8Array(n);

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}

	return new File([u8arr], filename, { type: mime });
};

export const insertFile = (files, file) => {
	const newFiles = addItemFromArr({
		arr: files,
		payload: file,
	});
	return newFiles.map(item => item);
};

export const updateFile = (files, file) => {
	const updateFiles = updateItemFromArr({
		arr: files,
		payload: file,
		itemKey: FILE_PROPS.VCM_ID_KEY,
	});
	return updateFiles.map(item => item);
};

export const deleteFile = (files, file) => {
	const filteredFiles = deleteItemFromArr({
		arr: files,
		payload: file,
		itemKey: FILE_PROPS.VCM_ID_KEY,
	});
	return filteredFiles.map(item => item);
};

export const moveFileUp = (files, file) => {
	return moveArrItemUp({
		arr: files,
		itemToMoveUp: file,
		itemKey: FILE_PROPS.VCM_ID_KEY,
		sortKey: FILE_PROPS.DISPLAY_ORDER_KEY,
	});
};

export const moveFileDown = (files, file) => {
	return moveArrItemDown({
		arr: files,
		itemToMoveDown: file,
		itemKey: FILE_PROPS.VCM_ID_KEY,
		sortKey: FILE_PROPS.DISPLAY_ORDER_KEY,
	});
};
