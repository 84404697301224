import { useEffect, useMemo } from 'react';
import { fromRowsByQuickSheet, getRowsByQuickSheet } from 'services/rowsByQuickSheetService';
import { translations } from 'translation/en';
import { showToast } from 'utils/toastUtil';
import { SEARCH_QUERY_PARAM_EXACT_MATCH, SEARCH_QUERY_PARAM_NAME } from 'constants/searchConstants';
import { useRequest } from './useRequest';
import { useQueryParams } from './useQueryParams';
import useBrandData from './useBrandData';
import { useAuthData } from './useAuthData';

export const useRowsByQuickSheet = () => {
	const {
		loading: loadingRows,
		data: response,
		error,
		run: fetchRows,
		clear: clearRows,
	} = useRequest(params => getRowsByQuickSheet(params));

	const { brandId } = useBrandData();
	const { user } = useAuthData();
	const { getQueryParam } = useQueryParams();

	const searchTerm = getQueryParam(SEARCH_QUERY_PARAM_NAME);
	const isExactMatch = getQueryParam(SEARCH_QUERY_PARAM_EXACT_MATCH);

	useEffect(() => {
		if (error) {
			clearRows();
			showToast({ type: 'error', message: translations.FAILED_FETCH_ARTICLE });
		}
	}, [error, clearRows]);

	const rows = useMemo(() => {
		return response?.data && fromRowsByQuickSheet(response?.data, { searchTerm, isExactMatch }, { brandId }, user);
	}, [response?.data, user, brandId, isExactMatch, searchTerm]);

	return { loadingRows, rows, fetchRows, clearRows };
};
