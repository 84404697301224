import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { moduleTemplateImages } from 'components/addModule/constants/moduleTemplateConstants';
import { useArticleByPath } from 'hooks/useArticleByPath';
import useBrandDetails from 'hooks/useBrandDetails';
import { useChapterByPath } from 'hooks/useChapterByPath';
import useParagraphs from 'hooks/useParagraphs';
import { usePutContentFragment } from 'hooks/usePutContentFragment';
import { useSectionByPath } from 'hooks/useSectionByPath';
import { useCreateModule } from 'hooks/useCreateModule';
import { EDIT_MENU_TYPES } from 'components/begEditMenu/constants/begEditMenuConstants';
import { MODELS_PATHS } from 'constants/aemConstants';
import { PAGE_TYPES } from 'constants/pageConstants';
import { fromParagraphService } from 'services/paragraphsService';
import * as actionCreators from 'actions/actionCreators';
import { Container, Content, Item, TemplateItems } from './AddModulesDropDown.style';

const AddModulesDropDown = ({ open, position, onClose }) => {
	const { currentType } = useBrandDetails();
	const { setIsParagraphLoading, handleAddParagraph } = useParagraphs();

	const { fetchChapter } = useChapterByPath();
	const { fetchSection } = useSectionByPath();
	const { fetchArticle } = useArticleByPath();

	const { createModule } = useCreateModule();
	const { updateContentFragment } = usePutContentFragment({ shouldShowToast: false });
	const { editMenuSetSelectedToEdit } = actionCreators;

	const dispatch = useDispatch();

	const handleAddNewModule = async type => {
		// Create new relator
		setIsParagraphLoading(true);
		onClose();

		try {
			const postResponse = await createModule({
				type,
				displayOrder: position,
			});

			if (postResponse.data) {
				const newParagraph = fromParagraphService(postResponse.data);

				let updateResponse = null;
				let key = '';
				let oldPaths = [];

				// Get old modules paths for the selected page (chapter, section or article).
				if (currentType.type === PAGE_TYPES.CHAPTER_TYPE) {
					key = PAGE_TYPES.CHAPTER_CONTENT_FK_PROP;
					const { data: chapters } = await fetchChapter({ path: currentType.path });
					oldPaths = chapters?.data?.getChapterByPath.items[0]?.[key]?.map(item => item._path) || [];
				} else if (currentType.type === PAGE_TYPES.SECTION_TYPE) {
					key = PAGE_TYPES.SECTION_CONTENT_FK_PROP;
					const { data: sections } = await fetchSection({ path: currentType.path });
					oldPaths = sections?.data?.getSectionByPath.items[0]?.[key]?.map(item => item._path) || [];
				} else if (currentType.type === PAGE_TYPES.ARTICLE_TYPE) {
					key = PAGE_TYPES.ARTICLE_CONTENT_FK_PROP;
					const { data: articles } = await fetchArticle({ path: currentType.path });
					oldPaths = articles?.data?.getArticleByPath.items[0]?.[key]?.map(item => item._path) || [];
				}

				// Add the newly created relator_paragraph's path to chapter or section or article
				const newPaths = [...oldPaths];
				newPaths.splice(position, 0, newParagraph.path);

				[updateResponse] = await updateContentFragment({
					payload: { [key]: newPaths, approved: false },
					path: currentType?.path,
					model: MODELS_PATHS[currentType?.type?.toUpperCase()],
					shouldApprove: false,
				});

				if (postResponse?.status === 200 && updateResponse?.status === 200) {
					handleAddParagraph({
						position,
						...newParagraph,
					});
				}
			}
		} finally {
			setIsParagraphLoading(false);
			dispatch(editMenuSetSelectedToEdit({ selectedToEdit: EDIT_MENU_TYPES.EDIT_INLINE }));
		}
	};

	return (
		<Container isExpanded={open}>
			<Content>
				<TemplateItems>
					{moduleTemplateImages.map(item => (
						<Item key={item.id} role="button" onClick={() => handleAddNewModule(item.type)}>
							<img src={item.src} alt={item.templateName} />
							<p>{item.templateName}</p>
						</Item>
					))}
				</TemplateItems>
			</Content>
		</Container>
	);
};

AddModulesDropDown.propTypes = {
	open: PropTypes.bool,
	position: PropTypes.number,
	onClose: PropTypes.func,
};

AddModulesDropDown.defaultProps = {
	open: false,
	position: 0,
	onClose: () => {},
};

export { AddModulesDropDown };
