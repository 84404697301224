import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { LOCATION_REGION, REGION_VALUE } from '../../constants/modalAnalyticsConstants';
import { last30Days, listOptions } from '../../constants/periodAnalyticsConstants';
import { translation } from '../../constants/translation';
import { useAnalyticsContext } from '../../hooks/useAnalyticsContext';
import { endDateFormat, getFormattedDate, startDateFormat } from '../../utils/dateUtils';
import { ButtonAnalytics } from '../buttonAnalytics/ButtonAnalytics';
import { FormFieldDateAnalytics } from '../formFieldDateAnalytics/FormFieldDateAnalytics';
import { Overlay } from '../overlay/Overlay';
import './DropdownMenuLink.scss';

export const DropdownMenuLink = () => {
	const context = useFormContext();
	const format = date => getFormattedDate(date);

	const { getMetricsForChart, locationType, setLocationType } = useAnalyticsContext();

	const [isOpen, setOpen] = useState(false);
	const [statePeriod, setStatePeriod] = useState(last30Days);
	const { value: type } = locationType;

	const {
		setValue,
		watch,
		control,
		reset,
		handleSubmit,
		formState: { isDirty },
	} = context;

	const startDateWatch = watch('startDate');
	const endDateWatch = watch('endDate');

	const submit = useCallback(
		period => {
			const params = {
				startDate: startDateFormat(period.startDate),
				endDate: endDateFormat(period.endDate),
				type: type || REGION_VALUE,
			};

			getMetricsForChart(params);
		},
		[getMetricsForChart, type]
	);

	const handlePeriodSearch = useCallback(
		period => {
			setStatePeriod(period);
			setValue('startDate', period.startDate);
			setValue('endDate', period.endDate);

			submit(period);
			setOpen(false);
		},
		[setValue, submit]
	);

	const handleClick = useCallback(
		data => {
			setStatePeriod({
				text: `${format(startDateWatch)} | ${format(endDateWatch)}`,
			});

			const params = {
				startDate: startDateFormat(data.startDate),
				endDate: endDateFormat(data.endDate),
				type,
			};

			getMetricsForChart(params);
			reset({}, { keepValues: true });
			setOpen(false);
		},
		[endDateWatch, getMetricsForChart, reset, startDateWatch, type]
	);

	useEffect(() => {
		if (!locationType.value) {
			submit(last30Days);
			setLocationType(LOCATION_REGION);
		}
	}, [locationType, setLocationType, submit]);

	return (
		<>
			<Overlay
				style={{ zIndex: '5', background: 'transparent' }}
				open={isOpen}
				onClick={() => {
					setOpen(false);
					reset();
				}}
			/>

			<div className="DropdownMenuLink">
				<button onClick={() => setOpen(prevState => !prevState)} className="DropdownMenuLink__buttonSelect">
					{statePeriod.text}
				</button>

				<div
					className={classNames('DropdownMenuLink__menuList', {
						'DropdownMenuLink__menuList--open': isOpen,
					})}
				>
					<ul>
						{listOptions.map(period => (
							<li key={period.text}>
								<button onClick={() => handlePeriodSearch(period)}>{period.text}</button>
							</li>
						))}
					</ul>

					<div className="DropdownMenuLink__input-wrapper">
						<p>{translation.CUSTOM_RANGE}</p>

						<FormFieldDateAnalytics
							control={control}
							name="startDate"
							value={startDateWatch}
							setValue={setValue}
							hasError={false}
						/>

						<FormFieldDateAnalytics
							control={control}
							name="endDate"
							value={endDateWatch}
							setValue={setValue}
							hasError={false}
						/>

						{isDirty && (
							<div className="DropdownMenuLink__button-wrapper">
								<ButtonAnalytics primary onClick={handleSubmit(handleClick)}>
									{translation.FILTER}
								</ButtonAnalytics>

								<ButtonAnalytics onClick={() => setOpen(false)}>{translation.CLOSE}</ButtonAnalytics>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};
