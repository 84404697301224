import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import useBrandDetails from 'hooks/useBrandDetails';
import { translations } from 'translation/en';
import { getPublishStatus } from 'utils/publishUtil';
import { PAGE_TYPES } from 'constants/pageConstants';

export const ApprovePublishContext = createContext({});

const ApprovePublishProvider = ({ children }) => {
	const [isPageApproved, setIsPageApproved] = useState();
	const [isPagePublished, setIsPagePublished] = useState();
	const [isStale, setIsStale] = useState();
	const [selectedPageTree, setSelectedPageTree] = useState();

	const { currentType, chapter, section, article, chapters } = useBrandDetails();

	const publishStatus = useMemo(() => {
		if (isStale) {
			return translations.STALE;
		}

		if (isPagePublished) {
			return translations.PUBLISHED;
		}

		return translations.UNPUBLISHED;
	}, [isPagePublished, isStale]);

	useEffect(() => {
		if (currentType?.publishInfo?.lastReplicationAction !== undefined) {
			const isPublished = getPublishStatus(currentType?.publishInfo?.lastReplicationAction);
			setIsPagePublished(isPublished);
		}
	}, [currentType?.publishInfo?.lastReplicationAction]);

	useEffect(() => {
		setIsStale(currentType?.publishInfo?.ModifiedSinceLastPublication && isPagePublished);
	}, [currentType?.publishInfo?.ModifiedSinceLastPublication, isPagePublished]);

	useEffect(() => {
		if (currentType?.quickSheetPath && chapters) {
			const quickSheet = chapters?.filter(arrChapter => arrChapter?.quickSheetPath === currentType?.quickSheetPath)?.[0];
			setIsPageApproved(quickSheet?.approved);
		} else if (currentType?.articlePath === article?.path) {
			setIsPageApproved(article?.approved);
		} else if (currentType?.sectionPath === section?.path) {
			setIsPageApproved(section?.approved);
		} else if (currentType?.chapterPath === chapter?.path) {
			setIsPageApproved(chapter?.approved);
		}
	}, [
		section,
		chapter,
		article,
		currentType?.articlePath,
		currentType?.sectionPath,
		currentType?.chapterPath,
		chapters,
		currentType?.quickSheetPath,
	]);

	useEffect(() => {
		switch (currentType?.type) {
			case PAGE_TYPES.CHAPTER_TYPE: {
				const currentChapter = chapters?.find(page => page.path === currentType?.path);
				if (currentChapter) {
					setSelectedPageTree(currentChapter);
				}
				break;
			}
			case PAGE_TYPES.SECTION_TYPE: {
				const currentChapter = chapters?.find(page => page.path === currentType?.chapterPath);
				const currentSection = currentChapter?.sections?.find(page => page.path === currentType?.path);
				if (currentSection) {
					setSelectedPageTree(currentSection);
				}
				break;
			}
			case PAGE_TYPES.ARTICLE_TYPE: {
				const currentChapter = chapters?.find(page => page.path === currentType?.chapterPath);
				const currentSection = currentChapter?.sections?.find(page => page.path === currentType?.sectionPath);
				const currentArticle = currentSection?.articles?.find(page => page.path === currentType?.path);
				if (currentArticle) {
					setSelectedPageTree(currentArticle);
				}
				break;
			}
			default:
				break;
		}
	}, [chapters, currentType?.path, currentType?.type, currentType?.chapterPath, currentType?.sectionPath]);

	const handleStaleStatus = useCallback(
		status => {
			if (isPagePublished) {
				setIsStale(status);
			}
		},
		[isPagePublished]
	);

	const valueProvider = useMemo(
		() => ({
			isPageApproved,
			isPagePublished,
			selectedPageTree,
			isStale,
			publishStatus,

			setIsStale,
			setIsPageApproved,
			setIsPagePublished,
			handleStaleStatus,
		}),
		[
			isPageApproved,
			isPagePublished,
			selectedPageTree,
			isStale,
			publishStatus,

			setIsStale,
			setIsPageApproved,
			setIsPagePublished,
			handleStaleStatus,
		]
	);

	return <ApprovePublishContext.Provider value={valueProvider}>{children}</ApprovePublishContext.Provider>;
};

ApprovePublishProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export { ApprovePublishProvider };
