import { ReactNode, useMemo, useState } from 'react';
import { Modal, ModalProps } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import './ModalBox.scss';

type ModalBoxProps = {
	children: ReactNode;
	closeOnEsc?: boolean;
	open: boolean;
	onClose: () => void;
	className?: string;
	overlayClassName?: string;
	hideExpand?: boolean;
	closeIcon?: ReactNode;
};

export function ModalBox({
	children,
	open,
	onClose,
	className,
	overlayClassName,
	closeIcon,
	hideExpand,
	closeOnEsc,
	...rest
}: ModalBoxProps) {
	const [expanded, setExpanded] = useState(false);

	const modalClassNames = useMemo<ModalProps['classNames']>(
		() => ({
			modal: `ModalBox ${expanded ? 'ModalBox--expanded' : ''} ${className}`.trim(),
			overlay: `ModalBox__overlay ${overlayClassName}`.trim(),
		}),
		[className, overlayClassName, expanded]
	);

	return (
		<Modal
			open={open}
			onClose={onClose}
			closeIcon={closeIcon || <i className="ModalBox__icon icon-close-slim" />}
			center
			closeOnEsc={closeOnEsc}
			classNames={modalClassNames}
			{...rest}
		>
			{children}
			{!hideExpand && (
				<>
					<button type="button" className="ModalBox__full-size" onClick={() => setExpanded(prev => !prev)}>
						<i className={`ModalBox__icon icon-${expanded ? 'collapse' : 'expand'}-alt`} />
					</button>
					<span className="ModalBox__separator" />
				</>
			)}
		</Modal>
	);
}
