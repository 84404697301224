import { LOCATION_SET_DATA } from '../../actions/actionTypes';

const initialState = {};

const locationSetData = (state, action) => ({
	...state,
	...action.location,
});

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case LOCATION_SET_DATA:
			return locationSetData(state, action);
		default:
			break;
	}
	return state;
};

export default reducer;
