import PropTypes from 'prop-types';
import { Icon } from './icon';
import { Label } from './label';
import * as S from './Link.style';

const Link = ({ variant, children, icon, onClick, ...props }) => {
	const handleClick = e => {
		e.preventDefault();
		onClick(e);
	};

	return (
		<S.Link variant={variant} onClick={e => onClick && handleClick(e)} {...props}>
			<S.Wrapper variant={variant}>
				{icon.align === 'left' && <Icon variant={variant} icon={icon} />}
				<Label variant={variant}>{children}</Label>
				{icon.align === 'right' && <Icon variant={variant} icon={icon} />}
			</S.Wrapper>
		</S.Link>
	);
};

Link.propTypes = {
	variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quaternary']),
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	icon: PropTypes.shape({
		style: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		name: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
		align: PropTypes.oneOf(['left', 'right', 'none']),
	}),
	onClick: PropTypes.func,
};

Link.defaultProps = {
	variant: 'primary',
	children: '',
	icon: {
		style: '',
		name: '',
		align: 'none',
	},
	onClick: () => null,
};

export { Link };
