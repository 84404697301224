import { format } from 'date-fns';

export const getFormattedDate = date => {
	if (!date) return '';

	return format(new Date(date), 'yyyy-MM-dd 00:00:00').replace('00:00:00', '');
};

export const startDateFormat = date => format(new Date(date), 'yyyy-MM-dd 00:00:00');

export const endDateFormat = date => format(new Date(date), 'yyyy-MM-dd 23:59:59');
