import { ReactNode } from 'react';
import './Pill.scss';

type PillProps = {
	children?: ReactNode;
	text?: string | null;
	hasClose?: boolean;
	onClose?: () => void;
};

export function Pill({ children = null, text = null, hasClose = false, onClose = () => {} }: PillProps) {
	return (
		<div className="Pill">
			{text || children}
			{hasClose && (
				<button type="button" className="Pill__close" onClick={onClose}>
					<i className="icon icon-delete-circle" />
				</button>
			)}
		</div>
	);
}

export default Pill;
