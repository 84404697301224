import { useCallback } from 'react';
import routers from 'router/router';
import { PAGE_TYPES } from 'constants/pageConstants';
import useBrandData from './useBrandData';

const usePrintRoute = () => {
	const { brandName } = useBrandData();

	const chapterPrintRoute = `${brandName}${routers.chapterPrintROUTE.getRouteDefaultPath()}`;
	const sectionPrintRoute = `${brandName}${routers.sectionPrintROUTE.getRouteDefaultPath()}`;
	const articlePrintRoute = `${brandName}${routers.articlePrintROUTE.getRouteDefaultPath()}`;

	const getPrintRoute = useCallback(
		({ type, params }) => {
			const searchParams = new URLSearchParams();

			Object.entries(params).forEach(([key, value]) => {
				if (value) {
					searchParams.append(key, value);
				}
			});

			const queryString = searchParams.toString();

			switch (type) {
				case PAGE_TYPES.CHAPTER_TYPE:
					return `${chapterPrintRoute}/${type}?${queryString}`;
				case PAGE_TYPES.SECTION_TYPE:
					return `${sectionPrintRoute}/${type}?${queryString}`;
				case PAGE_TYPES.ARTICLE_TYPE:
					return `${articlePrintRoute}/${type}?${queryString}`;
				default:
					return null;
			}
		},
		[articlePrintRoute, chapterPrintRoute, sectionPrintRoute]
	);

	return { getPrintRoute };
};

export default usePrintRoute;
