import complementaryTheme from 'themes/complementary/complementary';
import colors from './standardColors';
import typographyTheme from './standardTypo';

const standard = {
	name: 'Standard Theme',
	color: colors,

	font: {
		family: {
			helveticaNeue: 'Helvetica Neue',
			poynterRegular: 'Poynter Regular',
		},
	},
};

const standardTheme = {
	...standard,
	...typographyTheme(standard),
	...complementaryTheme,
};

export default standardTheme;
