import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { InnerHtml } from '@hyatt/inner-html';
import { Wrapper } from 'containers/brandDetails/components/brandMain/components/template/ModuleTemplate.style';
import { ROLE_QUICKSHEET_BODY, TYPE_QUICKSHEET_MODULE_TEXT } from 'containers/brandDetails/constants/modulesTypesConstants';
import { useHighlight } from 'hooks/useHighlight';
import { useQueryParams } from 'hooks/useQueryParams';
import { translations } from 'translation/en';
import { ListFiles } from '../../listFiles';
import { QuickSheetSpecificationDropdown } from '../../quicksheetSpecificationDropDown';
import { SpecificationLabel } from '../../specificationDropDown/SpecificationDropDown.style';
import * as S from './QuickSheetTextModule.style';

const QuickSheetTextModule = ({ paragraphId, paragraph, isOnPrintPage, ...props }) => {
	const { targetHash } = useQueryParams();
	const { handleTextHighlight } = useHighlight();

	const highlightedTitle = useMemo(() => {
		return <InnerHtml body={handleTextHighlight(paragraph.title)} />;
	}, [paragraph.title, handleTextHighlight]);

	const highlightedBody = useMemo(() => {
		return <InnerHtml body={handleTextHighlight(paragraph.body)} />;
	}, [paragraph.body, handleTextHighlight]);

	useEffect(() => {
		if (targetHash === paragraphId) {
			setTimeout(() => {
				document
					.getElementById(paragraphId)
					?.scrollIntoView({ block: 'center', inline: 'nearest', behavior: 'smooth' }, 2500);
			}, 1000);
		}
	}, [paragraphId, targetHash]);

	return (
		<div id={paragraphId} key={paragraphId}>
			<S.Container empty={!paragraph.title}>
				<Wrapper role={TYPE_QUICKSHEET_MODULE_TEXT}>
					<S.TitleContainer>{highlightedTitle}</S.TitleContainer>
				</Wrapper>
			</S.Container>

			<div>
				<S.ContentContainer role={ROLE_QUICKSHEET_BODY}>{highlightedBody}</S.ContentContainer>
			</div>

			{!isOnPrintPage && paragraph?.linkArray?.length > 0 && (
				<S.ContentContainer>
					<QuickSheetSpecificationDropdown content={paragraph.linkArray} />
				</S.ContentContainer>
			)}

			{isOnPrintPage && paragraph?.links?.trim() && (
				<S.ContentContainer>
					<SpecificationLabel>{translations.RESOURCES_TEXT}</SpecificationLabel>
					<InnerHtml body={paragraph.links} />
				</S.ContentContainer>
			)}

			<ListFiles {...props} />
		</div>
	);
};

QuickSheetTextModule.propTypes = {
	paragraphId: PropTypes.string,
	paragraph: PropTypes.shape({
		vcmId: PropTypes.string,
		title: PropTypes.string,
		body: PropTypes.string,
		links: PropTypes.string,
		linkArray: PropTypes.arrayOf(PropTypes.shape({})),
	}),
	isOnPrintPage: PropTypes.bool,
};

QuickSheetTextModule.defaultProps = {
	paragraphId: '',
	paragraph: {
		vcmId: '',
		title: '',
		body: '',
		links: '',
		linkArray: [],
	},
	isOnPrintPage: false,
};

export { QuickSheetTextModule };
