import DestinationHorizontal from 'assets/img/logos/destination-horizontal.svg';
import DestinationLogo from 'assets/img/logos/destination-white.svg';
import DestinationHyattLogo from 'assets/img/logos/destination-black.svg';
import complementaryTheme from 'themes/complementary/complementary';
import colors from './destinationColors';
import typographyTheme from './destinationTypo';

const logo = {
	footer: DestinationHorizontal, // logo in the footer
	loading: DestinationHyattLogo, // logo in the loading
	sidebar: DestinationLogo, // logo in the sidebar
	mobile: DestinationLogo, // logo in the navbar
	size: {
		sidebar: {
			width: '158px',
		},
		footer: {
			height: '75px',
		},
		navbar: {
			width: '158px',
		},
		loading: {
			width: '168px',
			height: '91px',
		},
	},
};

const copyrightText = {
	padding: '5px 0 5px 22px',
};

const destination = {
	brandId: 'dh',
	name: 'Destination by Hyatt',
	logo,
	color: colors,
	copyrightText,

	font: {
		family: {
			helveticaNeue: 'Helvetica Neue',
			gothamBook: 'Gotham Book',
			arialSansSerif: 'Arial, Helvetica, sans-serif',
			poynterRegular: 'Poynter Regular',
			gothamLight: 'Gotham Light',
			arimo: 'Arimo',
			arimoRegular: 'Arimo Regular',
			arimoRegularSansSerif: 'Arimo Regular, sans-serif',
			arimoBold: 'Arimo Bold',
		},
	},
};

const destinationTheme = {
	...destination,
	...typographyTheme(destination),
	...complementaryTheme,
};

export default destinationTheme;
