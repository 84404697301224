import classNames from 'classnames';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import SelectAsync from 'react-select/async';
import './FormFieldSelect.scss';

const FormFieldSelectAsync = React.forwardRef(
  ({ className, searchPromise, loadOptions, ...props }, ref) => {
    const loadOptionsCallback = useMemo(() => {
      if (loadOptions) {
        return loadOptions;
      }
      if (!searchPromise) {
        return null;
      }
      const load = (query, callback) => {
        if (!(query && query.length > 2)) {
          callback(null);
        } else {
          searchPromise(query).then(resp => callback(resp));
        }
      };
      return debounce(load, 800);
    }, [loadOptions, searchPromise]);

    return (
      <SelectAsync
        placeholder="Select..."
        noOptionsMessage={() => 'No available options'}
        {...props}
        ref={ref}
        loadOptions={loadOptionsCallback}
        className={classNames('FormFieldSelect', className)}
        classNamePrefix="FormFieldSelect"
      />
    );
  }
);

FormFieldSelectAsync.propTypes = {
  className: PropTypes.string,
  searchPromise: PropTypes.func,
  loadOptions: PropTypes.func,
};

FormFieldSelectAsync.defaultProps = {
  className: '',
  searchPromise: null,
  loadOptions: null,
};

export { FormFieldSelectAsync };
