import logo from 'assets/img/logos/hyatt-logo-light.png';
import { Base } from 'components/base';
import { translations } from 'translation/en';
import { getYear } from 'utils/dateUtil';
import { replaceArgs } from 'utils/stringUtil';
import './Footer.scss';

const Footer = () => {
	const year = getYear();

	return (
		<footer className="Footer">
			<Base>
				<div className="Footer__content">
					<div>
						<img className="Footer__logo" src={logo} alt="Hyatt" />
					</div>
					<div>
						<p className="Footer__text"> </p>
						<p className="Footer__text">{replaceArgs(translations.FOOTER_TEXT_ALL_RIGHTS_RESERVED, year)}</p>
						<p className="Footer__text">{replaceArgs(translations.FOOTER_TEXT_VERSION, year)}</p>
						<p className="Footer__text"> </p>
					</div>
				</div>
			</Base>
		</footer>
	);
};

export { Footer };
