import React from 'react';
import { bool, node } from 'prop-types';
import classNames from 'classnames';
import './EditMenuHeader.scss';

export const EditMenuHeader = ({ isRedBackground, children }) => {
	return (
		<p
			className={classNames('EditMenuHeader', {
				'EditMenuHeader--red-background': isRedBackground,
			})}
		>
			{children}
		</p>
	);
};

EditMenuHeader.propTypes = {
	isRedBackground: bool,
	children: node.isRequired,
};

EditMenuHeader.defaultProps = {
	isRedBackground: false,
};
