export const removeEmptyNodesFromHTMLString = htmlStr => {
	const parser = new DOMParser();
	const html = parser.parseFromString(htmlStr, 'text/html');

	const treeWalker = document.createTreeWalker(html.body, NodeFilter.SHOW_ELEMENT);

	let { currentNode } = treeWalker;
	const emptyNodes = [];

	// test if a node has no text, regardless of whitespaces
	const isNodeEmpty = node => !node.textContent.trim();

	// find all empty nodes
	while (currentNode) {
		if (isNodeEmpty(currentNode)) emptyNodes.push(currentNode);
		currentNode = treeWalker.nextNode();
	}

	// remove found empty nodes
	emptyNodes.forEach(node => node.parentNode.removeChild(node));

	return html.body?.firstElementChild?.outerHTML;
};

const removeLineBreak = text => text?.replace(/\n/g, '');

export const capitalizeTheContent = htmlStr => {
	const text = removeLineBreak(htmlStr);

	if (text?.includes('<h3>')) {
		const outputString = text
			.replace(/<h3>.*?<\/h3>/g, match => {
				return match.toUpperCase();
			})
			.replace(/<\/?[a-zA-Z0-9]+[^>]*>|&NBSP;/g, match => {
				if (match.startsWith('<a ') && match.endsWith('>')) {
					return match;
				}
				return match.toLowerCase();
			});
		return outputString;
	}

	return text;
};
