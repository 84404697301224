import { NavLink as RouterLink } from 'react-router-dom';
import styled from 'styled-components/macro';

export const Link = styled(RouterLink)(({ theme: { color }, disabled }) => ({
	display: 'inline-flex',
	alignItems: 'center',
	width: '100%',
	minHeight: '45px',
	background: 'none',
	textDecoration: 'none !important',
	pointerEvents: disabled ? 'none' : 'auto',
	opacity: disabled ? '0.5' : '1 !important',

	a: {
		color: `${color.link.primary.default.label} !important`,
	},

	'&:hover': {
		a: {
			color: `${color.link.primary.hover.label} !important`,
		},
	},
}));

export const Wrapper = styled.div(() => ({
	display: 'inline-flex',
	alignItems: 'center',
	width: '100%',
	height: 'inherit',
	padding: '15px 20px 10px 25px',
	justifyContent: 'space-between',
}));

export const Label = styled.span(({ theme: { color, typo } }) => ({
	...typo.link['03'],
	color: `${color.link.primary.default.label} !important`,
	lineHeight: '1.35',
}));
