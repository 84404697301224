import { SEARCH_RESET, SEARCH_SET_IS_LOADING, SEARCH_SET_NUM_FOUND, SEARCH_SET_PARAMS, SEARCH_SET_RESULTS } from './actionTypes';

export const setSearchedParams = search => ({
	type: SEARCH_SET_PARAMS,
	search,
});

export const setSearchResults = searchResults => ({
	type: SEARCH_SET_RESULTS,
	searchResults,
});

export const setSearchNumFound = numFound => ({
	type: SEARCH_SET_NUM_FOUND,
	numFound,
});

export const setSearchIsLoading = isLoading => ({
	type: SEARCH_SET_IS_LOADING,
	isLoading,
});

export const searchReset = () => ({
	type: SEARCH_RESET,
	results: [],
	numFound: 0,
});

export const setAutoRunIsEnabled = isAutoRunEnabled => ({
	type: SEARCH_SET_IS_LOADING,
	isAutoRunEnabled,
});
