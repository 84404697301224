import useToggle from 'hooks/useToggle';
import { useTheme } from 'styled-components';
import { PrintModal } from './PrintModal';

const PrintButton = () => {
	const { color } = useTheme();
	const [isOpen, setIsOpen] = useToggle();

	return (
		<>
			<i
				className="icon-printer"
				onClick={setIsOpen}
				role="button"
				aria-label="print page button"
				tabIndex="0"
				style={{ color: color.icon.primary02 }}
			/>
			<PrintModal open={isOpen} onClose={setIsOpen} />
		</>
	);
};

export { PrintButton };
