import { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PublishModal } from 'components/modals/PublishModal';
import { ApproveModal } from 'components/modals/ApproveModal';
import { EDIT_MENU_TYPES } from 'components/begEditMenu/constants/begEditMenuConstants';
import { RemoveModal } from 'components/removeModal';
import { editMenuSetSelectedToEdit } from 'actions/actionCreators';
import useBrandDetails from 'hooks/useBrandDetails';
import useBrandRoute from 'hooks/useBrandRoute';
import { useDeletePage } from 'hooks/useDeletePage';
import { useEditMenuData } from 'hooks/useEditMenuData';
import { useUpdatePage } from 'hooks/useUpdatePage';
import { useMovePage } from 'hooks/useMovePage';
import { handleMoveItem, handleRemoveItem, handleUpdateItem, moveDown, moveUp } from 'utils/editNavigationUtil';
import { showToast } from 'utils/toastUtil';
import { translations } from 'translation/en';
import { redirectToQuickSheet } from 'utils/redirectUtil';
import ArrowUpDown from 'assets/img/arrows/arrow-up-down.svg';
import { EditNavigationPortal } from '../editNavigationPortal';
import { EditNavigationTypes } from '../types/editNavigationTypes';
import './MenuEditNavigation.scss';

const MenuEditNavigation = ({ show, parentRef, value, children, isLastLevel, onAdd }) => {
	const initialState = useMemo(() => {
		return {
			type: EditNavigationTypes.CLOSE,
		};
	}, []);

	const history = useHistory();
	const { selectedToEdit } = useEditMenuData();
	const { baseRoute, getBrandRoute } = useBrandRoute();
	const {
		chapters,
		currentType,
		isLoadingItems,
		onFinishedLoadingItems,
		setMutableChapters,
		onLoadingItems,
		handleSaveChaptersToHistory,
	} = useBrandDetails();

	const { updatePage } = useUpdatePage();
	const { deletePage } = useDeletePage();
	const { swappingPage } = useMovePage();

	const [item, setItem] = useState({
		currentItem: null,
		previousItem: null,
	});

	const [inputValue, setInputValue] = useState(value);
	const [state, setDispatch] = useState(initialState);
	const dispatch = useDispatch();

	const isEnabled = selectedToEdit === EDIT_MENU_TYPES.EDIT_NAVIGATION && show;

	const editNavigationClassNames = classNames('EditNavigationMenu__box', {
		'EditNavigationMenu__box--open': isEnabled,
	});

	const cellClassNames = classNames('EditNavigationMenu__cell', {
		'EditNavigationMenu__cell--selected': isEnabled,
	});

	const handleCancel = useCallback(() => {
		setDispatch(initialState);
	}, [initialState]);

	const onResetInput = useCallback(() => {
		setInputValue(value);
	}, [value]);

	const onReset = useCallback(() => {
		handleCancel();
		onResetInput();
	}, [handleCancel, onResetInput]);

	const onCloseEditMode = useCallback(() => {
		dispatch(editMenuSetSelectedToEdit({ selectedToEdit: EditNavigationTypes.CLOSE }));
	}, [dispatch]);

	const onSuccessUpdated = useCallback(() => {
		const updatedChapters = handleUpdateItem(chapters, currentType, inputValue);
		setMutableChapters(updatedChapters);
		// TODO I think it will be a better approach to use a useReducer
		// to be able to manage the cases and states of the mutableChapters.
		handleSaveChaptersToHistory(updatedChapters);
		history.replace(getBrandRoute(currentType.path));
	}, [chapters, currentType, history, inputValue, setMutableChapters, handleSaveChaptersToHistory, getBrandRoute]);

	const onSuccessDeleted = useCallback(() => {
		const updatedChapters = handleRemoveItem(chapters, currentType);
		setMutableChapters(updatedChapters);
		// TODO I think it will be a better approach to use a useReducer
		// to be able to manage the cases and states of the mutableChapters.
		handleSaveChaptersToHistory(updatedChapters);
		history.replace(baseRoute);
	}, [baseRoute, chapters, currentType, history, setMutableChapters, handleSaveChaptersToHistory]);

	const onFinally = useCallback(() => {
		onCloseEditMode();
		onFinishedLoadingItems();
	}, [onCloseEditMode, onFinishedLoadingItems]);

	const handleMoveUp = useCallback(() => {
		const { previousItem, currentItem, updatedArray } = handleMoveItem(chapters, currentType, moveUp);
		setItem({
			currentItem,
			previousItem,
		});
		setMutableChapters(updatedArray);
	}, [chapters, currentType, setMutableChapters]);

	const handleMoveDown = useCallback(() => {
		const { previousItem, currentItem, updatedArray } = handleMoveItem(chapters, currentType, moveDown);
		setItem({
			currentItem,
			previousItem,
		});
		setMutableChapters(updatedArray);
	}, [chapters, currentType, setMutableChapters]);

	const handleEdit = () => {
		setDispatch({
			type: EditNavigationTypes.EDIT,
		});
	};

	const handleMove = useCallback(() => {
		setDispatch({
			type: EditNavigationTypes.MOVE,
		});
	}, []);

	const handleAdd = useCallback(() => {
		onResetInput();
		setDispatch({
			type: EditNavigationTypes.ADD,
		});
	}, [onResetInput]);

	const handleCheck = useCallback(() => {
		setDispatch({
			type: EditNavigationTypes.CHECK,
		});
	}, []);

	const handlePublish = useCallback(() => {
		setDispatch({
			type: EditNavigationTypes.PUBLISH,
		});
	}, []);

	const handleRemove = useCallback(() => {
		setDispatch({
			type: EditNavigationTypes.REMOVE,
		});
	}, []);

	const handleChange = e => {
		e.preventDefault();
		setInputValue(e.target.value);
	};

	const handleSubmitSwap = useCallback(
		async e => {
			e.preventDefault();
			onLoadingItems();

			swappingPage({
				type: currentType?.type,
				currentItem: item?.currentItem,
				previousItem: item?.previousItem,
				onSuccess: () => handleSaveChaptersToHistory(chapters),
				onFinished: () => onFinally(),
			});
		},
		[chapters, item, currentType?.type, onLoadingItems, handleSaveChaptersToHistory, swappingPage, onFinally]
	);

	const handleEmptySubmit = useCallback(() => {
		const errorMessage = `${translations.FAILED_SAVE_CONTENT}. Reason: ${translations.EMPTY_TITLE}`;

		showToast({
			type: 'error',
			message: errorMessage,
		});
	}, []);

	const handleSubmit = useCallback(
		async e => {
			if (inputValue === '') {
				handleEmptySubmit();
			} else {
				e.preventDefault();
				onLoadingItems();

				updatePage({
					title: inputValue,
					path: currentType?.path,
				})
					.then(([response]) => {
						if (response.data) {
							onSuccessUpdated();
						}
					})
					.finally(() => {
						onFinally();
					});
			}
		},
		[inputValue, onLoadingItems, updatePage, currentType?.path, handleEmptySubmit, onSuccessUpdated, onFinally]
	);

	const handleConfirmRemoveItem = async () => {
		onReset();
		onLoadingItems();

		deletePage()
			.then(response => {
				if (!response.error) {
					onSuccessDeleted();
				}
			})
			.finally(() => {
				onFinally();
			});
	};

	const handleCancelEdit = useCallback(() => {
		onReset();
	}, [onReset]);

	const handleRedirectQuickSheet = e => {
		e.preventDefault();
		redirectToQuickSheet();
	};

	useEffect(() => {
		if (state) {
			onAdd(state.type === EditNavigationTypes.ADD);
		}
	}, [state, onAdd]);

	useEffect(() => {
		if (!isEnabled && currentType) {
			onReset();
		}
	}, [currentType, isEnabled, onReset]);

	const actionSaveByType = useMemo(() => {
		if (state.type === EditNavigationTypes.MOVE) {
			return (
				<button
					className="EditNavigationMenu__icon-button"
					title={translations.TOOLTIP_EDIT_NAVIGATION_TEXT_SAVE}
					onClick={handleSubmitSwap}
				>
					<i className="icon icon-save" style={{ fontSize: 'large' }} />
				</button>
			);
		}

		return (
			<button
				className="EditNavigationMenu__icon-button"
				title={translations.TOOLTIP_EDIT_NAVIGATION_TEXT_SAVE}
				disabled={inputValue === value || !inputValue}
				onClick={handleSubmit}
			>
				<i className="icon icon-save" style={{ fontSize: 'large' }} />
			</button>
		);
	}, [state.type, value, inputValue, handleSubmit, handleSubmitSwap]);

	const actionsOnTheLeft = useMemo(() => {
		if (state.type === EditNavigationTypes.MOVE) {
			return (
				<div className="EditNavigationMenu__list-buttons EditNavigationMenu__list-buttons__left">
					<button
						className="EditNavigationMenu__icon-button"
						title={translations.TOOLTIP_EDIT_NAVIGATION_TEXT_MOVE_UP}
						onClick={handleMoveUp}
					>
						<i className="icon icon-chevron-up" style={{ fontSize: 'large' }} />
					</button>
					<button
						className="EditNavigationMenu__icon-button"
						title={translations.TOOLTIP_EDIT_NAVIGATION_TEXT_MOVE_DOWN}
						onClick={handleMoveDown}
					>
						<i className="icon icon-chevron-down" style={{ fontSize: 'large' }} />
					</button>
				</div>
			);
		}

		return (
			!currentType?.quickSheetPath && (
				<div className="EditNavigationMenu__list-buttons EditNavigationMenu__list-buttons__left">
					<button
						aria-label="remove-action"
						title={translations.TOOLTIP_EDIT_NAVIGATION_TEXT_REMOVE}
						className="EditNavigationMenu__icon-button"
						onClick={handleRemove}
					>
						<i className="icon icon-trash" style={{ fontSize: 'large' }} />
					</button>
				</div>
			)
		);
	}, [state.type, currentType?.quickSheetPath, handleRemove, handleMoveUp, handleMoveDown]);

	const actionsOnTheRight = useMemo(
		() => (
			<div>
				{state.type === EditNavigationTypes.EDIT ? (
					<button
						aria-label="cancel-edit-action"
						className="EditNavigationMenu__icon-button"
						title={translations.TOOLTIP_EDIT_NAVIGATION_TEXT_CANCEL_EDIT}
						onClick={handleCancelEdit}
					>
						<i className="icon icon-delete-circle" style={{ fontSize: 'large' }} />
					</button>
				) : (
					<button
						aria-label="edit-action"
						title={translations.TOOLTIP_EDIT_NAVIGATION_TEXT_EDIT}
						className="EditNavigationMenu__icon-button"
						onClick={handleEdit}
					>
						<i className="icon icon-edit" style={{ fontSize: 'large' }} />
					</button>
				)}

				{currentType?.quickSheetPath && (
					<button
						aria-label="quicksheet-link-action"
						title={translations.TOOLTIP_EDIT_NAVIGATION_TEXT_QUICKSHEET}
						className="EditNavigationMenu__icon-button"
						onClick={handleRedirectQuickSheet}
					>
						<i className="icon-documents-outline" style={{ fontSize: 'large' }} />
					</button>
				)}

				<button
					className="EditNavigationMenu__icon-button"
					title={translations.TOOLTIP_EDIT_NAVIGATION_TEXT_MOVE}
					onClick={handleMove}
				>
					<img src={ArrowUpDown} style={{ width: '21px', height: '21px', filter: 'invert(37%)' }} alt="" />
				</button>

				{!currentType?.quickSheetPath && (
					<div>
						{!isLastLevel && (
							<button
								aria-label="add-action"
								title={translations.TOOLTIP_EDIT_NAVIGATION_TEXT_ADD}
								className="EditNavigationMenu__icon-button"
								onClick={handleAdd}
							>
								<i className="icon icon-plus" style={{ fontSize: 'x-large' }} />
							</button>
						)}

						<button
							className="EditNavigationMenu__icon-button"
							title={translations.TOOLTIP_EDIT_NAVIGATION_TEXT_APPROVE}
							onClick={handleCheck}
						>
							<i className="icon icon-check" style={{ fontSize: 'xx-large' }} />
						</button>

						<button
							className="EditNavigationMenu__icon-button"
							title={translations.TOOLTIP_EDIT_NAVIGATION_TEXT_PUBLISH}
							onClick={handlePublish}
						>
							<i className="icon icon-publish" style={{ fontSize: 'large' }} />
						</button>
					</div>
				)}

				{actionSaveByType}
			</div>
		),
		[
			isLastLevel,
			state.type,
			currentType?.quickSheetPath,
			handleCancelEdit,
			handleMove,
			handleAdd,
			handleCheck,
			handlePublish,
			actionSaveByType,
		]
	);

	const ChildrenComponent = useMemo(() => {
		if (state.type === EditNavigationTypes.EDIT) {
			return (
				<input
					aria-label="edit-input"
					className="EditNavigationMenu__edit-input"
					type="text"
					value={inputValue}
					onChange={handleChange}
				/>
			);
		}

		return <div className={cellClassNames}>{children}</div>;
	}, [cellClassNames, children, state.type, inputValue]);

	return (
		<>
			{!isLoadingItems && parentRef.current && (
				<EditNavigationPortal show={show} parentRef={parentRef}>
					<div className="EditNavigationMenu">
						<div className={editNavigationClassNames}>
							<div>{actionsOnTheLeft}</div>
							<div className="EditNavigationMenu__list-buttons EditNavigationMenu__list-buttons__right">
								{actionsOnTheRight}
							</div>
						</div>
					</div>
				</EditNavigationPortal>
			)}

			{ChildrenComponent}

			<RemoveModal
				open={state.type === EditNavigationTypes.REMOVE}
				title={translations.MODAL_REMOVE_ITEM_TITLE}
				onConfirm={handleConfirmRemoveItem}
				onClose={handleCancel}
			/>

			{state.type === EditNavigationTypes.CHECK && <ApproveModal open title={value} onClose={handleCancel} />}

			{state.type === EditNavigationTypes.PUBLISH && <PublishModal open title={value} onClose={handleCancel} />}
		</>
	);
};

MenuEditNavigation.propTypes = {
	show: PropTypes.bool,
	parentRef: PropTypes.shape({
		current: PropTypes.any,
	}).isRequired,
	children: PropTypes.node,
	value: PropTypes.string,
	isLastLevel: PropTypes.bool,
	onAdd: PropTypes.func,
};

MenuEditNavigation.defaultProps = {
	show: false,
	children: null,
	value: '',
	isLastLevel: false,
	onAdd: () => {},
};

export { MenuEditNavigation };
