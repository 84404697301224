import styled from 'styled-components/macro';

export const Wrapper = styled.div(({ theme: { breakpoint } }) => ({
	'& .WrapperEditableContent': {
		display: 'flex',
		flexDirection: 'column-reverse',

		[breakpoint.md()]: {
			display: 'block',
		},
	},
}));

export const Box = styled.div(({ theme: { breakpoint } }) => ({
	width: 'auto',

	[breakpoint.md()]: {
		float: 'right',
		paddingLeft: '15px',
		width: '65%',
	},
}));

export const Gallery = styled.div(() => ({
	display: 'grid',
	gridTemplateColumns: 'repeat(2, 1fr)',
	justifyContent: 'space-between',
	gap: '30px',
}));

export const SpecificationOnPrintPage = styled.div(() => ({
	display: 'flex',
	flexWrap: 'wrap',
	width: '100%',
}));
