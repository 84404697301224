import { InnerHtml } from '@hyatt/inner-html';
import { Button } from 'components/button';
import { CheckBox } from 'components/checkBox';
import { Loading } from 'components/loadingOverlay';
import { Modal } from 'components/modals/modal';
import { WordBox } from 'components/wordBox';
import { BEG_TERM, MODELS_PATHS } from 'constants/aemConstants';
import { usePutContentFragment } from 'hooks/usePutContentFragment';
import { arrayOf, bool, func, string } from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import useBrandDetails from 'hooks/useBrandDetails';
import { translations } from 'translation/en';
import { TitleContainer } from 'containers/brandDetails/components/brandMain/components/editableContent/EditableContent.style';
import * as S from './PropertiesModal.style';

const PropertiesModal = ({
	open,
	onClose,
	titleValue,
	isCollapsable,
	setIsCollapsable,
	startKeywords,
	setStartKeywords,
	modulePath,
}) => {
	const { typo, logo } = useTheme();
	const { currentType } = useBrandDetails();
	const { loadingUpdate, error, updateContentFragment } = usePutContentFragment();

	const [isCollapsableLocal, setIsCollapsableLocal] = useState(isCollapsable);
	const [keywords, setKeywords] = useState(startKeywords || []);

	useEffect(() => {
		setIsCollapsableLocal(isCollapsable);
	}, [isCollapsable]);

	const handleClose = useCallback(() => {
		setKeywords(startKeywords);
		setIsCollapsableLocal(isCollapsable);
		onClose();
	}, [isCollapsable, onClose, startKeywords]);

	const handleSave = useCallback(async () => {
		await updateContentFragment({
			payload: {
				COLLAPSIBLE: isCollapsableLocal,
				RELATED_TERMS: keywords.map(word => `${BEG_TERM}${word}`),
				approved: true,
			},
			path: modulePath,
			model: MODELS_PATHS.RELATOR_PARAGRAPHS,
			pagePath: currentType?.path,
			cfType: currentType?.type,
		});

		if (!error) {
			setIsCollapsable(isCollapsableLocal);
			setStartKeywords(keywords);
		}

		onClose();
	}, [
		updateContentFragment,
		isCollapsableLocal,
		keywords,
		modulePath,
		currentType?.path,
		currentType?.type,
		error,
		onClose,
		setIsCollapsable,
		setStartKeywords,
	]);

	const handleSaveAsDraft = useCallback(async () => {
		await updateContentFragment({
			payload: { COLLAPSIBLE: isCollapsableLocal, RELATED_TERMS: keywords.map(word => `${BEG_TERM}${word}`) },
			path: modulePath,
			model: MODELS_PATHS.RELATOR_PARAGRAPHS,
			pagePath: currentType?.path,
			cfType: currentType?.type,
			shouldApprove: false,
		});

		if (!error) {
			setIsCollapsable(isCollapsableLocal);
			setStartKeywords(keywords);
		}

		onClose();
	}, [
		updateContentFragment,
		isCollapsableLocal,
		keywords,
		modulePath,
		currentType?.path,
		currentType?.type,
		error,
		onClose,
		setIsCollapsable,
		setStartKeywords,
	]);

	return (
		open && (
			<S.PropertyModal
				open={open}
				overlayClassName="ModalBox__white-overlay"
				containerStyle={{ borderTop: 'none', paddingTop: '10px' }}
				onClose={handleClose}
				closeIcon={<i className="icon-close-slim" />}
				hideExpand
			>
				{loadingUpdate && (
					<Loading.Overlay>
						<Loading.LoadingLogo logo={logo?.loading} size={logo?.size.loading} />
					</Loading.Overlay>
				)}

				<Modal.Header variant="h2" showDivider={false}>
					{translations.PROPERTIES}
				</Modal.Header>

				<S.ModalMain>
					<div>
						<S.Title>{translations.TITLE}</S.Title>
						<TitleContainer>
							<InnerHtml body={titleValue} />
						</TitleContainer>
					</div>

					<div style={{ margin: '10px 0px 25px' }}>
						<CheckBox
							checked={isCollapsableLocal}
							label={translations.COLLAPSE_MODULE_ON_LOAD}
							checkBoxStyle={{ margin: '0px 5px 0px 0px', width: '20px', height: '20px' }}
							labelStyle={{ ...typo.lead['08'] }}
							onChange={() => setIsCollapsableLocal(previousValue => !previousValue)}
						/>
					</div>

					<div>
						<S.Keywords>{translations.KEYWORDS}</S.Keywords>
						<WordBox
							startKeywords={startKeywords}
							words={keywords}
							buttonName=""
							placeholder={translations.ENTER_YOUR_KEYWORDS}
							setWords={setKeywords}
						/>
					</div>
				</S.ModalMain>

				<Modal.Footer style={{ backgroundColor: '#f3f3f3' }}>
					<Button variant="gray-border-only" onClick={handleClose}>
						{translations.CANCEL}
					</Button>
					<Button variant="tertiary-03" onClick={handleSaveAsDraft}>
						{translations.SAVE_AS_DRAFT}
					</Button>
					<Button variant="tertiary-03" onClick={handleSave}>
						{translations.SAVE}
					</Button>
				</Modal.Footer>
			</S.PropertyModal>
		)
	);
};

PropertiesModal.propTypes = {
	open: bool,
	isCollapsable: bool,
	setIsCollapsable: func.isRequired,
	onClose: func.isRequired,
	fieldName: string.isRequired,
	modulePath: string.isRequired,
	titleValue: string.isRequired,
	startKeywords: arrayOf(string),
	setStartKeywords: func.isRequired,
};

PropertiesModal.defaultProps = {
	open: false,
	isCollapsable: false,
	startKeywords: [],
};

export { PropertiesModal };
