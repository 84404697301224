import axios from 'API/axios/axios';
import { PAGE_TYPES } from 'constants/pageConstants';
import { begSqlApi } from 'constants/serviceConstants';
import { mappedParagraphs } from 'utils/paragraphUtil';

export const getParagraphsByChapters = params => {
	const { path } = params;
	const url = `${process.env.REACT_APP_API_URL}${begSqlApi}/get-paragraphs-by-chapter`;

	return axios.get(url, {
		params: {
			pagePath: `beg-print-${path}`,
			cfType: PAGE_TYPES.CHAPTER_TYPE,
			parameter: `;_path=${path}`,
		},
	});
};

export const fromParagraphsByChapterService = data => {
	return {
		chapter: {
			title: data.BEG_CHAPTER_TITLE,
			paragraphs: data?.BEG_CHAPTER_CONTENT_FK_BEG_CHAPTER_ID?.map(mappedParagraphs),
			sections: data?.BEG_CHAPTER_RELATOR_FK_CHAPTER_ID?.map(sectionContentItem => {
				return {
					sectionTitle: sectionContentItem?.BEG_CHAPTER_RELATOR_SECTION_ID?.BEG_SECTION_TITLE,
					sectionDisplayOrder: sectionContentItem?.BEG_CHAPTER_RELATOR_SECTION_DISPLAY_ORDER,
					paragraphs:
						sectionContentItem.BEG_CHAPTER_RELATOR_SECTION_ID?.BEG_SECTION_CONTENT_FK_BEG_SECTION_ID?.map(
							mappedParagraphs
						),
					articles: sectionContentItem.BEG_CHAPTER_RELATOR_SECTION_ID?.BEG_SECTION_RELATOR_FK_SECTION_ID?.map(
						articleArrayItem => ({
							articleTitle: articleArrayItem?.BEG_SECTION_RELATOR_ARTICLE_ID.BEG_ARTICLE_TITLE,
							articleDisplayOrder: articleArrayItem?.BEG_SECTION_RELATOR_ARTICLE_DISPLAY_ORDER,
							paragraphs:
								articleArrayItem?.BEG_SECTION_RELATOR_ARTICLE_ID.BEG_ARTICLE_CONTENT_FK_BEG_ARTICLE_ID?.map(
									mappedParagraphs
								),
						})
					),
				};
			}),
		},
	};
};
