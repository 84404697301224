import { func } from 'prop-types';
import React from 'react';
import { DAY_VALUE } from '../../constants/modalAnalyticsConstants';
import { translation } from '../../constants/translation/en';
import { useAnalyticsContext } from '../../hooks/useAnalyticsContext';
import './HeaderAnalytics.scss';

export const HeaderAnalytics = ({ openMenu }) => {
	const { locationType, counterMetrics, metrics } = useAnalyticsContext();

	const totalMetrics = `(${counterMetrics(metrics)})`;

	return (
		<div className="HeaderAnalytics">
			<h1>
				<button className="HeaderAnalytics__buttonMenu" onClick={openMenu}>
					<i className="icon-hamburger-menu" />
				</button>

				{`
            ${translation.METRICS_BY} 
            ${locationType.text} 
            ${locationType.value !== DAY_VALUE ? totalMetrics : ''}
        `}
			</h1>
		</div>
	);
};

HeaderAnalytics.propTypes = {
	openMenu: func.isRequired,
};
