import axios from './axios/axios';

/**
 * Publish approved content
 *
 * @param {string|string[]} path - The path or an array of paths of the content(s) to publish
 * @param {Map<String, String>} cacheKeys - The cache keys to invalidate
 * @returns {object} The response from the API
 */
export const publishApprovedContent = (path, cacheKeys = {}) => {
	const url = `${process.env.REACT_APP_API_URL}/v1/content/approvedPublish`;

	return axios
		.post(url, {
			keys: cacheKeys,
			pathList: Array.isArray(path) ? path : [path],
		})
		.then(response => response.data.publishUnpublishNode);
};

/**
 * Publish Path´s
 *
 @param {string|string[]} path - The path or an array of paths of the content(s) to publish
 * @param {Map<String, String>} cacheKeys - The cache keys to invalidate
 * @returns {object} The response from the API
 */
export const publishApprovedAsync = (path, cacheKeys = {}) => {
	const url = `${process.env.REACT_APP_API_URL}/v1/content/approvedPublishAsync`;

	return axios.post(url, {
		keys: cacheKeys,
		pathList: Array.isArray(path) ? path : [path],
	});
};

/**
 * Publication status check service
 * @param {string} operationId
 * @returns
 */
export const getReplicateStatusByOperationId = operationId => {
	const url = `${process.env.REACT_APP_API_URL}/v1/content/replicateStatusByName?name=${operationId}`;
	return axios.get(url);
};

/**
 * Unpublish content
 *
 * @param {string|string[]} path - The path or an array of paths to the content(s) to unpublish
 * @param {Map<String, String>} cacheKeys - The cache keys to invalidate
 * @returns {object} The response from the API
 */
export const unpublishContent = (path, cacheKeys = {}) => {
	const url = `${process.env.REACT_APP_API_URL}/v1/content/unpublish`;

	return axios
		.post(url, {
			keys: cacheKeys,
			pathList: Array.isArray(path) ? path : [path],
		})
		.then(response => response.data.publishUnpublishNode);
};

/**
 * Unpublish Path´s
 *
 @param {string|string[]} path - The path or an array of paths of the content(s) to unpublish
 * @param {Map<String, String>} cacheKeys - The cache keys to invalidate
 * @returns {object} The response from the API
 */
export const unpublishAsync = (path, cacheKeys = {}) => {
	const url = `${process.env.REACT_APP_API_URL}/v1/content/unpublishAsync`;

	return axios.post(url, {
		keys: cacheKeys,
		pathList: Array.isArray(path) ? path : [path],
	});
};

/**
 * Approve content
 *
 * @param {string|string[]} paths - The path or an array of paths to the content(s) to unpublish
 * @param {Map<String, String>} cacheKeys - The cache keys to invalidate
 * @returns {object} The response from the API
 */
export const approveContent = (paths, cacheKeys = {}) => {
	const url = `${process.env.REACT_APP_API_URL}/v1/contentFragment/approve`;

	return axios.post(url, {
		keys: cacheKeys,
		pathList: Array.isArray(paths) ? paths : [paths],
	});
};

/**
 * Approve Async Content
 *
 * @param {string|string[]} paths - The path or an array of paths to the content(s) to unpublish
 * @param {Map<String, String>} cacheKeys - The cache keys to invalidate
 * @returns {object} The response from the API
 */
export const approveAsync = (paths, cacheKeys = {}) => {
	const url = `${process.env.REACT_APP_API_URL}/v1/contentFragment/approveAsync`;

	return axios.post(url, {
		keys: cacheKeys,
		pathList: Array.isArray(paths) ? paths : [paths],
	});
};

/**
 * Disapprove content
 *
 * @param {string|string[]} paths - The path or an array of paths to the content(s) to unpublish
 * @param {Map<String, String>} cacheKeys - The cache keys to invalidate
 * @returns {object} The response from the API
 */
export const disapproveContent = (paths, cacheKeys = {}) => {
	const url = `${process.env.REACT_APP_API_URL}/v1/contentFragment/disapprove`;

	return axios.post(url, {
		keys: cacheKeys,
		pathList: Array.isArray(paths) ? paths : [paths],
	});
};

/**
 * Disapprove Async content
 *
 * @param {string|string[]} paths - The path or an array of paths to the content(s) to unpublish
 * @param {Map<String, String>} cacheKeys - The cache keys to invalidate
 * @returns {object} The response from the API
 */
export const disapproveAsync = (paths, cacheKeys = {}) => {
	const url = `${process.env.REACT_APP_API_URL}/v1/contentFragment/disapproveAsync`;

	return axios.post(url, {
		keys: cacheKeys,
		pathList: Array.isArray(paths) ? paths : [paths],
	});
};
