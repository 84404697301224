import { useEffect, useMemo, useState, useRef, useCallback } from 'react';
import { approveAsync, approveContent, getReplicateStatusByOperationId } from 'API/publishAPI';
import { translations } from 'translation/en';
import { APPROVE_STATUS } from 'components/modals/constants/approvePublishConstants';
import { showToast } from 'utils/toastUtil';
import { isEmpty } from 'utils/arrayUtil';
import { getRelationsByPathAndType } from 'utils/approveUtil';
import { PAGE_TYPES } from 'constants/pageConstants';
import { useRequest } from './useRequest';
import useBrandDetails from './useBrandDetails';
import useApprovePublish from './useApprovePublish';
import useClearCache from './useClearCache';

const FIVE_SECONDS = 5000;

export const useApprovePage = () => {
	const initialState = useMemo(
		() => ({
			loading: false,
			data: null,
			error: null,
		}),
		[]
	);

	const { currentType, chapters } = useBrandDetails();
	const { setIsPageApproved } = useApprovePublish();

	const { loading: loadingApprove, error: approveError, run: postApprove } = useRequest(approveContent);
	const { loading: loadingApproveAsync, error: approveErrorAsync, run: postApproveAsync } = useRequest(approveAsync);
	const {
		loading: loadingReplicateStatus,
		error: replicateStatusError,
		run: fetchReplicateStatus,
	} = useRequest(getReplicateStatusByOperationId);

	const intervalRef = useRef();
	const [requestData, setRequestData] = useState(initialState);
	const { clearCache } = useClearCache();

	const getRelatorPathByTypeAndPath = useCallback(
		({ type, path }) => {
			const currentChapter = chapters.find(chapter => chapter.path === currentType?.chapterPath);
			if (type === PAGE_TYPES.SECTION_TYPE) {
				const sectionRelatorPath = currentChapter?.sections.find(section => section.path === path)?.relatorPath;

				if (sectionRelatorPath) {
					return sectionRelatorPath;
				}
			}

			if (type === PAGE_TYPES.ARTICLE_TYPE) {
				const articleRelatorPath = currentChapter?.sections
					.find(section => section.articles.find(article => article.path === path))
					?.articles.find(article => article.path === path)?.relatorPath;

				if (articleRelatorPath) {
					return articleRelatorPath;
				}
			}

			return null;
		},
		[chapters, currentType?.chapterPath]
	);

	const stopInterval = () => {
		clearInterval(intervalRef.current);
	};

	const startInterval = useCallback(
		(operationPrefix, operationId) => {
			intervalRef.current = setInterval(async () => {
				const operation = `${operationPrefix}${operationId}`;

				return fetchReplicateStatus(operation)
					.then(response => {
						if (isEmpty(response?.data)) {
							stopInterval();
							clearCache({ successCallback: () => {} });
							setIsPageApproved(true);
							setRequestData({
								...initialState,
								data: {
									status: APPROVE_STATUS.FINISHED,
								},
							});
						}
					})
					.catch(err => {
						stopInterval();
						setRequestData({ ...initialState, error: err });
					});
			}, FIVE_SECONDS);
		},
		[initialState, setIsPageApproved, fetchReplicateStatus, clearCache]
	);

	const sendPathsToApprove = useCallback(
		pathsToApprove => {
			return postApproveAsync(pathsToApprove)
				.then(response => {
					if (response.data) {
						const { operationId, operationPrefix } = response.data;
						startInterval(operationPrefix, operationId);
					}
				})
				.catch(err => {
					setRequestData({ ...initialState, error: err });
				});
		},
		[initialState, postApproveAsync, startInterval]
	);

	const approvePageTree = useCallback(
		async itemsToApprove => {
			setRequestData({ ...initialState, loading: true });

			let pathsToApprove = [];
			const promises = [];

			if (currentType?.quickSheetPath) {
				pathsToApprove = [currentType?.chapterPath, currentType?.quickSheetPath];
			} else {
				const paths = itemsToApprove.map(cf => cf.path);
				const results = itemsToApprove.map(({ path, type }) => getRelationsByPathAndType({ path, type }));

				const pathsPromise = Promise.all(results).then(resultArray => {
					pathsToApprove = [...pathsToApprove, ...resultArray.flat()];
				});

				promises.push(pathsPromise);
				await pathsPromise;

				itemsToApprove.forEach(({ type, path }) => {
					if (type !== PAGE_TYPES.CHAPTER_TYPE) {
						const relatorPath = getRelatorPathByTypeAndPath({ type, path });

						if (relatorPath) {
							pathsToApprove = [...pathsToApprove, relatorPath];
						}
					}
				});

				pathsToApprove = [...paths, ...pathsToApprove];
			}

			const promise = sendPathsToApprove(pathsToApprove);
			await promise;
			promises.push(promise);

			return Promise.all(promises);
		},
		[initialState, currentType?.quickSheetPath, currentType?.chapterPath, sendPathsToApprove, getRelatorPathByTypeAndPath]
	);

	const approvePage = useCallback(
		async itemsToApprove => {
			setRequestData({ ...initialState, loading: true });

			let pathsToApprove = [];

			if (currentType?.quickSheetPath) {
				pathsToApprove = [currentType?.chapterPath, currentType?.quickSheetPath];
			} else {
				pathsToApprove = itemsToApprove.map(cf => cf.path);
			}

			return postApprove(pathsToApprove)
				.then(response => {
					if (response.data) {
						clearCache({ successCallback: () => {} });
						setIsPageApproved(true);
						setRequestData({ ...initialState, data: response.data });
					}
				})
				.catch(err => {
					setRequestData({ ...initialState, error: err });
				});
		},
		[initialState, currentType, setIsPageApproved, postApprove, clearCache]
	);

	const isLoadingApprovePage = useMemo(
		() => [loadingApprove, loadingApproveAsync, loadingReplicateStatus, requestData.loading].some(Boolean),
		[loadingApprove, loadingApproveAsync, loadingReplicateStatus, requestData.loading]
	);

	const isErrorApprovePage = useMemo(
		() => [approveError, approveErrorAsync, replicateStatusError, requestData.error].some(Boolean),
		[approveError, approveErrorAsync, replicateStatusError, requestData.error]
	);

	const approvedPage = useMemo(() => requestData.data, [requestData.data]);

	useEffect(() => {
		if (isErrorApprovePage) {
			showToast({ type: 'error', message: translations.ERROR_WHEN_APPROVING_CONTENT });
		}
	}, [initialState, isErrorApprovePage]);

	return { approveLoading: isLoadingApprovePage, approvedPage, approvePage, approvePageTree };
};
