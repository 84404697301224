import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { EditNavigation } from 'components/editNavigation';
import { BrandSideBarSections } from 'containers/brandDetails/components/brandSideBar/brandSideBarSections';
import useBrandDetails from 'hooks/useBrandDetails';
import useBrandRoute from 'hooks/useBrandRoute';
import { hasLength } from 'utils/arrayUtil';
import { PAGE_TYPES } from 'constants/pageConstants';
import { ChapterItem } from './ChapterItem';
import * as S from './BrandSideBarChapter.style';

const BrandSideBarChapter = ({ vcmId, title, path, sections, isDisableEdit, isOpen, onCloseMenu, onMenuClick, onIconClick }) => {
	const history = useHistory();
	const { currentType } = useBrandDetails();
	const { getBrandRoute } = useBrandRoute();

	const parentRef = useRef(null);
	const [isAddSection, setIsAddSection] = useState();

	const isEditNavigationEnabled = !isDisableEdit && currentType?.path === path;

	const hasSections = hasLength(sections);
	const arrowClassNames = hasSections && classNames('icon icon-chevron-down');

	const handleClick = e => {
		e.preventDefault();
		setTimeout(() => {
			onMenuClick(vcmId);
		});

		if (!hasSections) {
			onCloseMenu();
		}

		history.push(getBrandRoute(path));
	};

	const handleArrowClick = e => {
		e.preventDefault();
		onIconClick(vcmId);
	};

	const handleAdd = isAddChild => {
		setIsAddSection(isAddChild);
	};

	return (
		<S.BrandSideBarChapter ref={parentRef} data-testid={PAGE_TYPES.CHAPTER_TEST_ID}>
			<EditNavigation.Menu show={isEditNavigationEnabled} parentRef={parentRef} value={title} onAdd={handleAdd}>
				<ChapterItem
					to={getBrandRoute(path)}
					icon={{
						name: arrowClassNames,
						style: {
							transform: isOpen ? 'rotate(180deg)' : 'none',
							transition: 'all .3s ease',
						},
					}}
					onClick={handleClick}
					onArrowClick={handleArrowClick}
				>
					{title}
				</ChapterItem>

				<S.Body isExpanded={isOpen && hasSections}>
					<BrandSideBarSections
						sections={sections}
						isExpanded={isOpen}
						onCloseMenu={onCloseMenu}
						onMenuClick={onMenuClick}
						onIconClick={onIconClick}
					/>
				</S.Body>

				{isAddSection && <EditNavigation.Input title={title} type={PAGE_TYPES.SECTION_TYPE} />}
			</EditNavigation.Menu>
		</S.BrandSideBarChapter>
	);
};

BrandSideBarChapter.propTypes = {
	vcmId: PropTypes.string,
	title: PropTypes.string,
	path: PropTypes.string,
	sections: PropTypes.array,
	isDisableEdit: PropTypes.bool,
	isOpen: PropTypes.bool,
	onCloseMenu: PropTypes.func,
	onMenuClick: PropTypes.func,
	onIconClick: PropTypes.func,
};

BrandSideBarChapter.defaultProps = {
	vcmId: null,
	title: '',
	path: '',
	isDisableEdit: false,
	isOpen: false,
	sections: [],
	onCloseMenu: () => {},
	onMenuClick: () => {},
	onIconClick: () => {},
};

export { BrandSideBarChapter };
