export const findAnchorElement = element => {
	if (element.tagName === 'A') {
		return element;
	}

	let parent = element.parentElement;

	while (parent && parent.tagName !== 'A') {
		parent = parent.parentElement;
	}

	return parent;
};
