import { detailRoutesConstants } from 'constants/detailRoutesConstants';
import { getMetrics, getMetricsByType } from 'services/analyticsService';
import { getUser } from 'services/userService';

export const appConfig = {
	appName: process.env.REACT_APP_ANALYTICS_APP_NAME,
	employeeROUTE: () => `${process.env.REACT_APP_HC_DOMAIN}${detailRoutesConstants?.employeeROUTE.getRouteDefaultPath()}`,
	locationsDetailROUTE: () =>
		`${process.env.REACT_APP_HC_DOMAIN}${detailRoutesConstants?.locationsDetailROUTE.getRouteDefaultPath()}`,
	getUser: userId => getUser(userId),
	getMetricsByType: params => getMetricsByType(params),
	getMetrics: params => getMetrics(params),
};
