import { useEffect, useMemo } from 'react';
import { fromSectionService, getSectionByPath } from 'services/sectionService';
import { translations } from 'translation/en';
import { showToast } from 'utils/toastUtil';
import { useRequest } from './useRequest';

export const useSectionByPath = () => {
	const {
		loading: loadingSection,
		data: response,
		error,
		run: fetchSection,
		clear: clearSection,
	} = useRequest(params =>
		getSectionByPath({
			...params,
		})
	);

	useEffect(() => {
		if (error) {
			showToast({ type: 'error', message: translations.FAILED_FETCH_ARTICLE });
		}
	}, [error]);

	const section = useMemo(() => {
		return response?.data?.data?.getSectionByPath?.items?.map(fromSectionService)?.shift();
	}, [response]);

	const sectionParagraphs = useMemo(() => {
		return section?.paragraphs?.sort((item1, item2) => +item1.displayOrder - +item2.displayOrder);
	}, [section]);

	return { loadingSection, section, sectionParagraphs, fetchSection, clearSection };
};
