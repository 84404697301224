import ParkHyattWhiteLogo from 'assets/img/logos/park-white.svg';
import ParkHyattLogo from 'assets/img/logos/park.svg';
import complementaryTheme from 'themes/complementary/complementary';
import colors from './parkHyattColors';
import typographyTheme from './parkHyattTypo';

const logo = {
	footer: ParkHyattLogo,
	loading: ParkHyattLogo, // logo in the loading
	sidebar: ParkHyattWhiteLogo,
	mobile: ParkHyattWhiteLogo,
	size: {
		sidebar: {
			width: '135px',
			height: '144px',
		},
		footer: {
			width: '140px',
			height: '40px',
		},
		navbar: {
			width: '130px',
			height: '144px',
		},
		loading: {
			width: '140px',
			height: '135px',
		},
	},
};

const copyrightText = {
	padding: '5px 0 5px 14px',
};

const parkHyatt = {
	brandId: 'ph',
	name: 'Park Hyatt',
	logo,
	color: colors,
	copyrightText,

	font: {
		family: {
			helveticaNeue: 'Helvetica Neue',
			bembo: 'Bembo',
			futuraBook: 'Futura Book',
			gothamLight: 'Gotham Light',
			arialSansSerif: 'Arial, Helvetica, sans-serif',
			poynterRegular: 'Poynter Regular',
			calibri: 'Calibri, Arial',
			didot: 'Didot',
			TheanoDidotRegular: 'TheanoDidot Regular',
			gothamBook: 'Gotham Book',
			robotoMonoRegular: 'RobotoMono Regular',
		},
	},
};

const parkHyattTheme = {
	...parkHyatt,
	...typographyTheme(parkHyatt),
	...complementaryTheme,
};

export default parkHyattTheme;
