import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Button } from 'components/button';
import { Modal } from 'components/modals/modal';
import { useTheme } from 'styled-components';
import { CloseIcon } from 'components/modals/modal/Modal.style';
import { translations } from 'translation/en';
import { Loading } from 'components/loadingOverlay';
import * as S from './PublishBrandModal.style';

const PublishBrandModal = ({ open, title, description, onSubmit, onClose, isLoadingPublishBrand }) => {
	const portalElement = document.getElementById('root');
	const [domReady, setDomReady] = useState(false);
	const { logo } = useTheme();

	useEffect(() => {
		setDomReady(true);

		return () => {
			setDomReady(false);
		};
	}, []);

	const modalComponent = open && (
		<S.PublishBrandModal open={open} closeIcon={<CloseIcon onClick={onClose}>&times;</CloseIcon>}>
			{isLoadingPublishBrand && (
				<Loading.Overlay>
					<Loading.LoadingLogo logo={logo?.loading} size={logo?.size.loading} />
				</Loading.Overlay>
			)}
			<Modal.Header />
			<Modal.Main>
				<S.Title>{title}</S.Title>
				<S.Description>{description}</S.Description>
			</Modal.Main>
			<Modal.Footer style={{ background: '#fff' }}>
				<Button variant="secondary-03" onClick={onSubmit}>
					{translations.PUBLISH}
				</Button>
			</Modal.Footer>
		</S.PublishBrandModal>
	);

	return domReady ? ReactDOM.createPortal(modalComponent, portalElement) : null;
};

PublishBrandModal.propTypes = {
	open: PropTypes.bool,
	title: PropTypes.string,
	description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	onSubmit: PropTypes.func,
	onClose: PropTypes.func,
	isLoadingPublishBrand: PropTypes.bool,
};

PublishBrandModal.defaultProps = {
	open: false,
	title: '',
	description: '',
	onSubmit: () => {},
	onClose: () => {},
	isLoadingPublishBrand: false,
};

export { PublishBrandModal };
