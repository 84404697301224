import PlaceholderSrc181x150 from 'assets/img/placeholders/181x150.jpg';
import PlaceholderSrc786x160 from 'assets/img/placeholders/786x160.jpg';
import { EDIT_MENU_TYPES } from 'components/begEditMenu/constants/begEditMenuConstants';
import { ImageBox } from 'components/imageBox';
import {
	EditableContentWrapper,
	EditableTitleWrapper,
} from 'containers/brandDetails/components/brandMain/components/editableContent';
import { SpecificationDropDown } from 'containers/brandDetails/components/brandMain/components/specificationDropDown';
import {
	Container,
	Content,
	Wrapper,
} from 'containers/brandDetails/components/brandMain/components/template/ModuleTemplate.style';
import { DropDown } from 'containers/brandDetails/components/dropdown';
import {
	DESCRIPTION_FIELD,
	EDITABLE_DESCRIPTION_TEST_ID,
	EDITABLE_DROPDOWN_SPECIFICATION_TEST_ID,
	EDITABLE_SPECIFICATION_TEST_ID,
	EDITABLE_TITLE_TEST_ID,
	SPECIFICATION_FIELD,
	TITLE_FIELD,
	TYPE_MODULE_TEXT_FOUR_VARIABLE_IMAGES,
} from 'containers/brandDetails/constants/modulesTypesConstants';
import { useEditMenuData } from 'hooks/useEditMenuData';
import PropTypes, { arrayOf, shape } from 'prop-types';
import { useState, useMemo, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { translations } from 'translation/en';
import { CAPTION_JUSTIFICATION, OVERLAY_JUSTIFICATION } from 'components/imageBox/constants/imageBoxConstants';
import useClearCache from 'hooks/useClearCache';
import * as S from './TYPE-4-VARIABLE-IMAGES.style';
import { ListFiles } from '../../listFiles';
import { DisclaimerEditInLine } from '../disclaimerEditInLine';
import { SpecificationLabel } from '../../specificationDropDown/SpecificationDropDown.style';

const PLACEHOLDER_SOURCES = [PlaceholderSrc786x160, PlaceholderSrc181x150, PlaceholderSrc181x150, PlaceholderSrc786x160];

const Type4VariableImages = ({
	startTitle,
	startDescription,
	startSpecification,
	startDisclaimer,
	startIsDropdown,
	keywords,
	imageData,
	path,
	isOnPrintPage,
	...props
}) => {
	const initialImage = useMemo(
		() => ({
			textOverlay: '',
			imageCaption: '',
			linkTo: '',
			textOverlayPosition: OVERLAY_JUSTIFICATION.LEFT.value,
			captionPosition: CAPTION_JUSTIFICATION.LEFT.value,
			relatedPath: path,
		}),
		[path]
	);

	const { color, typo } = useTheme();
	const { selectedToEdit } = useEditMenuData();
	const { clearPageCache } = useClearCache();

	const [title, setTitle] = useState(startTitle || '');
	const [description, setDescription] = useState(startDescription || '');
	const [specification, setSpecification] = useState(startSpecification);
	const [disclaimer, setDisclaimer] = useState(startDisclaimer);
	const [startKeywords, setStartKeywords] = useState(keywords || []);
	const [isDropdown, setIsDropDown] = useState(startIsDropdown || false);

	const defaultImages = useMemo(
		() =>
			[...Array(4).keys()].map(i => {
				const initialData = { ...initialImage, displayOrder: i + 1 };
				const finderImage = imageData?.find(img => +img.displayOrder === +initialData.displayOrder);

				const file = PLACEHOLDER_SOURCES[i];

				const image = {
					...initialData,
					...finderImage,
					textOverlayPosition: finderImage?.textOverlayPosition || initialData.textOverlayPosition,
					captionPosition: finderImage?.captionPosition || initialData.captionPosition,
					file,
				};

				return image;
			}),
		[imageData, initialImage]
	);

	const [imageGallery, setImageGallery] = useState(defaultImages);

	useEffect(() => {
		setImageGallery(defaultImages);
	}, [setImageGallery, defaultImages]);

	const handleSaveImage = payload => {
		clearPageCache();
		setImageGallery(prevArray => {
			const newArray = [
				...prevArray.slice(0, +payload.displayOrder - 1),
				payload,
				...prevArray.slice(+payload.displayOrder),
			];
			return newArray;
		});
	};

	const handleDeleteImage = payload => {
		clearPageCache();
		setImageGallery(prevArray => {
			return prevArray.map(item => {
				if (+item.displayOrder === +payload.displayOrder) {
					const { displayOrder } = payload;
					const file = PLACEHOLDER_SOURCES[+displayOrder - 1];

					return {
						...initialImage,
						displayOrder,
						file,
					};
				}

				return item;
			});
		});
	};

	const renderTitleComponent = () => (
		<Wrapper>
			<EditableTitleWrapper
				fieldName={TITLE_FIELD}
				placeholder={translations.INSERT_TITLE_HERE}
				content={title}
				setContent={setTitle}
				startKeywords={startKeywords}
				setStartKeywords={setStartKeywords}
				modulePath={path}
				isCollapsable={isDropdown}
				setIsCollapsable={setIsDropDown}
				data-testid={EDITABLE_TITLE_TEST_ID}
				{...props}
			/>
		</Wrapper>
	);

	const renderImageBox = (item, style) => (
		<ImageBox
			imageProps={item}
			imageStyle={style}
			onSave={handleSaveImage}
			onDelete={handleDeleteImage}
			isOnPrintPage={isOnPrintPage}
			{...props}
		/>
	);

	const renderSpecificationComponent = () =>
		isOnPrintPage ? (
			<S.SpecificationOnPrintPage>
				<SpecificationLabel>{translations.SPECIFICATIONS_TEXT}</SpecificationLabel>
				<EditableContentWrapper
					style={{ margin: '10px' }}
					fieldName={SPECIFICATION_FIELD}
					placeholder={translations.INSERT_SPECIFICATION_HERE}
					content={specification}
					setContent={setSpecification}
					modulePath={path}
					data-testid={EDITABLE_SPECIFICATION_TEST_ID}
					{...props}
				/>
			</S.SpecificationOnPrintPage>
		) : (
			<SpecificationDropDown
				content={specification}
				setContent={setSpecification}
				modulePath={path}
				data-testid={EDITABLE_DROPDOWN_SPECIFICATION_TEST_ID}
				{...props}
			/>
		);

	const renderContentComponent = () => (
		<Container>
			<Content>
				<EditableContentWrapper
					style={{ marginBottom: '15px', height: 'fit-content' }}
					fieldName={DESCRIPTION_FIELD}
					placeholder={translations.INSERT_DESCRIPTION_HERE}
					content={description}
					setContent={setDescription}
					modulePath={path}
					data-testid={EDITABLE_DESCRIPTION_TEST_ID}
					renderSpecification={
						(selectedToEdit === EDIT_MENU_TYPES.EDIT_INLINE || specification) && renderSpecificationComponent()
					}
					{...props}
				>
					<S.Box id={`${path}-gallery`}>
						<S.Row>
							{imageGallery.slice(0, 2).map((item, index) => (
								<div key={`${item.displayOrder}${item.imageVcmId}`}>
									{index === 0 &&
										renderImageBox(item, {
											width: '770px',
											height: '150px',
											objectFit: 'cover',
										})}
									{index === 1 &&
										renderImageBox(item, {
											width: '330px',
											height: '150px',
											objectFit: 'cover',
										})}
								</div>
							))}
						</S.Row>
						<S.Row>
							{imageGallery.slice(2, 4).map((item, index) => (
								<div key={`${item.displayOrder}${item.imageVcmId}`}>
									{index === 0 &&
										renderImageBox(item, {
											width: '330px',
											height: '150px',
											objectFit: 'cover',
										})}
									{index === 1 &&
										renderImageBox(item, {
											width: '770px',
											height: '150px',
											objectFit: 'cover',
										})}
								</div>
							))}
						</S.Row>
					</S.Box>
				</EditableContentWrapper>
				<ListFiles {...props} />
				{disclaimer && (
					<DisclaimerEditInLine disclaimer={disclaimer} setDisclaimer={setDisclaimer} modulePath={path} {...props} />
				)}
			</Content>
		</Container>
	);

	const renderTitleWithContentComponent = () => (
		<S.Wrapper>
			{renderTitleComponent()}
			{renderContentComponent()}
		</S.Wrapper>
	);

	const renderDropDownContentComponent = () => (
		<DropDown
			label={renderTitleComponent()}
			iconClickOnly={selectedToEdit === EDIT_MENU_TYPES.EDIT_INLINE}
			icon="icon-chevron-down"
			wrapperStyle={{
				padding: 0,
				color: color.text.secondary02,
				display: 'flex',
				alignItems: 'baseline',
				justifyContent: 'space-between',
				width: '100%',
				...typo.headline['02'],
			}}
		>
			{renderContentComponent()}
		</DropDown>
	);

	return (
		<div role={TYPE_MODULE_TEXT_FOUR_VARIABLE_IMAGES}>
			{isDropdown ? renderDropDownContentComponent() : renderTitleWithContentComponent()}
		</div>
	);
};

Type4VariableImages.propTypes = {
	startTitle: PropTypes.string,
	startDescription: PropTypes.string,
	startDisclaimer: PropTypes.string,
	startSpecification: PropTypes.string,
	keywords: PropTypes.arrayOf(PropTypes.string),
	path: PropTypes.string.isRequired,

	startIsDropdown: PropTypes.bool,
	isOnPrintPage: PropTypes.bool,
	imageData: arrayOf(
		shape({
			imagePath: PropTypes.string,
			textOverlay: PropTypes.string,
			textOverlayPosition: PropTypes.string,
			imageTitle: PropTypes.string,
		})
	),
};

Type4VariableImages.defaultProps = {
	startTitle: '',
	startDescription: '',
	startSpecification: '',
	startDisclaimer: '',
	keywords: [],
	startIsDropdown: false,
	isOnPrintPage: false,
	imageData: null,
};

export { Type4VariableImages };
