export const CHAPTERS_TYPE = 'chapters';

export const PAGE_TYPES = {
	CHAPTER_TYPE: 'chapter',
	SECTION_TYPE: 'section',
	ARTICLE_TYPE: 'article',

	PAGE_TYPE: 'page',

	CHAPTER_TEST_ID: 'chapter-test-id',
	SECTION_TEST_ID: 'section-test-id',
	ARTICLE_TEST_ID: 'article-test-id',

	CHAPTER_CONTENT_FK_PROP: 'BEG_CHAPTER_CONTENT_FK_BEG_CHAPTER_ID',
	SECTION_CONTENT_FK_PROP: 'BEG_SECTION_CONTENT_FK_BEG_SECTION_ID',
	ARTICLE_CONTENT_FK_PROP: 'BEG_ARTICLE_CONTENT_FK_BEG_ARTICLE_ID',

	DISPLAY_ORDER_KEY_PROP: 'displayOrder',

	DISPLAY_ORDER_CHAPTER: 'BEG_CHAPTER_DISPLAY_ORDER',
	DISPLAY_ORDER_SECTION: 'BEG_CHAPTER_RELATOR_SECTION_DISPLAY_ORDER',
	DISPLAY_ORDER_ARTICLE: 'BEG_SECTION_RELATOR_ARTICLE_DISPLAY_ORDER',
};
