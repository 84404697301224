import { useSelector } from 'react-redux';

export const useGroupsAndDepartments = () => {
	const { adGroups, adDepartments } = useSelector(state => state.security);

	const sortedAdGroups = adGroups.slice().sort((a, b) => a.title.localeCompare(b.title));
	const sortedAdDepartments = adDepartments.slice().sort((a, b) => a.title.localeCompare(b.title));

	return {
		adGroups: sortedAdGroups,
		adDepartments: sortedAdDepartments,
	};
};
