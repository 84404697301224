/* eslint-disable no-await-in-loop */
/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
import { useCallback } from 'react';
import { useRequest } from 'hooks/useRequest';
import useBrandData from 'hooks/useBrandData';
import { putDisplayOrderContentFragment } from 'services/contentFragmentService';
import { isNumber } from 'utils/numberUtil';
import {
	fixedProblematicItems,
	getName,
	getParentPath,
	getSortOrderAttribute,
	updateContentFragmentBaseObj,
} from 'utils/contentFragmentUtil';
import { getISONow } from 'utils/dateUtil';
import { CHAPTERS_TYPE, PAGE_TYPES } from 'constants/pageConstants';
import { MODELS_PATHS } from 'constants/aemConstants';

export const useFixChaptersDisplayOrder = () => {
	const { brand } = useBrandData();
	const { loading, data: response, error, run } = useRequest(putDisplayOrderContentFragment);

	const fetchUpdateDisplayOrder = useCallback(
		fixedItems => {
			let updateContentFragmentObj = {};

			fixedItems.map(async item => {
				switch (fixedItems[0].type) {
					case PAGE_TYPES.SECTION_TYPE:
						updateContentFragmentObj = updateContentFragmentBaseObj({
							name: getName(item?.relatorPath),
							parentPath: getParentPath(item?.relatorPath),
							model: MODELS_PATHS.RELATOR_SECTIONS,
							master: {
								MODIFICATION_DATE: getISONow(),
								BEG_CHAPTER_RELATOR_SECTION_DISPLAY_ORDER: item?.displayOrder,
							},
						});
						break;
					case PAGE_TYPES.ARTICLE_TYPE:
						updateContentFragmentObj = updateContentFragmentBaseObj({
							name: getName(item?.relatorPath),
							parentPath: getParentPath(item?.relatorPath),
							model: MODELS_PATHS.RELATOR_ARTICLES,
							master: {
								MODIFICATION_DATE: getISONow(),
								BEG_SECTION_RELATOR_ARTICLE_DISPLAY_ORDER: item?.displayOrder,
							},
						});
						break;
					default:
						updateContentFragmentObj = updateContentFragmentBaseObj({
							name: getName(item.path),
							parentPath: getParentPath(item.path),
							model: MODELS_PATHS.CHAPTER,
							master: {
								MODIFICATION_DATE: getISONow(),
								BEG_CHAPTER_DISPLAY_ORDER: item.displayOrder,
							},
						});
						break;
				}

				await run({
					cfType: CHAPTERS_TYPE,
					pagePath: brand.parentPath,
					data: updateContentFragmentObj,
				});
			});

			return null;
		},
		[brand, run]
	);

	const checkIfExistItemToFix = async (collection, keyProperty) => {
		const mapDisplayOrder = collection?.reduce((acc, item) => {
			acc[item[keyProperty]] = [...(acc[item[keyProperty]] || []), item];
			return acc;
		}, {});

		const values = Object?.values(mapDisplayOrder);

		const shouldReorder =
			// if any of the values has more than one item or repeated items
			values.some(val => val.length > 1) ||
			// if any of the items does not have a sortOrder attribute
			collection.some(item => !isNumber(getSortOrderAttribute(item, keyProperty)));

		if (shouldReorder) {
			const { replacedItems, problematicItems } = fixedProblematicItems(collection, keyProperty);

			fetchUpdateDisplayOrder(problematicItems);

			return replacedItems;
		}

		return collection;
	};

	const fetchFixedDisplayOrder = async chapters => {
		if (!chapters?.length) return [];

		const collection = await checkIfExistItemToFix(chapters, PAGE_TYPES.DISPLAY_ORDER_KEY_PROP);

		for (const chapter of chapters) {
			const { sections } = chapter;

			if (!sections?.length) {
				continue;
			}

			await checkIfExistItemToFix(sections, PAGE_TYPES.DISPLAY_ORDER_KEY_PROP);

			for (const section of sections) {
				const { articles } = section;

				if (!articles?.length) {
					continue;
				}

				await checkIfExistItemToFix(articles, PAGE_TYPES.DISPLAY_ORDER_KEY_PROP);
			}
		}

		return collection;
	};

	return { loading, response, error, fetchFixedDisplayOrder };
};
