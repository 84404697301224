import { Button } from 'components/button';
import { useOnOutsideClick } from 'hooks/useOnOutsideClick';
import useToggle from 'hooks/useToggle';
import PropTypes from 'prop-types';
import React, { useCallback, useRef } from 'react';
import { childrenWithProps } from 'utils/childrenUtil';
import { CollapseItem } from './collapseItem';
import * as S from './Collapse.style';

const Collapse = ({
	label,
	icon,
	children,
	inverted,
	wrapperStyle,
	iconOutlined,
	closeOnOutsideClick,
	iconClickOnly,
	initialOpen,
}) => {
	const collapseRef = useRef();
	const [show, setToggle] = useToggle(initialOpen);

	const handleOpen = useCallback(
		e => {
			e.stopPropagation();
			setToggle();
		},
		[setToggle]
	);

	const handleClose = useCallback(
		e => {
			e.stopPropagation();
			if (show) {
				setToggle();
			}
		},
		[show, setToggle]
	);

	useOnOutsideClick({ ref: collapseRef, action: closeOnOutsideClick ? handleClose : () => {} });

	const renderLabelComponent = () =>
		label && (
			<S.Wrapper inverted={inverted} style={wrapperStyle}>
				{label}
				<div onClick={iconClickOnly ? handleOpen : null} role="button" aria-label="open collapse" tabIndex={0}>
					{typeof icon === 'string' ? <S.Icon className={`icon ${icon}`} isExpanded={show} /> : icon}
				</div>
			</S.Wrapper>
		);

	const renderIconComponent = () =>
		!label &&
		icon && <Button onClick={iconClickOnly ? handleOpen : null} variant={iconOutlined ? 'outlined' : 'icon'} icon={icon} />;

	return (
		<S.Collapse>
			<S.Container ref={collapseRef}>
				<S.Head onClick={!iconClickOnly ? handleOpen : null}>
					{renderLabelComponent()}
					{renderIconComponent()}
				</S.Head>
				<S.Body className={show ? 'expanded' : ''}>{childrenWithProps(children, { handleClose })}</S.Body>
			</S.Container>
		</S.Collapse>
	);
};

Collapse.propTypes = {
	label: PropTypes.node,
	icon: PropTypes.node || PropTypes.string,
	wrapperStyle: PropTypes.objectOf(Object),
	inverted: PropTypes.bool,
	closeOnOutsideClick: PropTypes.bool,
	iconOutlined: PropTypes.bool,
	iconClickOnly: PropTypes.bool,
	children: PropTypes.node.isRequired,
	initialOpen: PropTypes.bool,
};

Collapse.defaultProps = {
	label: '',
	wrapperStyle: null,
	inverted: false,
	closeOnOutsideClick: false,
	iconOutlined: false,
	iconClickOnly: false,
	icon: null,
	initialOpen: false,
};

Collapse.CollapseItem = CollapseItem;

export { Collapse };
