import { MODELS_PATHS } from 'constants/aemConstants';
import { ADMIN_GROUP } from 'constants/ldapGroupsConstants';
import { employeeType as employeeTypes } from '../constants/userConstants';

export const isEmployee = user => [employeeTypes.EMPLOYEE].includes(user?.employeeType?.toUpperCase());

export const isFranchise = user =>
	[employeeTypes.FRANCHISE, employeeTypes.FRANCHISEE].includes(user?.employeeType?.toUpperCase());

export const isContractor = user => [employeeTypes.CONTRACTOR].includes(user?.employeeType?.toUpperCase());

export const isNotContractor = user => !isContractor(user);

export const isContractorWithoutAccessGroup = user => isContractor(user) && !user.hasContactorAccessGroup;

export const isAdmin = user => user?.memberOf?.includes(ADMIN_GROUP);

export const getRoleStatus = user => employeeTypes[user?.employeeType?.toUpperCase()];

export const getVisibleRole = user => {
	const prefix = 'VISIBLE_TO_';

	if (getRoleStatus(user) === employeeTypes.CONTRACTOR) {
		return prefix + employeeTypes.CONTRACTOR;
	}

	const visibleParam = isFranchise(user) ? employeeTypes.FRANCHISE : employeeTypes.EMPLOYEE;

	return prefix + visibleParam;
};

const checkFilter = (jsonValue = '', userValue = '') => {
	if (userValue === '' || jsonValue === '') {
		return false;
	}
	const jsonArray = jsonValue.split(',');
	const userArray = userValue.split(',');

	return jsonArray.some(value => userArray.includes(value));
};

/**
 * @param {{ jobLevelCode: string, location: string, memberOf: string, departmentName: string,
 * brandId: string, locationObj: Object  }} user
 * @param {{ jobLevel: string, isVisibleToFranchise: string, groups: string,
 *  departments: string, parentContentPath: string, cqModel: string  }} content
 * @returns {boolean}
 */

export const isUserAllowedToSeeContent = (user, content) => {
	let hasPermission = false;
	const isFranchiseUser = isFranchise(user);
	const franchiseeHasVisibleChapter = isFranchiseUser && content.isVisibleToFranchise === 'true';
	const isContractorUser = isContractor(user);
	const isJobLevelAll = content.jobLevel?.toLowerCase().indexOf('all') !== -1;
	const hasJobLevelPermission =
		checkFilter(content.jobLevel, user.jobLevelCode) || checkFilter(content.jobLevel, user.location);
	const hasChapterGroupPermission = checkFilter(content.groups?.join(','), user.memberOf[0]);
	const hasDepartmentPermission = checkFilter(content.departments?.join(','), user.departmentName);
	const isBrandCorporate = user.brandId === '71';

	if (franchiseeHasVisibleChapter) {
		if (hasJobLevelPermission || isJobLevelAll || hasChapterGroupPermission || hasDepartmentPermission || isBrandCorporate) {
			hasPermission = true;
		}
	} else if (isContractorUser) {
		if (isJobLevelAll || hasJobLevelPermission || hasChapterGroupPermission) {
			hasPermission = true;
		}
	} else if (
		hasJobLevelPermission ||
		isJobLevelAll ||
		hasChapterGroupPermission ||
		hasDepartmentPermission ||
		isBrandCorporate
	) {
		hasPermission = true;
	}

	const hasParent = content.parentContentPath || content.cqModel === MODELS_PATHS.CHAPTER;

	return hasPermission && hasParent;
};

export const userAndContentRegionFilter = (user, content) => {
	// TODO: change to check regionId instead of region name
	const contentRegion = content?.regions?.map(region => region?.toLowerCase());
	// TODO: Remove this specific replace after change for region id
	const userRegion = user?.locationObj?.regionId?.toLowerCase();
	const doesRegionMatch = contentRegion?.some(region => userRegion?.includes(region));
	return !contentRegion || !userRegion || doesRegionMatch;
};
