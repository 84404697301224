import styled from 'styled-components/macro';

const variantStyle = ({ variant = 'primary', color, border }) => {
	switch (variant) {
		case 'primary':
			return {
				background: color.interactive.primary.default.background,
				border: `${border.width.md} solid ${color.interactive.primary.default.border}`,
				borderRadius: border.radius.none,
				transition: 'all .3s ease',

				'&:hover': {
					background: color.interactive.primary.hover.background,
					border: `${border.width.md} solid ${color.interactive.primary.hover.border}`,

					'*': {
						color: color.interactive.primary.hover.label,
					},
				},

				'&:disabled': {
					cursor: 'not-allowed !important',
					opacity: '0.65',
				},
			};
		case 'primary-02':
			return {
				padding: '5px 10px',
				background: color.interactive.primary02.default.background,
				border: `${border.width.md} solid ${color.interactive.primary02.default.border}`,
				borderRadius: border.radius.none,
				transition: 'all .3s ease',

				'&:disabled': {
					cursor: 'not-allowed !important',
					opacity: `${color.interactive.primary02.default.opacity}`,
				},
			};
		case 'primary-03':
			return {
				background: color.interactive.primary.default.background,
				border: `${border.width.md} solid ${color.interactive.primary.default.border}`,
				borderRadius: border.radius.none,
				transition: 'all .3s ease',
				height: '31px',
				maxHeight: '31px',

				'&:hover': {
					background: color.interactive.primary.hover.background,
					border: `${border.width.md} solid ${color.interactive.primary.hover.border}`,

					'*': {
						color: color.interactive.primary.hover.label,
					},
				},
			};
		case 'primary-04':
			return {
				background: color.interactive.discardChangesButtons?.default.background,
				border: `${border.width.md} solid ${color.interactive.discardChangesButtons?.default.border}`,
				borderRadius: border.radius.none,
				transition: 'all .3s ease',

				'&:hover': {
					background: color.interactive.discardChangesButtons?.hover.background,
					border: `${border.width.md} solid ${color.interactive.discardChangesButtons?.hover.border}`,

					'*': {
						color: color.interactive.discardChangesButtons?.hover.label,
					},
				},
			};
		case 'primary-05':
			return {
				padding: '15px 40px',
				background: color.interactive.primary05.default.background,
				border: `${border.width.md} solid ${color.interactive.primary05.default.border}`,
				borderRadius: border.radius.none,
				transition: 'all .3s ease',

				'&:hover': {
					background: color.interactive.primary05.hover.background,
					border: `${border.width.md} solid ${color.interactive.primary05.hover.border}`,

					'*': {
						color: color.interactive.primary05.hover.label,
					},
				},
			};
		case 'secondary':
			return {
				background: color.interactive.secondary.default.background,
				border: `${border.width.md} solid ${color.interactive.secondary.default.border}`,
				borderRadius: border.radius.none,

				'&:hover, &:focus': {
					background: color.interactive.secondary.hover.background,
					border: `${border.width.md} solid ${color.interactive.secondary.hover.border}`,

					'*': {
						color: color.interactive.secondary.hover.label,
					},
				},
			};
		case 'secondary-02':
			return {
				background: color.interactive.secondary02.default.background,
				border: border.width.none,
				width: '160px',
				height: '41px',
				maxHeight: '41px',
				transition: 'all .3s ease',

				'&:hover, &:focus': {
					background: color.interactive.lightest,

					'*': {
						color: color.interactive.secondary02.hover.label,
					},
				},
			};
		case 'secondary-03':
			return {
				margin: '0 5px',
				padding: '10px 30px',
				background: color.interactive.secondary03.default.background,
				border: `${border.width.md} solid ${color.interactive.secondary03.default.border}`,
				color: color.interactive.secondary03.default.label,
				borderRadius: border.radius.none,
				transition: 'all .3s ease',

				'&:hover, &:focus': {
					background: color.interactive.secondary03.hover.background,
					border: `${border.width.md} solid ${color.interactive.secondary03.hover.border}`,

					'*': {
						color: color.interactive.secondary03.hover.label,
					},
				},

				'&:disabled': {
					cursor: 'not-allowed !important',
					opacity: '0.4',
					'&:hover, &:focus': {
						background: color.interactive.secondary03.default.background,
						border: `${border.width.md} solid ${color.interactive.secondary03.default.border}`,
						'*': {
							color: color.interactive.secondary03.default.label,
						},
					},
				},
			};
		case 'tertiary':
			return {
				padding: 0,
				background: color.interactive.tertiary.default.background,
				border: border.width?.none,
				borderRadius: border.radius?.circular,
				width: '22px',
				height: '22px',
			};
		case 'tertiary-02':
			return {
				padding: '6px 12px',
				background: color.interactive.printModalButtons?.default.background,
				border: `${border.width.md} solid ${color.interactive.printModalButtons?.default.border}`,
				borderRadius: '3px',
				transition: 'all .3s ease',

				i: {
					marginRight: '5px',
				},

				'&:hover': {
					background: color.interactive.printModalButtons?.hover.background,
					border: `${border.width.md} solid ${color.interactive.printModalButtons?.hover.border}`,

					'*': {
						color: color.interactive.printModalButtons?.hover.label,
					},
				},
			};
		case 'tertiary-03':
			return {
				margin: '0 5px',
				padding: '10px 30px',
				background: color.interactive.tertiary03.default.background,
				border: `${border.width.sm} solid ${color.interactive.tertiary03.default.border}`,
				color: color.interactive.tertiary03.default.label,
				borderRadius: border.radius.none,
				transition: 'all .3s ease',

				'&:disabled': {
					cursor: 'not-allowed !important',
					opacity: '0.65',
				},

				'&:hover, &:focus': {
					background: color.interactive.tertiary03.hover.background,
					border: `${border.width.sm} solid ${color.interactive.tertiary03.hover.border}`,

					'*': {
						color: color.interactive.tertiary03.hover.label,
					},
				},
			};
		case 'outlined':
			return {
				padding: 0,
				border: `${border.width.sm} solid ${color.interactive.outlined.default.border}`,
				borderRadius: border.radius['3xs'],
				width: '38px',
				height: '38px',
				background: 'none',
			};
		case 'icon':
			return {
				padding: 0,
				width: '38px',
				height: '38px',
				background: 'none',
			};
		case 'gray-border':
		case 'gray-border-secondary':
			return {
				margin: '0 5px',
				padding: '12px 18px',
				background: `${color.neutral.white}`,
				border: `1px solid ${color.neutral['50']}`,
				borderRadius: border.radius.none,
				color: `${color.neutral['65']}`,
				transition: 'all .3s ease',

				'&:hover, &:focus': {
					background: `${color.neutral.white}`,
					border: `1px solid ${color.neutral.black}`,

					'*': {
						color: `${color.neutral.black}`,
					},
				},
			};
		case 'gray-border-only':
			return {
				margin: '0 5px',
				padding: '10px 30px',
				background: `${color.neutral.white}`,
				border: `1px solid ${color.neutral['50']}`,
				borderRadius: border.radius.none,
				color: `${color.neutral.black}`,
				transition: 'all .3s ease',

				'&:disabled': {
					cursor: 'not-allowed !important',
					opacity: '0.65',
				},

				'&:hover, &:focus': {
					background: `${color.neutral.white}`,
					border: `1px solid ${color.neutral.black}`,
				},
			};
		case 'dark-gray':
			return {
				padding: '10px 30px',
				background: `${color.neutral['80']}`,
				color: `${color.neutral.white}`,
				border: `2px solid ${color.neutral['80']}`,
				transition: 'all .3s ease',

				'&:disabled': {
					cursor: 'not-allowed !important',
					opacity: '0.65',
				},

				'&:hover': {
					background: `${color.neutral.white}`,

					'*': {
						color: `${color.neutral['80']}`,
					},
				},
			};
		default:
			return {};
	}
};

export const Button = styled.button(({ theme: { color, border }, variant }) => ({
	display: 'inline-flex',
	position: 'relative',
	justifyContent: 'center',
	alignItems: 'center',
	textAlign: 'center',
	padding: '10px 30px',
	background: 'transparent',
	border: 'none',

	...variantStyle({ variant, color, border }),
}));
