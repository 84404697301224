import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { ModuleSkeleton } from '../moduleSkeleton';
import './PrintPageSkeleton.scss';

const PrintPageSkeleton = () => (
	<div className="PrintPageSkeleton">
		<div className="PrintPageSkeleton__button-action">
			<Skeleton className="PrintPageSkeleton__content" height={48} width={70} />
		</div>
		<ModuleSkeleton />
	</div>
);

export { PrintPageSkeleton };
