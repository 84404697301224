import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { useAppLoadData } from 'hooks/useAppLoadData';
import { authenticate } from 'utils/authUtil';
import { hasExternalUser } from 'utils/externalUserUtil';
import { hasAppValidToken } from 'utils/jwtUtil';
import Navigator from './router/components/Navigator';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

const App = () => {
	const { isReady, loadData } = useAppLoadData();

	useEffect(() => {
		if (hasAppValidToken() && !hasExternalUser()) {
			loadData();
		} else {
			authenticate();
		}
	}, [loadData]);

	return isReady ? (
		<div className="App" id="App">
			<Navigator />
			<ToastContainer />
		</div>
	) : null;
};

export default App;
