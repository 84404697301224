import { useEffect, useMemo } from 'react';
import { fromChapterService, getChapterByPath } from 'services/chapterService';
import { translations } from 'translation/en';
import { showToast } from 'utils/toastUtil';
import { useRequest } from './useRequest';

export const useChapterByPath = () => {
	const {
		loading: loadingChapter,
		data: response,
		error,
		run: fetchChapter,
		clear: clearChapter,
	} = useRequest(params =>
		getChapterByPath({
			...params,
		})
	);

	useEffect(() => {
		if (error) {
			showToast({ type: 'error', message: translations.FAILED_FETCH_ARTICLE });
		}
	}, [error]);

	const chapter = useMemo(() => {
		return response?.data?.data?.getChapterByPath?.items?.map(fromChapterService)?.shift();
	}, [response]);

	const chapterParagraphs = useMemo(() => {
		return chapter?.paragraphs?.sort((item1, item2) => +item1.displayOrder - +item2.displayOrder);
	}, [chapter]);

	return { loadingChapter, chapter, chapterParagraphs, fetchChapter, clearChapter };
};
