import { useEffect } from 'react';
import { postTermsAndConditions } from 'services/termsAndConditionsService';
import { translations } from 'translation/en';
import { showToast } from 'utils/toastUtil';
import { useAuthAccess, useAuthData } from './useAuthData';
import { useRequest } from './useRequest';

export const useAcceptTermsAndConditions = () => {
	const { user } = useAuthData();
	const { profileStatus } = useAuthAccess();
	const { loading: loadingAccept, data: response, error, run, clear } = useRequest(postTermsAndConditions);

	useEffect(() => {
		if (response) {
			clear();
		}
	}, [response, profileStatus, clear]);

	useEffect(() => {
		if (error) {
			showToast({ type: 'error', message: translations.SERVICE_FAILURE });
		}
	}, [error]);

	const acceptTermsAndConditions = () => {
		const { firstName, lastName, globalId, spiritCode, email } = user;

		return run({
			data: {
				firstName: firstName || '',
				lastName: lastName || '',
				globalId: globalId || '',
				spiritCode: spiritCode || '',
				email: email || '',
			},
		});
	};

	return { loadingAccept, response, error, acceptTermsAndConditions };
};
