import React, { useState } from 'react';
import { arrayOf, bool, func, node, shape, string } from 'prop-types';
import classNames from 'classnames';
import { EditMenuHeader } from '../editMenuHeader/EditMenuHeader';
import { EditMenuOption } from '../editMenuOption/EditMenuOption';
import './EditMenuGroup.scss';

export const EditMenuGroup = ({
	headerName,
	options,
	selectedOption,
	setSelectedOption,
	expandedIcon,
	collapsedIcon,
	isCollapsible,
	expandedCollapses,
}) => {
	const [isExpanded, setIsExpanded] = useState(expandedCollapses);

	return (
		<div
			className={classNames('EditMenuGroup', {
				'EditMenuGroup--collapsed': isCollapsible && !isExpanded,
				'EditMenuGroup--is-collapsible': isCollapsible,
			})}
			onClick={() => {
				if (isCollapsible) setIsExpanded(prev => !prev);
			}}
			tabIndex={0}
			role="button"
		>
			<EditMenuHeader>
				{headerName} {isCollapsible && (isExpanded ? expandedIcon : collapsedIcon)}
			</EditMenuHeader>
			<div className="EditMenuGroup__options">
				{options.map(({ label, icon, value, onClick, disabled, title, redBackground, ref, key, readOnly }) => (
					<EditMenuOption
						key={`${headerName}_${value || key}`}
						label={label}
						icon={icon}
						value={value}
						onClick={onClick}
						disabled={disabled}
						title={title}
						redBackground={redBackground}
						ref={ref}
						readOnly={readOnly}
						selectedOption={selectedOption}
						setSelectedOption={setSelectedOption}
					/>
				))}
			</div>
		</div>
	);
};

EditMenuGroup.propTypes = {
	headerName: string.isRequired,
	options: arrayOf(shape({})).isRequired,
	selectedOption: string,
	setSelectedOption: func,
	expandedIcon: node.isRequired,
	collapsedIcon: node.isRequired,
	isCollapsible: bool.isRequired,
	expandedCollapses: bool,
};

EditMenuGroup.defaultProps = {
	selectedOption: '',
	setSelectedOption: () => {},
	expandedCollapses: true,
};
