import { EDIT_MENU_TYPES } from 'components/begEditMenu/constants/begEditMenuConstants';
import { EditableContentWrapper } from 'containers/brandDetails/components/brandMain/components/editableContent';
import { SpecificationDropDown } from 'containers/brandDetails/components/brandMain/components/specificationDropDown';
import {
	DESCRIPTION_FIELD,
	EDITABLE_DESCRIPTION_TEST_ID,
	EDITABLE_DROPDOWN_SPECIFICATION_TEST_ID,
	EDITABLE_SPECIFICATION_TEST_ID,
	SPECIFICATION_FIELD,
	TYPE_MODULE_TEXT,
} from 'containers/brandDetails/constants/modulesTypesConstants';
import { useEditMenuData } from 'hooks/useEditMenuData';
import { bool, string } from 'prop-types';
import { useState } from 'react';
import { translations } from 'translation/en';
import { ListFiles } from '../../listFiles';
import { DisclaimerEditInLine } from '../disclaimerEditInLine';
import { SpecificationLabel } from '../../specificationDropDown/SpecificationDropDown.style';
import * as S from './IndexModule.style';

const IndexModule = ({ startDescription, startSpecification, startDisclaimer, path, isOnPrintPage, ...props }) => {
	const { selectedToEdit } = useEditMenuData();

	const [description, setDescription] = useState(startDescription || '');
	const [specification, setSpecification] = useState(startSpecification);
	const [disclaimer, setDisclaimer] = useState(startDisclaimer);

	const renderSpecificationComponent = () =>
		isOnPrintPage ? (
			<S.SpecificationOnPrintPage>
				<SpecificationLabel>{translations.SPECIFICATIONS_TEXT}</SpecificationLabel>
				<EditableContentWrapper
					style={{ margin: '10px' }}
					fieldName={SPECIFICATION_FIELD}
					placeholder={translations.INSERT_SPECIFICATION_HERE}
					content={specification}
					setContent={setSpecification}
					modulePath={path}
					data-testid={EDITABLE_SPECIFICATION_TEST_ID}
					{...props}
				/>
			</S.SpecificationOnPrintPage>
		) : (
			<SpecificationDropDown
				content={specification}
				setContent={setSpecification}
				modulePath={path}
				data-testid={EDITABLE_DROPDOWN_SPECIFICATION_TEST_ID}
				{...props}
			/>
		);
	return (
		<div role={TYPE_MODULE_TEXT}>
			<EditableContentWrapper
				style={{ marginBottom: '15px' }}
				fieldName={DESCRIPTION_FIELD}
				placeholder={translations.INSERT_DESCRIPTION_HERE}
				content={description}
				setContent={setDescription}
				modulePath={path}
				data-testid={EDITABLE_DESCRIPTION_TEST_ID}
				{...props}
			/>

			{(selectedToEdit === EDIT_MENU_TYPES.EDIT_INLINE || specification) && renderSpecificationComponent()}
			<ListFiles {...props} />
			{disclaimer && (
				<DisclaimerEditInLine disclaimer={disclaimer} setDisclaimer={setDisclaimer} modulePath={path} {...props} />
			)}
		</div>
	);
};

IndexModule.propTypes = {
	path: string.isRequired,
	paragraphId: string.isRequired,
	startDescription: string,
	startSpecification: string,
	startDisclaimer: string,
	isOnPrintPage: bool,
};

IndexModule.defaultProps = {
	startDescription: '',
	startSpecification: '',
	startDisclaimer: '',
	isOnPrintPage: false,
};

export { IndexModule };
