import { string } from 'prop-types';
import React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';

const Helmet = ({ title }) => {
	return <ReactHelmet title={title} />;
};

Helmet.propTypes = {
	title: string,
};

Helmet.defaultProps = {
	title: 'Brand Experience Guide',
};

export default Helmet;
