import styled from 'styled-components/macro';

export const Gallery = styled.div(() => ({
	display: 'grid',
	gridTemplateColumns: 'auto auto',
}));

export const Wrapper = styled.div(() => ({
	display: 'flex',
	justifyContent: 'space-between',
	gap: '10px',
	width: '100%',
	height: 'auto',
}));

export const Grid = styled.div(() => ({
	display: 'flex',
	gridTemplateColumns: 'repeat(2, 1fr)',
	gap: '10px',
	justifyContent: 'space-between',
}));

export const SpecificationOnPrintPage = styled.div(() => ({
	display: 'flex',
	flexWrap: 'wrap',
	width: '100%',
}));
