import { useEffect, useMemo, useState, useRef, useCallback } from 'react';
import { getReplicateStatusByOperationId, publishApprovedAsync } from 'API/publishAPI';
import { useUnpublishPage } from 'hooks/useUnpublishPage';
import { usePutContentFragment } from 'hooks/usePutContentFragment';
import { useDeleteContentFragment } from 'hooks/useDeleteContentFragment';
import { showToast } from 'utils/toastUtil';
import { extractContentFromPath, extractModelFromPath, getCurrentRelatorPathFromCurrentTypeToPublish } from 'utils/publishUtil';
import { isEmpty } from 'utils/arrayUtil';
import { MODELS_PATHS } from 'constants/aemConstants';
import { translations } from 'translation/en';
import { useRequest } from './useRequest';
import useBrandDetails from './useBrandDetails';
import useApprovePublish from './useApprovePublish';
import useClearCache from './useClearCache';

const FIVE_SECONDS = 5000;

export const usePublishPage = () => {
	const initialState = useMemo(
		() => ({
			loading: false,
			data: null,
			error: null,
		}),
		[]
	);

	const { currentType } = useBrandDetails();
	const { unpublishPage } = useUnpublishPage();
	const { updateContentFragment } = usePutContentFragment();
	const { removeContentFragment } = useDeleteContentFragment();
	const { isPageApproved, setIsPagePublished, handleStaleStatus } = useApprovePublish();
	const [unpublishLaterArray, setUnpublishLaterArray] = useState([]);

	const { loading: loadingPublish, error: publishError, run: postPublish } = useRequest(publishApprovedAsync);
	const {
		loading: loadingReplicateStatus,
		error: replicateStatusError,
		run: fetchReplicateStatus,
	} = useRequest(getReplicateStatusByOperationId);

	const intervalRef = useRef();
	const [requestData, setRequestData] = useState(initialState);
	const { clearCache, clearCacheDelivery } = useClearCache();

	const updateContentArrayUnpublishLater = useCallback(
		contentPathToUnpublishAndDelete => {
			const unpublishLaterNewArray = unpublishLaterArray.filter(item => item !== contentPathToUnpublishAndDelete);

			const { type, pagePath, path } = currentType;
			const modelPath = MODELS_PATHS[type.toUpperCase()];
			const shouldApprove = true;

			setUnpublishLaterArray(unpublishLaterNewArray);

			updateContentFragment({
				payload: { unpublishLater: [unpublishLaterNewArray] },
				path,
				model: modelPath,
				cfType: modelPath,
				pagePath,
				shouldApprove,
			});
		},
		[currentType, unpublishLaterArray, updateContentFragment]
	);

	const handleArrayContentUnpublishLater = useCallback(
		content => {
			const { unpublishLater: contentArrayToUnpublish } = content;

			if (contentArrayToUnpublish) {
				setUnpublishLaterArray(contentArrayToUnpublish);

				contentArrayToUnpublish.forEach(contentPathToUnpublishAndDelete => {
					const isUnpublishLater = true;

					const pagePath = currentType?.path;
					const contentModel = extractModelFromPath(contentPathToUnpublishAndDelete);
					const contentPath = extractContentFromPath(contentPathToUnpublishAndDelete);

					if (!contentModel) {
						return;
					}

					unpublishPage(contentPath, isUnpublishLater);

					try {
						removeContentFragment({
							pagePath,
							cfType: contentModel,
							data: { contentPath },
						}).then(() => {
							updateContentArrayUnpublishLater(contentPathToUnpublishAndDelete);
						});
					} catch (error) {
						showToast({ type: 'error', message: translations.UNPUBLISH_LATER_FAIL });
					}
				});
			}

			// When the content is deleted, you have to clear the unpublishLater array.
			// Because if you keep it, when you publish it it will try to delete these guys,
			// but they will already be deleted causing an error.
			updateContentArrayUnpublishLater();
		},
		[currentType?.path, removeContentFragment, unpublishPage, updateContentArrayUnpublishLater]
	);

	const stopInterval = () => {
		clearInterval(intervalRef.current);
	};

	const startInterval = useCallback(
		(operationPrefix, operationId) => {
			intervalRef.current = setInterval(async () => {
				const operation = `${operationPrefix}${operationId}`;

				return fetchReplicateStatus(operation)
					.then(response => {
						if (isEmpty(response?.data)) {
							stopInterval();
							clearCache({ successCallback: () => {} });
							clearCacheDelivery({ shouldShowToast: false });
							setIsPagePublished(true);
							handleStaleStatus(false);
							setRequestData({ ...initialState, data: response });
							showToast({ type: 'success', message: translations.PUBLISH_SUCCESS });
						}
					})
					.catch(err => {
						stopInterval();
						setRequestData({ ...initialState, error: err });
					});
			}, FIVE_SECONDS);
		},
		[fetchReplicateStatus, clearCache, clearCacheDelivery, handleStaleStatus, setIsPagePublished, initialState]
	);

	const publishPage = useCallback(
		async ({ checkApprove = true }) => {
			if (!isPageApproved && checkApprove) {
				showToast({ type: 'info', message: translations.PAGE_IS_NOT_APPROVED });
				return Promise.resolve();
			}

			setRequestData({ ...initialState, loading: true });

			const pathToPublish = getCurrentRelatorPathFromCurrentTypeToPublish(currentType);

			try {
				const response = await postPublish(pathToPublish);

				if (response.data) {
					const { operationId, operationPrefix } = response.data;
					startInterval(operationPrefix, operationId);
				}

				return response;
			} catch (err) {
				setRequestData({ ...initialState, error: err });
				throw err;
			} finally {
				handleArrayContentUnpublishLater(currentType);
			}
		},
		[currentType, initialState, isPageApproved, postPublish, startInterval, handleArrayContentUnpublishLater]
	);

	const publishButton = useMemo(
		() => ({
			key: 'publish-option',
			text: translations.PUBLISH,
			disabled: requestData.loading,
			loading: false,
			icon: 'icon-publish',
			onClick: publishPage,
		}),
		[requestData.loading, publishPage]
	);

	const isLoadingPublishPage = useMemo(
		() => [loadingPublish, loadingReplicateStatus, requestData.loading].some(Boolean),
		[loadingPublish, loadingReplicateStatus, requestData.loading]
	);

	const isErrorPublishPage = useMemo(
		() => [publishError, replicateStatusError, requestData.error].some(Boolean),
		[publishError, replicateStatusError, requestData.error]
	);

	const publishedPage = useMemo(() => requestData.data, [requestData.data]);

	useEffect(() => {
		if (isErrorPublishPage) {
			showToast({ type: 'error', message: translations.ERROR_PUBLISHING_PAGES });
		}
	}, [initialState, isErrorPublishPage]);

	return { publishLoading: isLoadingPublishPage, publishedPage, publishButton, publishPage };
};
