import { getBreakpoints } from 'themes/utils/breakpointsUtil';

const complementaryTheme = {
	name: 'complementary-theme',
	size: {
		'4xs': '10px',
		'3xs': '11px',
		'2xs': '12px',
		'1.5xs': '13px',
		xs: '14px',
		'1xs': '15px',
		sm: '16px',
		'1sm': '17px',
		md: '18px',
		'1md': '19px',
		lg: '20px',
		'1lg': '21px',
		xl: '24px',
		'1xl': '25px',
		'1.5xl': '27px',
		'2xl': '28px',
		'2.5xl': '30px',
		'3xl': '32px',
		'3.5xl': '34px',
		'4xl': '36px',
		'5xl': '40px',
		'6xl': '48px',
		'7xl': '64px',
	},
	weight: {
		thin: '100',
		extralight: '200',
		light: '300',
		regular: '400',
		medium: '500',
		semibold: '600',
		bold: '700',
		extrabold: '800',
		heavy: '900',
	},
	letter: {
		spacing: {
			sm: '0',
			md: '1px',
			lg: '2px',
			xl: '3px',
			'2xl': '4px',
			'3xl': '8px',
			'4xl': '12px',
			'5xl': '16px',
		},
	},
	fontVariantNumeric: {
		normal: 'normal',
		ordinal: 'ordinal',
		slashedZero: 'slashed-zero',
		liningNums: 'lining-nums',
		oldstyleNums: 'oldstyle-nums',
		proportionalNums: 'proportional-nums',
		tabularNums: 'tabular-nums',
		diagonalFractions: 'diagonal-fractions',
		stackedFractions: 'stacked-fractions',
		oldstyleNumsStackedFractions: 'oldstyle-nums stacked-fractions',
	},
	border: {
		radius: {
			none: 'none',
			zero: '0px',
			'4xs': '3px',
			'3xs': '4px',
			'2xs': '8px',
			xs: '12px',
			sm: '16px',
			md: '24px',
			lg: '32px',
			xl: '44px',
			'2xl': '60px',
			'3xl': '80px',
			pill: '500px',
			circular: '50%',
		},
		width: {
			none: 'none',
			sm: '1px',
			md: '2px',
			lg: '4px',
			xl: '8px',
		},
	},
	icon: {
		size: {
			'4xs': '8px',
			'3xs': '12px',
			'2xs': '16px',
			xs: '20px',
			sm: '24px',
			md: '28px',
			lg: '32px',
			xl: '36px',
			'2xl': '40px',
			'3xl': '44px',
			'4xl': '48px',
		},
	},
	lineHeight: {
		xs: '1.1em',
		small: '1.2em',
		normal: '1.4em',
		medium: '1.5em',
		large: '1.7em',
	},
	decoration: {
		none: 'none',
		italic: 'italic',
		underline: 'underline',
		strikethrough: 'strikethrough',
	},
	transform: {
		none: 'none',
		uppercase: 'uppercase',
		lowercase: 'lowercase',
		capitalize: 'capitalize',
	},
	neutral: {
		black: '#000000',
		100: '#161616',
		90: '#262626',
		80: '#333333',
		70: '#555555',
		66: '#888888',
		65: '#999999',
		63: '#666666',
		60: '#636466',
		50: '#CCCCCC',
		40: '#E5E5E5',
		35: '#EEEEEE',
		30: '#F2F3F4',
		20: '#f4f4f4',
		10: '#F7F7F7',
		white: '#FFFFFF',
	},
	breakpoint: getBreakpoints(),
};

export default complementaryTheme;
