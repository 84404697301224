import axios from 'API/axios/axios';
import { logError } from 'utils/logUtil';
import { isEmptyString } from 'utils/stringUtil';

export const postImage = ({ pagePath, cfType, data }) => {
	const url = `${process.env.REACT_APP_API_URL}/v1/contentFragment/relatorImage`;

	return axios.post(url, data, {
		headers: {
			pagePath,
			cfType,
			'Content-Type': 'multipart/form-data',
		},
	});
};

export const putImage = ({ pagePath, cfType, data }) => {
	const url = `${process.env.REACT_APP_API_URL}/v1/contentFragment/relatorImage`;

	return axios.put(url, data, {
		headers: {
			pagePath,
			cfType,
			'Content-Type': 'multipart/form-data',
		},
	});
};

export const fromImageService = contentFragment => {
	try {
		const imageVcmId = isEmptyString(contentFragment['jcr:uuid']);
		const parentPath = isEmptyString(contentFragment['jcr:content']['cq:parentPath']);
		const name = isEmptyString(contentFragment['jcr:content']['cq:name']);
		const master = isEmptyString(contentFragment['jcr:content'].data.master);

		const imageCaption = isEmptyString(master.BEG_ARTICLE_CONTENT_IMAGE_CAPTION);
		const captionPosition = isEmptyString(master.BEG_ARTICLE_CONTENT_IMAGE_CAPTION_POSITION);
		const displayOrder = isEmptyString(master.BEG_ARTICLE_CONTENT_IMAGE_DISPLAY_ORDER);
		const imageContentPath = isEmptyString(master.BEG_ARTICLE_CONTENT_IMAGE_IMAGE_CONTENT);
		const textOverlay = isEmptyString(master.BEG_ARTICLE_CONTENT_IMAGE_TEXT_OVERLAY);
		const textOverlayPosition = isEmptyString(master.BEG_ARTICLE_CONTENT_IMAGE_TEXT_OVERLAY_POSITION);
		const imageTitle = isEmptyString(master.BEG_ARTICLE_CONTENT_IMAGE_TITLE);
		const imagePath = parentPath && name ? `${parentPath}/${name}` : '';

		return {
			imageVcmId,
			imageCaption,
			captionPosition,
			displayOrder,
			imageContentPath,
			textOverlay,
			textOverlayPosition,
			imageTitle,
			parentPath,
			imagePath,
		};
	} catch (e) {
		logError(e.message);
		return null;
	}
};
