import styled from 'styled-components/macro';

export const LoadingContainer = styled.div(() => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: '100%',
	height: '100%',
}));

export const SectionPrintPageWrapper = styled.div(() => ({
	padding: '0 15px',
}));

export const ContainerArticle = styled.div(() => ({
	display: 'flex',
	flexDirection: 'column',
	pageBreakAfter: 'always',
}));

export const Title = styled.h1(({ theme: { color, breakpoint, typo } }) => ({
	display: 'flex',
	float: 'left',
	width: '100%',
	wordWrap: 'break-word',
	paddingBottom: '50px',

	pageBreakBefore: 'avoid',
	pageBreakAfter: 'avoid',

	color: color.text.primary02,
	...typo.headline['01'],

	[breakpoint.lg()]: {
		...typo.headline['01']?.lg,
	},

	'@media print': {
		paddingBottom: '30px',

		color: color.text.primary02,
		...typo.headline['01'],

		[breakpoint.lg()]: {
			...typo.headline['01']?.lg,
		},
	},
}));
