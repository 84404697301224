import axios from 'API/axios/axios';
import { isLocal } from 'utils/envUtil';

const getEnv = () => {
	return 'live';
};

const env = getEnv();

export const getMetricsByType = params => {
	const url = `${process.env.REACT_APP_ANALYTICS_API_URL}/v1/hyattdatalog/metrics`;
	return axios
		.get(url, {
			params: {
				...params,
				env,
			},
		})
		.then(response => response.data || []);
};

export const getMetrics = params => {
	const url = `${process.env.REACT_APP_ANALYTICS_API_URL}/v1/hyattdatalog/getMetrics`;
	return axios
		.get(url, {
			params: {
				...params,
				env,
			},
		})
		.then(response => response.data || []);
};

export const legacySaveView = data => {
	if (isLocal) {
		return Promise.resolve();
	}
	const url = `${process.env.REACT_APP_HYATT_SERVICES_URL}/exceedlabs/saveView`;
	return axios.post(url, data);
};
