/* eslint-disable max-len */
import { SpecialCharactersRegex } from 'constants/regexConstants';
import { PAGE_TYPES } from 'constants/pageConstants';
import { isNumber, isNegative } from 'utils/numberUtil';
import { getLastElement } from './arrayUtil';
import { logError } from './logUtil';
import { getYearMonth } from './dateUtil';
import { executeIfTruthy } from './functionUtils';

export const getName = path => path && getLastElement(path.split?.('/'));

export const getNameWithHyphen = name => name && name.toLowerCase().replace(/ /g, '-');

export const normalizedName = str => {
	return str && str.replace(SpecialCharactersRegex, '-');
};

export const normalizedPath = path => path && path.replace(/ /g, '-');

export const getParentPath = path => path && path.split('/').slice(0, -1).join('/');

export const extractParentPath = path => {
	const lastIndex = path?.lastIndexOf('/');
	const parentPath = path?.substring(0, lastIndex);

	return parentPath;
};

export const createContentFragmentBaseObj = ({ title, parentPath, model, master }) => {
	const cqNameFormatted = normalizedName(title);

	return {
		'jcr:content': {
			'jcr:title': title,
			'cq:name': cqNameFormatted,
			'cq:parentPath': parentPath,
			data: {
				'jcr:primaryType': 'nt:unstructured',
				'cq:model': model,
				master,
			},
		},
	};
};

export const updateContentFragmentBaseObj = ({ name, parentPath, model, master }) => {
	return {
		'jcr:content': {
			'cq:name': name,
			'cq:parentPath': parentPath,
			data: {
				'jcr:primaryType': 'nt:unstructured',
				'cq:model': model,
				master,
			},
		},
	};
};

export const updatedContentFragmentBaseObj = ({ name, parentPath, model, master, title = '' }) => {
	return {
		'jcr:content': {
			'jcr:title': title,
			'cq:name': name,
			'cq:parentPath': parentPath,
			data: {
				'jcr:primaryType': 'nt:unstructured',
				'cq:model': model,
				master,
			},
		},
	};
};

export const getCompletePath = contentFragment => {
	try {
		const cqParentPath = contentFragment['jcr:content']['cq:parentPath'];
		const cqName = contentFragment['jcr:content']['cq:name'];
		const completePath = `${cqParentPath}/${cqName}`;
		return completePath;
	} catch (e) {
		logError(e.message);
		return null;
	}
};

export const generateDisplayOrder = collection => {
	if (!collection || !collection.length) {
		return 1;
	}

	const displayOrders = collection?.map(o => +o.displayOrder).filter(i => i || i === 0);
	const displayOrder = Math.max(0, ...displayOrders) + 1;

	return displayOrder;
};

export const getDefaultPathWithDate = parentPath => (parentPath ? `${parentPath}/${getYearMonth()}` : '');

export const getNormalizedNameByPath = path => (path ? normalizedName(getName(path)) : '');

export const formattedNameWithHyphen = name => {
	return name ? normalizedName(getNameWithHyphen(name)) : '';
};

export const doItByType = (type, { doItForChapter, doItForSection, doItForArticle }) => {
	switch (type) {
		case PAGE_TYPES.SECTION_TYPE:
			return doItForSection;
		case PAGE_TYPES.ARTICLE_TYPE:
			return doItForArticle;
		default:
			return doItForChapter;
	}
};

export const generateFoldersByPath = ({
	basePath,
	chapterPath,
	sectionPath,
	articlePath,
	paragraphPath,
	isImage = false,
	isAsset = false,
}) => {
	const defaultPath = getDefaultPathWithDate(basePath);

	const chapterName = getNormalizedNameByPath(chapterPath);
	const sectionName = getNormalizedNameByPath(sectionPath);
	const articleName = getNormalizedNameByPath(articlePath);
	const paragraphName = executeIfTruthy(paragraphPath, `module-${getNormalizedNameByPath(paragraphPath)}`);

	let parentPath = defaultPath;
	let fragmentPath = '';

	if (paragraphName && isImage) {
		fragmentPath = `/${paragraphName}/images`;
	}

	if (paragraphName && isAsset) {
		fragmentPath = `/${paragraphName}/assets`;
	}

	if (chapterName) {
		parentPath += `/${chapterName}`;
	}

	if (sectionName) {
		parentPath += `/sections/${sectionName}`;
	}

	if (articleName) {
		parentPath += `/articles/${articleName}`;
	}

	const paragraphPartPath = '/paragraphs';

	return parentPath + paragraphPartPath + fragmentPath;
};

export const generatePagePath = ({ type = PAGE_TYPES.CHAPTER_TYPE, basePath, name, chapterPath, sectionPath }) => {
	const defaultPath = getDefaultPathWithDate(basePath);

	const currentCqName = formattedNameWithHyphen(name);
	const chapterCqName = getNormalizedNameByPath(chapterPath);
	const sectionCqName = getNormalizedNameByPath(sectionPath);

	const chapterParentPath = extractParentPath(chapterPath);
	const sectionParentPath = extractParentPath(sectionPath);

	const sectionsPath = `/${chapterCqName}/sections`;
	const articlesPath = `/${sectionCqName}/articles`;
	const currentPath = `/${currentCqName}`;

	const parentPath =
		defaultPath +
		doItByType(type, {
			doItForChapter: currentPath,
			doItForSection: `${sectionsPath}${currentPath}`,
			doItForArticle: `${sectionsPath}${articlesPath}${currentPath}`,
		});

	return {
		cqName: currentCqName,
		pagePath: parentPath,
		chapterCqName,
		sectionCqName,
		chapterParentPath,
		sectionParentPath,
	};
};

export const getSortOrderAttribute = (cfResponse, key) => {
	if (!cfResponse?.[key] && cfResponse[key] !== 0) return null;

	const value = Number(cfResponse[key]);

	if (!isNumber(value) || isNegative(value)) {
		return null;
	}

	return value;
};

export const fillAllSortOrderAttr = ({ arr, sortOrderKey }) => {
	return (
		Array.isArray(arr) &&
		arr?.map((item, index) => ({
			...item,
			[sortOrderKey]: index + 1,
		}))
	);
};

/* eslint-disable no-plusplus */
const replaceNullAndEmpty = (items, displayOrderKey) => {
	const fixedItems = [];

	// Filter out non-numeric and empty values
	const numericValues = items.map(item => Number(item[displayOrderKey])).filter(value => !Number.isNaN(value));

	// Find the maximum value in the filtered list
	const max = Math.max(...numericValues);

	// Generate a unique value greater than the maximum
	let newValue = max + 1;
	while (numericValues.includes(newValue)) {
		newValue++;
	}

	// Iterate through the list and replace null or empty values
	items.forEach(item => {
		if (!item[displayOrderKey] || (typeof item[displayOrderKey] === 'string' && item[displayOrderKey]?.trim() === '')) {
			item[displayOrderKey] = newValue; // Set to a unique value greater than the maximum
			newValue++;

			while (numericValues.includes(newValue)) {
				newValue++;
			}

			fixedItems.push(item);
		}
	});

	return { updatedItems: items, fixedItems };
};

const replaceRepeatedValues = (items, displayOrderKey) => {
	// Store the items with repeated values
	const repeatedItems = [];

	// Create a map to store the frequency of each value
	const frequencyMap = {};
	items.forEach(item => {
		if (typeof item[displayOrderKey] !== 'undefined') {
			const value = item[displayOrderKey].toString(); // Convert to string for consistency
			if (frequencyMap[value]) {
				frequencyMap[value]++;
			} else {
				frequencyMap[value] = 1;
			}
		}
	});

	// Find the repeated values
	const repeatedValues = Object.keys(frequencyMap).filter(key => frequencyMap[key] > 1);

	// Generate a list of available values
	const availableValues = Array.from({ length: items.length }, (_, i) => (i + 1).toString());

	// Replace repeated values
	items.forEach(item => {
		if (item[displayOrderKey]) {
			const value = item[displayOrderKey].toString();

			if (repeatedValues.includes(value)) {
				// Filter out the current value and any values already present in the list
				const available = availableValues.filter(
					val =>
						val !== value &&
						!items.some(obj => typeof obj[displayOrderKey] !== 'undefined' && obj[displayOrderKey].toString() === val)
				);

				if (available.length > 0) {
					// Assign a random value from the available list
					const randomIndex = Math.floor(Math.random() * available.length);
					item[displayOrderKey] = available[randomIndex];
					repeatedItems.push(item);
				}
			}
		}
	});

	return { replacedItems: items, repeatedItems };
};

export const fixedProblematicItems = (items, keyProp) => {
	const { updatedItems, fixedItems } = replaceNullAndEmpty(items, keyProp);
	const { replacedItems, repeatedItems } = replaceRepeatedValues(updatedItems, keyProp);

	return { replacedItems, problematicItems: [...fixedItems, ...repeatedItems] };
};

export const moveArrayElements = (arr, firstItem, firstItemIndex, secondItem, secondItemIndex, sortKey = 'displayOrder') => {
	const replacedArr = [...arr];

	const objA = { ...secondItem, [sortKey]: firstItem[sortKey] };
	const objB = { ...firstItem, [sortKey]: secondItem[sortKey] };

	replacedArr[firstItemIndex] = objA;
	replacedArr[secondItemIndex] = objB;

	return replacedArr.map(item => item);
};

export const moveArrItemUp = ({ arr, itemToMoveUp, itemKey, sortKey = 'displayOrder' }) => {
	const itemToMoveUpIndex = arr.findIndex(item => item[itemKey] === itemToMoveUp[itemKey]);

	if (itemToMoveUpIndex === 0) return { replacedArr: arr };

	const itemToReplaceIndex = itemToMoveUpIndex - 1;
	const itemToReplace = arr[itemToReplaceIndex];

	const replacedArr = moveArrayElements(arr, itemToMoveUp, itemToMoveUpIndex, itemToReplace, itemToReplaceIndex, sortKey);

	return {
		currentItem: { ...itemToMoveUp, [sortKey]: itemToReplace[sortKey] },
		previousItem: { ...itemToReplace, [sortKey]: itemToMoveUp[sortKey] },
		replacedArr,
	};
};

export const moveArrItemDown = ({ arr, itemToMoveDown, itemKey, sortKey = 'displayOrder' }) => {
	const itemToMoveDownIndex = arr.findIndex(item => item[itemKey] === itemToMoveDown[itemKey]);
	const lastItemIndex = arr.length - 1;

	if (itemToMoveDownIndex === lastItemIndex) return { replacedArr: arr };

	const itemToReplaceIndex = itemToMoveDownIndex + 1;
	const itemToReplace = arr[itemToReplaceIndex];

	const replacedArr = moveArrayElements(arr, itemToMoveDown, itemToMoveDownIndex, itemToReplace, itemToReplaceIndex, sortKey);

	return {
		currentItem: { ...itemToMoveDown, [sortKey]: itemToReplace[sortKey] },
		previousItem: { ...itemToReplace, [sortKey]: itemToMoveDown[sortKey] },
		replacedArr,
	};
};
