import { SEARCH_QUERY_PARAM_EXACT_MATCH, SEARCH_QUERY_PARAM_NAME } from 'constants/searchConstants';
import { getHighlightContent } from 'utils/highlightUtil';
import { useQueryParams } from './useQueryParams';

export const useHighlight = () => {
	const { getQueryParam } = useQueryParams();

	const searchTerm = getQueryParam(SEARCH_QUERY_PARAM_NAME);
	const isExactMatch = getQueryParam(SEARCH_QUERY_PARAM_EXACT_MATCH);

	const handleTextHighlight = text => {
		if (searchTerm) {
			return getHighlightContent({ searchTerms: searchTerm, content: text, isExactMatch });
		}

		return text;
	};

	return {
		handleTextHighlight,
	};
};
