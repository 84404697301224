import axios from 'API/axios/axios';
import { begSqlApi } from 'constants/serviceConstants';
import { TERMS_AND_CONDITIONS } from 'constants/termsAndConditionsConstants';
import { logError } from 'utils/logUtil';

export const postTermsAndConditions = ({ data }) => {
	const url = `${process.env.REACT_APP_API_URL}/v1/contractor/history/insert`;
	return axios.post(url, data);
};

export const getTermsAndConditions = params => {
	const { brandId, visible, status } = params;
	const url = `${process.env.REACT_APP_API_URL}${begSqlApi}/list-terms-and-conditions`;

	return axios.get(url, {
		params: {
			identifier: 'list-terms-and-conditions',
			pagePath: `beg-${brandId}-terms-and-conditions-${status.toLowerCase()}`,
			cfType: TERMS_AND_CONDITIONS,
			parameter: `;visible=${visible}`,
		},
	});
};

export const getCheckTermsAndConditions = params => {
	const { globalId } = params;
	const url = `${process.env.REACT_APP_API_URL}/v1/contractor/history/latestByGlobalId`;

	return axios.get(url, {
		params: {
			globalId,
		},
	});
};

export const fromTermsAndConditions = data => {
	try {
		return {
			path: data._path,
			title: data.title,
			teaser: data.teaser,
			description: data.description,
		};
	} catch (e) {
		logError(e.message);
		return null;
	}
};
