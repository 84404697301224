import { dynamicLinkUrl } from 'utils/urlUtil';
import { getBrands } from 'services/brandService';
import useBrandDetails from './useBrandDetails';
import { useRequest } from './useRequest';

export const useBrands = () => {
	const { currentType } = useBrandDetails();
	const emptyTitleCollection = ' ';

	const verifyTitle = title => {
		if (title === null || title === undefined || title === emptyTitleCollection) {
			return '';
		}

		return title;
	};

	const getBrandsPromise = () =>
		getBrands({
			pagePath: currentType?.path,
			cfType: `${currentType?.type?.toLowerCase()}`,
		});

	const { data: collectionArr, loading } = useRequest(() => getBrandsPromise(), { autoRun: true });

	const addBrandToRow = ({ rows, key, brand, maxBrandsPerRow }) => {
		const lastRow = rows?.[rows.length - 1];

		if (lastRow.data.length < maxBrandsPerRow) {
			lastRow.data.push(brand);
		} else {
			rows.push({ key: `${key}-${rows.length}`, data: [brand] });
		}
	};

	const mapBrandsToRows = (brands, maxBrandsPerRow, collectionName) => {
		return brands.reduce(
			(rows, brand) => {
				const { logoThumbnail, brandName, brandPath, brandStyle, contentPath, comingSoon } = brand;

				const structuredBrand = {
					...brand,
					imgSrc: dynamicLinkUrl(logoThumbnail),
					name: brandName,
					path: contentPath,
					pagePath: brandPath,
					style: JSON.parse(brandStyle),
					isComingSoon: comingSoon,
				};

				addBrandToRow({ rows, key: collectionName, brand: structuredBrand, maxBrandsPerRow });

				return rows;
			},
			[
				{
					key: `${collectionName}-0`,
					data: [],
				},
			]
		);
	};

	const collectionsMap = () => {
		const { data: collectionArrData } = collectionArr || {};

		return (collectionArrData || [])
			.filter(collection => collection.brands.length > 0)
			.sort((a, b) => a.dispOrderCollection - b.dispOrderCollection)
			.map(collection => {
				const { colletionTitle: collectionTitle, colletionName: collectionName, maxColumnPerRow, brands } = collection;
				const sortedBrands = brands.sort((a, b) => a.dispOrderBrand - b.dispOrderBrand);

				const structuredCollection = {
					title: verifyTitle(collectionTitle),
					rows: mapBrandsToRows(sortedBrands, maxColumnPerRow, collectionName),
					collectionName,
				};

				return structuredCollection;
			});
	};

	return { brands: collectionsMap(), loading };
};
