// eslint-disable-next-line import/no-extraneous-dependencies
import PptxGenJS from 'pptxgenjs';
import { useTheme } from 'styled-components';
import { addCoverSlide, addParagraphToExcel } from 'utils/pptxGenJSUtils';
import { capitalizeTheContent } from 'utils/htmlUtils';
import { showToast } from 'utils/toastUtil';
import { isEmptyString, removeTrailingSpaces } from 'utils/stringUtil';
import { translations } from 'translation/en';
import { PAGE_TYPES } from 'constants/pageConstants';
import { useQueryParams } from './useQueryParams';

const QUERY_PARAM = 'type';

const usePptxGenJS = handleSetIsLoadingFile => {
	const { color, typo } = useTheme();
	const { getQueryParam } = useQueryParams();

	const handleFileName = content => {
		switch (getQueryParam(QUERY_PARAM)) {
			case PAGE_TYPES.CHAPTER_TYPE:
				return removeTrailingSpaces(isEmptyString(content.title));
			case PAGE_TYPES.SECTION_TYPE:
				return removeTrailingSpaces(isEmptyString(content.sections?.[0]?.sectionTitle));
			case PAGE_TYPES.ARTICLE_TYPE:
				return removeTrailingSpaces(isEmptyString(content.sections?.[0]?.articles?.[0]?.articleTitle));
			default:
				return '';
		}
	};

	const createExcel = content => {
		handleSetIsLoadingFile(true);
		const pres = new PptxGenJS();

		addCoverSlide(pres, color.background.secondary, content.logo);

		content?.paragraphs?.map((paragraph, index) => {
			return addParagraphToExcel(
				{ ...paragraph, body: capitalizeTheContent(paragraph.body) },
				pres,
				index === 0 ? content.title : '',
				index === 0 ? content.sections?.[0]?.sectionTitle : '',
				index === 0 ? content.sections?.[0]?.articles?.[0]?.articleTitle : '',
				{ color, typo }
			);
		});
		content?.sections?.map(section => {
			section.paragraphs?.map((sectionParagraph, index) => {
				return addParagraphToExcel(
					{ ...sectionParagraph, body: capitalizeTheContent(sectionParagraph.body) },
					pres,
					'',
					index === 0 ? section?.sectionTitle : '',
					index === 0 ? section?.articles?.[0]?.articleTitle : '',
					{
						background: { color: 'FFFFFF' },
						slideNumber: { x: 0.3, y: '90%' },
						color,
						typo,
					}
				);
			});
			return section?.articles?.map(article => {
				return article?.paragraphs?.map((articleParagraph, index) => {
					return addParagraphToExcel(
						{ ...articleParagraph, body: capitalizeTheContent(articleParagraph.body) },
						pres,
						'',
						'',
						index === 0 ? article?.articleTitle : '',
						{
							background: { color: 'FFFFFF' },
							slideNumber: { x: 0.3, y: '90%' },
							color,
							typo,
						}
					);
				});
			});
		});
		pres.writeFile({ fileName: `${handleFileName(content)}` })
			.catch(err => {
				showToast({ type: 'error', message: `${translations.ERROR_EXPORTING_PPTX}: ${err}` });
			})
			.finally(() => {
				handleSetIsLoadingFile(false);
			});
	};
	return { createExcel };
};

export default usePptxGenJS;
