import { useCallback, useEffect } from 'react';
import { putContentFragment } from 'services/contentFragmentService';
import { translations } from 'translation/en';
import { getName, getParentPath, updateContentFragmentBaseObj } from 'utils/contentFragmentUtil';
import { getISONow } from 'utils/dateUtil';
import { showToast } from 'utils/toastUtil';
import useBrandDetails from './useBrandDetails';
import { useRequest } from './useRequest';
import useApprovePublish from './useApprovePublish';
import { useApprovePage } from './useApprovePage';
import { useDisapprovePage } from './useDisapprovePage';

export const usePutContentFragment = ({ shouldShowToast = false, toastId = null, shouldApproveUnapprove = true } = {}) => {
	const { currentType } = useBrandDetails();

	const { loading: loadingUpdate, data: response, error, run } = useRequest(putContentFragment);
	const { isPageApproved, handleStaleStatus } = useApprovePublish();
	const { isLoadingApprovePage, approvePageTree } = useApprovePage();
	const { isLoadingDisapprovePage, disapprovePage } = useDisapprovePage();

	useEffect(() => {
		if (response && shouldShowToast) {
			showToast({ type: 'success', message: translations.CHANGE_SAVED_SUCCESSFULLY_MESSAGE, config: { toastId } });
		}
	}, [response, shouldShowToast, toastId]);

	useEffect(() => {
		if (error) {
			showToast({ type: 'error', message: translations.ERROR_EDITING_CONTENT });
		}
	}, [error]);

	const updateContentFragment = useCallback(
		({ payload, path, model, cfType, pagePath, shouldApprove }) => {
			const updateContentFragmentObj = updateContentFragmentBaseObj({
				name: getName(path),
				parentPath: getParentPath(path),
				model,
				master: {
					...payload,
					MODIFICATION_DATE: getISONow(),
				},
			});

			const shouldApproveValue = shouldApprove ?? !isPageApproved;
			const shouldCallHandleApproveUnapprove = shouldApproveUnapprove && shouldApproveValue !== isPageApproved;

			let approvalPromise = Promise.resolve();

			if (shouldCallHandleApproveUnapprove) {
				if (shouldApproveValue) {
					approvalPromise = approvePageTree([currentType]);
				} else {
					approvalPromise = disapprovePage([currentType]);
				}
			}

			const updatePromise = run({
				pagePath: pagePath || currentType?.path,
				cfType: cfType || currentType?.type,
				data: updateContentFragmentObj,
			});

			return Promise.all([updatePromise, approvalPromise]).then(responses => {
				const isSuccess = responses.some(res => res?.data);

				if (isSuccess) {
					handleStaleStatus(true);
				}

				return responses;
			});
		},
		[shouldApproveUnapprove, currentType, isPageApproved, handleStaleStatus, approvePageTree, disapprovePage, run]
	);

	return {
		loadingUpdate: [loadingUpdate, isLoadingApprovePage, isLoadingDisapprovePage].some(Boolean),
		response,
		error,
		updateContentFragment,
	};
};
