import axios, { axiosWithRetry } from 'API/axios/axios';
import { cacheMaps } from 'constants/aemConstants';

export const clearCache = ({ cfType, pagePath }) => {
	const url = `${process.env.REACT_APP_API_URL}/v1/hazel/clear`;

	return axios.delete(url, {
		params: {
			cfType,
			pagePath,
		},
	});
};

// TODO: remove clearByKeys
export const clearKeys = keys => {
	const url = `${process.env.REACT_APP_API_URL}/v1/hazel/clearByKeys`;

	return axios.delete(url, {
		data: keys,
	});
};

const defaultMaps = [...Object.values(cacheMaps)];

export const clearMapsService = (maps = defaultMaps) => {
	const url = `${process.env.REACT_APP_API_URL}/v1/hazel/clear/maps`;
	return axiosWithRetry.delete(url, {
		data: typeof maps === 'string' ? [maps] : maps,
	});
};

export const clearMapsDeliveryService = (maps = defaultMaps) => {
	const url = `${process.env.REACT_APP_API_URL}/v1/hazel/clear/mapsDelivery`;
	return axiosWithRetry.delete(url, {
		data: typeof maps === 'string' ? [maps] : maps,
	});
};
