import { translation } from './translation';

export const DAY_VALUE = 0;
export const BRAND_VALUE = 1;
export const REGION_VALUE = 2;
export const ROLE_VALUE = 3;
export const LOCATION_TYPE_VALUE = 4;

export const REGION_NAME = 'locationRegion';
export const BRAND_NAME = 'locationBrand';
export const LOCATION_TYPE_NAME = 'locationType';
export const ROLE_NAME = 'userJobLevelCode';

export const DAY = {
	nameKey: 'day',
	text: translation.DAY,
	value: DAY_VALUE,
	pageDay: true,
};

export const LOCATION_BRAND = {
	nameKey: BRAND_NAME,
	text: translation.BRAND,
	value: BRAND_VALUE,
};

export const LOCATION_REGION = {
	nameKey: REGION_NAME,
	text: translation.REGION,
	value: REGION_VALUE,
};

export const ROLE = {
	nameKey: ROLE_NAME,
	text: translation.ROLE,
	value: ROLE_VALUE,
};

export const LOCATION_TYPE = {
	nameKey: LOCATION_TYPE_NAME,
	text: translation.LOCATION_TYPE,
	value: LOCATION_TYPE_VALUE,
};

export const listMetrics = [LOCATION_BRAND, DAY, LOCATION_TYPE, LOCATION_REGION, ROLE];

export const listTypes = [LOCATION_BRAND, LOCATION_TYPE, LOCATION_REGION, ROLE];
