import { useMemo, useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { EditMenu } from '@hyatt/edit-menu';
import { Spinner } from '@hyatt/spinner';
import { useOnBrandRouteChange } from 'hooks/useOnBrandRouteChange';
import { translations } from 'translation/en';
import * as actionCreators from 'actions/actionCreators';
import { Loading } from 'components/loadingOverlay';
import useBrandDetails from 'hooks/useBrandDetails';
import { useEditMenuData } from 'hooks/useEditMenuData';
import { useQueryParams } from 'hooks/useQueryParams';
import useClearCache from 'hooks/useClearCache';
import useApprovePublish from 'hooks/useApprovePublish';
import { useApprovePage } from 'hooks/useApprovePage';
import { useDisapprovePage } from 'hooks/useDisapprovePage';
import { useCurrentRoute } from 'hooks/useCurrentRoute';
import { usePublishPage } from 'hooks/usePublishPage';
import { useUnpublishPage } from 'hooks/useUnpublishPage';
import { SEARCH_QUERY_PARAM_NAME } from 'constants/searchConstants';
import { Button } from 'components/button';
import { Modal } from 'components/modals/modal';
import { ApproveModal } from 'components/modals/ApproveModal';
import { PublishModal } from 'components/modals/PublishModal';
import { doItByType } from 'utils/contentFragmentUtil';
import { PublishBrandModal } from 'components/publishBrandModal';
import { usePublishBrands } from 'hooks/usePublishBrands';
import { useAuthAccess } from 'hooks/useAuthData';
import {
	EDIT_MENU_TYPES,
	editMenuOptions,
	getMenuOptionsByQuickSheet,
	isQuickSheetSectionAndArticle,
} from './constants/begEditMenuConstants';

const BegEditMenu = () => {
	const initialState = useMemo(() => {
		return {
			type: EDIT_MENU_TYPES.RESET_EDIT_INLINE,
		};
	}, []);

	const { isAdmin } = useAuthAccess();
	const { selectedToEdit } = useEditMenuData();
	const { selectedBrands, onClearSelectedBrands, currentType } = useBrandDetails();
	const [state, setDispatch] = useState(initialState);
	const dispatch = useDispatch();
	const [isOpen, setIsOpen] = useState(false);
	const [highlightModalOpen, setHighlightModalOpen] = useState(false);
	const { isOnDefaultRoute } = useCurrentRoute();
	const { logo } = useTheme();
	const { getQueryParam } = useQueryParams();
	const searchTerm = getQueryParam(SEARCH_QUERY_PARAM_NAME);

	const { isPageApproved, isStale, isPagePublished, publishStatus } = useApprovePublish();

	const { approveLoading, approvePageTree } = useApprovePage();
	const { disapproveLoading, disapprovePageTree } = useDisapprovePage();
	const { publishButton } = usePublishPage();
	const { unpublishLoading, unpublishButton } = useUnpublishPage();

	const { isLoadingPublishBrand, publishedBrand, publishBrands } = usePublishBrands();

	const { clearCacheLoading, clearCacheDeliveryLoading, clearCache, clearCacheDelivery } = useClearCache();
	const { editMenuSetSelectedToEdit } = actionCreators;

	const isLoading = [approveLoading, disapproveLoading, unpublishLoading].some(Boolean);

	const titleModalApproveAndPublish = doItByType(currentType?.type, {
		doItForChapter: currentType?.chapterTitle,
		doItForSection: currentType?.sectionTitle,
		doItForArticle: currentType?.articleTitle,
	});

	const handleCancel = useCallback(() => {
		setDispatch(initialState);
	}, [initialState]);

	const handleCheck = useCallback(() => {
		setDispatch({
			type: EDIT_MENU_TYPES.APPROVE,
		});
	}, []);

	const handlePublish = useCallback(() => {
		setDispatch({
			type: EDIT_MENU_TYPES.PUBLISH,
		});
	}, []);

	const onPublishAllBrand = () => {
		publishBrands(selectedBrands);
		setDispatch(initialState);
	};

	const handlePublishBrand = useCallback(() => {
		setDispatch({ type: EDIT_MENU_TYPES.PUBLISH_BRANDS });
	}, []);

	useOnBrandRouteChange(() => {
		setIsOpen(false);
	});

	const onStopEditing = useCallback(() => {
		dispatch(editMenuSetSelectedToEdit({ selectedToEdit: EDIT_MENU_TYPES.RESET_EDIT_INLINE }));
	}, [dispatch, editMenuSetSelectedToEdit]);

	const getPublishBrandOption = () => ({
		headerName: translations.BRANDS,
		defaultCollapsed: false,
		options: [
			{
				icon: <i className="icon icon-plus" />,
				label: `${translations.EDIT_PUBLISH_BRANDS}`,
				value: EDIT_MENU_TYPES.EDIT_PUBLISH_BRANDS,
			},
			{
				icon: <i className="icon icon-publish" />,
				label: `${translations.PUBLISH_BRANDS}`,
				onClick: handlePublishBrand,
				value: EDIT_MENU_TYPES.PUBLISH_BRANDS,
				disabled: selectedBrands?.length === 0 || selectedToEdit !== EDIT_MENU_TYPES.EDIT_PUBLISH_BRANDS,
			},
		],
	});

	const publishBrandOption = getPublishBrandOption();

	const addMoreItemsToOptions = useCallback(
		currentOptions => {
			const options = [
				...currentOptions,
				...(isAdmin ? [publishBrandOption] : []),
				{
					headerName: translations.CACHE,
					defaultCollapsed: false,
					options: [
						{
							icon: <i className="icon icon-refresh" />,
							label: `${translations.CLEAR_CACHE} (${translations.PREVIEW})`,
							onClick: clearCache,
							value: 'clear-preview-cache',
							disabled: clearCacheLoading,
						},
						{
							icon: <i className="icon icon-refresh" />,
							label: `${translations.CLEAR_CACHE} (${translations.DELIVERY})`,
							onClick: clearCacheDelivery,
							value: 'clear-delivery-cache',
							disabled: clearCacheDeliveryLoading,
						},
					],
				},
			];
			return options;
		},
		[isAdmin, publishBrandOption, clearCache, clearCacheLoading, clearCacheDelivery, clearCacheDeliveryLoading]
	);

	const options = useMemo(() => {
		const quicksheetOptions = getMenuOptionsByQuickSheet(currentType);
		const currentOptions = currentType?.quickSheetPath ? [...quicksheetOptions] : [...editMenuOptions];
		const optionsWithNewOptions = addMoreItemsToOptions(currentOptions);

		return optionsWithNewOptions;
	}, [addMoreItemsToOptions, currentType]);

	const handleApproveUnapprovedPage = useCallback(async () => {
		if (currentType?.path) {
			if (isPageApproved) {
				await disapprovePageTree([currentType]);
			} else {
				await approvePageTree([currentType]);
			}
		}
	}, [currentType, isPageApproved, approvePageTree, disapprovePageTree]);

	const handleClickOptions = option => {
		if (option === EDIT_MENU_TYPES.EDIT_INLINE && searchTerm) {
			setHighlightModalOpen(true);
		} else {
			dispatch(editMenuSetSelectedToEdit({ selectedToEdit: option }));
		}
	};

	const publishOptions = useMemo(() => {
		if (isStale) {
			return [publishButton, unpublishButton];
		}

		if (isPagePublished) {
			return [unpublishButton];
		}

		return [publishButton];
	}, [isPagePublished, isStale, publishButton, unpublishButton]);

	const approvePublishProps = useMemo(
		() =>
			isQuickSheetSectionAndArticle(currentType)
				? {}
				: { isPagePublish: isPagePublished, isPageApproved, publishStatus, publishOptions, handleApproveUnapprovedPage },
		[currentType, handleApproveUnapprovedPage, isPageApproved, isPagePublished, publishOptions, publishStatus]
	);

	const renderSelectedBrands = () => {
		return (
			<div className="BrandsBox">
				<span>{translations.PUBLISH_BRAND_MESSAGE}</span>
				<ul className="BrandsBox__unordered-list-brands">
					{selectedBrands.map(brand => (
						<li key={brand.brandId}>
							<p>{brand.name}</p>
						</li>
					))}
				</ul>
			</div>
		);
	};

	const renderEditMenu = () => {
		if (isOnDefaultRoute && !isAdmin) {
			return null;
		}

		if (isOnDefaultRoute) {
			const publishBrandForGroups = isAdmin ? [publishBrandOption] : [];

			return (
				<EditMenu
					style={{ top: '110px' }}
					isOpen={isOpen}
					groups={publishBrandForGroups}
					selectedOption={selectedToEdit}
					translations={translations}
					onStopEditing={onStopEditing}
					setIsOpen={setIsOpen}
					setSelectedOption={handleClickOptions}
				/>
			);
		}

		return (
			<EditMenu
				style={{ top: '110px' }}
				checkIcon={<i className="icon icon-check" style={{ fontSize: '18px' }} />}
				loadingIcon={<Spinner width="15px" style={{ margin: 'auto' }} />}
				isOpen={isOpen}
				groups={options}
				selectedOption={selectedToEdit}
				translations={translations}
				setIsOpen={setIsOpen}
				setSelectedOption={handleClickOptions}
				onStopEditing={onStopEditing}
				openApproveModal={handleCheck}
				openPublishModal={handlePublish}
				isGroupsCollapsible
				{...approvePublishProps}
			/>
		);
	};

	// TODO extract this module to its own component
	// TODO add tests
	const highlightModal = highlightModalOpen && (
		<Modal open onClose={() => setHighlightModalOpen(false)} closeIcon={<div />} style={{ modal: { top: '15%' } }}>
			<Modal.Main>{translations.WARNING_HIGHLIGHT}</Modal.Main>
			<Modal.Footer style={{ padding: '10px' }}>
				<Button variant="primary" onClick={() => setHighlightModalOpen(false)}>
					{translations.OK}
				</Button>
			</Modal.Footer>
		</Modal>
	);

	useEffect(() => {
		if (publishedBrand) {
			handleCancel();

			onStopEditing();
			onClearSelectedBrands();
		}
	}, [publishedBrand]);

	useEffect(() => {
		const shouldClearSelectedBrands = selectedBrands?.length === 0 || selectedToEdit !== EDIT_MENU_TYPES.EDIT_PUBLISH_BRANDS;

		if (shouldClearSelectedBrands) {
			onClearSelectedBrands();
		}
	}, [selectedToEdit]);

	return (
		<>
			{isLoading && (
				<Loading.Overlay isFullScreen>
					<Loading.LoadingLogo logo={logo?.loading} size={logo?.size.loading} />
				</Loading.Overlay>
			)}

			{highlightModal}

			{renderEditMenu()}

			{state.type === EDIT_MENU_TYPES.APPROVE && (
				<ApproveModal open title={titleModalApproveAndPublish} onClose={handleCancel} />
			)}

			{state.type === EDIT_MENU_TYPES.PUBLISH && (
				<PublishModal open title={titleModalApproveAndPublish} onClose={handleCancel} />
			)}

			{state.type === EDIT_MENU_TYPES.PUBLISH_BRANDS && (
				<PublishBrandModal
					open
					isLoadingPublishBrand={isLoadingPublishBrand}
					description={renderSelectedBrands()}
					onSubmit={onPublishAllBrand}
					onClose={handleCancel}
				/>
			)}
		</>
	);
};

export { BegEditMenu };
