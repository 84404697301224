import { useEffect, useCallback, useState } from 'react';
import useBrandData from './useBrandData';
import useBrandDetails from './useBrandDetails';
import { useDebounce } from './useDebounce';

const useMenuEvents = () => {
	const { brandName } = useBrandData();
	const { chapters, contentPath } = useBrandDetails();
	const { debounce } = useDebounce();

	const [chaptersWithIsOpen, setChaptersWithIsOpen] = useState(chapters);
	const [currentBrandName, setCurrentBrandName] = useState();

	// Open the chapter and section that the user is currently on in the first render with a path
	useEffect(() => {
		const shouldOpenChapter = chapters && contentPath && currentBrandName !== brandName;

		if (shouldOpenChapter) {
			setCurrentBrandName(brandName);
			setChaptersWithIsOpen(() =>
				chapters?.map(chapter => {
					const shouldChapterBeOpen =
						contentPath === chapter.path ||
						chapter?.sections?.some(
							section =>
								contentPath === section.path || section.articles?.some(article => contentPath === article.path)
						);
					return {
						...chapter,
						isOpen: shouldChapterBeOpen,
						sections: chapter?.sections?.map(section => {
							const shouldSectionBeOpen = section.articles.some(article => contentPath === article.path);
							return {
								...section,
								isOpen: shouldSectionBeOpen,
							};
						}),
					};
				})
			);
		}
	}, [brandName, chapters, contentPath, currentBrandName]);

	// If the chapters change, preserve the isOpen state of the chapters and sections
	useEffect(() => {
		if (chapters) {
			setChaptersWithIsOpen(currChaptersWithIsOpen =>
				chapters?.map(chapter => {
					const openChapter = currChaptersWithIsOpen?.find(
						chapterWithIsOpen => chapterWithIsOpen.vcmId === chapter.vcmId
					);

					return {
						...chapter,
						isOpen: openChapter?.isOpen,
						sections: chapter?.sections?.map(section => {
							const openSection = currChaptersWithIsOpen
								?.find(chapterWithIsOpen => chapterWithIsOpen.vcmId === chapter.vcmId)
								?.sections?.find(sectionWithIsOpen => sectionWithIsOpen.vcmId === section.vcmId);

							return {
								...section,
								isOpen: openSection?.isOpen,
							};
						}),
					};
				})
			);
		}
	}, [chapters]);

	const onMenuClick = useCallback(
		vcmId => {
			debounce(() => {
				setChaptersWithIsOpen(currChapters =>
					currChapters?.map(chapter => {
						const isChapterChild =
							chapter?.sections?.some(section => section.vcmId === vcmId) ||
							chapter?.sections?.some(section => section?.articles?.some(article => article.vcmId === vcmId));
						const isChapterClicked = !!vcmId && chapter.vcmId === vcmId ? !chapter.isOpen : false;

						return {
							...chapter,
							isOpen: isChapterClicked || isChapterChild,
							sections: chapter.sections?.map(section => {
								const isSectionChild = section?.articles?.some(article => article.vcmId === vcmId);
								const isSectionClicked = !!vcmId && section.vcmId === vcmId ? !section.isOpen : false;

								return {
									...section,
									isOpen: isSectionClicked || isSectionChild,
								};
							}),
						};
					})
				);
			});
		},
		[debounce]
	);

	const onIconClick = useCallback(vcmId => {
		setChaptersWithIsOpen(currChapters =>
			currChapters?.map(chapter => ({
				...chapter,
				isOpen: !!vcmId && chapter.vcmId === vcmId ? !chapter.isOpen : chapter.isOpen,
				sections: chapter.sections?.map(section => ({
					...section,
					isOpen: !!vcmId && section.vcmId === vcmId ? !section.isOpen : section.isOpen,
				})),
			}))
		);
	}, []);

	return { chaptersWithIsOpen, onMenuClick, onIconClick };
};

export { useMenuEvents };
