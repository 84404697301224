import styled from 'styled-components/macro';

const variantStyle = ({ variant = 'primary', color }) => {
	switch (variant) {
		case 'primary':
			return {
				color: `${color.link.primary.default.label} !important`,
			};
		case 'secondary':
			return {
				color: `${color.link.secondary.default.label} !important`,
			};
		case 'tertiary':
			return {
				color: `${color.link.tertiary.default.label} !important`,
			};
		case 'quaternary':
			return {
				color: `${color.link.quaternary.default.label} !important`,
			};
		default:
			return {};
	}
};

export const Box = styled.div(({ theme: { size } }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	minWidth: size.lg,
	minHeight: size.lg,
}));

export const Icon = styled.i(({ theme: { color }, variant }) => ({
	...variantStyle({ variant, color }),
}));
