import { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes, { node } from 'prop-types';
import { InnerHtml } from '@hyatt/inner-html';
import { useTheme } from 'styled-components';
import { Loading } from 'components/loadingOverlay';
import { EDIT_MENU_TYPES } from 'components/begEditMenu/constants/begEditMenuConstants';
import useBrandDetails from 'hooks/useBrandDetails';
import { useEditMenuData } from 'hooks/useEditMenuData';
import { useOnOutsideClick } from 'hooks/useOnOutsideClick';
import { usePutContentFragment } from 'hooks/usePutContentFragment';
import useParagraphs from 'hooks/useParagraphs';
import { useHighlight } from 'hooks/useHighlight';
import { compareOnlyContent, isEmptyString, stringsDeepCompare } from 'utils/stringUtil';
import { MODELS_PATHS } from 'constants/aemConstants';
import { PARAGRAPH_PROPS } from 'constants/paragraphConstants';
import { translations } from 'translation/en';
import { EditableContentInput } from './inputs/EditableContentInput';
import { DiscardChangesModal } from './modals/discardChangesModal';
import { EditableTypes } from './types/editableTypes';
import { SaveBarOptions } from './saveBarOptions';
import * as S from './EditableContent.style';

const EditableContentWrapper = ({
	content,
	setContent,
	placeholder,
	style,
	fieldName,
	modulePath,
	children,
	renderSpecification,
	paragraph,
	...props
}) => {
	const initialState = useMemo(() => {
		return {
			type: EditableTypes.RESET,
		};
	}, []);

	const { logo } = useTheme();
	const { isLoadingChapters, currentType } = useBrandDetails();
	const { selectedToEdit } = useEditMenuData();
	const { loadingUpdate, updateContentFragment } = usePutContentFragment({ shouldShowToast: false });
	const { handleUpdateParagraph } = useParagraphs();
	const { handleTextHighlight } = useHighlight();

	const [state, setDispatch] = useState(initialState);
	const [initialContent, setInitialContent] = useState(content);
	const [isDifferentContent, setIsDifferentContent] = useState();

	const containerRef = useRef(null);

	const isEditModeOn = selectedToEdit === EDIT_MENU_TYPES.EDIT_INLINE;
	const isEditing = isEditModeOn && state.type === EditableTypes.EDITING;

	const onEditing = () => {
		setDispatch({ type: EditableTypes.EDITING });
	};

	const handleClose = useCallback(() => {
		if (isEditing) {
			if (isDifferentContent) {
				setDispatch({ type: EditableTypes.DISCARD_CHANGES });
				return;
			}

			setDispatch(initialState);
		}
	}, [initialState, isEditing, isDifferentContent]);

	const handleEditorChange = newText => {
		const hasChanged = !compareOnlyContent(initialContent, newText) || !stringsDeepCompare(initialContent, newText);

		setContent(newText);
		setIsDifferentContent(hasChanged);
	};

	const handleLocalStateChange = useCallback(
		currentContent => {
			const newParagraph = { ...paragraph, [fieldName]: currentContent };
			handleUpdateParagraph(newParagraph);
			setInitialContent(currentContent);
		},
		[fieldName, handleUpdateParagraph, paragraph]
	);

	const handleSave = useCallback(() => {
		updateContentFragment({
			payload: { [fieldName.toUpperCase()]: isEmptyString(content) },
			path: modulePath,
			cfType: currentType?.type,
			model: MODELS_PATHS[PARAGRAPH_PROPS.RELATOR_PARAGRAPHS_KEY.toUpperCase()],
			shouldApprove: true,
		}).then(res => {
			const isSuccess = res.some(response => response?.data);
			if (isSuccess) {
				handleLocalStateChange(content);
				setIsDifferentContent(false);
			}
		});
	}, [updateContentFragment, fieldName, content, modulePath, currentType?.type, handleLocalStateChange]);

	const handleSaveAsDraft = useCallback(() => {
		updateContentFragment({
			payload: { [fieldName.toUpperCase()]: isEmptyString(content) },
			path: modulePath,
			cfType: currentType?.type,
			model: MODELS_PATHS[PARAGRAPH_PROPS.RELATOR_PARAGRAPHS_KEY.toUpperCase()],
			shouldApprove: false,
		}).then(res => {
			const isSuccess = res.some(response => response?.data);
			if (isSuccess) {
				handleLocalStateChange(content);
				setIsDifferentContent(false);
			}
		});
	}, [updateContentFragment, fieldName, content, modulePath, currentType?.type, handleLocalStateChange]);

	const handleDiscardChanges = useCallback(() => {
		setContent(initialContent);
		setDispatch(initialState);
		setIsDifferentContent(false);
	}, [initialContent, initialState, setContent]);

	useOnOutsideClick({ ref: containerRef, action: handleClose });

	const editIcon = (
		<S.EditIcon
			id="edit-icon"
			className="icon-pencil"
			role="button"
			aria-label="edit-icon"
			tabIndex={0}
			onClick={onEditing}
		/>
	);

	return (
		<>
			<S.EditableInputContainer style={style} {...props}>
				{[isLoadingChapters, loadingUpdate].some(Boolean) && isEditing && (
					<Loading.Overlay optionalClassName="EditableInputContainer__overlay">
						<Loading.LoadingLogo logo={logo?.loading} size={logo?.size.loading} />
					</Loading.Overlay>
				)}

				{isEditModeOn && editIcon}
				{isEditModeOn && !content && !isEditing && <S.Placeholder>{placeholder}</S.Placeholder>}

				<S.ContentContainer>
					{(!isEditing || [!isEditing, isLoadingChapters, loadingUpdate].some(Boolean)) && (
						<>
							<div className="WrapperEditableContent">
								{children}
								{content && <InnerHtml body={handleTextHighlight(content)} />}
							</div>

							{renderSpecification}
						</>
					)}

					{isEditing && ![isLoadingChapters].some(Boolean) && (
						<>
							<div ref={containerRef}>
								{!isEditing && children}

								<SaveBarOptions onSave={handleSave} onSaveAsDraft={handleSaveAsDraft} onClose={handleClose} />
								<EditableContentInput
									containerId={`editable-input-html-container-${fieldName}`}
									value={content}
									placeholder={placeholder}
									onChange={handleEditorChange}
								/>
							</div>

							{renderSpecification}
						</>
					)}
				</S.ContentContainer>
			</S.EditableInputContainer>

			{state.type === EditableTypes.DISCARD_CHANGES && (
				<DiscardChangesModal open onContinueEdit={onEditing} onDiscardChanges={handleDiscardChanges} />
			)}
		</>
	);
};

EditableContentWrapper.propTypes = {
	content: PropTypes.string,
	modulePath: PropTypes.string.isRequired,
	setContent: PropTypes.func.isRequired,
	fieldName: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	style: PropTypes.objectOf(Object),
	children: node,
	renderSpecification: node,
	paragraph: PropTypes.objectOf(Object),
};

EditableContentWrapper.defaultProps = {
	content: '',
	placeholder: translations.INSERT_TEXT_HERE,
	style: null,
	children: null,
	renderSpecification: null,
	paragraph: {},
};

export { EditableContentWrapper };
