/* eslint-disable indent */
import axios from 'API/axios/axios';
import { isEmpty } from 'utils/objectUtils';
import { generateSlug } from 'utils/stringUtil';
import { begSqlApi } from 'constants/serviceConstants';
import { NO_TOPIC } from 'constants/quickSheetConstants';
import { BASE_DAM_BEG_PATH } from 'constants/aemConstants';
import { CHAPTERS_TYPE, PAGE_TYPES } from 'constants/pageConstants';

export const getChaptersByBrand = params => {
	const { brandId, pagePath } = params;
	const url = `${process.env.REACT_APP_API_URL}${begSqlApi}/list-chapter-by-page-path`;

	return axios.get(url, {
		params: {
			pagePath: `beg-${brandId}`,
			cfType: CHAPTERS_TYPE,
			parameter: `;brands=${brandId};app=${BASE_DAM_BEG_PATH};_path=${pagePath}`,
		},
	});
};

const mappedSections = data => {
	if (!data.BEG_CHAPTER_RELATOR_FK_CHAPTER_ID) {
		return [];
	}

	return data.BEG_CHAPTER_RELATOR_FK_CHAPTER_ID?.filter(
		section =>
			section.BEG_CHAPTER_RELATOR_SECTION_ID &&
			(Array.isArray(section.BEG_CHAPTER_RELATOR_SECTION_ID)
				? !isEmpty(section.BEG_CHAPTER_RELATOR_SECTION_ID?.[0])
				: !isEmpty(section.BEG_CHAPTER_RELATOR_SECTION_ID))
	)
		.sort(
			(item1, item2) => +item1.BEG_CHAPTER_RELATOR_SECTION_DISPLAY_ORDER - +item2.BEG_CHAPTER_RELATOR_SECTION_DISPLAY_ORDER
		)
		.map(section => {
			const sectionData = section.BEG_CHAPTER_RELATOR_SECTION_ID;

			return {
				type: PAGE_TYPES.SECTION_TYPE,
				displayOrder: section.BEG_CHAPTER_RELATOR_SECTION_DISPLAY_ORDER,
				relatorPath: section._path,
				publishInfo: sectionData?.publishInfo,
				approved: sectionData?.approved,
				path: sectionData?._path,
				title: sectionData?.BEG_SECTION_TITLE,
				body: sectionData?.BEG_SECTION_BODY,
				jobLevel: sectionData?.BEG_SECTION_JOBLEVEL,
				createDate: sectionData?.CREATION_DATE,
				creator: sectionData?.CREATOR,
				lastModified: sectionData?.LASTMODIFIED,
				modificationDate: sectionData?.MODIFICATION_DATE,
				modifier: sectionData?.MODIFIER,
				objectId: sectionData?.OBJECTID,
				vcmId: sectionData?.VCMID,
				visibleToFranchise: sectionData?.VISIBLE_TO_FRANCHISE,
				brands: sectionData?.brands,
				regions: sectionData?.regions,
				pagePath: sectionData?.pagePath,
				articles: !sectionData?.BEG_SECTION_RELATOR_FK_SECTION_ID
					? []
					: sectionData.BEG_SECTION_RELATOR_FK_SECTION_ID?.filter(
							article =>
								article.BEG_SECTION_RELATOR_ARTICLE_ID &&
								(Array.isArray(article.BEG_SECTION_RELATOR_ARTICLE_ID)
									? !isEmpty(article.BEG_SECTION_RELATOR_ARTICLE_ID?.[0])
									: !isEmpty(article.BEG_SECTION_RELATOR_ARTICLE_ID))
					  )
							.sort(
								(item1, item2) =>
									+item1.BEG_SECTION_RELATOR_ARTICLE_DISPLAY_ORDER -
									+item2.BEG_SECTION_RELATOR_ARTICLE_DISPLAY_ORDER
							)
							.map(article => {
								const articleData = article.BEG_SECTION_RELATOR_ARTICLE_ID;

								return {
									type: PAGE_TYPES.ARTICLE_TYPE,
									displayOrder: article?.BEG_SECTION_RELATOR_ARTICLE_DISPLAY_ORDER,
									relatorPath: article?._path,
									publishInfo: articleData?.publishInfo,
									approved: articleData?.approved,
									path: articleData?._path,
									title: articleData?.BEG_ARTICLE_TITLE,
									jobLevel: articleData?.BEG_ARTICLE_JOBLEVEL,
									creationDate: articleData?.CREATION_DATE,
									creator: articleData?.CREATOR,
									lastModified: articleData?.LASTMODIFIED,
									modificationDate: articleData?.MODIFICATION_DATE,
									modifier: articleData?.MODIFIER,
									objectId: articleData?.OBJECTID,
									vcmId: articleData?.VCMID,
									relatedTerms: articleData?.RELATED_TERMS,
									brands: articleData?.brands,
									regions: articleData?.regions,
									pagePath: articleData?.pagePath,
								};
							}),
			};
		});
};

const mappedQuickSheet = data => {
	if (!data.quicksheet) {
		return [];
	}

	const quickSheetPath = data.quicksheet.path;
	const sectionKeyMaps = data?.quicksheet?.keyMaps?.find(keyMap => keyMap?.type === 'operation')?.options;
	const articleKeyMaps = data?.quicksheet?.keyMaps?.find(keyMap => keyMap?.type === 'topic')?.options;
	const isDisableEdit = true;

	return data.quicksheet.operations
		.map((operation, i) => {
			const operationName = generateSlug(operation.name);
			const operationPath = `${quickSheetPath}/${operationName}`;

			const sectionDisplayOrder = sectionKeyMaps
				?.find(option => option.content.toLowerCase() === operation.name.toLowerCase())
				?.displayOrder?.toString();

			return {
				vcmId: operationPath,
				title: operation.name,
				path: operationPath,
				displayOrder: sectionDisplayOrder || `${i + 1}`,
				isDisableEdit,

				articles:
					operation.rows[0].name === NO_TOPIC
						? []
						: operation.rows
								.map((topic, j) => {
									const topicName = generateSlug(topic.name);
									const topicPath = `${quickSheetPath}/${operationName}/${topicName}`;

									const articleDisplayOrder = articleKeyMaps
										?.find(option => option.content.toLowerCase() === topic.name.toLowerCase())
										?.displayOrder?.toString();

									return {
										vcmId: topicPath,
										title: topic.name,
										path: topicPath,
										displayOrder: articleDisplayOrder || `${j + 1}`,
										isDisableEdit,
									};
								})
								.sort((a, b) => +a.displayOrder - +b.displayOrder),
			};
		})
		.sort((a, b) => +a.displayOrder - +b.displayOrder);
};

export const fromChaptersService = data => {
	return {
		type: PAGE_TYPES.CHAPTER_TYPE,
		path: data._path,
		title: data.BEG_CHAPTER_TITLE,
		chapterType: data.BEG_CHAPTER_TYPE,
		brands: data.brands,
		regions: data.regions,
		creator: data.CREATOR,
		chapterIcon: data.BEG_CHAPTER_ICON,
		displayOrder: data.BEG_CHAPTER_DISPLAY_ORDER,
		contentDisplayOrder: data.BEG_CHAPTER_CONTENT_DISPLAY_ORDER,
		jobLevel: data.BEG_CHAPTER_JOBLEVEL,
		creationDate: data.CREATION_DATE,
		lastModified: data.LASTMODIFIED,
		modificationDate: data.MODIFICATION_DATE,
		modifier: data.MODIFIER,
		objectId: data.OBJECTID,
		vcmId: data.VCMID,
		pagePath: data.pagePath,
		model: data._model,
		approved: data.approved,
		publishInfo: data.publishInfo,
		isDisableEdit: false,
		quickSheetPath: data.quicksheet && data.quicksheet?.path,
		sections: [...mappedSections(data), ...mappedQuickSheet(data)],
	};
};
