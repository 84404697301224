import classNames from 'classnames';
import { any, bool, func, string } from 'prop-types';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { CalendarAnalytics } from '../datePicker/CalendarAnalytics';
import { translation } from '../../constants/translation';
import { getFormattedDate } from '../../utils/dateUtils';
import { FormFieldLabelAnalytics } from '../formFieldLabelAnalytics/FormFieldLabelAnalytics';
import './FormFieldDateAnalytics.scss';

export const FormFieldDateAnalytics = forwardRef(
	({ required, hasError, control, name, value, setValue, disabled, label, format }, ref) => {
		const [date, setDate] = useState(format(value));

		useEffect(() => {
			setDate(format(value));
		}, [format, value]);

		const [isCalendarVisible, setIsCalendarVisible] = useState(false);
		const buttonRef = useRef();
		const calendarRef = useRef();

		const openCalendar = useCallback(
			e => {
				if (e.key === 'Enter') {
					setIsCalendarVisible(true);
				}
			},
			[setIsCalendarVisible]
		);

		const closeCalendar = useCallback(
			e => {
				const { target } = e;

				if (!buttonRef.current?.contains(target) && !calendarRef.current?.contains(target)) {
					setIsCalendarVisible(false);
				}
			},
			[setIsCalendarVisible]
		);

		useEffect(() => {
			if (isCalendarVisible) {
				document.addEventListener('click', closeCalendar);
			}

			return () => document.removeEventListener('click', closeCalendar);
		}, [closeCalendar, isCalendarVisible]);

		const handleDateChange = useCallback(
			newDate => {
				setDate(newDate);
				setValue(name, newDate, {
					shouldValidate: true,
					shouldDirty: true,
				});
				setIsCalendarVisible(false);
			},
			[setValue, name]
		);

		return (
			<div className="FormFieldDateAnalytics">
				<FormFieldLabelAnalytics label={label} />

				<div className="FormFieldDateAnalytics__content">
					<div ref={buttonRef} onClick={() => setIsCalendarVisible(!disabled)} role="button" tabIndex={0}>
						<Controller
							name={name}
							control={control}
							rules={{ required: required ? translation.REQUIRED : false }}
							render={({ field: { value: fieldValue, onChange: fieldOnChange, ...rest } }) => (
								<input
									className={classNames('FormFieldDateAnalytics__input', {
										'FormFieldDateAnalytics__input--has-error': hasError,
										'FormFieldDateAnalytics__input--is-active': isCalendarVisible,
										'FormFieldDateAnalytics__input--is-disabled': disabled,
									})}
									ref={ref}
									disabled={disabled}
									type="text"
									onKeyDown={openCalendar}
									onChange={() => {}}
									value={date}
									autoComplete="off"
									{...rest}
								/>
							)}
						/>

						<span className="FormFieldDateAnalytics__icon-input">
							<i className="icon-calendar" />
						</span>
					</div>

					<CalendarAnalytics
						ref={calendarRef}
						dateValue={date}
						handleDateChange={handleDateChange}
						isVisible={isCalendarVisible}
					/>
				</div>
			</div>
		);
	}
);

FormFieldDateAnalytics.propTypes = {
	setValue: func.isRequired,
	hasError: bool.isRequired,
	control: any.isRequired,
	errors: any,
	required: bool,
	label: string,
	disabled: bool,
	name: string.isRequired,
	value: string,
	format: func,
};

FormFieldDateAnalytics.defaultProps = {
	required: false,
	label: '',
	errors: null,
	disabled: false,
	value: '',
	format: v => getFormattedDate(v, 'yyyy-MM-dd'),
};
