import CentricHorizontalLogo from 'assets/img/logos/centric-horizontal.svg';
import CentricLogo from 'assets/img/logos/centric-logo.svg';
import complementaryTheme from 'themes/complementary/complementary';
import colors from './centricColors';
import typographyTheme from './centricTypo';

const logo = {
	footer: CentricHorizontalLogo, // logo in the footer
	loading: CentricLogo, // logo in the loading
	sidebar: CentricLogo, // logo in the sidebar
	mobile: CentricLogo, // logo in the navbar
	size: {
		sidebar: {
			width: '177px',
			height: '68px',
		},
		footer: {
			width: '171px',
			height: '18px',
		},
		navbar: {
			width: '177px',
			height: '125px',
		},
		loading: {
			width: '168px',
			height: '91px',
		},
	},
};

const copyrightText = {
	padding: '5px 0 5px 8px',
};

const centric = {
	brandId: 'hc',
	name: 'Hyatt Centric',
	logo,
	color: colors,
	copyrightText,

	font: {
		family: {
			helveticaNeue: 'Helvetica Neue',
			gothamBook: 'Gotham Book',
			gothamLight: 'Gotham Light',
			gothamMedium: 'Gotham Medium',
			gothamBold: 'Gotham Bold',
			arialSansSerif: 'Arial, Helvetica, sans-serif',
			poynterRegular: 'Poynter Regular',
			archerMedium: 'Archer Medium',
			robotoMonoRegular: 'RobotoMono Regular',
		},
	},
};

const centricTheme = {
	...centric,
	...typographyTheme(centric),
	...complementaryTheme,
};

export default centricTheme;
