/* eslint-disable indent */
import { useCallback, useEffect, useReducer } from 'react';
import useMutableRef from './useMutableRef';

export const ResponseTypes = {
	LOADING: 'loading',
	SUCCESS: 'success',
	ERROR: 'error',
	CLEAR: 'clear',
};

const requestReducer =
	initialData =>
	({ data = initialData }, action) => {
		switch (action.type) {
			case ResponseTypes.LOADING:
				return {
					loading: true,
					data,
				};
			case ResponseTypes.SUCCESS:
				return { loading: false, data: action.data };
			case ResponseTypes.ERROR:
				return { loading: false, error: action.error };
			case ResponseTypes.CLEAR:
				return { loading: false, data: undefined };
			default:
				return { loading: false, data };
		}
	};

export const useRequest = (request, { autoRun, initialData } = {}) => {
	const [{ data, loading, error }, dispatch] = useReducer(requestReducer(initialData), {
		data: initialData,
		loading: !!autoRun,
	});

	const loadingRef = useMutableRef(loading);
	const requestRef = useMutableRef(request);

	const run = useCallback(
		params => {
			if (!loadingRef.current) {
				dispatch({ type: ResponseTypes.LOADING });
			}

			const _run = async () => {
				try {
					const response = await requestRef.current(params);
					const result = { type: ResponseTypes.SUCCESS, data: response };
					dispatch(result);
					return response;
				} catch (e) {
					const err = { type: ResponseTypes.ERROR, error: e };
					dispatch(err);
					// TODO: throw error instead of return it and handle cases.
					// throw err;
					return err;
				}
			};

			return _run();
		},
		[loadingRef, requestRef]
	);

	const clear = useCallback(() => {
		dispatch({ type: ResponseTypes.CLEAR });
	}, []);

	useEffect(() => {
		if (autoRun) {
			run();
		}
	}, [autoRun, run]);

	return { loading, data, error, run, clear };
};
