import PropTypes from 'prop-types';
import React from 'react';
import './AppContainer.scss';

const AppContainer = ({ children }) => (
	<main className="AppContainer" id="app-container">
		{children}
	</main>
);

AppContainer.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AppContainer;
