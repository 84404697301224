import { verifyFileStatus } from 'services/assetService';
import { useState } from 'react';
import { useRequest } from './useRequest';
import useBrandDetails from './useBrandDetails';

export const useVerifyFileStatus = () => {
	const { currentType } = useBrandDetails();

	const { response, error, run, clear } = useRequest(verifyFileStatus);

	const [loadingVerification, setLoadingVerification] = useState(false);

	const verifyFile = processId => {
		return run({
			pagePath: currentType?.path,
			cfType: `${currentType?.type?.toLowerCase()}`,
			processId,
		});
	};

	const checkFileStatus = processId => {
		return new Promise(resolve => {
			setLoadingVerification(true);

			const intervalId = setInterval(async () => {
				const resp = await verifyFile(processId);

				const { body } = resp?.data || '';

				if (body !== '') {
					clearInterval(intervalId);
					setLoadingVerification(false);
					resp.data.body = JSON.parse(body);
					resolve(resp);
				}
			}, 1000);
		});
	};

	return {
		loadingVerification,
		response,
		error,
		clear,
		checkFileStatus,
	};
};
