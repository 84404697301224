import { Button } from 'components/button';
import React from 'react';
import * as S from './HelpButton.style';

const HelpButton = () => {
	const onSendEmail = e => {
		e.preventDefault();
		window.location.href = 'mailto:brandexperienceassist@hyatt.com';
	};

	const renderDesktop = () => (
		<S.BoxDesktop>
			<Button variant="tertiary" onClick={onSendEmail}>
				?
			</Button>
		</S.BoxDesktop>
	);

	const renderMobile = () => (
		<S.BoxMobile>
			<Button variant="outlined" onClick={onSendEmail}>
				?
			</Button>
		</S.BoxMobile>
	);

	return (
		<>
			{renderDesktop()}
			{renderMobile()}
		</>
	);
};

export { HelpButton };
