import styled from 'styled-components/macro';

export const Box = styled.div(() => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	padding: 0,
	background: 'none',
	borderRadius: '4px',
	width: '38px',
	height: '38px',
}));

export const Icon = styled.i(({ theme: { color, typo } }) => ({
	color: color.interactive.outlined.default.label,
	...typo.button.icon,
}));
