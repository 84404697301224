import classNames from 'classnames';
import { bool, string } from 'prop-types';
import './FormFieldLabel.scss';

const FormFieldLabel = ({ label, required, title }) => {
	const classes = classNames('FormFieldLabel', {
		'FormFieldLabel--required': required,
	});

	return (
		<label className={classes} title={title}>
			{label}
			{required ? <span>&nbsp;*</span> : ''}
		</label>
	);
};

FormFieldLabel.propTypes = {
	label: string,
	title: string,
	required: bool,
};

FormFieldLabel.defaultProps = {
	label: '',
	title: '',
	required: false,
};

export default FormFieldLabel;

export { FormFieldLabel };
