import BackgroundHeader from 'assets/img/bg-header-andaz.jpg';
import complementaryTheme from 'themes/complementary/complementary';

const { neutral } = complementaryTheme;

const colors = {
	background: {
		banner: {
			background: `url(${BackgroundHeader}) no-repeat`, // header
			backgroundPosition: '85%',
		},
		secondary: '#000000', // sidebar and navbar (mobile)
		tertiary: '#F7F7F7', // footer
	},
	interactive: {
		// terms and conditions primary button modal. remove image primary button modal
		primary: {
			default: {
				background: '#f15f3f',
				label: '#fff',
				border: '#f15f3f',
			},
			hover: {
				background: '#fff',
				label: '#f15f3f',
				border: '#f15f3f',
			},
		},
		// search button
		primary02: {
			default: {
				background: '#000000',
				label: '#fff',
				border: '#000000',
				opacity: '1',
			},
		},
		// file button
		primary05: {
			default: {
				background: '#F15F3F',
				label: '#fff',
				border: '#F15F3F',
			},
			hover: {
				background: '#fff',
				label: '#F15F3F',
				border: '#F15F3F',
			},
		},
		// terms & conditions secondary button modal
		secondary: {
			default: {
				background: '#cb1976',
				label: '#fff',
				border: '#cb1976',
			},
			hover: {
				background: '#fff',
				label: '#cb1976',
				border: '#cb1976',
			},
		},
		// sign out button
		secondary02: {
			default: {
				background: '#000000',
				label: '#ffffff',
			},
			hover: {
				background: '#f5f2e7',
				label: '#e38800',
			},
		},
		// button approve modal
		secondary03: {
			default: {
				background: '#333333',
				label: '#fff',
				border: '#333333',
			},
			hover: {
				background: '#fff',
				label: '#333333',
				border: '#333333',
			},
		},
		// help button
		tertiary: {
			default: {
				background: '#fff',
				label: '#f15f3f',
			},
		},
		printModalButtons: {
			default: {
				background: '#f15f3f',
				label: '#fff',
				border: '#f15f3f',
			},
			hover: {
				background: '#fff',
				label: '#f15f3f',
				border: '#f15f3f',
			},
		},
		// properties and edit image modals buttons
		tertiary03: {
			default: {
				background: '#fff',
				label: '#181818',
				border: '#ccc',
			},
			hover: {
				background: '#fff',
				label: '#181818',
				border: '#181818',
			},
		},
		// mobile
		outlined: {
			default: {
				label: '#ffffff', // mobile search and help buttons
				icon: '#ffffff', // mobile menu button
				border: '#ffffff', // mobile buttons
			},
		},
		// header buttons
		inverse: {
			default: {
				label: '#fff',
			},
		},
		discardChangesButtons: {
			default: {
				background: '#f15f3f',
				label: '#fff',
				border: '#f15f3f',
			},
			hover: {
				background: '#fff',
				label: '#f15f3f',
				border: '#f15f3f',
			},
		},
		printPageButtons: {
			default: {
				background: '#cb1976',
				label: '#fff',
				border: '#cb1976',
			},
			hover: {
				background: '#fff',
				label: '#cb1976',
				border: '#cb1976',
			},
		},
		dropDownButtons: {
			default: {
				label: '#fff',
			},
			active: {
				color: '#00000020',
				label: '#262626',
			},
			focusWithin: {
				background: 'transparent',
				label: '#fff',
				border: 'none',
			},
			hover: {
				background: 'transparent',
				label: '#fff',
				border: 'none',
			},
		},

		lightest: 'rgba(255, 255, 255, 0.1)',
	},
	field: {
		// search input
		primary: {
			text: '#000000',
			placeholder: '#898b8e',
		},
		// inputs edit image modal
		secondary: {
			text: '#676767',
			placeholder: '#676767',
		},
		// input properties modal
		tertiary: {
			text: '#676767',
			placeholder: '#676767',
		},
	},
	text: {
		primary: '#636466', // footer
		primary02: '#000000', // title chapter and section page

		titleModule: '#000000',
		headline01: '#000000',
		headline02: '#000000',
		headline03: '#000000',
		headline04: '#000000',
		headline05: '#000000',
		headline06: '#333333',

		paragraph: '#000000', // paragraph content page
		specificationTitle: '#000000', // specification title

		approveModalTitle: '#666666',
		approveModalDescription: '#999999',

		addFilesSubtitle: '#999999',

		secondary: '#000000', // terms and conditions title modal
		secondary02: '#000000', // title discard changes
		secondary03: '#181818', // title edit image modal

		tertiary: '#000000', // terms and conditions description modal
		tertiary03: '#181818', // title properties modal

		quaternary: '#00000', // title print modal
		quaternary02: '#333', // labels subtitle edit image modal
		quaternary03: '#000', // labels justification edit image modal

		quinary: '#000000', // subtitles items print modal
		quinary02: '#333', // subtitles properties modal
		quinary03: '#333', // title remove image modal
		senary: '#000000', // title article page

		imageCaption: '#000000', // image caption

		bullet: '#000000', // list item bullet
	},
	link: {
		// sidebar item chapter
		primary: {
			default: {
				label: '#ffffff',
			},
			hover: {
				label: '#ffffff',
			},
		},
		// sidebar item section
		secondary: {
			default: {
				label: '#ffffff',
			},
			hover: {
				background: 'rgba(255, 255, 255, 0.1)',
				label: '#ffffff',
			},
			active: {
				label: '#ffffff',
			},
		},
		// sidebar item article
		tertiary: {
			default: {
				label: '#ffffff',
			},
			hover: {
				background: 'rgba(255, 255, 255, 0.1)',
				label: '#ffffff',

				mobile: {
					label: '#ffffff',
				},
			},
			active: {
				label: '#ffffff',
			},
		},
		// breadcrumb
		quaternary: {
			default: {
				label: '#000000',
			},
			hover: {
				label: '#333',
			},
			active: {
				label: '#00a5b5',
			},
		},
	},
	icon: {
		primary: '#fff', // header dropdown arrow icon
		primary02: '#00a5b5', // print icon
		chapterArrow: '#ffffff', // chapter arrow icon
		sectionArrow: '#ffffff', // section arrow icon
		breadcrumbArrow: '#555555', // breadcrumb arrow icon
	},
	border: {
		primary: '#000000', // search input
		secondary: '#B9b5aa',
		tertiary: '#000000', // modal top border
		quaternary: '#999999', // module divider
	},
	shadow: {
		primary: 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 0, 0, 0.6)', // search input
		secondary: 'inset 0 1px 0 rgba(255, 255, 255, 0.1)', // menu
	},
	neutral,
	table: {
		row: {
			background: {
				primary: '#f5f2e7',
				secondary: 'transparent',
			},
			cell: {
				label: '#333333',
				border: '2px solid #5e2750',
			},
		},
	},
};

export default colors;
