import breathlessLogo from 'assets/img/logos/breathless.svg';
import breathlessWhiteLogo from 'assets/img/logos/breathless-white-v2.png';
import complementaryTheme from 'themes/complementary/complementary';
import colors from './breathlessColors';
import typographyTheme from './breathlessTypo';

const logo = {
	footer: breathlessLogo,
	loading: breathlessLogo, // logo in the loading
	sidebar: breathlessWhiteLogo,
	mobile: breathlessWhiteLogo, // logo in the navbar
	size: {
		sidebar: {
			width: '200px',
			height: '90px',
		},
		footer: {
			width: '190px',
			height: '90px',
		},
		navbar: {
			width: '200px',
			height: '90px',
		},
		loading: {
			width: '168px',
			height: '91px',
		},
	},
};

const breathless = {
	brandId: 'br',
	name: 'breathless',
	logo,
	color: colors,

	font: {
		family: {
			helveticaNeue: 'Helvetica Neue',
			gothamBook: 'Gotham Book',
			gothamLight: 'Gotham Light',
			calibri: 'Calibri, Arial',
			arialSansSerif: 'Arial, Helvetica, sans-serif',
			poynterRegular: 'Poynter Regular',
			openSans: 'Open Sans',
			robotoMonoRegular: 'RobotoMono Regular',
			notoSansLight: 'NotoSans Light',
			notoSansRegular: 'NotoSans Regular',
			notoSansSemiBold: 'NotoSans Semi Bold',
			notoSansBold: 'NotoSans Bold',
		},
	},
};

const breathlessTheme = {
	...breathless,
	...typographyTheme(breathless),
	...complementaryTheme,
};

export default breathlessTheme;
