import PropTypes from 'prop-types';
import React, { useEffect, forwardRef } from 'react';
import * as S from './BrandMenu.style';

const BrandMenu = forwardRef(({ open, children }, ref) => {
	const rootElement = document.getElementById('root');
	const appElement = document.getElementById('App');

	useEffect(() => {
		const rootOverflowY = rootElement?.style.overflowY;
		const appOverflowY = appElement?.style.overflowY;

		if (rootElement && appElement) {
			rootElement.style.overflowY = 'inherit';
			appElement.style.overflowY = 'inherit';
		}

		return () => {
			if (rootElement && appElement) {
				rootElement.style.overflowY = rootOverflowY;
				appElement.style.overflowY = appOverflowY;
			}
		};
	}, [open, rootElement, appElement]);

	return (
		<S.BrandMenu isShow={open}>
			<S.Container ref={ref}>{children}</S.Container>
		</S.BrandMenu>
	);
});

BrandMenu.propTypes = {
	open: PropTypes.bool,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

BrandMenu.defaultProps = {
	open: false,
};

export { BrandMenu };
