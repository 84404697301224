import { format } from 'date-fns';
import { useCallback } from 'react';
import { useAnalyticsContext } from './useAnalyticsContext';

export const useBarChartAnalytics = () => {
	const { locationType, metrics, getLabelByAbbr } = useAnalyticsContext();

	const { nameKey } = locationType;

	const convertToDate = useCallback(dateString => {
		const date = dateString ? new Date(`${dateString} 00:00:00`) : new Date();
		return format(date, 'dd-MMM-yy');
	}, []);

	const getMetricsWithConvertedLabel = useCallback(() => {
		return metrics.map(item => ({
			...item,
			date: convertToDate(item.date),
			[nameKey]: getLabelByAbbr(item[nameKey]),
		}));
	}, [convertToDate, getLabelByAbbr, metrics, nameKey]);

	const convertLabelsToGraphic = useCallback(() => {
		const labels = [...new Set(getMetricsWithConvertedLabel().map(item => item[nameKey]))];

		return labels.sort();
	}, [getMetricsWithConvertedLabel, nameKey]);

	const convertDataToGraphic = useCallback(() => {
		const arrayWithConvertedDate = getMetricsWithConvertedLabel();

		return arrayWithConvertedDate.reduce((acc, current) => {
			if (acc.filter(arr => arr?.date === current.date).length === 0) {
				acc.push({
					date: current.date,
					[current[nameKey]]: current.total,
					total: current.total,
				});
				return acc;
			}

			const index = acc.findIndex(item => item.date === current.date);

			acc[index] = {
				...acc[index],
				[current[nameKey]]: acc[index][current[nameKey]] ? acc[index][current[nameKey]] + current.total : current.total,
				total: acc[index].total + current.total,
			};

			return acc;
		}, []);
	}, [getMetricsWithConvertedLabel, nameKey]);

	return { convertDataToGraphic, convertLabelsToGraphic };
};
