import styled from 'styled-components/macro';

export const Box = styled.div(() => ({
	display: 'flex',
	flexDirection: 'column',
}));

export const SpecificationOnPrintPage = styled.div(() => ({
	display: 'flex',
	flexWrap: 'wrap',
	width: '100%',
}));
