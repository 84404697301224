import complementaryTheme from 'themes/complementary/complementary';

const { neutral } = complementaryTheme;

const colors = {
	interactive: {
		primary: {
			default: {
				background: '#333',
				label: '#fff',
				border: '#333',
			},
			hover: {
				background: '#808080',
				label: '#fff',
				border: '#808080',
			},
		},
		secondary: {
			default: {
				background: '#929292',
				label: '#fff',
				border: '#929292',
			},
			hover: {
				background: '#808080',
				label: '#444',
				border: '#808080',
			},
		},
		// publish and approve modal button
		secondary03: {
			default: {
				background: '#fff',
				label: '#000',
				border: '#333333',
			},
			hover: {
				background: '#fff',
				label: '#000',
				border: '#333333',
			},
		},
	},
	text: {
		primary: '#333',
		secondary: '#333',
		tertiary: '#555',
	},
	border: {
		primary: '#333',
		secondary: '#929292',
		tertiary: '#333',
	},
	shadow: {
		primary: 'rgba(0, 0, 0, 0.5)',
	},
	neutral,
	none: 'none',
};

export default colors;
