import PropTypes from 'prop-types';
import React from 'react';
import * as S from './Label.style';

const Label = ({ variant, children }) => {
	return <S.Label variant={variant}>{children}</S.Label>;
};

Label.propTypes = {
	variant: PropTypes.oneOf([
		'primary',
		'primary-02',
		'primary-03',
		'primary-04',
		'primary-05',
		'secondary',
		'secondary-02',
		'secondary-03',
		'tertiary',
		'tertiary-02',
		'tertiary-03',
		'outlined',
		'gray-border',
		'gray-border-secondary',
		'gray-border-only',
		'dark-gray',
	]),
	children: PropTypes.node,
};

Label.defaultProps = {
	variant: 'primnary',
	children: '',
};

export { Label };
