import { BASE_DAM_BEG_PATH, BASE_BEG_PATH } from 'constants/aemConstants';

export const brands = {
	CORPORATE: {
		name: 'Corporate',
		routePath: 'Corporate',
		brandId: '71',
		searchBrandId: 'pf',
		parentPath: `${BASE_DAM_BEG_PATH}/corporate`,
		pagePath: `${BASE_BEG_PATH}/corporate`,
	},
	WORLDOFHYATT: {
		name: 'World of Hyatt',
		routePath: 'WorldofHyatt',
		brandId: 'wh',
		parentPath: `${BASE_DAM_BEG_PATH}/world-of-hyatt`,
		pagePath: `${BASE_BEG_PATH}/world-of-hyatt`,
	},
	PARKHYATT: {
		name: 'Park Hyatt',
		routePath: 'ParkHyatt',
		brandId: 'ph',
		parentPath: `${BASE_DAM_BEG_PATH}/park-hyatt`,
		pagePath: `${BASE_BEG_PATH}/park-hyatt`,
	},
	GRANDHYATT: {
		name: 'Grand Hyatt',
		routePath: 'GrandHyatt',
		brandId: 'gh',
		parentPath: `${BASE_DAM_BEG_PATH}/grand-hyatt`,
		pagePath: `${BASE_BEG_PATH}/grand-hyatt`,
	},
	HYATTREGENCY: {
		name: 'Hyatt Regency',
		routePath: 'HyattRegency',
		brandId: 'hr',
		parentPath: `${BASE_DAM_BEG_PATH}/hyatt-regency`,
		pagePath: `${BASE_BEG_PATH}/hyatt-regency`,
	},
	ALILA: {
		name: 'Alila',
		routePath: 'Alila',
		brandId: 'al',
		parentPath: `${BASE_DAM_BEG_PATH}/alila`,
		pagePath: `${BASE_BEG_PATH}/alila`,
	},
	HYATT: {
		name: 'Hyatt',
		routePath: 'Hyatt',
		brandId: 'hy',
		parentPath: `${BASE_DAM_BEG_PATH}/hyatt`,
		pagePath: `${BASE_BEG_PATH}/hyatt`,
	},
	HYATTRESIDENCECLUB: {
		name: 'Hyatt Vacation Club',
		routePath: 'HyattResidenceClub',
		brandId: 'rc',
		parentPath: `${BASE_DAM_BEG_PATH}/hyatt-residence-club`,
		pagePath: `${BASE_BEG_PATH}/hyatt-residence-club`,
	},
	HYATTHOUSE: {
		name: 'Hyatt House',
		routePath: 'HyattHouse',
		brandId: 'hh',
		parentPath: `${BASE_DAM_BEG_PATH}/hyatt-house`,
		pagePath: `${BASE_BEG_PATH}/hyatt-house`,
	},
	HYATTPLACE: {
		name: 'Hyatt Place',
		routePath: 'HyattPlace',
		brandId: 'hp',
		parentPath: `${BASE_DAM_BEG_PATH}/hyatt-place`,
		pagePath: `${BASE_BEG_PATH}/hyatt-place`,
	},
	HYATTZIVA: {
		name: 'Hyatt Ziva',
		routePath: 'HyattZiva',
		brandId: 'zv',
		parentPath: `${BASE_DAM_BEG_PATH}/hyatt-ziva`,
		pagePath: `${BASE_BEG_PATH}/hyatt-ziva`,
	},
	HYATTZILARA: {
		name: 'Hyatt Zilara',
		routePath: 'HyattZilara',
		brandId: 'zl',
		parentPath: `${BASE_DAM_BEG_PATH}/hyatt-zilara`,
		pagePath: `${BASE_BEG_PATH}/hyatt-zilara`,
	},
	MIRAVAL: {
		name: 'Miraval',
		routePath: 'Miraval',
		brandId: 'mv',
		parentPath: `${BASE_DAM_BEG_PATH}/miraval`,
		pagePath: `${BASE_BEG_PATH}/miraval`,
	},
	ANDAZ: {
		name: 'Andaz',
		routePath: 'Andaz',
		brandId: 'az',
		parentPath: `${BASE_DAM_BEG_PATH}/andaz`,
		pagePath: `${BASE_BEG_PATH}/andaz`,
	},
	THOMPSON: {
		name: 'Thompson',
		routePath: 'Thompson',
		brandId: 'th',
		parentPath: `${BASE_DAM_BEG_PATH}/thompson`,
		pagePath: `${BASE_BEG_PATH}/thompson`,
	},
	HYATTCENTRIC: {
		name: 'Hyatt Centric',
		routePath: 'HyattCentric',
		brandId: 'hc',
		parentPath: `${BASE_DAM_BEG_PATH}/hyatt-centric`,
		pagePath: `${BASE_BEG_PATH}/hyatt-centric`,
	},
	THEUNBOUNDCOLLECTION: {
		name: 'The Unbound Collection',
		routePath: 'TheUnboundCollection',
		brandId: 'ub',
		parentPath: `${BASE_DAM_BEG_PATH}/the-unbound-collection`,
		pagePath: `${BASE_BEG_PATH}/the-unbound-collection`,
	},
	DESTINATION: {
		name: 'Destination by Hyatt',
		routePath: 'Destination',
		brandId: 'dh',
		parentPath: `${BASE_DAM_BEG_PATH}/destination`,
		pagePath: `${BASE_BEG_PATH}/destination`,
	},
	CAPTION: {
		name: 'Caption by Hyatt',
		routePath: 'Caption',
		brandId: 'cp',
		parentPath: `${BASE_DAM_BEG_PATH}/caption`,
		pagePath: `${BASE_BEG_PATH}/caption`,
	},
	JOIEDEVIVRE: {
		name: 'JDV by HYATT',
		routePath: 'JoiedeVivre',
		brandId: 'jd',
		parentPath: `${BASE_DAM_BEG_PATH}/JoiedeVivre`,
		pagePath: `${BASE_BEG_PATH}/joie-de-vivre`,
	},
	ALUA: {
		name: 'ALUA HOTELS & RESORTS',
		routePath: 'Alua',
		brandId: 'au',
		parentPath: `${BASE_DAM_BEG_PATH}/alua`,
		pagePath: `${BASE_BEG_PATH}/alua`,
	},
	BREATHLESS: {
		name: 'BREATHLESS RESORTS & SPAS',
		routePath: 'Breathless',
		brandId: 'br',
		parentPath: `${BASE_DAM_BEG_PATH}/breathless`,
		pagePath: `${BASE_BEG_PATH}/breathless`,
	},
};

export const allBrands = Object.values(brands);

export const findBrandByName = name => {
	return brands[name?.toUpperCase()] || brands['CORPORATE'];
};

export const findBrandByBrandId = brandId => {
	const brand = allBrands.find(b => b.brandId.toUpperCase() === brandId?.toUpperCase());
	return brand || brands['CORPORATE'];
};

export const findBrandByPagePath = pagePath => {
	return allBrands.find(brand => brand.pagePath === pagePath);
};

export const isBrandIdValid = brandId => {
	return allBrands.some(b => b.brandId.toUpperCase() === brandId?.toUpperCase());
};
