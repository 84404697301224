import classNames from "classnames";
import { string } from "prop-types";
import React, { forwardRef } from "react";
import "./FormFieldCheckbox.scss";

const FormFieldCheckbox = forwardRef(
  ({ className, label, hasError, ...props }, ref) => {
    return (
      <label className={classNames("FormFieldCheckbox", className)}>
        <input
          type="checkbox"
          className="FormFieldCheckbox__input"
          ref={ref}
          {...props}
        />
        <span className="FormFieldCheckbox__label">{label}</span>
        <span
          className={classNames("FormFieldCheckbox__checkmark", {
            "FormFieldCheckbox__checkmark--has-error": hasError,
          })}
        />
      </label>
    );
  }
);

FormFieldCheckbox.propTypes = {
  className: string,
  label: string,
};

FormFieldCheckbox.defaultProps = {
  className: "",
  label: "",
};

export { FormFieldCheckbox };
