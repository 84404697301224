import { useCallback, useEffect } from 'react';
import { postContentFragment } from 'services/contentFragmentService';
import { generateParagraphName } from 'utils/paragraphUtil';
import { createContentFragmentBaseObj, generateFoldersByPath } from 'utils/contentFragmentUtil';
import { getISONow } from 'utils/dateUtil';
import { showToast } from 'utils/toastUtil';
import { translations } from 'translation/en';
import { MODELS_PATHS } from 'constants/aemConstants';
import { PARAGRAPH_PROPS } from 'constants/paragraphConstants';
import useBrandData from './useBrandData';
import useBrandDetails from './useBrandDetails';
import { useRequest } from './useRequest';

export const useCreateModule = () => {
	const { brand } = useBrandData();
	const { currentType } = useBrandDetails();
	const { loading: loadingPost, data: response, error, run } = useRequest(postContentFragment);

	useEffect(() => {
		if (error) {
			showToast({ type: 'error', message: translations.ERROR_ADDING_CONTENT });
		}
	}, [error]);

	const createModule = useCallback(
		({ type, displayOrder = 1 }) => {
			const paragraphName = generateParagraphName({
				type,
				displayOrder,
				chapterTitle: currentType?.chapterTitle,
				sectionTitle: currentType?.sectionTitle,
				articleTitle: currentType?.articleTitle,
			});

			const parentPath = generateFoldersByPath({
				basePath: brand?.parentPath,
				chapterPath: currentType?.chapterPath,
				sectionPath: currentType?.sectionPath,
				articlePath: currentType?.articlePath,
			});

			const newContentFragmentObj = createContentFragmentBaseObj({
				title: paragraphName,
				parentPath,
				model: MODELS_PATHS.RELATOR_PARAGRAPHS,
				master: {
					brand: brand.brandId,
					parentVcmid: currentType.vcmId,
					TYPE: type,
					DISPLAY_ORDER: displayOrder,
					CREATION_DATE: getISONow(),
				},
			});

			return run({
				pagePath: brand.parentPath,
				cfType: PARAGRAPH_PROPS.RELATOR_PARAGRAPHS_KEY,
				data: newContentFragmentObj,
			});
		},
		[brand, currentType, run]
	);

	return { loadingPost, response, error, createModule };
};
