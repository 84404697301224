import { useCallback, useEffect } from 'react';
import useApprovePublish from 'hooks/useApprovePublish';
import { useDisapprovePage } from 'hooks/useDisapprovePage';
import { putContentFragment } from 'services/contentFragmentService';
import { getName, getParentPath, updateContentFragmentBaseObj } from 'utils/contentFragmentUtil';
import { getISONow } from 'utils/dateUtil';
import { showToast } from 'utils/toastUtil';
import { MODELS_PATHS } from 'constants/aemConstants';
import { translations } from 'translation/en';
import { useRequest } from './useRequest';

export const useMoveModule = () => {
	const { loading: loadingMove, data: response, error, run, clear } = useRequest(putContentFragment);
	const { handleStaleStatus } = useApprovePublish();
	const { isLoadingDisapprove, disapprovePage } = useDisapprovePage();

	useEffect(() => {
		if (response) {
			clear();
		}
	}, [response, clear]);

	useEffect(() => {
		if (error) {
			clear();
		}
	}, [error, clear]);

	const moveModule = useCallback(
		({ type, path, pagePath, displayOrder }) => {
			const updateContentFragmentObj = updateContentFragmentBaseObj({
				name: getName(path),
				parentPath: getParentPath(path),
				model: MODELS_PATHS.RELATOR_PARAGRAPHS,
				master: {
					DISPLAY_ORDER: displayOrder,
					MODIFICATION_DATE: getISONow(),
				},
			});

			const moveModulePromise = run({
				pagePath,
				cfType: type,
				data: updateContentFragmentObj,
			});

			const disapprovePagePromise = disapprovePage([{ path, type }]);

			return Promise.all([moveModulePromise, disapprovePagePromise]).then(responses => {
				const isSuccess = responses.some(res => res.data);

				if (isSuccess) {
					handleStaleStatus(true);
				}

				return responses;
			});
		},
		[handleStaleStatus, disapprovePage, run]
	);

	const swappingModule = useCallback(
		({ type, pagePath, currentItem, previousItem, onSuccess, onFinished }) => {
			return Promise.all([moveModule({ ...currentItem, type, pagePath }), moveModule({ ...previousItem, type, pagePath })])
				.then(([res]) => {
					if (res?.error) {
						showToast({ type: 'error', message: translations.FAILED_SAVE_CONTENT });
					} else {
						onSuccess();
					}
				})
				.finally(() => {
					onFinished();
				});
		},
		[moveModule]
	);

	return { loading: [loadingMove, isLoadingDisapprove].some(Boolean), swappingModule };
};
