import { bool } from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { FormField, FormFieldCheckbox, FormFieldGroup, FormFieldSelect } from '@hyatt/form-field';
import { MultiField } from '@hyatt/multi-field';
import FormFieldLabel from '@hyatt/form-field/FormFieldLabel';
import Tooltip from 'components/tooltip/Tooltip';
import useBrandDetails from 'hooks/useBrandDetails';
import { useGroupsAndDepartments } from 'hooks/useGroupsAndDepartments';
import { BEG_TERM } from 'constants/aemConstants';
import { allBrands } from 'utils/mappedBrandsUtil';
import { translations } from 'translation/en';
import { CHAPTER_TYPES } from '../../constants/chapterConstants';
import { verifyContentType } from '../../util/editPageModalUtil';
import * as S from './SecurityTab.style';

export const SecurityTab = ({ disableFields }) => {
	const { currentType } = useBrandDetails();
	const { adGroups, adDepartments } = useGroupsAndDepartments();
	const { register, watch, setValue } = useFormContext();

	const overrideSecurityOptions = watch('overrideSecurityOptions');

	const { isChapter, isSection, isArticle } = verifyContentType(currentType);

	const shouldDisableSecurityOptions = () => {
		if (isSection || isArticle) {
			return disableFields || !overrideSecurityOptions;
		}

		return disableFields;
	};

	return (
		<div className="SecurityTab">
			{(isSection || isArticle) && (
				<S.OverrideSecurityOptionsContainer>
					<FormFieldCheckbox
						label={translations.OVERRIDE_SECURITY_OPTIONS}
						{...register('overrideSecurityOptions')}
						disabled={disableFields}
					/>
					<Tooltip content={translations.OVERRIDE_SECURITY_OPTIONS_INFO}>
						<i className="icon icon-info-circle" />
					</Tooltip>
				</S.OverrideSecurityOptionsContainer>
			)}

			<FormFieldGroup>
				<FormField
					label={translations.SUPERVISORY_LEVEL}
					{...register('supervisoryLevel')}
					disabled={shouldDisableSecurityOptions()}
				/>
			</FormFieldGroup>

			<FormFieldGroup>
				<FormFieldLabel label={translations.ALLOWED_GROUPS} />
				<FormFieldSelect
					isMulti
					closeMenuOnSelect={false}
					allowSelectAll
					options={adGroups}
					value={watch('allowedGroups')}
					getOptionValue={group => group.path}
					getOptionLabel={group => group.title}
					onChange={selectedGroups => setValue('allowedGroups', selectedGroups)}
					isDisabled={shouldDisableSecurityOptions()}
				/>
			</FormFieldGroup>

			<FormFieldGroup>
				<FormFieldLabel label={translations.ALLOWED_DEPARTMENTS} />
				<FormFieldSelect
					isMulti
					closeMenuOnSelect={false}
					allowSelectAll
					options={adDepartments}
					value={watch('allowedDepartments')}
					getOptionValue={department => department.path}
					getOptionLabel={department => department.title}
					onChange={selectedDepartments => setValue('allowedDepartments', selectedDepartments)}
					isDisabled={shouldDisableSecurityOptions()}
				/>
			</FormFieldGroup>

			<FormFieldGroup>
				<FormFieldCheckbox
					label={translations.VISIBLE_TO_FRANCHISE}
					{...register('visibleToFranchise')}
					disabled={disableFields}
				/>
			</FormFieldGroup>

			<FormFieldGroup>
				<FormField label={translations.BRANDS} {...register('brands')} disabled={disableFields} />
			</FormFieldGroup>

			<FormFieldGroup>
				<FormField label={translations.REGIONS} {...register('regions')} disabled={disableFields} />
			</FormFieldGroup>

			{isChapter && (
				<FormFieldGroup>
					<FormFieldLabel label={translations.CHAPTER_TYPE} />
					<FormFieldSelect
						options={Object.values(CHAPTER_TYPES)}
						value={watch('chapterType')}
						getOptionValue={chapterType => chapterType.value}
						getOptionLabel={chapterType => chapterType.label}
						onChange={selectedChapterType => setValue('chapterType', selectedChapterType)}
						isClearable
						isDisabled={disableFields}
					/>
				</FormFieldGroup>
			)}

			<FormFieldGroup>
				<MultiField
					{...{ ...register('keywords'), ref: null }}
					currentItems={watch('keywords')}
					fieldLabel={translations.KEYWORDS}
					itemsKey="keywords"
					setCurrentItems={setValue}
					disabled={disableFields}
					itemPrefix={BEG_TERM}
				/>
			</FormFieldGroup>

			<FormFieldGroup>
				<FormFieldLabel label={translations.PAGE_PATH} />
				<FormFieldSelect
					isMulti
					closeMenuOnSelect={false}
					allowSelectAll
					options={allBrands}
					value={watch('pagePath')}
					getOptionValue={brand => brand.pagePath}
					getOptionLabel={brand => brand.name}
					onChange={selectedBrands => setValue('pagePath', selectedBrands)}
				/>
			</FormFieldGroup>
		</div>
	);
};

SecurityTab.propTypes = {
	disableFields: bool,
};

SecurityTab.defaultProps = {
	disableFields: false,
};
