import classNames from 'classnames';
import { arrayOf, bool, node, oneOfType, string } from 'prop-types';
import React from 'react';
import './ButtonAnalytics.scss';

export const ButtonAnalytics = ({ children, primary, className, ...props }) => {
	const primaryButtonClasses = classNames('ButtonAnalytics', { 'ButtonAnalytics--primary': primary }, className);

	return (
		<button className={primaryButtonClasses} {...props}>
			{children}
		</button>
	);
};

ButtonAnalytics.propTypes = {
	children: oneOfType([arrayOf(node), node]),
	className: string,
	primary: bool,
};

ButtonAnalytics.defaultProps = {
	children: null,
	className: '',
	primary: false,
};
