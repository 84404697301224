import styled from 'styled-components/macro';

export const SearchBar = styled.div({
	display: 'flex',
	margin: 0,
	padding: '5px 15px',
	width: '100%',
});

export const Col = styled.div({
	display: 'flex',
	flexDirection: 'column',
	marginBottom: '30px',
	width: '100%',
	background: 'transparent',
	verticalAlign: 'baseline',
});

export const Row = styled.div(({ theme: { breakpoint } }) => ({
	margin: '0px auto',
	position: 'relative',
	marginTop: '13px',
	width: '100%',

	[breakpoint.lg()]: {
		marginLeft: '25%',
		width: '58%',
	},
}));

export const SearchGroup = styled.div(() => ({
	display: 'inline-flex',
	position: 'relative',
	width: '100%',
	height: '30px',
}));

export const SearchInput = styled.input(({ theme: { color, typo, border } }) => ({
	padding: '5px 10px',
	width: '100%',
	height: 'inherit',
	boxSizing: 'border-box',

	background: color.neutral.white,
	color: color.field.primary.text,

	border: `${border.width.sm} solid ${color.border.primary}`,
	borderRadius: border.radius['4xs'],
	borderTopRightRadius: border.radius.zero,
	borderBottomRightRadius: border.radius.zero,
	transition: 'border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s',

	...typo.lead['02'],

	'::placeholder': {
		color: color.field.primary.placeholder,
	},

	':focus, :focus-visible, :focus-within': {
		borderColor: color.border.primary,
		boxShadow: color.shadow.primary,
	},
}));
