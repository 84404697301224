import React from 'react';
import { bool, func, node, shape, string } from 'prop-types';
import { EditMenuHeader } from '../editMenuHeader/EditMenuHeader';
import defaultTranslations from '../../constants/defaultTranslations';
import './EditMenuApproveOptions.scss';

export const EditMenuApproveOptions = ({
	translations,
	isPageApproved,
	handleApproveUnapprovedPage,
	isApproveLoading,
	checkIcon,
	loadingIcon,
	handleOpenModal,
}) => {
	const { APPROVE, APPROVED, STATUS, THIS_PAGE, UNAPPROVE, UNAPPROVED } = translations;
	const approveTitle = `${isPageApproved ? UNAPPROVE : APPROVE} ${THIS_PAGE}`;

	return (
		<>
			<EditMenuHeader isRedBackground={!isPageApproved}>
				{STATUS}: {isPageApproved ? APPROVED : UNAPPROVED}
			</EditMenuHeader>

			<div className="EditMenuApproveOptions">
				<button
					onClick={() => {
						if (isPageApproved) {
							handleApproveUnapprovedPage();
						}
						if (!isPageApproved) {
							handleOpenModal();
						}
					}}
					disabled={isApproveLoading}
				>
					{!isApproveLoading && checkIcon}
					{isApproveLoading ? loadingIcon : approveTitle}
				</button>
			</div>
		</>
	);
};

EditMenuApproveOptions.propTypes = {
	handleApproveUnapprovedPage: func.isRequired,
	checkIcon: node,
	loadingIcon: node,
	translations: shape({
		APPROVE: string,
		APPROVED: string,
		CLEAR_CACHE: string,
		STATUS: string,
		STOP_EDITING: string,
		THIS_PAGE: string,
		UNAPPROVE: string,
		UNAPPROVED: string,
		PUBLISH: string,
		PUBLISHED: string,
		UNPUBLISH: string,
		UNPUBLISHED: string,
		STALE: string,
	}),
	isPageApproved: bool,
	isApproveLoading: bool,
	handleOpenModal: func.isRequired,
};

EditMenuApproveOptions.defaultProps = {
	translations: defaultTranslations,
	checkIcon: null,
	loadingIcon: null,
	isPageApproved: false,
	isApproveLoading: false,
};
