import { arrayOf, bool, node, oneOfType } from 'prop-types';
import React from 'react';
import './SidePaneAnalytics.scss';

const SidePaneAnalytics = ({ open, children, ...rest }) => {
	return (
		<div data-printable className={`SidePaneAnalytics ${open ? 'SidePaneAnalytics--open' : ''}`} {...rest}>
			{children}
		</div>
	);
};

SidePaneAnalytics.propTypes = {
	open: bool.isRequired,
	children: oneOfType([node, arrayOf(node)]),
};

SidePaneAnalytics.defaultProps = {
	children: null,
};

export { SidePaneAnalytics };
