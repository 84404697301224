import axios from 'API/axios/axios';
import { PAGE_TYPES } from 'constants/pageConstants';
import { begSqlApi } from 'constants/serviceConstants';
import { mappedParagraphs } from 'utils/paragraphUtil';

export const getParagraphsBySection = params => {
	const { path } = params;
	const url = `${process.env.REACT_APP_API_URL}${begSqlApi}/get-paragraphs-by-section`;

	return axios.get(url, {
		params: {
			pagePath: `beg-print-${path}`,
			cfType: PAGE_TYPES.SECTION_TYPE,
			parameter: `;_path=${path}`,
		},
	});
};

export const fromParagraphsBySectionService = data => {
	return {
		sections: [
			{
				sectionTitle: data.BEG_SECTION_TITLE,
				paragraphs: data?.BEG_SECTION_CONTENT_FK_BEG_SECTION_ID?.map(mappedParagraphs),

				articles: data?.BEG_SECTION_RELATOR_FK_SECTION_ID?.map(articleContentItem => {
					return {
						articleTitle: articleContentItem?.BEG_SECTION_RELATOR_ARTICLE_ID?.BEG_ARTICLE_TITLE,
						articleDisplayOrder: articleContentItem?.BEG_SECTION_RELATOR_ARTICLE_DISPLAY_ORDER,
						paragraphs:
							articleContentItem?.BEG_SECTION_RELATOR_ARTICLE_ID?.BEG_ARTICLE_CONTENT_FK_BEG_ARTICLE_ID?.map(
								mappedParagraphs
							),
					};
				}),
			},
		],
	};
};
