import { Divider } from 'components/divider';
import PropTypes from 'prop-types';
import React from 'react';
import { childrenWithProps } from 'utils/childrenUtil';
import './CollapseItem.scss';

const CollapseItem = ({ children, ...props }) => {
	return (
		<div className="CollapseItem">
			<div className="CollapseItem__body">
				{childrenWithProps(children, props)}
				<Divider />
			</div>
		</div>
	);
};

CollapseItem.propTypes = {
	children: PropTypes.node,
};

CollapseItem.defaultProps = {
	children: null,
};

export { CollapseItem };
