import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormFieldGroup, FormField } from '@hyatt/form-field';
import { translations } from 'translation/en';
import { bool } from 'prop-types';

export const GeneralTab = ({ disableFields }) => {
	const {
		register,
		formState: { errors },
	} = useFormContext();

	return (
		<div className="GeneralTab">
			<FormFieldGroup>
				<FormField
					label={translations.TITLE}
					required
					{...register('title', { required: translations.REQUIRED })}
					hasError={!!errors.title}
					errors={errors.title}
					disabled={disableFields}
				/>
			</FormFieldGroup>

			<FormFieldGroup>
				<FormField
					label={translations.DISPLAY_ORDER}
					{...register('displayOrder')}
					type="number"
					disabled={disableFields}
				/>
			</FormFieldGroup>
		</div>
	);
};

GeneralTab.propTypes = {
	disableFields: bool,
};

GeneralTab.defaultProps = {
	disableFields: false,
};
