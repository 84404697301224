import styled from 'styled-components/macro';

export const BrandSideBarSection = styled.li({
	display: 'block',
	position: 'relative',
	width: '100%',
});

export const Body = styled.div(({ isExpanded }) => ({
	overflow: 'hidden',
	height: 'auto',
	maxHeight: isExpanded ? '3000px' : 0,
	transition: isExpanded ? 'max-height 1.5s ease-in-out, padding 0.4s ease' : 'max-height 0.5s ease 0s, padding 0.4s ease',
	padding: isExpanded ? '5px 0px' : 0,
}));
