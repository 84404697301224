import complementaryTheme from 'themes/complementary/complementary';

const { size, weight, lineHeight, letter, transform } = complementaryTheme;

const createHeadline = baseTheme => ({
	headline: {
		'07': {
			fontFamily: baseTheme.font.family.helveticaNeue,
			fontWeight: weight.medium,
			lineHeight: lineHeight.normal,
			fontSize: size['5xl'],
		},
		// title approve modal
		'08': {
			fontFamily: baseTheme.font.family.helveticaNeue,
			fontWeight: weight.medium,
			fontSize: size.xl,
			lineHeight: lineHeight.normal,
			textTransform: transform.uppercase,
		},
	},
});

const createBody = baseTheme => ({
	body: {
		'01': {
			fontFamily: baseTheme.font.family.helveticaNeue,
			fontSize: size.xs,
			lineHeight: lineHeight.normal,
		},
	},
});

const createCaption = baseTheme => ({
	caption: {
		// approve modal description
		'03': {
			fontFamily: baseTheme.font.family.arialSansSerif,
			fontSize: size['xs'],
			lineHeight: lineHeight.normal,
		},
	},
});

const createButton = baseTheme => ({
	button: {
		'04': {
			fontFamily: baseTheme.font.family.poynterRegular,
			fontSize: size['2xs'],
			lineHeight: lineHeight.normal,
			textTransform: transform.uppercase,
		},
		// approve modal button
		'06': {
			fontFamily: baseTheme.font.family.poynterRegular,
			fontSize: size['2xs'],
			letterSpacing: letter.spacing.md,
			textTransform: transform.uppercase,
			lineHeight: lineHeight.medium,
		},
		icon: {
			fontSize: size.md,

			xl: {
				fontSize: size.xl,
			},

			'2xl': {
				fontSize: size['2xl'],
			},
		},
	},
});

const typographyTheme = baseTheme => ({
	typo: {
		...createHeadline(baseTheme),
		...createBody(baseTheme),
		...createCaption(baseTheme),
		...createButton(baseTheme),
	},
});

export default typographyTheme;
