import complementaryTheme from 'themes/complementary/complementary';

const { neutral } = complementaryTheme;

const colors = {
	background: {
		banner: {
			background: '#636161', // header
		},
		secondary: '#e1ded9', // sidebar and navbar (mobile)
		tertiary: '#F7F7F7', // footer
	},
	interactive: {
		// terms & conditions primary button modal. remove primary button modal
		primary: {
			default: {
				background: '#e4e0d9',
				label: '#000000',
				border: '#433a38',
			},
			hover: {
				background: '#fff',
				label: '#0072ce',
				border: '#100e0d',
			},
		},
		// search button
		primary02: {
			default: {
				background: '#433a38',
				label: '#fff',
				border: '#433a38',
				opacity: '0.65',
			},
		},
		// file button
		primary05: {
			default: {
				background: '#e1ded9',
				label: '#636161',
				border: '#e1ded9',
			},
			hover: {
				background: '#fff',
				label: '#5E4236',
				border: '#5E4236',
			},
		},
		// terms & conditions secondary button modal
		secondary: {
			default: {
				background: '#e4e0d9',
				label: '#000000',
				border: '#433a38',
			},
			hover: {
				background: '#fff',
				label: '#0072ce',
				border: '#100e0d',
			},
		},
		// sign out button
		secondary02: {
			default: {
				background: '#f2f3f4',
				label: '#636161',
			},
			hover: {
				background: '#f2f3f4',
				label: '#0072ce',
			},
		},
		// approve modal button
		secondary03: {
			default: {
				background: '#333333',
				label: '#000',
				border: '#333333',
			},
			hover: {
				background: '#fff',
				label: '#000',
				border: '#333333',
			},
		},
		// help button
		tertiary: {
			default: {
				background: '#fff',
				label: '#636161',
			},
		},
		printModalButtons: {
			default: {
				background: '#e1ded9',
				label: '#636161',
				border: 'transparent',
			},
			hover: {
				background: '#e1ded9',
				label: '#636161',
				border: '#100e0d',
			},
		},
		// properties and edit image modals buttons
		tertiary03: {
			default: {
				background: '#fff',
				label: '#636161',
				border: '#ccc',
			},
			hover: {
				background: '#fff',
				label: '#181818',
				border: '#181818',
			},
		},
		// mobile
		outlined: {
			default: {
				label: '#9b8F87', // mobile search and help buttons
				icon: '#9b8F87', // mobile menu button
				border: '#9b8F87', // mobile buttons
			},
		},
		// header buttons
		inverse: {
			default: {
				label: '#fff',
			},
		},
		discardChangesButtons: {
			default: {
				background: '#e4e0d9',
				label: '#000000',
				border: '#433a38',
			},
			hover: {
				background: '#fff',
				label: '#0072ce',
				border: '#100e0d',
			},
		},
		printPageButtons: {
			default: {
				label: '#fff',
			},
			hover: {
				background: '#fff',
				label: '#0072ce',
				border: '#100e0d',
			},
		},
		dropDownButtons: {
			default: {
				label: '#fff',
			},
			active: {
				color: '#00000020',
				label: '#262626',
			},
			focusWithin: {
				background: 'transparent',
				label: '#fff',
				border: 'none',
			},
			hover: {
				background: 'transparent',
				label: '#fff',
				border: 'none',
			},
		},

		lightest: 'rgba(255, 255, 255, 0.1)',
	},
	field: {
		// search input
		primary: {
			text: '#433a38',
			placeholder: '#0072ce',
		},
		// inputs edit image modal
		secondary: {
			text: '#676767',
			placeholder: '#0072ce',
		},
		// input properties modal
		tertiary: {
			text: '#676767',
			placeholder: '#0072ce',
		},
	},
	text: {
		primary: '#636466', // footer
		primary02: '#636161', // title chapter, section page

		titleModule: '#636161',
		headline01: '#433A38',
		headline02: '#433A38',
		headline03: '#433A38',
		headline04: '#636161',
		headline05: '#636161',
		headline06: '#636161',

		paragraph: '#636161', // paragraph content page
		specificationTitle: '#636161', // specification title

		approveModalTitle: '#666666',
		approveModalDescription: '#999999',

		addFilesSubtitle: '#999999',

		secondary: '#433A38', // terms and conditions title modal
		secondary02: '#433A38', // title discard changes
		secondary03: '#181818', // title edit image modal

		tertiary: '#000', // terms and conditions description modal
		tertiary03: '#181818', // title properties modal

		quaternary: '#636161', // title print modal
		quaternary02: '#333', // labels subtitle edit image modal
		quaternary03: '#000', // labels justification edit image modal
		quinary: '#636161', // subtitles items print modal
		quinary02: '#333', // subtitles properties modal
		quinary03: '#333', // title remove modal
		senary: '#636161', // title article page

		bullet: '#898b8e', // list item bullet

		imageCaption: '#58595b', // image caption
	},
	link: {
		// sidebar item chapter
		primary: {
			default: {
				label: '#636161',
			},
			hover: {
				label: '#636161',
			},
		},
		// sidebar item section
		secondary: {
			default: {
				label: '#636161',
			},
			hover: {
				background: 'rgba(255, 255, 255, 0.1)',
				label: '#636161',
			},
			active: {
				label: '#636161',
			},
		},
		// sidebar item article
		tertiary: {
			default: {
				label: '#636161',
			},
			hover: {
				background: 'rgba(255, 255, 255, 0.1)',
				label: '#636161',
			},
			active: {
				label: '#636161',
			},
		},
		// breadcrumb
		quaternary: {
			default: {
				label: '#636161',
			},
			hover: {
				label: '#636161',
			},
			active: {
				label: '#636161',
			},
		},
	},
	icon: {
		primary: '#fff', // header dropdown arrow icon
		primary02: '#636161', // print icon
		chapterArrow: '#636161', // chapter arrow icon
		sectionArrow: '#636161', // section arrow icon
		breadcrumbArrow: '#555555', // breadcrumb arrow icon
	},
	border: {
		primary: '#433a38', // search input
		secondary: '#B9b5aa',
		tertiary: '#333', // modal
		quaternary: '#636161', // module divider
	},
	shadow: {
		primary: 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6)', // search input
		secondary: 'inset 0 1px 0 rgba(255, 255, 255, 0.1)', // menu
	},
	neutral,
	table: {
		row: {
			background: {
				primary: '#f2f3f4',
				secondary: 'transparent',
			},
			cell: {
				label: '#333333',
				border: '2px solid #636161',
			},
		},
	},
};

export default colors;
