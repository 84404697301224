import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { translations } from 'translation/en';
import { CheckListModal } from 'components/modals/checkListModal';
import { hasLength } from 'utils/arrayUtil';
import { replaceArgs } from 'utils/stringUtil';
import useBrandDetails from 'hooks/useBrandDetails';
import useListItemsToApprove from 'hooks/useListItemsToApprove';
import useApprovePublish from 'hooks/useApprovePublish';
import { useApprovePage } from 'hooks/useApprovePage';

const ApproveModal = ({ open, title, onClose }) => {
	const { currentType } = useBrandDetails();
	const { isPageApproved } = useApprovePublish();

	const { loadingItems, itemsToApprove, fetchItems } = useListItemsToApprove();
	const { approveLoading, approvedPage, approvePageTree } = useApprovePage();

	useEffect(() => {
		if (open) {
			fetchItems();
		}
	}, [open, fetchItems]);

	useEffect(() => {
		if (approvedPage) {
			onClose();
		}
	}, [approvedPage, onClose]);

	const handleApproveModalDescription = useMemo(() => {
		const isThereAnyChildItemToApprove = hasLength(
			itemsToApprove?.filter(item => (item.disabled && item?.articles?.length > 0) || !item.disabled)
		);

		if (isThereAnyChildItemToApprove) {
			return `${replaceArgs(translations.PAGES_UNDER_THAT_ARE_UNAPPROVED, currentType?.type)}`;
		}

		if (isPageApproved) {
			return replaceArgs(translations.MODAL_DESCRIPTION_NO_ITEMS, translations.APPROVED.toLowerCase(), currentType?.type);
		}

		return replaceArgs(translations.DO_YOU_WANT_TO, translations.APPROVE.toLowerCase(), currentType?.type);
	}, [itemsToApprove, isPageApproved, currentType?.type]);

	const onSave = async approvedItemsToSave => {
		approvedItemsToSave.push({ id: currentType?.path, type: currentType?.type });

		return approvePageTree(
			approvedItemsToSave.map(item => ({
				path: item.id,
				type: item.type,
			}))
		);
	};

	return (
		<CheckListModal
			open={open}
			loading={loadingItems}
			loadingSave={approveLoading}
			title={title}
			description={handleApproveModalDescription}
			collections={itemsToApprove}
			buttonName={translations.APPROVE}
			onSave={onSave}
			onClose={onClose}
		/>
	);
};

ApproveModal.propTypes = {
	open: PropTypes.bool,
	title: PropTypes.string,
	onClose: PropTypes.func,
};

ApproveModal.defaultProps = {
	open: false,
	title: '',
	onClose: () => {},
};

export { ApproveModal };
