import { useState, useEffect } from 'react';

export const useUndo = initialValue => {
	const [value, setValue] = useState(initialValue);
	const [history, setHistory] = useState([]);

	const undo = () => {
		if (history.length > 0) {
			const previousValue = history[history.length - 1];
			setValue(previousValue);
			setHistory(prevHistory => prevHistory.slice(0, -1));
		}
	};

	const saveToHistory = newValue => {
		setHistory(prevHistory => [...prevHistory, newValue]);
		setValue(newValue);
	};

	useEffect(() => {
		if (initialValue) {
			saveToHistory(initialValue);
		}
	}, [initialValue]);

	return [value, undo, saveToHistory];
};
