import { func, shape, string } from 'prop-types';
import TinyMCEEditor from 'components/tinyMceEditor/TinyMCEEditor';

const EditableContentInput = ({ containerId, value, onChange, toolbarOptions, settingsProps, ...props }) => {
	const handleEditorChange = content => {
		onChange(content);
	};

	return (
		<TinyMCEEditor
			containerId={containerId}
			value={value}
			init={{
				fixed_toolbar_container: `#${containerId}`,
				height: 300,
				auto_focus: true,
				selector: 'input#editor',
				toolbar: toolbarOptions,
				remove_trailing_brs: true,
				toolbar_mode: 'floating',
				plugins: 'link anchor code lists advlist media',
				link_target_list: [
					{ text: 'New window', value: '_blank', selected: true },
					{ text: 'Current window', value: '_self', selected: false },
				],
				block_formats: 'Header 1=h1; Header 2=h2; Header 3=h3; Header 4=h4; Header 5=h5; Header 6=h6; Paragraph=p;',
				setup(editor) {
					editor.on('init', () => {
						const toolbarContainer = document.querySelector(`#${containerId}`);
						const tinyMCEAuxcontainer = document.querySelector('.tox-tinymce-aux');

						if (tinyMCEAuxcontainer && toolbarContainer) {
							toolbarContainer.appendChild(tinyMCEAuxcontainer);
						}
					});
				},
				...settingsProps,
			}}
			onEditorChange={handleEditorChange}
			{...props}
		/>
	);
};

EditableContentInput.propTypes = {
	value: string,
	containerId: string.isRequired,
	toolbarOptions: string,
	settingsProps: shape({}),
	onChange: func.isRequired,
};

EditableContentInput.defaultProps = {
	value: '',
	settingsProps: null,
	toolbarOptions: `save media saveAsDraft undo redo | bold italic underline | blocks
	| link anchor | alignleft aligncenter alignright alignjustify
	| bullist numlist | outdent indent | superscript code`,
};

export { EditableContentInput };
