import complementaryTheme from 'themes/complementary/complementary';

const { neutral } = complementaryTheme;

const colors = {
	background: {
		banner: {
			background: '#002B49', // header
		},
		secondary: '#151F6D',
		tertiary: '#f7f7f7',
	},
	interactive: {
		primary: {
			default: {
				background: '#0072ce',
				label: '#fff',
				border: '#0072ce',
			},
			hover: {
				background: 'rgba(255, 255, 255, 0.1)',
				label: '#bddcf5',
				border: '#0072ce',
			},
		},
		primary02: {
			default: {
				background: '#0072ce',
				label: '#fff',
				border: '#0072ce',
				opacity: '0.65',
			},
		},
		// file button
		primary05: {
			default: {
				background: '#0072ce',
				label: '#fff',
				border: '#0072ce',
			},
			hover: {
				background: '#fff',
				label: '#0072ce',
				border: '#0072ce',
			},
		},
		secondary: {
			default: {
				background: '#F2F3F4',
				label: '#0072ce',
				border: '#0072ce',
			},
			hover: {
				background: '#F2F3F4',
				label: '#0072ce',
				border: '#0072ce',
			},
		},
		secondary02: {
			default: {
				background: '#F2F3F4',
				label: '#0072ce',
			},
			hover: {
				background: '#F2F3F4',
				label: '#0072ce',
			},
		},
		// button approve modal
		secondary03: {
			default: {
				background: '#333333',
				label: '#fff',
				border: '#333333',
			},
			hover: {
				background: '#fff',
				label: '#333333',
				border: '#333333',
			},
		},
		tertiary: {
			default: {
				background: '#fff',
				label: '#002B49',
			},
		},
		printModalButtons: {
			default: {
				background: '#0072ce',
				label: '#fff',
				border: '#0072ce',
			},
			hover: {
				background: '#F2F3F4',
				label: '#0072ce',
				border: '#0072ce',
			},
		},
		// properties and edit image modals buttons
		tertiary03: {
			default: {
				background: '#fff',
				label: '#181818',
				border: '#ccc',
			},
			hover: {
				background: '#fff',
				label: '#181818',
				border: '#181818',
			},
		},
		outlined: {
			default: {
				label: '#fff',
				icon: '#fff',
				border: '#fff',
			},
		},
		inverse: {
			default: {
				label: '#fff',
			},
		},
		discardChangesButtons: {
			default: {
				background: '#0072ce',
				label: '#fff',
				border: '#0072ce',
			},
			hover: {
				background: '#F2F3F4',
				label: '#0072ce',
				border: '#0072ce',
			},
		},
		printPageButtons: {
			default: {
				background: '#0072ce',
				label: '#fff',
				border: '#0072ce',
			},
			hover: {
				background: '#F2F3F4',
				label: '#0072ce',
				border: '#0072ce',
			},
		},
		dropDownButtons: {
			default: {
				label: '#ffffff',
			},
			active: {
				color: '#00000020',
				label: '#262626',
			},
			focusWithin: {
				background: 'transparent',
				label: '#ffffff',
				border: 'none',
			},
			hover: {
				background: 'transparent',
				label: '#ffffff',
				border: 'none',
			},
		},

		lightest: 'rgba(255, 255, 255, 0.1)',
	},
	field: {
		// search input
		primary: {
			text: '#636466',
			placeholder: '#0072ce',
		},
		// inputs edit image modal
		secondary: {
			text: '#676767',
			placeholder: '#0072ce',
		},
		// input properties modal
		tertiary: {
			text: '#676767',
			placeholder: '#0072ce',
		},
	},
	text: {
		primary: '#636466',
		primary02: '#151F6D', // title chapter, section page

		titleModule: '#435060',
		headline01: '#151F6D',
		headline02: '#433A38',
		headline03: '#0072ce',
		headline04: '#555',
		headline05: '#555',
		headline06: '#333',

		paragraph: '#555',
		specificationTitle: '#555', // specification title

		approveModalTitle: '#666666',
		approveModalDescription: '#999999',

		addFilesSubtitle: '#999999',

		secondary: '#555',
		secondary02: '#435060',
		secondary03: '#181818', // edit image modal title

		tertiary: '#555',
		tertiary03: '#181818', // title properties modal

		quaternary: '#333',
		quaternary02: '#333', // labels subtitle edit image modal
		quaternary03: '#000', // labels justification edit image modal

		quinary: '#333',
		quinary02: '#333', // subtitles properties modal
		quinary03: '#333', // title remove modal
		senary: '#435060', // title article page

		bullet: '#898b8e', // list item bullet

		imageCaption: '#58595b',
	},
	link: {
		primary: {
			default: {
				label: '#fff',
			},
			hover: {
				label: '#fff',
			},
		},
		secondary: {
			default: {
				label: '#fff',

				mobile: {
					label: '#4a89be',
				},
			},
			hover: {
				background: 'rgba(255, 255, 255, 0.1)',
				label: '#bddcf5',
			},
			active: {
				label: '#fff',

				mobile: {
					label: '#bddcf5',
				},
			},
		},
		tertiary: {
			default: {
				label: '#fff',

				mobile: {
					label: '#bddc5f',
				},
			},
			hover: {
				background: 'rgba(255, 255, 255, 0.1)',
				label: '#bddcf5',

				mobile: {
					label: '#bddc5f',
				},
			},
			active: {
				label: '#fff',

				mobile: {
					label: '#bddc5f',
				},
			},
		},
		quaternary: {
			default: {
				label: '#333',
			},
			hover: {
				label: '#333',
			},
			active: {
				label: '#151F6D',
			},
		},
	},
	icon: {
		primary: '#fff',
		primary02: '#898b8e',
		chapterArrow: '#fff', // chapter arrow icon
		sectionArrow: '#fff', // section arrow icon
		breadcrumbArrow: '#555555', // breadcrumb arrow icon
	},
	border: {
		primary: '#0072ce',
		secondary: '#FFFFFF',
		tertiary: '#333',
		quaternary: '#eee',
	},
	shadow: {
		primary: 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6)',
		secondary: 'inset 0 1px 0 rgba(255, 255, 255, 0.1)',
	},
	neutral,
	table: {
		row: {
			background: {
				primary: '#f2f3f4',
				secondary: 'transparent',
			},
			cell: {
				label: '#333333',
				border: '2px solid #898b8e',
			},
		},
	},
};

export default colors;
