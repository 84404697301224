import complementaryTheme from 'themes/complementary/complementary';

const { size, weight, lineHeight, letter, transform, decoration } = complementaryTheme;

const createHeadline = baseTheme => ({
	headline: {
		// title chapter & section page
		'01': {
			fontFamily: baseTheme.font.family.notoSansSemiBold,
			fontSize: '16pt',
			fontWeight: weight.semibold,
			lineHeight: lineHeight.xs,
			textTransform: transform.uppercase,
		},
		// title module
		'01.1': {
			fontFamily: baseTheme.font.family.notoSansBold,
			fontSize: '11pt',
			lineHeight: '1em',
			fontWeight: weight.regular,
		},
		// h1
		'01.2': {
			fontFamily: baseTheme.font.family.notoSansSemiBold,
			fontSize: '16pt',
			fontWeight: weight.semibold,
			lineHeight: lineHeight.xs,
			textTransform: transform.uppercase,
		},
		// title article page
		'02': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			textTransform: transform.uppercase,
			fontSize: '11pt',
			lineHeight: '1em',
			fontWeight: weight.regular,
		},
		// h2 titles content page
		'02.1': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			textTransform: transform.uppercase,
			fontSize: '11pt',
			fontWeight: weight.regular,
		},
		// h3 titles content page
		'03': {
			fontFamily: baseTheme.font.family.notoSansBold,
			fontSize: '11pt',
			fontWeight: weight.bold,
		},
		// h4 content page
		'04': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: '10pt',
			textTransform: transform.uppercase,
			fontWeight: weight.regular,
		},
		// specification dropdown title
		'04.1': {
			fontFamily: baseTheme.font.family.notoSansBold,
			fontSize: '10pt',
			fontWeight: weight.bold,
			textTransform: transform.uppercase,
			lineHeight: lineHeight.normal,
		},
		// h5 content page
		'05': {
			fontFamily: baseTheme.font.family.notoSansBold,
			fontSize: '10pt',
			fontWeight: weight.bold,
			textTransform: transform.uppercase,
			lineHeight: lineHeight.normal,
			letterSpacing: letter.spacing.sm,
		},
		// h6 content page
		'06': {
			fontFamily: baseTheme.font.family.notoSansBold,
			fontSize: '10pt',
			lineHeight: lineHeight.normal,
			letterSpacing: letter.spacing.sm,
		},
		// title terms and conditions modal
		'07': {
			fontFamily: baseTheme.font.family.notoSansSemiBold,
			fontSize: '16pt',
			fontWeight: weight.semibold,
			textTransform: transform.uppercase,
		},
		// title approve modal
		'08': {
			fontFamily: baseTheme.font.family.notoSansLight,
			fontWeight: weight.medium,
			fontSize: size.xl,
			lineHeight: lineHeight.normal,
			textTransform: transform.uppercase,
		},
		// title properties modal
		'09': {
			fontFamily: baseTheme.font.family.notoSansLight,
			fontWeight: weight.thin,
			fontSize: size['2.5xl'],
			textTransform: transform.uppercase,
		},
		// title edit image modal
		10: {
			fontFamily: baseTheme.font.family.notoSansLight,
			fontWeight: weight.thin,
			fontSize: size['1xl'],
			textTransform: transform.uppercase,
		},
	},
});

const createBody = baseTheme => ({
	body: {
		'01': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size.xs,
			lineHeight: lineHeight.normal,
		},
		// paragraphs content page
		'02': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: '9pt',
		},
		// labels subtitle edit image modal
		'03': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size['lg'],
		},
		// labels justification edit image modal
		'04': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size['md'],
		},
		// labels field file modal
		'05': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size['1lg'],
		},
		// title print modal
		'06': {
			fontFamily: baseTheme.font.family.notoSansSemiBold,
			fontWeight: weight.semibold,
			fontSize: size.lg,
			textTransform: transform.uppercase,
		},
		// underlist and list
		'07': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size['2xs'],
			bulletSize: size.xs,
			lineHeight: lineHeight.normal,
		},
	},
});

const createCaption = baseTheme => ({
	caption: {
		'01': {
			fontFamily: baseTheme.font.family.calibri,
			fontSize: size['2xs'],
		},
		// subtitles properties modal
		'02': {
			fontFamily: baseTheme.font.family.arialSansSerif,
			fontSize: size['lg'],
		},
		// approve modal description
		'03': {
			fontFamily: baseTheme.font.family.arialSansSerif,
			fontSize: size['1xs'],
			lineHeight: lineHeight.normal,
		},
		// input placeholder file modal
		'04': {
			fontFamily: baseTheme.font.family.gothamLight,
			fontSize: size['sm'],
			fontWeight: weight.thin,
			textTransform: transform.uppercase,
		},
		// input file modal
		'05': {
			fontFamily: baseTheme.font.family.arialSansSerif,
			fontSize: size['sm'],
			fontWeight: weight.thin,
			textTransform: transform.uppercase,
		},
		// subtitle add files button
		'06': {
			fontFamily: baseTheme.font.family.calibri,
			fontSize: size['2xs'],
			fontStyle: decoration.italic,
			lineHeight: lineHeight.normal,
		},
		// name file in file modal
		'07': {
			fontFamily: baseTheme.font.family.helveticaNeue,
			fontSize: size['sm'],
			lineHeight: lineHeight.small,
		},
		// image caption text
		'08': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size['3xs'],
		},
	},
});

const createLink = baseTheme => ({
	link: {
		// breadcrumb
		'01': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size['2xs'],
		},
		'02': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size['2xs'],
			letterSpacing: letter.spacing.md,
			textTransform: transform.uppercase,
		},
		// sidebar item chapter
		'03': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size['1xs'],
		},
		// sidebar item section
		'04': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size['1xs'],
		},
		// sidebar item article
		'05': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size['1xs'],
		},
		// logo sidebar
		'06': {
			margin: '0 auto 45px auto',
		},
	},
});

const createButton = baseTheme => ({
	button: {
		'01': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size['2xs'],
			letterSpacing: letter.spacing.md,
			textTransform: transform.uppercase,
		},
		'02': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size.sm,
		},
		'03': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size.xs,
			fontWeight: weight.semibold,

			sm: {
				fontSize: size.sm,
			},
		},
		// search label button
		'04': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size['2xs'],
			letterSpacing: letter.spacing.md,
			textTransform: transform.uppercase,
			lineHeight: lineHeight.medium,
		},
		// label buttons properties and edit image modals
		'05': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size['1.5xs'],
			letterSpacing: letter.spacing.md,
			textTransform: transform.uppercase,
			lineHeight: lineHeight.medium,
		},
		// approve modal button
		'06': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size['2xs'],
			letterSpacing: letter.spacing.md,
			textTransform: transform.uppercase,
			lineHeight: lineHeight.medium,
		},
		// discard changes button
		'07': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size['2xs'],
			letterSpacing: letter.spacing.md,
			textTransform: transform.uppercase,
			lineHeight: lineHeight.medium,
			fontWeight: weight.thin,
		},
		// file button text
		'08': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: '9pt',
			letterSpacing: letter.spacing.md,
			textTransform: transform.uppercase,
			lineHeight: lineHeight.medium,
			fontWeight: weight.regular,
		},
		icon: {
			fontSize: size.md,

			xl: {
				fontSize: size.xl,
			},

			'2xl': {
				fontSize: size['2xl'],
			},
		},
	},
});

const createLead = baseTheme => ({
	lead: {
		'01': {
			fontFamily: baseTheme.font.family.arialSansSerif,
			fontSize: size.xs,
		},
		'02': {
			fontFamily: `${baseTheme.font.family.poynterRegular} !important`,
			fontSize: size['2xs'],
			lineHeight: lineHeight.medium,
		},
		'03': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size.sm,
			lineHeight: lineHeight.small,
		},
		// title message discard changes modal
		'04': {
			fontFamily: baseTheme.font.family.notoSansSemiBold,
			fontSize: '11pt',
			fontWeight: weight.semibold,
		},
		'05': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size.sm,
			fontWeight: weight.medium,
			lineHeight: lineHeight.small,
		},
		// inputs edit image modal
		'06': {
			fontFamily: baseTheme.font.family.arialSansSerif,
			fontSize: size.md,
			textTransform: transform.none,
		},
		// input properties modal
		'07': {
			fontFamily: baseTheme.font.family.arialSansSerif,
			fontSize: size.md,
			fontWeight: weight.thin,
			textTransform: transform.none,
		},
		// checkbox label properties modal
		'08': {
			fontFamily: baseTheme.font.family.helveticaNeue,
			fontSize: size['sm'],
			fontWeight: weight.medium,
			lineHeight: lineHeight.normal,
		},
	},
});

const createTable = baseTheme => ({
	table: {
		'01': {
			fontFamily: baseTheme.font.family.arialSansSerif,
			fontSize: size['sm'],
			lineHeight: lineHeight.normal,
		},
	},
});

const typographyTheme = baseTheme => ({
	typo: {
		...createHeadline(baseTheme),
		...createCaption(baseTheme),
		...createLink(baseTheme),
		...createButton(baseTheme),
		...createLead(baseTheme),
		...createBody(baseTheme),
		...createTable(baseTheme),
	},
});

export default typographyTheme;
