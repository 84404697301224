import { Modal } from 'components/modals/modal';
import styled from 'styled-components/macro';

export const RemoveModal = styled(Modal)(() => ({
	top: '20%',
	maxWidth: '380px',
}));

export const Lead = styled.span(({ theme: { color, typo } }) => ({
	textAlign: 'center',
	display: 'inline-block',
	margin: '25px 0',

	color: color.text.quinary03,
	...typo.lead['05'],
}));
