import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
	isContractor as _isContractor,
	isEmployee as _isEmployee,
	isFranchise as _isFranchise,
	isAdmin as _isAdmin,
	getRoleStatus,
	getVisibleRole,
} from 'utils/userUtil';
import { CONTENT_MANAGERS_GROUP } from 'components/begEditMenu/constants/begEditMenuConstants';
import { isDelivery } from 'utils/envUtil';
import useBrandData from './useBrandData';

export const useAuthData = () => {
	return useSelector(store => store.auth);
};

export const useAuthUserRoles = () => {
	return useSelector(store => store.auth.user.roles);
};

export const useAuthUserGroups = () => {
	return useSelector(store => store.auth.user?.memberOf);
};

export const useAuthAccess = () => {
	const { user } = useSelector(store => store.auth);

	const isFranchise = useMemo(() => _isFranchise(user), [user]);

	const isContractor = useMemo(() => _isContractor(user), [user]);

	const isEmployee = useMemo(() => _isEmployee(user), [user]);

	const isAdmin = useMemo(() => _isAdmin(user), [user]);

	const isContractorHome = useMemo(() => {
		return isContractor && user?.hasContactorAccessGroup === false;
	}, [isContractor, user?.hasContactorAccessGroup]);

	const profileStatus = useMemo(() => getRoleStatus(user), [user]);

	const visibleStatus = useMemo(() => getVisibleRole(user), [user]);

	return {
		isContractor,
		isContractorHome,
		isFranchise,
		isEmployee,
		profileStatus,
		visibleStatus,
		isAdmin,
	};
};

export const useUserData = () => {
	const { brandId } = useBrandData();
	const groups = useAuthUserGroups();
	const allUserGroups = groups?.join(',') || '';

	const isUserAllowedToEdit =
		(allUserGroups.includes(CONTENT_MANAGERS_GROUP) ||
			allUserGroups.includes(`${CONTENT_MANAGERS_GROUP}-${brandId.toUpperCase()}`)) &&
		!isDelivery;

	return {
		isUserAllowedToEdit,
	};
};
