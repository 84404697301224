import { toast, Zoom } from 'react-toastify';

const defaultConfig = {
	position: 'top-center',
	autoClose: 1500,
	hideProgressBar: false,
	closeOnClick: true,
	draggable: true,
	limit: 1,
	transition: Zoom,
	theme: 'colored',
};

/**
 * @param {{
 * message: string,
 * type?: 'success' | 'info' | 'warn' | 'error',
 * config?: {
 * position: string,
 * autoClose: number,
 * hideProgressBar: boolean,
 * closeOnClick: boolean,
 * pauseOnHover: boolean,
 * draggable: boolean,
 * toastId: string | number,
 * }
 * }} toastOptions Toast options
 */

export const showToast = ({ type, message, config }) => {
	toast.clearWaitingQueue();
	const toastType = type ? toast[type] : toast;
	toastType(message, { ...defaultConfig, ...config });
};
