import styled from 'styled-components/macro';

export const Gallery = styled.div(() => ({
	display: 'grid',
	gridTemplateColumns: 'repeat(3, 1fr)',
	gap: '30px',
	width: '100%',
}));

export const SpecificationOnPrintPage = styled.div(() => ({
	display: 'flex',
	flexWrap: 'wrap',
	width: '100%',
}));
