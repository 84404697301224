import styled from 'styled-components/macro';

export const Collapse = styled.section({
	width: '100%',
});

export const Container = styled.div(() => ({
	margin: 0,
	padding: 0,
	height: 'auto',
	width: '100%',
}));

export const Head = styled.div(({ theme: { breakpoint } }) => ({
	display: 'inline',
	border: 0,
	background: 'none',
	height: 'inherit',
	width: '100%',

	'> *': {
		cursor: 'pointer',
	},

	[breakpoint.lg()]: {
		minWidth: '100px',
	},
}));

export const Wrapper = styled.div(({ inverted }) => ({
	display: 'flex',
	padding: '15px 20px',
	flexDirection: inverted ? 'row-reverse' : 'row',
	width: 'fit-content',
	minWidth: 'inherit',
	justifyContent: 'space-between',
	alignItems: 'baseline',
	gap: '10px',

	'> *': {
		cursor: 'pointer',
	},
}));

export const Body = styled.main(() => ({
	flexDirection: 'column',
	flex: 1,
	alignItems: 'stretch',
	justifyContent: 'stretch',
	maxHeight: 0,
	opacity: 0,
	overflow: 'hidden',
	visibility: 'hidden',
	height: 'auto',
	transition: 'all 0.5s ease-out',

	'&.expanded': {
		maxHeight: '3000px',
		opacity: 1,
		visibility: 'visible',
		overflow: 'visible',
		transition: 'all 0.5s ease-in',
	},
}));

export const Icon = styled.i(({ isExpanded }) => ({
	display: 'block',
	transform: isExpanded ? 'rotate(180deg)' : 'none',
	transition: 'transform 0.6s ease',
}));
