import styled from 'styled-components/macro';

export const WordBox = styled.div({
	display: 'flex',
	flexDirection: 'column',
	gap: '0.5rem',
});

export const WordsContainer = styled.div({
	padding: '0 0 5px',
	display: 'flex',
	marginBottom: '10px',
	flexWrap: 'wrap',
	gap: '10px',
});

export const Word = styled.span({
	display: 'flex',
	alignItems: 'center',
	gap: '10px',

	height: '35px',
	padding: '7px 10px',

	background: '#f0f1f1',
	color: '#656565',

	borderRadius: '5px',
	fontSize: '0.875rem',

	span: {
		color: '#aeb5bb',
		fontSize: '1.125rem',
		display: 'flex',
		alignItems: 'center',

		'&:hover': {
			cursor: 'pointer',
		},
	},
});

export const Input = styled.input(({ theme: { color, typo } }) => ({
	margin: '0',
	padding: '15px 10px',
	width: '100%',
	height: '35px',

	background: 'none',
	border: '1px solid #cccccc',
	borderRadius: '4px',
	textTransform: 'lowercase',

	color: color.field.tertiary.text,
	...typo.lead['07'],

	'::placeholder': {
		textTransform: 'none',
		color: color.field.tertiary.placeholder,
		...typo.lead['07'],
	},
}));

export const KeywordsContent = styled.div(({ theme: { color } }) => ({
	display: 'flex',

	input: {
		borderBottomRightRadius: 0,
		borderTopRightRadius: 0,
	},

	button: {
		flex: 1,

		width: '100%',
		height: '35px',

		margin: 0,
		padding: '10px',

		background: color.text.titleModule,

		borderRadius: '4px',
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
		borderColor: color.text.titleModule,

		'& span,  & i': {
			color: '#fff',
		},

		'&:hover, &:focus': {
			background: color.text.titleModule,
			borderColor: color.text.titleModule,
		},
	},
}));
