import HyattVacationClub from 'assets/img/logos/HVC.svg';
import complementaryTheme from 'themes/complementary/complementary';
import colors from './hyattVacationClubColors';
import typographyTheme from './hyattVacationClubTypo';

const logo = {
	footer: HyattVacationClub, // logo in the footer
	loading: HyattVacationClub, // logo in the loading
	sidebar: HyattVacationClub, // logo in the sidebar
	mobile: HyattVacationClub, // logo in the navbar
	size: {
		sidebar: {
			width: '180px',
			height: '116px',
		},
		footer: {
			width: '194px',
			height: '67px',
		},
		navbar: {
			width: '150px',
			height: '116px',
		},
		loading: {
			width: '130px',
			height: '96px',
		},
	},
};

const copyrightText = {
	padding: '5px 0 5px 35px',
};

const hyattVacationClub = {
	brandId: 'rc',
	name: 'Hyatt Vacation Club',
	logo,
	color: colors,
	copyrightText,

	font: {
		family: {
			arialSansSerif: 'Arial, Helvetica, sans-serif',
			helveticaNeue: 'Helvetica Neue',
			arial: 'arial',
			tenorSansRegular: 'tenor-sans-regular',
			robotoRegular: 'roboto',
			robotoMedium: 'roboto-medium',
			robotoBold: 'roboto-bold',
		},
	},
};

const hyattVacationClubTheme = {
	...hyattVacationClub,
	...typographyTheme(hyattVacationClub),
	...complementaryTheme,
};

export default hyattVacationClubTheme;
