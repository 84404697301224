import classNames from 'classnames';
import useToggle from 'hooks/useToggle';
import React, { useCallback, useEffect } from 'react';
import { Y_OFFSET_LIMIT } from './constants/floatinButtonConstants';
import './FloatingButton.scss';

const FloatingButton = () => {
	const [isVisible, setIsVisible] = useToggle();

	const listenToScroll = useCallback(() => {
		const winScroll = window.pageYOffset || document.documentElement.scrollTop;

		if (!isVisible && winScroll > Y_OFFSET_LIMIT) {
			setIsVisible(true);
		} else if (isVisible && winScroll <= Y_OFFSET_LIMIT) {
			setIsVisible(false);
		}
	}, [isVisible, setIsVisible]);

	useEffect(() => {
		window.addEventListener('scroll', listenToScroll, { passive: true });

		return () => {
			window.removeEventListener('scroll', listenToScroll);
		};
	}, [listenToScroll]);

	const scrollToTop = e => {
		e.preventDefault();
		window.scrollTo({ top: 0, behavior: 'smooth' });
		setIsVisible(false);
	};

	const floatingButtonClassNames = classNames('FloatingButton', {
		'FloatingButton--show': isVisible,
	});

	return (
		<button className={floatingButtonClassNames} onClick={scrollToTop}>
			<div className="FloatingButton__icon">
				<i className="icon icon-arrow-up" />
			</div>
		</button>
	);
};

export { FloatingButton };
