import useBrandDetails from 'hooks/useBrandDetails';
import useClearCache from 'hooks/useClearCache';
import { usePutContentFragment } from 'hooks/usePutContentFragment';
import { getContentModel, getContentRelatorModel, getContentRelatorPath, verifyContentType } from '../util/editPageModalUtil';

export const useEditPageModalSubmit = (formMethods, path) => {
	const { loadingUpdate, updateContentFragment } = usePutContentFragment({
		toastId: 'editPageModal',
		shouldApproveUnapprove: true,
	});
	const { currentType, fetchServiceByType } = useBrandDetails();
	const { clearCache, clearCacheLoading } = useClearCache(false);

	const { handleSubmit } = formMethods;

	const getContentPayload = data => {
		const { isChapter, isSection, isArticle } = verifyContentType(currentType);

		const {
			allowedDepartments,
			allowedGroups,
			brands,
			chapterType,
			displayOrder,
			keywords,
			overrideSecurityOptions,
			regions,
			supervisoryLevel,
			title,
			visibleToFranchise,
			pagePath,
		} = data;

		const allowedGroupsPaths = allowedGroups.map(g => g.path);
		const allowedDepartmentsPaths = allowedDepartments.map(d => d.path);
		const selectedPagePath = pagePath.map(p => p.pagePath);

		if (isChapter) {
			return {
				contentPayload: {
					BEG_DEPTALLOWED_BEG_CONTENT_DEPT_ID: allowedDepartmentsPaths,
					BEG_AD_GROUPALLOWED_BEG_CH_ADGROUPID: allowedGroupsPaths,
					brands,
					BEG_CHAPTER_TYPE: chapterType.value,
					BEG_CHAPTER_DISPLAY_ORDER: displayOrder,
					RELATED_TERMS: keywords,
					regions,
					BEG_CHAPTER_JOBLEVEL: supervisoryLevel,
					BEG_CHAPTER_TITLE: title,
					VISIBLE_TO_FRANCHISE: visibleToFranchise,
					pagePath: selectedPagePath,
				},
				relatorPayload: null,
			};
		}

		if (isSection) {
			return {
				contentPayload: {
					BEG_DEPTALLOWED_BEG_CONTENT_DEPT_ID: allowedDepartmentsPaths,
					BEG_AD_GROUPALLOWED_SEC_ADGROUPID: allowedGroupsPaths,
					brands,
					RELATED_TERMS: keywords,
					overrideSecurityOptions,
					regions,
					BEG_SECTION_JOBLEVEL: supervisoryLevel,
					BEG_SECTION_TITLE: title,
					VISIBLE_TO_FRANCHISE: visibleToFranchise,
					pagePath: selectedPagePath,
				},
				relatorPayload: {
					BEG_CHAPTER_RELATOR_SECTION_DISPLAY_ORDER: displayOrder,
				},
			};
		}

		if (isArticle) {
			return {
				contentPayload: {
					BEG_DEPTALLOWED_BEG_CONTENT_DEPT_ID: allowedDepartmentsPaths,
					BEG_AD_GROUPALLOWED_BEG_ART_ADGROUPID: allowedGroupsPaths,
					brands,
					RELATED_TERMS: keywords,
					overrideSecurityOptions,
					regions,
					BEG_ARTICLE_JOBLEVEL: supervisoryLevel,
					BEG_ARTICLE_TITLE: title,
					VISIBLE_TO_FRANCHISE: visibleToFranchise,
					pagePath: selectedPagePath,
				},
				relatorPayload: {
					BEG_SECTION_RELATOR_ARTICLE_DISPLAY_ORDER: displayOrder,
				},
			};
		}

		return { contentPayload: null, relatorPayload: null };
	};

	const onSubmit = async data => {
		const { contentPayload, relatorPayload } = getContentPayload(data);

		const promises = [
			updateContentFragment({
				payload: contentPayload,
				path,
				model: getContentModel(currentType),
				shouldApprove: false,
			}),
		];

		if (relatorPayload) {
			promises.push(
				updateContentFragment({
					payload: relatorPayload,
					path: getContentRelatorPath(currentType),
					model: getContentRelatorModel(currentType),
				})
			);
		}

		await Promise.all(promises).then(() => {
			fetchServiceByType({ type: currentType.type, path });
		});

		await clearCache();
	};

	return {
		onSubmit: handleSubmit(onSubmit),
		isSubmitting: loadingUpdate || clearCacheLoading,
	};
};
