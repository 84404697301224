import complementaryTheme from 'themes/complementary/complementary';

const { neutral } = complementaryTheme;

const colors = {
	background: {
		banner: {
			background: '#211551', // header
		},
		secondary: '#e8e5de',
		tertiary: '#f7f7f7',
	},
	interactive: {
		primary: {
			default: {
				background: '#211551',
				label: '#fff',
				border: '#211551',
			},
			hover: {
				background: '#fff',
				label: '#b1b1b1',
				border: '#b1b1b1',
			},
		},
		primary02: {
			default: {
				background: '#b1b1b1',
				label: '#fff',
				border: '#b1b1b1',
				opacity: '0.65',
			},
		},
		// file button
		primary05: {
			default: {
				background: '#211551',
				label: '#fff',
				border: '#211551',
			},
			hover: {
				background: '#fff',
				label: '#211551',
				border: '#211551',
			},
		},
		secondary: {
			default: {
				background: '#fff',
				label: '#b1b1b1',
				border: '#b1b1b1',
			},
			hover: {
				background: '#564e8e',
				label: '#fff',
				border: '#564e8e',
			},
		},
		secondary02: {
			default: {
				background: '#e8e5de',
				label: '#211551',
			},
			hover: {
				background: '#e8e5de',
				label: '#211551',
			},
		},
		// button approve modal
		secondary03: {
			default: {
				background: '#333333',
				label: '#fff',
				border: '#333333',
			},
			hover: {
				background: '#fff',
				label: '#333333',
				border: '#333333',
			},
		},
		// help button
		tertiary: {
			default: {
				background: '#fff',
				label: '#211551',
			},
		},
		printModalButtons: {
			default: {
				background: '#211551',
				label: '#fff',
				border: '#211551',
			},
			hover: {
				background: '#fff',
				label: '#b4b7b8',
				border: '#b4b7b8',
			},
		},
		// properties and edit image modals buttons
		tertiary03: {
			default: {
				background: '#fff',
				label: '#181818',
				border: '#ccc',
			},
			hover: {
				background: '#fff',
				label: '#181818',
				border: '#181818',
			},
		},
		outlined: {
			default: {
				label: '#707070',
				icon: '#707070',
				border: '#707070',
			},
		},
		inverse: {
			default: {
				label: '#fff',
			},
		},
		discardChangesButtons: {
			default: {
				background: '#211551',
				label: '#fff',
				border: '#211551',
			},
			hover: {
				background: '#fff',
				label: '#b4b7b8',
				border: '#b4b7b8',
			},
		},
		printPageButtons: {
			default: {
				background: '#211551',
				label: '#fff',
				border: '#211551',
			},
			hover: {
				background: '#fff',
				label: '#b4b7b8',
				border: '#b4b7b8',
			},
		},
		dropDownButtons: {
			default: {
				label: '#ffffff',
			},
			active: {
				color: '#00000020',
				label: '#ffffff',
			},
			focusWithin: {
				background: 'transparent',
				label: '#ffffff',
				border: 'none',
			},
			hover: {
				background: 'transparent',
				label: '#ffffff',
				border: 'none',
			},
		},

		lightest: 'rgba(255, 255, 255, 0.3)',
	},
	field: {
		// search input
		primary: {
			text: '#636466',
			placeholder: '#999',
		},
		// inputs edit image modal
		secondary: {
			text: '#676767',
			placeholder: '#676767',
		},
		// input properties modal
		tertiary: {
			text: '#676767',
			placeholder: '#676767',
		},
	},
	text: {
		primary: '#636466',
		primary02: '#211551', // title chapter, section page

		titleModule: '#211551',
		headline01: '#211551',
		headline02: '#707070',
		headline03: '#211551', // h3 title content page
		headline04: '#707070',
		headline05: '#211551',
		headline06: '#707070',

		paragraph: '#707070',
		specificationTitle: '#211551', // specification title

		approveModalTitle: '#666666',
		approveModalDescription: '#999999',

		addFilesSubtitle: '#999999',

		secondary: '#211551',
		secondary02: '#211551',
		secondary03: '#181818', // edit image modal title

		tertiary: '#707070',
		tertiary03: '#181818', // title properties modal

		quaternary: '#211551', // title print modal
		quaternary02: '#333', // labels subtitle edit image modal
		quaternary03: '#000', // labels justification edit image modal

		quinary: '#707070',
		quinary02: '#333', // subtitles properties modal
		quinary03: '#333', // title remove modal
		senary: '#707070', // title article page

		bullet: '#707070', // list item bullet

		imageCaption: '#707070',
	},
	link: {
		primary: {
			default: {
				label: '#707070',
			},
			hover: {
				label: '#707070',
			},
		},
		secondary: {
			default: {
				label: '#707070',
			},
			hover: {
				background: 'rgba(255, 255, 255, 0.1)',
				label: '#707070',
			},
			active: {
				label: '#707070',
			},
		},
		tertiary: {
			default: {
				label: '#707070',
			},
			hover: {
				background: 'rgba(255, 255, 255, 0.1)',
				label: '#707070',

				mobile: {
					label: '#707070',
				},
			},
			active: {
				label: '#707070',
			},
		},
		// breadcrumb
		quaternary: {
			default: {
				label: '#707070',
			},
			hover: {
				label: '#211551',
			},
			active: {
				label: '#211551',
			},
		},
	},
	icon: {
		primary: '#707070',
		primary02: '#707070',
		chapterArrow: '#707070', // chapter arrow icon
		sectionArrow: '#707070', // section arrow icon
		breadcrumbArrow: '#707070', // breadcrumb arrow icon
	},
	border: {
		primary: '#b1b1b1',
		secondary: '#FFF',
		tertiary: '#211551',
		quaternary: '#564e8e',
	},

	shadow: {
		primary: 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6)',
		secondary: 'inset 0 1px 0 rgba(255, 255, 255, 0.1)',
	},
	button: {
		primary: '#b4b7b8',
	},
	neutral,
	table: {
		row: {
			background: {
				primary: '#d2d2d2',
				secondary: 'transparent',
			},
			cell: {
				label: '#333333',
				border: '2px solid #564e8e',
			},
		},
	},
};

export default colors;
