import GrandHyattHorizontalLogo from 'assets/img/logos/grand-horizontal.svg';
import GrandHyattLogo from 'assets/img/logos/grand.svg';
import complementaryTheme from 'themes/complementary/complementary';
import colors from './grandHyattColors';
import typographyTheme from './grandHyattTypo';

const logo = {
	footer: GrandHyattHorizontalLogo,
	loading: GrandHyattLogo, // logo in the loading
	sidebar: GrandHyattLogo,
	mobile: GrandHyattLogo, // logo in the navbar
	size: {
		sidebar: {
			width: '160px',
			height: '71px',
		},
		footer: {
			width: '180px',
			height: '50px',
		},
		navbar: {
			width: '160px',
			height: '71px',
		},
		loading: {
			width: '120px',
			height: '85px',
		},
	},
};

const copyrightText = {
	padding: '0',
};

const grandHyatt = {
	brandId: 'gh',
	name: 'Grand Hyatt',
	logo,
	color: colors,
	copyrightText,

	font: {
		family: {
			helveticaNeue: 'Helvetica Neue',
			gothamLight: 'Gotham Light',
			arialSansSerif: 'Arial, Helvetica, sans-serif',
			poynterRegular: 'Poynter Regular',
			neutraFace: 'Neutra Face',
			calibri: 'Calibri, Arial',
			gothamBook: 'Gotham Book',
			robotoRegular: 'RobotoMono Regular',
		},
	},
};

const grandHyattTheme = {
	...grandHyatt,
	...typographyTheme(grandHyatt),
	...complementaryTheme,
};

export default grandHyattTheme;
