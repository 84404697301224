import { ModuleTemplate } from 'containers/brandDetails/components/brandMain/components/template';
import { string } from 'prop-types';
import { ColAModule } from './2COL-A';
import { ColBModule } from './2COL-B';
import { ColCModule } from './2COL-C';
import { ColFModule } from './2COL-F';
import { ColGModule } from './2COL-G';
import { ColHModule } from './2COL-H';
import { ColIModule } from './2COL-I';
import { ColJModule } from './2COL-J';
import { ColNModule } from './3COL-N';
import { FullK } from './FULL-K';
import { FullL } from './FULL-L';
import { FullM } from './FULL-M';
import { TextModule } from './TEXT';
import { Type3Images } from './TYPE-3-IMAGES';
import { Type4VariableImages } from './TYPE-4-VARIABLE-IMAGES';
import { QuickSheetTextModule } from './QUICKSHEET-TEXT';
import { IndexModule } from './INDEX-M';

const ModuleComponent = ({ type, path, ...props }) =>
	({
		'2-COL-A': <ColAModule path={path} {...props} />,
		'2-COL-B': <ColBModule path={path} {...props} />,
		'2-COL-C': <ColCModule path={path} {...props} />,
		'2-COL-F': <ColFModule path={path} {...props} />,
		'2-COL-G': <ColGModule path={path} {...props} />,
		'2-COL-H': <ColHModule path={path} {...props} />,
		'2-COL-I': <ColIModule path={path} {...props} />,
		'2-COL-J': <ColJModule path={path} {...props} />,
		'3-COL-N': <ColNModule path={path} {...props} />,
		'FULL-K': <FullK path={path} {...props} />,
		'FULL-L': <FullL path={path} {...props} />,
		'FULL-M': <FullM path={path} {...props} />,
		'TYPE-3-IMAGES': <Type3Images path={path} {...props} />,
		'TYPE-4-VARIABLE-IMAGES': <Type4VariableImages path={path} {...props} />,
		TABLE: <TextModule path={path} {...props} />,
		TEXT: <TextModule path={path} {...props} />,
		QUICKSHEET: <QuickSheetTextModule path={path} {...props} />,
		INDEX: <IndexModule path={path} {...props} />,
	}[type]);

const ModulesSwitch = ({ id, type, path, ...props }) => {
	return (
		<ModuleTemplate id={id} type={type} {...props}>
			<ModuleComponent type={type} path={path} {...props} />
		</ModuleTemplate>
	);
};

ModulesSwitch.propTypes = {
	id: string.isRequired,
	type: string,
	path: string,
};

ModulesSwitch.defaultProps = {
	type: '',
	path: '',
};

ModuleComponent.propTypes = {
	type: string,
	path: string,
};

ModuleComponent.defaultProps = {
	type: '',
	path: '',
};

export { ModulesSwitch };
