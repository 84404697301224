import { useEffect } from 'react';

export const useOnOutsideClick = ({ ref, action }) => {
	if (!action) {
		throw new Error('Action is required');
	}

	useEffect(() => {
		const actionHandler = event => {
			if (!Array.isArray(ref) && ref.current && !ref.current.contains(event.target)) {
				action(event);
				return;
			}

			if (Array.isArray(ref)) {
				const isOutside = ref.every(r => r.current && !r.current.contains(event.target));

				if (isOutside) {
					action(event);
				}
			}
		};

		window.addEventListener('mousedown', actionHandler);

		return () => {
			window.removeEventListener('mousedown', actionHandler);
		};
	}, [ref, action]);
};
