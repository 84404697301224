import { useEffect, useMemo } from 'react';
import { fromParagraphsBySectionService, getParagraphsBySection } from 'services/paragraphsBySectionService';
import { translations } from 'translation/en';
import { showToast } from 'utils/toastUtil';
import { useRequest } from './useRequest';

const useParagraphsBySection = () => {
	const {
		loading: loadingParagraphs,
		data: response,
		error,
		run: fetchParagraphsBySection,
		clear: resetParagraphs,
	} = useRequest(params => getParagraphsBySection({ ...params }));

	useEffect(() => {
		if (error) {
			showToast({ type: 'error', message: translations.FAILED_FETCH_ARTICLE });
		}
	}, [error]);

	const paragraphs = useMemo(() => {
		return response?.data?.data?.getParagraphsBySection?.items?.map(fromParagraphsBySectionService);
	}, [response]);

	return { loadingParagraphs, paragraphs, fetchParagraphsBySection, resetParagraphs };
};

export { useParagraphsBySection };
