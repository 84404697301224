/* eslint-disable react/no-unstable-nested-components */
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import ReactToPrint from 'react-to-print';
import { translations } from 'translation/en';
import usePptxGenJS from 'hooks/usePptxGenJS';
import { showToast } from 'utils/toastUtil';
import { descriptions, orientationValue } from './constants/printPageDropdownConstants';
import * as S from './PrintPageDropdown.style';

const PrintPageDropdown = forwardRef(({ content, handleCloseDropdown, handleSetIsLoadingFile, isLoadingFile, ...props }, ref) => {
	const { createExcel } = usePptxGenJS(handleSetIsLoadingFile);

	const setPageSize = cssPageSize => {
		const style = document.createElement('style');
		if (cssPageSize === orientationValue.LANDSCAPE) {
			style.innerHTML = `@page { size: ${cssPageSize}; margin: '10px 30px 20px 30px';}`;
		} else {
			style.innerHTML = `@page { size: ${cssPageSize}; margin: '10px 30px 20px 30px';}`;
		}
		style.id = 'page-orientation';
		document.head.appendChild(style);
	};

	const renderPageListItem = ({ label, ariaLabel, isLandscape, ...rest }) => {
		return (
			<S.PrintPageListItem>
				<S.PrintOptionButton disabled={isLoadingFile} aria-label={ariaLabel} {...rest}>
					<S.Icon className="icon icon-file-earmark" isLandscape={isLandscape} />
					{label}
				</S.PrintOptionButton>
			</S.PrintPageListItem>
		);
	};

	const handleRemoveCssOrientation = () => {
		const child = document.getElementById('page-orientation');
		child.parentNode.removeChild(child);
	};

	return (
		<S.PrintPageDropDownWrapper {...props}>
			<S.PrintPageList>
				<ReactToPrint
					copyStyles
					content={() => ref && ref?.current}
					trigger={() =>
						renderPageListItem({
							label: translations.PORTRAIT,
							ariaLabel: descriptions.PORTRAIT_BUTTON,
						})
					}
					onBeforeGetContent={() => {
						handleSetIsLoadingFile(true);
						handleCloseDropdown();
						setPageSize(orientationValue.PORTRAIT);
					}}
					onAfterPrint={() => {
						handleRemoveCssOrientation();
						handleSetIsLoadingFile(false);
					}}
					onPrintError={() => {
						handleRemoveCssOrientation();
						handleSetIsLoadingFile(false);
						showToast({
							type: 'error',
							message: translations.FAILED_TO_PRINT_THIS_PAGE,
						});
					}}
				/>
				<ReactToPrint
					copyStyles
					content={() => ref && ref?.current}
					trigger={() =>
						renderPageListItem({
							label: translations.LANDSCAPE,
							ariaLabel: descriptions.LANDSCAPE_BUTTON,
							isLandscape: true,
						})
					}
					onBeforeGetContent={() => {
						handleSetIsLoadingFile(true);
						handleCloseDropdown();
						setPageSize(orientationValue.LANDSCAPE);
					}}
					onAfterPrint={() => {
						handleRemoveCssOrientation();
						handleSetIsLoadingFile(false);
					}}
					onPrintError={() => {
						handleRemoveCssOrientation();
						handleSetIsLoadingFile(false);
						showToast({
							type: 'error',
							message: translations.FAILED_TO_PRINT_THIS_PAGE,
						});
					}}
				/>
				{renderPageListItem({
					label: translations.POWERPOINT,
					ariaLabel: descriptions.POWERPOINT_BUTTON,
					isLandscape: true,
					onClick: () => {
						createExcel(content);
						handleCloseDropdown();
					},
				})}
			</S.PrintPageList>
		</S.PrintPageDropDownWrapper>
	);
});

PrintPageDropdown.propTypes = {
	content: PropTypes.shape({}),
	handleCloseDropdown: PropTypes.func.isRequired,
	handleSetIsLoadingFile: PropTypes.func.isRequired,
	isLoadingFile: PropTypes.bool.isRequired,
};

PrintPageDropdown.defaultProps = {
	content: [],
};

export { PrintPageDropdown };
