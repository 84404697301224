import PropTypes from 'prop-types';
import * as S from './Icon.style';

const Icon = ({ variant, icon }) => {
	return (
		<S.Box style={icon?.style}>
			<S.Icon variant={variant} className={`icon ${icon?.name}`} />
		</S.Box>
	);
};

Icon.propTypes = {
	variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quaternary']),
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

Icon.defaultProps = {
	variant: 'primary',
	icon: '',
};

export { Icon };
