import { useCallback } from 'react';
import { translations } from 'translation/en';
import { CHAPTERS_TYPE, PAGE_TYPES } from 'constants/pageConstants';
import useBrandData from './useBrandData';
import useBrandDetails from './useBrandDetails';
import { useDeleteContentFragment } from './useDeleteContentFragment';

export const useDeletePage = () => {
	const { currentType } = useBrandDetails();
	const { brand } = useBrandData();

	const { loading, response, error, removeContentFragment } = useDeleteContentFragment({
		successMessage: translations.PAGE_CONTENT_DELETED,
		deleteMessage: translations.FAILED_DELETE_PAGE,
		handleError: true,
	});

	const deletePage = useCallback(() => {
		const type = currentType?.type || PAGE_TYPES.CHAPTER_TYPE;
		let path = '';

		switch (type) {
			case PAGE_TYPES.SECTION_TYPE:
				path = currentType?.sectionRelatorPath;
				break;
			case PAGE_TYPES.ARTICLE_TYPE:
				path = currentType?.articleRelatorPath;
				break;
			default:
				path = currentType?.chapterPath;
				break;
		}

		return removeContentFragment({
			cfType: CHAPTERS_TYPE,
			pagePath: brand.parentPath,
			data: { contentPath: path },
		});
	}, [brand, currentType, removeContentFragment]);

	return { loading, response, error, deletePage };
};
