import axios from 'API/axios/axios';
import { getFirstElement } from 'utils/arrayUtil';
import { getName } from 'utils/contentFragmentUtil';
import { isEmptyString } from 'utils/stringUtil';
import { begSqlApi } from 'constants/serviceConstants';
import { BEG_TERM, HC_TERM } from 'constants/aemConstants';
import { PAGE_TYPES } from 'constants/pageConstants';
import store from '../redux/store/store';

export const getArticleByPath = params => {
	const { path } = params;
	const url = `${process.env.REACT_APP_API_URL}${begSqlApi}/get-site-article-by-path`;

	return axios.get(url, {
		params: {
			pagePath: path,
			cfType: PAGE_TYPES.ARTICLE_TYPE,
			parameter: `;_path=${path}`,
		},
	});
};

export const fromArticleService = data => {
	const state = store.getState();
	const { adGroups, adDepartments } = state.security;

	return {
		path: data?._path,
		title: data?.BEG_ARTICLE_TITLE,
		approved: data?.approved,
		publishInfo: data?.publishInfo,
		visibleToFranchise: data?.VISIBLE_TO_FRANCHISE,
		supervisoryLevel: data?.BEG_ARTICLE_JOBLEVEL,
		brands: data?.brands,
		regions: data?.regions,
		overrideSecurityOptions: data?.overrideSecurityOptions,
		allowedGroups: data?.BEG_AD_GROUPALLOWED_BEG_ART_ADGROUPID?.map(groupPath => adGroups.find(g => g.path === groupPath)),
		allowedDepartments: data?.BEG_DEPTALLOWED_BEG_CONTENT_DEPT_ID?.map(departmentPath =>
			adDepartments.find(d => d.path === departmentPath)
		),
		keywords: data?.RELATED_TERMS,
		pagePath: data?.pagePath,
		unpublishLater: data?.unpublishLater,
		paragraphs:
			data?.BEG_ARTICLE_CONTENT_FK_BEG_ARTICLE_ID?.sort((item1, item2) => +item1.DISPLAY_ORDER - +item2.DISPLAY_ORDER)?.map(
				paragraph => ({
					path: paragraph._path,
					title: paragraph.TITLE,
					body: paragraph.BODY,
					displayOrder: paragraph.DISPLAY_ORDER,
					specification: paragraph.SPECIFICATION,
					objectId: paragraph.OBJECTID,
					disclaimer: paragraph.DISCLAIMER,
					collapsible: paragraph?.COLLAPSIBLE !== 'false' && paragraph?.COLLAPSIBLE,
					vcmId: paragraph.VCMID,
					type: paragraph.TYPE,
					template: paragraph.TEMPLATE,
					keywords: paragraph.RELATED_TERMS?.map(term => term.split(BEG_TERM)[1] || term.split(HC_TERM)[1]),
					files: paragraph?.RELATOR_FILE_BEG_ID?.sort((item1, item2) => +item1.DispOrder - +item2.DispOrder).map(
						item => {
							return {
								vcmId: item?.VCMID,
								path: item?._path,
								linkText: item?.FILE_LINK_TEXT,
								displayOrder: item?.DispOrder,
								creator: item?.CREATOR,
								filePath: item?.file?.length && getFirstElement(item?.file),
								file: {
									name: item?.file?.length && getName(getFirstElement(item?.file)),
								},
							};
						}
					),
					imageData: paragraph?.RELATOR_IMAGE_ID?.sort(
						(item1, item2) =>
							+item1.BEG_ARTICLE_CONTENT_IMAGE_DISPLAY_ORDER - +item2.BEG_ARTICLE_CONTENT_IMAGE_DISPLAY_ORDER
					).map(imgItem => {
						return {
							captionPosition: imgItem?.BEG_ARTICLE_CONTENT_IMAGE_CAPTION_POSITION?.toLowerCase(),
							displayOrder: imgItem?.BEG_ARTICLE_CONTENT_IMAGE_DISPLAY_ORDER,
							imageContentPath: imgItem?.BEG_ARTICLE_CONTENT_IMAGE_IMAGE_CONTENT,
							imageMobile: imgItem?.BEG_ARTICLE_CONTENT_IMAGE_IMAGE_MOBILE,
							imageRetina: imgItem?.BEG_ARTICLE_CONTENT_IMAGE_IMAGE_RETINA,
							imageLink: imgItem?.BEG_ARTICLE_CONTENT_IMAGE_LINK_IMAGE,
							textOverlayPosition: imgItem?.BEG_ARTICLE_CONTENT_IMAGE_TEXT_OVERLAY_POSITION?.toLowerCase(),
							imageObjectId: imgItem?.OBJECTID,
							imageVcmId: imgItem?.VCMID,
							imagePath: imgItem?._path,
							imageCaption: isEmptyString(imgItem?.BEG_ARTICLE_CONTENT_IMAGE_CAPTION),
							imageTitle: isEmptyString(imgItem?.BEG_ARTICLE_CONTENT_IMAGE_TITLE),
							textOverlay: isEmptyString(imgItem?.BEG_ARTICLE_CONTENT_IMAGE_TEXT_OVERLAY),
							linkTo: isEmptyString(imgItem?.BEG_ARTICLE_CONTENT_IMAGE_LINK_TO),
						};
					}),
				})
			) || [],
	};
};
