import { useCallback } from 'react';
import { useRequest } from 'hooks/useRequest';
import useBrandDetails from 'hooks/useBrandDetails';
import { putDisplayOrderContentFragment } from 'services/contentFragmentService';
import { isNumber } from 'utils/numberUtil';
import {
	fillAllSortOrderAttr,
	getName,
	getParentPath,
	getSortOrderAttribute,
	updateContentFragmentBaseObj,
} from 'utils/contentFragmentUtil';
import { getISONow } from 'utils/dateUtil';
import { MODELS_PATHS } from 'constants/aemConstants';
import { FILE_PROPS } from 'containers/brandDetails/components/brandMain/components/listFiles/constants/listFilesConstants';

export const useFixFilesDisplayOrder = () => {
	const { currentType } = useBrandDetails();
	const { loading, data: response, error, run } = useRequest(putDisplayOrderContentFragment);

	const fetchUpdateDisplayOrdersFromCollection = useCallback(
		async collection => {
			collection.map(item => {
				const updateContentFragmentObj = updateContentFragmentBaseObj({
					name: getName(item.path),
					parentPath: getParentPath(item.path),
					model: MODELS_PATHS.RELATOR_FILE,
					master: {
						DispOrder: item.displayOrder,
						MODIFICATION_DATE: getISONow(),
					},
				});

				return run({
					pagePath: currentType?.path,
					cfType: currentType?.type,
					data: updateContentFragmentObj,
				});
			});

			return null;
		},
		[currentType, run]
	);

	const checkIfExistItemToFix = useCallback(
		async (collection, keyProperty) => {
			const mapDisplayOrder = collection?.reduce((acc, item) => {
				acc[item[keyProperty]] = [...(acc[item[keyProperty]] || []), item];
				return acc;
			}, {});

			const values = Object?.values(mapDisplayOrder);

			const shouldReorder =
				// if any of the values has more than one item or repeated items
				values.some(val => val.length > 1) ||
				// if any of the items does not have a sortOrder attribute
				collection.some(item => !isNumber(getSortOrderAttribute(item, keyProperty)));

			if (shouldReorder) {
				const sortedArr = fillAllSortOrderAttr({ arr: collection, sortOrderKey: keyProperty });
				await fetchUpdateDisplayOrdersFromCollection(sortedArr);
				return sortedArr;
			}

			return collection;
		},
		[fetchUpdateDisplayOrdersFromCollection]
	);

	const fetchFixedDisplayOrder = useCallback(
		async files => {
			if (!files?.length) return [];

			const collection = await checkIfExistItemToFix(files, FILE_PROPS.DISPLAY_ORDER_KEY);

			return collection;
		},
		[checkIfExistItemToFix]
	);

	return { loading, response, error, fetchFixedDisplayOrder };
};
