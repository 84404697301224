import { zIndex } from 'constants/zIndexConstants';
import styled from 'styled-components/macro';

export const BrandMenu = styled.main(({ theme: { color, breakpoint }, isShow }) => ({
	display: 'block',
	position: 'absolute',
	margin: 0,
	padding: 0,
	top: '155px',
	left: '1px',
	width: '280px',
	background: color.background.secondary,
	boxShadow: isShow ? color.shadow.secondary : 'none',

	height: 'auto',
	maxHeight: isShow ? '8000px' : '0px',
	transition: 'all 1s ease-in-out',
	overflowY: 'hidden',
	visibility: isShow ? 'visible' : 'hidden',
	zIndex: zIndex.MENU,

	[breakpoint.lg()]: {
		display: 'none',
	},
}));

export const Container = styled.div({
	display: 'block',
	padding: '20px 0 8px 0',
	alignItems: 'center',
	justifyContent: 'center',
	width: '100%',
	height: '100%',
});
