export const getURLQueryParams = url => {
	const queryParams = {};
	const queryString = url.split('?')[1];
	if (queryString) {
		queryString.split('&').forEach(param => {
			const [key, value] = param.split('=');
			queryParams[key] = decodeURIComponent(value);
		});
	}
	return queryParams;
};
