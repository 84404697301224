import { useCallback, useEffect } from 'react';
import { putParagraph } from 'services/paragraphsService';
import { getName, getParentPath, updateContentFragmentBaseObj } from 'utils/contentFragmentUtil';
import { getISONow } from 'utils/dateUtil';
import { showToast } from 'utils/toastUtil';
import { translations } from 'translation/en';
import { MODELS_PATHS } from 'constants/aemConstants';
import { PARAGRAPH_PROPS } from 'constants/paragraphConstants';
import useBrandDetails from './useBrandDetails';
import { useRequest } from './useRequest';
import useApprovePublish from './useApprovePublish';
import { useDisapprovePage } from './useDisapprovePage';

export const usePutParagraph = () => {
	const { currentType } = useBrandDetails();
	const { loading: loadingUpdate, data: response, error, run, clear } = useRequest(putParagraph);

	const { isPageApproved, handleStaleStatus } = useApprovePublish();
	const { disapprovePage } = useDisapprovePage();

	useEffect(() => {
		if (response) {
			clear();
		}
	}, [response, clear]);

	useEffect(() => {
		if (error) {
			clear();
			showToast({ type: 'error', message: translations.ERROR_EDITING_CONTENT });
		}
	}, [error, clear]);

	const updateParagraph = useCallback(
		({ path, payload }) => {
			const updateContentFragmentObj = updateContentFragmentBaseObj({
				name: getName(path),
				parentPath: getParentPath(path),
				model: MODELS_PATHS[PARAGRAPH_PROPS.RELATOR_PARAGRAPHS_KEY.toUpperCase()],
				master: {
					...payload,
					MODIFICATION_DATE: getISONow(),
				},
			});

			const updateParagraphPromise = run({
				pagePath: currentType?.path,
				cfType: currentType?.type,
				data: updateContentFragmentObj,
			});

			let disapprovePagePromise = Promise.resolve();
			if (isPageApproved) {
				disapprovePagePromise = disapprovePage([currentType]);
			}

			return Promise.all([updateParagraphPromise, disapprovePagePromise]).then(responses => {
				const isSuccess = responses.some(res => res?.data);

				if (isSuccess) {
					handleStaleStatus(true);
				}

				return responses;
			});
		},
		[currentType, isPageApproved, handleStaleStatus, disapprovePage, run]
	);

	return { loadingUpdate, response, error, updateParagraph };
};
