import { useMemo, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Base } from 'components/base';
import { BrandsBox } from 'components/brandsBox';
import { BegEditMenu } from 'components/begEditMenu';
import { TermsConditionsModal } from 'components/termsConditionsModal';
import { useAuthData, useUserData } from 'hooks/useAuthData';
import { BrandDetailsProvider } from 'containers/brandDetails/context/BrandDetailsContext';
import { useRedirectByBrandID } from 'containers/redirectByBrandID/hooks/useRedirectByBrandID';
import { brands, findBrandByBrandId } from 'utils/mappedBrandsUtil';
import { Footer } from './components/footer';
import './ChooseBrands.scss';

const ChooseBrands = () => {
	const history = useHistory();
	const to = useRedirectByBrandID();

	const { user } = useAuthData();
	const { isUserAllowedToEdit } = useUserData();

	const userBrand = useMemo(() => findBrandByBrandId(user?.locationObj?.brandId), [user?.locationObj?.brandId]);

	useEffect(() => {
		if (userBrand?.routePath !== brands.CORPORATE.name) {
			const brandPathToRedirect = `brands/${userBrand.routePath}`;
			history.push(brandPathToRedirect);
		}
	}, [history, to, userBrand.routePath]);

	return (
		<BrandDetailsProvider>
			<main className="ChooseBrands">
				<Base>
					<BrandsBox />
					{isUserAllowedToEdit && <BegEditMenu />}
				</Base>
			</main>
			<Footer />
			<TermsConditionsModal />
		</BrandDetailsProvider>
	);
};

export default ChooseBrands;
