import BackgroundHeader from 'assets/img/background-header-hyatt.jpg';
import complementaryTheme from 'themes/complementary/complementary';

const { neutral } = complementaryTheme;

const colors = {
	background: {
		banner: {
			background: `url(${BackgroundHeader}) no-repeat`, // header
			backgroundSize: '100% 100%',
		},
		secondary: '#f2f3f4',
		tertiary: '#F7F7F7',
	},
	interactive: {
		primary: {
			default: {
				background: '#c7671f',
				label: '#fff',
				border: '#c7671f',
			},
			hover: {
				background: '#F7F7F7',
				label: '#c7671f',
				border: '#c7671f',
			},
		},
		primary02: {
			default: {
				background: '#c7671f',
				label: '#fff',
				border: '#c7671f',
				opacity: '0.65',
			},
		},
		// file button
		primary05: {
			default: {
				background: '#c7671f',
				label: '#fff',
				border: '#c7671f',
			},
			hover: {
				background: '#fff',
				label: '#c7671f',
				border: '#c7671f',
			},
		},
		secondary: {
			default: {
				background: '#eeefef',
				label: '#625d5a',
				border: '#625d5a',
			},
			hover: {
				background: '#f29500',
				label: '#fff',
				border: '#f29500',
			},
		},
		secondary02: {
			default: {
				background: '#f2f3f4',
				label: '#4a89be',
			},
			hover: {
				background: '#f2f3f4',
				label: '#2c6697',
			},
		},
		// button approve modal
		secondary03: {
			default: {
				background: '#333333',
				label: '#fff',
				border: '#333333',
			},
			hover: {
				background: '#fff',
				label: '#333333',
				border: '#333333',
			},
		},
		tertiary: {
			default: {
				background: '#fff',
				label: '#000',
			},
		},
		printModalButtons: {
			default: {
				background: '#c7671f',
				label: '#fff',
				border: '#c7671f',
			},
			hover: {
				background: '#F7F7F7',
				label: '#c7671f',
				border: '#c7671f',
			},
		},
		// properties and edit image modals buttons
		tertiary03: {
			default: {
				background: '#fff',
				label: '#181818',
				border: '#ccc',
			},
			hover: {
				background: '#fff',
				label: '#181818',
				border: '#181818',
			},
		},
		outlined: {
			default: {
				label: '#cb1976',
				icon: '#b9b5aa',
				border: '#b9b5aa',
			},
		},
		inverse: {
			default: {
				label: '#fff',
			},
		},
		discardChangesButtons: {
			default: {
				background: '#c7671f',
				label: '#fff',
				border: '#c7671f',
			},
			hover: {
				background: '#F7F7F7',
				label: '#c7671f',
				border: '#c7671f',
			},
		},
		printPageButtons: {
			default: {
				background: '#c7671f',
				label: '#fff',
				border: '#c7671f',
			},
			hover: {
				background: '#F7F7F7',
				label: '#c7671f',
				border: '#c7671f',
			},
		},
		dropDownButtons: {
			default: {
				label: '#fff',
			},
			active: {
				color: '#00000020',
				label: '#262626',
			},
			focusWithin: {
				background: 'transparent',
				label: '#ffffff',
				border: 'none',
			},
			hover: {
				background: 'transparent',
				label: '#ffffff',
				border: 'none',
			},
		},

		lightest: 'rgba(255, 255, 255, 0.1)',
	},
	field: {
		// search input
		primary: {
			text: '#636466',
			placeholder: '#888888',
		},
		// inputs edit image modal
		secondary: {
			text: '#676767',
			placeholder: '#676767',
		},
		// input properties modal
		tertiary: {
			text: '#676767',
			placeholder: '#676767',
		},
	},
	text: {
		primary: '#636466',
		primary02: '#4a89be', // title chapter, section page

		titleModule: '#4a89be',
		headline01: '#4a89be',
		headline02: '#4a89be',
		headline03: '#4a89be',
		headline04: '#919295',
		headline05: '#919295',
		headline06: '#333',

		paragraph: '#414042',
		specificationTitle: '#919295', // specification title

		approveModalTitle: '#666666',
		approveModalDescription: '#999999',

		addFilesSubtitle: '#999999',

		secondary: '#919295',
		secondary02: '#4a89be',
		secondary03: '#181818', // edit image modal title

		tertiary: '#414042',
		tertiary03: '#181818', // title properties modal

		quaternary: '#333',
		quaternary02: '#333', // labels subtitle edit image modal
		quaternary03: '#000', // labels justification edit image modal

		quinary: '#333',
		quinary02: '#333', // subtitles properties modal
		quinary03: '#333', // title remove modal
		senary: '#4a89be', // title article page

		bullet: '#f29500', // list item bullet

		imageCaption: '#58595b', // image caption
	},
	link: {
		primary: {
			default: {
				label: '#3e3d40',
			},
			hover: {
				label: '#3e3d40',
			},
		},
		secondary: {
			default: {
				label: '#4a89be',

				mobile: {
					label: '#4a89be',
				},
			},
			hover: {
				label: '#2c6697',
			},
			active: {
				label: '#4a89be',

				mobile: {
					label: '#4a89be',
				},
			},
		},
		tertiary: {
			default: {
				label: '#e7a512',

				mobile: {
					label: '#c7671f',
				},
			},
			hover: {
				label: '#c88b05',

				mobile: {
					label: '#c7671f',
				},
			},
			active: {
				label: '#e7a512',

				mobile: {
					label: '#c7671f',
				},
			},
		},
		quaternary: {
			default: {
				label: '#333',
			},
			hover: {
				label: '#333',
			},
			active: {
				label: '#f29500',
			},
		},
	},
	icon: {
		primary: '#fff',
		primary02: '#4a89be',
		chapterArrow: '#3e3d40',
		sectionArrow: '#3e3d40', // section arrow icon
		breadcrumbArrow: '#555555', // breadcrumb arrow icon
	},
	border: {
		primary: '#c7671f',
		secondary: '#FFFFFF',
		tertiary: '#333',
		quaternary: '#eee',
	},
	shadow: {
		primary: 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6)',
		secondary: 'inset 0 1px 0 rgba(255, 255, 255, 0.1)',
	},
	neutral,
	table: {
		row: {
			background: {
				primary: '#f2f3f4',
				secondary: 'transparent',
			},
			cell: {
				label: '#333333',
				border: '2px solid #c7671f',
			},
		},
	},
};

export default colors;
