import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import standardTheme from 'themes/standard';
import './Layout.scss';

const DefaultLayout = ({ children }) => {
	return (
		<div className="DefaultLayout">
			<ThemeProvider theme={standardTheme}>{children}</ThemeProvider>
		</div>
	);
};

DefaultLayout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default DefaultLayout;
