import { useMemo, useEffect } from 'react';
import { func } from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { ThemedTabs } from 'components/themedTabs/ThemedTabs.style';
import useBrandDetails from 'hooks/useBrandDetails';
import { Button } from 'components/button';
import { SimpleModal } from 'components/modals/simpleModal';
import { translations } from 'translation/en';
import { getContentDefaultValues, verifyContentType } from './util/editPageModalUtil';
import { GeneralTab } from './components/generalTab/GeneralTab';
import { SecurityTab } from './components/securityTab/SecurityTab';
import { useEditPageModalSubmit } from './hooks/useEditPageModalSubmit';

export const EditPageModal = ({ onClose }) => {
	const { currentType, chapter, section, article } = useBrandDetails();

	const content = useMemo(() => {
		const { isChapter, isSection, isArticle } = verifyContentType(currentType);

		if (isArticle) {
			return article || {};
		}

		if (isSection) {
			return section || {};
		}

		if (isChapter) {
			return chapter || {};
		}

		return {};
	}, [article, chapter, currentType, section]);

	const methods = useForm({
		defaultValues: getContentDefaultValues(content, currentType),
	});

	const { isSubmitting, onSubmit } = useEditPageModalSubmit(methods, content.path);

	useEffect(() => {
		methods.reset(getContentDefaultValues(content, currentType));
	}, [content, currentType, methods]);

	return (
		<FormProvider {...methods}>
			<SimpleModal open onClose={onClose}>
				<SimpleModal.Header variant="h4">
					{translations.EDIT} {content.title}
				</SimpleModal.Header>
				<SimpleModal.Main>
					<ThemedTabs
						defaultIndex={0}
						tabList={[
							{
								tab: translations.GENERAL,
								render: () => <GeneralTab disableFields={isSubmitting} />,
							},
							{
								tab: translations.SECURITY,
								render: () => <SecurityTab disableFields={isSubmitting} />,
							},
						]}
						forceRenderTabPanel
					/>
				</SimpleModal.Main>
				<SimpleModal.Footer>
					<Button variant="primary" title={translations.SAVE} onClick={onSubmit} disabled={isSubmitting}>
						{isSubmitting ? `${translations.SAVING}...` : translations.SAVE}
					</Button>
					<Button variant="gray-border-only" onClick={onClose} disabled={isSubmitting}>
						{translations.CANCEL}
					</Button>
				</SimpleModal.Footer>
			</SimpleModal>
		</FormProvider>
	);
};

EditPageModal.propTypes = {
	onClose: func.isRequired,
};
