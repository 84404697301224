import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Link } from 'components/link';
import useBrandRoute from 'hooks/useBrandRoute';
import { isLastElement } from 'utils/arrayUtil';
import * as S from './Breadcrumb.style';

const Breadcrumb = ({ steps, haveLink = true }) => {
	const history = useHistory();
	const { getBrandRoute } = useBrandRoute();

	const handleClick = path => {
		history.push(getBrandRoute(path));
	};

	const renderLabel = (label, path) => {
		if (haveLink) {
			return (
				<Link exact variant="quaternary" to={getBrandRoute(path)} onClick={() => handleClick(path)}>
					{label}
				</Link>
			);
		}
		return label;
	};
	return (
		<S.Breadcrumb>
			{steps?.map(({ id, path, label }, index) => {
				return (
					<span key={id + path}>
						{label && (
							<S.Step key={path}>
								{renderLabel(label, path)}
								<S.Icon>{!isLastElement(steps, index) && <i className="icon icon-chevron-right" />}</S.Icon>
							</S.Step>
						)}
					</span>
				);
			})}
		</S.Breadcrumb>
	);
};

Breadcrumb.propTypes = {
	steps: PropTypes.array,
	haveLink: PropTypes.bool,
};

Breadcrumb.defaultProps = {
	steps: [],
	haveLink: true,
};

export { Breadcrumb };
