/**
 * Auth
 */

export const AUTH_SET_USER = '@auth/SET_USER';
export const AUTH_SET_USER_DETAIL = '@auth/AUTH_SET_USER_DETAIL';

/**
 * Location (Hotel)
 */

export const LOCATION_SET_DATA = '@location/SET_DATA';

/**
 * Edit Menu
 */

export const EDIT_MENU_SET_SELECTED_TO_EDIT = '@editMenu/SET_SELECTED_TO_EDIT';

/**
 * Security
 */

export const SECURITY_SET_GROUPS_AND_DEPARTMENTS = '@security/SET_GROUPS_AND_DEPARTMENTS';

/**
 * Beg Search
 */

export const SEARCH_SET_PARAMS = '@search/SET_PARAMS';
export const SEARCH_SET_RESULTS = '@search/SET_RESULTS';
export const SEARCH_SET_NUM_FOUND = '@search/SET_NUM_FOUND';
export const SEARCH_SET_IS_LOADING = '@search/SET_IS_LOADING';
export const SEARCH_SET_AUTO_RUN = '@search/SEARCH_SET_AUTO_RUN';
export const SEARCH_RESET = '@search/SEARCH_RESET';
