import { addMinutes, format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export const getFormattedDate = (date, fmt) => {
  if (!date) return '';

  const dateUTc = new Date(date);
  return format(addMinutes(dateUTc, dateUTc.getTimezoneOffset()), fmt);
};

export const convertToUtc = date => {
  const timeZone = 'Etc/Greenwich';

  return utcToZonedTime(date, timeZone);
};

export const getDateFullInfo = date => {
  const dateWithTimezone = new Date(date);
  const dateFixed = new Date(
    dateWithTimezone.getTime() - dateWithTimezone.getTimezoneOffset() * -60000
  );
  const fullDate = dateFixed.toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const day = `${dateFixed.getUTCDate()}`;
  const month = `${dateFixed.getMonth() + 1}`.padStart(2, '0');
  const year = dateFixed.getFullYear();

  return { fullDate, day, month, year };
};

export const getFutureDate = ({ months }) => {
  const futureDate = new Date();
  futureDate.setMonth(futureDate.getMonth() + months);
  return futureDate;
};
