import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Cell, Pie, PieChart, ResponsiveContainer, Sector, Tooltip } from 'recharts';
import { useMediaQuery } from 'react-responsive';
import { mediaQueryConstants } from '../../constants/mediaQueryConstants';
import { COLORS } from '../../constants/colors';
import { ROLE_VALUE } from '../../constants/modalAnalyticsConstants';
import { translation } from '../../constants/translation';
import { useAnalyticsContext } from '../../hooks/useAnalyticsContext';
import { usePieChartAnalytics } from '../../hooks/usePieChartAnalytics';
import { hasLength, sortStringArrAscendingByKey } from '../../utils/arrayUtils';
import { endDateFormat, startDateFormat } from '../../utils/dateUtils';
import { LabelChartContent } from '../labelChartContent/LabelChartContent';
import { PieTableWrapper } from '../pieTableWrapper/PieTableWrapper';
import { PieChartWrapperSkeleton } from '../skeletons/pieChartWrapperSkeleton/PieChartWrapperSkeleton';
import { DELIVERY } from '../../constants/envConstants';
import './PieChartWrapper.scss';

export const PieChartWrapper = () => {
	const { getMetricsForTable, locationType, metricsTable, isMetricsRoles, isLoading } = useAnalyticsContext();
	const { getMetricsForChart } = usePieChartAnalytics();
	const context = useFormContext();

	const isEnvironmentDelivery = process.env.REACT_APP_ENVIRONMENT_TYPE === DELIVERY;

	const [dataTable, setDataTable] = useState([]);
	const [activeIndex, setActiveIndex] = useState(null);
	const { nameKey } = locationType;

	const data = sortStringArrAscendingByKey(getMetricsForChart(), nameKey);
	const DATA_KEY = locationType.value === ROLE_VALUE ? 'totalRegister' : 'total';

	const { watch } = context;
	const startDateWatch = watch('startDate');
	const endDateWatch = watch('endDate');

	const submit = useCallback(() => {
		const params = {
			startDate: startDateFormat(startDateWatch),
			endDate: endDateFormat(endDateWatch),
		};

		getMetricsForTable(params);
	}, [endDateWatch, getMetricsForTable, startDateWatch]);

	const filterByAbbr = useCallback(
		currentAbbr => {
			setDataTable(
				currentAbbr.reduce((acc, current) => {
					return acc.concat(metricsTable.filter(arr => arr?.[nameKey] === current));
				}, [])
			);
		},
		[metricsTable, nameKey]
	);

	const matches = useMediaQuery(mediaQueryConstants.MQ_TABLET_OR_MOBILE);

	const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
		const RADIAN = Math.PI / 180;
		const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
		const x = cx + radius * Math.cos(-midAngle * RADIAN);
		const y = cy + radius * Math.sin(-midAngle * RADIAN);

		if (matches) {
			return (
				<text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
					{`${(percent * 100).toFixed(1)}%`}
				</text>
			);
		}

		return `${(percent * 100).toFixed(1)}%`;
	};

	const renderActiveShape = params => {
		const { cx, cy, outerRadius, startAngle, endAngle } = params;
		return (
			<Sector
				cx={cx}
				cy={cy}
				outerRadius={outerRadius + 5}
				startAngle={startAngle}
				endAngle={endAngle}
				fill={params.fill}
				fillOpacity={0.9}
				stroke="#fff"
				strokeWidth={1}
			/>
		);
	};

	const onMouseEnter = (_, index) => {
		setActiveIndex(index);
	};

	const onMouseLeave = () => {
		setActiveIndex(null);
	};

	const onClick = useCallback(
		newData => {
			filterByAbbr(isMetricsRoles(newData.name) ? [] : newData.abbr);
		},
		[filterByAbbr, isMetricsRoles]
	);

	useEffect(() => {
		setDataTable([]);
		submit();
	}, [locationType, submit]);

	return (
		<>
			<div className="PieChartWrapper">
				<PieChartWrapperSkeleton isLoading={isLoading.chart}>
					{hasLength(data) ? (
						<>
							<div className="PieChartWrapper__content">
								<ResponsiveContainer>
									<PieChart>
										<Pie
											data={data}
											dataKey={DATA_KEY}
											nameKey={nameKey}
											fill="#0085ce"
											labelLine={!matches}
											label={renderCustomizedLabel}
											activeShape={renderActiveShape}
											activeIndex={activeIndex}
											onMouseEnter={onMouseEnter}
											onMouseLeave={onMouseLeave}
											onClick={onClick}
										>
											{data.map((_, index) => (
												// TODO: should use a unique value coming from the API
												// eslint-disable-next-line react/no-array-index-key
												<Cell key={`cell-${index}`} fill={COLORS[index]} />
											))}
										</Pie>
										<Tooltip />
									</PieChart>
								</ResponsiveContainer>
							</div>

							<LabelChartContent filterByAbbr={filterByAbbr} />
						</>
					) : (
						<p className="PieChartWrapper__info">{translation.NO_STATISTICS}</p>
					)}
				</PieChartWrapperSkeleton>
			</div>
			{!isEnvironmentDelivery && hasLength(dataTable) && <PieTableWrapper data={dataTable} />}
		</>
	);
};
