import axios from 'API/axios/axios';
import { CONTENT_TYPE, NO_TOPIC, QUICKSHEET } from 'constants/quickSheetConstants';
import { getGroupedDataByKey } from 'utils/arrayUtil';
import { hasContentOnSection } from 'utils/paragraphUtil';
import { extractFileNameFromPath, normalizeFileName } from 'utils/fileUtil';
import { getHighlightContent } from 'utils/highlightUtil';
import { removeEmptyNodesFromHTMLString } from 'utils/htmlUtils';
import { isNotEmpty } from 'utils/objectUtils';
import { generateSlug } from 'utils/stringUtil';
import { userAndContentRegionFilter } from 'utils/userUtil';
import { uuid } from 'utils/uuidUtil';

const isSectionContent = section => {
	if (section?.rows[0]?.name === NO_TOPIC) return true;

	return false;
};

export const getRowsByQuickSheet = params => {
	const { operation, topic, path } = params;
	const url = `${process.env.REACT_APP_API_URL}/v1/contentFragment/rowsByQuickSheet`;

	return axios.get(url, {
		params: {
			operation,
			topic,
			sheetPath: path,
		},
	});
};

const generateLinkList = linkArray => {
	let html = '<ul>';

	linkArray.forEach(link => {
		const { title, url, additionalText } = link;

		if (additionalText) {
			// eslint-disable-next-line max-len
			html += `<li><p><a data-mce-href="${url}" href="${url}" target="_blank" rel="noopener">${title}</a> ${additionalText}</p></li>`;
		} else {
			// eslint-disable-next-line max-len
			html += `<li><p><a data-mce-href="${url}" href="${url}" target="_blank" rel="noopener">${title}</a></p></li>`;
		}
	});

	html += '</ul>';

	return html;
};

const generateHTMLText = (arr, searchParams = {}) => {
	let html = '';
	const { searchTerm, isExactMatch } = searchParams;

	for (let i = 0; i < arr.length; i++) {
		const { globalBrand } = arr[i];
		let highlightedContent = globalBrand;

		if (searchTerm && isExactMatch) {
			highlightedContent = getHighlightContent({ isExactMatch, searchTerm, content: globalBrand });
		}

		html += `${highlightedContent}`;
	}

	return html;
};

const generateHTMLList = (arr, searchParams = {}) => {
	let html = '';
	let level = -1;
	const { searchTerm, isExactMatch } = searchParams;

	for (let i = 0; i < arr.length; i++) {
		const { globalBrand, level: objLevel } = arr[i];
		let highlightedContent = globalBrand;

		if (searchTerm && isExactMatch) {
			highlightedContent = getHighlightContent({ isExactMatch, searchTerm, content: globalBrand });
		}

		if (i === 0 && objLevel > 0) {
			html += '<ul>';
		}

		if (objLevel > level) {
			html += `<ul><li><p>${highlightedContent}</p>`;
			level = objLevel;
		} else if (objLevel < level) {
			html += `${'</li></ul>'.repeat(level - objLevel)}</li><li><p>${highlightedContent}</p>`;
			level = objLevel;
		} else {
			html += `</li><li><p>${highlightedContent}</p>`;
		}
	}

	html += '</li></ul>'.repeat(level + 1);

	return removeEmptyNodesFromHTMLString(html);
};

const generateBody = (arr, searchParams = {}, contentType = CONTENT_TYPE.LIST) => {
	switch (contentType) {
		case CONTENT_TYPE.LIST:
			return generateHTMLList(arr, searchParams);
		case CONTENT_TYPE.TEXT:
			return generateHTMLText(arr, searchParams);
		default:
			return generateHTMLList(arr, searchParams);
	}
};

const generateContent = (data, searchParams, brandParams, user) => {
	const { brandId } = brandParams;

	return data.map(item => {
		const filtered = item.list?.filter(r => {
			const rowBrands = r.brands?.map(b => b.abbr);
			const rowRegions = { regions: r.region };

			if (!rowBrands) return false;

			if (isNotEmpty(user) || brandId) {
				const hasBrand = rowBrands.includes(brandId);
				const hasRegion = userAndContentRegionFilter(user, rowRegions);

				return hasBrand && hasRegion;
			}

			return true;
		});

		if (filtered.length === 0) return null;

		const groupedByGroupKey = getGroupedDataByKey(filtered, 'groupKey');

		const content = groupedByGroupKey
			.map(group => {
				const sorted = group.list.sort((a, b) => {
					if (+a.serviceOrder === +b.serviceOrder) {
						return +a.level - +b.level;
					}

					return +a.serviceOrder - +b.serviceOrder;
				});

				return {
					bRefOrder: group.group.brandRefOrder,
					content: generateBody(sorted, searchParams, group.list[0]?.contentType, brandId),
				};
			})
			.sort((a, b) => +a.bRefOrder - +b.bRefOrder)
			.map(article => article.content);

		const id = item.group.groupKey;
		const title = item.group.subTopic;
		const html = content.join('');

		const linkArray = filtered
			.filter(({ link }) => link)
			.map(({ link }) => link)
			.flat()
			.filter(link => {
				const linkBrands = link.brands.map(b => b.value);

				if (brandId) {
					return linkBrands.includes(brandId);
				}

				return true;
			});

		const links = linkArray.length > 0 ? generateLinkList(linkArray) : '';

		const files = item.list
			?.filter(row => row.files)
			?.map(row => row.files)
			?.flat()
			?.filter(file => {
				if (!file.brands) return false;

				const fileBrands = JSON.parse(file.brands).map(b => b.value);

				if (brandId) {
					return fileBrands.includes(brandId);
				}

				return true;
			})
			?.sort((a, b) => +a.DispOrder - +b.DispOrder)
			?.map(file => ({
				...file,
				vcmId: file.VCMID,
				linkText: normalizeFileName(file.FILE_LINK_TEXT),
				filePath: file.file[0],
				fileName: extractFileNameFromPath(file.file[0]),
				displayOrder: +file.DispOrder || 0,
			}));

		return {
			vcmId: id,
			title,
			body: html,
			linkArray,
			links,
			files,
			displayOrder: title ? item.displayOrder || 0 : 0,
			brandRefOrder: +item.group.brandRefOrder,
		};
	});
};

const createContent = (data, searchParams, brandParams, user) => {
	if (!data?.rows || !data?.rows?.length) {
		return null;
	}

	const { keymaps } = data;
	const contentOptions = keymaps?.find(keymap => keymap.type === 'subTopic')?.options;

	let grouped = getGroupedDataByKey(data?.rows, 'subTopic');
	grouped = grouped.map(item => {
		let stKey = item.group.subTopicKey;
		const groupSubTopic = item.group.subTopic;

		if (!stKey) {
			const [, , tmp] = item.group.groupKey.split('.');
			stKey = tmp;
		}

		const displayOrder = contentOptions
			?.find(option => option.key === stKey || option.content.toLowerCase() === groupSubTopic?.toLowerCase())
			?.displayOrder?.toString();

		if (!item.group.subTopic || !displayOrder) {
			return {
				...item,
				displayOrder: '99',
			};
		}
		return {
			...item,
			displayOrder,
		};
	});

	const generatedContent = generateContent(grouped, searchParams, brandParams, user)
		.filter(item => item)
		.sort((a, b) => {
			if (+a.displayOrder === +b.displayOrder) {
				return +a.brandRefOrder - +b.brandRefOrder;
			}

			return +a.displayOrder - +b.displayOrder;
		});

	return generatedContent.length > 0 ? generatedContent : null;
};

export const fromRowsByQuickSheet = (data, searchParams, brandParams = {}, user = {}) => {
	let item = data;

	if (isSectionContent(data?.quicksheet?.operations[0])) {
		item = {
			keymaps: data.quicksheet.keyMaps,
			rows: data.quicksheet.operations[0].rows[0].rows,
		};
	}

	const payload = createContent(item, searchParams, brandParams, user);

	return {
		paragraphs: payload
			?.filter(i => i)
			?.sort((a, b) => +a.displayOrder - +b.displayOrder)
			?.map(({ vcmId, title, body, linkArray, files, displayOrder }) => {
				return {
					type: QUICKSHEET,
					vcmId: generateSlug(vcmId),
					title,
					body,
					linkArray,
					files,
					displayOrder,
				};
			}),
	};
};

const generateArticleParagraphContent = paragraph => {
	return {
		path: '',
		title: paragraph.title,
		body: paragraph.body,
		vcmId: paragraph.vcmId,
		links: paragraph.links,
		displayOrder: +paragraph.displayOrder,
		type: QUICKSHEET,
	};
};

export const fromArticleByQuickSheet = (data, brandParams = {}, user) => {
	if (!data?.rows || !data?.rows?.length) {
		return {
			paragraphs: [],
		};
	}

	const payload = createContent(data, {}, brandParams, user);

	return {
		title: data?.rows[0]?.topic,
		paragraphs: payload?.filter(item => item)?.map(paragraph => generateArticleParagraphContent(paragraph)),
	};
};

export const generateSectionContent = (data, brandParams, user) => {
	if (!data) {
		return {
			paragraphs: [],
		};
	}

	const { operations, keyMaps } = data;
	const contentOptions = keyMaps?.find(keymap => keymap.type === 'topic')?.options;
	const section = operations[0];

	if (isSectionContent(section)) {
		const content = createContent(section.rows[0], {}, brandParams, user);

		if (content?.every(item => !item || item === null)) return null;

		return {
			sectionTitle: section?.name,
			paragraphs: content?.map(({ title, body, vcmId, displayOrder, links }) => ({
				path: '',
				title,
				body,
				vcmId,
				links,
				displayOrder,
				type: QUICKSHEET,
			})),
		};
	}

	let grouped = getGroupedDataByKey(section?.rows, 'topic');

	grouped = grouped[0].list.map(item => {
		let tKey = item.rows[0].topicKey;

		if (!tKey) {
			const [, tmp] = item.rows[0].groupKey.split('.');
			tKey = tmp;
		}

		const displayOrder = contentOptions?.find(option => option.key === tKey)?.displayOrder?.toString();

		if (!item.rows[0].topic || !displayOrder) {
			return {
				...item,
				displayOrder: '0',
			};
		}

		return {
			...item,
			displayOrder,
		};
	});

	const articles = grouped.map(article => {
		const item = {
			keymaps: keyMaps,
			rows: article.rows,
		};

		return {
			...fromArticleByQuickSheet(item, brandParams, user),
			displayOrder: article.displayOrder,
		};
	});

	return {
		sectionTitle: section?.name,
		paragraphs: null,
		articles: articles?.map(article => {
			return {
				displayOrder: article.displayOrder || 0,
				articleTitle: article.title,
				paragraphs: article?.paragraphs
					?.filter(item => item)
					.map(paragraph => generateArticleParagraphContent(paragraph)),
			};
		}),
	};
};

export const fromSectionByQuickSheet = (data, brandParams = {}, user = {}) => {
	if (!data.quicksheet) {
		return null;
	}

	const final = {
		sections: [generateSectionContent(data.quicksheet, brandParams, user)],
	};

	return final;
};

export const fromChapterByQuickSheet = (data, brandParams = {}, user = {}) => {
	if (!data.quicksheet) {
		return null;
	}

	const { operations, keyMaps } = data.quicksheet;
	const contentOptions = keyMaps?.find(keymap => keymap.type === 'operation')?.options;

	const newOperations = operations.map(operation => {
		let oKey = operation.rows[0].rows[0].operationKey;

		if (!oKey) {
			const [tmp] = operation.rows[0].rows[0].groupKey.split('.');
			oKey = tmp;
		}

		const displayOrder = contentOptions?.find(option => option.key === oKey)?.displayOrder?.toString();

		if (!operation.rows[0].rows[0].operation || !displayOrder) {
			return {
				...operation,
				displayOrder: '0',
			};
		}

		return {
			...operation,
			displayOrder,
		};
	});

	const sections = newOperations.map(operation => {
		const item = {
			operations: [operation],
			keyMaps,
		};

		return {
			...generateSectionContent(item, brandParams, user),
			displayOrder: operation.displayOrder,
		};
	});

	const final = {
		chapter: {
			title: data.quicksheet.title,
			paragraphs: null,
			sections: sections
				?.filter(section => hasContentOnSection(section))
				.map(section => {
					return {
						id: uuid(),
						sectionTitle: section.sectionTitle,
						paragraphs: section.paragraphs,
						sectionDisplayOrder: section.displayOrder,
						articles: section.articles?.map(article => {
							return {
								articleDisplayOrder: article.displayOrder || 0,
								articleTitle: article.articleTitle,
								paragraphs: article?.paragraphs
									?.filter(item => item)
									?.map(paragraph => generateArticleParagraphContent(paragraph)),
							};
						}),
					};
				}),
		},
	};

	return final;
};
