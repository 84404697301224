/* eslint-disable react/no-danger */
import { CSSProperties, useMemo, useRef } from 'react';
import classNames from 'classnames';
import DOMPurify, { DOMPurifyI } from 'dompurify';
import { embedConfig } from './constants/innerHtmlConstants';
import { useAnchors } from './hooks/useAnchors';
import { isSrcAllowed } from './utils/innerHtmlUtils';
import './InnerHtml.scss';

DOMPurify.addHook('afterSanitizeAttributes', node => {
	if ('target' in node) {
		node.setAttribute('target', '_blank');
		node.setAttribute('rel', 'noopener');
	}
});

DOMPurify.addHook('uponSanitizeElement', (node, data) => {
	if (data.tagName === 'iframe') {
		const src = node.getAttribute('src') || '';
		if (!isSrcAllowed(src)) {
			node.parentNode?.removeChild(node);
		}
	}
	if ('data-ng-click' in node) {
		node.removeAttribute('data-ng-click');
	}
});

const clearInlineStyle = (domPurify: DOMPurifyI) => {
	domPurify.addHook('uponSanitizeElement', node => {
		if ('style' in node) {
			node.removeAttribute('style');
		}
	});
	return domPurify;
};

type InnerHtmlProps = {
	body: string;
	className?: string;
	format?: string;
	shouldClearInlineStyle?: boolean;
	style?: CSSProperties;
};

function InnerHtml({ body, className = '', format = '', shouldClearInlineStyle = false, style }: InnerHtmlProps) {
	const ref = useRef<HTMLSpanElement | null>(null);
	useAnchors(ref);

	const sanitizer = shouldClearInlineStyle ? clearInlineStyle(DOMPurify).sanitize : DOMPurify.sanitize;

	const sanitizedBody = useMemo(() => sanitizer(body, embedConfig), [body, sanitizer]);

	return (
		<span
			ref={ref}
			className={classNames('InnerHtml', className, {
				[`InnerHtml--${format}`]: format !== '',
			})}
			style={style}
			dangerouslySetInnerHTML={{ __html: sanitizedBody }}
		/>
	);
}

export { InnerHtml };
