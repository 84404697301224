import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useSearchData = () => {
	return useSelector(store => store.search);
};

export const useSearchContent = () => {
	const store = useSearchData();

	const isLoading = useMemo(() => store.isLoading, [store.isLoading]);

	const searchTerm = useMemo(() => store.searchTerm, [store.searchTerm]);

	const results = useMemo(() => store.results, [store.results]);

	const numFound = useMemo(() => store.numFound, [store.numFound]);

	const isExactMatch = useMemo(() => store.isExactMatch, [store.isExactMatch]);

	const isAutoRunEnabled = useMemo(() => store.isAutoRunEnabled, [store.isAutoRunEnabled]);

	return {
		results,
		numFound,
		isLoading,
		isExactMatch,
		searchTerm,
		isAutoRunEnabled,
	};
};
