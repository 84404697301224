import { Button } from 'components/button';
import { Modal } from 'components/modals/modal';
import { bool, func } from 'prop-types';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { translations } from 'translation/en';
import { getOverflow } from 'utils/overflowUtil';
import * as S from './DiscardChangesModal.style';

const DiscardChangesModal = ({ open, onContinueEdit, onDiscardChanges }) => {
	const [domReady, setDomReady] = useState(false);
	const portalElement = document.getElementById('root');

	useEffect(() => {
		setDomReady(true);
		const currentOverflowY = portalElement?.style.overflowY;
		if (portalElement) portalElement.style.overflowY = getOverflow(open);

		return () => {
			setDomReady(false);
			if (portalElement) portalElement.style.overflowY = currentOverflowY;
		};
	}, [open, portalElement]);

	const modalComponent = open && (
		<S.DiscardChangesModal open={open} closeIcon={<div />}>
			<Modal.Main>
				<S.ModalLabel>{translations.DISCARD_CHANGES_TEXT}</S.ModalLabel>
			</Modal.Main>
			<Modal.Footer style={{ padding: '32px' }}>
				<Button variant="primary-04" onClick={onContinueEdit}>
					{translations.CONTINUE}
				</Button>
				<Button variant="primary-04" onClick={onDiscardChanges}>
					{translations.DISCARD_CHANGES}
				</Button>
			</Modal.Footer>
		</S.DiscardChangesModal>
	);

	return domReady ? ReactDOM.createPortal(modalComponent, portalElement) : null;
};

DiscardChangesModal.propTypes = {
	open: bool,
	onContinueEdit: func,
	onDiscardChanges: func,
};

DiscardChangesModal.defaultProps = {
	open: false,
	onContinueEdit: () => {},
	onDiscardChanges: () => {},
};

export { DiscardChangesModal };
