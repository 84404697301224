import { useEffect, useMemo, useState, useRef, useCallback } from 'react';
import { getReplicateStatusByOperationId, publishApprovedAsync } from 'API/publishAPI';
import { showToast } from 'utils/toastUtil';
import { translations } from 'translation/en';
import { getChaptersByBrand } from 'services/chaptersByBrandService';
import { isEmpty } from 'utils/arrayUtil';
import { useRequest } from './useRequest';
import useClearCache from './useClearCache';
import useApprovePublish from './useApprovePublish';
import useBrandDetails from './useBrandDetails';

const FIVE_SECONDS = 5000;

export const usePublishBrands = () => {
	const initialState = useMemo(
		() => ({
			loading: false,
			data: null,
			error: null,
		}),
		[]
	);

	const { run: fetchChaptersByBrand } = useRequest(getChaptersByBrand);
	const { error: publishError, run: postPublishBrands } = useRequest(publishApprovedAsync);
	const { error: replicateStatusError, run: fetchReplicateStatus } = useRequest(getReplicateStatusByOperationId);

	const intervalRef = useRef();
	const [requestData, setRequestData] = useState(initialState);
	const { setIsPagePublished } = useApprovePublish();
	const { currentType } = useBrandDetails();
	const { clearCache, clearCacheDelivery } = useClearCache();

	const stopInterval = () => {
		clearInterval(intervalRef.current);
	};

	const handleStatusPublishOfCurrentPage = useCallback(
		pathsToPublish => {
			if (currentType && pathsToPublish) {
				const currentPageIsApproved = currentType.isPageApproved;
				const foundItem = pathsToPublish.find(item => item === currentType.path && currentPageIsApproved);

				if (foundItem) {
					setIsPagePublished(true);
				}
			}
		},
		[currentType, setIsPagePublished]
	);

	const startInterval = useCallback(
		(operationPrefix, operationId) => {
			return new Promise((resolve, reject) => {
				intervalRef.current = setInterval(async () => {
					const operation = `${operationPrefix}${operationId}`;

					try {
						const response = await fetchReplicateStatus(operation);
						if (isEmpty(response?.data)) {
							stopInterval();
							clearCache({ successCallback: () => {} });
							clearCacheDelivery({ shouldShowToast: false });
							setRequestData({ ...initialState, data: response });
							showToast({ type: 'success', message: translations.PUBLISH_BRAND_SUCCESS });
							resolve();
						}
					} catch (err) {
						stopInterval();
						setRequestData({ ...initialState, error: err });
						reject(err);
					}
				}, FIVE_SECONDS);
			});
		},
		[initialState, clearCache, clearCacheDelivery, fetchReplicateStatus]
	);

	const fetchPathsByBrand = useCallback(
		selectedBrandsArray => {
			if (isEmpty(selectedBrandsArray)) {
				throw new Error();
			}

			const promises = selectedBrandsArray.map(brand =>
				fetchChaptersByBrand({ brandId: brand.brandId, pagePath: brand.oldBrandPath })
			);

			return Promise.all(promises)
				.then(response => {
					return response.flatMap(item => item.data.data.siteChapterByBrands.items.flatMap(row => row._path));
				})
				.catch(err => {
					setRequestData({ ...initialState, error: err });
					return err;
				});
		},
		[initialState, fetchChaptersByBrand]
	);

	const publishBrands = useCallback(
		async selectedBrandsArray => {
			if (isEmpty(selectedBrandsArray)) {
				throw new Error();
			}

			setRequestData({
				...initialState,
				loading: true,
			});

			const pathsToPublish = await fetchPathsByBrand(selectedBrandsArray);

			try {
				const response = await postPublishBrands(pathsToPublish);
				if (response.data) {
					const { operationId, operationPrefix } = response.data;
					await startInterval(operationPrefix, operationId);
					handleStatusPublishOfCurrentPage(pathsToPublish);
				}
			} catch (err) {
				setRequestData({ ...initialState, error: err });
			}
		},
		[initialState, fetchPathsByBrand, postPublishBrands, startInterval, handleStatusPublishOfCurrentPage]
	);

	const isLoadingPublishBrand = useMemo(() => requestData.loading, [requestData.loading]);

	const isErrorPublishBrand = useMemo(
		() => [publishError, replicateStatusError, requestData.error].some(Boolean),
		[publishError, replicateStatusError, requestData.error]
	);

	const publishedBrand = useMemo(() => requestData.data, [requestData.data]);

	useEffect(() => {
		if (isErrorPublishBrand) {
			showToast({ type: 'error', message: translations.ERROR_PUBLISHING_PAGES });
		}
	}, [initialState, isErrorPublishBrand]);

	return {
		isLoadingPublishBrand,
		publishedBrand,
		publishBrands,
	};
};
