import { useCallback } from 'react';
import { useHistory } from 'react-router';
import routers from 'router/router';
import useBrandData from './useBrandData';

const useBrandRoute = () => {
	const history = useHistory();
	const { brandName } = useBrandData();

	const baseRoute = `${routers.brandROUTE.getRouteDefaultPath()}/${brandName}`;

	const getBrandRoute = useCallback(
		path => {
			return `${baseRoute}${path}`;
		},
		[baseRoute]
	);

	const resetRoute = useCallback(() => {
		return history.replace(baseRoute);
	}, [history, baseRoute]);

	return { baseRoute, getBrandRoute, resetRoute };
};

export default useBrandRoute;
