export const descriptions = {
	PORTRAIT_BUTTON: 'portrait-button',
	LANDSCAPE_BUTTON: 'landscape-button',
	POWERPOINT_BUTTON: 'powerPoint-button',
};

export const orientationValue = {
	PORTRAIT: ' A4 portrait',
	LANDSCAPE: 'landscape letter',
	POWERPOINT: 'PowerPoint',
};
