import hyattZilaraLogo from 'assets/img/logos/hyatt-zilara-logo.svg';
import hyattZilaraLogoFooter from 'assets/img/logos/zilara-horizontal.svg';
import complementaryTheme from 'themes/complementary/complementary';
import colors from './hyattZilaraColors';
import typographyTheme from './hyattZilaraTypo';

const logo = {
	footer: hyattZilaraLogoFooter,
	loading: hyattZilaraLogo, // logo in the loading
	sidebar: hyattZilaraLogo,
	mobile: hyattZilaraLogo,
	size: {
		sidebar: {
			width: '109px',
			height: '80px',
		},
		footer: {
			width: '248px',
			height: '35px',
		},
		navbar: {
			width: '109px',
			height: '144px',
		},
		loading: {
			width: '100px',
			height: '100px',
		},
	},
};

const copyrightText = {
	padding: '5px 0 5px 18px',
};

const hyattZilara = {
	brandId: 'zl',
	name: 'Hyatt Zilara',
	logo,
	color: colors,
	copyrightText,

	font: {
		family: {
			helveticaNeue: 'Helvetica Neue',
			verlagBook: 'Verlag Book',
			verlagBookCalibriArial: 'Verlag Book, Calibri, Arial',
			verlagBlack: 'Verlag Black',
			verlagBold: 'Verlag Bold',
			arialSansSerif: 'Arial, Helvetica, sans-serif',
			yesevaOneRegular: 'YesevaOne Regular',
			calibri: 'Calibri, Arial',
			notoSansBold: 'NotoSans Bold',
			poynterRegular: 'Poynter Regular',
			gothamBook: 'Gotham Book',
			neutraBold: 'Neutra Bold',
			robotoMonoRegular: 'RobotoMono Regular',
		},
	},
};

const hyattZilaraTheme = {
	...hyattZilara,
	...typographyTheme(hyattZilara),
	...complementaryTheme,
};

export default hyattZilaraTheme;
