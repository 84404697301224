import complementaryTheme from 'themes/complementary/complementary';

const { size, weight, lineHeight, letter, transform, decoration } = complementaryTheme;

const createHeadline = baseTheme => ({
	headline: {
		// title chapter & section page
		'01': {
			fontFamily: baseTheme.font.family.jostLight,
			textTransform: transform.uppercase,
			fontSize: size['xl'],
			fontWeight: weight.medium,
		},
		// module title
		'01.1': {
			fontFamily: baseTheme.font.family.jostMedium,
			textTransform: transform.uppercase,
			fontSize: size['sm'],
			fontWeight: weight.medium,
		},
		// h1
		'01.2': {
			fontFamily: baseTheme.font.family.jostLight,
			fontSize: size['xl'],
			fontWeight: weight.medium,
			lineHeight: lineHeight.xs,
			textTransform: transform.uppercase,
		},
		// title article page
		'02': {
			fontFamily: baseTheme.font.family.jostMedium,
			textTransform: transform.uppercase,
			fontWeight: weight.medium,
			fontSize: size['sm'],
		},
		// h2 content page
		'02.1': {
			fontFamily: baseTheme.font.family.jostMedium,
			fontSize: size['sm'],
			textTransform: transform.uppercase,
			fontWeight: weight.medium,
			lineHeight: '1em',
		},
		// h3 content page
		'03': {
			fontFamily: baseTheme.font.family.jostMedium,
			fontSize: size['sm'],
			textTransform: transform.uppercase,
			fontWeight: weight.medium,
			lineHeight: lineHeight.xs,
		},
		// h4 content page
		'04': {
			fontFamily: baseTheme.font.family.jostMedium,
			fontSize: size['1.5xs'],
			fontWeight: weight.medium,
			textTransform: transform.uppercase,
			lineHeight: lineHeight.xs,
		},
		// specification dropdown title
		'04.1': {
			fontFamily: baseTheme.font.family.jostMedium,
			fontSize: size['2xs'],
			fontWeight: weight.medium,
			textTransform: transform.uppercase,
			lineHeight: lineHeight.normal,
		},
		// h5 content page
		'05': {
			fontFamily: baseTheme.font.family.jostMedium,
			fontSize: size['1.5xs'],
			fontWeight: weight.medium,
			textTransform: transform.uppercase,
			lineHeight: lineHeight.xs,
		},
		// h6 content page
		'06': {
			fontFamily: baseTheme.font.family.jostMedium,
			fontSize: size['1.5xs'],
			fontWeight: weight.medium,
			lineHeight: lineHeight.xs,
		},
		// title terms and conditions modal
		'07': {
			fontFamily: baseTheme.font.family.jostLight,
			fontSize: size['5xl'],
			textTransform: transform.uppercase,
			lineHeight: '1.4em',
			fontWeight: weight.medium,
		},
		// title approve modal
		'08': {
			fontFamily: baseTheme.font.family.jostMedium,
			fontWeight: weight.medium,
			fontSize: size.xl,
			lineHeight: '1.4em',
		},
		// title properties modal
		'09': {
			fontFamily: baseTheme.font.family.jostLight,
			fontWeight: weight.thin,
			fontSize: size['2.5xl'],
			textTransform: transform.uppercase,
		},
		// title edit image modal
		10: {
			fontFamily: baseTheme.font.family.jostLight,
			fontWeight: weight.medium,
			fontSize: size['1xl'],
			textTransform: transform.uppercase,
		},
	},
});

const createBody = baseTheme => ({
	body: {
		// terms & conditions content modal
		'01': {
			fontFamily: baseTheme.font.family.jostRegular,
			fontSize: size['1.5xs'],
			fontWeight: weight.regular,
			lineHeight: lineHeight.normal,
		},
		// paragraphs content
		'02': {
			fontFamily: baseTheme.font.family.jostRegular,
			fontSize: size['1.5xs'],
		},
		// labels subtitle edit image modal
		'03': {
			fontFamily: baseTheme.font.family.arialSansSerif,
			fontSize: size['lg'],
			textTransform: transform.uppercase,
		},
		// labels justification edit image modal
		'04': {
			fontFamily: baseTheme.font.family.arialSansSerif,
			fontSize: size['md'],
			textTransform: transform.uppercase,
		},
		// labels field file modal
		'05': {
			fontFamily: baseTheme.font.family.arialSansSerif,
			fontSize: size['1lg'],
			textTransform: transform.uppercase,
		},
		// title print modal
		'06': {
			fontFamily: baseTheme.font.family.jostSemiBold,
			fontSize: size['xs'],
			fontWeight: weight.thin,
		},
		// underlist and list
		'07': {
			fontFamily: baseTheme.font.family.jostRegular,
			fontSize: size['1.5xs'],
			bulletSize: size.xs,
			lineHeight: lineHeight.normal,
		},
	},
});

const createCaption = baseTheme => ({
	caption: {
		// footer
		'01': {
			fontFamily: baseTheme.font.family.gothamLight,
			fontSize: size.xs,
		},
		// subtitles properties modal
		'02': {
			textTransform: transform.uppercase,
			fontFamily: baseTheme.font.family.arialSansSerif,
			fontSize: size['lg'],
		},
		// approve modal description
		'03': {
			fontFamily: baseTheme.font.family.arialSansSerif,
			fontSize: size['2xs'],
			lineHeight: lineHeight.normal,
		},
		// input placeholder file modal
		'04': {
			fontFamily: baseTheme.font.family.arialSansSerif,
			fontSize: size['sm'],
			fontWeight: weight.thin,
			textTransform: transform.uppercase,
		},
		// input file modal
		'05': {
			fontFamily: baseTheme.font.family.arialSansSerif,
			fontSize: size['sm'],
			fontWeight: weight.thin,
			textTransform: transform.uppercase,
		},
		// subtitle add files button
		'06': {
			fontFamily: baseTheme.font.family.jostRegular,
			fontSize: size['1.5x'],
			fontStyle: decoration.italic,
			lineHeight: lineHeight.normal,
		},
		// name file in file modal
		'07': {
			fontFamily: baseTheme.font.family.jostSemiBold,
			fontSize: size['1.5xs'],
			lineHeight: lineHeight.small,
		},
		// image caption text
		'08': {
			fontFamily: baseTheme.font.family.jostRegular,
			fontSize: size['3xs'],
		},
	},
});

const createLink = baseTheme => ({
	link: {
		// breadcrumb
		'01': {
			fontFamily: baseTheme.font.family.arialSansSerif,
			fontSize: size.xs,
		},
		'02': {
			fontFamily: baseTheme.font.family.jostRegular,
			fontSize: size['2xs'],
			letterSpacing: letter.spacing.md,
			textTransform: transform.uppercase,
		},
		// sidebar item chapter
		'03': {
			fontFamily: baseTheme.font.family.jostRegular,
			fontSize: size.sm,
		},
		// sidebar item section
		'04': {
			fontFamily: baseTheme.font.family.jostRegular,
			fontSize: size.sm,
		},
		// sidebar item article
		'05': {
			fontFamily: baseTheme.font.family.jostRegular,
			fontSize: size.sm,
		},
		// logo sidebar
		'06': {
			margin: '0 auto 55px auto',
		},
	},
});

const createButton = baseTheme => ({
	button: {
		'01': {
			fontFamily: baseTheme.font.family.helveticaNeue,
			fontSize: size['2xs'],
			letterSpacing: letter.spacing.md,
			textTransform: transform.uppercase,
		},
		// sign out button
		'02': {
			fontFamily: baseTheme.font.family.avenirBook,
			fontSize: size['1xs'],
			fontWeight: weight.regular,
		},
		// label help button
		'03': {
			fontFamily: baseTheme.font.family.arialSansSerif,
			fontSize: size.xs,
			fontWeight: weight.semibold,

			sm: {
				fontSize: size.sm,
			},
		},
		// Text in the print modal buttons
		'04': {
			fontFamily: baseTheme.font.family.jostRegular,
			fontSize: size['2xs'],
			letterSpacing: letter.spacing.md,
			textTransform: transform.uppercase,
			lineHeight: lineHeight.medium,
		},
		// label buttons properties and edit image modals
		'05': {
			fontFamily: baseTheme.font.family.jostRegular,
			fontSize: size['2xs'],
			letterSpacing: letter.spacing.md,
			textTransform: transform.uppercase,
			lineHeight: lineHeight.medium,
		},
		// approve modal button
		'06': {
			fontFamily: baseTheme.font.family.robotoMonoRegular,
			fontSize: size['1.5xs'],
			letterSpacing: letter.spacing.md,
			textTransform: transform.uppercase,
			lineHeight: lineHeight.medium,
		},
		// discard changes button
		'07': {
			fontFamily: baseTheme.font.family.jostRegular,
			fontSize: size['2xs'],
			letterSpacing: letter.spacing.md,
			textTransform: transform.uppercase,
			lineHeight: lineHeight.medium,
		},

		// file button text
		'08': {
			fontFamily: baseTheme.font.family.jostRegular,
			fontSize: size['2xs'],
			letterSpacing: letter.spacing.md,
			textTransform: transform.uppercase,
			lineHeight: lineHeight.medium,
			fontWeight: weight.regular,
		},

		icon: {
			fontSize: size.md,

			xl: {
				fontSize: size.xl,
			},

			'2xl': {
				fontSize: size['2xl'],
			},
		},
	},
});

const createLead = baseTheme => ({
	lead: {
		// checkbox label search bar
		'01': {
			fontFamily: baseTheme.font.family.arialSansSerif,
			fontSize: size.xs,
		},
		// search input text & placeholder
		'02': {
			fontFamily: `${baseTheme.font.family.arialSansSerif} !important`,
			fontSize: size['2xs'],
			lineHeight: lineHeight.medium,
		},
		// subtitle print modal
		'03': {
			fontFamily: baseTheme.font.family.jostSemiBold,
			fontSize: size['1.5xs'],
			fontWeight: weight.bold,
			lineHeight: lineHeight.small,
		},
		// title discard changes modal
		'04': {
			fontFamily: baseTheme.font.family.jostSemiBold,
			fontSize: size['1.5xs'],
			fontWeight: weight.medium,
			textTransform: `${transform.none} !important`,
		},
		// title remove modal
		'05': {
			fontFamily: baseTheme.font.family.helveticaNeue,
			fontSize: size.sm,
			fontWeight: weight.medium,
			lineHeight: lineHeight.small,
		},
		// inputs edit image modal
		'06': {
			fontFamily: baseTheme.font.family.arialSansSerif,
			fontSize: size.md,
			textTransform: transform.none,
		},
		// input properties modal
		'07': {
			fontFamily: baseTheme.font.family.arialSansSerif,
			fontSize: size.lg,
			fontWeight: weight.thin,
			textTransform: transform.uppercase,
		},
		// checkbox label properties modal
		'08': {
			fontFamily: baseTheme.font.family.jostSemiBold,
			fontSize: size['1.5xs'],
			fontWeight: weight.medium,
			lineHeight: lineHeight.normal,
		},
	},
});

const createTable = baseTheme => ({
	table: {
		'01': {
			fontFamily: baseTheme.font.family.arialSansSerif,
			fontSize: size['sm'],
			lineHeight: lineHeight.normal,
		},
	},
});

const typographyTheme = baseTheme => ({
	typo: {
		...createHeadline(baseTheme),
		...createCaption(baseTheme),
		...createLink(baseTheme),
		...createButton(baseTheme),
		...createLead(baseTheme),
		...createBody(baseTheme),
		...createTable(baseTheme),
	},
});

export default typographyTheme;
