import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export const useOnBrandRouteChange = onChange => {
	const { brandName } = useParams();
	const [currentRoute, setCurrentRoute] = useState('');

	useEffect(() => {
		if (currentRoute !== brandName) {
			setCurrentRoute(brandName);
			onChange(brandName);
		}
	}, [currentRoute, brandName, onChange]);
};
