import styled from 'styled-components/macro';

export const BrandSideBarChapter = styled.li({
	display: 'block',
	marginBottom: '15px',
	position: 'relative',
	width: '100%',
	background: 'transparent',
});

export const Body = styled.div(({ isExpanded }) => ({
	clipPath: 'inset(0 -100vw)',
	height: 'auto',
	maxHeight: isExpanded ? '3000px' : 0,
	transition: isExpanded ? 'max-height 1.5s ease 0s, padding 0.4s' : 'max-height 0.5s ease 0s, padding 0.4s',
	padding: isExpanded ? '5px 0' : 0,
	overflow: 'hidden',
}));
