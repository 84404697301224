import { hasLength } from './arrayUtil';
import { logError } from './logUtil';

export const isFunction = fn => fn && fn instanceof Function;

export const combineAll = (params = []) => {
	const funcList = Object.values(params);
	if (!hasLength(funcList)) {
		return null;
	}
	const [funcOne, ...others] = funcList;
	if (!hasLength(others)) {
		return funcOne();
	}
	return others.reduce((accumulator, currentFunc) => currentFunc(accumulator), funcOne());
};

export const executeAll = (functions = []) => {
	functions.forEach(fn => {
		if (isFunction(fn)) {
			fn();
		} else {
			logError(`${fn.name} is not a function.`);
		}
	});
};

export const executeIfTruthy = (check, doIt) => (check ? doIt : '');
