import classNames from 'classnames';
import { bool } from 'prop-types';
import React from 'react';
import './Overlay.scss';

export const Overlay = ({ open, ...rest }) => <div className={classNames('Overlay', { 'Overlay--open': open })} {...rest} />;

Overlay.propTypes = {
	open: bool.isRequired,
};
