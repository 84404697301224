/* eslint-disable max-len */
import { useCallback, useEffect } from 'react';
import { postImage } from 'services/imageService';
import { generateFoldersByPath } from 'utils/contentFragmentUtil';
import { showToast } from 'utils/toastUtil';
import { translations } from 'translation/en';
import { PARAGRAPH_PROPS } from 'constants/paragraphConstants';
import useBrandDetails from './useBrandDetails';
import { useRequest } from './useRequest';
import useApprovePublish from './useApprovePublish';
import useBrandData from './useBrandData';
import { useDisapprovePage } from './useDisapprovePage';

export const useCreateImage = () => {
	const { brand } = useBrandData();
	const { currentType } = useBrandDetails();
	const { loading: loadingCreate, data: response, error, run, clear } = useRequest(postImage);
	const { isPageApproved, handleStaleStatus } = useApprovePublish();
	const { isLoadingDisapprovePage, disapprovePage } = useDisapprovePage();

	useEffect(() => {
		if (response) {
			clear();
		}
	}, [response, clear]);

	useEffect(() => {
		if (error) {
			clear();
			showToast({ type: 'error', message: translations.FAILED_CREATE_IMAGE });
		}
	}, [error, clear]);

	const createImage = useCallback(
		({ paragraphData, imageData }) => {
			const parentPath = generateFoldersByPath({
				basePath: brand?.parentPath,
				chapterPath: currentType?.chapterPath,
				sectionPath: currentType?.sectionPath,
				articlePath: currentType?.articlePath,
				paragraphPath: paragraphData?.path,
				isImage: true,
			});

			const payload = {
				parentPath,
				imageContent: imageData?.orgFile,
				...imageData,
			};

			const createImagePromise = run({
				pagePath: currentType?.path,
				cfType: PARAGRAPH_PROPS.RELATOR_IMAGE_KEY,
				data: payload,
			});

			let disapprovePagePromise = Promise.resolve();
			if (isPageApproved) {
				disapprovePagePromise = disapprovePage([currentType]);
			}

			return Promise.all([createImagePromise, disapprovePagePromise]).then(responses => {
				const isSuccess = responses.some(res => res?.data);

				if (isSuccess) {
					handleStaleStatus(true);
				}

				return responses;
			});
		},
		[brand?.parentPath, currentType, isPageApproved, handleStaleStatus, disapprovePage, run]
	);

	return { loadingCreate: [loadingCreate, isLoadingDisapprovePage].some(Boolean), response, error, createImage };
};
