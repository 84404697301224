/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react';

export const usePrevious = value => {
	const ref = useRef();

	useEffect(() => {
		ref.current = value;
	}, []); // Do not add dependency!!!

	const setCurrent = newValue => {
		ref.current = newValue;
	};

	return { previousRef: ref.current, setCurrent };
};
