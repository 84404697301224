import { useRef, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { EditNavigation } from 'components/editNavigation';
import useBrandDetails from 'hooks/useBrandDetails';
import useBrandRoute from 'hooks/useBrandRoute';
import { hasLength } from 'utils/arrayUtil';
import { PAGE_TYPES } from 'constants/pageConstants';
import { SectionItem } from './SectionItem/SectionItem';
import { BrandSideBarArticles } from '../../brandSideBarArticles';
import * as S from './BrandSideBarSection.style';

const BrandSideBarSection = ({
	vcmId,
	title,
	path,
	articles,
	isExpanded,
	isDisableEdit,
	isOpen,
	onCloseMenu,
	onMenuClick,
	onIconClick,
}) => {
	const history = useHistory();
	const { currentType } = useBrandDetails();
	const { getBrandRoute } = useBrandRoute();

	const parentRef = useRef(null);
	const [isAddArticle, setIsAddArticle] = useState();

	const isItemActivated = currentType?.path === path;
	const isParentExpanded = isExpanded;
	const isEditNavigationEnabled = isItemActivated && isParentExpanded && !isDisableEdit;

	const hasArticles = hasLength(articles);
	const arrowClassNames = hasArticles && classNames('icon icon-chevron-down');

	const pathTo = useMemo(() => getBrandRoute(path), [getBrandRoute, path]);

	const handleClick = e => {
		e.preventDefault();
		setTimeout(() => {
			onMenuClick(vcmId);
		});
		if (!hasArticles) {
			onCloseMenu();
		}
		history.push(pathTo);
	};

	const handleArrowClick = e => {
		e.preventDefault();
		onIconClick(vcmId);
	};

	const handleAdd = isAddChild => {
		setIsAddArticle(isAddChild);
	};

	return (
		<S.BrandSideBarSection ref={parentRef} data-testid={PAGE_TYPES.SECTION_TEST_ID}>
			<EditNavigation.Menu show={isEditNavigationEnabled} parentRef={parentRef} value={title} onAdd={handleAdd}>
				<SectionItem
					to={pathTo}
					icon={{
						name: arrowClassNames,
						style: {
							transform: isOpen ? 'rotate(180deg)' : 'none',
							transition: 'all .3s ease',
						},
					}}
					$hasArticles={hasArticles}
					onClick={handleClick}
					onArrowClick={handleArrowClick}
				>
					{title}
				</SectionItem>

				<S.Body isExpanded={isOpen && hasArticles}>
					<BrandSideBarArticles
						isExpanded={isParentExpanded}
						articles={articles}
						onCloseMenu={onCloseMenu}
						onMenuClick={onMenuClick}
					/>
				</S.Body>

				{isAddArticle && <EditNavigation.Input title={title} type={PAGE_TYPES.ARTICLE_TYPE} />}
			</EditNavigation.Menu>
		</S.BrandSideBarSection>
	);
};

BrandSideBarSection.propTypes = {
	vcmId: PropTypes.string,
	title: PropTypes.string,
	path: PropTypes.string,
	articles: PropTypes.array,
	isExpanded: PropTypes.bool,
	isOpen: PropTypes.bool,
	isDisableEdit: PropTypes.bool,
	onCloseMenu: PropTypes.func,
	onMenuClick: PropTypes.func,
	onIconClick: PropTypes.func,
};

BrandSideBarSection.defaultProps = {
	vcmId: null,
	title: '',
	path: '',
	articles: [],
	isExpanded: false,
	isOpen: false,
	isDisableEdit: false,
	onCloseMenu: () => {},
	onMenuClick: () => {},
	onIconClick: () => {},
};

export { BrandSideBarSection };
