export const COLORS = [
	'#0085ce',
	'#0D3277',
	'#4B6EAF',
	'#439D69',
	'#458BA1',
	'#323D76',
	'#4DB3A0',
	'#70C285',
	'#00C49F',
	'#CED397',
	'#AB5649',
	'#B3724D',
	'#B3AA4D',
	'#3A6B2E',
	'#FF8042',
	'#FFBB28',
	'#43238c',
	'#a17693',
	'#2673fe',
	'#d01b13',
	'#a644d9',
	'#4f6136',
	'#6678c5',
	'#3a9d06',
	'#b5f749',
	'#aee2de',
	'#2274f0',
	'#cc71a8',
	'#477faa',
	'#aa5245',
	'#af62eb',
	'#f96b20',
	'#57bd77',
	'#48e43d',
	'#33d079',
	'#cc29b7',
	'#fd961d',
	'#e78ff7',
	'#92aed9',
	'#785d17',
	'#1b2996',
	'#ae5f48',
	'#466bbf',
	'#c33fed',
	'#551ccf',
	'#987454',
	'#256a40',
	'#6b2733',
	'#994ef7',
	'#cbc076',
];
