import PropTypes from 'prop-types';
import React from 'react';
import * as S from './SimpleModal.style';

const SimpleModal = ({ children, containerStyle, hideExpand, ...props }) => {
	return (
		<S.Modal hideExpand={hideExpand} {...props}>
			<S.Container style={containerStyle}>{children}</S.Container>
		</S.Modal>
	);
};

SimpleModal.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	onClose: PropTypes.func,
	hideExpand: PropTypes.bool,
	containerStyle: PropTypes.objectOf(Object),
};

SimpleModal.defaultProps = {
	children: null,
	hideExpand: true,
	containerStyle: null,
	onClose: () => null,
};

const Header = ({ children, variant, style }) => {
	const renderVariantHeader = () => {
		let titleComponent;

		switch (variant) {
			case 'h1':
				titleComponent = children && <S.Heading1>{children}</S.Heading1>;
				break;
			case 'h2':
				titleComponent = children && <S.Heading2>{children}</S.Heading2>;
				break;
			case 'h4':
				titleComponent = children && <S.Heading4>{children}</S.Heading4>;
				break;
			default:
				break;
		}

		return titleComponent;
	};

	return <S.Header style={style}>{renderVariantHeader()}</S.Header>;
};

Header.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	onClose: PropTypes.func,
	variant: PropTypes.oneOf(['h1', 'h2', 'h4']),
	style: PropTypes.objectOf(Object),
};

Header.defaultProps = {
	children: '',
	variant: 'h1',
	onClose: () => null,
	style: null,
};

const Main = ({ children, style }) => children && <S.Main style={style}>{children}</S.Main>;

Main.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	style: PropTypes.objectOf(Object),
};

Main.defaultProps = {
	children: null,
	style: null,
};

const Footer = ({ children, style }) => children && <S.Footer style={style}>{children}</S.Footer>;

Footer.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	style: PropTypes.objectOf(Object),
};

Footer.defaultProps = {
	children: null,
	style: null,
};

SimpleModal.Header = Header;
SimpleModal.Main = Main;
SimpleModal.Footer = Footer;

export { SimpleModal };
