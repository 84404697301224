import axios from 'API/axios/axios';
import { sqlApi } from 'constants/serviceConstants';
import { getBegCfByPath } from 'constants/sqlResources';
import { PAGE_TYPES } from 'constants/pageConstants';

export const checkPageExistenceService = (brandId, pathToCheck) => {
	const url = `${process.env.REACT_APP_API_URL}${sqlApi}/${getBegCfByPath}`;

	return axios.get(url, {
		params: {
			pagePath: `beg-${brandId}`,
			cfType: PAGE_TYPES.PAGE_TYPE,
			parameter: `;_path=${pathToCheck}`,
		},
	});
};

export const checkPageExistenceByResult = pageExistenceResult => {
	return pageExistenceResult?.data?.data?.total > 0;
};
