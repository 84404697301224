import { useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import { Logo } from 'components/logo';
import useToggle from 'hooks/useToggle';
import { useMenuEvents } from 'hooks/useMenuEvents';
import { useOnOutsideClick } from 'hooks/useOnOutsideClick';
import { BrandMenu } from '../brandMenu';
import { BrandModal } from '../brandModal';
import { BrandSideBarChapters } from '../brandSideBar/brandSideBarChapters';
import { DropDown } from '../dropdown';
import { HelpButton } from '../helpButton';
import { MenuButton } from '../menuButton';
import { SearchBar } from '../searchBar';
import * as S from './BrandSideBarMobile.style';

const BrandSideBarMobile = () => {
	const { logo } = useTheme();
	const { chaptersWithIsOpen, onMenuClick, onIconClick } = useMenuEvents();
	const [isOpenModal, setIsOpenModal] = useToggle();
	const [isOpenMenu, setIsOpenMenu] = useState(false);

	const menuMobileRef = useRef(null);
	const sideBarRef = useRef(null);
	const searchDropDownRef = useRef();

	const handleToggleMenu = e => {
		e.preventDefault();
		setIsOpenMenu(prevState => !prevState);
	};

	const handleCloseMenu = () => {
		setIsOpenMenu(false);
	};

	useOnOutsideClick({ ref: [menuMobileRef, sideBarRef], action: handleCloseMenu });

	return (
		<>
			<S.NavBar ref={menuMobileRef}>
				<MenuButton onClick={handleToggleMenu} />
				<S.Section>
					<Logo image={logo.mobile} size={logo.size.navbar} onClick={setIsOpenModal} />
				</S.Section>

				<S.Actions>
					<DropDown icon="icon-search" iconOutlined closeOnOutsideClick ref={searchDropDownRef}>
						<DropDown.DropDownItem>
							<SearchBar onClose={() => searchDropDownRef.current.handleClose()} />
						</DropDown.DropDownItem>
					</DropDown>
					<HelpButton />
				</S.Actions>
			</S.NavBar>

			<BrandModal open={isOpenModal} onClose={setIsOpenModal} />

			<BrandMenu ref={sideBarRef} open={isOpenMenu}>
				<BrandSideBarChapters
					chapters={chaptersWithIsOpen}
					onCloseMenu={handleCloseMenu}
					onMenuClick={onMenuClick}
					onIconClick={onIconClick}
				/>
			</BrandMenu>
		</>
	);
};

export { BrandSideBarMobile };
