import { func, string } from 'prop-types';
import React from 'react';
import './LabelChart.scss';

export const LabelChart = ({ label, color, onClick }) => {
	return (
		<button className="LabelChart" onClick={onClick}>
			<span style={{ background: color }} />
			<p>{label}</p>
		</button>
	);
};

LabelChart.propTypes = {
	label: string.isRequired,
	color: string.isRequired,
	onClick: func.isRequired,
};
