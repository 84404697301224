import PropTypes from 'prop-types';
import React from 'react';
import * as S from './ChapterItem.style';
import { Icon } from './icon';

const ChapterItem = ({ children, icon, onClick, onArrowClick, ...props }) => {
	const handleClick = e => {
		e.preventDefault();
		e.stopPropagation();
		onClick?.(e);
	};

	const handleArrowClick = e => {
		e.preventDefault();
		e.stopPropagation();
		onArrowClick?.(e);
	};

	return (
		<S.Link onClick={e => handleClick(e)} {...props}>
			<S.Wrapper>
				<S.Label>{children}</S.Label>
				{icon && <Icon icon={icon} onClick={handleArrowClick} />}
			</S.Wrapper>
		</S.Link>
	);
};

ChapterItem.propTypes = {
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	icon: PropTypes.shape({
		style: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		name: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
	}),
	onClick: PropTypes.func,
	onArrowClick: PropTypes.func,
};

ChapterItem.defaultProps = {
	children: '',
	icon: {
		style: '',
		name: '',
	},
	onClick: () => null,
	onArrowClick: () => null,
};

export { ChapterItem };
