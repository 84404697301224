import { add, endOfDay, endOfMonth, endOfYear, startOfMonth, startOfYear } from 'date-fns';

const today = {
	text: 'Today',
	startDate: new Date(),
	endDate: new Date(),
};

export const last30Days = {
	text: 'Last 30 days',
	startDate: add(endOfDay(new Date()), { days: -30 }),
	endDate: new Date(),
};

const lastMonth = {
	text: 'Last month',
	startDate: add(startOfMonth(new Date()), { months: -1 }),
	endDate: add(endOfMonth(new Date()), { months: -1 }),
};

const last90Days = {
	text: 'Last 90 days',
	startDate: add(endOfDay(new Date()), { days: -90 }),
	endDate: new Date(),
};

const lastYear = {
	text: 'Last year',
	startDate: add(startOfYear(new Date()), { years: -1 }),
	endDate: add(endOfYear(new Date()), { years: -1 }),
};

export const listOptions = [today, last30Days, lastMonth, last90Days, lastYear];
