import ThompsonBlackLogo from 'assets/img/logos/thompson-black.svg';
import ThompsonLogo from 'assets/img/logos/thompson-logo.svg';
import ThompsonWhiteLogo from 'assets/img/logos/thompson-white.svg';
import complementaryTheme from 'themes/complementary/complementary';
import colors from './thompsonColors';
import typographyTheme from './thompsonTypo';

const logo = {
	footer: ThompsonBlackLogo, // logo in the footer
	loading: ThompsonBlackLogo, // logo in the loading
	sidebar: ThompsonLogo, // logo in the sidebar
	mobile: ThompsonWhiteLogo, // logo in the navbar
	size: {
		sidebar: {
			width: '188px',
			height: '91px',
		},
		footer: {
			width: '163px',
			height: '81px',
		},
		navbar: {
			width: '168px',
			height: '91px',
		},
		loading: {
			width: '168px',
			height: '91px',
		},
	},
};

const copyrightText = {
	padding: '5px 0 5px 18px',
};

const thompson = {
	brandId: 'th',
	name: 'Thompson',
	logo,
	color: colors,
	copyrightText,

	font: {
		family: {
			helveticaNeue: 'Helvetica Neue',
			arialSansSerif: 'Arial, Helvetica, sans-serif',
			notoSansMonoMedium: 'NotoSansMono Medium',
			notoSansMonoRegular: 'NotoSansMono Regular',
			notoSansMonoSemiBold: 'NotoSansMono SemiBold',
			robotoMonoRegular: 'RobotoMono Regular',
		},
	},
};

const thompsonTheme = {
	...thompson,
	...typographyTheme(thompson),
	...complementaryTheme,
};

export default thompsonTheme;
