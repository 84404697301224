import PropTypes from 'prop-types';
import React from 'react';
import * as S from './Button.style';
import { Icon } from './icon';
import { Label } from './label';

const Button = ({ variant, children, icon, onClick, ...props }) => {
	const handleClick = e => {
		e.preventDefault();
		onClick(e);
	};

	const renderLabelComponent = () => children && <Label variant={variant}>{children}</Label>;

	const renderIconComponent = () => icon && <Icon icon={icon} />;

	return (
		<S.Button variant={variant} onClick={e => onClick && handleClick(e)} {...props}>
			{renderLabelComponent()}
			{renderIconComponent()}
		</S.Button>
	);
};

Button.propTypes = {
	variant: PropTypes.oneOf([
		'primary',
		'primary-02',
		'primary-03',
		'primary-04',
		'primary-05',
		'secondary',
		'secondary-02',
		'secondary-03',
		'tertiary',
		'tertiary-02',
		'tertiary-03',
		'outlined',
		'gray-border',
		'gray-border-secondary',
		'gray-border-only',
		'dark-gray',
	]),
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	icon: PropTypes.string,
	onClick: PropTypes.func,
};

Button.defaultProps = {
	variant: 'primary',
	children: '',
	icon: '',
	onClick: () => null,
};

export { Button };
