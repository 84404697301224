import styled from 'styled-components/macro';

export const Wrapper = styled.div(({ theme: { breakpoint } }) => ({
	'& .WrapperEditableContent': {
		display: 'flex',
		flexDirection: 'column-reverse',

		[breakpoint.md()]: {
			display: 'block',
		},

		'@media print': {
			display: 'flex',
			flexDirection: 'column-reverse',
		},
	},
}));

export const Box = styled.div(({ theme: { breakpoint } }) => ({
	width: 'auto',

	[breakpoint.md()]: {
		float: 'right',
		paddingLeft: '10px',
		width: '65%',
	},

	'@media print': {
		float: 'none',
		width: '100%',
		paddingLeft: '0',
	},
}));

export const Gallery = styled.div(() => ({
	display: 'grid',
	gridTemplateColumns: 'repeat(auto-fit, minmax(105px, 1fr))',
	gap: '30px',
	justifyContent: 'space-between',

	'@media print': {
		gridTemplateColumns: 'repeat(6, minmax(80px, 1fr))',
	},
}));

export const SpecificationOnPrintPage = styled.div(() => ({
	display: 'flex',
	flexWrap: 'wrap',
}));
