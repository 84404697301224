// TODO: This file is a mess, refactor it, remove warning disabler

import { translations } from 'translation/en';
import { capitalizeTheContent } from './htmlUtils';

/* eslint-disable no-param-reassign */
export const handleModuleStylesByType = moduleType => {
	switch (moduleType) {
		case '2-COL-A':
			return {
				display: 'flex',
				flexDirection: 'column-reverse',

				align: 'left',
			};
		case '2-COL-B':
			return {
				display: 'flex',
				flexDirection: 'column-reverse',

				align: 'left',
			};
		case '2-COL-C':
			return {
				display: 'flex',
				flexDirection: 'column-reverse',
				align: 'left',
			};
		case '2-COL-F':
			return {
				display: 'flex',
				flexDirection: 'column-reverse',
				align: 'left',
			};
		case '2-COL-G':
			return {
				display: 'flex',
				flexDirection: 'column-reverse',
				align: 'left',
			};
		case '2-COL-H':
			return {
				display: 'flex',
				flexDirection: 'column-reverse',
				align: 'left',
			};
		case '2-COL-I':
			return {
				display: 'flex',
				flexDirection: 'column-reverse',
				align: 'left',
			};
		case '2-COL-J':
			return {
				display: 'flex',
				flexDirection: 'column-reverse',
				align: 'left',
			};
		case 'FULL-K':
			return {
				display: 'flex',
				flexDirection: 'column-reverse',
				align: 'left',
			};
		case 'FULL-L':
			return {
				display: 'flex',
				flexDirection: 'column-reverse',
				align: 'left',
			};
		case 'FULL-M':
			return {
				align: 'center',
			};
		case 'TYPE-3-IMAGES':
			return {
				align: 'center',
			};
		case 'TYPE-4-VARIABLE-IMAGES':
			return {
				display: 'flex',
				flexDirection: 'column-reverse',
				align: 'left',
			};
		case 'TABLE':
			return {
				display: 'flex',
				flexDirection: 'column-reverse',
				align: 'left',
			};
		case 'TEXT':
			return {
				align: 'center',
			};
		case 'QUICKSHEET':
			return {
				display: 'flex',
				flexDirection: 'column-reverse',
				align: 'left',
			};
		default:
			return null; // or handle the default case as per your requirement
	}
};

export const handleAddText = (pres, content, moduleType) => {
	const { body, title, specification } = content;

	const slide = pres.addSlide('THEMED_SLIDE');
	slide.title = title;

	slide.addText(body, handleModuleStylesByType(moduleType));
	slide.addText(specification, handleModuleStylesByType(moduleType));
};

const getNodeTypeConfigurations = (styles, nodeName) => {
	if (nodeName === 'P') {
		return {
			color: styles.color.text.paragraph,
			fontSize: 10,
			fontFamily: styles.typo.body['02'].fontFamily,
			lineBlockHeight: 0.13,
			bullet: false,
		};
	}
	if (nodeName === 'SPAN') {
		return {
			color: styles.color.text.paragraph,
			fontSize: 10,
			fontFamily: styles.typo.body['02'].fontFamily,
			lineBlockHeight: 0.13,
			bullet: false,
		};
	}
	if (nodeName === 'LI') {
		return {
			color: styles.color.text.paragraph,
			fontSize: 10,
			fontFamily: styles.typo.body['07'].fontFamily,
			lineBlockHeight: 0.16,
			bullet: false,
			marginBottom: styles.typo.body['07'].marginBottom,
		};
	}
	if (nodeName === 'H1') {
		return {
			color: styles.color.text.headline01,
			fontSize: 30,
			fontFamily: styles.typo.headline['01.2'].fontFamily,
			lineBlockHeight: 0.5,
			bullet: false,
		};
	}
	if (nodeName === 'H2') {
		return {
			color: styles.color.text.headline02,
			fontSize: 19,
			fontFamily: styles.typo.headline['02.1'].fontFamily,
			lineBlockHeight: 0.25,
			bullet: false,
		};
	}
	if (nodeName === 'H3') {
		return {
			color: styles.color.text.headline03,
			fontSize: 16,
			fontFamily: styles.typo.headline['03'].fontFamily,
			lineBlockHeight: 0.2,
			bullet: false,
		};
	}
	if (nodeName === 'H4') {
		return {
			color: styles.color.text.headline04,
			fontSize: 14,
			fontFamily: styles.typo.headline['04'].fontFamily,
			lineBlockHeight: 0.2,
			bullet: false,
		};
	}
	if (nodeName === 'H5') {
		return {
			color: styles.color.text.headline05,
			fontSize: 14,
			fontFamily: styles.typo.headline['05'].fontFamily,
			lineBlockHeight: 0.2,
			bullet: false,
		};
	}
	if (nodeName === 'H6') {
		return {
			color: styles.color.text.headline06,
			fontSize: 16,
			fontFamily: styles.typo.headline['06'].fontFamily,
			lineBlockHeight: 0.2,
			bullet: false,
		};
	}

	if (nodeName === 'A') {
		return {
			color: '#337ab7',
			fontSize: 10,
			fontFamily: styles.typo.body['02'].fontFamily,
			lineBlockHeight: 0.13,
			bullet: false,
		};
	}

	return {
		color: styles.color.text.headline05,
		fontSize: 14,
		fontFamily: styles.typo.body['02'].fontFamily,
		lineBlockHeight: 0.13,
		bullet: false,
	};
};

export const addSlideTitles = (
	paragraphSlides,
	chapterTitle,
	sectionTitle,
	articleTitle,
	paragraphTitle,
	styles,
	slideMeasurements
) => {
	const { typo, color } = styles;
	let titleHeight = 0.1;
	let textHeight = 0;
	const slide = paragraphSlides[paragraphSlides.length - 1];

	if (chapterTitle != null && paragraphSlides.length === 1) {
		textHeight = 0.25 * (1 + Math.floor(chapterTitle.length / 82));
		if (chapterTitle) {
			slide.addText(chapterTitle.toUpperCase(), {
				y: titleHeight,
				x: slideMeasurements.borderSize,
				h: textHeight,
				w: slideMeasurements.maxTextWidth,
				color: color.text.headline01,
				fontFamily: typo.headline['01'].fontFamily,
				textTransform: typo.headline['01'].textTransform,
			});
			titleHeight += textHeight + 0.03;
		}
		if (sectionTitle != null) {
			textHeight = 0.25 * (1 + Math.floor(sectionTitle.length / 82));
			slide.addText(sectionTitle.toUpperCase(), {
				y: titleHeight,
				x: slideMeasurements.borderSize,
				h: textHeight,
				w: slideMeasurements.maxTextWidth,
				color: color.text.headline01,
				fontFamily: typo.headline['01'].fontFamily,
				textTransform: typo.headline['01'].textTransform,
			});
			titleHeight += textHeight + 0.03;
		}

		if (articleTitle != null) {
			textHeight = 0.25 * (1 + Math.floor(articleTitle.length / 82));
			slide.addText(articleTitle.toUpperCase(), {
				y: titleHeight,
				x: slideMeasurements.borderSize,
				h: textHeight,
				w: slideMeasurements.maxTextWidth,
				color: color.text.headline01,
				fontFamily: typo.headline['01'].fontFamily,
				textTransform: typo.headline['01'].textTransform,
			});
			titleHeight += textHeight + 0.03;
		}
	}

	if (paragraphTitle) {
		textHeight = 0.25 * (1 + Math.floor(paragraphTitle.length / 82));
		const doc = new DOMParser().parseFromString(paragraphTitle, 'text/html');
		const htmlBody = doc.body;
		slide.addText(htmlBody.innerText, {
			y: titleHeight,
			x: slideMeasurements.borderSize,
			h: textHeight,
			w: slideMeasurements.maxTextWidth,
			color: color.text.headline02,
			fontFamily: typo.headline['01.1'].fontFamily,
			textTransform: typo.headline['01.1'].textTransform,
		});
		titleHeight += textHeight + 0.03;
	}

	return titleHeight;
};

export const createTable = (nodeTable, slide, initialHeight) => {
	for (let i = 0; i < nodeTable.childNodes.length; i++) {
		const node = nodeTable.childNodes[i];
		if (node && node.nodeName === 'TBODY') {
			const table = [];

			for (let j = 0; j < node.childNodes.length; j++) {
				if (node.childNodes[i].nodeName === 'TR') {
					const line = [];
					for (let k = 0; k < node.childNodes[i].childNodes.length; k++) {
						if (node.childNodes[i].childNodes[k].nodeName === 'TD') {
							line.push(node.childNodes[i].childNodes[k].outerText);
						}
					}
					table.push(line);
				}
			}

			slide.addTable(table, { y: initialHeight, w: '80%' });
		}
	}
};

export const transverseDOM = (
	node,
	currentTextHeight,
	slideMeasurements,
	paragraphSlides,
	maxLength,
	maxTextWidth,
	pptx,
	styles
) => {
	let currentTextWidth = slideMeasurements.borderSize;
	let currentSlide = paragraphSlides[paragraphSlides.length - 1];
	if (node.nodeName === 'TABLE') {
		createTable(node, currentSlide, currentTextHeight);
	} else if (
		node.nodeName === 'P' ||
		node.nodeName === 'LI' ||
		node.nodeName === 'H1' ||
		node.nodeName === 'H2' ||
		node.nodeName === 'H3' ||
		node.nodeName === 'H4' ||
		node.nodeName === 'H5' ||
		node.nodeName === 'H6'
	) {
		const configurations = getNodeTypeConfigurations(styles, node.nodeName);

		let textToInsert = [];
		let textLength = 0;
		for (let i = 0; i < node.childNodes.length; i++) {
			if (node.childNodes[i].nodeType === Node.TEXT_NODE) {
				if (node.childNodes[i].nodeValue) {
					const text = node.nodeName === 'LI' ? node.childNodes[i].nodeValue.trim() : node.childNodes[i].nodeValue;
					const textPart = {
						text,
						options: {
							fontFamily: configurations.fontFamily,
							color: configurations.color,
							lineBlockHeight: configurations.lineBlockHeight,
							fontSize: configurations.fontSize,
						},
					};
					textToInsert.push(textPart);
					textLength += node.childNodes[i].nodeValue.trim().length;
				}
			} else if (node.childNodes[i].nodeName === 'A') {
				if (node.childNodes[i].innerText) {
					const innerContentConfig = getNodeTypeConfigurations(styles, node.childNodes[i].nodeName);
					const textPart = {
						text: node.childNodes[i].innerText,
						options: {
							fontFamily: innerContentConfig.fontFamily,
							color: innerContentConfig.color,
							lineBlockHeight: innerContentConfig.lineBlockHeight,
							fontSize: innerContentConfig.fontSize,
							textDecoration: 'none',
							hyperlink: {
								url: node.childNodes[i].href,
							},
						},
					};
					node.childNodes[i].setAttribute('href', node.childNodes[i].href);

					textToInsert.push(textPart);
					textLength += node.childNodes[i].innerText.length;
				}
			} else if (node.childNodes[i].nodeName === 'EM') {
				if (node.childNodes[i].innerText) {
					const textPart = {
						text: node.childNodes[i].innerText,
						options: {
							fontFamily: configurations.fontFamily,
							color: configurations.color,
							lineBlockHeight: configurations.lineBlockHeight,
							fontSize: configurations.fontSize,
							italic: true,
						},
					};

					node.childNodes[i].setAttribute('href', node.childNodes[i].href);

					textToInsert.push(textPart);
					textLength += node.childNodes[i].innerText.length;
				}
			} else if (node.childNodes[i].nodeName === 'DIV') {
				if (node.childNodes[i].innerText) {
					const textPart = {
						text: node.childNodes[i].innerText,
						options: {
							fontFamily: configurations.fontFamily,
							color: configurations.color,
							lineBlockHeight: configurations.lineBlockHeight,
							fontSize: configurations.fontSize,
						},
					};

					textToInsert.push(textPart);
					textLength += node.childNodes[i].innerText.length;
				}
			} else if (node.childNodes[i].nodeName === 'SPAN') {
				if (node.childNodes[i].innerText) {
					const textPart = {
						text: node.childNodes[i].innerText,
						options: {
							fontFamily: configurations.fontFamily,
							color: configurations.color,
							lineBlockHeight: configurations.lineBlockHeight,
							fontSize: configurations.fontSize,
						},
					};

					textToInsert.push(textPart);
					textLength += node.childNodes[i].innerText.length;
				}
			} else if (node.childNodes[i].nodeName === 'U') {
				if (node.childNodes[i].innerText) {
					const textPart = {
						text: node.childNodes[i].innerText,
						options: {
							fontFamily: configurations.fontFamily,
							color: configurations.color,
							lineBlockHeight: configurations.lineBlockHeight,
							fontSize: configurations.fontSize,
						},
					};

					textToInsert.push(textPart);
					textLength += node.childNodes[i].innerText.length;
				}
			} else if (node.childNodes[i].nodeName === 'STRONG') {
				if (node.childNodes[i].textContent) {
					const textPart = {
						text: node.childNodes[i].textContent.trim(),
						options: {
							fontFamily: configurations.fontFamily,
							color: configurations.color,
							lineBlockHeight: configurations.lineBlockHeight,
							fontSize: configurations.fontSize,
							bold: true,
						},
					};

					textToInsert.push(textPart);
					textLength += node.childNodes[i].textContent.trim().length;
				}
			} else if (node.childNodes[i].nodeName === 'P') {
				const c = transverseDOM(
					node.childNodes[i],
					currentTextHeight,
					slideMeasurements,
					paragraphSlides,
					maxLength,
					maxTextWidth,
					pptx,
					styles
				);
				currentTextHeight = c.currentTextHeight;
				currentSlide = c.slide;
			} else if (node.childNodes[i].nodeName === 'UL') {
				const textHeight = configurations.lineBlockHeight * (1 + Math.floor(textLength / maxLength));

				if (currentTextHeight + textHeight > slideMeasurements.maxTextHeight) {
					currentSlide = pptx.addSlide();
					paragraphSlides.push(currentSlide);
					currentTextHeight = 0.1;
				}

				currentSlide.addText(textToInsert, {
					y: currentTextHeight,
					x: currentTextWidth,
					w: maxTextWidth,
					h: textHeight,
					bullet: configurations.bullet,
				});
				currentTextHeight += textHeight;

				textToInsert = [];
				const c = transverseDOM(
					node.childNodes[i],
					currentTextHeight,
					slideMeasurements,
					paragraphSlides,
					maxLength,
					maxTextWidth,
					pptx,
					styles
				);
				currentTextHeight = c.currentTextHeight;
				currentSlide = c.slide;
			}
		}

		if (textToInsert.length > 0) {
			const textHeight = configurations.lineBlockHeight * (1 + Math.floor(textLength / maxLength));

			if (currentTextHeight + textHeight > slideMeasurements.maxTextHeight) {
				currentSlide = pptx.addSlide();
				paragraphSlides.push(currentSlide);
				currentTextHeight = 0.1;
			}
			currentSlide.addText(textToInsert, {
				y: currentTextHeight,
				x: currentTextWidth,
				w: maxTextWidth,
				h: textHeight,
				bullet: configurations.bullet,
			});

			currentTextHeight += textHeight;
		}
	} else if (node.childNodes.length > 0) {
		if (node.nodeName === 'UL') {
			currentTextWidth += 0.1;
		}
		for (let j = 0; j < node.childNodes.length; j++) {
			const c = transverseDOM(
				node.childNodes[j],
				currentTextHeight,
				slideMeasurements,
				paragraphSlides,
				maxLength,
				maxTextWidth,
				pptx,
				styles
			);
			currentTextHeight = c.currentTextHeight;
			currentSlide = c.slide;
		}
	}

	return { currentTextHeight, slide: currentSlide };
};

export const imageToBase64 = image => {
	const canvas = document.createElement('canvas');
	const ctx = canvas.getContext('2d');
	canvas.width = image.width;
	canvas.height = image.height;
	ctx.drawImage(image, 0, 0);
	const base64String = canvas.toDataURL();
	return base64String;
};

export const insertImage = (slide, paragraphImage, image, xValue, yValue, maxW, maxH) => {
	// 1 inch = 96 pixels   //1pixel = 0.0104166667 in
	const inch2pixel = 96;
	const pixel2int = 0.0104166667;
	if (paragraphImage.imageContentPath && image && image.height && image.width) {
		const maxHPixels = inch2pixel * maxH;
		const maxWPixels = inch2pixel * maxW;

		if (maxHPixels >= image.height && maxWPixels >= image.width) {
			maxH = image.height * pixel2int;
			maxW = image.width * pixel2int;
		} else if (maxHPixels < image.height && maxWPixels >= image.width) {
			const newWidth = (maxHPixels * image.width) / image.height;
			maxW = newWidth * pixel2int;
		} else if (maxHPixels >= image.height && maxWPixels < image.width) {
			const newHeight = (maxWPixels * image.height) / image.width;
			maxH = newHeight * pixel2int;
		} else {
			let newHeight = (maxWPixels * image.height) / image.width;
			let newWidth = maxWPixels;

			if (newHeight > maxHPixels) {
				newWidth = (maxHPixels * newWidth) / newHeight;
				newHeight = maxHPixels;
			}
			maxH = newHeight * pixel2int;
			maxW = newWidth * pixel2int;
		}

		slide.addImage({
			x: xValue,
			y: yValue,
			w: maxW,
			h: maxH,
			path: image.src,
			objectName: 'image',
		});
		if (paragraphImage.imageCaption) {
			slide.addText(paragraphImage.imageCaption, { x: xValue, y: yValue + maxH + 0.05, w: maxW, fontSize: 8 });
		}
	}
};

export const getParagraphsDomImgs = paragraph => {
	const paragraphGallery = document.getElementById(`${paragraph.path}-gallery`);
	const imgElements = paragraphGallery?.getElementsByTagName('img');
	return imgElements;
};

export const addSlideImages = async (pptx, paragraph, titleHeight, currentTextHeight, paragraphSlides, slideMeasurements) => {
	let xValue = slideMeasurements.borderSize;
	let currentSlidePosition = 0;
	const images = paragraph.imageData ? getParagraphsDomImgs(paragraph) : [];
	const imageSpace = (slideMeasurements.height - slideMeasurements.borderSize - titleHeight).toFixed(2);
	const imagesDivider = 0.1;
	let secondLineHeightStart = (titleHeight + parseFloat(imageSpace)) / (2 + imagesDivider);

	if (paragraph.body && paragraph.type !== 'FULL-M' && paragraph.type !== 'FULL-L' && paragraph.type !== 'FULL-K') {
		xValue = 4.75;
	}

	if (paragraph.imageData && images) {
		if (paragraph.type === '2-COL-A') {
			let yValue = titleHeight;
			let xGallery = xValue;
			for (let i = 0; i < paragraph.imageData.length; i++) {
				images[i].h = 105;
				images[i].w = 105;
				insertImage(paragraphSlides[currentSlidePosition], paragraph.imageData[i], images[i], xGallery, yValue, 1, 1);
				xGallery += 1.1;

				if ((i + 1) % 4 === 0) {
					yValue += 1.2;
					xGallery = xValue;
				}
				if ((i + 1) % 16 === 0) {
					yValue = slideMeasurements.borderSize;
					currentSlidePosition += 1;
					if (currentSlidePosition > paragraphSlides.length - 1) {
						const slide = pptx.addSlide();
						paragraphSlides.push(slide);
					}
				}
			}
		}

		if (paragraph.type === '2-COL-C') {
			if (images[0]) {
				images[0].h = 242;
				images[0].w = 242;
				insertImage(paragraphSlides[0], paragraph.imageData[0], images[0], xValue, titleHeight, 2.5, imageSpace);
			}

			if (images[1]) {
				images[1].h = 242;
				images[1].w = 242;
				insertImage(paragraphSlides[0], paragraph.imageData[1], images[1], xValue + 2.55, titleHeight, 2.5, imageSpace);
			}
		}

		if (paragraph.type === '2-COL-B') {
			if (images[0]) {
				images[0].h = 242;
				images[0].w = 242;
				insertImage(paragraphSlides[0], paragraph.imageData[0], images[0], xValue, titleHeight, 2.5, imageSpace);
			}

			if (images[1]) {
				images[1].h = 242;
				images[1].w = 242;
				insertImage(paragraphSlides[0], paragraph.imageData[1], images[1], xValue + 2.55, titleHeight, 2.5, imageSpace);
			}

			if (images[2]) {
				images[2].h = 242;
				images[2].w = 242;
				insertImage(paragraphSlides[0], paragraph.imageData[2], images[2], xValue, titleHeight + 2, 2.5, imageSpace);
			}

			if (images[3]) {
				images[3].h = 242;
				images[3].w = 242;
				insertImage(
					paragraphSlides[0],
					paragraph.imageData[3],
					images[3],
					xValue + 2.55,
					titleHeight + 2,
					2.5,
					imageSpace
				);
			}
		}

		if (paragraph.type === '2-COL-F') {
			if (images[0]) {
				insertImage(
					paragraphSlides[0],
					paragraph.imageData[0],
					images[0],
					xValue,
					titleHeight - 0.6,
					5.1,
					imageSpace * 0.8
				);
			}

			if (images[1]) {
				insertImage(
					paragraphSlides[0],
					paragraph.imageData[1],
					images[1],
					xValue,
					secondLineHeightStart + 1,
					2.5,
					imageSpace * 0.6
				);
			}

			if (images[2]) {
				insertImage(
					paragraphSlides[0],
					paragraph.imageData[2],
					images[2],
					xValue + 2.6,
					secondLineHeightStart + 1,
					2.5,
					imageSpace * 0.6
				);
			}
		}

		if (paragraph.type === '2-COL-G') {
			if (images[0]) {
				insertImage(paragraphSlides[0], paragraph.imageData[0], images[0], xValue, titleHeight, 2.5, imageSpace / 2);
			}

			if (images[1]) {
				insertImage(
					paragraphSlides[0],
					paragraph.imageData[1],
					images[1],
					xValue + 2.6,
					titleHeight,
					2.5,
					imageSpace / 2
				);
			}

			if (images[2]) {
				insertImage(
					paragraphSlides[0],
					paragraph.imageData[2],
					images[2],
					xValue,
					secondLineHeightStart,
					2.5,
					imageSpace / 2
				);
			}

			if (paragraph.imageData[3]) {
				insertImage(
					paragraphSlides[0],
					paragraph.imageData[3],
					images[3],
					xValue + 2.6,
					secondLineHeightStart,
					2.5,
					imageSpace / 2
				);
			}
		}

		if (paragraph.type === '2-COL-H') {
			for (let i = 0; i < paragraph.imageData.length; i++) {
				insertImage(
					paragraphSlides[currentSlidePosition],
					paragraph.imageData[i],
					images[i],
					xValue + 0.3,
					titleHeight - 0.5 + (i % 2) * 2.4,
					5,
					2.1
				);
				if (i !== 0 && (i + 1) % 2 === 0) {
					currentSlidePosition += 1;
					if (currentSlidePosition > paragraphSlides.length - 1) {
						const slide = pptx.addSlide();
						paragraphSlides.push(slide);
					}
				}
			}
		}

		if (paragraph.type === '2-COL-I' || paragraph.type === '2-COL-J') {
			if (paragraph.imageData[0]) {
				insertImage(paragraphSlides[0], paragraph.imageData[0], images[0], xValue, titleHeight, 5, imageSpace);
			}
		}

		if (paragraph.type === 'FULL-L') {
			let yValue = currentTextHeight;
			let imageHeightSize = 2.5;
			for (let i = 0; i < paragraph.imageData.length; i++) {
				if (slideMeasurements.height - yValue - slideMeasurements.borderSize > 2) {
					imageHeightSize = slideMeasurements.height - yValue - slideMeasurements.borderSize;
				} else {
					yValue = slideMeasurements.borderSize;
					const slide = pptx.addSlide();
					paragraphSlides.push(slide);
				}
				insertImage(
					paragraphSlides[paragraphSlides.length - 1],
					paragraph.imageData[i],
					images[i],
					xValue + 2.1,
					yValue,
					slideMeasurements.width,
					imageHeightSize
				);
				yValue += 3.2;
			}
		}

		if (paragraph.type === 'FULL-M') {
			let yValue = currentTextHeight;
			const imageHeightSize = 2;
			let xGallery = xValue;

			for (let i = 0; i < paragraph.imageData.length; i++) {
				if (!(slideMeasurements.height - yValue - slideMeasurements.borderSize > 2)) {
					yValue = slideMeasurements.borderSize;
					const slide = pptx.addSlide();
					paragraphSlides.push(slide);
				}

				insertImage(
					paragraphSlides[paragraphSlides.length - 1],
					paragraph.imageData[i],
					images[i],
					xGallery,
					yValue,
					3,
					imageHeightSize
				);

				xGallery += 3.1;
				if ((i + 1) % 3 === 0) {
					yValue += 2.2;
					xGallery = xValue;
				}
			}
		}

		if (paragraph.type === 'FULL-K') {
			let yValue = currentTextHeight;
			if (slideMeasurements.height - yValue - slideMeasurements.borderSize < 2) {
				yValue = slideMeasurements.borderSize;
				const slide = pptx.addSlide();
				paragraphSlides.push(slide);
			}

			if (paragraph.imageData[0] || paragraph.imageData[1]) {
				secondLineHeightStart = yValue;
			}

			if (paragraph.imageData[0]) {
				insertImage(paragraphSlides[0], paragraph.imageData[0], images[0], xValue + 0.45, yValue, 5.55, imageSpace / 2);
			}
			// down left
			if (paragraph.imageData[1]) {
				insertImage(
					paragraphSlides[0],
					paragraph.imageData[1],
					images[1],
					xValue + 0.45,
					secondLineHeightStart + 1.4,
					5,
					imageSpace / 2
				);
			}
			// down middle
			if (paragraph.imageData[2]) {
				insertImage(
					paragraphSlides[0],
					paragraph.imageData[2],
					images[2],
					xValue + 3.45,
					secondLineHeightStart + 1.4,
					5,
					imageSpace
				);
			}

			// full height
			if (paragraph.imageData[3]) {
				insertImage(paragraphSlides[0], paragraph.imageData[3], images[3], xValue + 6.45, yValue, 3.65, imageSpace / 2);
			}
		}

		if (paragraph.type === 'TYPE-3-IMAGES') {
			if (paragraph.imageData[0]) {
				insertImage(
					paragraphSlides[0],
					paragraph.imageData[0],
					images[0],
					xValue,
					titleHeight - 0.2,
					3.25,
					imageSpace / 2
				);
			}

			if (paragraph.imageData[1]) {
				insertImage(
					paragraphSlides[0],
					paragraph.imageData[1],
					images[1],
					xValue + 3.35,
					titleHeight - 0.2,
					1.65,
					imageSpace / 2
				);
			}

			if (paragraph.imageData[2]) {
				insertImage(
					paragraphSlides[0],
					paragraph.imageData[2],
					images[2],
					xValue,
					secondLineHeightStart + 0.35,
					5,
					imageSpace / 2
				);
			}
		}

		if (paragraph.type === 'TYPE-4-VARIABLE-IMAGES') {
			if (paragraph.imageData[0]) {
				insertImage(paragraphSlides[0], paragraph.imageData[0], images[0], xValue, titleHeight, 3.25, imageSpace / 2);
			}

			if (paragraph.imageData[1]) {
				insertImage(
					paragraphSlides[0],
					paragraph.imageData[1],
					images[1],
					xValue + 3.35,
					titleHeight,
					1.65,
					imageSpace / 2
				);
			}

			if (paragraph.imageData[2]) {
				insertImage(
					paragraphSlides[0],
					paragraph.imageData[2],
					images[2],
					xValue,
					secondLineHeightStart,
					1.65,
					imageSpace / 2
				);
			}
			if (paragraph.imageData[3]) {
				insertImage(
					paragraphSlides[0],
					paragraph.imageData[3],
					images[3],
					xValue + 1.75,
					secondLineHeightStart,
					3.25,
					imageSpace / 2
				);
			}
		}
	}
};

const parseFromString = paragraph => {
	const specification = `<h5>${translations.SPECIFICATIONS_TEXT}</h5>  ${paragraph.specification}`;

	const content = capitalizeTheContent(`${paragraph.body} ${paragraph.specification && specification}`);

	const htmlBody = new DOMParser().parseFromString(content, 'text/html');

	return htmlBody.body;
};

export const addParagraphToExcel = (paragraph, pptx, chapterTitle, sectionTitle, articleTitle, styles) => {
	if (chapterTitle || sectionTitle || articleTitle || paragraph.title || paragraph.body || paragraph.imageData) {
		let currentTextHeight = 0;
		const slideMeasurements = { width: 10, height: 5.625, borderSize: 0.25 };
		slideMeasurements.maxTextWidth = slideMeasurements.width - 2 * slideMeasurements.borderSize;
		slideMeasurements.maxTextHeight = slideMeasurements.height - 2 * slideMeasurements.borderSize;

		const paragraphSlides = [];
		paragraphSlides.push(pptx.addSlide());

		// TITLES
		const titleHeight = addSlideTitles(
			paragraphSlides,
			chapterTitle,
			sectionTitle,
			articleTitle,
			paragraph.title,
			styles,
			slideMeasurements
		);

		// TEXT
		if (paragraph.body) {
			let maxTextWidth = 4.35;
			let maxLength = 40;
			if (
				!paragraph.imageData ||
				paragraph.type === 'FULL-M' ||
				paragraph.type === 'FULL-L' ||
				paragraph.type === 'FULL-K'
			) {
				maxTextWidth = slideMeasurements.maxTextWidth;
				maxLength = 80;
			}

			const htmlBody = parseFromString(paragraph);

			// transverse the dom
			const c = transverseDOM(
				htmlBody,
				titleHeight,
				slideMeasurements,
				paragraphSlides,
				maxLength,
				maxTextWidth,
				pptx,
				styles
			);

			currentTextHeight = c.currentTextHeight;
		}

		addSlideImages(pptx, paragraph, titleHeight, currentTextHeight, paragraphSlides, slideMeasurements);
	}
};

const convertPixelToInches = pxValue => parseInt(pxValue, 10) / 72;

export const addCoverSlide = (pptx, bgColor, logoData) => {
	const { sidebar: pathImage, size } = logoData;

	const widthImage = convertPixelToInches(size.sidebar.width);
	const heightImage = convertPixelToInches(size.sidebar.height);

	pptx.defineSlideMaster({
		title: 'COVER_SLIDE',
		objects: [
			{ rect: { x: 0, y: 0, w: '100%', h: '50%', fill: { color: bgColor } } },
			{ image: { x: 0.5, y: 0.5, w: widthImage, h: heightImage, path: pathImage } },
		],
	});

	pptx.addSlide({ masterName: 'COVER_SLIDE' });
};
