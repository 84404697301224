import { func } from 'prop-types';
import React from 'react';
import { BRAND_VALUE, ROLE_VALUE } from '../../constants/modalAnalyticsConstants';

import { useAnalyticsContext } from '../../hooks/useAnalyticsContext';
import { usePieChartAnalytics } from '../../hooks/usePieChartAnalytics';
import { sortStringArrAscendingByKey } from '../../utils/arrayUtils';
import { LabelChart } from '../labelChart/LabelChart';
import './LabelChartContent.scss';

export const LabelChartContent = ({ filterByAbbr }) => {
	const { getMetricsForChart, getMetricsForChartByGroup } = usePieChartAnalytics();

	const { locationType, isMetricsRoles } = useAnalyticsContext();
	const { nameKey } = locationType;

	const data = sortStringArrAscendingByKey(getMetricsForChart(), nameKey);

	return (
		<div className="LabelChartContent">
			{getMetricsForChartByGroup(data).map(({ group, locations }) => (
				<div key={group} className="LabelChartContent__groupLabel">
					{(locationType.value === ROLE_VALUE || locationType.value === BRAND_VALUE) && <span> {group} </span>}

					{locations.map(({ label, abbr, totalRegister, total, color }) => (
						<LabelChart
							key={label}
							label={`${label} (${
								locationType.value === ROLE_VALUE && label !== 'Others' ? totalRegister : total
							})`}
							color={color}
							onClick={() => filterByAbbr(isMetricsRoles(label) ? [] : abbr)}
						/>
					))}
				</div>
			))}
		</div>
	);
};

LabelChartContent.propTypes = {
	filterByAbbr: func.isRequired,
};
