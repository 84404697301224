import PropTypes from 'prop-types';
import React, { Fragment, memo } from 'react';
import AppContainer from '../../containers/appContainer/AppContainer';
import { RouteModel } from '../model/RouteModel';

const arePropsEqual = (prevProps, nextProps) => {
	if (prevProps === nextProps) {
		return true;
	}
	if (prevProps.matchParams !== nextProps.matchParams) {
		return JSON.stringify(prevProps.matchParams) === JSON.stringify(nextProps.matchParams);
	}
	return false;
};

const NavigationRouteContent = memo(({ routeItem, matchParams }) => {
	const Layout = routeItem.layout ? routeItem.layout : Fragment;

	return (
		<>
			{routeItem.header()}
			<AppContainer>
				<Layout>{routeItem.renderComponent(matchParams)}</Layout>
			</AppContainer>
			{routeItem.footer()}
		</>
	);
}, arePropsEqual);

NavigationRouteContent.propTypes = {
	routeItem: PropTypes.instanceOf(RouteModel).isRequired,
	matchParams: PropTypes.shape({}).isRequired,
};

export default NavigationRouteContent;
