/* eslint-disable max-len */
// Please keep alphabetical order
export const translations = Object.freeze({
	ACCEPT: 'Accept',
	ADD: 'Add',
	ADD_EDIT_FILES: 'Add / Edit files',
	ADD_EDIT_FILES_LIMIT_FILE_MESSAGE: '*Only 5 files per paragraphs are allowed',
	ADD_FILES: 'Add Files',
	ADD_MODULE: 'Add module',
	ALL: 'All',
	ALLOWED_DEPARTMENTS: 'Allowed Departments',
	ALLOWED_GROUPS: 'Allowed Groups',
	ALREADY_APPROVED: 'This content is already approved',
	APPROVE: 'Approve',
	APPROVED: 'Approved',
	ARTICLE: 'Article',
	APPROVE_AND_PUBLISH: 'Approve & Publish',
	BRANDS: 'Brands',
	CACHE: 'Cache',
	CANCEL: 'Cancel',
	CAPTION_JUSTIFICATION: 'Caption Justification',
	CHANGE_DELETE: 'Change / Delete',
	CHANGE_SAVED_SUCCESSFULLY_MESSAGE: 'Changes were successfully saved',
	CHAPTER: 'Chapter',
	CHAPTER_TYPE: 'Chapter Type',
	CLEAR_CACHE: 'Clear cache',
	CLEAR_CACHE_ERROR: 'There was an error cleaning cache, please try again.',
	CLEAR_CACHE_SUCCESS: 'Cache cleared successfully.',
	COLLAPSE_MODULE_ON_LOAD: 'Collapse module on load',
	COMING_SOON_TEXT: 'Coming soon',
	CONFIRM: 'Confirm',
	CONTENT_ADDED: 'Content was successfully added',
	CONTENT_DELETED: 'Content was successfully deleted',
	CONTENT_SAVED_SUCCESSFULLY: 'Content saved successfully',
	CONTINUE: 'Continue',
	COPIED_TO_CLIPBOARD: 'Copied to clipboard',
	COPY_IN_DEV_ERROR: "Copy doesn't work in dev ambient",
	COPY_MODULE_LINK: 'Copy module link',
	DELETE: 'DELETE',
	DELETE_MODULE: 'Delete Module',
	DELETE_MODULE_CONFIRMATION_TEXT: 'Select the button below if you would like to delete this module',
	DELIVERY: 'Delivery',
	DISCARD_CHANGES: 'Discard changes',
	DISCARD_CHANGES_TEXT: 'Do you wish to discard changes without saving?',
	DISPLAY_ORDER: 'Display Order',
	DO_YOU_WANT_TO: 'Do you want to {0} this {1}?',
	EDIT: 'Edit',
	EDIT_IMAGE: 'Edit image',
	EDIT_INLINE: 'Edit inline',
	EDIT_NAVIGATION: 'Edit Navigation',
	EDIT_PAGE_CONFIG: 'Edit Page Config',
	EDIT_PUBLISH_BRANDS: 'Edit publish brands',
	ENTER_LINK: 'Enter link...',
	ENTER_TEXT: 'Enter text...',
	ENTER_YOUR_KEYWORDS: 'Enter your keywords...',
	ENTER_YOUR_VALUES: 'Enter your values...',
	ERROR_ADDING_CONTENT: 'Error adding content.',
	ERROR_DELETING_CONTENT: 'Error deleting content.',
	ERROR_DELETING_IMAGE: 'Error deleting image.',
	ERROR_DELETING_PUBLISHED_CONTENT:
		'The content cannot be removed because it is published. Please unpublish the content and try to remove it again.',
	ERROR_EDITING_CONTENT: 'Error editing content.',
	ERROR_FETCHING_RELATIONSHIP: 'Error while fetching relationships',
	ERROR_FILES_LIMIT: 'You can upload up to 5 files only.',
	ERROR_EXPORTING_PPTX: 'An error occurred when exporting power point presentation',
	ERROR_PUBLISHING_PAGES: 'Error publishing page(s)',
	ERROR_WHEN_DISAPPROVING_CONTENT: 'Error when disapproving content.',
	ERROR_WHEN_APPROVING_CONTENT: 'Error when approving content.',
	ERROR_UNPUBLISHING_PAGES: 'Error unpublishing page(s)',
	ERROR_UPLOADING_IMAGE: 'There was an error uploading image, please try again.',
	EXACT_MATCH: 'Exact match',
	EMPLOYEE_PERMISSION_DENIED_MESSAGE:
		'Content for Brand Experiences is only visible to managers and above. Please ask your manager to review the site with you if there is information you need.',
	FAILED_CHANGE_FILE: 'Failed to change the file',
	FAILED_CREATE_IMAGE: 'Failed to create the image',
	FAILED_CREATE_PAGE: 'Failed to create the page',
	FAILED_DELETE_FILE: 'Failed to delete the file',
	FAILED_DELETE_IMAGE: 'Failed to delete the image',
	FAILED_DELETE_PAGE: 'Failed to delete the page',
	FAILED_FETCH_ARTICLE: 'Problems loading the articles on this page.',
	FAILED_FETCH_CHAPTERS: 'Problems loading the chapters.',
	FAILED_FETCH_RELATED_TERMS: 'Problems loading the related terms.',
	FAILED_UPDATE_IMAGE: 'Failed to update the image',
	FAILED_SAVE_CHANGE: 'Failed to save changes',
	FAILED_SAVE_CONTENT: 'Failed to save content',
	FAILED_TO_PRINT_THIS_PAGE: 'Failed to print this page',
	FAILED_TO_COPY: 'Failed to copy',
	FAILED_UPDATE_FILE: 'Failed to update the file',
	FAILED_UPLOAD_FILE: 'Failed to upload the file',
	FILE_CHANGE_SUCCESS: 'File was successfully changed',
	FILE_DELETE_SUCCESS: 'File was successfully deleted',
	FILE_EXTENSION_NOT_ALLOWED: 'File extension not allowed.',
	FILE_SIZE_NOT_ALLOWED: 'The file size must be up to 2 Mb.',
	FILE_UPDATE_SUCCESS: 'File was successfully updated',
	FILE_UPLOAD_SUCCESS: 'File was successfully uploaded',
	FRANCHISEE_PERMISSION_DENIED_MESSAGE:
		'You must be a manager or above to view this page. Please ask a manager to review this content with you. If you are a manager and are receiving this message please ask your General Manager to update your role within Hyatt User Management.',
	FOOTER_TEXT_ALL_RIGHTS_RESERVED:
		'Confidential and Proprietary Materials of Hyatt | (c) {0} Hyatt Corporation. All rights reserved.',
	FOOTER_TEXT_VERSION: 'Version 28: Q3 {0}',
	GENERAL: 'General',
	IMAGE_ADDED_SUCCESS: 'Image was successfully added',
	IMAGE_DELETED_SUCCESS: 'Image was successfully deleted',
	IMAGE_TEXT_OVERLAY: 'Image Text Overlay',
	IMAGE_UPDATED_SUCCESS: 'Image was successfully updated',
	INSERT_DESCRIPTION_HERE: 'Insert description here',
	INSERT_SPECIFICATION_HERE: 'Insert specification here',
	INSERT_TEASER_HERE: 'Insert teaser here',
	INSERT_TEXT_HERE: 'Insert text here',
	INSERT_TITLE_HERE: 'Insert title here',
	INSERT_DISCLAIMER_HERE: 'Insert disclaimer here',
	INVALID_PATH: 'Invalid path',
	KEYWORDS: 'Keywords',
	LABEL_BUTTON: 'new button',
	LABEL_FILE_BUTTON: 'choose file',
	LANDSCAPE: 'Landscape',
	LAST_MODIFIED_TEXT: 'Last Modified',
	LINK_TO: 'Link To',
	MODAL_DESCRIPTION_NO_ITEMS: 'there are no items to be {0} in this {1}',
	MODAL_FILE_TITLE: 'Add Button and Assets',
	MODAL_PRINT_TITLE: 'What would you like to print?',
	MODAL_REMOVE_BUTTON_MESSAGE: 'Are you sure that you want to permanently remove {0} button?',
	MODAL_REMOVE_IMAGE_TITLE: 'Are you sure that you want to permanently remove this image?',
	MODAL_REMOVE_ITEM_TITLE: 'Are you sure that you want to permanently remove this item?',
	MODULE: 'Module',
	MODULE_DELETE_FAILED: 'Failed to delete the module',
	MODULE_SUCCESS_DELETED: 'Module was successfully deleted.',
	MOVE: 'Move',
	NAVIGATION: 'Navigation',
	NO_PERMISSION_TITLE: "WHY CAN'T I SEE THIS CONTENT?",
	OK: 'Ok',
	OVERRIDE_SECURITY_OPTIONS: 'Override Security Options',
	OVERRIDE_SECURITY_OPTIONS_INFO: 'Override content security settings. Supervisory Level, Allowed Groups, Allowed Departments',
	PAGES_UNDER_THAT_ARE_UNAPPROVED: 'All these pages under this {0} that are unapproved.',
	PAGE_APPROVED: 'Page was successfully approved.',
	PAGE_CONTENT_ADDED: 'Page was successfully created.',
	PAGE_CONTENT_DELETED: 'Page was successfully deleted.',
	PAGE_IS_NOT_APPROVED: 'Page is not approved',
	PAGE_IS_NOT_APPROVED_PUBLISH: 'This page is not approved, do you want to approve and publish it anyway?',
	PAGE_IS_NOT_PUBLISHED: 'Page is not published',
	PAGE_PATH: 'Page Path',
	PAGE_UNAPPROVED: 'Page was successfully unapproved.',
	PERMISSION_DENIED_MESSAGE:
		'Please contact your Marketing or Design Services sponsor if you need access to content from the Brand Experiences site.',
	PLACEHOLDER_CHAPTER_TEXT: 'Add Level Zero Item',
	PLACEHOLDER_CHILD_TEXT: 'Add child to',
	PLEASE_BE_PATIENT: 'Please, be patient. It can take some time.',
	PORTRAIT: 'Portrait',
	POWERPOINT: 'PowerPoint',
	PREVIEW: 'Preview',
	PROPERTIES: 'Properties',
	PUBLISH: 'Publish',
	PUBLISH_BRAND_MESSAGE: 'Do you want to publish all approved pages from selected brands below:',
	PUBLISHED: 'Published',
	PUBLISHING_PAGE: 'Publishing page...',
	PUBLISH_SUCCESS: 'The page was published successfully',
	PUBLISH_BRAND_SUCCESS: 'The brand was published successfully',
	PUBLISH_BRAND_FAILED: 'There was a failure publishing the brand',
	PUBLISH_BRANDS_TITLE: ' Publishing Brands',
	PUBLISH_BRANDS: 'Publish Brands',
	REGIONS: 'Regions',
	REPLACE_IMAGE: 'Replace Image',
	REQUIRED: 'Required',
	RESOURCES_TEXT: 'Resources',
	RESULTS_FOR: 'results for',
	SAVE: 'Save',
	SAVE_AS_DRAFT: 'Save as draft',
	SAVING: 'Saving',
	SEARCH_FOR: 'Search for...',
	SEARCH_TEXT: 'Search',
	SECTION: 'Section',
	SECURITY: 'Security',
	SELECT_AN_IMAGE_BEFORE_OR: 'Select an image or change its overlay/sub-caption before saving',
	SEARCH_RESULTS: 'Search results',
	SELECT_A_MODULE: 'Select a Module',
	SERVICE_FAILURE: 'Service failure',
	SETTING_PUBLISH_BRAND: 'Setting Publish Brand',
	SIGN_OUT_TEXT: 'Sign out',
	SOMETHING_WENT_WRONG: 'Something went wrong',
	SPECIFICATIONS_TEXT: 'Specifications',
	STALE: 'Stale',
	STATUS: 'Status',
	STOP_EDITING: 'Stop editing',
	SUB_CAPTION_TEXT: 'Sub-Caption Text',
	SUPERVISORY_LEVEL: 'Supervisory Level',
	TERMS_CONDITIONS_TITLE: 'Terms and Conditions',
	TEXT_PLACEHOLDER_NAME_BUTTON: 'Name your file...',
	THIS_PAGE: 'this page',
	TITLE: 'Title',
	TITLE_BOX: 'To learn more about our portfolio of brands, select a logo below.',
	TITLE_JUSTIFICATION: 'Title Justification',
	TITLE_SECTION_BUTTON: 'Button',
	TITLE_SECTION_FIELD: 'Button Text',
	TITLE_SECTION_FILE: 'File',
	TOOLTIP_EDIT_NAVIGATION_TEXT_ADD: 'Add item',
	TOOLTIP_EDIT_NAVIGATION_TEXT_CANCEL_EDIT: 'Cancel edit',
	TOOLTIP_EDIT_NAVIGATION_TEXT_APPROVE: 'Would you like to approve items under the hyatt brand',
	TOOLTIP_EDIT_NAVIGATION_TEXT_EDIT: 'Edit item',
	TOOLTIP_EDIT_NAVIGATION_TEXT_MOVE: 'Move item',
	TOOLTIP_EDIT_NAVIGATION_TEXT_PUBLISH: 'Would you like to publish items under the hyatt brand',
	TOOLTIP_EDIT_NAVIGATION_TEXT_MOVE_UP: 'Move up',
	TOOLTIP_EDIT_NAVIGATION_TEXT_MOVE_DOWN: 'Move down',
	TOOLTIP_EDIT_NAVIGATION_TEXT_REMOVE: 'Remove item',
	TOOLTIP_EDIT_NAVIGATION_TEXT_SAVE: 'Save changes',
	TOOLTIP_EDIT_NAVIGATION_TEXT_QUICKSHEET: 'Link to Quicksheet',
	TRY_AGAIN: 'Try again',
	UNAPPROVE: 'Unapprove',
	UNAPPROVED: 'Unapproved',
	UNPUBLISH: 'Unpublish',
	UNPUBLISH_LATER_FAIL: 'There was a failure to handle the deleted content',
	UNPUBLISHED: 'Unpublished',
	UNPUBLISH_SUCCESS: 'The page was unpublished successfully',
	UPLOAD_IMAGE: 'Upload Image',
	VISIBLE_TO_FRANCHISE: 'Visible to Franchise',
	WANT_TO_PUBLISH: 'Do you want to approve and publish them?',
	WARNING_HIGHLIGHT: `We noticed you searched for this document and now there are a few highlights in the content. If you wish to edit an item please access it from the navigation`,
	WELCOME: 'Welcome',
	WE_ARE_HAVING_PROBLEMS_TO_LOAD_APP_DATA: "We're having some problems to load the application data. Please, try again.",
	WORKING_HARD: "Wait a moment -- we're working hard in the background.",
	YOUR_FILE: 'Your file is being created.',
});
