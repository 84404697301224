import React from 'react';

export const childrenWithProps = (children, props) =>
	React.Children.map(children, child => {
		// Checking isValidElement is the safe way and avoids a typescript
		// error too.
		if (React.isValidElement(child)) {
			return React.cloneElement(child, { ...props });
		}
		return child;
	});
