import React, { useState } from 'react';
import classNames from 'classnames';
import { array, oneOfType, string } from 'prop-types';
import { appConfig } from 'utils/analyticsUtil';
import { useTheme } from 'styled-components';
import { ModalAnalytics } from '@hyatt/modal-analytics';
import { isDelivery } from 'utils/envUtil';

import './ButtonModalAnalytics.scss';

export const ButtonModalAnalytics = ({ contentId, ...props }) => {
	const { color } = useTheme();

	const [isOpen, setIsOpenModalAnalytics] = useState(false);

	const classNameButtonAnalytics = classNames('ButtonModalAnalytics', {
		'ButtonModalAnalytics--isDelivery': isDelivery,
	});

	return (
		<>
			<button
				type="button"
				className={classNameButtonAnalytics}
				onClick={() => {
					setIsOpenModalAnalytics(true);
				}}
				{...props}
			>
				<i className="icon icon-barchart" style={{ color: color.icon.primary02 }} />
			</button>

			{isOpen && (
				<ModalAnalytics
					appConfig={appConfig}
					contentId={contentId}
					open={isOpen}
					onClose={() => setIsOpenModalAnalytics(false)}
				/>
			)}
		</>
	);
};

ButtonModalAnalytics.propTypes = {
	contentId: oneOfType([string, array]),
};

ButtonModalAnalytics.defaultProps = {
	contentId: '',
};
