import axios from 'API/axios/axios';
import { getLastElement } from 'utils/arrayUtil';
import { getName } from 'utils/contentFragmentUtil';
import { logError } from 'utils/logUtil';
import { isEmptyString } from 'utils/stringUtil';

export const putParagraph = ({ pagePath, cfType, data }) => {
	const url = `${process.env.REACT_APP_API_URL}/v1/contentFragment/createOrUpdateNested`;

	return axios.post(url, data, {
		headers: {
			pagePath,
			cfType,
		},
	});
};

const fromRelatorImage = relatorImage => {
	try {
		const imageVcmId = isEmptyString(relatorImage.VCMID);

		const imageCaption = isEmptyString(relatorImage.BEG_ARTICLE_CONTENT_IMAGE_CAPTION);
		const captionPosition = isEmptyString(relatorImage.BEG_ARTICLE_CONTENT_IMAGE_CAPTION_POSITION);
		const imageContentPath = isEmptyString(relatorImage.BEG_ARTICLE_CONTENT_IMAGE_IMAGE_CONTENT);
		const textOverlay = isEmptyString(relatorImage.BEG_ARTICLE_CONTENT_IMAGE_TEXT_OVERLAY);
		const textOverlayPosition = isEmptyString(relatorImage.BEG_ARTICLE_CONTENT_IMAGE_TEXT_OVERLAY_POSITION);
		const imageTitle = isEmptyString(relatorImage.BEG_ARTICLE_CONTENT_IMAGE_TITLE);

		const displayOrder = relatorImage.BEG_ARTICLE_CONTENT_IMAGE_DISPLAY_ORDER || 1;

		const imagePath = relatorImage?._path;
		const parentPath = relatorImage?._path;

		return {
			imageVcmId,
			imageCaption,
			captionPosition,
			displayOrder,
			imageContentPath,
			textOverlay,
			textOverlayPosition,
			imageTitle,
			parentPath,
			imagePath,
		};
	} catch (e) {
		logError(e.message);
		return null;
	}
};

const fromRelatorFile = relatorFile => {
	try {
		const vcmId = isEmptyString(relatorFile.VCMID);
		const linkText = isEmptyString(relatorFile.FILE_LINK_TEXT);
		const path = relatorFile?._path;
		const displayOrder = relatorFile.DispOrder || 1;
		const filePath = relatorFile?.file?.length && getLastElement(relatorFile?.file);
		const file = {
			name: relatorFile?.file?.length && getName(getLastElement(relatorFile?.file)),
		};

		return { vcmId, linkText, path, displayOrder, filePath, file };
	} catch (e) {
		logError(e.message);
		return null;
	}
};

export const fromParagraphService = contentFragment => {
	try {
		const vcmId = isEmptyString(contentFragment['jcr:uuid']);
		const parentPath = isEmptyString(contentFragment['jcr:content']['cq:parentPath']);
		const name = isEmptyString(contentFragment['jcr:content']['cq:name']);
		const master = isEmptyString(contentFragment['jcr:content'].data.master);

		const title = isEmptyString(contentFragment['jcr:content']?.data?.master?.TITLE);
		const body = isEmptyString(contentFragment['jcr:content']?.data?.master?.BODY);
		const specification = isEmptyString(contentFragment['jcr:content']?.data?.master?.SPECIFICATION);
		const disclaimer = isEmptyString(contentFragment['jcr:content']?.data?.master?.DISCLAIMER);

		const displayOrder = master.DISPLAY_ORDER || 1;
		const type = master.TYPE;

		const imageData = master?.RELATOR_IMAGE_ID?.map(image => fromRelatorImage(image));

		const files = master?.RELATOR_FILE_BEG_ID?.sort((item1, item2) => +item1.DispOrder - +item2.DispOrder).map(relatorFile =>
			fromRelatorFile(relatorFile)
		);

		let path = '';

		if (parentPath && name) {
			path = `${parentPath}/${name}`;
		}

		return { vcmId, path, type, body, title, specification, disclaimer, displayOrder, imageData, files };
	} catch (e) {
		logError(e.message);
		return null;
	}
};
