import { CHAPTERS_TYPE, PAGE_TYPES } from 'constants/pageConstants';
import { TERMS_AND_CONDITIONS } from 'constants/termsAndConditionsConstants';

const MODELS_BASE_PATH = '/conf/HyattConnect/settings/dam/cfm/models';

export const BASE_BEG_PATH = '/content/beg/home';
export const BASE_DAM_BEG_PATH = '/content/dam/beg';
export const TERMS_AND_CONDITIONS_PATH = '/content/dam/beg/terms-and-conditions';
export const BEG_TERM = 'BEG:';
export const HC_TERM = 'hyattconnect:';

export const MODELS = {
	CHAPTER: 'site-chapter',
	RELATOR_CHAPTERS: 'relator-chapters',
	SECTION: 'site-section',
	RELATOR_SECTIONS: 'relator-sections',
	ARTICLE: 'site-article',
	RELATOR_ARTICLES: 'relator-articles',
	RELATOR_PARAGRAPHS: 'relator-paragraphs',
	RELATOR_IMAGE: 'relator-image',
	RELATOR_FILE: 'relator-file',
	TERMS_AND_CONDITIONS: 'terms-and-conditions',
	SITE_AD_GROUP: 'site-ad-group',
	RELATOR_ALLOWED_DEPARTMENTS: 'relator-allowed-departments',
	ROW: 'row',
};

export const MODELS_PATHS = {
	CHAPTER: `${MODELS_BASE_PATH}/${MODELS.CHAPTER}`,
	RELATOR_CHAPTERS: `${MODELS_BASE_PATH}/${MODELS.RELATOR_CHAPTERS}`,
	SECTION: `${MODELS_BASE_PATH}/${MODELS.SECTION}`,
	RELATOR_SECTIONS: `${MODELS_BASE_PATH}/${MODELS.RELATOR_SECTIONS}`,
	ARTICLE: `${MODELS_BASE_PATH}/${MODELS.ARTICLE}`,
	RELATOR_ARTICLES: `${MODELS_BASE_PATH}/${MODELS.RELATOR_ARTICLES}`,
	RELATOR_PARAGRAPHS: `${MODELS_BASE_PATH}/${MODELS.RELATOR_PARAGRAPHS}`,
	RELATOR_IMAGE: `${MODELS_BASE_PATH}/${MODELS.RELATOR_IMAGE}`,
	RELATOR_FILE: `${MODELS_BASE_PATH}/${MODELS.RELATOR_FILE}`,
	TERMS_AND_CONDITIONS: `${MODELS_BASE_PATH}/${MODELS.TERMS_AND_CONDITIONS}`,
	SITE_AD_GROUP: `${MODELS_BASE_PATH}/${MODELS.SITE_AD_GROUP}`,
	RELATOR_ALLOWED_DEPARTMENTS: `${MODELS_BASE_PATH}/${MODELS.RELATOR_ALLOWED_DEPARTMENTS}`,
	ROW: `${MODELS_BASE_PATH}/${MODELS.ROW}`,
};

export const CACHE_MAP_PREFIX = 'com.hyatt.adobe.intranet.sql';

export const cacheMaps = {
	CHAPTERS: `${CACHE_MAP_PREFIX}.${CHAPTERS_TYPE}`,
	CHAPTER: `${CACHE_MAP_PREFIX}.${PAGE_TYPES.CHAPTER_TYPE}`,
	SECTION: `${CACHE_MAP_PREFIX}.${PAGE_TYPES.SECTION_TYPE}`,
	ARTICLE: `${CACHE_MAP_PREFIX}.${PAGE_TYPES.ARTICLE_TYPE}`,
	PAGE: `${CACHE_MAP_PREFIX}.${PAGE_TYPES.PAGE_TYPE}`,
	TERMS_AND_CONDITIONS: `${CACHE_MAP_PREFIX}.${TERMS_AND_CONDITIONS}`,
};
