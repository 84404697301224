import { useEffect, useMemo } from 'react';
import { fromTermsAndConditions, getTermsAndConditions } from 'services/termsAndConditionsService';
import { translations } from 'translation/en';
import { showToast } from 'utils/toastUtil';
import useBrandData from './useBrandData';
import { useRequest } from './useRequest';
import { useAuthAccess } from './useAuthData';

const TERMS_AND_CONDITIONS_KEY = 'terms-and-conditions';

export const useTermsAndConditions = () => {
	const { brandId } = useBrandData();
	const { profileStatus, visibleStatus } = useAuthAccess();
	const {
		loading: loadingTermsAndConditions,
		data: response,
		error,
		run: fetchTermsAndConditions,
		clear,
	} = useRequest(() => getTermsAndConditions({ brandId, visible: visibleStatus, status: profileStatus }));

	useEffect(() => {
		if (response) {
			clear();
		}
	}, [response, clear]);

	useEffect(() => {
		if (error) {
			showToast({ type: 'error', message: translations.SERVICE_FAILURE });
		}
	}, [error]);

	const termsAndConditions = useMemo(() => {
		return response?.data.data[TERMS_AND_CONDITIONS_KEY].items.map(fromTermsAndConditions)[0];
	}, [response]);

	return { loadingTermsAndConditions, termsAndConditions, error, fetchTermsAndConditions };
};
