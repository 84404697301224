import { useCallback, useEffect } from 'react';
import { deleteContentFragment } from 'services/contentFragmentService';
import { translations } from 'translation/en';
import { showToast } from 'utils/toastUtil';
import useBrandDetails from './useBrandDetails';
import { useRequest } from './useRequest';

import useClearCache from './useClearCache';

export const useDeleteContentFragment = (
	{ handleError, deleteMessage, successMessage } = {
		handleError: true,
		deleteMessage: translations.ERROR_DELETING_CONTENT,
		successMessage: translations.CONTENT_DELETED,
	}
) => {
	const { currentType } = useBrandDetails();
	const { loading, data: response, error, run, clear } = useRequest(deleteContentFragment);
	const { clearCache } = useClearCache();

	useEffect(() => {
		if (response) {
			clear();
			clearCache();
		}
	}, [clear, clearCache, response, successMessage]);

	useEffect(() => {
		if (error && handleError) {
			if (error.response?.status === 400) {
				showToast({ type: 'error', message: translations.ERROR_DELETING_PUBLISHED_CONTENT });
			} else {
				showToast({ type: 'error', message: deleteMessage });
			}
		}
	}, [error, handleError, deleteMessage]);

	const removeContentFragment = useCallback(
		({ cfType, pagePath, data }) => {
			return run({
				pagePath: pagePath || currentType?.path,
				cfType: cfType || `${currentType?.type?.toLowerCase()}`,
				data,
			});
		},
		[currentType, run]
	);

	return { loading, response, error, removeContentFragment, run, clear };
};
