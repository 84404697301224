import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { EditNavigation } from 'components/editNavigation';
import { Loading } from 'components/loadingOverlay';
import { PAGE_TYPES } from 'constants/pageConstants';
import { BrandSideBarChapter } from './BrandSideBarChapter';
import './BrandSideBarChapters.scss';

const BrandSideBarChapters = ({ chapters, loading, ...props }) => {
	const { logo } = useTheme();

	return (
		<ul className="BrandSideBarChapters">
			{loading && (
				<Loading.Overlay>
					<Loading.LoadingLogo logo={logo?.loading} size={logo?.size.loading} />
				</Loading.Overlay>
			)}

			{chapters?.map(chapter => (
				<BrandSideBarChapter key={chapter.vcmId + chapter.path + chapter.displayOrder} {...chapter} {...props} />
			))}
			<EditNavigation.Input type={PAGE_TYPES.CHAPTER_TYPE} />
		</ul>
	);
};

BrandSideBarChapters.propTypes = {
	loading: PropTypes.bool,
	chapters: PropTypes.array,
};

BrandSideBarChapters.defaultProps = {
	loading: false,
	chapters: [],
};

export { BrandSideBarChapters };
