import styled from 'styled-components/macro';

export const NoPermissionMessage = styled.div(() => ({
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
	height: '100%',
	marginTop: '25px',
}));

export const StyledTitle = styled.h2(({ theme: { color, typo } }) => ({
	color: color?.text?.primary02,
	...typo?.headline['02.1'],
}));

export const StyledMessage = styled.p(({ theme: { color, typo } }) => ({
	color: color?.text?.paragraph,
	...typo?.body['02'],
}));
