import { string } from 'prop-types';
import React from 'react';
import './FormFieldError.scss';

const FormFieldError = ({ message }) => {
	return <p className="FormFieldError">{message}</p>;
};

FormFieldError.propTypes = {
	message: string,
};

FormFieldError.defaultProps = {
	message: '',
};

export default FormFieldError;

export { FormFieldError };
