import { func } from 'prop-types';
import React from 'react';
import * as S from './MenuButton.style';

const MenuButton = ({ onClick }) => {
	return (
		<S.MenuButton variant="outlined" onClick={onClick}>
			<S.Icon className="icon icon-menu" />
		</S.MenuButton>
	);
};

MenuButton.propTypes = {
	onClick: func,
};

MenuButton.defaultProps = {
	onClick: () => null,
};

export { MenuButton };
