import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import useBrandData from 'hooks/useBrandData';

const useContentPath = () => {
	const { pathname } = useLocation();
	const { brandName } = useBrandData();

	const contentPath = useMemo(() => pathname.split(`/brands/${brandName}`)[1], [pathname]);

	return { contentPath };
};

export { useContentPath };
