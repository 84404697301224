import useBrandRoute from 'hooks/useBrandRoute';
import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useQueryParams = () => {
	const { search, hash } = useLocation();
	const { getBrandRoute } = useBrandRoute();

	const searchParams = useMemo(() => search, [search]);

	const getQueryParam = useCallback(
		key => {
			const queryParams = new URLSearchParams(searchParams);
			return queryParams.get(key) && decodeURIComponent(queryParams.get(key));
		},
		[searchParams]
	);

	const getRouteQueryParam = useCallback(
		path => {
			return `${getBrandRoute(path)}${searchParams}`;
		},
		[searchParams, getBrandRoute]
	);

	const targetHash = useMemo(() => hash?.split('#')[1], [hash]);

	return {
		searchParams,
		targetHash,
		getQueryParam,
		getRouteQueryParam,
	};
};
