import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { PropTypes } from 'prop-types';
import { useTheme } from 'styled-components';
import { InnerHtml } from '@hyatt/inner-html';
import { Button } from 'components/button';
import { Loading } from 'components/loadingOverlay';
import { Modal } from 'components/modals/modal';
import { TermsConditionsSkeleton } from 'components/loaders/termsConditionsSkeleton';
import { useAcceptTermsAndConditions } from 'hooks/useAcceptTermsAndConditions';
import { useTermsAndConditions } from 'hooks/useTermsAndConditions';
import useSignOut from 'hooks/useSignOut';
import { useCheckTermsAndConditions } from 'hooks/useCheckTermsAndConditions';
import { translations } from 'translation/en';
import { getOverflow } from 'utils/overflowUtil';
import * as S from './TermsConditionsModal.style';

const TermsConditionsModal = ({ open, action }) => {
	const [isVisible, setIsVisible] = useState(open);
	const [domReady, setDomReady] = useState(false);
	const { logo } = useTheme();

	const portalElement = document.getElementById('root');
	const [termsAndConditionsData, setTermsAndConditionsData] = useState({
		title: '',
		teaser: '',
		description: '',
	});

	const { isShowTermsAndConditions } = useCheckTermsAndConditions();
	const { loadingAccept, acceptTermsAndConditions } = useAcceptTermsAndConditions();
	const { loadingTermsAndConditions, termsAndConditions, fetchTermsAndConditions } = useTermsAndConditions();
	const { signOut } = useSignOut();

	useEffect(() => {
		setDomReady(true);
		const currentOverflowY = portalElement?.style.overflowY;
		if (portalElement) portalElement.style.overflowY = getOverflow(open);

		return () => {
			setDomReady(false);
			if (portalElement) portalElement.style.overflow = currentOverflowY;
		};
	}, [open, portalElement]);

	useEffect(() => {
		if (isShowTermsAndConditions) {
			fetchTermsAndConditions();
			setIsVisible(true);
		}
	}, [isShowTermsAndConditions, fetchTermsAndConditions]);

	useEffect(() => {
		if (termsAndConditions) {
			setTermsAndConditionsData(termsAndConditions);
		}
	}, [termsAndConditions]);

	const handleClose = () => {
		setIsVisible();
	};

	const handleAccept = () => {
		acceptTermsAndConditions().then(() => {
			handleClose();
		});
	};

	const handleLogout = e => {
		signOut(e);
		handleClose();
	};

	if (!isShowTermsAndConditions) {
		return null;
	}

	const renderTermsConditionContent = () => {
		if (loadingTermsAndConditions) {
			return <TermsConditionsSkeleton />;
		}

		return (
			<>
				<Modal.Header onClose={handleLogout}>
					<S.Title>{termsAndConditionsData?.title}</S.Title>
				</Modal.Header>
				<Modal.Main>
					<S.Content>
						<div>
							<InnerHtml body={termsAndConditionsData?.teaser} />
						</div>
						<div>
							<InnerHtml body={termsAndConditionsData?.description} />
						</div>
					</S.Content>
				</Modal.Main>
			</>
		);
	};

	const modalComponent = isVisible && (
		<S.StyledModal open={isVisible} onClose={handleLogout} closeIcon={<i className="icon-close-slim" />}>
			{loadingAccept && (
				<Loading.Overlay>
					<Loading.LoadingLogo logo={logo?.loading} size={logo?.size.loading} />
				</Loading.Overlay>
			)}

			{renderTermsConditionContent()}

			<Modal.Footer>
				<Button
					variant={action.primaryVariant}
					disabled={[loadingTermsAndConditions, loadingAccept].some(Boolean)}
					onClick={handleAccept}
				>
					{translations.ACCEPT}
				</Button>
				<Button variant={action.secondaryVariant} onClick={handleLogout}>
					{translations.CANCEL}
				</Button>
			</Modal.Footer>
		</S.StyledModal>
	);

	return domReady ? ReactDOM.createPortal(modalComponent, portalElement) : null;
};

TermsConditionsModal.propTypes = {
	open: PropTypes.bool,
	action: PropTypes.shape({
		primaryVariant: PropTypes.string,
		secondaryVariant: PropTypes.string,
	}),
};

TermsConditionsModal.defaultProps = {
	open: false,
	action: {
		primaryVariant: 'primary',
		secondaryVariant: 'secondary',
	},
};

export { TermsConditionsModal };
