import { combineReducers } from 'redux';
import authReducer from './auth/authReducer';
import editMenuReducer from './editMenu/editMenuReducer';
import locationReducer from './location/locationReducer';
import securityReducer from './security/securityReducer';
import searchReducer from './search/searchReducer';

const rootReducer = combineReducers({
	auth: authReducer,
	location: locationReducer,
	editMenu: editMenuReducer,
	security: securityReducer,
	search: searchReducer,
});

export default rootReducer;
