import BackgroundHeader from 'assets/img/background-header-hyatt-house.jpg';
import complementaryTheme from 'themes/complementary/complementary';

const { neutral } = complementaryTheme;

const colors = {
	background: {
		banner: {
			background: `url(${BackgroundHeader}) no-repeat`, // header
		},
		secondary: '#5a7f71', // sidebar and navbar (mobile)
		tertiary: '#F7F7F7', // footer
	},
	interactive: {
		// terms & conditions primary button modal
		primary: {
			default: {
				background: '#5a7f71',
				label: '#f5f0e3',
				border: '#5a7f71',
			},
			hover: {
				background: '#273c34',
				label: '#f5f0e3',
				border: '#5a7f71',
			},
		},
		// search button
		primary02: {
			default: {
				background: '#266782',
				label: '#fff',
				border: '#266782',
				opacity: '0.65',
			},
		},
		// file button
		primary05: {
			default: {
				background: '#5a7f71',
				label: '#f5f0e3',
				border: '#5a7f71',
			},
			hover: {
				background: '#273c34',
				label: '#f5f0e3',
				border: '#5a7f71',
			},
		},
		// terms & conditions secondary button modal
		secondary: {
			default: {
				background: '#5a7f71',
				label: '#f5f0e3',
				border: '#5a7f71',
			},
			hover: {
				background: '#273c34',
				label: '#f5f0e3',
				border: '#5a7f71',
			},
		},
		// sign out button
		secondary02: {
			default: {
				background: '#182e55',
				label: '#f5f0e3',
			},
			hover: {
				background: '#F2F3F4',
				label: '#f5f0e3',
			},
		},
		// button approve modal
		secondary03: {
			default: {
				background: '#333333',
				label: '#f5f0e3',
				border: '#333333',
			},
			hover: {
				background: '#fff',
				label: '#333333',
				border: '#333333',
			},
		},
		// button help
		tertiary: {
			default: {
				background: '#f5f0e3',
				label: '#151F6D',
			},
		},
		// discard changes, print modals and print page buttons
		printModalButtons: {
			default: {
				background: '#5a7f71',
				label: '#f5f0e3',
				border: '#5a7f71',
			},
			hover: {
				background: '#273c34',
				label: '#f5f0e3',
				border: '#5a7f71',
			},
		},
		// properties and edit image modals buttons
		tertiary03: {
			default: {
				background: '#fff',
				label: '#181818',
				border: '#ccc',
			},
			hover: {
				background: '#fff',
				label: '#181818',
				border: '#181818',
			},
		},
		// mobile
		outlined: {
			default: {
				label: '#f5f0e3', // mobile search and help buttons
				icon: '#f5f0e3', // mobile menu button
				border: '#f5f0e3', // mobile buttons
			},
		},
		// header buttons
		inverse: {
			default: {
				label: '#f5f0e3',
			},
		},
		discardChangesButtons: {
			default: {
				background: '#5a7f71',
				label: '#f5f0e3',
				border: '#5a7f71',
			},
			hover: {
				background: '#273c34',
				label: '#f5f0e3',
				border: '#5a7f71',
			},
		},
		printPageButtons: {
			default: {
				background: '#5a7f71',
				label: '#f5f0e3',
				border: '#5a7f71',
			},
			hover: {
				background: '#273c34',
				label: '#f5f0e3',
				border: '#5a7f71',
			},
		},
		dropDownButtons: {
			default: {
				label: '#f5f0e3',
			},
			active: {
				color: '#00000020',
				label: '#262626',
			},
			focusWithin: {
				background: 'transparent',
				label: '#5a7f71',
				border: 'none',
			},
			hover: {
				background: 'transparent',
				label: '#f5f0e3',
				border: 'none',
			},
		},

		lightest: 'rgba(255, 255, 255, 0.1)',
	},
	field: {
		// search input
		primary: {
			text: '#555',
			placeholder: '#999',
		},
		// inputs edit image modal
		secondary: {
			text: '#676767',
			placeholder: '#676767',
		},
		// input properties modal
		tertiary: {
			text: '#676767',
			placeholder: '#676767',
		},
	},
	text: {
		primary: '#636466', // footer
		primary02: '#1c355e', // title chapter, section page

		titleModule: '#1c355e',
		headline01: '#1c355e',
		headline02: '#5a7f71',
		headline03: '#1c355e',
		headline04: '#5a7f71',
		headline05: '#1c355e',
		headline06: '#5a7f71',

		paragraph: '#1c355e', // paragraph content page
		specificationTitle: '#1c355e', // specification title

		approveModalTitle: '#1c355e',
		approveModalDescription: '#1c355e',

		addFilesSubtitle: '#999999',

		secondary: '#1c355e', // terms & conditions title modal
		secondary02: '#1c355e', // title discard changes modal
		secondary03: '#1c355e', // edit image modal title

		tertiary: '#1c355e', // terms & conditions description modal
		tertiary03: '#1c355e', // title properties modal

		quaternary: '#1c355e', // title print modal
		quaternary02: '#333', // labels subtitle edit image modal
		quaternary03: '#000', // labels justification edit image modal

		quinary: '#1c355e', // titles items print modal
		quinary02: '#333', // subtitles properties modal
		quinary03: '#333', // title remove modal

		senary: '#5a7f71', // title article page
		bullet: '#1c355e', // list item bullet

		imageCaption: '#1C355E', // image caption
	},
	link: {
		// sidebar item chapter
		primary: {
			default: {
				label: '#f5f0e3',
			},
			hover: {
				label: '#f5f0e3',
			},
		},
		// sidebar item section
		secondary: {
			default: {
				label: '#f5f0e3',
			},
			hover: {
				background: 'rgba(255, 255, 255, 0.1)',
				label: '#f5f0e3',
			},
			active: {
				label: '#f5f0e3',
			},
		},
		// sidebar item article
		tertiary: {
			default: {
				label: '#f5f0e3',

				mobile: {
					label: '#3e3d40',
				},
			},
			hover: {
				label: '#f5f0e3',
				background: 'rgba(255, 255, 255, 0.1)',

				mobile: {
					label: '#3e3d40 ',
				},
			},
			active: {
				label: '#f5f0e3',
			},
		},
		// breadcrumb
		quaternary: {
			default: {
				label: '#1c355e',
			},
			hover: {
				label: '#1c355e',
			},
			active: {
				label: '#6aaae4',
			},
		},
	},
	icon: {
		primary: '#f5f0e3', // arrow icon
		primary02: '#5a7f71', // print icon
		chapterArrow: '#f5f0e3', // sidebar items arrow icon
		sectionArrow: '#f5f0e3', // section arrow icon
		breadcrumbArrow: '#555555', // breadcrumb arrow icon
	},
	border: {
		primary: '#266782', // search input
		secondary: '#B9b5aa',
		tertiary: '#333', // modal
		quaternary: '#333', // module divider
	},
	shadow: {
		primary: 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6)', // search input
		secondary: 'inset 0 1px 0 rgba(255, 255, 255, 0.1)', // menu
	},
	neutral,
	table: {
		row: {
			background: {
				primary: '#e6e7e8',
				secondary: 'transparent',
			},
			cell: {
				label: '#333333',
				border: '2px solid #0097d8',
			},
		},
	},
};

export default colors;
