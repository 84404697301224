import styled from 'styled-components/macro';

export const Container = styled.div(({ isExpanded }) => ({
	margin: '-15px auto',
	width: '100%',
	background: '#f0f0f0',

	overflow: 'hidden',
	height: 'auto',
	maxHeight: isExpanded ? '600px' : 0,
	transition: 'all 0.6s ease-in-out',
}));

export const Content = styled.div(() => ({
	overflow: 'hidden',
	padding: '50px 35px 20px',
}));

export const TemplateItems = styled.ul({
	margin: 0,
	padding: 0,
	display: 'grid',
	gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
	width: '100%',
	gap: '20px',
});

export const Item = styled.li({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'baseline',
	flexDirection: 'column',
	cursor: 'pointer',

	p: {
		fontSize: '12px',
		padding: '0 5px',
		marginTop: '8px',
		lineHeight: '1.4em',
		textAlign: 'center',
	},
});
