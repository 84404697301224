import PropTypes from 'prop-types';
import React from 'react';
import { childrenWithProps } from 'utils/childrenUtil';
import './Base.scss';

const Base = ({ children, ...props }) => {
	return <div className="Base">{childrenWithProps(children, props)}</div>;
};

Base.propTypes = {
	children: PropTypes.node,
};

Base.defaultProps = {
	children: null,
};

export { Base };
