import PlaceholderSrc786x490 from 'assets/img/placeholders/786x490.jpg';
import { EDIT_MENU_TYPES } from 'components/begEditMenu/constants/begEditMenuConstants';
import { ImageBox } from 'components/imageBox';
import {
	EditableContentWrapper,
	EditableTitleWrapper,
} from 'containers/brandDetails/components/brandMain/components/editableContent';
import { SpecificationDropDown } from 'containers/brandDetails/components/brandMain/components/specificationDropDown';
import { Wrapper } from 'containers/brandDetails/components/brandMain/components/template/ModuleTemplate.style';
import {
	DESCRIPTION_FIELD,
	EDITABLE_DESCRIPTION_TEST_ID,
	EDITABLE_DROPDOWN_SPECIFICATION_TEST_ID,
	EDITABLE_SPECIFICATION_TEST_ID,
	EDITABLE_TITLE_TEST_ID,
	FULL_WIDTH_TYPE_PLUS_GALLERY_MODULE,
	SPECIFICATION_FIELD,
	TITLE_FIELD,
} from 'containers/brandDetails/constants/modulesTypesConstants';
import { useEditMenuData } from 'hooks/useEditMenuData';
import PropTypes, { arrayOf, shape } from 'prop-types';
import { useState, useMemo, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { translations } from 'translation/en';
import { Collapse } from 'containers/brandDetails/components/brandMain/components/collapse';
import { CAPTION_JUSTIFICATION, OVERLAY_JUSTIFICATION } from 'components/imageBox/constants/imageBoxConstants';
import useClearCache from 'hooks/useClearCache';
import { ListFiles } from '../../listFiles';
import { DisclaimerEditInLine } from '../disclaimerEditInLine';
import { SpecificationLabel } from '../../specificationDropDown/SpecificationDropDown.style';
import * as S from './FULL-L.style';

const IMAGES_IN_MODULE = 1;

const FullL = ({
	startTitle,
	startDescription,
	startSpecification,
	startDisclaimer,
	startIsDropdown,
	imageData,
	keywords,
	path,
	isOnPrintPage,
	...props
}) => {
	const initialImage = useMemo(
		() => ({
			textOverlay: '',
			imageCaption: '',
			linkTo: '',
			file: PlaceholderSrc786x490,
			textOverlayPosition: OVERLAY_JUSTIFICATION.LEFT.value,
			captionPosition: CAPTION_JUSTIFICATION.LEFT.value,
			relatedPath: path,
		}),
		[path]
	);

	const { color, typo } = useTheme();
	const { selectedToEdit } = useEditMenuData();
	const { clearPageCache } = useClearCache();

	const [title, setTitle] = useState(startTitle || '');
	const [description, setDescription] = useState(startDescription || '');
	const [specification, setSpecification] = useState(startSpecification);
	const [disclaimer, setDisclaimer] = useState(startDisclaimer);
	const [startKeywords, setStartKeywords] = useState(keywords || []);
	const [isDropdown, setIsDropDown] = useState(startIsDropdown || false);

	const imageArraySize = useMemo(
		() => (imageData?.length > IMAGES_IN_MODULE ? imageData?.length : IMAGES_IN_MODULE),
		[imageData]
	);

	const defaultImages = useMemo(
		() =>
			[...Array(imageArraySize).keys()].map(i => {
				const initialData = { ...initialImage, displayOrder: i + 1 };
				const finderImage = imageData?.find(img => +img.displayOrder === +initialData.displayOrder);

				const image = {
					...initialData,
					...finderImage,
					textOverlayPosition: finderImage?.textOverlayPosition || initialData.textOverlayPosition,
					captionPosition: finderImage?.captionPosition || initialData.captionPosition,
				};

				return image;
			}),
		[imageArraySize, imageData, initialImage]
	);

	const [imageGallery, setImageGallery] = useState(defaultImages);

	useEffect(() => {
		setImageGallery(defaultImages);
	}, [setImageGallery, defaultImages]);

	const handleSaveImage = payload => {
		clearPageCache();
		setImageGallery(prevArray => {
			const newArray = [
				...prevArray.slice(0, +payload.displayOrder - 1),
				payload,
				...prevArray.slice(+payload.displayOrder),
			];
			return newArray;
		});
	};

	const handleDeleteImage = payload => {
		clearPageCache();
		setImageGallery(prevArray => {
			return prevArray.map(item => {
				if (+item.displayOrder === +payload.displayOrder) {
					return { ...initialImage, displayOrder: payload.displayOrder };
				}

				return item;
			});
		});
	};

	const renderTitleComponent = () => (
		<Wrapper>
			<EditableTitleWrapper
				fieldName={TITLE_FIELD}
				placeholder={translations.INSERT_TITLE_HERE}
				content={title}
				setContent={setTitle}
				startKeywords={startKeywords}
				setStartKeywords={setStartKeywords}
				modulePath={path}
				isCollapsable={isDropdown}
				setIsCollapsable={setIsDropDown}
				data-testid={EDITABLE_TITLE_TEST_ID}
				{...props}
			/>
		</Wrapper>
	);

	const renderSpecificationComponent = () =>
		isOnPrintPage ? (
			<S.SpecificationOnPrintPage>
				<SpecificationLabel>{translations.SPECIFICATIONS_TEXT}</SpecificationLabel>
				<EditableContentWrapper
					style={{ margin: '10px' }}
					fieldName={SPECIFICATION_FIELD}
					placeholder={translations.INSERT_SPECIFICATION_HERE}
					content={specification}
					setContent={setSpecification}
					modulePath={path}
					data-testid={EDITABLE_SPECIFICATION_TEST_ID}
					{...props}
				/>
			</S.SpecificationOnPrintPage>
		) : (
			<SpecificationDropDown
				content={specification}
				setContent={setSpecification}
				modulePath={path}
				data-testid={EDITABLE_DROPDOWN_SPECIFICATION_TEST_ID}
				{...props}
			/>
		);

	const renderContentComponent = () => (
		<>
			<EditableContentWrapper
				style={{ marginBottom: '15px', height: 'fit-content' }}
				fieldName={DESCRIPTION_FIELD}
				placeholder={translations.INSERT_DESCRIPTION_HERE}
				content={description}
				setContent={setDescription}
				modulePath={path}
				data-testid={EDITABLE_DESCRIPTION_TEST_ID}
				{...props}
			/>
			{(selectedToEdit === EDIT_MENU_TYPES.EDIT_INLINE || specification) && renderSpecificationComponent()}

			<S.Box id={`${path}-gallery`}>
				{imageGallery.map(item => (
					<ImageBox
						key={`${item.displayOrder}${item.imageVcmId}`}
						imageProps={item}
						imageStyle={{
							margin: '0px 0px 10px',
							width: '100%',
							height: '100%',
						}}
						onSave={handleSaveImage}
						onDelete={handleDeleteImage}
						isOnPrintPage={isOnPrintPage}
						{...props}
					/>
				))}
			</S.Box>
			<ListFiles {...props} />
			{disclaimer && (
				<DisclaimerEditInLine disclaimer={disclaimer} setDisclaimer={setDisclaimer} modulePath={path} {...props} />
			)}
		</>
	);

	const renderTitleWithContentComponent = () => (
		<>
			{renderTitleComponent()}
			{renderContentComponent()}
		</>
	);

	const renderDropDownContentComponent = () => (
		<Collapse
			label={renderTitleComponent()}
			iconClickOnly={selectedToEdit === EDIT_MENU_TYPES.EDIT_INLINE}
			icon="icon-chevron-down"
			wrapperStyle={{
				padding: 0,
				color: color.text.secondary02,
				display: 'flex',
				alignItems: 'baseline',
				justifyContent: 'space-between',
				width: '100%',
				...typo.headline['02'],
			}}
		>
			{renderContentComponent()}
		</Collapse>
	);

	return (
		<div role={FULL_WIDTH_TYPE_PLUS_GALLERY_MODULE}>
			{isDropdown ? renderDropDownContentComponent() : renderTitleWithContentComponent()}
		</div>
	);
};

FullL.propTypes = {
	startTitle: PropTypes.string,
	startDescription: PropTypes.string,
	startSpecification: PropTypes.string,
	startDisclaimer: PropTypes.string,
	keywords: PropTypes.arrayOf(PropTypes.string),
	path: PropTypes.string.isRequired,

	startIsDropdown: PropTypes.bool,
	isOnPrintPage: PropTypes.bool,
	imageData: arrayOf(
		shape({
			imagePath: PropTypes.string,
			textOverlay: PropTypes.string,
			textOverlayPosition: PropTypes.string,
			imageTitle: PropTypes.string,
		})
	),
};

FullL.defaultProps = {
	startTitle: '',
	startDescription: '',
	startSpecification: '',
	startDisclaimer: '',
	keywords: [],
	startIsDropdown: false,
	isOnPrintPage: false,
	imageData: null,
};

export { FullL };
