import { translations } from 'translation/en';

import { useAuthAccess } from 'hooks/useAuthData';
import * as S from './NoPermissionMessage.style';
import { NO_PERMISSION_MESSAGE_TEST_ID, NO_PERMISSION_TITLE_TEST_ID } from './constants';

const NoPermissionMessage = () => {
	const { isFranchise, isEmployee } = useAuthAccess();

	let message = translations.PERMISSION_DENIED_MESSAGE;
	if (isFranchise) {
		message = translations.FRANCHISEE_PERMISSION_DENIED_MESSAGE;
	} else if (isEmployee) {
		message = translations.EMPLOYEE_PERMISSION_DENIED_MESSAGE;
	}
	return (
		<S.NoPermissionMessage>
			<S.StyledTitle data-testid={NO_PERMISSION_TITLE_TEST_ID}>{translations.NO_PERMISSION_TITLE}</S.StyledTitle>
			<S.StyledMessage data-testid={NO_PERMISSION_MESSAGE_TEST_ID}>{message}</S.StyledMessage>
		</S.NoPermissionMessage>
	);
};

export { NoPermissionMessage };
