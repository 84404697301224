import styled from 'styled-components/macro';
import { Modal } from 'components/modals/modal';

export const PublishBrandModal = styled(Modal)(({ theme: { color } }) => ({
	width: '85%',
	maxWidth: '500px',
	transform: 'translate(0, 0)',
	transition: 'transform .3s ease-out',
	borderRadius: 0,
	border: `3px solid ${color.neutral['50']}`,

	'> *': {
		borderTop: 'none',
	},
}));

export const Title = styled.h5(({ theme: { color, typo } }) => ({
	margin: 0,
	padding: 0,
	textAlign: 'center',
	color: color.text.approveModalTitle,
	...typo.headline['08'],
}));

export const Description = styled.div(({ theme: { color, typo } }) => ({
	textAlign: 'center',
	color: color.text.approveModalDescription,
	...typo.caption['03'],
}));
