import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/button';
import { useOnOutsideClick } from 'hooks/useOnOutsideClick';
import useToggle from 'hooks/useToggle';
import { childrenWithProps } from 'utils/childrenUtil';
import { DropDownItem } from './dropDownItem';
import * as S from './DropDown.style';

const DropDown = forwardRef(
	({ label, icon, children, inverted, wrapperStyle, iconOutlined, closeOnOutsideClick, iconClickOnly }, ref) => {
		const dropdownRef = useRef();
		const [show, setToggle] = useToggle();

		const handleOpen = useCallback(
			e => {
				e.stopPropagation();
				setToggle();
			},
			[setToggle]
		);

		const handleClose = useCallback(
			e => {
				e?.stopPropagation();
				if (show) {
					setToggle();
				}
			},
			[show, setToggle]
		);

		useOnOutsideClick({ ref: dropdownRef, action: closeOnOutsideClick ? handleClose : () => {} });

		useImperativeHandle(
			ref,
			() => {
				return {
					handleClose,
				};
			},
			[handleClose]
		);

		const renderLabelComponent = () =>
			label && (
				<S.Wrapper inverted={inverted} style={wrapperStyle} role="button" tabIndex={0}>
					{label}
					<div onClick={iconClickOnly ? handleOpen : null} role="button" aria-label="open dropdown" tabIndex={0}>
						{typeof icon === 'string' ? <S.Icon className={`icon ${icon}`} isExpanded={show} /> : icon}
					</div>
				</S.Wrapper>
			);

		const renderIconComponent = () =>
			!label &&
			icon && (
				<Button onClick={iconClickOnly ? handleOpen : null} variant={iconOutlined ? 'outlined' : 'icon'} icon={icon} />
			);

		return (
			<S.DropDown>
				<S.Container ref={dropdownRef}>
					<S.Head onClick={!iconClickOnly ? handleOpen : null}>
						{renderLabelComponent()}
						{renderIconComponent()}
					</S.Head>
					<S.Body className={show ? 'expanded' : ''}>{childrenWithProps(children, { handleClose })}</S.Body>
				</S.Container>
			</S.DropDown>
		);
	}
);

DropDown.propTypes = {
	label: PropTypes.node,
	icon: PropTypes.node || PropTypes.string,
	wrapperStyle: PropTypes.objectOf(Object),
	inverted: PropTypes.bool,
	closeOnOutsideClick: PropTypes.bool,
	iconOutlined: PropTypes.bool,
	iconClickOnly: PropTypes.bool,
	children: PropTypes.node.isRequired,
};

DropDown.defaultProps = {
	label: '',
	wrapperStyle: null,
	inverted: false,
	closeOnOutsideClick: false,
	iconOutlined: false,
	iconClickOnly: false,
	icon: null,
};

DropDown.DropDownItem = DropDownItem;

export { DropDown };
