import classNames from 'classnames';
import { string } from 'prop-types';
import React from 'react';
import Select from 'react-select';
import { FormFieldLabelAnalytics } from '../formFieldLabelAnalytics/FormFieldLabelAnalytics';
import './FormFieldSelectAnalytics.scss';

export const FormFieldSelectAnalytics = ({ className, label, ...props }) => {
	return (
		<div className="FormFieldSelectAnalytics">
			<FormFieldLabelAnalytics label={label} />

			<Select
				className={classNames(className)}
				classNamePrefix="FormFieldSelectAnalytics"
				placeholder="Select..."
				noOptionsMessage={() => 'No available options'}
				components={{
					IndicatorSeparator: () => null,
				}}
				{...props}
			/>
		</div>
	);
};

FormFieldSelectAnalytics.propTypes = {
	className: string,
	label: string.isRequired,
};

FormFieldSelectAnalytics.defaultProps = {
	className: '',
};
