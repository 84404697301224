import { ReactComponent as ArrowUpDown } from 'assets/img/arrows/arrow-up-down.svg';
import { FILE_TYPES } from 'components/addEditFiles/constants/addEditFilesConstants';
import { RemoveModal } from 'components/removeModal';
import PropTypes from 'prop-types';
import { useReducer } from 'react';
import { translations } from 'translation/en';
import { replaceArgs } from 'utils/stringUtil';
import { FileModal } from '../FileModal';
import * as S from './FileMenu.style';

const initialState = {
	type: FILE_TYPES.RESET,
	payload: null,
};

const reducer = (state, action) => {
	switch (action.type) {
		case FILE_TYPES.FILE_MODAL:
			return { ...state, type: FILE_TYPES.FILE_MODAL };
		case FILE_TYPES.FILE_MOVE:
			return { ...state, type: FILE_TYPES.FILE_MOVE };
		case FILE_TYPES.DELETE_MODAL:
			return { ...state, type: FILE_TYPES.DELETE_MODAL };
		case FILE_TYPES.CLOSE_MODAL:
			return { ...state, type: FILE_TYPES.CLOSE_MODAL };
		default:
			return state;
	}
};

const FileMenu = ({
	show,
	file,
	selectedFile,
	children,
	paragraph,
	onEditFile,
	onDeleteFile,
	onSelectedFile,
	onMoveUp,
	onMoveDown,
}) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	const isSelectedFile = state.type === FILE_TYPES.FILE_MOVE && selectedFile.vcmId === file.vcmId;

	const onOpenFileModal = () => {
		dispatch({
			type: FILE_TYPES.FILE_MODAL,
		});
	};

	const onOpenRemoveModal = () => {
		dispatch({
			type: FILE_TYPES.DELETE_MODAL,
		});
	};

	const onMove = payload => {
		onSelectedFile(payload);
		dispatch({
			type: FILE_TYPES.FILE_MOVE,
		});
	};

	const onClose = () => {
		dispatch({
			type: FILE_TYPES.CLOSE_MODAL,
		});
	};

	const onSubmitDelete = () => {
		onClose();
		onDeleteFile(file);
	};

	return (
		<div>
			{show ? (
				<S.Container>
					{isSelectedFile && (
						<S.MoveOptions>
							<S.MoveIconButton onClick={() => onMoveUp(selectedFile)}>
								<i className="icon icon-chevron-up" />
							</S.MoveIconButton>

							<S.MoveIconButton onClick={() => onMoveDown(selectedFile)}>
								<i className="icon icon-chevron-down" />
							</S.MoveIconButton>
						</S.MoveOptions>
					)}

					<div>{children}</div>

					<S.Options>
						<S.IconButton onClick={onOpenFileModal}>
							<i className="icon icon-edit" />
						</S.IconButton>

						<S.IconButton onClick={() => onMove(file)}>
							<ArrowUpDown style={{ width: '18px', height: '30px' }} alt="" />
						</S.IconButton>

						<S.IconButton aria-label="remove-action" onClick={onOpenRemoveModal}>
							<i className="icon icon-trash" />
						</S.IconButton>
					</S.Options>
				</S.Container>
			) : (
				<div>{children}</div>
			)}

			{state.type === FILE_TYPES.FILE_MODAL && (
				<FileModal open file={file} paragraph={paragraph} onSaveAsDraft={onEditFile} onClose={onClose} />
			)}

			{state.type === FILE_TYPES.DELETE_MODAL && (
				<RemoveModal
					open
					title={replaceArgs(translations.MODAL_REMOVE_BUTTON_MESSAGE, file.linkText)}
					onConfirm={onSubmitDelete}
					onClose={onClose}
				/>
			)}
		</div>
	);
};

FileMenu.propTypes = {
	show: PropTypes.bool,
	selectedFile: PropTypes.shape({
		vcmId: PropTypes.string,
		filePath: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(Object)]),
		linkText: PropTypes.string,
		displayOrder: PropTypes.number,
	}),
	file: PropTypes.shape({
		vcmId: PropTypes.string,
		filePath: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(Object)]),
		linkText: PropTypes.string,
		displayOrder: PropTypes.number,
	}),
	paragraph: PropTypes.shape({
		path: PropTypes.string,
	}),
	children: PropTypes.node,
	onEditFile: PropTypes.func.isRequired,
	onSelectedFile: PropTypes.func.isRequired,
	onDeleteFile: PropTypes.func.isRequired,
	onMoveUp: PropTypes.func.isRequired,
	onMoveDown: PropTypes.func.isRequired,
};

FileMenu.defaultProps = {
	show: false,
	selectedFile: {},
	file: {},
	paragraph: {
		path: '',
	},
	children: null,
};

export { FileMenu };
