import classNames from "classnames";
import { bool, element, elementType, func, oneOfType, shape, string } from "prop-types";
import { forwardRef } from "react";
import "./FormField.scss";
import FormFieldError from "./FormFieldError";
import FormFieldLabel from "./FormFieldLabel";
import "./FormFieldWithButtons.scss";

export const FormFieldWithButtons = forwardRef(
  (
    {
      hasError,
      label,
      title,
      errors,
      required,
      onPrimaryButtonClick,
      onSecondaryButtonClick,
      primaryButtonChildren,
      secondaryButtonChildren,
      primaryButtonDisabled,
      ...props
    },
    ref
  ) => {
    return (
      <div className="FormFieldWithButtons">
        {label && <FormFieldLabel label={label} required={required} title={title} />}
        <div className="FormFieldWithButtons__input-container">
          <input
            className={classNames("FormField", props?.className, {
              "FormField--has-error": hasError,
              "FormField--no-right-border": onPrimaryButtonClick,
            })}
            {...props}
            ref={ref}
          />
          {onPrimaryButtonClick && (
            <button
              className={classNames("FormFieldWithButtons__primary-button", {
                "FormFieldWithButtons__primary-button--no-right-border":
                  onSecondaryButtonClick,
              })}
              onClick={onPrimaryButtonClick}
              disabled={primaryButtonDisabled}
            >
              {primaryButtonChildren}
            </button>
          )}
          {onSecondaryButtonClick && (
            <button
              className="FormFieldWithButtons__secondary-button"
              onClick={onSecondaryButtonClick}
            >
              {secondaryButtonChildren}
            </button>
          )}
        </div>
        {hasError && <FormFieldError message={errors?.message} />}
      </div>
    );
  }
);

FormFieldWithButtons.propTypes = {
  hasError: bool,
  label: string,
  title: string,
  errors: shape({}),
  onPrimaryButtonClick: func,
  onSecondaryButtonClick: func,
  primaryButtonChildren: oneOfType([string, element, elementType]),
  secondaryButtonChildren: oneOfType([string, element, elementType]),
  primaryButtonDisabled: bool,
};

FormFieldWithButtons.defaultProps = {
  onPrimaryButtonClick: null,
  onSecondaryButtonClick: null,
  primaryButtonChildren: "Save",
  secondaryButtonChildren: "Cancel",
  hasError: false,
  label: null,
  title: "",
  errors: null,
  primaryButtonDisabled: false,
};
