import { useParams } from 'react-router';
import routers from 'router/router';
import { findBrandByBrandId, isBrandIdValid } from 'utils/mappedBrandsUtil';

export const useRedirectByBrandID = () => {
	const { brandId, contentPath } = useParams();

	if (!brandId || !isBrandIdValid(brandId) || !contentPath) return routers.brandsROUTE.getRouteDefaultPath();

	const { routePath } = findBrandByBrandId(brandId);

	return `${routers.brandROUTE.getRouteDefaultPath()}/${routePath}/${contentPath}`;
};
