/* eslint-disable max-len */
import axios from 'API/axios/axios';
import {
	AD_DEPARTMENTS,
	AD_GROUPS,
	ARTICLE_CONTENT,
	ARTICLE_JOBLEVEL,
	BEG_ARTICLE_TITLE,
	BEG_CHAPTER_TITLE,
	BEG_SECTION_TITLE,
	BRANDS,
	CHAPTER_CONTENT,
	CHAPTER_JOBLEVEL,
	CHAPTER_PATH,
	CQ_MODEL,
	CQ_NAME,
	CQ_PARENT_PATH,
	FILE_LINK,
	FILE_LINK_TEXT,
	GLOBAL_BRAND_NO_HTML,
	GROUP_KEY,
	OPERATION,
	PAGE_PATH,
	PARENT_CONTENT_PATH,
	PARENT_PATH,
	PARENT_ROW_ID,
	PARENT_VCMID,
	PATH_,
	QUICKSHEET_PATH,
	REGION,
	REGIONS,
	SECTION_CONTENT,
	SECTION_JOBLEVEL,
	SERVICE_ORDER,
	SUBTOPIC,
	TITLE,
	TOPIC,
	VCMID,
	VISIBLE_TO_FRANCHISE,
} from 'constants/solrConstants';
import { getBrandResourcesSearchParams, getSolrParamsToSearchByVcmIds } from 'utils/solrSearchUtils';
import { generateSlug } from 'utils/stringUtil';

export const getSolrResult = params => {
	const url = process.env.REACT_APP_SOLR_URL;
	return axios.get(url, {
		params: getBrandResourcesSearchParams(params),
	});
};

export const solrSearchByVcmId = vcmIds => {
	const url = process.env.REACT_APP_SOLR_URL;
	return axios.get(url, {
		params: getSolrParamsToSearchByVcmIds(vcmIds),
	});
};

export const fromSolrService = data => {
	return {
		path: data?.[PATH_]?.[0],
		parentPath: data?.[CQ_PARENT_PATH]?.[0] || data?.[PARENT_PATH]?.[0],
		solrId: data?.id,
		vcmId: generateSlug(data?.[GROUP_KEY]?.[0]) || data?.[VCMID]?.[0],
		jobLevel: data?.[CHAPTER_JOBLEVEL]?.[0] || data?.[SECTION_JOBLEVEL]?.[0] || data?.[ARTICLE_JOBLEVEL]?.[0],
		title:
			data?.[TITLE]?.[0] ||
			data?.[BEG_CHAPTER_TITLE]?.[0] ||
			data?.[BEG_SECTION_TITLE]?.[0] ||
			data?.[BEG_ARTICLE_TITLE]?.[0],
		relatorsPath: data?.[CHAPTER_CONTENT] || data?.[SECTION_CONTENT] || data?.[ARTICLE_CONTENT] || [],
		brands: data?.[BRANDS]?.[0],
		regions: data?.[REGIONS] || data?.[REGION],
		isVisibleToFranchise: data?.[VISIBLE_TO_FRANCHISE]?.[0],
		cqModel: data?.[CQ_MODEL]?.[0],
		cqName: data?.[CQ_NAME]?.[0],
		score: data?.score,
		groups: data?.[AD_GROUPS],
		departments: data?.[AD_DEPARTMENTS],
		parentContentPath: data?.[PARENT_CONTENT_PATH],
		linkText: data?.[FILE_LINK_TEXT]?.[0] || '',
		filePath: data?.[FILE_LINK]?.[0],
		parentVcmid: data?.[PARENT_VCMID]?.[0],
		// Related to quicksheet
		operation: data?.[OPERATION]?.[0],
		topic: data?.[TOPIC]?.[0],
		subTopic: data?.[SUBTOPIC]?.[0],
		globalBrand: data?.[GLOBAL_BRAND_NO_HTML]?.[0],
		parentRowId: data?.[PARENT_ROW_ID]?.[0],
		// the only content that needs to be filtered by this attribute is the quicksheet, hence, if the content is not a quicksheet, the value will be 0
		serviceOrder: data?.[SERVICE_ORDER]?.[0] || 0,
		quicksheetPath: data?.[QUICKSHEET_PATH]?.[0],
		chapterPath: data?.[CHAPTER_PATH]?.[0],
		pagePath: data?.[PAGE_PATH],
	};
};
