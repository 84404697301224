import { Modal } from 'components/modals/modal';
import styled from 'styled-components/macro';

export const DiscardChangesModal = styled(Modal)(() => ({
	top: '20%',
}));

export const ModalLabel = styled.h3(({ theme: { color, typo } }) => ({
	margin: '25px 0',
	textAlign: 'center',
	color: color.text.secondary02,
	...typo.lead['04'],
}));
