import { useState } from 'react';
import { arrayOf, bool, string } from 'prop-types';
import { useTheme } from 'styled-components';
import { EDIT_MENU_TYPES } from 'components/begEditMenu/constants/begEditMenuConstants';
import {
	EditableContentWrapper,
	EditableTitleWrapper,
} from 'containers/brandDetails/components/brandMain/components/editableContent';
import { SpecificationDropDown } from 'containers/brandDetails/components/brandMain/components/specificationDropDown';
import { Wrapper } from 'containers/brandDetails/components/brandMain/components/template/ModuleTemplate.style';
import {
	DESCRIPTION_FIELD,
	EDITABLE_DESCRIPTION_TEST_ID,
	EDITABLE_DROPDOWN_SPECIFICATION_TEST_ID,
	EDITABLE_SPECIFICATION_TEST_ID,
	EDITABLE_TITLE_TEST_ID,
	SPECIFICATION_FIELD,
	TITLE_FIELD,
	TYPE_MODULE_TEXT,
} from 'containers/brandDetails/constants/modulesTypesConstants';
import { useEditMenuData } from 'hooks/useEditMenuData';
import { translations } from 'translation/en';
import { Collapse } from 'containers/brandDetails/components/brandMain/components/collapse';
import { ListFiles } from '../../listFiles';
import { DisclaimerEditInLine } from '../disclaimerEditInLine';
import { SpecificationLabel } from '../../specificationDropDown/SpecificationDropDown.style';
import * as S from './TextModule.style';

const TextModule = ({
	startTitle,
	startDescription,
	startSpecification,
	startDisclaimer,
	startIsDropdown,
	keywords,
	path,
	isOnPrintPage,
	...props
}) => {
	const { color, typo } = useTheme();
	const { selectedToEdit } = useEditMenuData();

	const [title, setTitle] = useState(startTitle || '');
	const [description, setDescription] = useState(startDescription || '');
	const [specification, setSpecification] = useState(startSpecification);
	const [disclaimer, setDisclaimer] = useState(startDisclaimer);
	const [isDropdown, setIsDropDown] = useState(startIsDropdown || false);
	const [startKeywords, setStartKeywords] = useState(keywords || []);

	const renderTitleComponent = () => (
		<Wrapper>
			<EditableTitleWrapper
				fieldName={TITLE_FIELD}
				placeholder={translations.INSERT_TITLE_HERE}
				content={title}
				setContent={setTitle}
				startKeywords={startKeywords}
				setStartKeywords={setStartKeywords}
				modulePath={path}
				isCollapsable={isDropdown}
				setIsCollapsable={setIsDropDown}
				data-testid={EDITABLE_TITLE_TEST_ID}
				{...props}
			/>
		</Wrapper>
	);

	const renderSpecificationComponent = () =>
		isOnPrintPage ? (
			<S.SpecificationOnPrintPage>
				<SpecificationLabel>{translations.SPECIFICATIONS_TEXT}</SpecificationLabel>
				<EditableContentWrapper
					style={{ margin: '10px' }}
					fieldName={SPECIFICATION_FIELD}
					placeholder={translations.INSERT_SPECIFICATION_HERE}
					content={specification}
					setContent={setSpecification}
					modulePath={path}
					data-testid={EDITABLE_SPECIFICATION_TEST_ID}
					{...props}
				/>
			</S.SpecificationOnPrintPage>
		) : (
			<SpecificationDropDown
				content={specification}
				setContent={setSpecification}
				modulePath={path}
				data-testid={EDITABLE_DROPDOWN_SPECIFICATION_TEST_ID}
				{...props}
			/>
		);

	const renderContentComponent = () => (
		<>
			<EditableContentWrapper
				style={{ marginBottom: '15px' }}
				fieldName={DESCRIPTION_FIELD}
				placeholder={translations.INSERT_DESCRIPTION_HERE}
				content={description}
				setContent={setDescription}
				modulePath={path}
				data-testid={EDITABLE_DESCRIPTION_TEST_ID}
				{...props}
			/>

			{(selectedToEdit === EDIT_MENU_TYPES.EDIT_INLINE || specification) && renderSpecificationComponent()}

			<ListFiles {...props} />

			{disclaimer && (
				<DisclaimerEditInLine disclaimer={disclaimer} setDisclaimer={setDisclaimer} modulePath={path} {...props} />
			)}
		</>
	);

	const renderTitleWithContentComponent = () => (
		<>
			{renderTitleComponent()}
			{renderContentComponent()}
		</>
	);

	const renderDropDownContentComponent = () => (
		<Collapse
			label={renderTitleComponent()}
			iconClickOnly={selectedToEdit === EDIT_MENU_TYPES.EDIT_INLINE}
			icon="icon-chevron-down"
			wrapperStyle={{
				padding: 0,
				color: color.text.secondary02,
				display: 'flex',
				alignItems: 'baseline',
				justifyContent: 'space-between',
				width: '100%',
				...typo.headline['02'],
			}}
		>
			{renderContentComponent()}
		</Collapse>
	);

	return <div role={TYPE_MODULE_TEXT}>{isDropdown ? renderDropDownContentComponent() : renderTitleWithContentComponent()}</div>;
};

TextModule.propTypes = {
	startTitle: string,
	path: string.isRequired,
	paragraphId: string.isRequired,
	startDescription: string,
	startSpecification: string,
	startDisclaimer: string,
	keywords: arrayOf(string),
	startIsDropdown: bool,
	isOnPrintPage: bool,
};

TextModule.defaultProps = {
	startTitle: '',
	startDescription: '',
	startSpecification: '',
	startDisclaimer: '',
	keywords: [],
	startIsDropdown: false,
	isOnPrintPage: false,
};

export { TextModule };
