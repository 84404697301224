import { TabWrapper } from '@hyatt/tab-wrapper';
import styled from 'styled-components/macro';

export const ThemedTabs = styled(TabWrapper)(({ theme: { color } }) => ({
	'li:not([class*="react-tabs__tab--selected"])': {
		color: `${color.link.primary.default.label} !important`,
		backgroundColor: `${color.background.secondary} !important`,
		border: '1px solid transparent',
	},
}));
