import { MODELS_PATHS } from 'constants/aemConstants';
import { PAGE_TYPES } from 'constants/pageConstants';
import { getRelationships } from 'services/relationshipService';
import { translations } from 'translation/en';
import { showToast } from './toastUtil';
import { logError } from './logUtil';
import { getName } from './contentFragmentUtil';

export const getRelationsByPathAndType = async ({ path, type }, fetchedPaths = new Set()) => {
	const result = [];

	if (!path || fetchedPaths.has(path)) {
		return result;
	}

	fetchedPaths.add(path);

	let relationshipServiceResult;

	try {
		relationshipServiceResult = await getRelationships(path);
	} catch {
		logError(translations.ERROR_FETCHING_RELATIONSHIP);
		showToast('error', translations.ERROR_FETCHING_RELATIONSHIP);
	}

	const data = relationshipServiceResult?.sharedContents.map(sharedContentInfo => sharedContentInfo.data) || [];

	await Promise.all(
		data.map(async ({ path: sharedContentPath, cq_model: cqModel }) => {
			const isChapter = cqModel === MODELS_PATHS.CHAPTER;
			const isSection = cqModel === MODELS_PATHS.SECTION;
			if (isChapter) return Promise.resolve([]);

			const isNotChapterRelated =
				type === PAGE_TYPES.CHAPTER_TYPE &&
				(cqModel === MODELS_PATHS.RELATOR_SECTIONS || cqModel === MODELS_PATHS.RELATOR_ARTICLES);

			if (isNotChapterRelated) return Promise.resolve([]);

			const isNotSectionRelated = type === PAGE_TYPES.SECTION_TYPE && cqModel === MODELS_PATHS.RELATOR_ARTICLES;
			if (isNotSectionRelated) return Promise.resolve([]);

			const isNotArticleRelated =
				type === PAGE_TYPES.ARTICLE_TYPE && (cqModel === MODELS_PATHS.RELATOR_SECTIONS || isSection);
			if (isNotArticleRelated) return Promise.resolve([]);

			if (cqModel === MODELS_PATHS.RELATOR_ALLOWED_DEPARTMENTS) return Promise.resolve([]);
			if (cqModel === MODELS_PATHS.SITE_AD_GROUP) return Promise.resolve([]);

			result.push(sharedContentPath);

			if (!cqModel) return Promise.resolve([]);

			const promiseToReturn = getRelationsByPathAndType({ path: sharedContentPath, type: getName(cqModel) }, fetchedPaths);
			const childrenSharedContent = await promiseToReturn;

			if (childrenSharedContent.length > 0) {
				result.push(...childrenSharedContent);
			}

			return promiseToReturn;
		})
	);

	return result;
};
