import React, { useEffect, useMemo } from 'react';
import { ModulesSwitch } from 'containers/brandDetails/components/brandMain/components/modules/ModulesSwitch';
import { PrintPageTemplate } from 'containers/printPageTemplate';
import { useParagraphsBySection } from 'hooks/useParagraphsBySection';
import { useQueryParams } from 'hooks/useQueryParams';
import { useRowsPrintByQuickSheet } from 'hooks/useRowsPrintByQuickSheet';
import { PrintPageSkeleton } from 'components/loaders/printPageSkeleton';
import { isFirstParagraph, hasParagraph } from 'utils/paragraphUtil';
import * as S from './SectionPrintPage.style';

const SectionPrintPage = () => {
	const { getQueryParam } = useQueryParams();
	const { loadingParagraphs, paragraphs: paragraphsData, fetchParagraphsBySection } = useParagraphsBySection();
	const { loadingRows, rowsSection, fetchRows } = useRowsPrintByQuickSheet();

	const path = getQueryParam('path');
	const quickSheetPath = getQueryParam('quicksheet-path');
	const quickSheetOperation = getQueryParam('quicksheet-operation');

	useEffect(() => {
		if (path) {
			fetchParagraphsBySection({ path });
		}
	}, [path, fetchParagraphsBySection]);

	useEffect(() => {
		if (quickSheetPath) {
			fetchRows({
				path: quickSheetPath,
				operation: quickSheetOperation,
			});
		}
	}, [quickSheetPath, quickSheetOperation, fetchRows]);

	const paragraphs = useMemo(() => {
		if (rowsSection) {
			return [rowsSection];
		}

		return paragraphsData;
	}, [paragraphsData, rowsSection]);

	if ([loadingParagraphs, loadingRows].some(Boolean)) {
		return <PrintPageSkeleton />;
	}

	return (
		<PrintPageTemplate
			content={{
				sections: [
					{
						paragraphs: paragraphs?.[0]?.sections?.[0]?.paragraphs,
						sectionTitle: paragraphs?.[0]?.sections?.[0]?.sectionTitle,
						articles: paragraphs?.[0]?.sections?.[0]?.articles,
					},
				],
			}}
			title={paragraphs?.[0]?.sections?.[0]?.sectionTitle}
		>
			<S.SectionPrintPageWrapper>
				{paragraphs?.[0]?.sections?.[0]?.paragraphs
					?.sort((a, b) => +a.displayOrder - +b.displayOrder)
					?.map((paragraph, index) => {
						return (
							<ModulesSwitch
								paragraph={paragraph}
								key={paragraph.vcmId}
								id={paragraph.vcmId}
								paragraphId={paragraph.vcmId}
								type={paragraph.type}
								startTitle={paragraph.title}
								startDescription={paragraph.body}
								startSpecification={paragraph.specification}
								path={paragraph.path}
								keywords={paragraph.keywords}
								orderValue={index + 2}
								imageData={paragraph.imageData}
								startIsDropdown={false}
								files={paragraph.files}
								isFirst={isFirstParagraph(paragraphs?.[0]?.sections?.[0]?.paragraphs, paragraph)}
								isLast={false}
								isOnPrintPage
							/>
						);
					})}
				{paragraphs?.[0]?.sections?.[0]?.articles
					?.sort((a, b) => +a.articleDisplayOrder - +b.articleDisplayOrder)
					?.map(article => {
						return (
							<React.Fragment key={article?.articleTitle}>
								{hasParagraph(article) ||
									(!rowsSection && (
										<S.ContainerArticle>
											<S.Title>{article?.articleTitle}</S.Title>
											{article.paragraphs
												?.sort((a, b) => +a.displayOrder - +b.displayOrder)
												?.map((paragraph, index) => {
													return (
														<ModulesSwitch
															paragraph={paragraph}
															key={paragraph.vcmId}
															id={paragraph.vcmId}
															paragraphId={paragraph.vcmId}
															type={paragraph.type}
															startTitle={paragraph.title}
															startDescription={paragraph.body}
															startSpecification={paragraph.specification}
															path={paragraph.path}
															keywords={paragraph.keywords}
															orderValue={index + 2}
															imageData={paragraph.imageData}
															startIsDropdown={false}
															isFirst={isFirstParagraph(article.paragraphs, paragraph)}
															isLast={false}
															files={paragraph.files}
															isOnPrintPage
														/>
													);
												})}
										</S.ContainerArticle>
									))}
							</React.Fragment>
						);
					})}
			</S.SectionPrintPageWrapper>
		</PrintPageTemplate>
	);
};

export { SectionPrintPage };
