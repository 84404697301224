import styled from 'styled-components/macro';

export const SubTitle = styled.h2(({ theme: { color, typo } }) => ({
	flexWrap: 'nowrap',
	fontSize: '19px',
	padding: '15px 0 0 15px',
	wordWrap: 'break-word',
	margin: 0,
	lineHeight: 1.1,

	color: color.text.senary,
	...typo.headline['02'],
}));
