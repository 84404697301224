import { useMemo } from 'react';
import routers from 'router/router';
import { useCurrentRouteInstance } from './useCurrentRouteInstance';

export const useCurrentRoute = () => {
	const routeInstance = useCurrentRouteInstance();

	const isOnDefaultRoute = useMemo(() => {
		return routeInstance?.defaultPath === routers.brandsROUTE.getRouteDefaultPath();
	}, [routeInstance]);

	return {
		isOnDefaultRoute,
	};
};
