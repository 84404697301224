import complementaryTheme from 'themes/complementary/complementary';

const { neutral } = complementaryTheme;

const colors = {
	background: {
		banner: {
			background: '#00a1af', // header
		},
		secondary: '#dae0e1',
		tertiary: '#f7f7f7',
	},
	interactive: {
		primary: {
			default: {
				background: '#00a1af',
				label: '#fff',
				border: '#00a1af',
			},
			hover: {
				background: '#fff',
				label: '#00a1af',
				border: '#00a1af',
			},
		},
		primary02: {
			default: {
				background: '#383a35',
				label: '#fff',
				border: '#383a35',
			},
		},
		// file button
		primary05: {
			default: {
				background: '#00a1af',
				label: '#fff',
				border: '#00a1af',
			},
			hover: {
				background: '#fff',
				label: '#00a1af',
				border: '#00a1af',
			},
		},
		secondary: {
			default: {
				background: '#F2F3F4',
				label: '#0072ce',
				border: '#0072ce',
			},
			hover: {
				background: '#F2F3F4',
				label: '#0072ce',
				border: '#0072ce',
			},
		},
		// sign out button
		secondary02: {
			default: {
				background: '#DAE0E1',
				label: '#383A35',
			},
			hover: {
				background: '#F2F3F4',
				label: '#383A35',
			},
		},
		// button approve modal
		secondary03: {
			default: {
				background: '#333333',
				label: '#fff',
				border: '#333333',
			},
			hover: {
				background: '#fff',
				label: '#333333',
				border: '#333333',
			},
		},
		tertiary: {
			default: {
				background: '#fff',
				label: '#00A1AF',
			},
		},
		printModalButtons: {
			default: {
				background: '#00a1af',
				label: '#ffffff',
				border: '#00a1af',
			},
			hover: {
				background: '#ffffff',
				label: '#00a1af',
				border: '#00a1af',
			},
		},
		// properties and edit image modals buttons
		tertiary03: {
			default: {
				background: '#fff',
				label: '#181818',
				border: '#ccc',
			},
			hover: {
				background: '#fff',
				label: '#181818',
				border: '#181818',
			},
		},
		outlined: {
			default: {
				label: '#00657f',
				icon: '#00657f',
				border: '#00657f',
			},
		},
		inverse: {
			default: {
				label: '#fff',
			},
		},
		discardChangesButtons: {
			default: {
				background: '#00a1af',
				label: '#fff',
				border: '#00a1af',
			},
			hover: {
				background: '#F2F3F4',
				label: '#00a1af',
				border: '#00a1af',
			},
		},
		printPageButtons: {
			default: {
				background: '#0072ce',
				label: '#fff',
				border: '#0072ce',
			},
			hover: {
				background: '#F2F3F4',
				label: '#0072ce',
				border: '#0072ce',
			},
		},
		dropDownButtons: {
			default: {
				label: '#ffffff',
			},
			active: {
				color: '#00000020',
				label: '#262626',
			},
			focusWithin: {
				background: 'transparent',
				label: '#ffffff',
				border: 'none',
			},
			hover: {
				background: 'transparent',
				label: '#ffffff',
				border: 'none',
			},
		},

		lightest: 'rgba(255, 255, 255, 0.1)',
	},
	field: {
		// search input
		primary: {
			text: '#636466',
			placeholder: '#383a35',
		},
		// inputs edit image modal
		secondary: {
			text: '#676767',
			placeholder: '#0072ce',
		},
		// input properties modal
		tertiary: {
			text: '#676767',
			placeholder: '#0072ce',
		},
	},
	text: {
		primary: '#636466',
		primary02: '#00657f', // title chapter, section page

		titleModule: '#00657f',
		headline01: '#00657f',
		headline02: '#9a8b7d',
		headline03: '#00657f',
		headline04: '#383a35',
		headline05: '#00657f',
		headline06: '#383a35',

		paragraph: '#383a35',
		specificationTitle: '#00657f', // specification title

		approveModalTitle: '#666666',
		approveModalDescription: '#999999',

		addFilesSubtitle: '#999999',

		secondary: '#00657f', // terms and conditions title modal
		secondary02: '#383a35', // title discard changes
		secondary03: '#181818', // edit image modal title

		tertiary: '#383a35',
		tertiary03: '#181818', // title properties modal

		quaternary: '#00657f',
		quaternary02: '#333', // labels subtitle edit image modal
		quaternary03: '#000', // labels justification edit image modal

		quinary: '#383a35',
		quinary02: '#333', // subtitles properties modal
		quinary03: '#333', // title remove modal
		senary: '#9a8b7d', // title article page

		bullet: '#383a35', // list item bullet

		imageCaption: '#383a35',
	},
	link: {
		primary: {
			default: {
				label: '#383a35',
			},
			hover: {
				label: '#383a35',
			},
		},
		secondary: {
			default: {
				label: '#383a35',

				mobile: {
					label: '#383a35',
				},
			},
			hover: {
				background: 'rgba(255, 255, 255, 0.1)',
				label: '#383a35',
			},
			active: {
				label: '#383a35',

				mobile: {
					label: '#383a35',
				},
			},
		},
		tertiary: {
			default: {
				label: '#383a35',

				mobile: {
					label: '#383a35',
				},
			},
			hover: {
				background: 'rgba(255, 255, 255, 0.1)',
				label: '#383a35',

				mobile: {
					label: '#383a35',
				},
			},
			active: {
				label: '#383a35',

				mobile: {
					label: '#383a35',
				},
			},
		},
		quaternary: {
			default: {
				label: '#383a35',
			},
			hover: {
				label: '#383a35',
			},
			active: {
				label: '#00a1af',
			},
		},
	},
	icon: {
		primary: '#fff',
		primary02: '#383a35',
		chapterArrow: '#383a35', // chapter arrow icon
		sectionArrow: '#383a35', // section arrow icon
	},
	border: {
		primary: '#383a35',
		secondary: '#FFFFFF',
		tertiary: '#333',
		quaternary: '#9A8B7D',
	},
	shadow: {
		primary: 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(58, 56, 53, 0.6)',
		secondary: 'inset 0 1px 0 rgba(255, 255, 255, 0.1)',
	},
	neutral,
	table: {
		row: {
			background: {
				primary: '#f2f3f4',
				secondary: 'transparent',
			},
			cell: {
				label: '#333333',
				border: '2px solid #898b8e',
			},
		},
	},
};

export default colors;
