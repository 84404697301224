import complementaryTheme from 'themes/complementary/complementary';

const { size, weight, lineHeight, letter, transform, decoration, fontVariantNumeric } = complementaryTheme;

const createHeadline = baseTheme => ({
	headline: {
		'01': {
			fontFamily: baseTheme.font.family.darbySansMedium,
			fontWeight: weight.bold,
			fontSize: size['1.5xl'],
			lineHeight: lineHeight.normal,
			marginBottom: '20px !important',
			textTransform: 'uppercase',
			letterSpacing: letter.spacing['xl'],
			fontVariantNumeric: fontVariantNumeric.liningNums,
		},
		// title module
		'01.1': {
			fontFamily: baseTheme.font.family.darbySansMedium,
			fontSize: size['1lg'],
			letterSpacing: letter.spacing['lg'],
			fontVariantNumeric: fontVariantNumeric.liningNums,
			textTransform: transform.uppercase,
		},
		// h1
		'01.2': {
			fontFamily: baseTheme.font.family.darbySansMedium,
			fontSize: size['1.5xl'],
			fontWeight: weight.bold,
			lineHeight: lineHeight.normal,
			textTransform: 'uppercase',
			letterSpacing: letter.spacing['xl'],
			fontVariantNumeric: fontVariantNumeric.liningNums,
		},
		// title article page
		'02': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size['1.5xs'],
			textTransform: transform.uppercase,
			fontWeight: weight.bold,
			lineHeight: '1em',
			letterSpacing: letter.spacing['lg'],
		},
		// h2 content page
		'02.1': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size['1.5xs'],
			fontWeight: weight.medium,
			textTransform: transform.uppercase,
		},
		// h3 content page
		'03': {
			fontFamily: baseTheme.font.family.darbySansMedium,
			fontSize: size['1lg'],
			letterSpacing: letter.spacing['lg'],
			fontVariantNumeric: fontVariantNumeric.liningNums,
		},
		// h4 content page
		'04': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size['1.5xs'],
			fontWeight: weight.medium,
			lineHeight: lineHeight.normal,
			textTransform: transform.uppercase,
		},
		// specification dropdown title
		'04.1': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size['1.5xs'],
			fontWeight: weight.thin,
			lineHeight: lineHeight.normal,
			textTransform: transform.uppercase,
		},
		// h5 content page
		'05': {
			fontFamily: baseTheme.font.family.notoSansBold,
			fontSize: size['1.5xs'],
			textTransform: transform.uppercase,
		},
		// h6 content page
		'06': {
			fontFamily: baseTheme.font.family.notoSansBold,
			fontSize: size['2xs'],
			fontWeight: weight.medium,
			letterSpacing: '0px',
		},
		// Terms and Conditions Header
		'07': {
			fontFamily: baseTheme.font.family.darbySansMedium,
			fontWeight: weight.medium,
			lineHeight: lineHeight.normal,
			fontSize: size['5xl'],
			textTransform: 'uppercase',
			letterSpacing: letter.spacing['xl'],
		},
		// title approve modal
		'08': {
			fontFamily: baseTheme.font.family.yesevaOneRegular,
			fontWeight: weight.medium,
			fontSize: size.xl,
			lineHeight: lineHeight.normal,
		},
		// title properties modal
		'09': {
			fontFamily: baseTheme.font.family.yesevaOneRegular,
			fontWeight: weight.thin,
			fontSize: size['2.5xl'],
		},
		// title edit image modal
		10: {
			fontFamily: baseTheme.font.family.yesevaOneRegular,
			fontWeight: weight.medium,
			fontSize: size['1xl'],
		},
	},
});

const createBody = baseTheme => ({
	body: {
		'01': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size['2xs'],
			lineHeight: lineHeight.normal,
		},
		// paragraphs content page
		'02': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size['2xs'],
			paddingBottom: '10px',
		},
		// labels subtitle edit image modal
		'03': {
			fontFamily: baseTheme.font.family.arialSansSerif,
			fontSize: size['lg'],
		},
		// labels justification edit image modal
		'04': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size['xs'],
			textTransform: transform.uppercase,
		},
		// labels field file modal
		'05': {
			fontFamily: baseTheme.font.family.arialSansSerif,
			fontSize: size['sm'],
		},
		// title print modal
		'06': {
			fontFamily: baseTheme.font.family.notoSansBold,
			fontWeight: weight.bold,
			fontSize: size['2xs'],
		},
		// underlist and list
		'07': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size['2xs'],
			bulletSize: size['4xs'],
			lineHeight: lineHeight.normal,
		},
	},
});

const createCaption = baseTheme => ({
	caption: {
		'01': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size['2xs'],
		},
		// subtitles properties modal
		'02': {
			fontFamily: baseTheme.font.family.arialSansSerif,
			fontSize: size['lg'],
		},
		// approve modal description
		'03': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size['2xs'],
			lineHeight: lineHeight.normal,
		},
		// input placeholder file modal
		'04': {
			fontFamily: baseTheme.font.family.gothamLight,
			fontSize: size['sm'],
			fontWeight: weight.thin,
			textTransform: transform.uppercase,
		},
		// input file modal
		'05': {
			fontFamily: baseTheme.font.family.arialSansSerif,
			fontSize: size['sm'],
			fontWeight: weight.thin,
			textTransform: transform.uppercase,
		},
		// subtitle add files button
		'06': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size['2xs'],
			fontStyle: decoration.italic,
			lineHeight: lineHeight.normal,
		},
		// name file in file modal
		'07': {
			fontFamily: baseTheme.font.family.helveticaNeue,
			fontSize: size['sm'],
			lineHeight: lineHeight.small,
		},
		// image caption text
		'08': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size['3xs'],
		},
	},
});

const createLink = baseTheme => ({
	link: {
		'01': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size['3xs'],
		},
		'02': {
			fontFamily: baseTheme.font.family.helveticaNeue,
			fontSize: size['2xs'],
			letterSpacing: letter.spacing.md,
			textTransform: transform.uppercase,
		},
		'03': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size.sm,
		},
		'04': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size['1xs'],
		},
		'05': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size['1xs'],
		},
		// logo sidebar
		'06': {
			margin: '0 auto 55px auto',
		},
	},
});

const createButton = baseTheme => ({
	button: {
		'01': {
			fontFamily: baseTheme.font.family.helveticaNeue,
			fontSize: size['2xs'],
			letterSpacing: letter.spacing.md,
			textTransform: transform.uppercase,
		},
		'02': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size.xs,
		},
		'03': {
			fontFamily: baseTheme.font.family.arialSansSerif,
			fontSize: size.xs,
			fontWeight: weight.semibold,

			sm: {
				fontSize: size.sm,
			},
		},
		'04': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size['2xs'],
			letterSpacing: letter.spacing.md,
			textTransform: transform.uppercase,
			lineHeight: lineHeight.medium,
		},
		// label buttons properties and edit image modals
		'05': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size['1.5xs'],
			letterSpacing: letter.spacing.md,
			textTransform: transform.uppercase,
			lineHeight: lineHeight.medium,
		},
		// approve modal button
		'06': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size['2xs'],
			letterSpacing: letter.spacing.md,
			textTransform: transform.uppercase,
			lineHeight: lineHeight.medium,
		},
		// discard changes button
		'07': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size['2xs'],
			letterSpacing: letter.spacing.md,
			textTransform: transform.uppercase,
			lineHeight: lineHeight.medium,
		},
		// file button text
		'08': {
			fontFamily: baseTheme.font.family.notoSansBold,
			fontSize: size.xs,
			letterSpacing: letter.spacing.md,
			textTransform: transform.uppercase,
			fontWeight: weight.regular,
		},
		icon: {
			fontSize: size.md,

			xl: {
				fontSize: size.xl,
			},

			'2xl': {
				fontSize: size['2xl'],
			},
		},
	},
});

const createLead = baseTheme => ({
	lead: {
		'01': {
			fontFamily: baseTheme.font.family.arialSansSerif,
			fontSize: size.xs,
		},
		'02': {
			fontFamily: `${baseTheme.font.family.notoSansRegular} !important`,
			fontSize: size['2xs'],
			lineHeight: lineHeight.medium,
		},
		'03': {
			fontFamily: baseTheme.font.family.helveticaNeue,
			fontSize: size.sm,
			fontWeight: weight.bold,
			lineHeight: lineHeight.small,
		},
		'04': {
			fontFamily: baseTheme.font.family.helveticaNeue,
			fontSize: size['1md'],
			fontWeight: weight.medium,
			textTransform: `${transform.none} !important`,
		},
		'05': {
			fontFamily: baseTheme.font.family.helveticaNeue,
			fontSize: size.sm,
			fontWeight: weight.medium,
			lineHeight: lineHeight.small,
		},
		// inputs edit image modal
		'06': {
			fontFamily: baseTheme.font.family.notoSansRegular,
			fontSize: size.md,
			fontWeight: weight.thin,
			textTransform: transform.none,
		},
		// input properties modal
		'07': {
			fontFamily: baseTheme.font.family.arialSansSerif,
			fontSize: size.md,
			fontWeight: weight.thin,
			textTransform: transform.none,
		},
		// checkbox label properties modal
		'08': {
			fontFamily: baseTheme.font.family.helveticaNeue,
			fontSize: size['sm'],
			fontWeight: weight.medium,
			lineHeight: lineHeight.normal,
		},
	},
});

const createTable = baseTheme => ({
	table: {
		'01': {
			fontFamily: baseTheme.font.family.arialSansSerif,
			fontSize: size['sm'],
			lineHeight: lineHeight.normal,
		},
	},
});

const typographyTheme = baseTheme => ({
	typo: {
		...createHeadline(baseTheme),
		...createCaption(baseTheme),
		...createLink(baseTheme),
		...createButton(baseTheme),
		...createLead(baseTheme),
		...createBody(baseTheme),
		...createTable(baseTheme),
	},
});

export default typographyTheme;
