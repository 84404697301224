import styled from 'styled-components/macro';

const variantStyle = ({ variant = 'primary', color }) => {
	if (variant === 'secondary') {
		return {
			appearance: 'none',
			'-webkit-appearance': 'none',
			width: '13px',
			height: '13px',
			borderRadius: '3px',
			border: `1px solid ${color.neutral['80']}`,

			'&:checked': {
				backgroundColor: color.neutral['80'],
			},
		};
	}

	return {};
};

export const CheckBoxGroup = styled.div(() => ({
	display: 'inline-flex',
	position: 'relative',
	alignItems: 'center',
}));

export const CheckBox = styled.input(({ theme: { color }, variant }) => ({
	display: 'inline-block',
	marginRight: '7px',
	verticalAlign: 'middle',
	cursor: 'pointer',
	...variantStyle({ variant, color }),
	'&:disabled': {
		backgroundColor: color.neutral['80'],
		cursor: 'not-allowed',
	},
}));

export const Label = styled.span(({ theme: { neutral, typo } }) => ({
	...typo.lead['01'],
	color: neutral['80'],
}));
