import { useCallback } from 'react';
import { logError } from 'utils/logUtil';

export const useLocalStorage = (key, initialValue) => {
	const getValue = useCallback(() => {
		try {
			const storedValue = sessionStorage.getItem(key);
			return storedValue ? JSON.parse(storedValue) : initialValue;
		} catch {
			return initialValue;
		}
	}, [key, initialValue]);

	const setValue = useCallback(
		value => {
			try {
				sessionStorage.setItem(key, JSON.stringify(value));
			} catch (e) {
				logError(e);
			}
		},
		[key]
	);

	return [getValue(), setValue];
};
