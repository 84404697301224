import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { InnerHtml } from '@hyatt/inner-html';
import { editMenuSetSelectedToEdit } from 'actions/editMenuAction';
import Helmet from 'components/helmet/Helmet';
import { AddModules } from 'components/addModule';
import { BrandMainSkeleton } from 'components/loaders/brandMainSkeleton';
import { NoPermissionMessage } from 'components/noPermissionMessage';
import { EDIT_MENU_TYPES } from 'components/begEditMenu/constants/begEditMenuConstants';
import { ButtonModalAnalytics } from 'components/buttonModalAnalytics/ButtonModalAnalytics';
import { analyticsObjectTypes } from 'constants/analyticsObjectTypes';
import { contentConstants } from 'containers/brandDetails/components/brandMain/constants/contentConstants';
import useBrandDetails from 'hooks/useBrandDetails';
import { useAnalytics } from 'hooks/useAnalytics';
import { useEditMenuData } from 'hooks/useEditMenuData';
import { useHighlight } from 'hooks/useHighlight';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useModules } from 'hooks/useModules';
import { isFirstParagraph, isLastParagraph } from 'utils/paragraphUtil';
import { isEmpty } from 'utils/arrayUtil';
import { localStorageKeys } from 'constants/localStorageConstants';
import { PAGE_TYPES } from 'constants/pageConstants';
import { Breadcrumb } from '../breadcrumb';
import { EditPageModal } from './components/editPageModal/EditPageModal';
import { ModulesSwitch } from './components/modules/ModulesSwitch';
import { PageSubTitle } from './components/pageSubTitle';
import { PageTitle } from './components/pageTitle';
import { PrintButton } from './components/printButton/PrintButton';
import { ModulesLoading } from './components/modulesLoading';
import './BrandMain.scss';

const BrandMain = () => {
	const { hash } = useLocation();
	const { selectedToEdit } = useEditMenuData();

	const { currentType, crumbs, showNoPermissionMessage } = useBrandDetails();
	const { loadingModules, loadingModule, paragraphsArr } = useModules();
	const dispatch = useDispatch();

	const { saveViewOnDemand } = useAnalytics(false);
	const { handleTextHighlight } = useHighlight();

	const currentUrl = useLocalStorage(localStorageKeys.STORED_ROUTE, { current: '', last: '' })[0].current;
	const targetHash = useMemo(() => hash?.split('#')[1], [hash]);

	const isAddModule = selectedToEdit === EDIT_MENU_TYPES.ADD_MODULE;

	const pageTitle = useMemo(
		() => (currentType?.type === PAGE_TYPES.CHAPTER_TYPE ? currentType?.chapterTitle : currentType?.sectionTitle),
		[currentType?.chapterTitle, currentType?.sectionTitle, currentType?.type]
	);

	const highlightedPageTitle = useMemo(() => {
		return <InnerHtml body={handleTextHighlight(pageTitle)} />;
	}, [pageTitle, handleTextHighlight]);

	const highlightedPageSubTitle = useMemo(() => {
		return currentType?.articleTitle ? <InnerHtml body={handleTextHighlight(currentType?.articleTitle)} /> : null;
	}, [currentType?.articleTitle, handleTextHighlight]);

	const pageSubTitle = highlightedPageSubTitle && <PageSubTitle>{highlightedPageSubTitle}</PageSubTitle>;

	const handleCloseEditPageModal = () => dispatch(editMenuSetSelectedToEdit({ selectedToEdit: '' }));

	useEffect(() => {
		if (!loadingModules && !loadingModule && targetHash) {
			setTimeout(() => {
				document
					.getElementById(targetHash)
					?.scrollIntoView({ block: 'center', inline: 'nearest', behavior: 'smooth' }, 2500);
			}, 1000);
		}
	}, [loadingModules, loadingModule, targetHash]);

	useEffect(() => {
		if (currentType?.type && currentUrl.endsWith(currentType?.path) && !loadingModules) {
			let contentId;
			let pageTitleToSave;

			switch (currentType.type) {
				case PAGE_TYPES.SECTION_TYPE:
					contentId = currentType.sectionId;
					pageTitleToSave = currentType.sectionTitle;
					break;
				case PAGE_TYPES.ARTICLE_TYPE:
					contentId = currentType.articleId;
					pageTitleToSave = currentType.articleTitle;
					break;
				case PAGE_TYPES.CHAPTER_TYPE:
					contentId = currentType.chapterId;
					pageTitleToSave = currentType.chapterTitle;
					break;
				default:
					break;
			}

			saveViewOnDemand({
				contentId,
				pageTitle: pageTitleToSave,
				pageAuthor: currentType?.creator,
				pageBrands: currentType?.brands,
				pageRegions: currentType?.regions,
				pagePublishingDate: currentType?.creationDate,
				typeObject: analyticsObjectTypes.CONTENT_INSTANCE,
			});
		}
	}, [currentType, currentUrl, loadingModules, saveViewOnDemand]);

	if (loadingModules || ((showNoPermissionMessage === false || !showNoPermissionMessage) && !paragraphsArr && currentType)) {
		return <BrandMainSkeleton />;
	}

	return (
		<main className="BrandMain">
			{showNoPermissionMessage && <NoPermissionMessage />}
			{currentType && !showNoPermissionMessage && (
				<>
					<ModulesLoading isLoading={loadingModule} />

					{currentType?.type && <Helmet title={currentType[contentConstants[currentType.type].title]} />}
					<Breadcrumb steps={crumbs} />

					<div id="print-section" className="main-panel">
						<div className="title-print-container">
							<div className="page-title">
								<PageTitle>{highlightedPageTitle}</PageTitle>
							</div>
							<div className="icons-content">
								<ButtonModalAnalytics contentId={currentType?.vcmId} />
								<PrintButton />
							</div>
						</div>

						{pageSubTitle}
					</div>

					<div>{isEmpty(paragraphsArr) && <AddModules open={isAddModule} position={0} />}</div>

					<div>
						{paragraphsArr?.map((paragraph, index) => {
							const { title, body, specification, disclaimer, path, collapsible, keywords, links, linkArray } =
								paragraph;
							return (
								<div className="BrandMain__module-wrapper" key={paragraph.vcmId}>
									<AddModules open={isAddModule} position={index} />

									<ModulesSwitch
										id={paragraph.vcmId}
										paragraphId={paragraph.vcmId}
										type={paragraph.type}
										paragraph={paragraph}
										action={selectedToEdit}
										startTitle={title}
										startDescription={body}
										startSpecification={specification}
										startDisclaimer={disclaimer}
										links={links}
										linkArray={linkArray}
										path={path}
										startIsDropdown={collapsible}
										keywords={keywords}
										imageData={paragraph.imageData}
										files={paragraph.files}
										isFirst={isFirstParagraph(paragraphsArr, paragraph)}
									/>

									{isLastParagraph(paragraphsArr, paragraph) && (
										<AddModules open={isAddModule} position={index + 1} />
									)}
								</div>
							);
						})}
					</div>

					{selectedToEdit === EDIT_MENU_TYPES.EDIT_PAGE_CONFIG && <EditPageModal onClose={handleCloseEditPageModal} />}
				</>
			)}
		</main>
	);
};

export { BrandMain };
