import PropTypes from 'prop-types';
import React from 'react';
import * as S from './PageTitle.style';

const PageTitle = ({ children }) => {
	return <S.Title>{children}</S.Title>;
};

PageTitle.propTypes = {
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

PageTitle.defaultProps = {
	children: '',
};

export { PageTitle };
