import Cookies from 'js-cookie';
import { setUrlToStorage } from 'utils/urlUtil';
import { removeAuthCookies } from './cookiesUtil';
import { isLocal } from './envUtil';
import { getExternalUser } from './externalUserUtil';
import { encodeParams } from './queryParamsUtils';

const getRedirect = () => {
	if (process.env.NODE_ENV === 'development') {
		return '';
	}

	if (!isLocal) {
		return encodeParams({ r: window.location.href }, 'r');
	}

	return '';
};

export const redirectToLogin = () => {
	const extUser = getExternalUser();

	if (extUser) {
		Cookies.set(process.env.REACT_APP_TOKEN_KEY, extUser.token);
		window.location.assign(process.env.REACT_APP_SSO_URL_REDIRECT);
		return;
	}

	setUrlToStorage();
	const redirect = getRedirect();
	removeAuthCookies(() => {
		window.location.assign(`${process.env.REACT_APP_SSO_URL}${process.env.REACT_APP_SSO_URL_REDIRECT}${redirect}`);
	});
};

export const redirectToOrigin = () => {
	const originUrl = `${window.location.origin}${window.location.pathname}`;
	window.location.assign(originUrl);
};

export const redirectToQuickSheet = () => {
	window.open(`${process.env.REACT_APP_QUICKSHEET_URL}`, '_blank');
};

export const redirectToPath = path => {
	const url = `${window.location.origin}${window.location.pathname}#${path}`;
	window.location.assign(url);
};

export const redirectToUrl = url => {
	window.location.assign(url);
};
