import { useRef } from 'react';
import { Base } from 'components/base';
import { BrandsBox } from 'components/brandsBox';
import { useAuthData } from 'hooks/useAuthData';
import useBrandData from 'hooks/useBrandData';
import { translations } from 'translation/en';
import { BrandSideBarMobile } from '../brandSideBarMobile';
import { DropDown } from '../dropdown';
import { HelpButton } from '../helpButton';
import { SearchBar } from '../searchBar';
import { SignOutButton } from '../signOutButton';
import * as S from './BrandHeader.style';

const BrandHeader = () => {
	const { user } = useAuthData();
	const { brand } = useBrandData();

	const searchDropDownRef = useRef();

	return (
		<>
			<S.BrandHeader>
				<Base>
					<S.NavBar>
						<S.Actions>
							<div>
								<DropDown label={<S.Label>{brand.name}</S.Label>} icon={<S.Arrow />} closeOnOutsideClick>
									<DropDown.DropDownItem>
										<Base>
											<BrandsBox hasMargin />
										</Base>
									</DropDown.DropDownItem>
								</DropDown>
							</div>
							<div>
								<DropDown
									ref={searchDropDownRef}
									label={<S.Label>{translations.SEARCH_TEXT}</S.Label>}
									icon={<S.Arrow />}
									closeOnOutsideClick
								>
									<DropDown.DropDownItem>
										<Base>
											<SearchBar onClose={() => searchDropDownRef.current.handleClose()} />
										</Base>
									</DropDown.DropDownItem>
								</DropDown>
							</div>
							<div>
								<DropDown label={<S.Label>{user?.name}</S.Label>} icon={<S.Arrow />} closeOnOutsideClick>
									<S.SignOutBox>
										<SignOutButton />
									</S.SignOutBox>
								</DropDown>
							</div>
							<div>
								<HelpButton />
							</div>
						</S.Actions>
					</S.NavBar>
				</Base>
			</S.BrandHeader>
			<BrandSideBarMobile />
		</>
	);
};

export { BrandHeader };
