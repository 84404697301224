import styled from 'styled-components/macro';

export const Breadcrumb = styled.main({
	display: 'inline-block',
	margin: '0 0 0 15px',
	padding: '0 3px',
	border: 0,
	width: 'fit-content',
	height: 'auto',
});

export const Step = styled.div({
	display: 'inline-flex',
	flexGrow: 1,
	flexWrap: 'nowrap',
	alignItems: 'center',
	maxHeight: '16px',
	boxSizing: 'border-box',
	verticalAlign: 'middle',
	lineHeight: '14.4px',
});

export const Icon = styled.div(({ theme: { size, color } }) => ({
	fontSize: size.xs,
	width: size.sm,
	height: size.sm,
	display: 'flex',
	alignItems: 'center',
	marginLeft: '3px',
	marginRight: '3px',
	justifyContent: 'center',

	'.icon': {
		color: color.icon.breadcrumbArrow,
		opacity: '0.5',
	},
}));
