import Cookies from 'js-cookie';
import routers from 'router/router';
import { getExternalUser, hasExternalUser } from './externalUserUtil';
import { redirectToLogin } from './redirectUtil';

export const authenticate = () => {
	if (hasExternalUser()) {
		Cookies.remove('JSESSIONID');
		Cookies.remove(process.env.REACT_APP_TOKEN_KEY);

		const { token, brandName } = getExternalUser();

		Cookies.set(process.env.REACT_APP_TOKEN_KEY, token);
		const to = brandName
			? `#${routers.brandROUTE.getRouteDefaultPath()}/${brandName}`
			: `#${routers.brandsROUTE.getRouteDefaultPath()}`;

		window.open(to, '_self');
		window.location.reload();

		return;
	}
	redirectToLogin();
};
