import PropTypes from 'prop-types';
import classNames from 'classnames';
import { moduleTemplateImages } from 'components/addModule/constants/moduleTemplateConstants';
import { translations } from 'translation/en';
import useParagraphs from 'hooks/useParagraphs';
import { usePutParagraph } from 'hooks/usePutParagraph';
import { fromParagraphService } from 'services/paragraphsService';
import useClearCache from 'hooks/useClearCache';
import './ChangeModulePopover.scss';

const ChangeModulePopover = ({ show, paragraph, onClose }) => {
	const { setIsParagraphLoading, handleUpdateParagraph } = useParagraphs();
	const { updateParagraph } = usePutParagraph();
	const { clearCache } = useClearCache();

	const moduleModalClassNames = classNames('ChangeModulePopover', {
		active: show,
	});

	const moduleTemplateClassNames = item =>
		classNames('ChangeModulePopover__body-item', {
			'--selected': paragraph?.type === item?.type,
		});

	const handleChange = (module, payload) => {
		if (module.type === payload.type) {
			return;
		}

		onClose();
		setIsParagraphLoading(true);

		updateParagraph({
			path: payload.path,
			payload: { TYPE: module.type },
		})
			.then(([response]) => {
				if (response?.data) {
					const updatedParagraph = fromParagraphService(response.data);
					handleUpdateParagraph(updatedParagraph);
				}
			})
			.finally(() => {
				onClose();
				clearCache({ successCallback: () => {} });
				setIsParagraphLoading(false);
			});
	};

	return (
		<div className={moduleModalClassNames}>
			<div className="ChangeModulePopover__header">
				<i className="icon icon-four-squares" />
				<p>{translations.SELECT_A_MODULE}</p>
				<button className="ChangeModulePopover__header-button" onClick={onClose}>
					<i className="icon icon-close-rounded" />
				</button>
			</div>
			<div className="ChangeModulePopover__body">
				{moduleTemplateImages.map(module => (
					<button
						key={module.id}
						className={moduleTemplateClassNames(module)}
						onClick={() => handleChange(module, paragraph)}
					>
						<img src={module.src} alt={module.templateName} />
						<p>{module.templateName}</p>
					</button>
				))}
			</div>
		</div>
	);
};

ChangeModulePopover.propTypes = {
	show: PropTypes.bool,
	paragraph: PropTypes.shape({
		vcmId: PropTypes.string,
		type: PropTypes.string,
		path: PropTypes.string,
	}),
	onClose: PropTypes.func.isRequired,
};

ChangeModulePopover.defaultProps = {
	show: false,
	paragraph: null,
};

export { ChangeModulePopover };
