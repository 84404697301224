import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDeleteModule } from 'hooks/useDeleteModule';
import useParagraphs from 'hooks/useParagraphs';
import React from 'react';
import { translations } from 'translation/en';
import './DeleteModulePopover.scss';

const DeleteModulePopover = ({ show, paragraph, onClose }) => {
	const { setIsParagraphLoading, handleDeleteParagraph } = useParagraphs();
	const { deleteModule } = useDeleteModule();

	const deleteModalClassNames = classNames('DeleteModulePopover', {
		active: show,
	});

	const handleDelete = payload => {
		onClose();
		setIsParagraphLoading(true);

		deleteModule({
			path: payload?.path,
		})
			.then(result => {
				// TODO: remove this when handling throw in useRequest
				if (!result?.error) {
					handleDeleteParagraph(payload);
				}
			})
			.finally(() => {
				setIsParagraphLoading(false);
			});
	};

	return (
		<div className={deleteModalClassNames}>
			<div className="DeleteModulePopover__header">
				<button className="DeleteModulePopover__header-button" onClick={onClose}>
					<i className="icon icon-close-rounded" />
				</button>
			</div>
			<div className="DeleteModulePopover__body">
				<i className="icon icon-trash" />
				<h1>{translations.DELETE_MODULE}</h1>
				<p className="DeleteModulePopover__body-text">{translations.DELETE_MODULE_CONFIRMATION_TEXT}</p>
				<button className="DeleteModulePopover__body-button" onClick={() => handleDelete(paragraph)}>
					{translations.DELETE}
				</button>
			</div>
		</div>
	);
};

DeleteModulePopover.propTypes = {
	show: PropTypes.bool,
	paragraph: PropTypes.shape({
		vcmId: PropTypes.string,
	}),
	onClose: PropTypes.func.isRequired,
};

DeleteModulePopover.defaultProps = {
	show: false,
	paragraph: null,
};

export { DeleteModulePopover };
