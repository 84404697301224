import { useLocation, useParams } from 'react-router-dom';
import { findThemeByBrandId } from 'themes/utils/themeUtil';
import { findBrandByName } from 'utils/mappedBrandsUtil';

const useBrandData = () => {
	const { pathname } = useLocation();
	const { brandName } = useParams();

	const brand = findBrandByName(brandName);
	const themeBrand = findThemeByBrandId(brand.brandId);

	const extractPathFromWindow = () => {
		if (pathname !== '/') {
			sessionStorage.setItem('brand-path', pathname);
		}

		return sessionStorage.getItem('brand-path');
	};

	const brandPathFromWindow = extractPathFromWindow();

	return { brandName, brand, brandId: brand.brandId, themeBrand, brandPathFromWindow };
};

export default useBrandData;
