import { SECURITY_SET_GROUPS_AND_DEPARTMENTS } from '../../actions/actionTypes';

const initialState = {
	adGroups: null,
	adDepartments: null,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SECURITY_SET_GROUPS_AND_DEPARTMENTS:
			return {
				...state,
				adGroups: action.payload.adGroups,
				adDepartments: action.payload.adDepartments,
			};
		default:
			break;
	}
	return state;
};

export default reducer;
