import { usePosition } from '@ernestorb/useposition';
import PropTypes from 'prop-types';
import React, { useLayoutEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { EDIT_MENU_NAVIGATION_PORTAL } from '../constants/editNavigationConstants';

const EditNavigationPortal = ({ show, parentRef, children }) => {
	const elementRef = useRef(null);
	const [position, setPosition] = useState(null);

	useLayoutEffect(() => {
		const el = document.getElementById(EDIT_MENU_NAVIGATION_PORTAL);

		if (el) {
			elementRef.current = el;
		}
	}, []);

	usePosition(
		parentRef,
		({ top }) => {
			setPosition({
				top,
			});
		},
		{ enableHighAccuracy: true },
		[]
	);

	if (!elementRef.current || !show || !parentRef.current || !position?.top) {
		return null;
	}

	return (
		show &&
		parentRef.current &&
		ReactDOM.createPortal(
			<div style={{ position: 'absolute', top: position.top + window.scrollY, transition: 'all 5ms ease-in-out' }}>
				{children}
			</div>,
			elementRef.current
		)
	);
};

EditNavigationPortal.propTypes = {
	show: PropTypes.bool,
	parentRef: PropTypes.shape({
		current: PropTypes.any,
	}).isRequired,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

EditNavigationPortal.defaultProps = {
	show: false,
	children: null,
};

export { EditNavigationPortal };
