import classNames from 'classnames';
import { any, bool, string } from 'prop-types';
import React, { forwardRef } from 'react';
import Select from 'react-select';
import FormFieldError from './FormFieldError';
import FormFieldLabel from './FormFieldLabel';
import './FormFieldSelect.scss';

export const FormFieldSelect = forwardRef(
	(
		{
			className,
			required,
			hasError,
			label,
			errors,
			showSeparator,
			styles,
			icon,
			...props
		},
		ref
	) => {
		return (
			<>
				{label && <FormFieldLabel label={label} required={required} />}
				<div>
					<Select
						ref={ref}
						className={classNames('FormFieldSelect', className, {
							'FormFieldSelect--has-error': hasError,
						})}
						classNamePrefix="FormFieldSelect"
						placeholder="Select..."
						noOptionsMessage={() => 'No available options'}
						styles={{
							...styles,
							menuPortal: (base) => ({
								...base,
								zIndex: 2,
							}),
							indicatorSeparator: (base) => ({
								...base,
								opacity: showSeparator ? 1 : 0,
							}),
						}}
						{...props}
					/>
					{icon && <i className={icon} />}
				</div>
				{hasError && errors?.message && (
					<FormFieldError message={errors?.message} />
				)}
			</>
		);
	}
);

FormFieldSelect.propTypes = {
	className: string,
	hasSeparator: bool,
	label: string,
	errors: any,
	required: bool,
	hasError: bool,
	showSeparator: bool,
	styles: any,
	icon: string,
};

FormFieldSelect.defaultProps = {
	className: '',
	hasSeparator: true,
	label: null,
	required: false,
	hasError: false,
	errors: null,
	showSeparator: false,
	styles: null,
	icon: '',
};
