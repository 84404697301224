import { Base } from 'components/base';
import { Logo } from 'components/logo';
import { useTheme } from 'styled-components';
import { translations } from 'translation/en';
import { getYear } from 'utils/dateUtil';
import { replaceArgs } from 'utils/stringUtil';
import * as S from './BrandFooter.style';

const BrandFooter = () => {
	const { logo } = useTheme();
	const year = getYear();

	return (
		<S.BrandFooter>
			<Base>
				<S.Section>
					<Logo to="#" image={logo.footer} size={logo.size.footer} />
				</S.Section>
				<S.Section>
					<S.CopyrightText>{replaceArgs(translations.FOOTER_TEXT_ALL_RIGHTS_RESERVED, year)}</S.CopyrightText>
				</S.Section>
			</Base>
		</S.BrandFooter>
	);
};

export { BrandFooter };
