import PropTypes from 'prop-types';
import React from 'react';
import { translations } from 'translation/en';
import * as S from './AddFileButton.style';

const AddFileButton = ({ onClick }) => {
	return (
		<>
			<S.AddFileButton variant="gray-border-secondary" onClick={onClick}>
				<i className="icon icon-file-earmark" />
				{translations.ADD_FILES}
			</S.AddFileButton>
			<S.LimitFileMessage>{translations.ADD_EDIT_FILES_LIMIT_FILE_MESSAGE}</S.LimitFileMessage>
		</>
	);
};

AddFileButton.propTypes = {
	onClick: PropTypes.func.isRequired,
};

export { AddFileButton };
