import UnboundHorizontalLogo from 'assets/img/logos/unbound-horizontal.svg';
import UnboundHyattLogo from 'assets/img/logos/unbound.svg';
import UnboundLogo from 'assets/img/logos/unbound-logo.svg';
import complementaryTheme from 'themes/complementary/complementary';

import colors from './theUnboundCollectionColors';
import typographyTheme from './theUnboundCollectionTypo';

const logo = {
	footer: UnboundHorizontalLogo, // footer logo
	loading: UnboundHyattLogo, // logo in the loading
	sidebar: UnboundLogo, // sidebar logo
	mobile: UnboundLogo,
	size: {
		sidebar: {
			width: '106px',
			height: '107px',
		},
		footer: {
			width: '261px',
			height: '44px',
		},
		navbar: {
			width: '106px',
			height: '144px',
		},
		loading: {
			width: '100px',
			height: '100px',
		},
	},
};

const copyrightText = {
	padding: '5px 0 5px 30px',
};

const theUnboundCollection = {
	brandId: 'ub',
	name: 'The Unbound Collection',
	logo,
	color: colors,
	copyrightText,

	font: {
		family: {
			jostSemiBold: 'Jost SemiBold',
			jostRegular: 'Jost Regular',
			jostMedium: 'Jost Medium',
			jostLight: 'Jost Light',
			arialSansSerif: 'Arial, Helvetica, sans-serif',
			calibri: 'Calibri',
			helveticaNeue: 'Helvetica Neue',
		},
	},
};

const theUnboundCollectionTheme = {
	...theUnboundCollection,
	...typographyTheme(theUnboundCollection),
	...complementaryTheme,
};

export default theUnboundCollectionTheme;
