import complementaryTheme from 'themes/complementary/complementary';

const { neutral } = complementaryTheme;

const colors = {
	background: {
		banner: {
			background: '#BD2137', // header
		},
		secondary: '#BCBCBD',
		tertiary: '#f7f7f7',
		printModal: '#BD2137',
	},
	interactive: {
		primary: {
			default: {
				background: '#BD2137',
				label: '#fff',
				border: '#BD2137',
			},
			hover: {
				background: '#F7F7F7',
				label: '#BD2137',
				border: '#BD2137',
			},
		},
		primary02: {
			default: {
				background: '#BD2137',
				label: '#fff',
				border: '#BD2137',
				opacity: '0.65',
			},
		},
		// file button
		primary05: {
			default: {
				background: '#BD2137',
				label: '#fff',
				border: '#BD2137',
			},
			hover: {
				background: '#fff',
				label: '#BD2137',
				border: '#BD2137',
			},
		},
		secondary: {
			default: {
				background: '#eeefef',
				label: '#625d5a',
				border: '#625d5a',
			},
			hover: {
				background: '#BD2137',
				label: '#fff',
				border: '#BD2137',
			},
		},
		secondary02: {
			default: {
				background: '#eeefef',
				label: '#625d5a',
			},
			hover: {
				background: '#eeefef',
				label: '#BD2137',
			},
		},
		// button approve modal
		secondary03: {
			default: {
				background: '#333333',
				label: '#fff',
				border: '#333333',
			},
			hover: {
				background: '#fff',
				label: '#333333',
				border: '#333333',
			},
		},
		tertiary: {
			default: {
				background: '#fff',
				label: '#BD2137',
			},
		},
		printModalButtons: {
			default: {
				background: '#BD2137',
				label: '#fff',
				border: '#BD2137',
			},
			hover: {
				background: '#F7F7F7',
				label: '#BD2137',
				border: '#BD2137',
			},
		},
		// properties and edit image modals buttons
		tertiary03: {
			default: {
				background: '#fff',
				label: '#181818',
				border: '#ccc',
			},
			hover: {
				background: '#fff',
				label: '#181818',
				border: '#181818',
			},
		},
		outlined: {
			default: {
				label: '#fff',
				icon: '#fff',
				border: '#fff',
			},
		},
		inverse: {
			default: {
				label: '#fff',
			},
		},
		discardChangesButtons: {
			default: {
				background: '#BD2137',
				label: '#fff',
				border: '#BD2137',
			},
			hover: {
				background: '#F7F7F7',
				label: '#BD2137',
				border: '#BD2137',
			},
		},
		printPageButtons: {
			default: {
				background: '#7d7773',
				label: '#fff',
				border: '#7d7773',
			},
			hover: {
				background: '#F7F7F7',
				label: '#7d7773',
				border: '#7d7773',
			},
		},
		dropDownButtons: {
			default: {
				label: '#fff',
			},
			active: {
				color: '#00000020',
				label: 'red',
			},
			focusWithin: {
				background: '#2f2a27',
				label: '#7d7773',
				border: 'none',
			},
			hover: {
				background: 'transparent',
				label: '#fff',
				border: 'none',
			},
		},
		lightest: 'rgba(255, 255, 255, 0.1)',
	},
	field: {
		// search input
		primary: {
			text: '#636466',
			placeholder: '#888888',
		},
		// inputs edit image modal
		secondary: {
			text: '#676767',
			placeholder: '#676767',
		},
		// input properties modal
		tertiary: {
			text: '#676767',
			placeholder: '#676767',
		},
	},
	text: {
		primary: '#636466', // footer
		primary02: '#BD2137', // title chapter, section page

		titleModule: '#000000',
		headline01: '#BD2137',
		headline02: '#000000',
		headline03: '#BD2137',
		headline04: '#BD2137',
		headline05: '#000000',
		headline06: '#000000',

		paragraph: '#000000', // paragraph content page
		specificationTitle: '#BD2137', // specification title

		approveModalTitle: '#666666',
		approveModalDescription: '#999999',

		addFilesSubtitle: '#999999',

		secondary: '#BD2137', // terms & conditions title modal and
		secondary02: '#000000', // title discard changes modal
		secondary03: '#181818', // edit image modal title

		tertiary: '#000000', // terms & conditions description modal
		tertiary03: '#181818', // title properties modal

		quaternary: '#000000', // title print modal
		quaternary02: '#333', // labels subtitle edit image modal
		quaternary03: '#000', // labels justification edit image modal

		quinary: '#000000', // titles items print modal
		quinary02: '#333', // subtitles properties modal
		quinary03: '#333', // title remove modal
		senary: '#000000', // title article page

		bullet: '#000000', // list item bullet

		imageCaption: '#666666',
	},
	link: {
		primary: {
			default: {
				label: '#000000',
			},
			hover: {
				label: '#000000',
			},
		},
		secondary: {
			default: {
				label: '#000000',

				mobile: {
					label: '#000000',
				},
			},
			hover: {
				background: 'rgba(255, 255, 255, 0.1)',
				label: '#000000',
			},
			active: {
				label: '#000000',
			},
		},
		tertiary: {
			default: {
				label: '#000000',
			},
			hover: {
				background: 'rgba(255, 255, 255, 0.1)',
				label: '#000000',

				mobile: {
					label: '#d31145',
				},
			},
			active: {
				label: '#62b3e3',
			},
		},
		quaternary: {
			default: {
				label: '#333',
			},
			hover: {
				label: '#333',
			},
			active: {
				label: '#BD2137',
			},
		},
	},
	icon: {
		primary: '#fff',
		primary02: '#BD2137',
		chapterArrow: '#625d5a', // chapter arrow icon
		sectionArrow: '#000000', // section arrow icon
		breadcrumbArrow: '#555555', // breadcrumb arrow icon
	},
	border: {
		primary: '#BD2137',
		secondary: '#FFFFFF',
		tertiary: '#BD2137',
		quaternary: '#54565A',
	},
	shadow: {
		primary: 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(189, 33, 55, 0.6)',
		secondary: 'inset 0 1px 0 rgba(255, 255, 255, 0.1)',
	},
	button: {
		primary: '#7d7773',
	},
	neutral,
	table: {
		row: {
			background: {
				primary: '#efe8e4',
				secondary: 'transparent',
			},
			cell: {
				label: '#333333',
				border: '2px solid #7d7773',
			},
		},
	},
};

export default colors;
