import { useEffect, useMemo } from 'react';
import { fromArticleService, getArticleByPath } from 'services/articleService';
import { translations } from 'translation/en';
import { showToast } from 'utils/toastUtil';
import { useRequest } from './useRequest';

export const useArticleByPath = () => {
	const {
		loading: loadingArticle,
		data: response,
		error,
		run: fetchArticle,
		clear: clearArticle,
	} = useRequest(params =>
		getArticleByPath({
			...params,
		})
	);

	useEffect(() => {
		if (error) {
			showToast({ type: 'error', message: translations.FAILED_FETCH_ARTICLE });
		}
	}, [error]);

	const article = useMemo(() => {
		return response?.data?.data?.getArticleByPath?.items?.map(fromArticleService)?.shift();
	}, [response]);

	const articleParagraphs = useMemo(() => {
		return article?.paragraphs?.sort((item1, item2) => +item1.displayOrder - +item2.displayOrder);
	}, [article]);

	return { loadingArticle, article, articleParagraphs, fetchArticle, clearArticle };
};
