import BrandDetails from 'containers/brandDetails/BrandDetails';
import ChooseBrands from 'containers/chooseBrand/ChooseBrands';
import DefaultLayout from 'containers/_layout/DefaultLayout';
import BrandLayout from 'containers/_layout/BrandLayout';
import PrintLayout from 'containers/_layout/PrintLayout';
import { ChapterPrintPage } from 'containers/chapterPrintPage';
import { SectionPrintPage } from 'containers/sectionPrintPage';
import { ArticlePrintPage } from 'containers/articlePrintPage';
import { RedirectByBrandID } from 'containers/redirectByBrandID';
import { Header } from 'containers/chooseBrand/components/header';
import { SearchPage } from 'containers/searchPage/SearchPage';
import { RouteModel } from './model/RouteModel';

const routers = {
	chapterPrintROUTE: new RouteModel({
		key: 'chapterPrint',
		path: '/:brandName/print/chapter',
		defaultPath: '/print',
		layout: PrintLayout,
		header: () => {},
		component: () => <ChapterPrintPage />,
		footer: () => {},
	}),
	sectionPrintROUTE: new RouteModel({
		key: 'sectionPrint',
		path: '/:brandName/print/section',
		defaultPath: '/print',
		layout: PrintLayout,
		header: () => {},
		component: () => <SectionPrintPage />,
		footer: () => {},
	}),
	articlePrintROUTE: new RouteModel({
		key: 'articlePrint',
		path: '/:brandName/print/article',
		defaultPath: '/print',
		layout: PrintLayout,
		header: () => {},
		component: () => <ArticlePrintPage />,
		footer: () => {},
	}),
	searchROUTE: new RouteModel({
		key: 'searchPage',
		path: '/brands/:brandName/search/',
		defaultPath: '/search',
		layout: BrandLayout,
		header: () => {},
		component: () => <SearchPage />,
		footer: () => {},
	}),
	brandROUTE: new RouteModel({
		key: 'brand',
		path: '/brands/:brandName',
		defaultPath: '/brands',
		layout: BrandLayout,
		header: () => {},
		component: () => <BrandDetails />,
		footer: () => {},
	}),
	redirectByBrandIDRoute: new RouteModel({
		key: 'redirect-by-brand-id',
		path: '/redirect/:brandId/:contentPath+',
		defaultPath: '/redirect/71',
		layout: BrandLayout,
		header: () => {},
		component: () => <RedirectByBrandID />,
		footer: () => {},
	}),
	brandsROUTE: new RouteModel({
		key: 'choose-brands',
		path: ['/choose-brands', '/'],
		defaultPath: '/choose-brands',
		layout: DefaultLayout,
		header: () => <Header />,
		component: () => {
			window.history.replaceState({}, '', '#/choose-brands');
			return <ChooseBrands />;
		},
		footer: () => {},
	}),
};

const routersList = Object.values(routers);
const defaultRoute = 'brandsROUTE';

const getPathToDefault = () => routers[defaultRoute].getRouteDefaultPath();

export default routers;

export { routersList, getPathToDefault };
