import axios from 'API/axios/axios';
import { logError } from 'utils/logUtil';

export const getRelationships = (path, attributes = 'path,cq_model') => {
	const url = `${process.env.REACT_APP_API_URL}/v1/contentFragment/relationships`;

	return axios
		.get(url, {
			params: {
				path,
				filter: attributes,
			},
		})
		.then(response => response.data)
		.catch(e => logError(e));
};
