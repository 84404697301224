import { filterUniqueObjectsByIndex } from 'utils/arrayUtil';
import { SEARCH_STRING_PATH } from 'constants/searchConstants';
import {
	SEARCH_RESET,
	SEARCH_SET_AUTO_RUN,
	SEARCH_SET_IS_LOADING,
	SEARCH_SET_NUM_FOUND,
	SEARCH_SET_PARAMS,
	SEARCH_SET_RESULTS,
} from '../../actions/actionTypes';

const initialState = {
	results: [],
	numFound: 0,
	searchTerm: '',
	isExactMatch: '',
	isLoading: true,
	isAutoRunEnabled: true,
};

const searchSetParams = (state, action) => ({
	...state,
	...action.search,
});

const setSearchResults = (state, action) => {
	const allIncomingPages = action?.searchResults?.highlightedResponse;
	const newPages = filterUniqueObjectsByIndex(allIncomingPages, state?.results, SEARCH_STRING_PATH);

	const updatedOldPages = state?.results?.map(oldPage => {
		const newPageData = allIncomingPages.find(page => page.path === oldPage.path);

		if (!newPageData) return oldPage;

		return {
			...oldPage,
			...newPageData,
			paragraphs: [...oldPage.paragraphs, ...newPageData.paragraphs],
			files: [...oldPage.files, ...newPageData.files],
		};
	});

	const updatedSearchResults = [...updatedOldPages, ...newPages];

	return {
		...state,
		results: [...updatedSearchResults],
	};
};

const setSearchNumFound = (state, action) => ({
	...state,
	numFound: action.numFound,
});

const setSearchIsLoading = (state, action) => ({
	...state,
	isLoading: action.isLoading,
});

export const searchReset = (state, action) => ({
	...state,
	...action,
	results: [],
});

export const setAutoRunIsEnabled = (state, action) => ({
	...state,
	isAutoRunEnabled: action.isAutoRunEnabled,
});

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SEARCH_SET_PARAMS:
			return searchSetParams(state, action);
		case SEARCH_SET_RESULTS:
			return setSearchResults(state, action);
		case SEARCH_SET_NUM_FOUND:
			return setSearchNumFound(state, action);
		case SEARCH_SET_IS_LOADING:
			return setSearchIsLoading(state, action);
		case SEARCH_RESET:
			return searchReset(state, action);
		case SEARCH_SET_AUTO_RUN:
			return setAutoRunIsEnabled(state, action);
		default:
			break;
	}
	return state;
};

export default reducer;
