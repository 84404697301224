/* eslint-disable max-len */
import { useEffect } from 'react';
import { postFile } from 'services/assetService';
import { translations } from 'translation/en';
import { showToast } from 'utils/toastUtil';
import { generateFoldersByPath } from 'utils/contentFragmentUtil';
import useBrandDetails from './useBrandDetails';
import { useRequest } from './useRequest';
import useApprovePublish from './useApprovePublish';
import useBrandData from './useBrandData';
import { useDisapprovePage } from './useDisapprovePage';
import { useVerifyFileStatus } from './useVerifyFileStatus';

export const useUploadFile = () => {
	const { brand } = useBrandData();
	const { currentType } = useBrandDetails();
	const { isPageApproved, handleStaleStatus } = useApprovePublish();
	const { checkFileStatus, loadingVerification } = useVerifyFileStatus();

	const { loading: loadingUpload, response, error, run, clear } = useRequest(postFile);
	const { isLoadingDisapprovePage, disapprovePage } = useDisapprovePage();

	useEffect(() => {
		if (response) {
			clear();
		}
	}, [response, clear]);

	useEffect(() => {
		if (error) {
			clear();
			showToast({ type: 'error', message: translations.FAILED_UPLOAD_FILE });
		}
	}, [error, clear]);

	const uploadFile = ({ fileData, relatedPath }) => {
		const parentPath = generateFoldersByPath({
			basePath: brand?.parentPath,
			chapterPath: currentType?.chapterPath,
			sectionPath: currentType?.sectionPath,
			articlePath: currentType?.articlePath,
			paragraphPath: relatedPath,
			isAsset: true,
		});

		const formData = new FormData();
		formData.append('parentVcmid', currentType.vcmId);
		formData.append('parentPath', parentPath);
		formData.append('propertyName', 'RELATOR_FILE_BEG_ID');
		formData.append('damAsset', fileData.file);
		formData.append('fileLinkText', fileData.linkText);
		formData.append('dispOrder', fileData.displayOrder);
		formData.append('relatedPath', relatedPath);

		const uploadFilePromise = run({
			pagePath: currentType?.path,
			cfType: `${currentType?.type?.toLowerCase()}`,
			data: formData,
		}).then(async resp => {
			if (resp && resp?.data) {
				const status = await checkFileStatus(resp.data.processId);
				return status;
			}
			return null;
		});

		let disapprovePagePromise = Promise.resolve();
		if (isPageApproved) {
			disapprovePagePromise = disapprovePage([currentType]);
		}

		return Promise.all([uploadFilePromise, disapprovePagePromise]).then(responses => {
			const isSuccess = responses.some(res => res?.data);

			if (isSuccess) {
				handleStaleStatus(true);
			}

			return responses;
		});
	};

	return {
		loadingUpload: [loadingUpload, isLoadingDisapprovePage, loadingVerification].some(Boolean),
		response,
		error,
		uploadFile,
	};
};
