import { useEffect, useMemo } from 'react';
import { showToast } from 'utils/toastUtil';
import { translations } from 'translation/en';
import { getCheckTermsAndConditions } from 'services/termsAndConditionsService';
import { is24SinceLastAccess } from 'utils/termsAndConditionsUtil';
import { isEmpty } from 'utils/objectUtils';
import { useRequest } from './useRequest';
import { useAuthAccess, useAuthData } from './useAuthData';

export const useCheckTermsAndConditions = () => {
	const { user } = useAuthData();
	const { isContractor } = useAuthAccess();
	const {
		loading: loadingCheckTermsAndConditions,
		data: response,
		error,
		clear,
	} = useRequest(() => getCheckTermsAndConditions({ globalId: user?.globalId }), {
		autoRun: true,
	});

	useEffect(() => {
		if (error) {
			clear();
			showToast({ type: 'error', message: translations.SERVICE_FAILURE });
		}
	}, [error, clear]);

	const checkTermsAndConditions = useMemo(() => {
		return response?.data;
	}, [response]);

	const isShowTermsAndConditions = useMemo(() => {
		if (isEmpty(response?.data) && typeof response?.data !== 'undefined') {
			return true;
		}

		return response?.data && isContractor && is24SinceLastAccess(response?.data?.dateAccepted);
	}, [response, isContractor]);

	return { loadingCheckTermsAndConditions, checkTermsAndConditions, isShowTermsAndConditions };
};
