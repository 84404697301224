import PropTypes from 'prop-types';
import React from 'react';
import * as S from './Modal.style';

const Modal = ({ children, containerStyle, hideExpand, style, ...props }) => {
	return (
		<S.Modal hideExpand={hideExpand} styles={style} {...props}>
			<S.Container style={containerStyle}>{children}</S.Container>
		</S.Modal>
	);
};

Modal.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	onClose: PropTypes.func,
	hideExpand: PropTypes.bool,
	containerStyle: PropTypes.objectOf(Object),
	style: PropTypes.objectOf(Object),
};

Modal.defaultProps = {
	children: null,
	hideExpand: true,
	containerStyle: null,
	style: null,
	onClose: () => null,
};

const Header = ({ children, variant, style, showDivider }) => {
	const renderVariantHeader = () => {
		let titleComponent;

		switch (variant) {
			case 'h1':
				titleComponent = children && <S.Heading1>{children}</S.Heading1>;
				break;
			case 'h2':
				titleComponent = children && <S.Heading2>{children}</S.Heading2>;
				break;
			case 'h4':
				titleComponent = children && <S.Heading4>{children}</S.Heading4>;
				break;
			default:
				break;
		}

		return titleComponent;
	};

	return (
		<>
			<S.Header style={style}>{renderVariantHeader()}</S.Header>
			{showDivider && children && <S.Divider />}
		</>
	);
};

Header.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	onClose: PropTypes.func,
	variant: PropTypes.oneOf(['h1', 'h2', 'h4']),
	style: PropTypes.objectOf(Object),
	showDivider: PropTypes.bool,
};

Header.defaultProps = {
	children: '',
	variant: 'h1',
	onClose: () => null,
	style: null,
	showDivider: true,
};

const Main = ({ children, style }) => children && <S.Main style={style}>{children}</S.Main>;

Main.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	style: PropTypes.objectOf(Object),
};

Main.defaultProps = {
	children: null,
	style: null,
};

const Footer = ({ children, style }) => children && <S.Footer style={style}>{children}</S.Footer>;

Footer.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	style: PropTypes.objectOf(Object),
};

Footer.defaultProps = {
	children: null,
	style: null,
};

Modal.Header = Header;
Modal.Main = Main;
Modal.Footer = Footer;

export { Modal };
