import PropTypes from 'prop-types';
import React from 'react';
import { BrandSideBarArticle } from './brandSideBarArticle';
import './BrandSideBarArticles.scss';

const BrandSideBarArticles = ({ articles, ...rest }) => {
	if (!articles) return null;
	return (
		<ul className="BrandSideBarArticles">
			{articles.map(article => (
				<BrandSideBarArticle
					key={article.vcmId + article.relatorPath + article.path + article.displayOrder + article.title}
					{...article}
					{...rest}
				/>
			))}
		</ul>
	);
};

BrandSideBarArticles.propTypes = {
	articles: PropTypes.array,
};

BrandSideBarArticles.defaultProps = {
	articles: [],
};

export { BrandSideBarArticles };
