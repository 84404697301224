import React from 'react';
import { arrayOf, bool, func, node, shape, string } from 'prop-types';
import defaultTranslations from '../../constants/defaultTranslations';
import { EditMenuHeader } from '../editMenuHeader/EditMenuHeader';
import './EditMenuPublishOptions.scss';

export const EditMenuPublishOptions = ({
	checkIcon,
	isPagePublish,
	loadingIcon,
	publishOptions,
	publishStatus,
	translations,
	handleOpenModal,
}) => {
	const { STATUS, THIS_PAGE } = translations;

	return (
		<div className="EditMenuPublishOptions">
			<EditMenuHeader isRedBackground={!isPagePublish}>
				{STATUS}: {publishStatus}
			</EditMenuHeader>

			<div className="EditMenuPublishOptions__publish-buttons">
				{publishOptions.map(({ key, text, onClick, disabled, loading }) => (
					<button
						key={key}
						onClick={() => {
							if (text === translations.UNPUBLISH) {
								onClick();
							}
							if (text === translations.PUBLISH) {
								handleOpenModal();
							}
						}}
						disabled={disabled}
					>
						{!loading && checkIcon}
						{loading ? loadingIcon : `${text} ${THIS_PAGE}`}
					</button>
				))}
			</div>
		</div>
	);
};

EditMenuPublishOptions.propTypes = {
	checkIcon: node,
	isPagePublish: bool,
	loadingIcon: node,
	publishOptions: arrayOf(
		shape({
			key: string,
			text: string,
			onClick: func,
			disabled: bool,
			loading: bool,
		})
	).isRequired,
	publishStatus: string.isRequired,
	translations: shape({
		APPROVE: string,
		APPROVED: string,
		CLEAR_CACHE: string,
		STATUS: string,
		STOP_EDITING: string,
		THIS_PAGE: string,
		UNAPPROVE: string,
		UNAPPROVED: string,
		PUBLISH: string,
		PUBLISHED: string,
		UNPUBLISH: string,
		UNPUBLISHED: string,
		STALE: string,
	}),
	handleOpenModal: func.isRequired,
};

EditMenuPublishOptions.defaultProps = {
	checkIcon: null,
	isPagePublish: false,
	loadingIcon: null,
	translations: defaultTranslations,
};
