import { func, shape } from 'prop-types';
import { Link } from 'react-router-dom';
import routers from 'router/router';
import { CheckBox } from 'components/checkBox';
import useBrandData from 'hooks/useBrandData';
import useBrandDetails from 'hooks/useBrandDetails';
import useParagraphs from 'hooks/useParagraphs';
import { useUserData } from 'hooks/useAuthData';
import { useEditMenuData } from 'hooks/useEditMenuData';
import { translations } from 'translation/en';
import { EDIT_MENU_TYPES } from 'components/begEditMenu/constants/begEditMenuConstants';
import './BrandsItems.scss';

const BrandsItems = ({ items, onSelected, onClose }) => {
	const { brandId } = useBrandData();
	const { selectedToEdit } = useEditMenuData();
	const { isUserAllowedToEdit } = useUserData();
	const { onResetChapters } = useBrandDetails();
	const { onResetParagraphs } = useParagraphs();

	const handleRedirect = item => {
		if (brandId !== item.brandId) {
			onResetChapters();
		}
	};

	const renderName = item => {
		const shouldShowName = selectedToEdit === EDIT_MENU_TYPES.EDIT_PUBLISH_BRANDS && isUserAllowedToEdit;

		return shouldShowName && <span className="BrandsItems__item-name BrandItems__fade-in">{item.name}</span>;
	};

	const renderCheckbox = item => {
		const itemIsComingSoon = item.isComingSoon;

		if (itemIsComingSoon) {
			return null;
		}

		const shouldShowCheckbox = selectedToEdit === EDIT_MENU_TYPES.EDIT_PUBLISH_BRANDS && isUserAllowedToEdit;

		return (
			shouldShowCheckbox && (
				<div className="BrandsItems__container-checkbox BrandItems__fade-in">
					<CheckBox variant="secondary" onChange={onSelected(item)} key={item?.path} />
				</div>
			)
		);
	};

	return (
		<nav className="BrandsItems">
			{items.rows?.map(row => (
				<div key={row?.key} className="BrandsItems__row">
					{row.data?.map(item => (
						<div key={item?.path} className="BrandsItems__item">
							<div className="BrandsItems__item-content" onClick={onClose} role="presentation">
								<div className="BrandsItems__item-container-name">
									{item.name && (
										<>
											{renderName(item)}
											{renderCheckbox(item)}
										</>
									)}
								</div>
								{item.isComingSoon ? (
									<img className="BrandsItems__item-image" src={item?.imgSrc} style={item?.style} alt="" />
								) : (
									<div className="BrandsItems__item-container">
										<Link
											className="BrandsItems__item-link"
											to={`${routers.brandROUTE.getRouteDefaultPath()}/${item?.path}`}
											onClick={() => {
												onResetParagraphs?.();
												handleRedirect(item);
											}}
										>
											<img
												className="BrandsItems__item-image"
												src={item?.imgSrc}
												style={item?.style}
												alt=""
											/>
										</Link>
									</div>
								)}
							</div>
							{item.isComingSoon && (
								<div className="BrandsItems__item-coming-soon">
									<p className="BrandsItems__item-coming-soon__text">{translations.COMING_SOON_TEXT}</p>
								</div>
							)}
						</div>
					))}
				</div>
			))}
		</nav>
	);
};

BrandsItems.propTypes = {
	items: shape({}),
	onSelected: func,
	onClose: func,
};

BrandsItems.defaultProps = {
	items: {},
	onSelected: () => {},
	onClose: () => {},
};

export { BrandsItems };
