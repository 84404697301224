import { useMemo } from 'react';
import useBrandDetails from 'hooks/useBrandDetails';
import useParagraphs from 'hooks/useParagraphs';
import useQuickSheet from 'hooks/useQuickSheet';

export const useModules = () => {
	const { isLoadingChapters, isPageContentLoading, currentType } = useBrandDetails();
	const { isParagraphLoading, paragraphs } = useParagraphs();
	const { loadingRows, rows } = useQuickSheet();

	const paragraphsArr = useMemo(() => {
		return currentType?.quickSheetPath && rows?.paragraphs ? rows?.paragraphs : paragraphs?.paragraphs;
	}, [currentType?.quickSheetPath, paragraphs?.paragraphs, rows?.paragraphs]);

	return {
		loadingModules: [isLoadingChapters, isPageContentLoading, loadingRows].some(Boolean),
		loadingModule: isParagraphLoading,

		paragraphsArr,
	};
};
