import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import './Logo.scss';

const Logo = ({ image, to, size, onClick }) => {
	const handleClick = e => {
		e.preventDefault();
		onClick(e);
	};

	return (
		<div className="Logo">
			<Link to={to} className="Logo__link" onClick={e => onClick && handleClick(e)}>
				<img src={image} alt="" {...size} />
			</Link>
		</div>
	);
};

Logo.propTypes = {
	image: PropTypes.string,
	to: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.object]),
	onClick: PropTypes.func,
};

Logo.defaultProps = {
	image: '',
	to: '',
	size: {
		width: '148px',
		height: '50px',
	},
	onClick: () => null,
};

export { Logo };
