import { useRef } from 'react';

const useDebounce = () => {
	const timeout = useRef(null);

	const debounce = (callback, delay = 0) => {
		clearTimeout(timeout.current);
		timeout.current = setTimeout(() => {
			callback();
		}, delay);
	};

	return { debounce };
};

export { useDebounce };
