import styled from 'styled-components/macro';

export const DropDown = styled.section(() => ({
	width: '100%',
}));

export const Container = styled.div(() => ({
	margin: 0,
	padding: 0,
	height: 'auto',
	width: '100%',
}));

export const Head = styled.div(({ theme: { breakpoint } }) => ({
	display: 'inline',
	border: '0',
	background: 'none',
	height: 'inherit',
	width: '100%',

	'> *': {
		cursor: 'pointer',
	},

	[breakpoint.lg()]: {
		minWidth: '100px',
	},
}));

export const Wrapper = styled.div(({ inverted, theme: { color } }) => ({
	display: 'flex',
	padding: '15px 20px',
	flexDirection: inverted ? 'row-reverse' : 'row',
	width: 'fit-content',
	minWidth: 'inherit',
	justifyContent: 'space-between',
	alignItems: 'baseline',
	color: color.interactive.dropDownButtons?.default.label,

	':focus-within': {
		outline: `2px solid ${color.interactive.dropDownButtons?.focusWithin.border}`,
		background: color.interactive.dropDownButtons?.focusWithin.background,
		color: color.interactive.dropDownButtons?.focusWithin.label,
	},

	':active': {
		boxShadow: `inset 0 3px 5px ${color.interactive.dropDownButtons?.active.color}`,
		color: `${color.interactive.dropDownButtons?.active.label}!important`,
	},

	':hover': {
		background: color.interactive.dropDownButtons?.hover.background,
		color: color.interactive.dropDownButtons?.hover.label,
	},

	'> *': {
		cursor: 'pointer',
	},
}));

export const Body = styled.main(() => ({
	flexDirection: 'column',
	flex: 1,
	alignItems: 'stretch',
	justifyContent: 'stretch',
	overflow: 'hidden',
	visibility: 'hidden',
	height: 'auto',
	maxHeight: 0,
	transition: 'all 1s ease-out',

	'&.expanded': {
		visibility: 'visible',
		overflow: 'visible',
		transition: 'all 1s ease-in',
		maxHeight: '3000px',
	},
}));

export const Icon = styled.i(({ isExpanded }) => ({
	display: 'block',
	transform: isExpanded ? 'rotate(180deg)' : 'none',
	transition: 'transform 0.6s ease',
}));
