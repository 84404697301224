import React from 'react';
import { arrayOf, node, oneOfType } from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import './CheckListModalSkeleton.scss';

const CheckListItemSkeleton = () => (
	<div className="CheckListModalSkeleton__item">
		<div className="CheckListModalSkeleton__checkbox">
			<Skeleton height={15} width={15} />
		</div>
		<div className="CheckListModalSkeleton__label">
			<Skeleton height={15} width={180} />
		</div>
	</div>
);

const CheckListContentSkeleton = ({ children }) => (
	<div className="CheckListModalSkeleton__list-wrapper">
		<div className="CheckListModalSkeleton__list-header">
			<CheckListItemSkeleton />
		</div>
		<div className="CheckListModalSkeleton__list-wrapper">
			<CheckListItemSkeleton />
			<CheckListItemSkeleton />
			<CheckListItemSkeleton />
		</div>
		{children}
	</div>
);

const CheckListModalSkeleton = () => (
	<div className="CheckListModalSkeleton">
		<div className="CheckListModalSkeleton__description">
			<Skeleton height={15} width={400} />
		</div>
		<div className="CheckListModalSkeleton__list">
			<CheckListContentSkeleton />
			<CheckListContentSkeleton>
				<CheckListItemSkeleton />
				<CheckListItemSkeleton />
			</CheckListContentSkeleton>
		</div>
	</div>
);

CheckListContentSkeleton.propTypes = {
	children: oneOfType([arrayOf(node), node]),
};

CheckListContentSkeleton.defaultProps = {
	children: null,
};

export { CheckListModalSkeleton };
