import { useEffect, useMemo, useState, useRef, useCallback } from 'react';
import { disapproveAsync, disapproveContent, getReplicateStatusByOperationId } from 'API/publishAPI';
import { translations } from 'translation/en';
import { showToast } from 'utils/toastUtil';
import { isEmpty } from 'utils/arrayUtil';
import { doItByType } from 'utils/contentFragmentUtil';
import { getRelationsByPathAndType } from 'utils/approveUtil';
import { PAGE_TYPES } from 'constants/pageConstants';
import { useRequest } from './useRequest';
import useBrandDetails from './useBrandDetails';
import useApprovePublish from './useApprovePublish';
import useClearCache from './useClearCache';

const FIVE_SECONDS = 5000;

export const useDisapprovePage = () => {
	const initialState = useMemo(
		() => ({
			loading: false,
			data: null,
			error: null,
		}),
		[]
	);

	const { currentType } = useBrandDetails();
	const { setIsPageApproved, handleStaleStatus } = useApprovePublish();

	const { loadingDisapprove, error: disapproveError, run: postDisapprove } = useRequest(disapproveContent);
	const {
		loading: loadingDisapproveAsync,
		error: disapproveErrorAsync,
		run: postDisapproveAsync,
	} = useRequest(disapproveAsync);
	const {
		loading: loadingReplicateStatus,
		error: replicateStatusError,
		run: fetchReplicateStatus,
	} = useRequest(getReplicateStatusByOperationId);

	const intervalRef = useRef();
	const [requestData, setRequestData] = useState(initialState);
	const { clearCache } = useClearCache();

	const stopInterval = () => {
		clearInterval(intervalRef.current);
	};

	const startInterval = useCallback(
		(operationPrefix, operationId) => {
			intervalRef.current = setInterval(async () => {
				const operation = `${operationPrefix}${operationId}`;

				return fetchReplicateStatus(operation)
					.then(response => {
						if (isEmpty(response?.data)) {
							stopInterval();
							clearCache({ successCallback: () => {} });
							setIsPageApproved(false);
							handleStaleStatus(true);
							setRequestData({ ...initialState, data: response });
						}
					})
					.catch(err => {
						stopInterval();
						setRequestData({ ...initialState, error: err });
					});
			}, FIVE_SECONDS);
		},
		[initialState, setIsPageApproved, handleStaleStatus, fetchReplicateStatus, clearCache]
	);

	const sendPathsToDisapprove = useCallback(
		pathsToDisapprove => {
			return postDisapproveAsync(pathsToDisapprove)
				.then(response => {
					if (response.data) {
						const { operationId, operationPrefix } = response.data;
						startInterval(operationPrefix, operationId);
					}
				})
				.catch(err => {
					setRequestData({ ...initialState, error: err });
				});
		},
		[initialState, postDisapproveAsync, startInterval]
	);

	const disapprovePageTree = useCallback(
		async itemsToDisapprove => {
			setRequestData({ ...initialState, loading: true });

			let pathsToDisapprove = [];
			const promises = [];

			if (currentType?.quickSheetPath) {
				pathsToDisapprove = [currentType?.chapterPath, currentType?.quickSheetPath];
			} else {
				const paths = itemsToDisapprove.map(cf => cf.path);
				const results = itemsToDisapprove.map(({ path, type }) => getRelationsByPathAndType({ path, type }));

				const pathsPromise = Promise.all(results).then(resultArray => {
					pathsToDisapprove = [...pathsToDisapprove, ...resultArray.flat()];
				});

				promises.push(pathsPromise);
				await pathsPromise;

				itemsToDisapprove.forEach(({ type }) => {
					if (type !== PAGE_TYPES.CHAPTER_TYPE) {
						const relatorPath = doItByType(type, {
							doItForChapter: null,
							doItForSection: currentType?.sectionRelatorPath,
							doItForArticle: currentType?.articleRelatorPath,
						});

						if (relatorPath) {
							pathsToDisapprove = [...pathsToDisapprove, relatorPath];
						}
					}
				});

				pathsToDisapprove = [...paths, ...pathsToDisapprove];
			}

			const promise = sendPathsToDisapprove(pathsToDisapprove);
			await promise;
			promises.push(promise);

			return Promise.all(promises);
		},
		[initialState, currentType, sendPathsToDisapprove]
	);

	const disapprovePage = useCallback(
		async itemsToDisapprove => {
			setRequestData({ ...initialState, loading: true });

			let pathsToDisapprove = itemsToDisapprove.map(cf => cf.path);

			if (currentType?.quickSheetPath) {
				pathsToDisapprove = [currentType?.chapterPath, currentType?.quickSheetPath];
			}

			return postDisapprove(pathsToDisapprove)
				.then(response => {
					if (response.data) {
						clearCache({ successCallback: () => {} });
						handleStaleStatus(true);
						setIsPageApproved(false);
						setRequestData({ ...initialState, data: response });
					}
				})
				.catch(err => {
					setRequestData({ ...initialState, error: err });
				});
		},
		[initialState, currentType, setIsPageApproved, handleStaleStatus, postDisapprove, clearCache]
	);

	const isLoadingDisapprovePage = useMemo(
		() => [loadingDisapprove, loadingDisapproveAsync, loadingReplicateStatus, requestData.loading].some(Boolean),
		[loadingDisapprove, loadingDisapproveAsync, loadingReplicateStatus, requestData.loading]
	);

	const isErrorDisapprovePage = useMemo(
		() => [disapproveError, disapproveErrorAsync, replicateStatusError, requestData.error].some(Boolean),
		[disapproveError, disapproveErrorAsync, replicateStatusError, requestData.error]
	);

	const disapprovedPage = useMemo(() => requestData.data, [requestData.data]);

	useEffect(() => {
		if (isErrorDisapprovePage) {
			showToast({ type: 'error', message: translations.ERROR_WHEN_DISAPPROVING_CONTENT });
		}
	}, [initialState, isErrorDisapprovePage]);

	return { disapproveLoading: isLoadingDisapprovePage, disapprovedPage, disapprovePage, disapprovePageTree };
};
