import { func, shape, string } from 'prop-types';
import { BrandsItems } from '../brandsItems';
import './BrandsSection.scss';

const BrandsSection = ({ title, items, onSelected, ...props }) => {
	return (
		<section className="BrandsSection">
			{title && (
				<header className="BrandsSection__header">
					<div className="BrandsSection__section-title">
						<span className="BrandsSection__title">{title}</span>
					</div>
				</header>
			)}
			<BrandsItems items={items} onSelected={onSelected} {...props} />
		</section>
	);
};

BrandsSection.propTypes = {
	title: string,
	items: shape({}),
	handleClose: func,
	onSelected: func.isRequired,
};

BrandsSection.defaultProps = {
	title: '',
	items: {},
	handleClose: () => {},
};

export { BrandsSection };
