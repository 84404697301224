export const hasText = text => {
	if (!text || !text.length) {
		return false;
	}
	return text.trim().length > 0;
};

export const textEllipsis = (text, textLimit) => {
	if (hasText(text) && text.length > textLimit + 1) {
		return `${text.slice(0, textLimit - text.length - 4)}...`;
	}
	return text;
};

export const isString = str => typeof str === 'string';

export const toUpperCaseFirstLetter = str => {
	const result =
		str && str.length
			? str
					.toLowerCase()
					.split(' ')
					.reduce((p, s) => `${p}  ${s.charAt(0).toUpperCase()}${s.slice(1)}`, '')
			: '';
	return result;
};
