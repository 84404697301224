import PropTypes from 'prop-types';
import React from 'react';
import { LogoLoading } from 'components/logoLoading';
import HyattConnectLogo from 'assets/img/logos/hc.svg';
import * as S from './LoadingOverlay.style';
import './LoadingOverlayLogo.scss';

const Loading = ({ children }) => <div>{children}</div>;

Loading.propTypes = {
	children: PropTypes.node,
};

Loading.defaultProps = {
	children: null,
};

const Overlay = ({ children, isFullScreen, optionalClassName }) => (
	<S.LoadingOverlay isFullScreen={isFullScreen} className={optionalClassName}>
		{children}
	</S.LoadingOverlay>
);

Overlay.propTypes = {
	children: PropTypes.node,
	isFullScreen: PropTypes.bool,
	optionalClassName: PropTypes.string,
};

Overlay.defaultProps = {
	children: null,
	isFullScreen: false,
	optionalClassName: '',
};

const LoadingLogo = ({ size, logo }) => {
	return (
		<div className="LoadingLogo">
			<LogoLoading image={logo} size={size} />
		</div>
	);
};

LoadingLogo.propTypes = {
	logo: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.object]),
};

LoadingLogo.defaultProps = {
	logo: HyattConnectLogo,
	size: {
		width: '148px',
		height: '50px',
	},
};

Loading.Overlay = Overlay;
Loading.LoadingLogo = LoadingLogo;

export { Loading };
