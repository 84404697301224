import { useEffect, createContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useBrandDetails from 'hooks/useBrandDetails';
import { useRowsByQuickSheet } from 'hooks/useRowsByQuickSheet';

export const QuickSheetContext = createContext({});

const QuickSheetProvider = ({ children }) => {
	const { pathname } = useLocation();
	const { currentType } = useBrandDetails();
	const { loadingRows, rows, fetchRows, clearRows } = useRowsByQuickSheet();

	useEffect(() => {
		if (currentType?.quickSheetPath) {
			fetchRows({
				operation: currentType?.sectionTitle,
				topic: currentType?.articleTitle,
				path: currentType?.quickSheetPath,
			});
		}
	}, [currentType, fetchRows, clearRows]);

	useEffect(() => {
		if (pathname) {
			clearRows();
		}
	}, [pathname, clearRows]);

	const valueProvider = useMemo(
		() => ({
			loadingRows,
			rows,
		}),
		[rows, loadingRows]
	);

	return <QuickSheetContext.Provider value={valueProvider}>{children}</QuickSheetContext.Provider>;
};

QuickSheetProvider.propTypes = {
	chapters: PropTypes.array,
	children: PropTypes.node.isRequired,
};

QuickSheetProvider.defaultProps = {
	chapters: [],
};

export { QuickSheetProvider };
