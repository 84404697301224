import BackgroundHeader from 'assets/img/background-header-world.png';
import complementaryTheme from 'themes/complementary/complementary';

const { neutral } = complementaryTheme;

const colors = {
	background: {
		banner: {
			background: `url(${BackgroundHeader}) no-repeat`, // header
			backgroundSize: '100% 100%',
		},
		secondary: '#F2F3F7',
		tertiary: '#F7F7F7',
	},
	interactive: {
		primary: {
			default: {
				background: '#0072ce',
				label: '#fff',
				border: '#0072ce',
			},
			hover: {
				background: '#fff',
				label: '#0072ce',
				border: '#0072ce',
			},
		},
		primary02: {
			default: {
				background: '#0072ce',
				label: '#fff',
				border: '#0072ce',
				opacity: '0.65',
			},
		},
		// file button
		primary05: {
			default: {
				background: '#0072ce',
				label: '#fff',
				border: '#0072ce',
			},
			hover: {
				background: '#fff',
				label: '#0072ce',
				border: '#0072ce',
			},
		},
		secondary: {
			default: {
				background: '#F2F3F4',
				label: '#0072ce',
				border: '#0072ce',
			},
			hover: {
				background: '#F2F3F4',
				label: '#0072ce',
				border: '#0072ce',
			},
		},
		secondary02: {
			default: {
				background: '#F2F3F4',
				label: '#0072ce',
			},
			hover: {
				background: '#F2F3F4',
				label: '#0072ce',
			},
		},
		// button approve modal
		secondary03: {
			default: {
				background: '#333333',
				label: '#fff',
				border: '#333333',
			},
			hover: {
				background: '#fff',
				label: '#333333',
				border: '#333333',
			},
		},
		tertiary: {
			default: {
				background: '#000',
				label: '#FFF',
			},
		},
		printModalButtons: {
			default: {
				background: '#0072ce',
				label: '#fff',
				border: '#0072ce',
			},
			hover: {
				background: '#fff',
				label: '#0072ce',
				border: '#0072ce',
			},
		},
		// properties and edit image modals buttons
		tertiary03: {
			default: {
				background: '#fff',
				label: '#181818',
				border: '#ccc',
			},
			hover: {
				background: '#fff',
				label: '#181818',
				border: '#181818',
			},
		},
		outlined: {
			default: {
				label: '#717171',
				icon: '#717171',
				border: '#717171',
			},
		},
		inverse: {
			default: {
				label: '#000',
			},
		},
		discardChangesButtons: {
			default: {
				background: '#0072ce',
				label: '#fff',
				border: '#0072ce',
			},
			hover: {
				background: '#fff',
				label: '#0072ce',
				border: '#0072ce',
			},
		},
		printPageButtons: {
			default: {
				background: '#0072ce',
				label: '#fff',
				border: '#0072ce',
			},
			hover: {
				background: '#fff',
				label: '#0072ce',
				border: '#0072ce',
			},
			active: {
				background: 'red',
				color: '#00000020',
				label: '#262626',
			},
		},
		dropDownButtons: {
			default: {
				label: '#000000',
			},
			active: {
				background: 'transparent',
				color: '#00000020',
				label: '#262626',
			},
			focusWithin: {
				background: 'transparent',
				label: '#000000',
				border: 'none',
			},
			hover: {
				background: 'transparent',
				label: '#000000',
				border: 'none',
			},
		},

		lightest: 'rgba(255, 255, 255, 0.1)',
	},
	field: {
		// search input
		primary: {
			text: '#000',
			placeholder: '#0072ce',
		},
		// inputs edit image modal
		secondary: {
			text: '#676767',
			placeholder: '#0072ce',
		},
		// input properties modal
		tertiary: {
			text: '#676767',
			placeholder: '#676767',
		},
	},
	text: {
		primary: '#000',
		primary02: '#000', // title chapter, section page

		titleModule: '#000',
		headline01: '#000',
		headline02: '#000',
		headline03: '#000',
		headline04: '#0072ce',
		headline05: '#000',
		headline06: '#000',

		paragraph: '#000',
		specificationTitle: '#0072ce', // specification title

		approveModalTitle: '#000',
		approveModalDescription: '#000',

		addFilesSubtitle: '#000',

		secondary: '#000',
		secondary02: '#000',
		secondary03: '#000', // edit image modal title

		tertiary: '#000',
		tertiary03: '#000', // title properties modal

		quaternary: '#000',
		quaternary02: '#333', // labels subtitle edit image modal
		quaternary03: '#000', // labels justification edit image modal

		quinary: '#333',
		quinary02: '#333', // subtitles properties modal
		quinary03: '#333', // title remove modal
		senary: '#000', // title article page

		bullet: '#00000', // list item bullet

		imageCaption: '#000',
	},
	link: {
		primary: {
			default: {
				label: '#000',
			},
			hover: {
				label: '#000',
			},
		},
		secondary: {
			default: {
				label: '#000',
			},
			hover: {
				background: 'rgba(255, 255, 255, 0.1)',
				label: '#000',
			},
			active: {
				label: '#000',
			},
		},
		tertiary: {
			default: {
				label: '#000',
			},
			hover: {
				background: 'rgba(255, 255, 255, 0.1)',
				label: '#000',

				mobile: {
					label: '#000',
				},
			},
			active: {
				label: '#000',
			},
		},
		quaternary: {
			default: {
				label: '#000',
			},
			hover: {
				label: '#000',
			},
			active: {
				label: '#0072ce',
			},
		},
	},
	icon: {
		primary: '#000',
		primary02: '#898b8e',
		chapterArrow: '#000',
		sectionArrow: '#000', // section arrow icon
		breadcrumbArrow: '#555555', // breadcrumb arrow icon
	},
	border: {
		primary: '#0072ce',
		secondary: '#B9b5aa',
		tertiary: '#333',
		quaternary: '#eee',
	},
	shadow: {
		primary: 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6)',
		secondary: 'inset 0 1px 0 rgba(255, 255, 255, 0.1)',
	},
	button: {
		primary: '#0072ce',
	},
	neutral,
	table: {
		row: {
			background: {
				primary: '#f2f3f4',
				secondary: 'transparent',
			},
			cell: {
				label: '#333333',
				border: '2px solid #898b8e',
			},
		},
	},
};

export default colors;
