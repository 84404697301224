import { useDispatch } from 'react-redux';
import { editMenuSetSelectedToEdit } from 'actions/editMenuAction';
import { Base } from 'components/base';
import { BegEditMenu } from 'components/begEditMenu';
import { EditNavigation } from 'components/editNavigation';
import { FloatingButton } from 'components/floatingButton';
import { TermsConditionsModal } from 'components/termsConditionsModal';
import { useOnBrandRouteChange } from 'hooks/useOnBrandRouteChange';
import { useUserData } from 'hooks/useAuthData';
import { BrandFooter } from './components/brandFooter';
import { BrandHeader } from './components/brandHeader';
import { BrandMain } from './components/brandMain';
import { BrandSideBar } from './components/brandSideBar';
import { BrandDetailsProvider } from './context/BrandDetailsContext';
import { ParagraphsProvider } from './context/ParagraphsContext';
import './BrandDetails.scss';

const BrandDetails = () => {
	const dispatch = useDispatch();
	const { isUserAllowedToEdit } = useUserData();

	useOnBrandRouteChange(() => dispatch(editMenuSetSelectedToEdit({ selectedToEdit: '' })));

	return (
		<BrandDetailsProvider>
			<ParagraphsProvider>
				<BrandHeader />
				<Base>
					<section className="BrandDetails">
						<EditNavigation>
							<aside className="BrandDetails__sidebar">
								<BrandSideBar />
							</aside>
						</EditNavigation>
						<main className="BrandDetails__content">
							<BrandMain />
						</main>
					</section>

					{isUserAllowedToEdit && <BegEditMenu />}

					<FloatingButton />
				</Base>
				<BrandFooter />

				<TermsConditionsModal action={{ primaryVariant: 'primary', secondaryVariant: 'primary' }} />
			</ParagraphsProvider>
		</BrandDetailsProvider>
	);
};

export default BrandDetails;
