import { ReactNode } from 'react';
import classNames from 'classnames';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './TabWrapper.scss';

type TabWrapperProps = {
	tabList: {
		tab: string;
		render: () => ReactNode;
	}[];
	className?: string;
	forceRenderTabPanel?: boolean;
	defaultIndex?: number;
};

export function TabWrapper({ tabList, className = '', forceRenderTabPanel = false, defaultIndex = 0 }: TabWrapperProps) {
	const classNamesTabs = classNames('TabWrapper', className);

	return (
		<Tabs defaultIndex={defaultIndex} className={classNamesTabs} forceRenderTabPanel={forceRenderTabPanel}>
			<TabList>
				{tabList.map(({ tab }) => (
					<Tab key={tab}>{tab}</Tab>
				))}
			</TabList>

			{tabList.map(({ tab, render }) => (
				<TabPanel key={tab}>{render()}</TabPanel>
			))}
		</Tabs>
	);
}
