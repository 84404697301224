import Skeleton from 'react-loading-skeleton';
import './PieChartWrapperSkeleton.scss';
import { node, bool } from 'prop-types';

export const PieChartWrapperSkeleton = ({ children, isLoading }) =>
	isLoading ? (
		<div className="PieChartWrapperSkeleton">
			<div className="PieChartWrapperSkeleton__chart">
				<Skeleton circle width={328} height={328} />
			</div>
			<div className="PieChartWrapperSkeleton__legend">
				{Array.from({ length: 4 }, (_, i) => (
					<div className="PieChartWrapperSkeleton__legend-item" key={i}>
						<Skeleton width={30} height={20} borderRadius={1} />
						<Skeleton width={80} height={10} borderRadius={1} />
					</div>
				))}
			</div>
		</div>
	) : (
		children
	);

PieChartWrapperSkeleton.propTypes = {
	children: node.isRequired,
	isLoading: bool.isRequired,
};
