import classNames from 'classnames';
import { array } from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { DAY_VALUE, REGION_VALUE } from '../../constants/modalAnalyticsConstants';
import { useAnalyticsContext } from '../../hooks/useAnalyticsContext';
import { endDateFormat, startDateFormat } from '../../utils/dateUtils';
import { textEllipsis, toUpperCaseFirstLetter } from '../../utils/stringUtil';
import './SideBarMetrics.scss';

const CHARACTER_LIMIT = 25;

export const SideBarMetrics = ({ listContentIds }) => {
	const context = useFormContext();
	const { activeId, setActiveId, locationType, getMetricsForChart } = useAnalyticsContext();

	const [isOpenList, setOpenList] = useState(false);

	const { watch } = context;

	const startDateWatch = watch('startDate');
	const endDateWatch = watch('endDate');

	const submit = useCallback(
		contentId => {
			const params = {
				startDate: startDateFormat(startDateWatch),
				endDate: endDateFormat(endDateWatch),
				type: locationType.value === DAY_VALUE ? REGION_VALUE : locationType.value,
				contentId,
			};

			setActiveId(contentId);
			getMetricsForChart(params, contentId);
		},
		[endDateWatch, getMetricsForChart, locationType, setActiveId, startDateWatch]
	);

	return (
		<div className="SidebarMetrics">
			<h2 className="SidebarMetrics__buttonType">
				{listContentIds[0].type}
				<button onClick={() => setOpenList(!isOpenList)}>
					<i className={isOpenList ? 'icon-close-slim' : 'icon-plus'} />
				</button>
			</h2>
			<ul
				className={classNames('SidebarMetrics__listButtons', {
					'SidebarMetrics__listButtons--open': isOpenList,
				})}
			>
				{listContentIds.map(({ title, contentId }) => (
					<li key={contentId}>
						<button
							onClick={() => submit(contentId)}
							className={classNames('SidebarMetricsButton', {
								'SidebarMetricsButton--active': contentId === activeId,
							})}
						>
							{toUpperCaseFirstLetter(textEllipsis(title, CHARACTER_LIMIT))}
						</button>
					</li>
				))}
			</ul>
		</div>
	);
};

SideBarMetrics.propTypes = {
	listContentIds: array.isRequired,
};
