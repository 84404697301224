import React, { useCallback } from 'react';
import { array, bool, func, string } from 'prop-types';
import { Pill } from '@hyatt/pill';
import './MultiFieldList.scss';

const MultiFieldList = ({ items, onRemove, itemPrefix, showItemPrefix }) => {
	const onClose = useCallback(
		(pill) => {
			if (onRemove instanceof Function) {
				onRemove(pill);
			}
		},
		[onRemove]
	);

	const getPillText = useCallback(
		(pill) => {
			if (showItemPrefix) return pill;
			return pill.replace(itemPrefix, '');
		},
		[itemPrefix, showItemPrefix]
	);

	return (
		<div className="MultiFieldList">
			{items.map((pill) => (
				<Pill
					key={pill}
					text={getPillText(pill)}
					hasClose={!!onRemove}
					onClose={() => onClose(pill)}
				/>
			))}
		</div>
	);
};

MultiFieldList.propTypes = {
	items: array.isRequired,
	onRemove: func,
	itemPrefix: string,
	showItemPrefix: bool,
};

MultiFieldList.defaultProps = {
	onRemove: null,
	itemPrefix: '',
	showItemPrefix: false,
};

export default MultiFieldList;
