import PropTypes, { string } from 'prop-types';
import React from 'react';
import './LogoLoading.scss';

const LogoLoading = ({ image, size }) => {
	return (
		<div className="LogoLoading">
			<img src={image} alt="" {...size} />
		</div>
	);
};

LogoLoading.propTypes = {
	image: PropTypes.string,
	size: PropTypes.shape({ width: string, height: string }),
};

LogoLoading.defaultProps = {
	image: '',
	size: {
		width: '148px',
		height: '50px',
	},
};

export { LogoLoading };
