import axios from 'axios';
import axiosRetry from 'axios-retry';
import Cookies from 'js-cookie';
import { translations } from 'translation/en';
import { combineAll } from 'utils/functionUtils';
import { showToast } from 'utils/toastUtil';
import { addTokenValidationInterceptor } from './interceptors/validTokenInterceptors';

export const createInstance = (baseURL, headers) =>
	axios.create({
		baseURL,
		headers: {
			'Content-Type': 'application/json',
			Authorization: Cookies.get(process.env.REACT_APP_TOKEN_KEY),
			hc_tk: Cookies.get(process.env.REACT_APP_TOKEN_KEY),
			...headers,
		},
		withCredentials: true,
	});

export const createRetryInstance = baseURL => {
	const client = axios.create({
		baseURL,
		headers: {
			'Content-Type': 'application/json',
			Authorization: Cookies.get(process.env.REACT_APP_TOKEN_KEY),
			hc_tk: Cookies.get(process.env.REACT_APP_TOKEN_KEY),
		},
		withCredentials: true,
	});
	axiosRetry(client, { retries: 4 });
	return client;
};

export const addInterceptorErrorToast = instance => {
	// unused at moment because of inexistent users in AEM, triggering unexpected server errors
	instance.interceptors.response.use(
		response => {
			return response;
		},
		error => {
			const dataErr = error?.response?.data || null;
			const errorMessage = dataErr ? dataErr.error || dataErr.message : null;
			const errorMessageFormatted = errorMessage ? `: ${errorMessage}` : '';

			showToast({ message: `${translations.SOMETHING_WENT_WRONG}${errorMessageFormatted}`, type: 'error' });
		}
	);

	return instance;
};

export const axiosWithRetry = combineAll([
	createRetryInstance,
	addTokenValidationInterceptor,
	//
]);

export default combineAll([
	createInstance,
	addTokenValidationInterceptor,
	//
]);
