import { useCallback } from 'react';
import { SEARCH_QUERY_PARAM_EXACT_MATCH, SEARCH_QUERY_PARAM_NAME } from 'constants/searchConstants';
import useBrandRoute from './useBrandRoute';

const useSearchRoute = () => {
	const { baseRoute } = useBrandRoute();

	const getSearchRoute = useCallback(
		(searchValue, isExactMatch) => {
			return `${baseRoute}/search/?${SEARCH_QUERY_PARAM_NAME}=${encodeURIComponent(
				searchValue
			)}&${SEARCH_QUERY_PARAM_EXACT_MATCH}=${isExactMatch}`;
		},
		[baseRoute]
	);

	return { baseRoute, getSearchRoute };
};

export default useSearchRoute;
