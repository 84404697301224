import { bool, func, string } from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';
import { Calendar as DateRangeCalendar } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { getFormattedDate } from '../../utils/dateUtils';
import './CalendarAnalytics.scss';

const CalendarAnalytics = forwardRef(({ dateValue, handleDateChange, isVisible }, ref) => {
	const [date, setDate] = useState(() => {
		if (!dateValue) return new Date();

		return new Date(dateValue);
	});

	useEffect(() => {
		setDate(new Date(dateValue));
	}, [dateValue]);

	return (
		isVisible && (
			<div ref={ref} className="CalendarAnalytics">
				<DateRangeCalendar
					onChange={item => {
						setDate(item);
						handleDateChange(getFormattedDate(item));
					}}
					date={date}
					color="#0085ce"
					editableDateInputs
				/>
			</div>
		)
	);
});

CalendarAnalytics.propTypes = {
	dateValue: string,
	handleDateChange: func.isRequired,
	isVisible: bool,
};

CalendarAnalytics.defaultProps = {
	dateValue: '',
	isVisible: false,
};

export { CalendarAnalytics };
