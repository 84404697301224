/* eslint-disable max-len */

export const PLUGINS =
	'preview searchreplace autolink save directionality code visualblocks visualchars fullscreen link image media table charmap pagebreak nonbreaking insertdatetime advlist lists wordcount help charmap quickbars emoticons';

export const TOOLBAR =
	'undo redo | bold italic underline strikethrough | fontsize blocks | alignleft aligncenter alignright alignjustify | numlist bullist | image link | table code';

export const API_KEY = process.env.REACT_APP_TINYMCE_API_KEY;

export const FONTS =
	'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Open Sans=open sans; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats';

export const CONTENT_STYLE = `
	@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;1,300;1,400;1,600&display=swap');

	body {
		font-family: 'Open Sans', sans-serif;
	}
`;

export const EMPTY_TITLE_BOGUS = '<h2><br data-mce-bogus="1"></h2>';
export const EMPTY_TITLE = '<h2><br></h2>';

export const EMPTY_PARAGRAPH_BOGUS = '<p><br data-mce-bogus="1"></p>';
export const EMPTY_PARAGRAPH = '<p><br></p>';
