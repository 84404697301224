export const employeeType = Object.freeze({
	CONTRACTOR: 'CONTRACTOR',
	EMPLOYEE: 'EMPLOYEE',
	FRANCHISE: 'FRANCHISE',
	FRANCHISEE: 'FRANCHISEE',
	OWNER: 'OWNER',
});

export const searchByOptions = {
	COMMON_NAME: { value: 'commonName', text: 'Global ID' },
	FIRST_NAME: { value: 'firstName', text: 'First Name' },
	LAST_NAME: { value: 'lastName', text: 'Last Name' },
	EMAIL: { value: 'email', text: 'E-mail' },
};
