class UserModel {
	constructor(opt = {}) {
		this.id = opt.id || null;
		this.commonName = opt.commonName || null;
		this.username = opt.username || null;
		this.name = opt.name || null;
		this.firstName = opt.firstName || null;
		this.lastName = opt.lastName || null;
		this.distinguishedName = opt.distinguishedName || null;
		this.title = opt.title || null;
		this.email = opt.email || null;
		this.employeeType = opt.employeeType || null;
		this.managerId = opt.managerId || [];
		this.location = opt.location || null;
		this.locationObj = opt.locationObj || null;
		this.displayName = opt.displayName || null;
		this.memberOf = opt.memberOf || [];
		this.departmentName = opt.departmentName || null;
		this.mobilePhone = opt.mobilePhone || null;
		this.jobCode = opt.jobCode || null;
		this.jobLevelCode = opt.jobLevelCode || null;
		this.employeeStatus = opt.employeeStatus || null;
		this.hyattBio = opt.hyattBio || null;
		this.division = opt.division || null;
		this.whenCreated = opt.whenCreated || null;
		this.whenChanged = opt.whenChanged || null;
		this.subDept = opt.subDept || null;
		this.subDeptCode = opt.subDeptCode || null;
		this.hyattPSC = opt.hyattPSC || null;
		this.region = opt.region || null;
		this.telephoneNumber = opt.telephoneNumber || null;
		this.workspace = opt.workspace || null;
		this.userAccountControl = opt.userAccountControl || null;
		this.preferredLanguage = opt.preferredLanguage || null;
		this.fax = opt.fax || null;
		this.hyattTwitter = opt.hyattTwitter || null;
		this.hyattLinkedIn = opt.hyattLinkedIn || null;
	}
}

export { UserModel };
