import BackgroundHeader from 'assets/img/bg-header-caption.jpg';
import complementaryTheme from 'themes/complementary/complementary';

const { neutral } = complementaryTheme;

const colors = {
	background: {
		banner: {
			background: `url(${BackgroundHeader}) no-repeat`, // header
			backgroundSize: '100% 100%',
		},
		secondary: '#e9e8e8', // sidebar and navbar (mobile)
		tertiary: '#F7F7F7', // footer
	},
	interactive: {
		// terms and conditions primary button modal. remove image primary button modal
		primary: {
			default: {
				background: '#ea737c',
				label: '#1F4D42',
				border: '#ea737c',
			},
			hover: {
				background: '#fff',
				label: '#0072ce',
				border: '#0072ce',
			},
		},
		// search button
		primary02: {
			default: {
				background: '#0fb0c2',
				label: '#fff',
				border: '#0fb0c2',
				opacity: '0.65',
			},
		},
		// file button
		primary05: {
			default: {
				background: '#6c6c6e',
				label: '#fff',
				border: '#6c6c6e',
			},
			hover: {
				background: '#fff',
				label: '#6c6c6e',
				border: '#6c6c6e',
			},
		},
		// terms & conditions secondary button modal
		secondary: {
			default: {
				background: '#ea737c',
				label: '#1F4D42',
				border: '#ea737c',
			},
			hover: {
				background: '#fff',
				label: '#0072ce',
				border: '#0072ce',
			},
		},
		// sign out button
		secondary02: {
			default: {
				background: '#f2f3f4',
				label: '#0072ce',
			},
			hover: {
				background: '#f2f3f4',
				label: '#0072ce',
			},
		},
		// button approve modal
		secondary03: {
			default: {
				background: '#333333',
				label: '#1F4D42',
				border: '#333333',
			},
			hover: {
				background: '#fff',
				label: '#1F4D42',
				border: '#333333',
			},
		},
		// help button
		tertiary: {
			default: {
				background: '#1F4D42',
				label: '#7BD2B2',
			},
		},
		printModalButtons: {
			default: {
				background: '#f0f0f0',
				label: '#1F4D42',
				border: '#f0f0f0',
			},
			hover: {
				background: '#fff',
				label: '#0072ce',
				border: '#0072ce',
			},
		},
		// properties and edit image modals buttons
		tertiary03: {
			default: {
				background: '#fff',
				label: '#181818',
				border: '#ccc',
			},
			hover: {
				background: '#fff',
				label: '#181818',
				border: '#181818',
			},
		},
		// mobile
		outlined: {
			default: {
				label: '#ea737c', // mobile search and help buttons
				icon: '#ea737c', // mobile menu button
				border: '#b9b5aa', // mobile buttons
			},
		},
		// header dropdown buttons
		inverse: {
			default: {
				label: '#1F4D42',
			},
		},
		discardChangesButtons: {
			default: {
				background: '#ea737c',
				label: '#1F4D42',
				border: '#ea737c',
			},
			hover: {
				background: '#fff',
				label: '#0072ce',
				border: '#0072ce',
			},
		},
		printPageButtons: {
			default: {
				background: '#fff',
				label: '#1F4D42',
				border: '#ccc',
			},
			hover: {
				background: '#fff',
				label: '#0072ce',
				border: '#0072ce',
			},
		},
		dropDownButtons: {
			default: {
				label: '#1F4D42',
			},
			active: {
				color: '#00000020',
				label: '#262626',
			},
			focusWithin: {
				background: 'transparent',
				label: '#1F4D42',
				border: 'none',
			},
			hover: {
				background: 'transparent',
				label: '#fff',
				border: 'none',
			},
		},

		lightest: 'rgba(255, 255, 255, 0.1)',
	},
	field: {
		// search input
		primary: {
			text: '#433a38',
			placeholder: '#0072ce',
		},
		// inputs edit image modal
		secondary: {
			text: '#676767',
			placeholder: '#0072ce',
		},
		// input properties modal
		tertiary: {
			text: '#676767',
			placeholder: '#0072ce',
		},
	},
	text: {
		primary: '#636466', // footer
		primary02: '#ea737c', // title chapter and section page

		titleModule: '#0fb0c2',
		headline01: '#ea737c',
		headline02: '#6c6c6e',
		headline03: '#0fb0c2',
		headline04: '#6c6c6e',
		headline05: '#ea737c',
		headline06: '#6c6c6e',

		paragraph: '#6c6c6e', // paragraph content page
		specificationTitle: '#ea737c', // specification title

		approveModalTitle: '#666666',
		approveModalDescription: '#999999',

		addFilesSubtitle: '#999999',

		secondary: '#6c6c6e', // terms and conditions title modal
		secondary02: '#0fb0c2', // title discard changes
		secondary03: '#181818', // title edit image modal

		tertiary: '#6c6c6e', // terms and conditions description modal
		tertiary03: '#181818', // title properties modal

		quaternary: '#6c6c6e', // title print modal
		quaternary02: '#333', // labels subtitle edit image modal
		quaternary03: '#000', // labels justification edit image modal

		quinary: '#333', // subtitles items print modal
		quinary02: '#333', // subtitles properties modal
		quinary03: '#333', // title remove image modal
		senary: '#6c6c6e', // title article page

		bullet: '#6c6c6e',

		imageCaption: '#6c6c6e', // image caption
	},
	link: {
		// sidebar item chapter
		primary: {
			default: {
				label: '#6c6c6e',
			},
			hover: {
				label: '#6c6c6e',
			},
		},
		// sidebar item section
		secondary: {
			default: {
				label: '#6c6c6e',

				mobile: {
					label: '#4a89be',
				},
			},
			hover: {
				background: 'rgba(255, 255, 255, 0.1)',
				label: '#6c6c6e',
			},
			active: {
				label: '#6c6c6e',
			},
		},
		// sidebar item article
		tertiary: {
			default: {
				label: '#6c6c6e',

				mobile: {
					label: '#bddc5f',
				},
			},
			hover: {
				background: 'rgba(255, 255, 255, 0.1)',
				label: '#6c6c6e',

				mobile: {
					label: '#bddc5f',
				},
			},
			active: {
				label: '#6c6c6e',

				mobile: {
					label: '#bddc5f',
				},
			},
		},
		// breadcrumb
		quaternary: {
			default: {
				label: '#333',
			},
			hover: {
				label: '#333',
			},
			active: {
				label: '#0fb0c2',
			},
		},
	},
	icon: {
		primary: '#1F4D42', // header dropdown arrow icon
		primary02: '#ea737c', // print icon
		chapterArrow: '#6c6c6e', // chapter arrow icon
		sectionArrow: '#0fb0c2', // section arrow icon
		breadcrumbArrow: '#555555', // breadcrumb arrow icon
	},
	border: {
		primary: '#0fb0c2', // search input
		secondary: '#B9b5aa',
		tertiary: '#333', // modal top border
		quaternary: '#eee', // module divider
	},
	shadow: {
		primary: 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6)', // search input
		secondary: 'inset 0 1px 0 rgba(255, 255, 255, 0.1)', // menu
	},
	neutral,
	table: {
		row: {
			background: {
				primary: '#f2f3f4',
				secondary: 'transparent',
			},
			cell: {
				label: '#333333',
				border: '2px solid #898b8e',
			},
		},
	},
};

export default colors;
