import { EXTERNAL_USER_GLOBAL_ID } from 'constants/externalUserConstants';
import { getURLQueryParams } from './queryParamsUtil';

export const isExternalUserRoute = () => {
	const regex = /\/[^\s/?]+(?=\?)/;
	const match = window.location.hash.match(regex);
	return match?.[0] === '/external-user';
};

export const getExternalUser = () => {
	if (!isExternalUserRoute()) {
		return null;
	}

	const { brand: brandName, hc_token: token } = getURLQueryParams(window.location.href);

	if (!brandName || !token) {
		return null;
	}

	return {
		brandName,
		token,
	};
};

export const hasExternalUser = () => !!getExternalUser();

export const isExternalUser = user => user.globalId === EXTERNAL_USER_GLOBAL_ID;
