import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { func } from 'prop-types';
import { Button } from 'components/button';
import { CheckBox } from 'components/checkBox';
import useSearchRoute from 'hooks/useSearchRoute';
import useToggle from 'hooks/useToggle';
import { useQueryParams } from 'hooks/useQueryParams';
import { useSolrService } from 'hooks/useSolrService';
import { translations } from 'translation/en';
import { SEARCH_QUERY_PARAM_EXACT_MATCH, SEARCH_QUERY_PARAM_NAME } from 'constants/searchConstants';
import * as S from './SearchBar.style';

const SearchBar = ({ onClose }) => {
	const history = useHistory();
	const { getSearchRoute } = useSearchRoute();
	const { getQueryParam } = useQueryParams();
	const { resetSearchResult } = useSolrService();

	const [checked, setChecked] = useToggle(false);
	const [searchValue, setSearchValue] = useState('');

	const searchTerm = getQueryParam(SEARCH_QUERY_PARAM_NAME);
	const isExactMatch = getQueryParam(SEARCH_QUERY_PARAM_EXACT_MATCH);

	const handleChange = e => {
		setSearchValue(e.target.value);
	};

	const handleClearInput = () => {
		setSearchValue('');
	};

	const handleSubmit = e => {
		e.preventDefault();
		onClose();

		if (searchTerm === searchValue && (isExactMatch === 'true') === checked) {
			window.location.reload();
		}

		history.replace(getSearchRoute(searchValue, checked));

		setTimeout(() => {
			handleClearInput();
		}, 1000);
	};

	const handleEnter = e => {
		if (e.key === 'Enter') {
			handleSubmit(e);
		}
	};

	const handleToggle = () => {
		setChecked();
	};

	useEffect(() => {
		if (searchTerm) {
			resetSearchResult();
		}
	}, [searchTerm, resetSearchResult]);

	return (
		<S.SearchBar>
			<S.Col>
				<S.Row>
					<S.SearchGroup>
						<S.SearchInput
							value={searchValue}
							placeholder={translations.SEARCH_FOR}
							onChange={handleChange}
							onKeyDown={handleEnter}
						/>
						<Button variant="primary-02" disabled={!searchValue} onClick={handleSubmit}>
							{translations.SEARCH_TEXT}
						</Button>
					</S.SearchGroup>
				</S.Row>
				<S.Row>
					<CheckBox
						label={translations.EXACT_MATCH}
						checked={checked}
						containerStyle={{ justifyContent: 'end', width: '100%' }}
						onChange={handleToggle}
					/>
				</S.Row>
			</S.Col>
		</S.SearchBar>
	);
};

SearchBar.propTypes = {
	onClose: func,
};

SearchBar.defaultProps = {
	onClose: () => {},
};

export { SearchBar };
