import { useCallback } from 'react';
import { COLORS } from '../constants/colors';
import { LOCATION_TYPE_NAME, ROLE_NAME } from '../constants/modalAnalyticsConstants';
import { translation } from '../constants/translation';
import { locationGroup } from '../utils/categoriesLocationType';
import { useAnalyticsContext } from './useAnalyticsContext';

export const usePieChartAnalytics = () => {
	const { locationType, metrics, getLabelByAbbr, isMetricsRoles } = useAnalyticsContext();
	const { nameKey } = locationType;

	const getGroupByAbbr = useCallback(currentAbbr => {
		const group = locationGroup.find(item => item.abbr.includes(currentAbbr));
		return group?.name || translation.OTHERS;
	}, []);

	const getMetricsForChartByGroup = useCallback(
		data => {
			return data.reduce((acc, current, i) => {
				if (acc.filter(arr => arr?.group === current.group).length === 0) {
					acc.push({
						group: current.group,
						locations: [
							{
								label: current[nameKey],
								abbr: current.abbr,
								totalRegister: current.totalRegister,
								total: current.total,
								color: COLORS[i],
							},
						],
					});

					return acc;
				}
				const index = acc.findIndex(item => item.group === current.group);

				acc[index] = {
					group: acc[index]?.group,
					locations: acc[index].locations.concat({
						label: current[nameKey],
						abbr: current.abbr,
						totalRegister: current.totalRegister,
						total: current.total,
						color: COLORS[i],
					}),
				};

				return acc;
			}, []);
		},
		[nameKey]
	);

	const metricsTotalizer = useCallback(
		data => {
			return data.reduce((acc, current) => {
				if (acc.filter(arr => arr?.[nameKey] === current[nameKey]).length === 0) {
					acc.push({
						...current,
						abbr: current.abbr ? current.abbr : current[nameKey],
						totalRegister: current.totalRegister ? current.totalRegister : 1,
					});
					return acc;
				}

				const index = acc.findIndex(item => item[nameKey] === current[nameKey]);

				acc[index] = {
					...acc[index],

					abbr: current.abbr ? acc[index].abbr.concat(current.abbr) : current[nameKey],

					totalRegister: current.totalRegister
						? acc[index].totalRegister + current.totalRegister
						: acc[index].totalRegister + 1,

					total: acc[index].total + current.total,
				};

				return acc;
			}, []);
		},
		[nameKey]
	);

	const getMetricsWithConvertedLabel = useCallback(() => {
		return metricsTotalizer(metrics).map(item => ({
			...item,
			[nameKey]: getLabelByAbbr(item[nameKey]),
			abbr: [item.abbr],
			group: getGroupByAbbr(nameKey === ROLE_NAME ? item[LOCATION_TYPE_NAME] : item[nameKey]),
		}));
	}, [getGroupByAbbr, getLabelByAbbr, metrics, metricsTotalizer, nameKey]);

	const getMetricsForChart = useCallback(() => {
		const listMetrics = getMetricsWithConvertedLabel();

		const metricsTotalizerGroupLabel = metricsTotalizer(listMetrics);

		return metricsTotalizerGroupLabel.map(item => ({
			...item,
			totalRegister: isMetricsRoles(item[nameKey]) ? item.total : item.totalRegister,
		}));
	}, [getMetricsWithConvertedLabel, isMetricsRoles, metricsTotalizer, nameKey]);

	return { getMetricsForChart, getMetricsForChartByGroup };
};
