import { Modal } from 'components/modals/modal';
import styled from 'styled-components/macro';

export const StyledModal = styled(Modal)({
	'&&': {
		maxHeight: '100vh',
		maxWidth: '900px',
	},

	'*': {
		marginBlock: 0,
	},
});

export const Title = styled.div(() => ({
	margin: '18px 0px 0px',
}));

export const Content = styled.div(({ theme: { color, typo } }) => ({
	textAlign: 'left',
	position: 'relative',
	margin: '20px 0',

	p: {
		marginBottom: '20px',

		':last-child': {
			marginBottom: 0,
		},
	},

	color: color.text.tertiary,
	...typo.body['01'],
}));
