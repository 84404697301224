import styled from 'styled-components/macro';

export const PrintOptionButton = styled.button(() => ({
	width: '100%',
	color: 'inherit',
	fontSize: '15px',
	background: 'transparent',
	border: 'none',
	display: 'flex',
	alignItems: 'center',
}));

export const PrintPageList = styled.ul(() => ({
	background: 'darkgrey',
	borderRadius: '6px',
	width: '180px',
	height: '135px',
	textAlign: 'left',
	paddingLeft: '0',
	paddingTop: '10px',
}));

export const PrintPageListItem = styled.li(({ theme: { color } }) => ({
	display: 'flex',
	padding: '10px',
	float: 'left',
	width: '100%',
	color: `${color.interactive.dropDownButtons.default.label} `,

	'&:hover': {
		background: `${color.interactive.dropDownButtons.hover.background}`,
		color: `${color.interactive.dropDownButtons.hover.label} !important`,
	},
}));

export const PrintPageDropDownWrapper = styled.div(() => ({
	width: '180px',
	position: 'absolute',
	top: '95%',
	left: '46%',
}));

export const Icon = styled.i(({ theme: { color, typo }, isLandscape }) => ({
	display: 'inline-block',
	marginRight: '5px',
	color: {
		...color.interactive.primary.default,
		hover: { ...color.interactive.primary.hover },
	},
	...typo.button.icon['xs'],
	transform: isLandscape ? 'rotate(90deg)' : 'none',
}));
