import { Button } from 'components/button';
import { Main, Modal } from 'components/modals/modal/Modal.style';
import styled from 'styled-components/macro';

export const FileModal = styled(Modal)(() => ({
	maxWidth: '900px',
}));

export const FileModalMain = styled(Main)(() => ({
	position: 'relative',

	'::-webkit-scrollbar': {
		width: '5px',
		height: '5px',
		borderRadius: '10px',
		background: '#f0f0f0',
	},
	'::-webkit-scrollbar-thumb': {
		borderRadius: '20px',
		background: '#b4b4b4',
	},
	'::-webkit-scrollbar-track': {
		background: 'rgba(0, 0, 0, 0.1)',
	},
}));

export const Box = styled.div(() => ({
	padding: '30px',
	margin: '20px 0',
	width: '100%',
	border: '1px solid #ccc',
	borderRadius: '4px',
	boxSizing: 'border-box',
}));

export const Row = styled.div(() => ({
	display: 'inline-flex',
	width: '100%',
	marginBottom: '20px',
}));

export const Column = styled.div(() => ({
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
}));

export const Label = styled.label(({ theme: { typo } }) => ({
	marginBottom: '8px',
	width: '100%',
	color: '#333',

	...typo.body['05'],
}));

export const NewButton = styled(Button)(() => ({
	position: 'relative',
	minWidth: '200px',
	width: 'fit-content',
	height: '40px',
	borderRadius: '0.2rem',
}));

export const Input = styled.input(({ theme: { color, typo } }) => ({
	padding: '10px',
	width: '100%',
	height: '41px',
	border: '1px solid #cccccc',
	boxSizing: 'border-box',
	borderRadius: '4px',

	color: color.field.secondary.text,
	...typo.caption['05'],

	'&::placeholder': {
		color: color.field.secondary.placeholder,
		...typo.caption['04'],
	},

	'&:disabled': {
		cursor: 'default',
	},
}));

export const ChooseFileButton = styled.button(() => ({
	width: '168px',
	height: '40px',
	border: '1px solid #ccc',
	borderRadius: '0.2rem',
	backgroundColor: '#868e96',
	transition: 'all .3s ease',

	color: '#fff',
	fontFamily: 'Poynter Regular',
	fontSize: '14px',
	letterSpacing: '1px',
	textTransform: 'uppercase',
	lineHeight: '1.5em',

	'i:first-child': {
		marginRight: '10px',
	},

	'&:hover, &:focus': {
		background: '#727980',
		border: '1px solid #fff',

		'*': {
			color: '#fff',
		},
	},
}));

export const FileGroup = styled.div(() => ({
	display: 'inline-flex',
	alignItems: 'center',
	height: '19px',
}));

export const DeleteButton = styled.button(() => ({
	background: 'none',
	border: 'none',

	i: {
		fontSize: '19px',
		color: '#999',
	},
}));

export const FileNameLabel = styled.label(({ theme: { typo } }) => ({
	color: '#333',
	...typo.caption['07'],
}));
