/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import ErrorImageSrc from 'assets/img/error/broken-image.png';
import classNames from 'classnames';
import { EDIT_MENU_TYPES } from 'components/begEditMenu/constants/begEditMenuConstants';
import { RemoveModal } from 'components/removeModal';
import { useDeleteImage } from 'hooks/useDeleteImage';
import { useEditMenuData } from 'hooks/useEditMenuData';
import useParagraphs from 'hooks/useParagraphs';
import mediumZoom from 'medium-zoom';
import PropTypes from 'prop-types';
import { useCallback, useRef, useState, useReducer } from 'react';
import { translations } from 'translation/en';
import { getImageUrl } from 'utils/urlUtil';
import { isDelivery } from 'utils/envUtil';
import { IMAGE_BOX_TYPES } from './constants/imageBoxConstants';
import { EditImageModal } from './editImageModal';
import './ImageBox.scss';
import * as S from './ImageBox.style';

const initialState = {
	type: IMAGE_BOX_TYPES.RESET,
};

const reducer = (state, action) => {
	switch (action.type) {
		case IMAGE_BOX_TYPES.EDIT_IMAGE_MODAL:
			return { ...state, type: IMAGE_BOX_TYPES.EDIT_IMAGE_MODAL };
		case IMAGE_BOX_TYPES.REMOVE_IMAGE_MODAL:
			return { ...state, type: IMAGE_BOX_TYPES.REMOVE_IMAGE_MODAL };
		case IMAGE_BOX_TYPES.CLOSE_MODAL:
			return { ...state, type: IMAGE_BOX_TYPES.CLOSE_MODAL };
		case IMAGE_BOX_TYPES.RESET:
			return { ...state, type: IMAGE_BOX_TYPES.RESET };
		default:
			return state;
	}
};

const ImageBox = ({ imageProps, imageStyle, onSave, onDelete, isOnPrintPage, ...props }) => {
	const { selectedToEdit } = useEditMenuData();
	const imageRef = useRef(
		mediumZoom({
			scrollOffset: 0,
			background: 'rgba(0, 0, 0, 0.3)',
		})
	);

	const { setIsParagraphLoading } = useParagraphs();
	const [imageData, setImageData] = useState(imageProps);
	const [state, dispatch] = useReducer(reducer, initialState);
	const { deleteImage } = useDeleteImage();

	const imageBoxClassNames = classNames('ImageBox__wrapper', {
		'ImageBox__wrapper--has-link': imageData.linkTo,
	});

	const attachImageZoom = image => {
		if (imageData.linkTo) {
			imageRef.current.detach(image);
			return;
		}

		imageRef.current.attach(image);
	};

	const handleOpenLink = e => {
		e.preventDefault();

		if (imageData.linkTo) {
			window.open(imageData.linkTo, '_blank');
		}
	};

	const handleEdit = e => {
		e.preventDefault();
		dispatch({ type: IMAGE_BOX_TYPES.EDIT_IMAGE_MODAL });
	};

	const handleRemove = e => {
		e.preventDefault();
		dispatch({ type: IMAGE_BOX_TYPES.REMOVE_IMAGE_MODAL });
	};

	const handleSaveAsDraft = payload => {
		dispatch({ type: IMAGE_BOX_TYPES.CLOSE_MODAL });
		setImageData(payload);
		onSave(payload);
	};

	const handleAction = useCallback(action => {
		dispatch({ type: action });
	}, []);

	const handleConfirmRemoveImage = useCallback(
		async e => {
			e.preventDefault();
			setIsParagraphLoading(true);

			handleAction(IMAGE_BOX_TYPES.CLOSE_MODAL);

			deleteImage({
				contentPath: imageData.imagePath,
			})
				.then(result => {
					if (!result?.error) {
						onDelete(imageData);
					}
				})
				.finally(() => {
					setIsParagraphLoading(false);
				});
		},
		[setIsParagraphLoading, handleAction, deleteImage, imageData, onDelete]
	);

	const titleClassNames = classNames('ImageBox__title', `ImageBox__title--${imageData.textOverlayPosition?.toLowerCase()}`);

	const captionClassNames = classNames('ImageBox__caption', `ImageBox__caption--${imageData.captionPosition?.toLowerCase()}`, {
		'ImageBox__caption--empty': !imageData.imageCaption,
	});

	const buttonsClassNames = classNames('ImageBox__buttons-group', {
		'ImageBox__buttons-group--show': selectedToEdit === EDIT_MENU_TYPES.EDIT_INLINE,
	});

	const addDefaultSrc = ({ currentTarget }) => {
		currentTarget.onerror = null;
		currentTarget.src = ErrorImageSrc;
	};

	const shouldNotShowImageBox =
		(!imageData.imageContentPath && imageData.file && isDelivery) || (!imageData.imageContentPath && isOnPrintPage);

	if (shouldNotShowImageBox) {
		return null;
	}

	return (
		<>
			<div className="ImageBox">
				<div className={imageBoxClassNames} onClick={handleOpenLink}>
					<div className="ImageBox__image">
						<img
							ref={attachImageZoom}
							src={getImageUrl(imageData)}
							style={imageStyle}
							onError={addDefaultSrc}
							alt=""
						/>
						{imageData.textOverlay && (
							<div className={titleClassNames}>
								<label className="ImageBox__label">{imageData.textOverlay}</label>
							</div>
						)}
					</div>
					{imageData.imageCaption && (
						<div className={captionClassNames}>
							<S.ImageBoxCaption>{imageData.imageCaption}</S.ImageBoxCaption>
						</div>
					)}
				</div>

				<div className={buttonsClassNames}>
					<button className="ImageBox__button" onClick={handleEdit}>
						<i className="icon icon-pencil" />
					</button>
					{imageData.imagePath && (
						<button className="ImageBox__button ImageBox__button__remove" onClick={handleRemove}>
							<i className="icon icon-trash" />
						</button>
					)}
				</div>
			</div>

			{state.type === IMAGE_BOX_TYPES.EDIT_IMAGE_MODAL && (
				<EditImageModal
					open
					imageData={imageData}
					imageStyle={imageStyle}
					onSaveAsDraft={handleSaveAsDraft}
					onClose={() => handleAction(IMAGE_BOX_TYPES.CLOSE_MODAL)}
					{...props}
				/>
			)}

			{state.type === IMAGE_BOX_TYPES.REMOVE_IMAGE_MODAL && (
				<RemoveModal
					open
					title={translations.MODAL_REMOVE_IMAGE_TITLE}
					onConfirm={handleConfirmRemoveImage}
					onClose={() => handleAction(IMAGE_BOX_TYPES.CLOSE_MODAL)}
				/>
			)}
		</>
	);
};

ImageBox.propTypes = {
	imageProps: PropTypes.shape({
		imageVcmId: PropTypes.string,
		textOverlay: PropTypes.string,
		imageCaption: PropTypes.string,
		imageContentPath: PropTypes.string,
		linkTo: PropTypes.string,
		displayOrder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	}),
	isOnPrintPage: PropTypes.bool,
	imageStyle: PropTypes.shape({}),
	onSave: PropTypes.func,
	onDelete: PropTypes.func,
};

ImageBox.defaultProps = {
	imageProps: {
		imageVcmId: '',
		textOverlay: '',
		imageCaption: '',
		imageContentPath: '',
		linkTo: '',
		displayOrder: '',
	},
	isOnPrintPage: false,
	imageStyle: { width: '105px', height: '105px' },
	onSave: () => {},
	onDelete: () => {},
};

export { ImageBox };
