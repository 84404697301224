import styled from 'styled-components/macro';

export const ModuleContainer = styled.div(() => ({
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
	height: 'auto',
	overflowWrap: ' break-word',
	pageBreakInside: 'avoid',
	marginBottom: '20px',
}));

export const Container = styled.div(() => ({
	justifyContent: 'space-evenly',
}));

export const Content = styled.div(() => ({
	display: 'flex',
	flexDirection: 'column',
}));

export const ContentWrapper = styled.div(() => ({
	padding: '0 15px',
}));

export const Divider = styled.hr(({ theme: { color } }) => ({
	marginTop: 0,
	marginBottom: '44px',
	right: '5px',
	width: '100%',
	border: 'none',
	opacity: '0.45',
	borderBottom: `1px solid ${color.border.quaternary}`,
}));

export const ImageItem = styled.div(() => ({
	display: 'flex',
	justifyContent: 'end',
	margin: '0px 0px 10px',
	padding: '0 0 0 15px',
}));

export const Wrapper = styled.div(() => ({
	display: 'flex',
	gap: '10px',
	width: '100%',
}));

export const CopyIcon = styled.i(() => ({
	cursor: 'pointer',
	paddingBottom: '10px',

	'@media print': {
		display: 'none',
	},
}));

export const Disclaimer = styled.div(() => ({
	margin: '0px 10px 10px',
	'p, span': {
		fontSize: '13px !important',
		color: 'black !important',
		textAlign: 'justify !important',
		lineHeight: '1.0em !important',
		fontFamily: 'Calibri, Arial',
	},
}));
