import complementaryTheme from 'themes/complementary/complementary';

const { neutral } = complementaryTheme;

const colors = {
	background: {
		banner: {
			background: '#663399', // header
		},
		secondary: '#2a2c34',
		tertiary: '#f7f7f7',
	},
	interactive: {
		primary: {
			default: {
				background: '#663399',
				label: '#fff',
				border: '#663399',
			},
			hover: {
				background: '#fff',
				label: '#663399',
				border: '#663399',
			},
		},
		primary02: {
			default: {
				background: '#d619ba',
				label: '#fff',
				border: '#d619ba',
			},
		},
		// file button
		primary05: {
			default: {
				background: '#663399',
				label: '#fff',
				border: '#663399',
			},
			hover: {
				background: '#fff',
				label: '#663399',
				border: '#663399',
			},
		},
		secondary: {
			default: {
				background: '#F2F3F4',
				label: '#0072ce',
				border: '#0072ce',
			},
			hover: {
				background: '#F2F3F4',
				label: '#0072ce',
				border: '#0072ce',
			},
		},
		secondary02: {
			default: {
				background: '#d619ba',
				label: '#FFFFFF',
			},
			hover: {
				background: '#F2F3F4',
				label: '#FFFFFF',
			},
		},
		// button approve modal
		secondary03: {
			default: {
				background: '#333333',
				label: '#fff',
				border: '#333333',
			},
			hover: {
				background: '#fff',
				label: '#333333',
				border: '#333333',
			},
		},
		tertiary: {
			default: {
				background: '#fff',
				label: '#663399',
			},
		},
		printModalButtons: {
			default: {
				background: '#663399',
				label: '#ffffff',
				border: '#663399',
			},
			hover: {
				background: '#ffffff',
				label: '#663399',
				border: '#663399',
			},
		},
		// properties and edit image modals buttons
		tertiary03: {
			default: {
				background: '#fff',
				label: '#181818',
				border: '#ccc',
			},
			hover: {
				background: '#fff',
				label: '#181818',
				border: '#181818',
			},
		},
		outlined: {
			default: {
				label: '#fff',
				icon: '#fff',
				border: '#fff',
			},
		},
		inverse: {
			default: {
				label: '#fff',
			},
		},
		discardChangesButtons: {
			default: {
				background: '#663399',
				label: '#fff',
				border: '#663399',
			},
			hover: {
				background: '#F2F3F4',
				label: '#663399',
				border: '#663399',
			},
		},
		printPageButtons: {
			default: {
				background: '#0072ce',
				label: '#fff',
				border: '#0072ce',
			},
			hover: {
				background: '#F2F3F4',
				label: '#0072ce',
				border: '#0072ce',
			},
		},
		dropDownButtons: {
			default: {
				label: '#ffffff',
			},
			active: {
				color: '#00000020',
				label: '#262626',
			},
			focusWithin: {
				background: 'transparent',
				label: '#ffffff',
				border: 'none',
			},
			hover: {
				background: 'transparent',
				label: '#ffffff',
				border: 'none',
			},
		},

		lightest: 'rgba(255, 255, 255, 0.1)',
	},
	field: {
		// search input
		primary: {
			text: '#d619ba',
			placeholder: '#d619ba',
		},
		// inputs edit image modal
		secondary: {
			text: '#676767',
			placeholder: '#0072ce',
		},
		// input properties modal
		tertiary: {
			text: '#676767',
			placeholder: '#0072ce',
		},
	},
	text: {
		primary: '#636466',
		primary02: '#050707', // title chapter, section page

		titleModule: '#050707',
		headline01: '#050707',
		headline02: '#d619ba',
		headline03: '#050707',
		headline04: '#050707',
		headline05: '#d619ba',
		headline06: '#050707',

		paragraph: '#050707',
		specificationTitle: '#d619ba', // specification title

		approveModalTitle: '#666666',
		approveModalDescription: '#999999',

		addFilesSubtitle: '#999999',

		secondary: '#050707', // terms and conditions title modal
		secondary02: '#050707', // title discard changes
		secondary03: '#181818', // edit image modal title

		tertiary: '#050707',
		tertiary03: '#181818', // title properties modal

		quaternary: '#050707',
		quaternary02: '#333', // labels subtitle edit image modal
		quaternary03: '#000', // labels justification edit image modal

		quinary: '#050707',
		quinary02: '#333', // subtitles properties modal
		quinary03: '#333', // title remove modal
		senary: '#d619ba', // title article page

		bullet: '#050707', // list item bullet

		imageCaption: '#050707',
	},
	link: {
		primary: {
			default: {
				label: '#fff',
			},
			hover: {
				label: '#fff',
			},
		},
		secondary: {
			default: {
				label: '#fff',

				mobile: {
					label: '#fff',
				},
			},
			hover: {
				background: 'rgba(255, 255, 255, 0.1)',
				label: '#fff',
			},
			active: {
				label: '#fff',

				mobile: {
					label: '#fff',
				},
			},
		},
		tertiary: {
			default: {
				label: '#ffffff',

				mobile: {
					label: '#ffffff',
				},
			},
			hover: {
				background: 'rgba(255, 255, 255, 0.1)',
				label: '#ffffff',

				mobile: {
					label: '#ffffff',
				},
			},
			active: {
				label: '#ffffff',

				mobile: {
					label: '#ffffff',
				},
			},
		},
		quaternary: {
			default: {
				label: '#050707',
			},
			hover: {
				label: '#050707',
			},
			active: {
				label: '#d619ba',
			},
		},
	},
	icon: {
		primary: '#fff',
		primary02: '#050707',
		chapterArrow: '#ffffff', // chapter arrow icon
		sectionArrow: '#ffffff', // section arrow icon
	},
	border: {
		primary: '#d619ba',
		secondary: '#FFFFFF',
		tertiary: '#333',
		quaternary: '#2a2c34',
	},
	shadow: {
		primary: 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(214, 25, 186, 0.6)',
		secondary: 'inset 0 1px 0 rgba(255, 255, 255, 0.1)',
	},
	neutral,
	table: {
		row: {
			background: {
				primary: '#f2f3f4',
				secondary: 'transparent',
			},
			cell: {
				label: '#333333',
				border: '2px solid #898b8e',
			},
		},
	},
};

export default colors;
