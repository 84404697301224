export const translation = Object.freeze({
	ACCESS_MODE_EMAIL: 'Access mode: E-mail',
	BRAND: 'Brand',
	CLOSE: 'Close',
	CUSTOM_RANGE: 'Custom range',
	DAY: 'Day',
	END_DATE: 'End Date',
	EXPORT: 'Export',
	FILTER: 'Filter',
	LOCATION_TYPE: 'Location Type',
	METRICS_BY: 'Metrics by',
	NO_STATISTICS: 'The system did not find any statistics for this content.',
	OTHERS: 'Others',
	REFRESH: 'Refresh',
	REGION: 'Region',
	REQUIRED: 'Required',
	ROLE: 'Role',
	START_DATE: 'Start Date',
	TYPE: 'Type',
});
