import { RouteModel } from 'router/model/RouteModel';

export const detailRoutesConstants = {
	locationsDetailROUTE: new RouteModel({
		path: '/location-detail/:title',
		defaultPath: '/location-detail',
		pageTitle: 'Location detail',
		key: 'location-detail',
	}),
	employeeROUTE: new RouteModel({
		path: '/employee-detail/:id',
		defaultPath: '/employee-detail',
		pageTitle: 'Employee Detail',
		key: 'employeeDetail',
	}),
};
