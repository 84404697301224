import { format } from 'date-fns';
import { useCallback } from 'react';
import { sortStringArrAscendingByKey } from '../utils/arrayUtils';
import { useAnalyticsContext } from './useAnalyticsContext';

export const useTableAnalytics = () => {
	const { locationType, metrics, getLabelByAbbr } = useAnalyticsContext();
	const { nameKey } = locationType;
	const ORDER_BY_KEY = 'name';

	const convertToDate = useCallback(dateString => {
		const date = dateString ? new Date(`${dateString} 00:00:00`) : new Date();
		return format(date, 'MMM dd, yyyy');
	}, []);

	const getMetricsWithConvertedLabel = useCallback(() => {
		return metrics.map(item => ({
			...item,
			date: convertToDate(item.date),
			[nameKey]: getLabelByAbbr(item[nameKey]),
		}));
	}, [convertToDate, getLabelByAbbr, metrics, nameKey]);

	const convertDataToTable = useCallback(() => {
		const locations = [...new Set(getMetricsWithConvertedLabel().map(item => item[nameKey]))];

		return getMetricsWithConvertedLabel().reduce((acc, current) => {
			if (acc.filter(arr => arr?.date === current.date).length === 0) {
				const locationsTypes = locations.map(item =>
					item === current[nameKey] ? { name: item, value: current.total } : { name: item, value: 0 }
				);

				acc.push({
					date: current.date,
					locations: sortStringArrAscendingByKey(locationsTypes, ORDER_BY_KEY),
					total: current.total,
				});
				return acc;
			}

			const index = acc.findIndex(item => item.date === current.date);

			const locationsTypes = acc[index].locations.map(item =>
				item.name === current[nameKey]
					? { name: item.name, value: item.value + current.total }
					: { name: item.name, value: item.value }
			);

			acc[index] = {
				...acc[index],
				locations: locationsTypes,
				total: acc[index].total + current.total,
			};

			return acc;
		}, []);
	}, [getMetricsWithConvertedLabel, nameKey]);

	const generalTotal = useCallback((arrayData, attribute) => {
		return arrayData.reduce((acc, current) => acc + current[attribute], 0);
	}, []);

	const generalTotalLocation = useCallback(
		location => {
			const list = getMetricsWithConvertedLabel().filter(item => item[nameKey] === location);

			return generalTotal(list, 'total');
		},
		[generalTotal, getMetricsWithConvertedLabel, nameKey]
	);

	return { convertDataToTable, generalTotalLocation, generalTotal };
};
