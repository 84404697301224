import { Link } from 'react-router-dom';
import { shape } from 'prop-types';
import { InnerHtml } from '@hyatt/inner-html';
import useBrandRoute from 'hooks/useBrandRoute';
import { useQueryParams } from 'hooks/useQueryParams';
import { roleConstants } from 'constants/testsConstants';
import * as S from './SearchPageItem.style';

const SearchPageItem = ({ item, paragraph }) => {
	const { searchParams } = useQueryParams();
	const { getBrandRoute } = useBrandRoute();

	return (
		<S.SearchPageItemWrapper>
			<Link to={`${getBrandRoute(item.path)}${searchParams}#${paragraph?.vcmId}`} target="_blank" rel="noopener noreferrer">
				{paragraph?.body && (
					<>
						<S.SearchPageItemDivider />
						<S.SearchPageItemInnerHtml>
							<InnerHtml body={paragraph?.body} />
						</S.SearchPageItemInnerHtml>
					</>
				)}

				{paragraph?.specification && (
					<>
						<S.SearchPageItemDivider role={roleConstants.SEPARATOR} />
						<S.SearchPageItemInnerHtml>
							<InnerHtml body={paragraph?.specification} />
						</S.SearchPageItemInnerHtml>
					</>
				)}
			</Link>
		</S.SearchPageItemWrapper>
	);
};

SearchPageItem.propTypes = {
	paragraph: shape({}),
	item: shape({}),
};
SearchPageItem.defaultProps = {
	paragraph: {},
	item: {},
};

export { SearchPageItem };
