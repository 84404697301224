import classNames from 'classnames';
import { any, bool, string } from 'prop-types';
import React, { forwardRef } from 'react';
import FormFieldError from './FormFieldError';
import FormFieldLabel from './FormFieldLabel';
import './FormFieldTextArea.scss';

export const FormFieldTextArea = forwardRef(
  ({ className, hasError, required, label, errors, title, ...props }, ref) => {
    return (
      <>
        {label && (
          <FormFieldLabel label={label} required={required} title={title} />
        )}
        <textarea
          className={classNames('FormFieldTextArea', className, {
            'FormFieldTextArea--has-error': hasError,
          })}
          {...props}
          ref={ref}
        />
        {hasError && <FormFieldError message={errors?.message} />}
      </>
    );
  }
);

FormFieldTextArea.propTypes = {
  className: string,
  hasError: bool,
  label: string,
  title: string,
  errors: any,
};

FormFieldTextArea.defaultProps = {
  className: '',
  title: '',
  hasError: false,
  label: null,
  errors: null,
};
