import { removeAuthCookies } from 'utils/cookiesUtil';

const useSignOut = () => {
	const signOut = e => {
		e?.preventDefault();
		removeAuthCookies(() => {
			window.location.href = `${process.env.REACT_APP_DOMAIN}/hyatt-azure-sso
			/logout?url=${window.location.origin}/hyattconnect/`;
		});
	};

	return { signOut };
};

export default useSignOut;
