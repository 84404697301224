import { arrayOf, func, node, oneOfType, string } from 'prop-types';
import React from 'react';
import { translations } from 'translation/en';
import { redirectToOrigin } from 'utils/redirectUtil';
import './ErrorPage.scss';

export const ErrorPage = ({ title, message, onButtonClick, buttonText, children }) => {
	return (
		<div className="ErrorPage">
			<h1 className="ErrorPage__title">{title}</h1>
			<p className="ErrorPage__message">{message}</p>
			{children}
			<button className="ErrorPage__button" onClick={onButtonClick}>
				{buttonText}
			</button>
		</div>
	);
};

ErrorPage.propTypes = {
	title: string,
	message: string,
	onButtonClick: func,
	buttonText: string,
	children: oneOfType([arrayOf(node), node]),
};

ErrorPage.defaultProps = {
	title: translations.SOMETHING_WENT_WRONG,
	message: translations.WE_ARE_HAVING_PROBLEMS_TO_LOAD_APP_DATA,
	onButtonClick: redirectToOrigin,
	buttonText: translations.TRY_AGAIN,
	children: null,
};
