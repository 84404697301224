import React from 'react';
import ContentLoader from 'react-content-loader';

const SearchPageSkeleton = props => (
	<ContentLoader
		speed={2}
		width={900}
		height={900}
		viewBox="0 0 900 900"
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
		{...props}
	>
		<rect x="2" y="40" rx="5" ry="5" width="378" height="30" />
		<rect x="2" y="95" rx="5" ry="5" width="600" height="60" />
		<rect x="2" y="276" rx="5" ry="5" width="378" height="30" />
		<rect x="2" y="411" rx="5" ry="5" width="600" height="60" />
		<rect x="2" y="330" rx="5" ry="5" width="600" height="60" />
		<rect x="2" y="176" rx="5" ry="5" width="600" height="60" />
		<rect x="2" y="511" rx="5" ry="5" width="378" height="30" />
		<rect x="2" y="645" rx="5" ry="5" width="600" height="30" />
		<rect x="2" y="565" rx="5" ry="5" width="600" height="60" />
		<rect x="2" y="10" rx="5" ry="5" width="378" height="15" />
	</ContentLoader>
);

export default SearchPageSkeleton;
