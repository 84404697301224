import MiravalLogo from 'assets/img/logos/miraval.svg';
import MiravalLogoSidear from 'assets/img/logos/miraval_sidebar.svg';
import complementaryTheme from 'themes/complementary/complementary';
import colors from './miravalColors';
import typographyTheme from './miravalTypo';

const logo = {
	footer: MiravalLogo, // logo in the footer
	loading: MiravalLogo, // logo in the loading
	sidebar: MiravalLogoSidear, // logo in the sidebar
	mobile: MiravalLogo, // logo in the navbar
	size: {
		sidebar: {
			width: '188px',
			height: 'auto',
		},
		footer: {
			width: '182px',
			height: '68px',
		},
		navbar: {
			width: '130px',
			height: '135px',
		},
		loading: {
			width: '160px',
			height: '155px',
		},
	},
};

const copyrightText = {
	padding: '5px 0 5px 18px',
};

const miraval = {
	brandId: 'mv',
	name: 'Miraval',
	logo,
	color: colors,
	copyrightText,

	font: {
		family: {
			helveticaNeue: 'Helvetica Neue',
			notoSansRegular: 'NotoSans Regular',
			arialSansSerif: 'Arial, Helvetica, sans-serif',
			gothamLight: 'Gotham Light',
			gothamBook: 'Gotham Book',
			futuraBook: 'Futura Book',
			poynterRegular: 'Poynter Regular',
			futuraBookCalibri: 'Futura Book, Calibri',
			robotoMonoRegular: 'RobotoMono Regular',
		},
	},
};

const miravalTheme = {
	...miraval,
	...typographyTheme(miraval),
	...complementaryTheme,
};

export default miravalTheme;
