import HyattHouseLogoHorizontal from 'assets/img/logos/logo-hyatt-house-horizontal.svg';
import HouseHyattLogo from 'assets/img/logos/house.svg';
import HyattHouseLogo from 'assets/img/logos/logo-hyatt-house.svg';
import complementaryTheme from 'themes/complementary/complementary';
import colors from './hyattHouseColors';
import typographyTheme from './hyattHouseTypo';

const logo = {
	footer: HyattHouseLogoHorizontal, // logo in the footer
	loading: HouseHyattLogo, // logo in the loading
	sidebar: HyattHouseLogo, // logo in the sidebar
	mobile: HyattHouseLogo, // logo in the navbar
	size: {
		sidebar: {
			width: '130px',
			height: '135px',
		},
		footer: {
			width: '182px',
			height: '68px',
		},
		navbar: {
			width: '130px',
			height: '135px',
		},
		loading: {
			width: '120px',
			height: '120px',
		},
	},
};

const copyrightText = {
	padding: '5px 0 5px 14px',
};

const hyattHouse = {
	brandId: 'hh',
	name: 'Hyatt House',
	logo,
	color: colors,
	copyrightText,

	font: {
		family: {
			helveticaNeue: 'Helvetica Neue',
			notoSansRegular: 'NotoSans Regular',
			arialSansSerif: 'Arial, Helvetica, sans-serif',
			gothamLight: 'Gotham Light',
			gothamBook: 'Gotham Book',
			montserratRegular: 'Montserrat Regular',
			montserratBold: 'Montserrat Bold',
			montserratSemiBold: 'Montserrat Semi-Bold',
			poynterRegular: 'Poynter Regular',
		},
	},
};

const hyattHouseTheme = {
	...hyattHouse,
	...typographyTheme(hyattHouse),
	...complementaryTheme,
};

export default hyattHouseTheme;
