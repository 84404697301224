import { useCallback, forwardRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { bool, func, shape, string } from 'prop-types';
import { API_KEY, CONTENT_STYLE, FONTS, PLUGINS, TOOLBAR } from './constants/tinyMceConstants';
import './TinyMCEEditor.scss';

const TinyMCEEditor = forwardRef(
	({ focusOnInit, initProps, plugins, toolbar, style, fonts, setup, containerId, ...props }, ref) => {
		const onInit = useCallback(
			(evt, editor) => {
				if (focusOnInit) {
					editor.focus();
					editor?.getBody()?.scrollIntoView({ behavior: 'smooth', block: 'center' });
				}
			},
			[focusOnInit]
		);

		return (
			<div className="TinyMCEEditor" id={containerId}>
				<Editor
					ref={ref}
					apiKey={API_KEY}
					onInit={onInit}
					init={{
						plugins,
						toolbar,
						toolbar_mode: 'sliding',
						relative_urls: false,
						font_family_formats: fonts,
						content_style: style,
						link_assume_external_targets: true,
						link_default_target: '_blank',
						quickbars_selection_toolbar: 'bold italic | link h2 h3 blockquote',
						setup,
						...initProps,
					}}
					scriptLoading={{
						async: true,
						delay: 100,
					}}
					{...props}
				/>
			</div>
		);
	}
);

TinyMCEEditor.propTypes = {
	containerId: string.isRequired,
	focusOnInit: bool,
	initProps: shape({ menubar: bool, toolbar: string, plugins: string }),
	plugins: string,
	toolbar: string,
	style: string,
	fonts: string,
	setup: func,
};

TinyMCEEditor.defaultProps = {
	focusOnInit: false,
	initProps: {},
	plugins: PLUGINS,
	toolbar: TOOLBAR,
	style: CONTENT_STYLE,
	fonts: FONTS,
	setup: () => {},
};

export default TinyMCEEditor;
