import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { InnerHtml } from '@hyatt/inner-html';
import { fromSolrService, getSolrResult, solrSearchByVcmId } from 'services/solrService';
import { searchReset, setSearchIsLoading, setSearchNumFound, setSearchResults } from 'actions/actionCreators';
import { useQueryParams } from 'hooks/useQueryParams';
import { generateSlug, isEmptyString } from 'utils/stringUtil';
import { isUserAllowedToSeeContent, userAndContentRegionFilter } from 'utils/userUtil';
import { MODELS_PATHS } from 'constants/aemConstants';
import { groupBy, filterUniqueObjectsByIndex } from 'utils/arrayUtil';
import { BODY, FILE_LINK_TEXT, GLOBAL_BRAND_NO_HTML, PAGINATION_START, SPECIFICATION } from 'constants/solrConstants';
import { Breadcrumb } from 'containers/brandDetails/components/breadcrumb';
import { getHighlightOnSearchResults } from 'utils/highlightUtil';
import {
	SEARCH_QUERY_PARAM_NAME,
	SEARCH_QUERY_PARAM_EXACT_MATCH,
	SEARCH_BOOLEAN_STRING_FALSE,
	OLD_BRAND_STANDARDS_PATH,
} from 'constants/searchConstants';
import useBrandData from './useBrandData';
import { useAuthData } from './useAuthData';
import { useBreadcrumbService } from './useBreadcrumbService';
import { useRequest } from './useRequest';
import { useSearchContent } from './useSearchData';

export const useSolrService = () => {
	const { brand } = useBrandData();
	const { user } = useAuthData();
	const { color, typo } = useTheme();

	const { isLoading: isLoadingSolrSearch, searchTerm, results, isExactMatch, isAutoRunEnabled } = useSearchContent();
	const { getQueryParam, getRouteQueryParam } = useQueryParams();
	const { getBreadcrumbFromPageIdOrPath } = useBreadcrumbService();
	const dispatch = useDispatch();

	const querySearchTerm = getQueryParam(SEARCH_QUERY_PARAM_NAME);
	const queryIsExactMatch = getQueryParam(SEARCH_QUERY_PARAM_EXACT_MATCH);

	const fromRelatedTermsContent = useCallback(
		async relatedTermsResp => {
			const highlightedSearchResponse = relatedTermsResp?.data?.highlighting;
			const mappedResponse = relatedTermsResp?.data?.response?.docs?.map(fromSolrService);

			const mapQuicksheetWithHighlight = quicksheet => {
				const globalBrandHighlight = isEmptyString(
					highlightedSearchResponse?.[`${quicksheet?.solrId}`]?.[GLOBAL_BRAND_NO_HTML]?.[0]
				);

				return {
					...quicksheet,
					body: globalBrandHighlight,
				};
			};

			const mapParagraphWithHighlight = paragraph => {
				const bodyHighlight = isEmptyString(highlightedSearchResponse?.[`${paragraph?.solrId}`]?.[BODY]?.[0]);
				const specificationHighlight = isEmptyString(
					highlightedSearchResponse?.[`${paragraph?.solrId}`]?.[SPECIFICATION]?.[0]
				);

				return {
					...paragraph,
					body: bodyHighlight,
					specification: specificationHighlight,
				};
			};

			const mapFileWithHighlight = file => ({
				...file,
				linkText: isEmptyString(highlightedSearchResponse?.[`${file?.solrId}`]?.[FILE_LINK_TEXT]?.[0])
					? highlightedSearchResponse?.[`${file?.solrId}`]?.[FILE_LINK_TEXT]?.[0]
					: file.linkText,
			});

			const getLabelComponent = (label = '') =>
				label && <InnerHtml body={label} style={{ color: color.text.headline03, ...typo.headline['03'] }} />;

			const getBreadcrumbComponent = breadcrumbData => {
				// If the page does not have a chapter, we don't want to show it in the search results
				// That is, only pages (content) that have a chapter will be displayed
				if (breadcrumbData?.list) {
					const testList = breadcrumbData.list.filter(item => item.model === MODELS_PATHS.CHAPTER);

					if (testList.length <= 0) {
						return null;
					}
				}

				return breadcrumbData?.list ? (
					<Breadcrumb
						steps={breadcrumbData.list?.map(step => {
							const highlightedTitle = getHighlightOnSearchResults({
								isExactMatch,
								searchTerm,
								content: step.title,
							});

							return {
								id: step.vcmId,
								path: step.path,
								label: getLabelComponent(highlightedTitle),
							};
						})}
						haveLink={false}
					/>
				) : null;
			};

			const generateQuicksheetBreadcrumb = (quicksheetBreadcrumbData, quicksheetItem) => {
				const pageTopic = generateSlug(quicksheetItem?.topic);
				const operation = generateSlug(quicksheetItem?.operation);
				const operationPath = `${quicksheetItem?.quicksheetPath}/${operation}`;
				const path = `${operationPath}/${pageTopic}`;
				const steps = [
					...(quicksheetBreadcrumbData?.list?.map(step => ({
						id: step.vcmId,
						label: getLabelComponent(step.title),
						path: step.path,
					})) || []),
					{
						id: quicksheetItem?.solrId,
						path: operationPath,
						label: getLabelComponent(quicksheetItem?.operation),
					},
					{
						id: quicksheetItem?.solrId,
						path,
						label: getLabelComponent(quicksheetItem?.topic),
					},
				];

				return <Breadcrumb steps={steps.filter(step => step?.label)} haveLink={false} />;
			};

			const allPages = mappedResponse
				.filter(
					content =>
						content.cqModel === MODELS_PATHS.CHAPTER ||
						content.cqModel === MODELS_PATHS.SECTION ||
						content.cqModel === MODELS_PATHS.ARTICLE
				)
				// Security filter
				.filter(content => isUserAllowedToSeeContent(user, content));

			let allParagraphs = mappedResponse.filter(content => content.cqModel === MODELS_PATHS.RELATOR_PARAGRAPHS);

			let allFiles = mappedResponse.filter(content => content.cqModel === MODELS_PATHS.RELATOR_FILE);

			const allQuickSheet = mappedResponse.filter(content => content.cqModel === MODELS_PATHS.ROW && content?.chapterPath);

			// Init handle quicksheet
			const allQuicksheetOperations = groupBy(allQuickSheet, 'operation');
			const operationGroupKeys = Object.keys(allQuicksheetOperations);
			let quicksheetBreadcrumbData = [];

			if (allQuickSheet?.[0]?.chapterPath) {
				quicksheetBreadcrumbData = await getBreadcrumbFromPageIdOrPath(allQuickSheet[0]?.chapterPath);
			}

			const quicksheetGroupedByOperationAndTopic = operationGroupKeys.map(key =>
				groupBy(allQuicksheetOperations[key], 'topic')
			);

			const allQuicksheetPages = quicksheetGroupedByOperationAndTopic.reduce((pages, topic) => {
				const topicGroupKeys = Object.keys(topic);
				let newPages = topicGroupKeys.map(key => topic[key]);

				newPages = newPages.map(quicksheetPages => {
					const firstItem = quicksheetPages[0];
					const pageTopic = generateSlug(firstItem?.topic);
					const operation = generateSlug(firstItem?.operation);
					const operationPath = `${firstItem.quicksheetPath}/${operation}`;
					const path = pageTopic ? `${operationPath}/${pageTopic}` : operationPath;
					const title = generateQuicksheetBreadcrumb(quicksheetBreadcrumbData, firstItem);
					return {
						title,
						paragraphs: quicksheetPages.map(mapQuicksheetWithHighlight),
						path,
						regions: firstItem?.regions,
					};
				});
				return [...pages, ...newPages];
			}, []);

			// Finish quicksheet handle

			// 1° case: page and paragraph or file comes in the same pagination
			// 2° case: page and paragraph or file comes in different paginations

			let highlightedResponse = allPages?.map(async item => {
				const pageParagraphs = allParagraphs.filter(paragraph => item.relatorsPath?.includes(paragraph?.path));

				allParagraphs = filterUniqueObjectsByIndex(allParagraphs, pageParagraphs, 'vcmId');

				const pageFiles = allFiles.filter(file => item.vcmId === file.parentVcmid);
				allFiles = filterUniqueObjectsByIndex(allFiles, pageFiles, 'vcmId');

				if (!item?.path) {
					return null;
				}

				const breadcrumbData = await getBreadcrumbFromPageIdOrPath(item.path);
				const breadcrumb = getBreadcrumbComponent({ ...item, ...breadcrumbData });

				if (breadcrumb) {
					return {
						...item,
						path: breadcrumb?.path || item?.path,
						pagePath: item?.pagePath,
						title: breadcrumb || item?.title,
						paragraphs: pageParagraphs?.map(mapParagraphWithHighlight),
						files: pageFiles?.map(mapFileWithHighlight),
					};
				}

				return null;
			});

			// paragraph handling
			highlightedResponse = (await Promise.all(highlightedResponse)).filter(page => page);
			const paragraphsVcmid = allParagraphs
				.filter(paragraph => paragraph.parentVcmid)
				.map(paragraph => paragraph.parentVcmid);

			// searches all paragraphs parents (articles, sections and chapters)
			const paragraphsParents =
				paragraphsVcmid.length > 0
					? (await solrSearchByVcmId(paragraphsVcmid))?.data?.response?.docs?.map(fromSolrService)
					: [];

			allParagraphs = allParagraphs.map(paragraph => {
				// select parent content paragraphs
				const paragraphParent = paragraphsParents.find(parent => parent.vcmId === paragraph.parentVcmid);

				if (paragraphParent) {
					return {
						...paragraph,
						paragraphParent,
					};
				}

				return paragraph;
			});

			// file handling
			const filesParentVcmid = allFiles.filter(file => file.parentVcmid).map(file => file.parentVcmid);
			let filesParent =
				filesParentVcmid.length > 0
					? (await solrSearchByVcmId(filesParentVcmid))?.data?.response?.docs?.map(fromSolrService)
					: [];

			filesParent = filesParent.filter(
				fileParent =>
					fileParent.brands?.toLowerCase()?.includes(brand.brandId) ||
					fileParent.brands?.toLowerCase()?.includes(brand.searchBrandId)
			);

			allFiles = allFiles
				.filter(file => filesParent.find(parent => parent.vcmId === file.parentVcmid))
				.map(file => {
					const fileParent = filesParent.find(parent => parent.vcmId === file.parentVcmid);

					if (fileParent) {
						const fileWithParentData = {
							...file,
							fileParent,
							operation: fileParent.operation,
							topic: fileParent.topic,
							path: fileParent.chapterPath,
							parentVcmid: fileParent.chapterPath || fileParent.vcmId,
						};

						return fileWithParentData;
					}

					return file;
				});

			const unmappedParagraphsAndFiles = allFiles.concat(allParagraphs).filter(item => item.parentVcmid);

			const itemsGroupedByParentVcmid = groupBy(unmappedParagraphsAndFiles, 'parentVcmid');
			const groupKeys = Object.keys(itemsGroupedByParentVcmid);

			const groupPromises = groupKeys.map(async key => {
				let breadcrumb;
				let files = itemsGroupedByParentVcmid[key].filter(item => item.cqModel === MODELS_PATHS.RELATOR_FILE);

				const breadcrumbData = await getBreadcrumbFromPageIdOrPath(key);
				const quicksheetFiles = files.filter(file => file.operation);

				if (quicksheetFiles.length > 0) {
					files = quicksheetFiles;
					breadcrumb = generateQuicksheetBreadcrumb(breadcrumbData, quicksheetFiles[0]);
				} else {
					breadcrumb = getBreadcrumbComponent({ ...itemsGroupedByParentVcmid[key], ...breadcrumbData });
				}

				// filter paragraphs by parent
				const paragraphs = itemsGroupedByParentVcmid[key].filter(
					item => item.cqModel === MODELS_PATHS.RELATOR_PARAGRAPHS
				);

				if (breadcrumb) {
					const parent = paragraphs[0]?.paragraphParent || files[0]?.fileParent || {};

					return {
						...parent,
						title: breadcrumb,
						files: files.map(mapFileWithHighlight),
						paragraphs: paragraphs.map(mapParagraphWithHighlight),
						path: breadcrumbData.path,
					};
				}

				return null;
			});

			const pagesToAdd = (await Promise.all(groupPromises)).filter(page => page);

			highlightedResponse = highlightedResponse
				.concat(pagesToAdd)
				// old brand standards filter
				.filter(content => {
					const filteredPagePath =
						content.pagePath?.filter(pagePath => pagePath.includes(OLD_BRAND_STANDARDS_PATH)) || [];
					return filteredPagePath.length !== 1;
				})
				// security filter
				.filter(content => isUserAllowedToSeeContent(user, content))
				.filter(content => content.cqModel !== MODELS_PATHS.CHAPTER || brand.pagePath.includes(content.pagePath));

			const quicksheetFilteredByRegion = allQuicksheetPages.filter(content => userAndContentRegionFilter(user, content));
			highlightedResponse = highlightedResponse.concat(quicksheetFilteredByRegion);

			return {
				numFound: relatedTermsResp?.data?.response.numFound,
				highlightedResponse,
			};
		},
		[
			isExactMatch,
			searchTerm,
			typo.headline,
			user,
			brand.brandId,
			brand.pagePath,
			brand.searchBrandId,
			color.text.headline03,
			getBreadcrumbFromPageIdOrPath,
		]
	);

	const resetSearchResult = useCallback(() => {
		dispatch(searchReset());
	}, [dispatch]);

	const fetchRelatedTerms = useCallback(
		params => {
			dispatch(setSearchIsLoading(true));
			resetSearchResult();

			return getSolrResult({
				searchName: searchTerm || querySearchTerm,
				exactMatch: isEmptyString(isExactMatch)
					? isExactMatch !== SEARCH_BOOLEAN_STRING_FALSE
					: queryIsExactMatch !== SEARCH_BOOLEAN_STRING_FALSE,
				...params,
				brand: {
					brandId: brand.brandId,
					searchBrandId: brand.searchBrandId,
					parentPath: brand.parentPath,
				},
				region: user?.region,
			})
				.then(resp => fromRelatedTermsContent(resp))
				.then(treatedResponse => {
					dispatch(setSearchNumFound(treatedResponse.numFound));
					dispatch(setSearchResults(treatedResponse));
				})
				.catch(error => {
					console.error(error);
				})
				.finally(() => {
					dispatch(setSearchIsLoading(false));
				});
		},
		[
			dispatch,

			searchTerm,
			querySearchTerm,
			isExactMatch,
			queryIsExactMatch,
			brand.brandId,
			brand.parentPath,
			brand.searchBrandId,
			user?.region,

			fromRelatedTermsContent,
			resetSearchResult,
		]
	);

	useRequest(() => fetchRelatedTerms({ start: PAGINATION_START }), {
		autoRun: isAutoRunEnabled,
	});

	useEffect(() => {
		if (querySearchTerm) {
			resetSearchResult();
			fetchRelatedTerms();
		}
	}, [querySearchTerm, fetchRelatedTerms, resetSearchResult]);

	return { isLoadingSolrSearch, querySearchTerm, results, fetchRelatedTerms, getRouteQueryParam, resetSearchResult };
};
