import PropTypes from 'prop-types';
import React from 'react';
import * as S from './ArticleItem.style';

const ArticleItem = ({ children, onClick, ...props }) => {
	const handleClick = e => {
		e.preventDefault();
		onClick(e);
	};

	return (
		<S.Link onClick={e => onClick && handleClick(e)} {...props}>
			<S.Wrapper>
				<S.Label>{children}</S.Label>
			</S.Wrapper>
		</S.Link>
	);
};

ArticleItem.propTypes = {
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	onClick: PropTypes.func,
};

ArticleItem.defaultProps = {
	children: '',
	onClick: () => null,
};

export { ArticleItem };
