import logo from 'assets/img/logos/hyatt-beg-logo.png';
import { Base } from 'components/base';
import React from 'react';
import { Link } from 'react-router-dom';
import routers from 'router/router';
import './Header.scss';

const Header = () => {
	return (
		<header className="Header">
			<Base>
				<div className="Header__wrapper">
					<Link to={`${routers.brandsROUTE.getRouteDefaultPath()}`}>
						<img src={logo} className="Header__logo" alt="Hyatt-Beg" />
					</Link>
				</div>
			</Base>
		</header>
	);
};

export { Header };
