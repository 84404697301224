import { NavLink as RouterLink } from 'react-router-dom';
import styled from 'styled-components/macro';

const getMD = () => '@media(max-width: 880px)';

const variantStyle = ({ variant = 'primary', color }) => {
	switch (variant) {
		case 'primary':
			return {
				a: {
					color: `${color.link.primary.default.label} !important`,
				},

				'&:hover': {
					a: {
						color: `${color.link.primary.hover.label} !important`,
					},
				},
			};
		case 'secondary':
			return {
				a: {
					color: `${color.link.secondary.default.label} !important`,
				},

				'&:hover': {
					background: color.interactive.lightest,

					'*': {
						color: `${color.link.secondary.hover.label} !important`,
					},
				},

				'&:active, &:visited, &.active': {
					a: {
						color: `${color.link.secondary.active.label}`,
					},
				},
			};
		case 'tertiary':
			return {
				'*': {
					color: `${color.link.tertiary.default.label} !important`,

					[getMD()]: {
						'*': {
							color: `${color.link.tertiary.default?.mobile?.label} !important`,
						},
					},
				},

				'&:hover': {
					background: color.interactive.lightest,

					'*': {
						color: `${color.link.tertiary.hover.label} !important`,
					},
				},

				'&:active, &.active, &:visited': {
					'*': {
						color: `${color.link.tertiary.active.label}`,

						[getMD()]: {
							'*': {
								color: `${color.link.tertiary.active?.mobile?.label} !important`,
							},
						},
					},
				},
			};
		case 'quaternary':
			return {
				'*': {
					color: color.link.quaternary.default.label,
				},

				'&:hover, &:focus': {
					'*': {
						color: color.link.quaternary.hover.label,
						textDecoration: 'underline',
					},
				},

				'&:active, &.active': {
					'*': {
						color: color.link.quaternary.active.label,
						textDecoration: 'underline',
					},

					'&:focus, &:hover, &:visited, &:link': {
						color: color.link.quaternary.active.label,
					},
				},
			};
		default:
			return {};
	}
};

const wrapperStyle = ({ variant }) => {
	switch (variant) {
		case 'primary':
			return {
				padding: '15px 20px 10px 25px',
				justifyContent: 'space-between',
			};
		case 'secondary':
			return {
				padding: '10px 20px',
				justifyContent: 'flex-start',

				'> :first-child': {
					marginRight: '4px',
				},
			};
		case 'tertiary':
			return {
				padding: '10px 60px',
				justifyContent: 'flex-start',
			};
		default:
			return {};
	}
};

export const Link = styled(RouterLink)(({ theme: { color }, variant }) => ({
	display: 'inline-flex',
	alignItems: 'center',
	width: '100%',
	minHeight: '45px',
	background: 'none',
	textDecoration: 'none !important',

	...variantStyle({ variant, color }),
}));

export const Wrapper = styled.div(({ variant }) => ({
	display: 'inline-flex',
	alignItems: 'center',
	width: '100%',
	height: 'inherit',

	...wrapperStyle({ variant }),
}));
