import { Button } from 'components/button';
import styled from 'styled-components/macro';

export const Container = styled.div(() => ({
	display: 'block',
	position: 'relative',
	margin: '20px 0px 0px',
}));

export const Box = styled.div(() => ({
	marginBottom: '20px',
}));

export const FileButton = styled(Button)(() => ({
	padding: '12px 25px',
	minWidth: '200px',
	width: 'auto',
	height: '48px',
	zIndex: 1,
}));

export const ButtonsContainer = styled.div(() => ({
	display: 'flex',
	alignContent: 'center',
	gap: '10px',
	margin: '0 0 20px',
}));
