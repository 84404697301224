import { useEffect, useMemo, useState, useRef, useCallback } from 'react';
import { getReplicateStatusByOperationId, unpublishAsync } from 'API/publishAPI';
import { showToast } from 'utils/toastUtil';
import { getCurrentRelatorPathFromCurrentTypeToUnpublish } from 'utils/publishUtil';
import { isEmpty } from 'utils/arrayUtil';
import { translations } from 'translation/en';
import { useRequest } from './useRequest';
import useBrandDetails from './useBrandDetails';
import useApprovePublish from './useApprovePublish';
import useClearCache from './useClearCache';

const FIVE_SECONDS = 5000;

export const useUnpublishPage = () => {
	const initialState = useMemo(
		() => ({
			loading: false,
			data: null,
			error: null,
		}),
		[]
	);

	const { currentType } = useBrandDetails();
	const { setIsPagePublished } = useApprovePublish();

	const { loading: loadingUnpublish, error: unpublishError, run: postUnpublish } = useRequest(unpublishAsync);
	const {
		loading: loadingReplicateStatus,
		error: replicateStatusError,
		run: fetchReplicateStatus,
	} = useRequest(getReplicateStatusByOperationId);

	const intervalRef = useRef();
	const [requestData, setRequestData] = useState(initialState);
	const { clearCache, clearCacheDelivery } = useClearCache();

	const stopInterval = () => {
		clearInterval(intervalRef.current);
	};

	const startInterval = useCallback(
		(operationPrefix, operationId, isUnpublishLater = false) => {
			intervalRef.current = setInterval(async () => {
				const operation = `${operationPrefix}${operationId}`;

				return fetchReplicateStatus(operation)
					.then(response => {
						if (isEmpty(response?.data)) {
							stopInterval();
							clearCache({ successCallback: () => {} });
							clearCacheDelivery({ shouldShowToast: false });
							setRequestData({ ...initialState, data: response });

							if (!isUnpublishLater) {
								setIsPagePublished(false);
								showToast({ type: 'success', message: translations.UNPUBLISH_SUCCESS });
							}
						}
					})
					.catch(err => {
						stopInterval();
						setRequestData({ ...initialState, error: err });
					});
			}, FIVE_SECONDS);
		},
		[initialState, fetchReplicateStatus, clearCache, clearCacheDelivery, setIsPagePublished]
	);

	const unpublishPage = useCallback(
		async (contentPath = null, isUnpublishLater = false) => {
			setRequestData({ ...initialState, loading: true });

			const pathToPublish = contentPath || getCurrentRelatorPathFromCurrentTypeToUnpublish(currentType);

			return postUnpublish(pathToPublish)
				.then(response => {
					if (response.data) {
						const { operationId, operationPrefix } = response.data;
						startInterval(operationPrefix, operationId, isUnpublishLater);
					}
				})
				.catch(err => {
					setRequestData({ ...initialState, error: err });
				});
		},
		[currentType, initialState, postUnpublish, startInterval]
	);

	const unpublishButton = useMemo(
		() => ({
			key: 'unpublish-option',
			text: translations.UNPUBLISH,
			disabled: requestData.loading,
			loading: false,
			icon: 'icon-unpublish',
			onClick: unpublishPage,
		}),
		[requestData.loading, unpublishPage]
	);

	const isLoadingUnpublishPage = useMemo(
		() => [loadingUnpublish, loadingReplicateStatus, requestData.loading].some(Boolean),
		[loadingUnpublish, loadingReplicateStatus, requestData.loading]
	);

	const isErrorUnpublishPage = useMemo(
		() => [unpublishError, replicateStatusError, requestData.error].some(Boolean),
		[unpublishError, replicateStatusError, requestData.error]
	);

	const unpublishedPage = useMemo(() => requestData.data, [requestData.data]);

	useEffect(() => {
		if (isErrorUnpublishPage) {
			showToast({ type: 'error', message: translations.ERROR_UNPUBLISHING_PAGES });
		}
	}, [initialState, isErrorUnpublishPage]);

	return { unpublishLoading: isLoadingUnpublishPage, unpublishedPage, unpublishButton, unpublishPage };
};
