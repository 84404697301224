export const isNumber = value => {
	return typeof value === 'number' && Number.isFinite(value);
};

export const isOdd = num => {
	return num % 2;
};

export const isEven = num => {
	return !isOdd(num);
};

export const isNegative = num => {
	return num < 0;
};
