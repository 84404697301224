import { isValidElement } from 'react';
import { Link } from 'react-router-dom';
import { InnerHtml } from '@hyatt/inner-html';
import { Base } from 'components/base';
import { TermsConditionsModal } from 'components/termsConditionsModal';
import SearchPageSkeleton from 'components/loaders/searchPageSkeleton/SearchPageSkeleton';
import { BrandDetailsProvider } from 'containers/brandDetails/context/BrandDetailsContext';
import { BrandFooter } from 'containers/brandDetails/components/brandFooter';
import { BrandHeader } from 'containers/brandDetails/components/brandHeader';
import { BrandSideBar } from 'containers/brandDetails/components/brandSideBar';
import { ListFiles } from 'containers/brandDetails/components/brandMain/components/listFiles';
import { useSolrService } from 'hooks/useSolrService';
import { roleConstants } from 'constants/testsConstants';
import { translations } from 'translation/en';
import { SearchPageItem } from './components/SearchPageItem';
import * as S from './SearchPage.style';

const SearchPage = () => {
	const { isLoadingSolrSearch, querySearchTerm, results, getRouteQueryParam } = useSolrService();

	const renderSearchResults = () => {
		if (isLoadingSolrSearch) {
			return <SearchPageSkeleton role={roleConstants.STATUS} />;
		}

		return (
			<>
				<S.SearchPageSubTitle>
					{`${results?.length} ${translations.RESULTS_FOR} `}&ldquo;<strong>{querySearchTerm}</strong>&rdquo;
				</S.SearchPageSubTitle>

				<div>
					{results?.map(item => {
						return (
							<S.SearchPageResultListItem role={roleConstants.LIST_ITEM} key={item.vcmId}>
								<Link to={getRouteQueryParam(item.path)} target="_blank" rel="noopener noreferrer">
									<S.SearchPageResultTitle>
										{isValidElement(item?.title) ? item?.title : <InnerHtml body={item?.title} />}
									</S.SearchPageResultTitle>
								</Link>

								{item?.files && <ListFiles files={item.files} isAnalyticsEnabled={false} />}

								{item?.paragraphs
									?.sort((a, b) => a.serviceOrder - b.serviceOrder)
									?.map(paragraph => {
										return <SearchPageItem item={item} key={paragraph?.vcmId} paragraph={paragraph} />;
									})}
								<S.SearchPageResultDivider />
							</S.SearchPageResultListItem>
						);
					})}
				</div>
			</>
		);
	};

	return (
		<BrandDetailsProvider>
			<BrandHeader />
			<Base>
				<S.SearchPageWrapper>
					<S.AsideBrands>
						<BrandSideBar />
					</S.AsideBrands>
					<S.SearchResultsWrapper>
						<S.SearchPageTitle>{translations.SEARCH_RESULTS}</S.SearchPageTitle>
						<S.SearchPageResultList>{renderSearchResults()}</S.SearchPageResultList>
					</S.SearchResultsWrapper>
				</S.SearchPageWrapper>
			</Base>
			<BrandFooter />
			<TermsConditionsModal action={{ primaryVariant: 'primary', secondaryVariant: 'primary' }} />
		</BrandDetailsProvider>
	);
};

SearchPage.propTypes = {};

export { SearchPage };
