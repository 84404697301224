import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { ModuleSkeleton } from '../moduleSkeleton';
import './BrandMainSkeleton.scss';

const BrandMainSkeleton = () => (
	<div className="BrandMainSkeleton">
		<div className="BrandMainSkeleton__header-page">
			<div className="BrandMainSkeleton__wrapper-header">
				<Skeleton className="BrandMainSkeleton__bread-crumb" />
				<div className="BrandMainSkeleton__wrapper-title">
					<div className="BrandMainSkeleton__title">
						<Skeleton height={48} />
					</div>
					<div className="BrandMainSkeleton__actions">
						<Skeleton height={26} width={26} circle count={2} />
					</div>
				</div>
			</div>
		</div>
		<ModuleSkeleton />
	</div>
);

export { BrandMainSkeleton };
