import { node, shape, string } from 'prop-types';
import { useRef, useState, useCallback } from 'react';
import { translations } from 'translation/en';
import { getYear } from 'utils/dateUtil';
import { replaceArgs } from 'utils/stringUtil';
import Helmet from 'components/helmet/Helmet';
import { Loading } from 'components/loadingOverlay';
import { useTheme } from 'styled-components';
import { Logo } from 'components/logo';
import { PRINT_PAGE } from './constants/printPageConstants';
import { PrintPageDropdown } from './printPageDropdown';
import * as S from './PrintPageTemplate.style';

const PrintPageTemplate = ({ content, children, title }) => {
	const printPageRef = useRef();
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [isLoadingFile, setIsLoadingFile] = useState(false);
	const { logo } = useTheme();

	const year = getYear();

	const handleCloseDropdown = () => {
		setIsDropdownOpen(false);
	};

	const handleSetIsLoadingFile = useCallback(isLoading => {
		setIsLoadingFile(isLoading);
	}, []);

	return (
		<>
			{isLoadingFile && (
				<Loading.Overlay isFullScreen>
					<Loading.LoadingLogo logo={logo?.loading} />
					<S.loadingFileMessage>
						<strong>{translations.YOUR_FILE} </strong>
						{translations.PLEASE_BE_PATIENT}
					</S.loadingFileMessage>
				</Loading.Overlay>
			)}
			<S.PrintPageTemplate ref={printPageRef}>
				<S.PrintPageTemplateHeader>
					<S.PrintPageTemplateButtonWrapper>
						<S.MenuButton aria-label={PRINT_PAGE.DROPDOWN_BUTTON} onClick={() => setIsDropdownOpen(prev => !prev)}>
							<S.Icon className="icon icon-printer" />
							<div>
								<S.Arrow />
							</div>
						</S.MenuButton>
						{isDropdownOpen && (
							<PrintPageDropdown
								content={{ ...content, logo }}
								handleCloseDropdown={handleCloseDropdown}
								handleSetIsLoadingFile={handleSetIsLoadingFile}
								isLoadingFile={isLoadingFile}
								ref={printPageRef}
							/>
						)}
					</S.PrintPageTemplateButtonWrapper>

					<S.PrintPageLogo>
						<Logo image={logo.sidebar} size={logo.size.sidebar} />
					</S.PrintPageLogo>

					{title && (
						<S.PrintPageTitle>
							{title}
							<Helmet title={title} />
						</S.PrintPageTitle>
					)}
				</S.PrintPageTemplateHeader>

				<table>
					<thead>
						<tr>
							<td>
								<div style={{ height: '0', '@media print': { display: 'none' } }} />
							</td>
						</tr>
					</thead>

					<tbody>
						<tr>
							<td>
								<S.PrintPageTemplateBody>{children}</S.PrintPageTemplateBody>
							</td>
						</tr>
					</tbody>

					<tfoot>
						<tr>
							<td>
								<div style={{ height: '50px', '@media print': { display: 'none' } }} />
							</td>
						</tr>
					</tfoot>
				</table>

				<S.PrintPageTemplateFooter>
					{replaceArgs(translations.FOOTER_TEXT_ALL_RIGHTS_RESERVED, year)}
				</S.PrintPageTemplateFooter>
				<S.PrintPageTemplateFooterVersion>
					{replaceArgs(translations.FOOTER_TEXT_VERSION, year)}
				</S.PrintPageTemplateFooterVersion>
			</S.PrintPageTemplate>
		</>
	);
};

PrintPageTemplate.propTypes = {
	children: node,
	pageOrientation: string,
	title: string,
	content: shape({}),
};

PrintPageTemplate.defaultProps = {
	children: null,
	title: '',
	pageOrientation: '',
	content: null,
};

export { PrintPageTemplate };
