import classNames from 'classnames';
import { BrandsBox } from 'components/brandsBox';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './BrandModal.scss';

const BrandModal = ({ open, onClose }) => {
	const [domReady, setDomReady] = useState(false);
	const rootElement = document.getElementById('root');
	const bodyElement = document.body;

	useEffect(() => {
		setDomReady(true);
		const bodyOverflowY = bodyElement?.style.overflowY;

		if (bodyElement) {
			bodyElement.style.overflowY = open ? 'hidden' : 'inherit';
		}

		return () => {
			setDomReady(false);
			if (bodyElement) {
				bodyElement.style.overflowY = bodyOverflowY;
			}
		};
	}, [open, rootElement, bodyElement]);

	const modalClassNames = classNames('BrandModal', {
		'BrandModal--open': open,
	});

	const backDropClassNames = classNames('BrandBackDrop', {
		'BrandBackDrop--open': open,
	});

	const boxClassNames = classNames('BrandBox', {
		'BrandBox--open': open,
	});

	const modalComponent = rootElement && (
		<div className={modalClassNames} open={open}>
			<div className={backDropClassNames} />
			<div className={boxClassNames}>
				<div className="BrandBox__content">
					<div className="BrandHeader">
						<button className="BrandHeader__button" onClick={onClose}>
							<span className="BrandHeader__label">&times;</span>
						</button>
					</div>
					<div className="BrandBody">
						<BrandsBox hasMargin handleClose={onClose} />
					</div>
				</div>
			</div>
		</div>
	);

	return domReady ? ReactDOM.createPortal(modalComponent, rootElement) : null;
};

BrandModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
};

BrandModal.defaultProps = {
	open: false,
	onClose: () => null,
};

export { BrandModal };
