import { Divider } from 'components/divider';
import PropTypes from 'prop-types';
import React from 'react';
import { childrenWithProps } from 'utils/childrenUtil';
import './DropDownItem.scss';

const DropDownItem = ({ children, ...props }) => {
	return (
		<div className="DropDownItem">
			<div className="DropDownItem__body">
				{childrenWithProps(children, props)}
				<Divider />
			</div>
		</div>
	);
};

DropDownItem.propTypes = {
	children: PropTypes.node,
};

DropDownItem.defaultProps = {
	children: null,
};

export { DropDownItem };
