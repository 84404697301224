import React from 'react';
import { useAnalyticsContext } from '../../hooks/useAnalyticsContext';
import { useBarChartAnalytics } from '../../hooks/useBarChartAnalytics';
import { useTableAnalytics } from '../../hooks/useTableAnalytics';
import { sortStringArrAscendingByKey } from '../../utils/arrayUtils';
import './BarChartTableWrapper.scss';

export const BarChartTableWrapper = () => {
	const { metrics } = useAnalyticsContext();
	const { convertLabelsToGraphic } = useBarChartAnalytics();
	const { convertDataToTable, generalTotalLocation, generalTotal } = useTableAnalytics();
	const ORDER_BY_KEY = 'date';

	const labels = convertLabelsToGraphic();
	const data = sortStringArrAscendingByKey(convertDataToTable(), ORDER_BY_KEY);

	return (
		<div className="BarChartTableWrapper">
			<table>
				<thead>
					<tr>
						<th>Date</th>
						{labels.map(label => (
							<th key={label}>{label}</th>
						))}
						<th>Total</th>
					</tr>
				</thead>
				<tbody>
					{data.map(item => (
						<tr key={item.date}>
							<td>{item.date}</td>
							{item.locations.map(location => (
								<td key={location.name}>{location.value}</td>
							))}
							<td>{item.total}</td>
						</tr>
					))}

					<tr>
						<td>Total</td>
						{labels.map(location => (
							<td key={location}>{generalTotalLocation(location)}</td>
						))}
						<td>{generalTotal(metrics, 'total')}</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};
