import { Main, Modal } from 'components/modals/modal/Modal.style';
import styled from 'styled-components/macro';

export const EditModal = styled(Modal)(({ theme: { typo, color, neutral } }) => ({
	maxWidth: '900px',

	h2: {
		margin: 0,
		paddingBottom: '15px',
		color: color.text.secondary03,
		...typo.headline['02'],
	},

	h3: {
		margin: 0,
		fontFamily: 'Arial',
		fontSize: '21px',
		padding: '0 0 10px 0',
		textTransform: 'none',
		fontWeight: '500',
		color: neutral['80'],
	},
}));

export const EditModalMain = styled(Main)(() => ({
	overflow: 'auto',

	'::-webkit-scrollbar': {
		width: '5px',
		height: '5px',
		borderRadius: '10px',
		background: '#f0f0f0',
	},
	'::-webkit-scrollbar-thumb': {
		borderRadius: '20px',
		background: '#b4b4b4',
	},
	'::-webkit-scrollbar-track': {
		background: 'rgba(0, 0, 0, 0.1)',
	},
}));

export const RowImage = styled.div(() => ({
	display: 'inline-flex',
	margin: '30px 0',
	width: '100%',
}));

export const Row = styled.div(({ theme: { breakpoint } }) => ({
	display: 'flex',
	flexDirection: 'column',
	gap: '15px',

	width: '100%',
	marginBottom: '20px',

	[breakpoint.md()]: {
		flexDirection: 'row',
	},
}));

export const ColumnInputs = styled.div(() => ({
	flex: 1,
}));

export const ColumnJustification = styled.div(({ theme: { breakpoint } }) => ({
	marginTop: '10px',
	minWidth: '228px',

	[breakpoint.md()]: {
		marginTop: 0,
	},
}));

export const UploadImage = styled.div(() => ({
	display: 'inline-flex',
	flexDirection: 'column',
	position: 'relative',

	img: {
		objectFit: 'contain',
		textIndent: '-10000px',
	},
}));

export const OverlayButton = styled.button(({ theme: { neutral } }) => ({
	display: 'block',
	position: 'absolute',
	padding: '10px 15px',
	bottom: 0,
	textAlign: 'center',
	width: '100%',
	borderStyle: 'none',
	textDecoration: 'none',
	color: neutral.white,
	background: 'rgba(0, 0, 0, 0.7)',
	cursor: 'pointer',

	label: {
		fontFamily: 'Helvetica Neue',
		fontSize: '16px',
		textTransform: 'uppercase',
		cursor: 'pointer',
	},

	'&:hover': {
		background: 'rgba(0, 0, 0, 0.8)',
	},
}));

export const InputGroup = styled.div(() => ({
	display: 'flex',
	flexDirection: 'column',
}));

export const JustificationGroup = styled.div(() => ({
	display: 'flex',
	flexDirection: 'column',
}));

const baseInputStyles = ({ color, typo }) => ({
	padding: '10px',
	width: '100%',
	height: '35px',
	border: '1px solid #cccccc',
	borderRadius: '4px',
	boxSizing: 'border-box',

	color: color.field.secondary.text,
	...typo.lead['06'],

	'&::placeholder': {
		color: color.field.secondary.placeholder,
		...typo.lead['06'],
	},

	'&:disabled': {
		cursor: 'default',
	},
});

export const LabelInput = styled.label(({ theme: { color, typo } }) => ({
	padding: '0 0 8px 0',

	color: color.text.quaternary02,
	...typo.body['03'],
}));

export const LabelJustification = styled.label(({ theme: { color, typo } }) => ({
	marginTop: '0px',
	paddingBottom: '8px',

	color: color.text.quaternary03,
	...typo.body['04'],
}));

export const Input = styled.input(({ theme }) => ({
	...baseInputStyles(theme),
}));

export const TextArea = styled.textarea(({ theme }) => ({
	...baseInputStyles(theme),

	resize: 'vertical',
	overflowY: 'hidden',
	minHeight: '80px',
}));

export const JustificationButtons = styled.div(() => ({
	display: 'flex',
	gap: '30px',
	width: '100%',
	height: '49px',
}));

export const JustificationButton = styled.button(({ imageDefault, imageActive, checked }) => ({
	display: 'flex',
	margin: '0',
	width: '56px',
	height: 'inherit',
	backgroundColor: 'none',
	border: 'none',
	background: `url(${checked ? imageActive : imageDefault}) no-repeat`,

	'&:hover': {
		background: `url(${imageActive}) no-repeat`,
	},
}));
