/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { EditNavigation } from 'components/editNavigation';
import useBrandDetails from 'hooks/useBrandDetails';
import useBrandRoute from 'hooks/useBrandRoute';
import { PAGE_TYPES } from 'constants/pageConstants';
import { ArticleItem } from './ArticleItem';
import * as S from './BrandSideBarArticle.style';

const BrandSideBarArticle = ({ vcmId, title, path, isExpanded, isDisableEdit, onCloseMenu, onMenuClick }) => {
	const history = useHistory();
	const { currentType } = useBrandDetails();
	const { getBrandRoute } = useBrandRoute();

	const parentRef = useRef(null);
	const [isSelectedItem, setIsSelectedItem] = useState(false);

	const isItemActivated = currentType?.articleId === vcmId && currentType?.path === path;
	const isEditNavigationEnabled = isItemActivated && isExpanded && !isDisableEdit;

	const onSelectedItem = useCallback(() => setIsSelectedItem(isItemActivated), [isItemActivated]);

	useEffect(() => {
		if (path && currentType) {
			onSelectedItem(isSelectedItem);
		}
	}, [path, currentType, isSelectedItem, onSelectedItem]);

	const handleClick = e => {
		e.preventDefault();
		setTimeout(() => {
			onMenuClick(vcmId);
		});
		onCloseMenu();
		history.push(getBrandRoute(path));
	};

	return (
		<S.BrandSideBarArticle ref={parentRef} data-testid={PAGE_TYPES.ARTICLE_TEST_ID}>
			<EditNavigation.Menu show={isEditNavigationEnabled} parentRef={parentRef} value={title} isLastLevel>
				<ArticleItem to={getBrandRoute(path)} onClick={handleClick}>
					{title}
				</ArticleItem>
			</EditNavigation.Menu>
		</S.BrandSideBarArticle>
	);
};

BrandSideBarArticle.propTypes = {
	vcmId: PropTypes.string,
	title: PropTypes.string,
	path: PropTypes.string,
	isExpanded: PropTypes.bool,
	isDisableEdit: PropTypes.bool,
	onCloseMenu: PropTypes.func,
	onMenuClick: PropTypes.func,
};

BrandSideBarArticle.defaultProps = {
	vcmId: null,
	title: '',
	path: '',
	isExpanded: false,
	isDisableEdit: false,
	onCloseMenu: () => {},
	onMenuClick: () => {},
};

export { BrandSideBarArticle };
