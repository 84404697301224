/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { PAGE_TYPES } from 'constants/pageConstants';
import { hasLength } from './arrayUtil';
import { generateDisplayOrder } from './contentFragmentUtil';

const getPreviousIndex = index => index - 1;

const getNextIndex = index => index + 1;

const findIndexByPath = (chapters, path) => {
	for (const i in chapters) {
		const chapter = chapters[i];

		if (chapter.path === path) {
			return { chapterIndex: +i };
		}

		const { sections } = chapter;

		for (const j in sections) {
			const section = sections[j];

			if (section.path === path) {
				return { chapterIndex: +i, sectionIndex: +j };
			}

			const { articles } = section;

			for (const k in articles) {
				const article = articles[k];

				if (article.path === path) {
					return { chapterIndex: +i, sectionIndex: +j, articleIndex: +k };
				}
			}
		}
	}

	return chapters;
};

export const handleMoveItem = (collection, currentType, onMove) => {
	const auxArray = [...collection];
	const foundItem = findIndexByPath(auxArray, currentType?.path);
	let data = {};

	if (currentType.type === PAGE_TYPES.CHAPTER_TYPE) {
		const { previousItem, currentItem, items: modifiedChapters } = onMove(auxArray, foundItem.chapterIndex);
		const updatedArray = [...modifiedChapters];

		data = {
			previousItem,
			currentItem,
			updatedArray,
		};
	}

	if (currentType.type === PAGE_TYPES.SECTION_TYPE) {
		const {
			previousItem,
			currentItem,
			items: modifiedSections,
		} = onMove(auxArray[foundItem.chapterIndex]?.sections, foundItem.sectionIndex);

		const updatedArray = auxArray.map((chapter, i) => {
			if (i === foundItem.chapterIndex) {
				return {
					...chapter,
					sections: modifiedSections,
				};
			}

			return chapter;
		});

		data = {
			previousItem,
			currentItem,
			updatedArray,
		};
	}

	if (currentType.type === PAGE_TYPES.ARTICLE_TYPE) {
		const {
			previousItem,
			currentItem,
			items: modifiedArticles,
		} = onMove(auxArray[foundItem.chapterIndex]?.sections[foundItem.sectionIndex]?.articles, foundItem.articleIndex);

		const updatedArray = auxArray.map((chapter, i) => {
			if (i === foundItem.chapterIndex) {
				return {
					...chapter,
					sections: auxArray[foundItem.chapterIndex]?.sections.map((section, j) => {
						if (j === foundItem.sectionIndex) {
							return {
								...section,
								articles: modifiedArticles,
							};
						}

						return section;
					}),
				};
			}

			return chapter;
		});

		data = {
			previousItem,
			currentItem,
			updatedArray,
		};
	}

	return data;
};

export const handleAddItem = (collection, currentType, payload, type = PAGE_TYPES.CHAPTER_TYPE) => {
	const auxArray = [...collection];
	const foundItem = findIndexByPath(auxArray, currentType?.path);
	let result = [];

	if (type === PAGE_TYPES.CHAPTER_TYPE) {
		auxArray.push(payload);
		result = [...auxArray];
	}

	if (type === PAGE_TYPES.SECTION_TYPE) {
		const chapter = auxArray[foundItem.chapterIndex];
		const sections = chapter?.sections;

		if (hasLength(sections)) {
			chapter?.sections?.push(payload);
		} else {
			chapter.sections = new Array(1).fill(payload);
		}

		result = [...auxArray];
	}

	if (type === PAGE_TYPES.ARTICLE_TYPE) {
		const chapter = auxArray[foundItem.chapterIndex];
		const section = chapter.sections[foundItem.sectionIndex];
		const articles = section?.articles;

		if (hasLength(articles)) {
			articles?.push(payload);
		} else {
			section.articles = new Array(1).fill(payload);
		}

		result = [...auxArray];
	}

	return result;
};

export const handleUpdateItem = (collection, currentType, value) => {
	const auxArray = [...collection];
	const { type } = currentType;
	const foundItem = findIndexByPath(auxArray, currentType?.path);
	let result = [];

	if (type === PAGE_TYPES.CHAPTER_TYPE) {
		const chapter = auxArray[foundItem.chapterIndex];
		chapter.title = value;
		result = [...auxArray];
	}

	if (type === PAGE_TYPES.SECTION_TYPE) {
		const chapter = auxArray[foundItem.chapterIndex];
		const section = chapter?.sections[foundItem.sectionIndex];
		section.title = value;
		result = [...auxArray];
	}

	if (type === PAGE_TYPES.ARTICLE_TYPE) {
		const chapter = auxArray[foundItem.chapterIndex];
		const section = chapter?.sections[foundItem.sectionIndex];
		const article = section?.articles[foundItem.articleIndex];
		article.title = value;
		result = [...auxArray];
	}

	return result;
};

export const handleRemoveItem = (collection, currentType) => {
	const auxArray = [...collection];
	const foundItem = findIndexByPath(auxArray, currentType?.path);
	const { type } = currentType;
	let result = [];

	if (type === PAGE_TYPES.CHAPTER_TYPE) {
		auxArray?.splice(foundItem.chapterIndex, 1);
		result = [...auxArray];
	}

	if (type === PAGE_TYPES.SECTION_TYPE) {
		auxArray[foundItem.chapterIndex]?.sections?.splice(foundItem.sectionIndex, 1);
		result = [...auxArray];
	}

	if (type === PAGE_TYPES.ARTICLE_TYPE) {
		auxArray[foundItem.chapterIndex]?.sections[foundItem.sectionIndex]?.articles?.splice(foundItem.articleIndex, 1);
		result = [...auxArray];
	}

	return result;
};

export const moveUp = (array, currentIndex) => {
	const items = [...array];

	let previousItem = {};
	let currentItem = {};

	if (currentIndex > 0) {
		previousItem = items[getPreviousIndex(currentIndex)];
		currentItem = items[currentIndex];

		const el = items[currentIndex];
		items[currentIndex] = items[getPreviousIndex(currentIndex)];
		items[getPreviousIndex(currentIndex)] = el;
	}

	return { previousItem, currentItem, items };
};

export const moveDown = (array, currentIndex) => {
	const items = [...array];

	let previousItem = {};
	let currentItem = {};

	if (currentIndex !== -1 && currentIndex < items.length - 1) {
		previousItem = items[getNextIndex(currentIndex)];
		currentItem = items[currentIndex];

		const el = items[currentIndex];
		items[currentIndex] = items[getNextIndex(currentIndex)];
		items[getNextIndex(currentIndex)] = el;
	}

	return { previousItem, currentItem, items };
};

export const mappingSelectAllData = collection => checked => {
	return (
		collection &&
		collection?.map(item => ({
			...item,
			selected: checked || false,
			articles: item?.articles && item?.articles?.map(article => ({ ...article, selected: checked })),
		}))
	);
};

const changeItemById = (id, checked) => obj => {
	if (obj.id === id) obj.selected = checked;
	else if (obj.articles) obj.articles.forEach(changeItemById(id, checked));
};

export const updateItemById = collection => (id, checked) => collection.forEach(changeItemById(id, checked));

export const generateDisplayOrderByType = (collection, currentType, type = PAGE_TYPES.CHAPTER_TYPE) => {
	const chapters = [...collection];
	const foundItem = findIndexByPath(chapters, currentType?.path);
	let displayOrder = 1;

	if (type === PAGE_TYPES.CHAPTER_TYPE) {
		if (hasLength(chapters)) {
			displayOrder = generateDisplayOrder(chapters);
		}
	}

	if (type === PAGE_TYPES.SECTION_TYPE) {
		const chapter = chapters[foundItem.chapterIndex];
		const sections = chapter?.sections;

		if (hasLength(sections)) {
			displayOrder = generateDisplayOrder(sections);
		}
	}

	if (type === PAGE_TYPES.ARTICLE_TYPE) {
		const chapter = chapters[foundItem.chapterIndex];
		const section = chapter.sections[foundItem.sectionIndex];
		const articles = section?.articles;

		if (hasLength(articles)) {
			displayOrder = generateDisplayOrder(articles);
		}
	}

	return `${displayOrder}`;
};
