import { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { InnerHtml } from '@hyatt/inner-html';
import { useTheme } from 'styled-components';
import { Loading } from 'components/loadingOverlay';
import { EDIT_MENU_TYPES } from 'components/begEditMenu/constants/begEditMenuConstants';
import useBrandDetails from 'hooks/useBrandDetails';
import { useEditMenuData } from 'hooks/useEditMenuData';
import { useOnOutsideClick } from 'hooks/useOnOutsideClick';
import { usePutContentFragment } from 'hooks/usePutContentFragment';
import useParagraphs from 'hooks/useParagraphs';
import { useHighlight } from 'hooks/useHighlight';
import { compareOnlyContent, isEmptyString } from 'utils/stringUtil';
import { MODELS_PATHS } from 'constants/aemConstants';
import { PARAGRAPH_PROPS } from 'constants/paragraphConstants';
import { translations } from 'translation/en';
import { EditableTitleInput } from './inputs/EditableTitleInput';
import { DiscardChangesModal } from './modals/discardChangesModal';
import { PropertiesModal } from './modals/propertiesModal';
import { EditableTypes } from './types/editableTypes';
import { SaveBarOptions } from './saveBarOptions';
import * as S from './EditableContent.style';

const EditableTitleWrapper = ({
	content,
	setContent,
	placeholder,
	fieldName,
	modulePath,
	isCollapsable,
	setIsCollapsable,
	startKeywords,
	setStartKeywords,
	paragraph,
	...props
}) => {
	const initialState = useMemo(() => {
		return {
			type: EditableTypes.RESET,
		};
	}, []);

	const { logo } = useTheme();
	const { currentType } = useBrandDetails();
	const { selectedToEdit } = useEditMenuData();
	const { loadingUpdate, updateContentFragment } = usePutContentFragment({ shouldShowToast: false });
	const { handleUpdateParagraph } = useParagraphs();
	const { handleTextHighlight } = useHighlight();

	const [state, setDispatch] = useState(initialState);
	const [initialContent, setInitialContent] = useState(content);
	const [isDifferentContent, setIsDifferentContent] = useState(false);

	const containerRef = useRef(null);

	const isEditModeOn = selectedToEdit === EDIT_MENU_TYPES.EDIT_INLINE;
	const isEditing = isEditModeOn && state.type === EditableTypes.EDITING;

	const handleChangedContent = isEnabled => {
		setIsDifferentContent(isEnabled);
	};

	const onEditing = () => {
		setDispatch({ type: EditableTypes.EDITING });
	};

	const onConfig = () => {
		setDispatch({ type: EditableTypes.CONFIG_MODAL });
		setContent(initialContent);
	};

	const handleClose = useCallback(() => {
		if (isEditing) {
			if (isDifferentContent) {
				setDispatch({ type: EditableTypes.DISCARD_CHANGES });
				return;
			}

			setDispatch(initialState);
		}
	}, [initialState, isEditing, isDifferentContent]);

	const handleEditorChange = newText => {
		const hasChanged = !compareOnlyContent(initialContent, newText);

		setContent(newText);
		setIsDifferentContent(hasChanged);
	};

	const handleLocalStateChange = useCallback(
		currentContent => {
			const newParagraph = { ...paragraph, [fieldName]: currentContent };
			handleUpdateParagraph(newParagraph);
			setInitialContent(currentContent);
		},
		[fieldName, handleUpdateParagraph, paragraph]
	);

	const handleSave = useCallback(() => {
		updateContentFragment({
			payload: { [fieldName.toUpperCase()]: isEmptyString(content) },
			path: modulePath,
			cfType: currentType?.type,
			model: MODELS_PATHS[PARAGRAPH_PROPS.RELATOR_PARAGRAPHS_KEY.toUpperCase()],
			shouldApprove: true,
		}).then(res => {
			const isSuccess = res.some(response => response?.data);
			if (isSuccess) {
				handleLocalStateChange(content);
				setIsDifferentContent(false);
			}
		});
	}, [updateContentFragment, fieldName, content, modulePath, currentType?.type, handleLocalStateChange]);

	const handleSaveAsDraft = useCallback(() => {
		updateContentFragment({
			payload: { [fieldName.toUpperCase()]: isEmptyString(content) },
			path: modulePath,
			cfType: currentType?.type,
			model: MODELS_PATHS[PARAGRAPH_PROPS.RELATOR_PARAGRAPHS_KEY.toUpperCase()],
			shouldApprove: false,
		}).then(res => {
			const isSuccess = res.some(response => response?.data);
			if (isSuccess) {
				handleLocalStateChange(content);
				setIsDifferentContent(false);
			}
		});
	}, [updateContentFragment, fieldName, content, modulePath, currentType?.type, handleLocalStateChange]);

	const handleDiscardChanges = useCallback(() => {
		setContent(initialContent);
		setDispatch(initialState);
		setIsDifferentContent(false);
	}, [initialContent, initialState, setContent]);

	const handleCloseConfigModal = () => {
		setDispatch(initialState);
	};

	useOnOutsideClick({ ref: containerRef, action: handleClose });

	const editIcon = (
		<S.EditIcon
			id="edit-icon"
			className="icon-pencil"
			role="button"
			aria-label="edit-icon"
			tabIndex={0}
			onClick={onEditing}
		/>
	);

	return (
		<>
			<S.EditableInputContainer ref={containerRef} {...props}>
				<S.PlaceholderTitleWrapper>
					{isEditModeOn && editIcon}
					{((isEditModeOn && !content && !isEditing) || (loadingUpdate && !content)) && (
						<S.PlaceholderTitle uppercase>{placeholder}</S.PlaceholderTitle>
					)}
				</S.PlaceholderTitleWrapper>

				<S.TitleContainer>
					{loadingUpdate && isEditing && (
						<Loading.Overlay optionalClassName="EditableInputContainer__overlay">
							<Loading.LoadingLogo logo={logo?.loading} size={logo?.size.loading} />
						</Loading.Overlay>
					)}

					{content && !isEditing && <InnerHtml body={handleTextHighlight(content)} />}

					{isEditing && (
						<>
							<SaveBarOptions onSave={handleSave} onSaveAsDraft={handleSaveAsDraft} onClose={handleClose} />

							<EditableTitleInput
								containerId={`editable-input-text-container-${fieldName}`}
								placeholder={placeholder}
								value={content}
								initialContent={initialContent}
								onChangedContent={handleChangedContent}
								onChange={handleEditorChange}
								onConfig={onConfig}
							/>
						</>
					)}
				</S.TitleContainer>
			</S.EditableInputContainer>

			{state.type === EditableTypes.CONFIG_MODAL && (
				<PropertiesModal
					open
					titleValue={content}
					fieldName={fieldName}
					isCollapsable={isCollapsable}
					setIsCollapsable={setIsCollapsable}
					startKeywords={startKeywords}
					setStartKeywords={setStartKeywords}
					modulePath={modulePath}
					onClose={handleCloseConfigModal}
				/>
			)}

			{state.type === EditableTypes.DISCARD_CHANGES && (
				<DiscardChangesModal open onContinueEdit={onEditing} onDiscardChanges={handleDiscardChanges} />
			)}
		</>
	);
};

EditableTitleWrapper.propTypes = {
	content: PropTypes.string.isRequired,
	setContent: PropTypes.func.isRequired,
	fieldName: PropTypes.string.isRequired,
	setIsCollapsable: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	isCollapsable: PropTypes.bool,
	modulePath: PropTypes.string.isRequired,
	startKeywords: PropTypes.arrayOf(PropTypes.string),
	setStartKeywords: PropTypes.func.isRequired,
	paragraph: PropTypes.objectOf(Object),
};

EditableTitleWrapper.defaultProps = {
	placeholder: translations.INSERT_TEXT_HERE,
	isCollapsable: false,
	startKeywords: [],
	paragraph: {},
};

export { EditableTitleWrapper };
