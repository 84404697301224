/* eslint-disable max-len */
/* eslint-disable no-param-reassign */

const hasClosingHtmlTag = str => {
	const regex = /<\/[a-zA-Z]+>/;
	return regex.test(str);
};

const hasHtmlTags = str => {
	const htmlRegex = /<[a-z][\s\S]*>/i;
	return htmlRegex.test(str);
};

const wrapSearchedWord = (searchWord, inputString) => {
	const regex = new RegExp(searchWord, 'gi');
	return inputString.replace(regex, match => `<mark>${match}</mark>`);
};

// checks if the match is broken by html tags, if so, find the word inside the string and wrap it with a mark tag
const handleStringBrokenByHtml = (str, contentArray, firstMatchIndex, searchTermsArray) => {
	if (hasHtmlTags(str) || hasClosingHtmlTag(str)) {
		contentArray
			?.slice(firstMatchIndex, firstMatchIndex + searchTermsArray.length)
			.forEach((matchedWord, matchedWordIndex) => {
				searchTermsArray.forEach(term => {
					contentArray[firstMatchIndex + matchedWordIndex] = wrapSearchedWord(
						term,
						contentArray[firstMatchIndex + matchedWordIndex]
					);
				});
			});
	} else {
		contentArray[firstMatchIndex] = `<mark>${contentArray[firstMatchIndex]}`;
		contentArray[firstMatchIndex + searchTermsArray.length - 1] = `${
			contentArray[firstMatchIndex + searchTermsArray.length - 1]
		}</mark>`;
	}
};

export const getHighlightContent = ({ isExactMatch, searchTerms, content }) => {
	let wrappedText = content;

	const withoutTags = content?.replace(/<[^>]+>/g, '');
	const contentArray = content?.replace(/&nbsp;/g, ' ')?.split(/\s+/);
	const searchTermsArray = searchTerms?.toLowerCase()?.split(' ');

	// on exact match, compare each word to each term searched, looking for an exact match
	if (searchTerms) {
		if (isExactMatch) {
			contentArray?.forEach((word, index) => {
				let firstMatchIndex = -1;
				// if a match is found, check if the next words match the next terms in the search if they do,check the next  and so on...
				if (word.toLowerCase().includes(searchTermsArray[0])) {
					let wordsMatchOrder = true;
					let currentSearchTermIndex = 1;
					firstMatchIndex = index;

					contentArray?.slice(firstMatchIndex + 1, firstMatchIndex + searchTermsArray.length)?.forEach(nextWord => {
						if (nextWord.toLowerCase().includes(searchTermsArray[currentSearchTermIndex])) {
							currentSearchTermIndex += 1;
						} else {
							wordsMatchOrder = false;
						}
					});

					if (wordsMatchOrder) {
						handleStringBrokenByHtml(
							contentArray.slice(firstMatchIndex, firstMatchIndex + searchTermsArray.length),
							contentArray,
							firstMatchIndex,
							searchTermsArray
						);
					}
				}
			});

			wrappedText = contentArray?.join(' ');

			return wrappedText;
		}
		searchTermsArray.forEach(term => {
			// Perform the search on the modified text
			const regex = new RegExp(term, 'gi');
			let match = regex.exec(withoutTags);

			while (match !== null) {
				const matchedWord = match[0];
				const matchedIndex = match.index;

				// Find the index of the matched word in the original text
				const originalIndex = content?.indexOf(matchedWord, matchedIndex);

				wrappedText =
					wrappedText.slice(0, originalIndex) +
					wrappedText.slice(originalIndex).replace(matchedWord, `<mark>${matchedWord}</mark>`);

				match = regex.exec(withoutTags);
			}
		});
	}
	return wrappedText;
};

const iterateNodes = (nodes, highlightRegex) => {
	nodes.forEach(node => {
		if (node.nodeType === Node.ELEMENT_NODE && node.nodeValue !== '\n') {
			const newNode = node.cloneNode(true);
			const { innerText, innerHTML } = newNode;
			const normalizedInnerText = innerText
				?.replaceAll('\n', '')
				.replaceAll('&nbsp;', ' ')
				.replaceAll(/\u00A0/g, ' ');
			const normalizedInnerHTML = innerHTML?.replaceAll('&nbsp;', ' ').replaceAll(/\u00A0/g, ' ');
			const highlightedText = innerText?.replaceAll(
				highlightRegex,
				match => `<mark class="search-highlight">${match}</mark>`
			);
			const newInnerHTML = normalizedInnerHTML?.replace(normalizedInnerText, highlightedText);
			if (newInnerHTML !== normalizedInnerHTML) {
				newNode.innerHTML = newInnerHTML;
			}
			if (newNode.childNodes.length > 0 && newNode.childNodes?.[0].nodeName !== 'MARK') {
				iterateNodes(newNode.childNodes, highlightRegex);
			}
			node.replaceWith(newNode);
		}
	});
};

export const getHighlightOnSearchResults = ({ isExactMatch, searchTerm, content }) => {
	const highlightRegex =
		isExactMatch === 'true' ? new RegExp(searchTerm, 'gi') : new RegExp(searchTerm.split(' ').join('|'), 'gi');

	if (hasHtmlTags(content)) {
		const parser = new DOMParser();
		const doc = parser.parseFromString(content, 'text/html');
		const nodes = doc.body.childNodes;
		iterateNodes(nodes, highlightRegex);
		return doc.body.innerHTML;
	}

	return content?.replaceAll(highlightRegex, match => `<mark class="search-highlight">${match}</mark>`);
};
