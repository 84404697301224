import axios from '../API/axios/axios';

export const getSecurityConfigs = async () => {
	const url = `${process.env.REACT_APP_API_URL}/v1/contentFragment/allowedDepartmentsGroups`;

	const {
		data: { adGroups, adDepartments },
	} = await axios.get(url);

	return {
		adGroups,
		adDepartments,
	};
};
