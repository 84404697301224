import { Modal } from 'components/modals/modal';
import { Main } from 'components/modals/modal/Modal.style';
import styled from 'styled-components/macro';

export const CheckListModal = styled(Modal)(() => ({
	width: '85%',
	maxWidth: '500px',
	transform: 'translate(0, 0)',
	transition: 'transform .3s ease-out',

	'> *': {
		borderTop: 'none',
	},
}));

export const CheckListModalMain = styled(Main)(() => ({
	overflow: 'auto',
	margin: '25px 0',
}));

export const Title = styled.h5(({ theme: { color, typo } }) => ({
	margin: 0,
	padding: 0,
	textAlign: 'center',
	color: color.text.approveModalTitle,
	...typo.headline['08'],
}));

export const Description = styled.p(({ theme: { color, typo } }) => ({
	textAlign: 'center',
	color: color.text.approveModalDescription,
	...typo.caption['03'],
}));

export const ModifiedList = styled.ul({
	listStyle: 'none',
});

export const ModifiedItem = styled.li({
	display: 'flex',
	marginBottom: '12px',
});

export const Wrapper = styled.div({
	display: 'flex',
	position: 'relative',
	flexWrap: 'wrap',
	top: '2px',
	gap: '5px',
});

export const ModifiedLabel = styled.span(({ theme: { color, size } }) => ({
	marginRight: '5px',
	marginBottom: '5px',
	fontFamily: 'Arial, Helvetica, sans-serif',
	fontSize: size.xs,
	color: color.neutral['80'],
}));

export const ModifiedDate = styled.span(({ theme: { color } }) => ({
	display: 'flex',
	padding: '2px 5px',
	fontFamily: 'Arial, Helvetica, sans-serif',
	fontSize: '12px',
	borderRadius: '3px',
	background: color.neutral['35'],
	color: color.neutral['66'],
	height: 'fit-content',
}));
