import styled from 'styled-components/macro';

export const BrandSideBar = styled.div(({ theme: { color, breakpoint } }) => ({
	display: 'none',
	position: 'relative',
	padding: '55px 0',
	width: '265px',
	height: '100%',
	minHeight: '550px',

	background: color.background.secondary,
	zIndex: 25,

	[breakpoint.lg()]: {
		display: 'block',
	},
}));

export const SectionLogo = styled.div(({ theme: { typo } }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: '100%',
	height: 'fit-content',
	...typo.link['06'],
}));

export const ContainerItems = styled.div({
	display: 'block',
	width: '100%',
	height: '100%',
});
