import WorldOfHyattLogo from 'assets/img/logos/world.svg';
import complementaryTheme from 'themes/complementary/complementary';
import colors from './worldOfHyattColors';
import typographyTheme from './worldOfHyattTypo';

const logo = {
	footer: WorldOfHyattLogo,
	loading: WorldOfHyattLogo, // logo in the loading
	sidebar: WorldOfHyattLogo,
	mobile: WorldOfHyattLogo,
	size: {
		sidebar: {
			width: '99px',
			height: '99px',
		},
		footer: {
			width: '45px',
			height: '45px',
		},
		navbar: {
			width: '77px',
			height: '77px',
		},
		loading: {
			width: '77px',
			height: '77px',
		},
	},
};

const worldOfHyatt = {
	brandId: 'wh',
	name: 'World Of Hyatt',
	logo,
	color: colors,

	font: {
		family: {
			helveticaNeue: 'Helvetica Neue',
			notoSansRegular: 'NotoSans Regular',
			arialSansSerif: 'Arial, Helvetica, sans-serif',
			yesevaOneRegular: 'YesevaOne Regular',
			calibri: 'Calibri',
			notoSansBold: 'NotoSans Bold',
			poynterRegular: 'Poynter Regular',
			gothamLight: 'Gotham Light',
			darbySansMedium: 'DarbySans Medium',
		},
	},
};

const worldOfHyattTheme = {
	...worldOfHyatt,
	...typographyTheme(worldOfHyatt),
	...complementaryTheme,
};

export default worldOfHyattTheme;
