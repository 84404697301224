import { useCallback, useEffect } from 'react';
import { breadcrumbService } from 'services/breadcrumbService';
import { translations } from 'translation/en';
import { showToast } from 'utils/toastUtil';
import { useRequest } from './useRequest';

export const useBreadcrumbService = () => {
	const { loading: loadingBreadcrumb, data, error: breadcrumbError, run } = useRequest(breadcrumbService);

	useEffect(() => {
		if (breadcrumbError) {
			showToast({ type: 'error', message: translations.SERVICE_FAILURE });
		}
	}, [breadcrumbError]);

	const getBreadcrumbFromPageIdOrPath = useCallback(
		async idOrPath => {
			const result = await run([idOrPath]);
			return result?.data?.[0];
		},
		[run]
	);

	return { loadingBreadcrumb, breadcrumbData: data, breadcrumbError, getBreadcrumbFromPageIdOrPath };
};
