import { useCallback } from 'react';
import PropTypes from 'prop-types';
import useParagraphs from 'hooks/useParagraphs';
import { useMoveModule } from 'hooks/useMoveModule';
import useBrandDetails from 'hooks/useBrandDetails';
import { isFirstParagraph, isLastParagraph, moveParagraphDown, moveParagraphUp } from 'utils/paragraphUtil';
import './MoveModule.scss';

const MoveModule = ({ paragraph }) => {
	const { currentType } = useBrandDetails();
	const { paragraphs, onStartedLoadingParagraphs, onFinishedLoadingParagraphs, handleSwapParagraph } = useParagraphs();
	const { swappingModule } = useMoveModule();

	const isFirstElement = isFirstParagraph(paragraphs.paragraphs, paragraph);
	const isLastElement = isLastParagraph(paragraphs.paragraphs, paragraph);

	const onFinally = useCallback(() => {
		onFinishedLoadingParagraphs();
	}, [onFinishedLoadingParagraphs]);

	const handleSwap = useCallback(
		({ currentItem, previousItem, replacedArr }) => {
			if (!currentItem || !previousItem) return;
			onStartedLoadingParagraphs();

			swappingModule({
				type: currentType.type,
				pagePath: currentType.path,
				currentItem,
				previousItem,
				onSuccess: () => handleSwapParagraph(replacedArr),
				onFinished: () => onFinally(),
			});
		},
		[currentType, onStartedLoadingParagraphs, handleSwapParagraph, swappingModule, onFinally]
	);

	const handleMoveUp = useCallback(
		module => {
			const payload = moveParagraphUp(paragraphs?.paragraphs, module);
			handleSwap(payload);
		},
		[paragraphs, handleSwap]
	);

	const handleMoveDown = module => {
		const payload = moveParagraphDown(paragraphs?.paragraphs, module);
		handleSwap(payload);
	};

	return (
		<div className="MoveModule">
			<button
				aria-label="arrow-up-module-action"
				className="MoveModule__button"
				disabled={isFirstElement}
				onClick={() => handleMoveUp(paragraph)}
			>
				<i className="icon icon-chevron-up" />
			</button>
			<button
				aria-label="arrow-down-module-action"
				className="MoveModule__button"
				disabled={isLastElement}
				onClick={() => handleMoveDown(paragraph)}
			>
				<i className="icon icon-chevron-down" />
			</button>
		</div>
	);
};

MoveModule.propTypes = {
	paragraph: PropTypes.shape({
		vcmId: PropTypes.string,
	}),
};

MoveModule.defaultProps = {
	paragraph: null,
};

export { MoveModule };
