import { createJsonString, easyDecrypt, easyEncrypt, parseJsonString } from './stringUtil';

const createDataEncoded = (data = {}, dataKey) => {
	return easyEncrypt(
		createJsonString({
			dk: dataKey,
			...data,
		})
	);
};

const decodeQueryValue = encryptedQueryValue => {
	const dataString = easyDecrypt(encryptedQueryValue);
	const params = parseJsonString(dataString);
	return params;
};

/**
 *
 * @param {object} params Object parameter to encode
 * @param {string} key Key to encrypt the parameter
 * @returns {string}
 */
export const encodeParams = (params, key) => {
	return createDataEncoded(params, key);
};

export const decodeParams = encryptedParams => {
	return decodeQueryValue(encryptedParams);
};

export const getEncodedKeyParam = (key, params) => {
	return {
		[key]: encodeParams(params, key),
	};
};

/**
 *
 * @param {object} params Object parameter to serialize WITH encode
 * @returns {string}
 */
export const paramsSerializer = params => {
	const result = [];
	Object.keys(params).forEach(k => {
		if (params[k] !== undefined) {
			result.push(`${k}=${encodeURIComponent(params[k])}`);
		}
	});
	return result.join('&');
};

/**
 *
 * @param {object} params Object parameter to serialize WITHOUT encode
 * @returns {string}
 */
export const paramsSerializerNotEncoded = params => {
	const query = Object.keys(params)
		.map(k => `${k}=${params[k]}`)
		.join('&');
	return query;
};
