import styled from 'styled-components/macro';

const variantStyle = ({ variant = 'primary', color, typo }) => {
	switch (variant) {
		case 'primary':
		case 'primary-03':
			return {
				...typo.button['04'],
				color: color.interactive.primary.default.label,
			};
		case 'primary-04':
			return {
				...typo.button['07'],
				color: color.interactive.discardChangesButtons?.default.label,
			};
		case 'primary-05':
			return {
				...typo.button['08'],
				color: color.interactive.primary05?.default.label,
			};
		case 'dark-gray':
		case 'primary-02':
			return {
				...typo.button['04'],
				color: color.neutral.white,
			};
		case 'secondary':
			return {
				...typo.button['04'],
				color: color.interactive.secondary.default.label,
			};
		case 'secondary-02':
			return {
				...typo.button['02'],
				color: color.interactive.secondary02.default.label,
			};
		case 'secondary-03': {
			return {
				...typo.button['06'],
				color: color.interactive.secondary03.default.label,
			};
		}
		case 'tertiary':
			return {
				...typo.button['03'],
				color: color.interactive.tertiary.default.label,
			};
		case 'tertiary-02':
			return {
				...typo.button['04'],
				color: color.interactive.printModalButtons?.default.label,
			};
		case 'tertiary-03': {
			return {
				...typo.button['05'],
				color: color.interactive.tertiary03.default.label,
			};
		}
		case 'outlined':
			return {
				...typo.button['03'],
				...typo.button['03'].sm,
				color: color.interactive.outlined.default.label,
			};
		case 'gray-border-only': {
			return {
				...typo.button['05'],
				color: color.neutral['100'],
			};
		}
		case 'gray-border': {
			return {
				fontSize: '14px',
				textTransform: 'uppercase',
				letterSpacing: '1px',
			};
		}
		case 'gray-border-secondary': {
			return {
				fontFamily: 'Arial, Helvetica, sans-serif',
				fontSize: '12px',
				textTransform: 'uppercase',
				letterSpacing: '1px',
			};
		}
		default:
			return {};
	}
};

export const Label = styled.span(({ theme: { color, typo }, variant }) => ({
	...variantStyle({ variant, color, typo }),
}));
