import { Button } from 'components/button';
import styled from 'styled-components/macro';

export const MenuButton = styled(Button)({
	'&&': {
		width: '44px',
		height: '35px',
	},
});

export const Icon = styled.i(({ theme: { color, typo } }) => ({
	color: color.interactive.outlined.default.icon,
	...typo.button.icon['2xl'],
}));
