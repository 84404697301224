import { CSSProperties } from 'react';
import SpinnerMainSvg from './assets/loading-main.svg';
import SpinnerMenuBlackSvg from './assets/loading-menu-black.svg';
import SpinnerMenuGraySvg from './assets/loading-menu-gray.svg';

const getSpinnerType = (type: SpinnerType) => {
	switch (type) {
		case 'main':
			return SpinnerMainSvg;
		case 'black':
			return SpinnerMenuBlackSvg;
		case 'gray':
			return SpinnerMenuGraySvg;
		default:
			return SpinnerMainSvg;
	}
};

type SpinnerType = 'main' | 'black' | 'gray';

export type SpinnerProps = {
	type?: SpinnerType;
	width?: string;
	style?: CSSProperties;
};

function Spinner({ type = 'main', width = '90px', style }: SpinnerProps) {
	return (
		<div data-testid="spinner-loading" style={style}>
			<img src={getSpinnerType(type)} alt="Loading spinner" width={width} />
		</div>
	);
}

export { Spinner };
