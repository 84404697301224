import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { editMenuSetSelectedToEdit } from 'actions/actionCreators';
import { EDIT_MENU_TYPES } from 'components/begEditMenu/constants/begEditMenuConstants';
import useBrandDetails from 'hooks/useBrandDetails';
import useBrandRoute from 'hooks/useBrandRoute';
import { useCreatePage } from 'hooks/useCreatePage';
import { useEditMenuData } from 'hooks/useEditMenuData';
import { handleAddItem } from 'utils/editNavigationUtil';
import { hasText } from 'utils/stringUtil';
import { translations } from 'translation/en';
import { PAGE_TYPES } from 'constants/pageConstants';
import { EditNavigationTypes } from '../types/editNavigationTypes';
import './InputEditNavigation.scss';

const InputEditNavigation = ({ title, type }) => {
	const initialValue = '';

	const history = useHistory();
	const { selectedToEdit } = useEditMenuData();
	const { getBrandRoute } = useBrandRoute();
	const {
		isLoadingItems,
		chapters,
		currentType,
		setMutableChapters,
		onLoadingItems,
		onFinishedLoadingItems,
		handleSaveChaptersToHistory,
	} = useBrandDetails();

	const { page, createPage } = useCreatePage();
	const [inputValue, setInputValue] = useState(initialValue);
	const dispatch = useDispatch();

	const isEditNavigation = selectedToEdit === EDIT_MENU_TYPES.EDIT_NAVIGATION;
	const isChild = type !== PAGE_TYPES.CHAPTER_TYPE;
	const placeholder = isChild ? `${translations.PLACEHOLDER_CHILD_TEXT} ${title}` : translations.PLACEHOLDER_CHAPTER_TEXT;

	const editNavigationInputClassNames = classNames('EditNavigationInput__container', {
		'EditNavigationInput__container--open': isEditNavigation,
	});

	const onResetInput = () => {
		setInputValue(initialValue);
	};

	const onCloseEditMode = useCallback(() => {
		dispatch(editMenuSetSelectedToEdit({ selectedToEdit: EditNavigationTypes.CLOSE }));
	}, [dispatch]);

	const onFinally = useCallback(() => {
		onResetInput();
		onCloseEditMode();
		onFinishedLoadingItems();
	}, [onFinishedLoadingItems, onCloseEditMode]);

	const onSuccessCreated = useCallback(() => {
		const newChapters = handleAddItem(chapters, currentType, page, type);

		// TODO I think it will be a better approach to use a useReducer
		// to be able to manage the cases and states of the mutableChapters.
		setMutableChapters(newChapters);
		handleSaveChaptersToHistory(newChapters);
		history.replace(getBrandRoute(page.path));
	}, [type, history, page, chapters, currentType, setMutableChapters, handleSaveChaptersToHistory, getBrandRoute]);

	const handleChange = e => {
		e.preventDefault();
		setInputValue(e.target.value);
	};

	const handleSubmit = useCallback(
		async e => {
			e.preventDefault();
			onLoadingItems();

			await createPage({
				type,
				title: inputValue,
			});

			onFinally();
		},
		[type, inputValue, onLoadingItems, createPage, onFinally]
	);

	const handleCancel = e => {
		e.preventDefault();
		onCloseEditMode();
		onResetInput();
	};

	useEffect(() => {
		if (page) {
			onSuccessCreated();
		}
	}, [type, history, chapters, currentType, page, getBrandRoute, onSuccessCreated, setMutableChapters]);

	return (
		<div className={editNavigationInputClassNames}>
			{isChild && !isLoadingItems && (
				<button className="EditNavigationInput__cancel-button" title={translations.CANCEL} onClick={handleCancel}>
					<i className="icon icon-delete-circle" style={{ fontSize: 'large' }} />
				</button>
			)}
			<input
				aria-label="add-input"
				className="EditNavigationInput__input"
				type="text"
				placeholder={placeholder}
				value={inputValue}
				onChange={handleChange}
			/>
			<button
				className="EditNavigationInput__save-button"
				title={translations.SAVE}
				disabled={!hasText(inputValue)}
				onClick={handleSubmit}
			>
				<i className="icon icon-save" style={{ fontSize: 'large' }} />
			</button>
		</div>
	);
};

InputEditNavigation.propTypes = {
	title: PropTypes.string,
	type: PropTypes.oneOf([PAGE_TYPES.CHAPTER_TYPE, PAGE_TYPES.SECTION_TYPE, PAGE_TYPES.ARTICLE_TYPE]),
};

InputEditNavigation.defaultProps = {
	title: '',
	type: PAGE_TYPES.CHAPTER_TYPE,
};

export { InputEditNavigation };
