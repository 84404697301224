/* eslint-disable react/no-array-index-key */
import { Button } from 'components/button';
import { arrayOf, func, string } from 'prop-types';
import { useState } from 'react';
import { translations } from 'translation/en';
import * as S from './WordBox.style';

const WordBox = ({ words, placeholder, buttonName, setWords }) => {
	const [inputValue, setInputValue] = useState('');

	const wordsComponent = (
		<S.WordsContainer>
			{words?.map((word, idx) => (
				<S.Word key={word + idx}>
					{word}{' '}
					<span
						role="button"
						tabIndex="0"
						onClick={() => setWords(oldWords => oldWords.filter((_, index) => idx !== index))}
					>
						<i className="icon-delete-circle" />
					</span>
				</S.Word>
			))}
		</S.WordsContainer>
	);

	return (
		<S.WordBox>
			<S.KeywordsContent>
				<S.Input placeholder={placeholder} value={inputValue} onChange={e => setInputValue(e.target.value)} />

				<Button
					variant="gray-border-only"
					icon="icon-plus"
					onClick={() => {
						if (inputValue) {
							setWords(oldWords => [...oldWords, inputValue]);
							setInputValue('');
						}
					}}
				>
					{buttonName?.toUpperCase()}
				</Button>
			</S.KeywordsContent>

			{wordsComponent}
		</S.WordBox>
	);
};

WordBox.propTypes = {
	words: arrayOf(string),
	placeholder: string,
	buttonName: string,
	setWords: func,
};

WordBox.defaultProps = {
	words: [],
	placeholder: translations.ENTER_YOUR_VALUES,
	buttonName: translations.ADD,
	setWords: () => {},
};

export { WordBox };
