import { useEffect, useState } from 'react';
import { PAGE_TYPES } from 'constants/pageConstants';
import useBrandDetails from './useBrandDetails';
import { useChaptersByBrand } from './useChaptersByBrand';

const useListItemsToApprove = () => {
	const { currentType } = useBrandDetails();
	const { loadingChapters, chapters, fetchChapters } = useChaptersByBrand();
	const [itemsToApprove, setItemsToApprove] = useState([]);

	const mapApprovedSections = section => ({
		id: section.path,
		title: section.title,
		modificationDate: section.modificationDate,
		disabled: section.approved,
		type: PAGE_TYPES.SECTION_TYPE,
		articles: section.articles
			?.filter(article => !article.approved)
			.map(article => ({
				id: article.path,
				title: article.title,
				modificationDate: article.modificationDate,
				type: PAGE_TYPES.ARTICLE_TYPE,
			})),
	});

	const mapApprovedArticles = article => ({
		id: article.path,
		title: article.title,
		modificationDate: article.modificationDate,
		type: PAGE_TYPES.ARTICLE_TYPE,
	});

	useEffect(() => {
		let toBeApproved = [];
		const selectedChapter = chapters?.find(chapter => chapter.path === currentType?.chapterPath);
		const isCurrentPageChapter = currentType?.path === currentType?.chapterPath;
		const isCurrentPageSection = currentType?.path === currentType?.sectionPath && selectedChapter;

		if (currentType?.quicksheetPath || selectedChapter?.quickSheetPath) {
			setItemsToApprove([]);
		} else if (isCurrentPageChapter) {
			toBeApproved = selectedChapter?.sections.map(mapApprovedSections);
		} else if (isCurrentPageSection) {
			const selectedSection = selectedChapter.sections?.find(section => section.path === currentType?.sectionPath);
			toBeApproved = selectedSection?.articles?.filter(article => !article.approved).map(mapApprovedArticles);
		}
		setItemsToApprove(toBeApproved?.filter(item => (item.disabled && item?.articles?.length > 0) || !item.disabled));
	}, [chapters, currentType?.chapterPath, currentType?.path, currentType?.quicksheetPath, currentType?.sectionPath]);

	return {
		itemsToApprove,
		loadingItems: loadingChapters,
		fetchItems: fetchChapters,
	};
};

export default useListItemsToApprove;
