import PropTypes from 'prop-types';
import { Button } from 'components/button';
import useToggle from 'hooks/useToggle';
import { translations } from 'translation/en';
import { AddModulesDropDown } from './components/addModuleDropDown';
import { AddModuleContainer, ModuleLine } from './AddModules.style';

const AddModules = ({ open, position }) => {
	const [isCollapsed, setIsCollapsed] = useToggle();

	const handleOpenAddModulesDropDown = e => {
		e.preventDefault();
		setIsCollapsed();
	};

	const handleCloseAddModulesDropDown = () => {
		setIsCollapsed();
	};

	return (
		<AddModuleContainer open={open}>
			<ModuleLine />
			<Button variant="gray-border" onClick={handleOpenAddModulesDropDown}>
				{translations.ADD_MODULE}
			</Button>
			<AddModulesDropDown open={isCollapsed} position={position} onClose={handleCloseAddModulesDropDown} />
		</AddModuleContainer>
	);
};

AddModules.propTypes = {
	open: PropTypes.bool,
	position: PropTypes.number,
};

AddModules.defaultProps = {
	open: false,
	position: 0,
};

export { AddModules };
