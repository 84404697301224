/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useBrandData from 'hooks/useBrandData';
import useBrandRoute from 'hooks/useBrandRoute';
import { useChaptersByBrand } from 'hooks/useChaptersByBrand';
import { useChapterByPath } from 'hooks/useChapterByPath';
import { useSectionByPath } from 'hooks/useSectionByPath';
import { useArticleByPath } from 'hooks/useArticleByPath';
import { useUndo } from 'hooks/useUndo';
import { fetchFirstItemWithContent, fetchItemByPath, findItemByPath, generateCrumbs } from 'utils/brandsUtil';
import { MODELS_PATHS } from 'constants/aemConstants';
import { isPreview } from 'utils/envUtil';
import { checkPageExistenceByResult } from 'services/checkPageExistence';
import { useCheckPageExistence } from 'hooks/useCheckPageExistence';
import { useEditMenuData } from 'hooks/useEditMenuData';
import { EDIT_MENU_TYPES } from 'components/begEditMenu/constants/begEditMenuConstants';
import { useQueryParams } from 'hooks/useQueryParams';
import { fromSectionService } from 'services/sectionService';
import { fromChapterService } from 'services/chapterService';
import { fromArticleService } from 'services/articleService';
import { useAuthData } from 'hooks/useAuthData';
import { useContentPath } from 'hooks/useContentPath';
import { logError } from 'utils/logUtil';
import { showToast } from 'utils/toastUtil';
import { translations } from 'translation/en';
import { PAGE_TYPES } from 'constants/pageConstants';
import { QuickSheetProvider } from './QuickSheetContext';
import { ApprovePublishProvider } from './ApprovePublishContext';

export const BrandDetailsContext = createContext({});

const BrandDetailsProvider = ({ children }) => {
	const history = useHistory();
	const { pathname, hash } = useLocation();
	const { brandId, brandName } = useBrandData();
	const { contentPath } = useContentPath();
	const { user } = useAuthData();
	const { selectedToEdit } = useEditMenuData();
	const { searchParams } = useQueryParams();

	const { getBrandRoute, resetRoute } = useBrandRoute();
	const { checkPageExistence } = useCheckPageExistence();

	const previousBrand = useRef(brandName);

	const { loadingChapters, chapters, fetchChapters, resetChapters } = useChaptersByBrand();
	const { loadingChapter, chapter, chapterParagraphs, fetchChapter, clearChapter } = useChapterByPath();
	const { loadingSection, section, sectionParagraphs, fetchSection, clearSection } = useSectionByPath();
	const { loadingArticle, article, articleParagraphs, fetchArticle, clearArticle } = useArticleByPath();

	const [selectedBrands, setSelectedBrands] = useState([]);
	const [isLoading, setIsLoading] = useState();
	const [isPageContentLoading, setIsPageContentLoading] = useState(false);
	const [currentType, setCurrentType] = useState();
	const [showNoPermissionMessage, setShowNoPermissionMessage] = useState();

	const [crumbs, setCrumbs] = useState([]);
	const [mutableChapters, setMutableChapters] = useState(chapters);
	const [value, undo, saveToHistory] = useUndo(chapters);

	const isModeNavigation = selectedToEdit === EDIT_MENU_TYPES.EDIT_NAVIGATION;

	const isLoadingChapters = useMemo(() => [loadingChapters].some(Boolean), [loadingChapters]);
	const isLoadingItems = useMemo(() => [isLoading].some(Boolean), [isLoading]);

	const getModelPath = useCallback(
		type => {
			switch (type || currentType?.type) {
				case PAGE_TYPES.CHAPTER_TYPE:
					return MODELS_PATHS[PAGE_TYPES.CHAPTER_TYPE.toUpperCase()];
				case PAGE_TYPES.SECTION_TYPE:
					return MODELS_PATHS[PAGE_TYPES.SECTION_TYPE.toUpperCase()];
				case PAGE_TYPES.ARTICLE_TYPE:
					return MODELS_PATHS[PAGE_TYPES.ARTICLE_TYPE.toUpperCase()];
				default:
					return '';
			}
		},
		[currentType?.type]
	);

	const clearCurrentPage = useCallback(() => {
		switch (currentType?.type) {
			case PAGE_TYPES.CHAPTER_TYPE:
				return clearChapter();
			case PAGE_TYPES.SECTION_TYPE:
				return clearSection();
			case PAGE_TYPES.ARTICLE_TYPE:
				return clearArticle();
			default:
				return '';
		}
	}, [currentType?.type]);

	const fetchServiceByType = useCallback(
		async ({ type, path }) => {
			if (!path) {
				return null;
			}

			let response = null;
			let payload = null;

			switch (type) {
				case PAGE_TYPES.CHAPTER_TYPE:
					if (!loadingChapter) {
						clearChapter();
						response = await fetchChapter({ path });
						payload = response?.data?.data?.getChapterByPath?.items?.map(fromChapterService)?.shift();
						setIsLoading(false);
					}
					break;
				case PAGE_TYPES.SECTION_TYPE:
					if (!loadingSection) {
						clearSection();
						response = await fetchSection({ path });
						payload = response?.data?.data?.getSectionByPath?.items?.map(fromSectionService)?.shift();
						setIsLoading(false);
					}
					break;
				case PAGE_TYPES.ARTICLE_TYPE:
					if (!loadingArticle) {
						clearArticle();
						response = await fetchArticle({ path });
						payload = response?.data?.data?.getArticleByPath?.items?.map(fromArticleService)?.shift();
						setIsLoading(false);
					}
					break;
				default:
					break;
			}

			if (payload?.paragraphs?.length) {
				return payload?.paragraphs;
			}

			return null;
		},
		[fetchChapter, fetchSection, fetchArticle]
	);

	const onStart = useCallback(() => {
		if (brandName) {
			fetchChapters();
		}
	}, [brandName, fetchChapters]);

	const onLoad = useCallback(async () => {
		if (chapters && !contentPath) {
			setIsPageContentLoading(true);

			const path = await fetchFirstItemWithContent(chapters);
			const searchParamsString = searchParams || '';
			const hashString = hash || '';

			if (path) {
				history.replace(getBrandRoute(`${path}${searchParamsString}${hashString}`));
			}

			setIsPageContentLoading(false);
		}
	}, [pathname, brandName, chapters, history, hash, getBrandRoute]);

	const onReady = useCallback(async () => {
		setIsPageContentLoading(true);

		if (pathname && mutableChapters && contentPath && currentType?.path !== contentPath) {
			const item =
				isPreview && selectedToEdit === EDIT_MENU_TYPES.EDIT_NAVIGATION
					? findItemByPath(mutableChapters, pathname)
					: await fetchItemByPath(
							mutableChapters,
							brandId,
							user,
							pathname,
							PAGE_TYPES.CHAPTER_TYPE,
							null,
							null,
							fetchServiceByType
					  );

			if (isModeNavigation) {
				fetchServiceByType({ type: item.type, path: contentPath });
			}

			if (item) {
				setCurrentType(item);
				setCrumbs(generateCrumbs(item));

				const searchParamsString = searchParams || '';
				const hashString = hash || '';

				if (item.path) {
					history.replace(getBrandRoute(`${item.path}${searchParamsString}${hashString}`));
				}

				setShowNoPermissionMessage(false);
			} else if (contentPath) {
				const quicksheetPath = '/content/dam/quicksheets/ops-standards/brand_standards';
				const isQuicksheet = contentPath.includes(quicksheetPath);
				const pathToCheckExistence = isQuicksheet ? quicksheetPath : contentPath;

				try {
					const pageExistenceResult = await checkPageExistence(pathToCheckExistence);
					const pageExist = checkPageExistenceByResult(pageExistenceResult);
					setShowNoPermissionMessage(pageExist);
				} catch (e) {
					logError(e);
					showToast({
						message: translations.INVALID_PATH,
						type: 'error',
					});
				}
			}
		}

		setIsPageContentLoading(false);
		// TODO The selectedToEdit left the dependencies array due to a bug in the
		// creation of the sidebar items. Redo and rethink another strategy for this.
	}, [pathname, mutableChapters, hash, currentType?.path, fetchServiceByType]);

	const onReset = useCallback(() => {
		if (pathname) {
			setCurrentType();
		}
	}, [pathname]);

	const onResetChapters = useCallback(() => {
		resetChapters();
		setMutableChapters(null);

		previousBrand.current = brandName;
	}, [brandName, resetChapters]);

	const onResetAll = useCallback(() => {
		setMutableChapters(null);
		resetChapters();
		fetchChapters();
		resetRoute();

		previousBrand.current = brandName;
	}, [brandName, fetchChapters, resetRoute, resetChapters]);

	const onChangeBrand = useCallback(() => {
		if (brandName !== previousBrand.current) {
			onResetAll();
		}
	}, [brandName, onResetAll]);

	const onLoadingItems = useCallback(() => {
		setIsLoading(true);
	}, [setIsLoading]);

	const onFinishedLoadingItems = useCallback(() => {
		setIsLoading(false);
	}, [setIsLoading]);

	const handleSaveChaptersToHistory = newValue => {
		saveToHistory(newValue);
	};

	const onUndoChapters = () => {
		undo();
		setMutableChapters(value);
	};

	const handleSelectedBrand = selectedBrand => e => {
		e.stopPropagation();

		if (e.target.checked) {
			setSelectedBrands([...selectedBrands, selectedBrand]);
		} else {
			setSelectedBrands(selectedBrands.filter(brand => brand !== selectedBrand));
		}
	};

	const onClearSelectedBrands = () => {
		setSelectedBrands([]);
	};

	useEffect(() => {
		onStart();
	}, [onStart]);

	useEffect(() => {
		onLoad();
	}, [onLoad]);

	useEffect(() => {
		const item = findItemByPath(mutableChapters, pathname);
		setCurrentType(item);

		if (!isPageContentLoading) {
			onReady();
		}
	}, [onReady, onReset]);

	useEffect(() => {
		onChangeBrand();
	}, [onChangeBrand]);

	useEffect(() => {
		if (chapters) {
			setMutableChapters(chapters);
		}
	}, [chapters]);

	const valueProvider = useMemo(
		() => ({
			loadingChapters,
			chapters: mutableChapters,
			crumbs,
			currentType,
			selectedBrands,

			chapter,
			section,
			article,

			chapterParagraphs,
			sectionParagraphs,
			articleParagraphs,

			isPageContentLoading,
			isLoadingChapters,
			isLoadingItems,
			contentPath,
			showNoPermissionMessage,

			fetchChapters,
			fetchCurrentContent: onReady,
			setMutableChapters,

			onLoadingItems,
			onFinishedLoadingItems,
			onResetChapters,
			getModelPath,

			handleSaveChaptersToHistory,
			onUndoChapters,
			clearCurrentPage,
			fetchServiceByType,
			handleSelectedBrand,
			onClearSelectedBrands,
		}),
		[
			isPageContentLoading,
			isLoadingChapters,
			isLoadingItems,
			loadingChapters,
			mutableChapters,
			selectedBrands,

			chapterParagraphs,
			sectionParagraphs,
			articleParagraphs,

			crumbs,
			currentType,
			chapter,
			section,
			article,
			contentPath,
			showNoPermissionMessage,

			fetchChapters,
			onReady,
			onLoadingItems,
			onFinishedLoadingItems,
			onResetChapters,
			getModelPath,

			handleSaveChaptersToHistory,
			onUndoChapters,
			clearCurrentPage,
			fetchServiceByType,
			handleSelectedBrand,
			onClearSelectedBrands,
		]
	);

	return (
		<BrandDetailsContext.Provider value={valueProvider}>
			<ApprovePublishProvider value={valueProvider}>
				<QuickSheetProvider value={valueProvider}>{children}</QuickSheetProvider>
			</ApprovePublishProvider>
		</BrandDetailsContext.Provider>
	);
};

BrandDetailsProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export { BrandDetailsProvider };
