export const ROLE_UPLOAD_FILE_BOX = 'upload-file-box';
export const TEST_TITLE_INPUT_ID = 'title-input-id';
export const TEST_CAPTION_TEXTAREA_ID = 'caption-textarea-id';
export const TEST_LINK_INPUT_ID = 'link-input-id';

export const OVERLAY_JUSTIFICATION = {
	LEFT: { value: 'left' },
	RIGHT: { value: 'right' },
};

export const CAPTION_JUSTIFICATION = {
	LEFT: { value: 'left' },
	CENTER: { value: 'center' },
	RIGHT: { value: 'right' },
};

export const OVERLAY_PROP = 'textOverlay';
export const OVERLAY_POSITION_PROP = 'textOverlayPosition';
export const CAPTION_PROP = 'imageCaption';
export const CAPTION_POSITION_PROP = 'captionPosition';
export const LINK_TO_PROP = 'linkTo';

export const IMAGE_BOX_TYPES = {
	EDIT_IMAGE_MODAL: 'edit-image-modal',
	REMOVE_IMAGE_MODAL: 'remove-image-modal',
	CLOSE_MODAL: 'close-image-modal',
	RESET: '',
};
