import Tippy from '@tippyjs/react';
import { arrayOf, bool, node, oneOfType, string } from 'prop-types';
import React from 'react';
import 'tippy.js/dist/tippy.css';

const Tooltip = ({ content, children, shouldHide, ...props }) => {
	if (shouldHide) return children;

	return (
		<Tippy content={content} {...props}>
			{children}
		</Tippy>
	);
};

Tooltip.propTypes = {
	content: oneOfType([arrayOf(node), node, string]).isRequired,
	children: oneOfType([arrayOf(node), node]).isRequired,
	shouldHide: bool,
};

Tooltip.defaultProps = {
	shouldHide: false,
};

export default Tooltip;
