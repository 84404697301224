import { PARAGRAPH_PROPS } from 'constants/paragraphConstants';
import { deleteItemFromArr, getLastElement, insertItemInArr, updateItemFromArr } from 'utils/arrayUtil';
import { getName, moveArrItemDown, moveArrItemUp } from 'utils/contentFragmentUtil';
import { isEmptyString } from 'utils/stringUtil';

export const hasParagraph = content => {
	if (!content.paragraphs) {
		return false;
	}

	if (content.paragraphs.length === 0) {
		return false;
	}

	return true;
};

export const hasContentOnSection = section => {
	if (hasParagraph(section) || section?.articles?.some(article => hasParagraph(article))) {
		return true;
	}

	return false;
};

export const isFirstParagraph = (arr, element) => arr && Array.isArray(arr) && arr.indexOf(element) === 0;

export const isLastParagraph = (arr, element) => arr && Array.isArray(arr) && arr.indexOf(element) === arr.length - 1;

export const insertParagraph = (paragraphs, paragraph, position) => {
	const newParagraphs = insertItemInArr({
		arr: paragraphs,
		payload: paragraph,
		index: position,
	});
	return newParagraphs.map(item => item);
};

export const deleteParagraph = (paragraphs, paragraph) => {
	const filteredParagraphs = deleteItemFromArr({
		arr: paragraphs,
		payload: paragraph,
		itemKey: PARAGRAPH_PROPS.VCM_ID_KEY,
	});
	return filteredParagraphs.map(item => item);
};

export const updateParagraph = (paragraphs, paragraph) => {
	const updatedParagraphs = updateItemFromArr({
		arr: paragraphs,
		payload: paragraph,
		itemKey: PARAGRAPH_PROPS.VCM_ID_KEY,
	});
	return updatedParagraphs.map(item => item);
};

export const moveParagraphUp = (paragraphs, paragraph) => {
	return moveArrItemUp({
		arr: paragraphs,
		itemToMoveUp: paragraph,
		itemKey: PARAGRAPH_PROPS.VCM_ID_KEY,
		sortKey: PARAGRAPH_PROPS.DISPLAY_ORDER_KEY,
	});
};

export const moveParagraphDown = (paragraphs, paragraph) => {
	return moveArrItemDown({
		arr: paragraphs,
		itemToMoveDown: paragraph,
		itemKey: PARAGRAPH_PROPS.VCM_ID_KEY,
		sortKey: PARAGRAPH_PROPS.DISPLAY_ORDER_KEY,
	});
};

export const generateParagraphName = ({ type, displayOrder, chapterTitle, sectionTitle, articleTitle }) => {
	const chapterName = chapterTitle || '';
	const sectionName = sectionTitle || '';
	const articleName = articleTitle || '';

	return `${chapterName}_${sectionName}_${articleName}_${type}_${displayOrder}`.replace(/ /g, '_');
};

export const mappedParagraphs = data => {
	return {
		path: data._path,
		body: data.BODY,
		collapsible: data.COLLAPSIBLE,
		disclaimer: data.DISCLAIMER,
		displayOrder: data.DISPLAY_ORDER,
		specification: data.SPECIFICATION,
		title: data.TITLE,
		type: data.TYPE,
		objectId: data.OBJECTID,
		relatedTerms: data.RELATED_TERMS,
		vcmId: data.VCMID,
		files: data?.RELATOR_FILE_BEG_ID?.sort((item1, item2) => +item1.DispOrder - +item2.DispOrder).map(item => {
			return {
				vcmId: item?.VCMID,
				path: item?._path,
				creator: item?.CREATOR,
				linkText: item?.FILE_LINK_TEXT,
				displayOrder: item?.DispOrder,
				filePath: item?.file?.length && getLastElement(item?.file),
				file: {
					name: item?.file?.length && getName(getLastElement(item?.file)),
				},
			};
		}),
		imageData: data?.RELATOR_IMAGE_ID?.sort(
			(item1, item2) => +item1.BEG_ARTICLE_CONTENT_IMAGE_DISPLAY_ORDER - +item2.BEG_ARTICLE_CONTENT_IMAGE_DISPLAY_ORDER
		).map(imgItem => {
			return {
				captionPosition: imgItem?.BEG_ARTICLE_CONTENT_IMAGE_CAPTION_POSITION,
				displayOrder: imgItem?.BEG_ARTICLE_CONTENT_IMAGE_DISPLAY_ORDER,
				imageContentPath: imgItem?.BEG_ARTICLE_CONTENT_IMAGE_IMAGE_CONTENT,
				imageMobile: imgItem?.BEG_ARTICLE_CONTENT_IMAGE_IMAGE_MOBILE,
				imageRetina: imgItem?.BEG_ARTICLE_CONTENT_IMAGE_IMAGE_RETINA,
				imageLink: imgItem?.BEG_ARTICLE_CONTENT_IMAGE_LINK_IMAGE,
				textOverlayPosition: imgItem?.BEG_ARTICLE_CONTENT_IMAGE_TEXT_OVERLAY_POSITION,
				imageObjectId: imgItem?.OBJECTID,
				imageVcmId: imgItem?.VCMID,
				imagePath: imgItem?._path,
				imageCaption: isEmptyString(imgItem?.BEG_ARTICLE_CONTENT_IMAGE_CAPTION),
				textOverlay: isEmptyString(imgItem?.BEG_ARTICLE_CONTENT_IMAGE_TEXT_OVERLAY),
				imageTitle: isEmptyString(imgItem?.BEG_ARTICLE_CONTENT_IMAGE_TITLE),
				linkTo: isEmptyString(imgItem?.BEG_ARTICLE_CONTENT_IMAGE_LINK_TO),
			};
		}),
	};
};
