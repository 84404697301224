import { useEffect, useMemo } from 'react';
import { fromParagraphsByChapterService, getParagraphsByChapters } from 'services/paragraphsByChapterService';
import { translations } from 'translation/en';
import { showToast } from 'utils/toastUtil';
import { useRequest } from './useRequest';

const useParagraphsChapter = () => {
	const {
		loading: loadingParagraphs,
		data: response,
		error,
		run: fetchParagraphsByChapter,
		clear: resetParagraphs,
	} = useRequest(params => getParagraphsByChapters({ ...params }));

	useEffect(() => {
		if (error) {
			showToast({ type: 'error', message: translations.FAILED_FETCH_ARTICLE });
		}
	}, [error]);

	const paragraphs = useMemo(() => {
		return response?.data?.data?.getParagraphsByChapter?.items?.map(fromParagraphsByChapterService);
	}, [response]);

	return { loadingParagraphs, paragraphs, fetchParagraphsByChapter, resetParagraphs };
};

export { useParagraphsChapter };
