import { NavLink as RouterLink } from 'react-router-dom';
import styled from 'styled-components/macro';

const getMD = () => '@media(max-width: 880px)';

export const Label = styled.span(({ theme: { color, typo } }) => ({
	...typo.link['05'],
	color: `${color.link.tertiary.default.label} !important`,
}));

export const Link = styled(RouterLink)(({ theme: { color }, disabled }) => ({
	display: 'inline-flex',
	alignItems: 'center',
	width: '100%',
	minHeight: '45px',
	background: `none`,
	textDecoration: 'none !important',
	pointerEvents: disabled ? 'none' : 'auto',
	opacity: disabled ? '0.5' : '1 !important',

	'*': {
		color: `${color.link.tertiary.default.label} !important`,
		lineHeight: '21px',

		[getMD()]: {
			'*': {
				color: `${color.link.tertiary.default?.mobile?.label} !important`,
			},
		},
	},

	'&:hover': {
		background: `${color.link.tertiary.hover.background} !important`,

		'*': {
			color: `${color.link.tertiary.hover.label} !important`,
		},

		[getMD()]: {
			'*': {
				color: `${color.link.tertiary.hover?.mobile?.label} !important`,
			},
		},
	},

	'&:active, &.active, &:visited': {
		background: color.interactive.lightest,

		'*': {
			color: `${color.link.tertiary.active.label}`,

			[getMD()]: {
				'*': {
					color: `${color.link.tertiary.active?.mobile?.label} !important`,
				},
			},
		},
	},
}));

export const Wrapper = styled.div(() => ({
	display: 'inline-flex',
	alignItems: 'center',
	width: '100%',
	height: 'inherit',
	padding: '10px 60px',
	justifyContent: 'flex-start',
}));
