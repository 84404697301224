import styled from 'styled-components/macro';

export const SaveBarOptions = styled.div({
	display: 'flex',
	justifyContent: 'space-between',

	width: '100%',
	padding: '12px',

	background: '#000',
	borderRadius: '4px',
});

export const BarContainer = styled.div({
	display: 'flex',
	gap: '10px',
});

export const ButtonAction = styled.button(({ theme: { color } }) => ({
	padding: '4px 30px',
	textTransform: 'uppercase',
	background: color.interactive.primary.default.background,
	color: 'white',

	borderRadius: '2px',
	border: `1px solid ${color.interactive.primary.default.background}`,

	transition: 'all .2s ease',

	'&:hover': {
		opacity: '0.8',
	},
}));

export const ButtonOnClose = styled(ButtonAction)`
	border: 1px solid #fff;
	background: #000;
`;
