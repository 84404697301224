export const PUBLISHED_STATUS = 'Activate';

export const getCurrentRelatorPathFromCurrentTypeToUnpublish = currentType => {
	if (currentType?.quickSheetPath) {
		return [currentType?.chapterPath];
	}

	switch (currentType?.path) {
		case currentType?.sectionPath:
			return currentType?.sectionRelatorPath;
		case currentType?.articlePath:
			return currentType?.articleRelatorPath;
		default:
			return currentType?.chapterPath;
	}
};

export const getCurrentRelatorPathFromCurrentTypeToPublish = currentType => {
	if (currentType?.quickSheetPath) {
		return [currentType?.chapterPath];
	}

	switch (currentType?.path) {
		case currentType?.sectionPath:
			return currentType?.sectionRelatorPath;
		case currentType?.articlePath:
			return currentType?.articleRelatorPath;
		default:
			return currentType?.chapterPath;
	}
};

export const extractModelFromPath = (path = '') => {
	const regex = /models\/([^,]+)/;
	const match = path.match(regex);

	if (match && match[1]) {
		return match[1];
	}

	return '';
};

export const extractContentFromPath = (path = '') => {
	const regex = /\/content([^}]+)/;
	const match = path.match(regex);

	if (match && match[1]) {
		return `/content${match[1]}`;
	}

	return '';
};

export const getPublishStatus = status => status && status.toUpperCase() === PUBLISHED_STATUS.toUpperCase();
