import PlaceHolderSrc242x242 from 'assets/img/placeholders/242x242.jpg';
import { EDIT_MENU_TYPES } from 'components/begEditMenu/constants/begEditMenuConstants';
import { ImageBox } from 'components/imageBox';
import {
	EditableContentWrapper,
	EditableTitleWrapper,
} from 'containers/brandDetails/components/brandMain/components/editableContent';
import { SpecificationDropDown } from 'containers/brandDetails/components/brandMain/components/specificationDropDown';
import { Wrapper } from 'containers/brandDetails/components/brandMain/components/template/ModuleTemplate.style';
import {
	DESCRIPTION_FIELD,
	EDITABLE_DESCRIPTION_TEST_ID,
	EDITABLE_DROPDOWN_SPECIFICATION_TEST_ID,
	EDITABLE_SPECIFICATION_TEST_ID,
	EDITABLE_TITLE_TEST_ID,
	GRID_IMAGE_TEST_ID,
	SPECIFICATION_FIELD,
	TITLE_FIELD,
	TYPE_MODULE_FULL_WIDTH_THREE_COLUMN_IMAGE,
} from 'containers/brandDetails/constants/modulesTypesConstants';
import { useEditMenuData } from 'hooks/useEditMenuData';
import useClearCache from 'hooks/useClearCache';
import { arrayOf, bool, shape, string } from 'prop-types';
import { useState, useMemo, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { translations } from 'translation/en';
import { Collapse } from 'containers/brandDetails/components/brandMain/components/collapse';
import { CAPTION_JUSTIFICATION, OVERLAY_JUSTIFICATION } from 'components/imageBox/constants/imageBoxConstants';
import { ListFiles } from '../../listFiles';
import { DisclaimerEditInLine } from '../disclaimerEditInLine';
import { SpecificationLabel } from '../../specificationDropDown/SpecificationDropDown.style';
import * as S from './FULL-M.style';

const FullM = ({
	startTitle,
	startDescription,
	startIsDropdown,
	startSpecification,
	startDisclaimer,
	path,
	imageData,
	keywords,
	isOnPrintPage,
	...props
}) => {
	const initialImage = useMemo(
		() => ({
			textOverlay: '',
			imageCaption: '',
			linkTo: '',
			file: PlaceHolderSrc242x242,
			textOverlayPosition: OVERLAY_JUSTIFICATION.LEFT.value,
			captionPosition: CAPTION_JUSTIFICATION.LEFT.value,
			relatedPath: path,
		}),
		[path]
	);

	const { color, typo } = useTheme();
	const { selectedToEdit } = useEditMenuData();
	const { clearPageCache } = useClearCache();

	const [title, setTitle] = useState(startTitle || '');
	const [description, setDescription] = useState(startDescription || '');
	const [specification, setSpecification] = useState(startSpecification || '');
	const [disclaimer, setDisclaimer] = useState(startDisclaimer);
	const [isDropdown, setIsDropDown] = useState(startIsDropdown || false);
	const [startKeywords, setStartKeywords] = useState(keywords || []);

	const defaultImages = useMemo(
		() =>
			[...Array(6).keys()].map(i => {
				const initialData = { ...initialImage, displayOrder: i + 1 };
				const finderImage = imageData?.find(img => +img.displayOrder === +initialData.displayOrder);

				const image = {
					...initialData,
					...finderImage,
					textOverlayPosition: finderImage?.textOverlayPosition || initialData.textOverlayPosition,
					captionPosition: finderImage?.captionPosition || initialData.captionPosition,
				};

				return image;
			}),
		[imageData, initialImage]
	);

	const [imageGallery, setImageGallery] = useState(defaultImages);

	useEffect(() => {
		setImageGallery(defaultImages);
	}, [setImageGallery, defaultImages]);

	const handleSaveImage = payload => {
		clearPageCache();
		setImageGallery(prevArray => {
			const newArray = [
				...prevArray.slice(0, +payload.displayOrder - 1),
				payload,
				...prevArray.slice(+payload.displayOrder),
			];
			return newArray;
		});
	};

	const handleDeleteImage = payload => {
		clearPageCache();
		setImageGallery(prevArray => {
			return prevArray.map(item => {
				if (+item.displayOrder === +payload.displayOrder) {
					return { ...initialImage, displayOrder: payload.displayOrder };
				}

				return item;
			});
		});
	};

	const renderTitleComponent = () => (
		<Wrapper>
			<EditableTitleWrapper
				fieldName={TITLE_FIELD}
				placeholder={translations.INSERT_TITLE_HERE}
				content={title}
				setContent={setTitle}
				startKeywords={startKeywords}
				setStartKeywords={setStartKeywords}
				modulePath={path}
				isCollapsable={isDropdown}
				setIsCollapsable={setIsDropDown}
				data-testid={EDITABLE_TITLE_TEST_ID}
				{...props}
			/>
		</Wrapper>
	);

	const renderSpecificationComponent = () =>
		isOnPrintPage ? (
			<S.SpecificationOnPrintPage>
				<SpecificationLabel>{translations.SPECIFICATIONS_TEXT}</SpecificationLabel>
				<EditableContentWrapper
					style={{ margin: '10px' }}
					fieldName={SPECIFICATION_FIELD}
					placeholder={translations.INSERT_SPECIFICATION_HERE}
					content={specification}
					setContent={setSpecification}
					modulePath={path}
					data-testid={EDITABLE_SPECIFICATION_TEST_ID}
					{...props}
				/>
			</S.SpecificationOnPrintPage>
		) : (
			<SpecificationDropDown
				content={specification}
				setContent={setSpecification}
				modulePath={path}
				data-testid={EDITABLE_DROPDOWN_SPECIFICATION_TEST_ID}
				{...props}
			/>
		);

	const renderContentComponent = () => {
		return (
			<>
				<div>
					<EditableContentWrapper
						style={{ marginBottom: '15px', height: 'fit-content' }}
						data-testid={EDITABLE_DESCRIPTION_TEST_ID}
						content={description}
						setContent={setDescription}
						placeholder={translations.INSERT_DESCRIPTION_HERE}
						fieldName={DESCRIPTION_FIELD}
						modulePath={path}
						{...props}
					/>

					{(selectedToEdit === EDIT_MENU_TYPES.EDIT_INLINE || specification) && renderSpecificationComponent()}
				</div>

				<S.Gallery id={`${path}-gallery`} data-testid={GRID_IMAGE_TEST_ID}>
					{imageGallery.map(item => (
						<ImageBox
							key={`${item.displayOrder}${item.imageVcmId}`}
							imageProps={item}
							imageStyle={{
								width: '100%',
								maxWidth: '500px',
								objectFit: 'fill',
							}}
							onSave={handleSaveImage}
							onDelete={handleDeleteImage}
							isOnPrintPage={isOnPrintPage}
							{...props}
						/>
					))}
				</S.Gallery>
				<ListFiles {...props} />
				{disclaimer && (
					<DisclaimerEditInLine disclaimer={disclaimer} setDisclaimer={setDisclaimer} modulePath={path} {...props} />
				)}
			</>
		);
	};

	const renderTitleWithContentComponent = () => (
		<>
			{renderTitleComponent()}
			{renderContentComponent()}
		</>
	);

	const renderDropDownContentComponent = () => (
		<Collapse
			label={renderTitleComponent()}
			icon="icon-chevron-down"
			wrapperStyle={{
				padding: 0,
				color: color.text.secondary02,
				display: 'flex',
				alignItems: 'baseline',
				justifyContent: 'space-between',
				width: '100%',
				...typo.headline['02'],
			}}
		>
			{renderContentComponent()}
		</Collapse>
	);
	return (
		<div role={TYPE_MODULE_FULL_WIDTH_THREE_COLUMN_IMAGE}>
			{isDropdown ? renderDropDownContentComponent() : renderTitleWithContentComponent()}
		</div>
	);
};

export { FullM };

FullM.propTypes = {
	type: string,
	startTitle: string,
	startDescription: string,
	startSpecification: string,
	startDisclaimer: string,
	keywords: arrayOf(string),
	startIsDropdown: bool,
	path: string.isRequired,
	paragraphId: string.isRequired,
	isOnPrintPage: bool,
	imageData: arrayOf(
		shape({
			imagePath: string,
			textOverlay: string,
			textOverlayPosition: string,
			imageTitle: string,
		})
	),
};

FullM.defaultProps = {
	type: '',
	startTitle: '',
	startDescription: '',
	keywords: [],
	startSpecification: '',
	startDisclaimer: '',
	startIsDropdown: false,
	isOnPrintPage: false,
	imageData: null,
};
