import { useCallback } from 'react';
import { translations } from 'translation/en';
import { showToast } from 'utils/toastUtil';
import { useDeleteContentFragment } from './useDeleteContentFragment';
import { useDisapprovePage } from './useDisapprovePage';

export const useDeleteFile = () => {
	const {
		loading: loadingDeleteFile,
		response,
		error,
		removeContentFragment,
	} = useDeleteContentFragment({
		successMessage: translations.FILE_DELETE_SUCCESS,
		deleteMessage: translations.FAILED_DELETE_FILE,
		handleError: true,
	});
	const { isLoadingDisapprovePage, disapprovePage } = useDisapprovePage();

	const removeFile = useCallback(
		({ type, path, pagePath, contentPath, onSuccess, onFinished }) => {
			const payload = {
				contentPath,
			};

			const removeFilePromise = removeContentFragment({
				pagePath,
				cfType: type,
				data: payload,
			});

			const disapprovePagePromise = disapprovePage([{ path, type }]);

			return Promise.all([removeFilePromise, disapprovePagePromise])
				.then(([res]) => {
					if (res?.error) {
						showToast({
							type: 'error',
							message: translations.FAILED_DELETE_FILE,
						});
					} else {
						onSuccess();
					}
				})
				.finally(() => {
					onFinished();
				});
		},
		[disapprovePage, removeContentFragment]
	);

	return { loading: [loadingDeleteFile, isLoadingDisapprovePage].some(Boolean), response, error, removeFile };
};
