import { ModalBox } from '@hyatt/modal-box';
import styled from 'styled-components/macro';

export const Modal = styled(ModalBox)(({ theme: { breakpoint } }) => ({
	position: 'fixed',
	margin: '10px auto',
	top: 0,
	left: 0,
	right: 0,

	borderRadius: '5px',
	maxWidth: '500px',
	minHeight: 'fit-content',

	[breakpoint.lg()]: {
		margin: '30px auto',
	},
}));

export const Container = styled.div(({ theme: { color } }) => ({
	position: 'relative',
	background: color.neutral.white,
	borderTop: `10px solid ${color.border.tertiary}`,
	boxShadow: `0 3px 9px ${color.shadow.primary}`,
}));

export const Header = styled.header({
	margin: 0,
	padding: '2.4rem 2.4rem 0',
});

export const Divider = styled.div(({ theme: { color } }) => ({
	borderBottom: `1px solid ${color.neutral['40']}`,
}));

export const CloseIcon = styled.i(({ theme: { typo } }) => ({
	border: 'none',
	float: 'right',
	color: 'black',
	filter: 'alpha(opacity=20)',
	opacity: 0.2,
	cursor: 'pointer',
	background: 'transparent',
	...typo.button.icon['2xl'],

	'&:hover': {
		opacity: 0.5,
		filter: 'alpha(opacity=50)',
	},
}));

export const CrossCloseIcon = styled.i(() => ({
	marginTop: '5px',
	float: 'right',
	fontSize: '25px',
	border: '1px solid #181818',
	borderRadius: '50%',
	opacity: 0.9,
	cursor: 'pointer',
	background: 'transparent',
	width: '30px',
	height: '30px',

	span: {
		paddingRight: '2px',
	},

	'&:hover': {
		opacity: 0.5,
		filter: 'alpha(opacity=50)',
	},
}));

export const Heading1 = styled.h1(({ theme: { color, typo } }) => ({
	marginBlockStart: '0.67em',
	marginBlockEnd: '0.67em',

	color: color.text.secondary,
	...typo.headline['07'],
}));

export const Heading2 = styled.h1(({ theme: { color, typo } }) => ({
	marginBlockStart: '0.67em',
	marginBlockEnd: '0.67em',

	color: color.text.tertiary03,
	...typo.headline['09'],
}));

export const Heading4 = styled.h4(({ theme: { color, typo } }) => ({
	margin: 0,
	color: color.text.secondary03,
	...typo.headline['10'],
}));

export const CloseLabel = styled.span(({ theme: { typo } }) => ({
	background: 'transparent',
	...typo.button.icon['2xl'],
}));

export const Main = styled.main({
	position: 'relative',
	margin: 0,
	padding: '0 2.4rem',
	maxHeight: '400px',
	overflowY: 'auto',

	'::-webkit-scrollbar': {
		width: '5px',
		height: '5px',
		borderRadius: '10px',
		background: '#f0f0f0',
	},
	'::-webkit-scrollbar-thumb': {
		borderRadius: '20px',
		background: '#b4b4b4',
	},
	'::-webkit-scrollbar-track': {
		background: 'rgba(0, 0, 0, 0.1)',
	},
});

export const Footer = styled.footer(({ theme: { color, breakpoint } }) => ({
	margin: 0,
	padding: '2.4rem',
	textAlign: 'center',

	background: color.neutral['40'],

	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexDirection: 'column',
	gap: '0.5rem',

	flexWrap: 'wrap',

	button: {
		borderRadius: '0.2rem',
		margin: 0,
		minWidth: '190px',
	},

	[breakpoint.sm()]: {
		flexDirection: 'row',
		gap: '1rem',

		button: {
			minWidth: '150px',
		},
	},
}));
