import { START_DATE_TIME_KEY, TWENTY_FOUR_HOURS } from 'constants/termsAndConditionsConstants';
import { employeeType } from 'constants/userConstants';
import Cookies from 'js-cookie';

export const getHoursDifferenceByCookies = () => {
	const startDate = Cookies.get(START_DATE_TIME_KEY);

	if (!startDate) {
		return new Date();
	}

	const nowDate = new Date();
	return Math.abs(nowDate - startDate) / 36e5;
};

export const getHoursDifference = initialDate => {
	const startDate = new Date(initialDate);

	if (!startDate) {
		return new Date();
	}

	const nowDate = new Date();
	return Math.abs(nowDate - startDate) / 36e5;
};

export const is24SinceLastAccess = startDate => {
	return getHoursDifference(startDate) > TWENTY_FOUR_HOURS;
};

export const setDateTimeInCookieBy24Hours = () => {
	const expires = 1;
	const dateTime = new Date().getTime();
	Cookies.set(START_DATE_TIME_KEY, dateTime, { expires });
};

export const setDateTimeInCookieByForever = () => {
	const expires = 36500;
	const dateTime = new Date().getTime();
	Cookies.set(START_DATE_TIME_KEY, dateTime, { expires });
};

export const setDateTimeInCookie = (expires = 1) => {
	const dateTime = new Date().getTime();
	Cookies.set(START_DATE_TIME_KEY, dateTime, { expires });
};

export const setDateTimeInCookieByStatus = status => {
	switch (status) {
		case employeeType.CONTRACTOR:
			setDateTimeInCookieBy24Hours();
			break;
		default:
			setDateTimeInCookieByForever();
			break;
	}
};

export const getDateTimeFromCookie = () => {
	return Cookies.get(START_DATE_TIME_KEY);
};

export const hasNotDateTimeInCookie = () => {
	return !getDateTimeFromCookie();
};

export const removeAllCookies = () => {
	Object.keys(Cookies.get()).forEach(cookieName => {
		const neededAttributes = {
			// Here you pass the same attributes that were used when the cookie was created
			// and are required when removing the cookie
		};
		Cookies.remove(cookieName, neededAttributes);
	});
};
