import styled from 'styled-components/macro';

export const NavBar = styled.nav(({ theme: { color, breakpoint } }) => ({
	display: 'flex',
	position: 'relative',
	top: '-75px',
	left: 0,
	padding: '20px 25px',
	justifyContent: 'space-between',
	alignItems: 'center',
	width: '100%',
	minHeight: '177px',

	background: color.background.secondary,

	[breakpoint.lg()]: {
		display: 'none',
	},
}));

export const Actions = styled.div({
	display: 'block',

	'> *': {
		marginBottom: '4px',

		':last-child': {
			marginBottom: 0,
		},
	},
});

export const Section = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});
