import { useLayoutEffect, useRef } from 'react';

const useMutableRef = value => {
	const mutableRef = useRef(value);

	useLayoutEffect(() => {
		mutableRef.current = value;
	}, [value]);

	return mutableRef;
};

export default useMutableRef;
