import axios, { axiosWithRetry } from 'API/axios/axios';
import { logError } from 'utils/logUtil';
import { isEmptyString } from 'utils/stringUtil';

export const postContentFragment = ({ pagePath, cfType, data }) => {
	const url = `${process.env.REACT_APP_API_URL}/v1/contentFragment`;

	return axios.post(url, data, {
		headers: {
			pagePath,
			cfType,
		},
	});
};

export const putContentFragment = ({ pagePath, cfType, data }) => {
	const url = `${process.env.REACT_APP_API_URL}/v1/contentFragment`;

	return axios.put(url, data, {
		headers: {
			pagePath,
			cfType,
		},
	});
};

export const deleteContentFragment = ({ pagePath, cfType, data }) => {
	const url = `${process.env.REACT_APP_API_URL}/v1/contentFragment`;

	return axios.delete(url, {
		data,
		headers: {
			pagePath,
			cfType,
		},
	});
};

export const updateRelatorOrder = ({ data, pagePath, cfType }) => {
	const url = `${process.env.REACT_APP_API_URL}/v1/contentFragment/relatorOrder`;

	return axios.put(url, data, {
		headers: {
			pagePath,
			cfType,
		},
	});
};

export const putDisplayOrderContentFragment = ({ pagePath, cfType, data }) => {
	const url = `${process.env.REACT_APP_API_URL}/v1/contentFragment`;

	return axiosWithRetry.put(url, data, {
		headers: {
			pagePath,
			cfType,
		},
	});
};

export const fromContentFragmentService = contentFragment => {
	try {
		const vcmId = isEmptyString(contentFragment['jcr:uuid']);
		const parentPath = isEmptyString(contentFragment['jcr:content']['cq:parentPath']);
		const name = isEmptyString(contentFragment['jcr:content']['cq:name']);
		const master = isEmptyString(contentFragment['jcr:content'].data.master);

		const title = isEmptyString(contentFragment['jcr:content']?.data?.master?.TITLE);
		const body = isEmptyString(contentFragment['jcr:content']?.data?.master?.BODY);
		const specification = isEmptyString(contentFragment['jcr:content']?.data?.master?.SPECIFICATION);

		const displayOrder = master.DISPLAY_ORDER;
		const type = master.TYPE;
		let path = '';

		if (parentPath && name) {
			path = `${parentPath}/${name}`;
		}

		return { vcmId, path, type, body, title, specification, displayOrder };
	} catch (e) {
		logError(e.message);
		return null;
	}
};
