import { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useRouteStorage } from 'hooks/useRouteStorage';
import { hasAppValidToken } from 'utils/jwtUtil';
import { redirectToLogin } from 'utils/redirectUtil';
import { getPathToDefault, routersList } from '../router';
import NavigationRouteRender from './NavigationRouteRender';

const Navigator = () => {
	useRouteStorage();
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });

		if (!hasAppValidToken()) {
			redirectToLogin();
		}
	}, [pathname]);

	return (
		<Switch>
			<Redirect exact from="/" to={getPathToDefault()} />
			{routersList.map(route => (
				<Route
					key={route.key}
					path={route.path}
					render={({ match, loc }) => {
						return <NavigationRouteRender match={match} location={loc} routeItem={route} />;
					}}
				/>
			))}
		</Switch>
	);
};

export default Navigator;
