import axios from 'API/axios/axios';
import { logError } from 'utils/logUtil';
import { isEmptyString } from 'utils/stringUtil';
import { PAGE_TYPES } from 'constants/pageConstants';

export const postPage = ({ data }) => {
	const url = `${process.env.REACT_APP_API_URL}/v1/aem/pageBeg`;
	return axios.post(url, data);
};

export const fromPageService = (contentFragment, type = PAGE_TYPES.CHAPTER_TYPE) => {
	try {
		const vcmId = isEmptyString(contentFragment.contentCreate['jcr:uuid']);
		const name = isEmptyString(contentFragment.contentCreate['jcr:content']['cq:name']);
		const title = isEmptyString(contentFragment.contentCreate['jcr:content']['jcr:title']); // don´t show.
		const parentPath = isEmptyString(contentFragment.contentCreate['jcr:content']['cq:parentPath']);
		const path = parentPath && name ? `${parentPath}/${name}` : '';

		const CREATOR = isEmptyString(contentFragment.contentCreate['jcr:content'].data.master.CREATOR);
		const CREATION_DATE = isEmptyString(contentFragment.contentCreate['jcr:content'].data.master.CREATION_DATE);
		const brands = isEmptyString(contentFragment.contentCreate['jcr:content'].data.master.brands);

		let relatorPath = '';
		let displayOrder = 1;

		if (type === PAGE_TYPES.CHAPTER_TYPE) {
			displayOrder = contentFragment.contentCreate['jcr:content'].data.master.BEG_CHAPTER_DISPLAY_ORDER;
		}

		if (contentFragment.contentRelator) {
			const contentRelator = contentFragment.contentRelator['jcr:content'];

			const relatorName = contentRelator['cq:name'];
			const relatorParentPath = contentRelator['cq:parentPath'];
			const { BEG_CHAPTER_RELATOR_SECTION_DISPLAY_ORDER, BEG_SECTION_RELATOR_ARTICLE_DISPLAY_ORDER } =
				contentRelator.data.master;

			relatorPath = `${relatorParentPath}/${relatorName}`;

			if (type === PAGE_TYPES.SECTION_TYPE) {
				displayOrder = BEG_CHAPTER_RELATOR_SECTION_DISPLAY_ORDER;
			}

			if (type === PAGE_TYPES.ARTICLE_TYPE) {
				displayOrder = BEG_SECTION_RELATOR_ARTICLE_DISPLAY_ORDER;
			}
		}

		return {
			vcmId,
			title,
			path,
			displayOrder,
			relatorPath,
			paragraphs: [],
			creator: CREATOR,
			creationDate: CREATION_DATE,
			brands,
		};
	} catch (e) {
		logError(e.message);
		return null;
	}
};
