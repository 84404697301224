import { useSelector } from 'react-redux';

const useDefaultPageData = () => {
	const { adGroups } = useSelector(state => state.security);

	const defaultAdGroups = adGroups
		?.filter(
			adGroup =>
				adGroup.vcmId === '5f3a19ad577e7510VgnVCM1000002031a00aRCRD' ||
				adGroup.vcmId === 'f04a19ad577e7510VgnVCM1000002031a00aRCRD'
		)
		.map(adGroup => adGroup.path);

	return {
		allowedGroups: defaultAdGroups,
	};
};

export default useDefaultPageData;
