export default {
	APPROVE: 'Approve',
	APPROVED: 'Approved',
	CLEAR_CACHE: 'Clear Cache',
	STATUS: 'Status',
	STOP_EDITING: 'STOP EDITING',
	THIS_PAGE: 'this page',
	PUBLISH: 'Publish',
	UNPUBLISH: 'Unpublish',
	PUBLISHED: 'Published',
	UNPUBLISHED: 'Unpublished',
	UNAPPROVE: 'Unapprove',
	UNAPPROVED: 'Unapproved',
};
