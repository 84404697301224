import img2COLA from 'assets/img/modulesTemplateIcons/2-COL-A.png';
import img2COLB from 'assets/img/modulesTemplateIcons/2-COL-B.png';
import img2COLC from 'assets/img/modulesTemplateIcons/2-COL-C.png';
import img2COLF from 'assets/img/modulesTemplateIcons/2-COL-F.png';
import img2COLG from 'assets/img/modulesTemplateIcons/2-COL-G.png';
import img2COLH from 'assets/img/modulesTemplateIcons/2-COL-H.png';
import img2COLI from 'assets/img/modulesTemplateIcons/2-COL-I.png';
import img2COLJ from 'assets/img/modulesTemplateIcons/2-COL-J.png';
import imgFULLK from 'assets/img/modulesTemplateIcons/FULL-K.png';
import imgTEXT from 'assets/img/modulesTemplateIcons/TEXT.png';
import imgFULLL from 'assets/img/modulesTemplateIcons/FULL-L.png';
import imgFULLM from 'assets/img/modulesTemplateIcons/FULL-M.png';
import imgTYPE3IMAGES from 'assets/img/modulesTemplateIcons/TYPE-3-IMAGES.png';
import imgTYPE4VARIABLEIMAGES from 'assets/img/modulesTemplateIcons/TYPE-4-VARIABLE-IMAGES.png';
import img3COLN from 'assets/img/modulesTemplateIcons/3-COL-N.png';

export const moduleTemplateImages = [
	{
		id: 1,
		src: img2COLA,
		templateName: 'Type + Thumbnail Gallery',
		type: '2-COL-A',
	},
	{
		id: 2,
		src: img2COLB,
		templateName: 'Type + 2 Column Image Gallery with Variable Height',
		type: '2-COL-B',
	},
	{
		id: 3,
		src: img2COLC,
		templateName: 'Type + 2 Column Image',
		type: '2-COL-C',
	},
	{
		id: 4,
		src: img2COLF,
		templateName: 'Type + 2 Column Image with Full Span',
		type: '2-COL-F',
	},
	{
		id: 5,
		src: img2COLG,
		templateName: 'Type + 2 Column Image Gallery',
		type: '2-COL-G',
	},
	{
		id: 6,
		src: img2COLH,
		templateName: 'Type + Image Full Span',
		type: '2-COL-H',
	},
	{
		id: 7,
		src: img2COLI,
		templateName: 'Type + Vertical Image',
		type: '2-COL-I',
	},
	{
		id: 8,
		src: img2COLJ,
		templateName: 'Type + Horizontal Image',
		type: '2-COL-J',
	},
	{
		id: 9,
		src: imgFULLK,
		templateName: 'Type + 3 Column Image with Full Span',
		type: 'FULL-K',
	},
	{
		id: 10,
		src: imgTEXT,
		templateName: 'Type',
		type: 'TEXT',
	},
	{
		id: 11,
		src: imgFULLL,
		templateName: 'Full Width Type + Gallery',
		type: 'FULL-L',
	},
	{
		id: 12,
		src: imgFULLM,
		templateName: 'Full Width Type + 3 Column Gallery',
		type: 'FULL-M',
	},
	{
		id: 13,
		src: imgTYPE3IMAGES,
		templateName: 'Type + 3 Images',
		type: 'TYPE-3-IMAGES',
	},
	{
		id: 14,
		src: imgTYPE4VARIABLEIMAGES,
		templateName: 'Type + 4 Variable Images',
		type: 'TYPE-4-VARIABLE-IMAGES',
	},
	{
		id: 15,
		src: img3COLN,
		templateName: 'Type + 3 Column Images',
		type: '3-COL-N',
	},
];
