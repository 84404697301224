import { useCallback, useEffect } from 'react';
import { putContentFragment } from 'services/contentFragmentService';
import { useDisapprovePage } from 'hooks/useDisapprovePage';
import useApprovePublish from 'hooks/useApprovePublish';
import { showToast } from 'utils/toastUtil';
import { getName, getParentPath, updateContentFragmentBaseObj } from 'utils/contentFragmentUtil';
import { getISONow } from 'utils/dateUtil';
import { MODELS_PATHS } from 'constants/aemConstants';
import { CHAPTERS_TYPE, PAGE_TYPES } from 'constants/pageConstants';
import { translations } from 'translation/en';
import useBrandData from './useBrandData';
import { useRequest } from './useRequest';

export const useMovePage = () => {
	const { brand } = useBrandData();
	const { loading: loadingMove, data: response, error, run, clear } = useRequest(putContentFragment);
	const { handleStaleStatus } = useApprovePublish();
	const { isLoadingDisapprovePage, disapprovePage } = useDisapprovePage();

	useEffect(() => {
		if (response) {
			clear();
		}
	}, [response, clear]);

	useEffect(() => {
		if (error) {
			clear();
		}
	}, [error, clear]);

	const movePage = useCallback(
		({ type, path, relatorPath, displayOrder }) => {
			let updateContentFragmentObj = {};

			switch (type) {
				case PAGE_TYPES.SECTION_TYPE:
					updateContentFragmentObj = updateContentFragmentBaseObj({
						name: getName(relatorPath),
						parentPath: getParentPath(relatorPath),
						model: MODELS_PATHS.RELATOR_SECTIONS,
						master: {
							MODIFICATION_DATE: getISONow(),
							BEG_CHAPTER_RELATOR_SECTION_DISPLAY_ORDER: displayOrder,
						},
					});
					break;
				case PAGE_TYPES.ARTICLE_TYPE:
					updateContentFragmentObj = updateContentFragmentBaseObj({
						name: getName(relatorPath),
						parentPath: getParentPath(relatorPath),
						model: MODELS_PATHS.RELATOR_ARTICLES,
						master: {
							MODIFICATION_DATE: getISONow(),
							BEG_SECTION_RELATOR_ARTICLE_DISPLAY_ORDER: displayOrder,
						},
					});
					break;
				default:
					updateContentFragmentObj = updateContentFragmentBaseObj({
						name: getName(path),
						parentPath: getParentPath(path),
						model: MODELS_PATHS.CHAPTER,
						master: {
							MODIFICATION_DATE: getISONow(),
							BEG_CHAPTER_DISPLAY_ORDER: displayOrder,
						},
					});
					break;
			}

			const movePagePromise = run({
				cfType: CHAPTERS_TYPE,
				pagePath: brand.parentPath,
				data: updateContentFragmentObj,
			});

			const disapprovePagePromise = disapprovePage([{ path, type }]);

			return Promise.all([movePagePromise, disapprovePagePromise]).then(responses => {
				const isSuccess = responses.some(res => res?.data);

				if (isSuccess) {
					handleStaleStatus(true);
				}

				return responses;
			});
		},
		[brand, handleStaleStatus, disapprovePage, run]
	);

	const swappingPage = useCallback(
		({ type, currentItem, previousItem, onSuccess, onFinished }) => {
			Promise.all([
				movePage({ ...currentItem, displayOrder: previousItem?.displayOrder, type }),
				movePage({ ...previousItem, displayOrder: currentItem?.displayOrder, type }),
			])
				.then(([res]) => {
					if (res?.error) {
						showToast({
							type: 'error',
							message: translations.FAILED_SAVE_CONTENT,
						});
					} else {
						onSuccess();
					}
				})
				.finally(() => {
					onFinished();
				});
		},
		[movePage]
	);

	return { loadingMove: [loadingMove, isLoadingDisapprovePage].some(Boolean), response, error, swappingPage, movePage };
};
