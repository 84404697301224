export const sizes = {
	LG: 1224,
	SD: 1024,
	MD: 880,
	SM: 768,
	XS: 450,
};

const breakPoints = Object.freeze({
	LG: `${sizes.LG}px`,
	SD: `${sizes.SD}px`,
	MD: `${sizes.MD}px`,
	SM: `${sizes.SM}px`,
	XS: `${sizes.XS}px`,
});

export default breakPoints
