export const SOLR_ATTR_PREFIX = 'aemUsr-';
export const SOLR_ATTR_PREFIX2 = 'aemUsr';
export const SORT_SOLR_ATTR_PREFIX = 'sort_aemUsr-';
export const ARR_SOLR_ATTR_PREFIX = 'aemUsrArray-';

export const PAGINATION_START = 0;
export const CQ_MODEL = `${SOLR_ATTR_PREFIX}cq_model`;
export const CQ_NAME = `${SOLR_ATTR_PREFIX}cq_name`;
export const CQ_TEMPLATE = `${SOLR_ATTR_PREFIX}cq_template`;
export const BEG_CHAPTER_TITLE = `${SOLR_ATTR_PREFIX}BEG_CHAPTER_TITLE`;
export const BEG_SECTION_TITLE = `${SOLR_ATTR_PREFIX}BEG_SECTION_TITLE`;
export const BEG_ARTICLE_TITLE = `${SOLR_ATTR_PREFIX}BEG_ARTICLE_TITLE`;
export const CHAPTER_CONTENT = `${ARR_SOLR_ATTR_PREFIX}BEG_CHAPTER_CONTENT_FK_BEG_CHAPTER_ID`;
export const SECTION_CONTENT = `${ARR_SOLR_ATTR_PREFIX}BEG_SECTION_CONTENT_FK_BEG_SECTION_ID`;
export const ARTICLE_CONTENT = `${ARR_SOLR_ATTR_PREFIX}BEG_ARTICLE_CONTENT_FK_BEG_ARTICLE_ID`;
export const CHAPTER_JOBLEVEL = `${SOLR_ATTR_PREFIX}BEG_CHAPTER_JOBLEVEL`;
export const SECTION_JOBLEVEL = `${SOLR_ATTR_PREFIX}BEG_CHAPTER_JOBLEVEL`;
export const ARTICLE_JOBLEVEL = `${SOLR_ATTR_PREFIX}BEG_CHAPTER_JOBLEVEL`;
export const TITLE = `${SOLR_ATTR_PREFIX}TITLE`;
export const BODY = `${SOLR_ATTR_PREFIX}BODY-nohtml`;
export const SPECIFICATION = `${SOLR_ATTR_PREFIX}SPECIFICATION-nohtml`;
export const REGIONS = `${SOLR_ATTR_PREFIX}regions`;
export const BRAND = `${SOLR_ATTR_PREFIX}brand`;
export const BRANDS = `${SOLR_ATTR_PREFIX}brands`;
export const PARENT_ = `${SOLR_ATTR_PREFIX}parent_`;
export const PATH_ = `${SOLR_ATTR_PREFIX}path_`;
export const LINK_TYPE = `${SOLR_ATTR_PREFIX}LinkType`;
export const VISIBLE_TO_CONTRACTOR = `${SOLR_ATTR_PREFIX}VISIBLE_TO_CONTRACTOR`;
export const VISIBLE_TO_FRANCHISE = `${SOLR_ATTR_PREFIX}VISIBLE_TO_FRANCHISE`;
export const PAGE_TITLE = `${SOLR_ATTR_PREFIX}pageTitle`;
export const DEPARTMENT_NAME = `${SOLR_ATTR_PREFIX}departmentName`;
export const SLING_RESOURCE_TYPE = `${SOLR_ATTR_PREFIX}sling_resourceType`;
export const FILE_LINK_TEXT = `${SOLR_ATTR_PREFIX}FILE_LINK_TEXT`;
export const PARENT_CONTENT_PATH = `${SOLR_ATTR_PREFIX}parentContentPath`;
export const PARENT_VCMID = `${SOLR_ATTR_PREFIX}parentVcmid`;
export const VCMID = `${SOLR_ATTR_PREFIX}VCMID`;

export const AD_GROUPS = `${ARR_SOLR_ATTR_PREFIX}adGroups'`;
export const AD_DEPARTMENTS = `${ARR_SOLR_ATTR_PREFIX}adDeparts`;

// quicksheet
export const OPERATION = `${SOLR_ATTR_PREFIX}operation`;
export const TOPIC = `${SOLR_ATTR_PREFIX}topic`;
export const SUBTOPIC = `${SOLR_ATTR_PREFIX}subTopic`;
export const GLOBAL_BRAND = `${SOLR_ATTR_PREFIX}globalBrand`;
export const GLOBAL_BRAND_NO_HTML = `${SOLR_ATTR_PREFIX}globalBrand-nohtml`;
export const PARENT_ROW_ID = `${SOLR_ATTR_PREFIX}parentRowId`;
export const SERVICE_ORDER = `${SOLR_ATTR_PREFIX}serviceOrder`;
export const PARENT_PATH = `${SOLR_ATTR_PREFIX}parentPath`;
export const CQ_PARENT_PATH = `${SOLR_ATTR_PREFIX}cq_parentPath`;
export const QUICKSHEET_PATH = `${SOLR_ATTR_PREFIX}quickSheetPath`;
export const CHAPTER_PATH = `${SOLR_ATTR_PREFIX}chapterPath`;
export const GROUP_KEY = `${SOLR_ATTR_PREFIX}groupKey`;
export const REGION = `${ARR_SOLR_ATTR_PREFIX}region`;
// end quicksheet

export const PAGE_PATH = `${ARR_SOLR_ATTR_PREFIX}pagePath`;
export const RELATED_TERMS = `${ARR_SOLR_ATTR_PREFIX}RELATED_TERMS`;

export const FILE_LINK = `${SOLR_ATTR_PREFIX2}Array-file`;

export const DISPLAY_ORDER = `${SORT_SOLR_ATTR_PREFIX}DISPLAY_ORDER`;

export const SOLR_SPECIAL_CHARS_REGEX = /[+\-&|!(){}[\]^"~*?:\\]/g;

// Terms highlight
export const BODY_NO_HTML_HIGHLIGHT = '*BODY-nohtml';
export const SPECIFICATION_NO_HTML_HIGHLIGHT = '*SPECIFICATION-nohtml';
export const FILE_LINK_TEXT_HIGHLIGHT = '*FILE_LINK_TEXT';
export const OPERATION_HIGHLIGHT = '*operation';
export const TOPIC_HIGHLIGHT = '*topic';
export const SUBTOPIC_HIGHLIGHT = '*subTopic';
export const GLOBAL_BRAND_NO_HTML_HIGHLIGHT = '*globalBrand-nohtml';
// end Terms highlight
