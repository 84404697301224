import styled from 'styled-components/macro';

export const BrandFooter = styled.footer(({ theme: { breakpoint, color } }) => ({
	display: 'flex',
	padding: '70px 20px 40px',
	width: '100%',

	[breakpoint.sm()]: {
		height: '229px',
	},
	[breakpoint.xl()]: {
		height: '249px',
	},

	background: color.background.tertiary,
}));

export const Section = styled.div({
	padding: '0 15px',
	width: '100%',
	float: 'left',
});

export const CopyrightText = styled.div(({ theme: { color, lineHeight, typo, breakpoint, copyrightText } }) => ({
	display: 'flex',
	margin: '40px 0 20px',
	width: 'inherit',
	height: 'inherit',
	justifyContent: 'flex-start',
	textAlign: 'left',

	padding: copyrightText ? copyrightText.padding : '5px 0',

	...typo.caption['01'],

	lineHeight: lineHeight.normal,
	color: color.text.primary,

	[breakpoint.lg()]: {
		width: '66%',
	},
}));
