import { useState } from 'react';
import { checkPageExistenceService } from 'services/checkPageExistence';
import { handleClearCache } from 'utils/cacheUtils';
import useBrandData from './useBrandData';

const useCheckPageExistence = () => {
	const { brandId } = useBrandData();
	const [loading, setLoading] = useState();

	const checkPageExistence = async contentPath => {
		setLoading(true);
		const promiseToReturn = checkPageExistenceService(brandId, contentPath);
		handleClearCache({ onSuccess: () => {}, setLoading });
		return promiseToReturn;
	};

	return {
		loading,
		checkPageExistence,
	};
};

export { useCheckPageExistence };
