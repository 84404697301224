/* eslint-disable no-useless-concat */
/* eslint-disable max-len */
import { MODELS_PATHS } from 'constants/aemConstants';
import { PAGINATION_SIZE } from 'constants/searchConstants';
import {
	BEG_ARTICLE_TITLE,
	BEG_CHAPTER_TITLE,
	BEG_SECTION_TITLE,
	BODY,
	BODY_NO_HTML_HIGHLIGHT,
	BRAND,
	BRANDS,
	CQ_MODEL,
	FILE_LINK_TEXT,
	FILE_LINK_TEXT_HIGHLIGHT,
	GLOBAL_BRAND,
	GLOBAL_BRAND_NO_HTML_HIGHLIGHT,
	OPERATION,
	OPERATION_HIGHLIGHT,
	PARENT_,
	PATH_,
	RELATED_TERMS,
	SOLR_SPECIAL_CHARS_REGEX,
	SPECIFICATION,
	SPECIFICATION_NO_HTML_HIGHLIGHT,
	SUBTOPIC,
	SUBTOPIC_HIGHLIGHT,
	TITLE,
	TOPIC,
	VCMID,
	TOPIC_HIGHLIGHT,
	CQ_PARENT_PATH,
} from 'constants/solrConstants';

/**
 * @param {string} q Query with ""
 * @returns {string} Query with "" replaced
 */
const replaceExactMatch = q => {
	return q.replace(/"/g, '');
};

export const escapeSpecialCharacters = str => {
	return str.replace(SOLR_SPECIAL_CHARS_REGEX, '\\$&');
};

export const escapeHtml = str => {
	const htmlEntities = {
		'&': '&amp;',
		'<': '&lt;',
		'>': '&gt;',
		'"': '&quot;',
		"'": '&#39;',
		'/': '&#x2F;',
	};
	return str.replace(/[&<>"'/]/g, match => {
		return htmlEntities[match];
	});
};

export const getSearchNameFragmentBySearchName = (searchName, exactMatch = false) => {
	const escapedSearchName = escapeSpecialCharacters(searchName);

	let allSearch = `("${escapedSearchName}")^30`;

	if (!exactMatch) {
		const words = escapedSearchName.split(' ');
		allSearch = words.slice(0, 100).reduce((acc, word) => {
			const wordFrag = `(${word})^30`;
			return `${acc} OR ${wordFrag}`;
		}, allSearch);
	}

	const searchNameHtmlEscaped = escapeHtml(searchName);

	const chapterTitleFrag = `(${BEG_CHAPTER_TITLE}:"${escapedSearchName}")^50`;
	const sectionTitleFrag = `(${BEG_SECTION_TITLE}:"${escapedSearchName}")^50`;
	const articleTitleFrag = `(${BEG_ARTICLE_TITLE}:"${escapedSearchName}")^50 `;
	const paragraphTitleFrag = `(${TITLE}:"${searchNameHtmlEscaped}")^100 `;
	const paragraphBodyFrag = `(${BODY}:"${searchNameHtmlEscaped}")^50 `;
	const paragraphSpecificationFrag = `(${SPECIFICATION}:"${searchNameHtmlEscaped}")^50 `;
	const relatedTermsFrag = `(${RELATED_TERMS}:"${escapedSearchName}")^50`;
	const fileTitleFrag = `(${FILE_LINK_TEXT}:"${escapedSearchName}")^50 OR (${FILE_LINK_TEXT}:"${escapedSearchName}.*")^50`;
	// quicksheet
	const operationFrag = `(${OPERATION}:"${escapedSearchName}")^50`;
	const topicFrag = `(${TOPIC}:"${escapedSearchName}")^50`;
	const subTopicFrag = `(${SUBTOPIC}:"${escapedSearchName}")^50`;
	const globalBrandFrag = `(${GLOBAL_BRAND}:"${escapedSearchName}")^50`;

	const searchNameFrag =
		allSearch.length > 255
			? allSearch
			: `(${allSearch} OR ` +
			  `${paragraphTitleFrag} OR ` +
			  `${chapterTitleFrag} OR ` +
			  `${paragraphBodyFrag} OR ` +
			  `${paragraphSpecificationFrag} OR ` +
			  `${sectionTitleFrag} OR ` +
			  `${articleTitleFrag} OR ` +
			  `${relatedTermsFrag} OR ` +
			  `${fileTitleFrag} OR ` +
			  `${operationFrag} OR ` +
			  `${topicFrag} OR ` +
			  `${subTopicFrag} OR ` +
			  `${globalBrandFrag}` +
			  ')';

	return searchNameFrag;
};

export const getBrandResourcesSearchParams = formData => {
	const { searchName, brand, exactMatch, start, rows } = formData;
	const fileModelFrag = `${CQ_MODEL}:'${MODELS_PATHS.RELATOR_FILE}'`;
	let brandFrag =
		`(${BRAND}:"${brand.brandId}" OR ` +
		`${BRANDS}:"${brand.brandId}" OR ` +
		`${fileModelFrag} OR ` +
		`${CQ_PARENT_PATH}:'${brand.parentPath}'` +
		`)`;

	if (brand.searchBrandId) {
		brandFrag = brandFrag
			.replace(')', '')
			.concat(` OR ${BRAND}:"${brand.searchBrandId}" OR ${BRANDS}:"${brand.searchBrandId}")`);
	}

	const chapterModelFrag = `${CQ_MODEL}:'${MODELS_PATHS.CHAPTER}'`;
	const sectionModelFrag = `${CQ_MODEL}:'${MODELS_PATHS.SECTION}'`;
	const articleModelFrag = `${CQ_MODEL}:'${MODELS_PATHS.ARTICLE}'`;
	const paragraphModelFrag = `${CQ_MODEL}:'${MODELS_PATHS.RELATOR_PARAGRAPHS}'`;

	const rowModelFrag = `${CQ_MODEL}:'${MODELS_PATHS.ROW}'`;

	const searchNameFrag = getSearchNameFragmentBySearchName(searchName, exactMatch);
	const modelsFrag =
		`(${chapterModelFrag} OR ` +
		`${sectionModelFrag} OR ` +
		`${articleModelFrag} OR ` +
		`${paragraphModelFrag} OR ` +
		`${fileModelFrag} OR ` +
		`${rowModelFrag}` +
		')';

	let query = `${searchNameFrag} ${brandFrag} ${modelsFrag}`.trim();
	let highlightQuery = `"${searchName}"`;
	let highlightItemsToAdd = { 'hl.usePhraseHighlighter': 'true', 'hl.useFastVectorHighlighter': 'true' };

	if (!exactMatch) {
		query = replaceExactMatch(query);
		highlightQuery = replaceExactMatch(highlightQuery);
		highlightItemsToAdd = {};
	}

	// Removes minisite content from BEG search
	const itemsToRemove =
		`-${PARENT_}:/.*minisite.*/ OR ` + `-${PATH_}:/.*minisite.*/ OR ` + `-${CQ_PARENT_PATH}: '/content/dam/Hyattconnect'`;

	const fieldsToHighlight = `${BODY_NO_HTML_HIGHLIGHT}, ${SPECIFICATION_NO_HTML_HIGHLIGHT}, ${FILE_LINK_TEXT_HIGHLIGHT} ${OPERATION_HIGHLIGHT}, ${TOPIC_HIGHLIGHT}, ${SUBTOPIC_HIGHLIGHT}, ${GLOBAL_BRAND_NO_HTML_HIGHLIGHT}`;

	return {
		q: query,
		fq: itemsToRemove,
		'q.op': 'AND',
		start,
		rows: rows || PAGINATION_SIZE,
		fl: '*,score',
		sort: 'score desc, sort_aemUsr-MODIFICATION_DATE desc',
		defType: 'edismax',
		wt: 'json',
		hl: true,
		'hl.fragsize': 300,
		'hl.highlightMultiTerm': 'true',
		'hl.simple.pre': '<mark class="search-highlight">',
		'hl.simple.post': '</mark>',
		'hl.q': highlightQuery,
		'hl.fl': fieldsToHighlight,
		...highlightItemsToAdd,
	};
};

export const getSolrParamsToSearchByVcmIds = vcmIds => {
	const vcmIdsFrag = vcmIds.map(vcmId => `(${VCMID}:${vcmId})`).join(' ');
	const query = `${vcmIdsFrag}`;

	return {
		q: query,
		fq: '',
		'q.op': 'OR',
		start: 0,
		rows: 1000,
		fl: '*,score',
		sort: 'score desc, sort_aemUsr-MODIFICATION_DATE desc',
	};
};
