import PropTypes from 'prop-types';
import React from 'react';
import * as S from './Icon.style';

const Icon = ({ icon, onClick }) => {
	return (
		<S.Box style={icon?.style} onClick={onClick}>
			<S.Icon className={`icon ${icon?.name}`} />
		</S.Box>
	);
};

Icon.propTypes = {
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	onClick: PropTypes.func,
};

Icon.defaultProps = {
	icon: '',
	onClick: () => null,
};

export { Icon };
