import { translations } from 'translation/en';

export const EDIT_MENU_TYPES = {
	EDIT_INLINE: 'edit-inline',
	ADD_MODULE: 'add-module',
	ADD_EDIT_FILES: 'add-edit-files',
	CHANGE_DELETE: 'change-delete',
	MOVE: 'move',
	EDIT_NAVIGATION: 'edit-navigation',
	EDIT_PAGE_CONFIG: 'edit-page-config',
	RESET_EDIT_INLINE: '',
	APPROVE: 'approve',
	PUBLISH: 'publish',
	PUBLISH_BRANDS: 'publish-brands',
	EDIT_PUBLISH_BRANDS: 'edit-publish-brands',
	RESET: '',
};

export const editMenuOptions = [
	{
		headerName: translations.MODULE,
		defaultCollapsed: true,
		options: [
			{
				icon: <i className="icon icon-pencil" />,
				label: translations.EDIT_INLINE,
				value: EDIT_MENU_TYPES.EDIT_INLINE,
			},
			{
				icon: <i className="icon icon-plus" style={{ fontSize: '18px', fontWeigh: 600 }} />,
				label: translations.ADD_MODULE,
				value: EDIT_MENU_TYPES.ADD_MODULE,
			},
			{
				icon: <i className="icon icon-file-earmark " />,
				label: translations.ADD_EDIT_FILES,
				value: EDIT_MENU_TYPES.ADD_EDIT_FILES,
			},
			{
				icon: <i className="icon icon-four-squares " />,
				label: translations.CHANGE_DELETE,
				value: EDIT_MENU_TYPES.CHANGE_DELETE,
			},
			{
				icon: <i className="icon icon-vertical-align" />,
				label: translations.MOVE,
				value: EDIT_MENU_TYPES.MOVE,
			},
			{
				icon: <i className="icon icon-gear-fill" />,
				label: translations.EDIT_PAGE_CONFIG,
				value: EDIT_MENU_TYPES.EDIT_PAGE_CONFIG,
			},
		],
	},
	{
		headerName: translations.NAVIGATION,
		defaultCollapsed: false,
		options: [
			{
				icon: <i className="icon icon-cursor-shine" />,
				label: translations.EDIT_NAVIGATION,
				value: EDIT_MENU_TYPES.EDIT_NAVIGATION,
			},
		],
	},
];

export const isQuickSheetSectionAndArticle = currentType => {
	if (!currentType) {
		return null;
	}

	const { sectionPath, articlePath, quickSheetPath } = currentType;

	return quickSheetPath && (sectionPath?.startsWith(quickSheetPath) || articlePath?.startsWith(quickSheetPath));
};

export const getMenuOptionsByQuickSheet = currentType => {
	const isRemovedEditPage = isQuickSheetSectionAndArticle(currentType);

	const chapterQuicksheetOptions = editMenuOptions?.map(item => ({
		...item,
		options: item.options.filter(
			option => option.value === EDIT_MENU_TYPES.EDIT_PAGE_CONFIG || option.value === EDIT_MENU_TYPES.EDIT_NAVIGATION
		),
	}));

	const othersQuicksheetOptions = editMenuOptions?.find(item =>
		item.options.some(option => option.value === EDIT_MENU_TYPES.EDIT_NAVIGATION)
	);

	const newEditMenuOptions = isRemovedEditPage ? [othersQuicksheetOptions] : [...chapterQuicksheetOptions];

	return newEditMenuOptions.map(item => ({ ...item }));
};

export const CONTENT_MANAGERS_GROUP = 'CHICO-SGM-CHICO-BEG-ContentManagers';
