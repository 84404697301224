import { useCallback, useEffect, useMemo, useState } from 'react';
import { fromPageService, postPage } from 'services/pageService';
import { generatePagePath } from 'utils/contentFragmentUtil';
import { generateDisplayOrderByType } from 'utils/editNavigationUtil';
import { showToast } from 'utils/toastUtil';
import { translations } from 'translation/en';
import { CHAPTERS_TYPE, PAGE_TYPES } from 'constants/pageConstants';
import useBrandData from './useBrandData';
import useBrandDetails from './useBrandDetails';
import { useRequest } from './useRequest';
import useDefaultPageData from './useDefaultPageData';
import useParagraphs from './useParagraphs';

export const useCreatePage = () => {
	const { brand } = useBrandData();
	const { currentType, chapters, clearCurrentPage } = useBrandDetails();
	const { loading, data: response, error, run, clear } = useRequest(postPage);
	const { handleParagraphs } = useParagraphs();

	const securityData = useDefaultPageData();
	const [pageType, setPageType] = useState(PAGE_TYPES.CHAPTER_TYPE);

	useEffect(() => {
		if (response) {
			clear();
		}
	}, [response, clear]);

	useEffect(() => {
		if (error) {
			clear();
			showToast({ type: 'error', message: translations.FAILED_CREATE_PAGE });
		}
	}, [clear, error]);

	const createPage = useCallback(
		async ({ title, type }) => {
			clearCurrentPage();
			const { cqName, pagePath, chapterCqName, sectionCqName, chapterParentPath, sectionParentPath } = generatePagePath({
				type,
				name: title,
				basePath: brand?.parentPath,
				chapterPath: currentType?.chapterPath,
				sectionPath: currentType?.sectionPath,
			});

			const chapterDisplayOrder = generateDisplayOrderByType(chapters, currentType, type);
			const sectionDisplayOrder = generateDisplayOrderByType(chapters, currentType, type);
			const articleDisplayOrder = generateDisplayOrderByType(chapters, currentType, type);

			const commonData = {
				cfType: CHAPTERS_TYPE,
				brands: brand.brandId,
				pagePath: brand.pagePath,
			};

			let payload = {};
			setPageType(type);

			switch (type) {
				case PAGE_TYPES.SECTION_TYPE:
					payload = {
						chapterDTO: {
							...commonData,
							cqName: chapterCqName,
							parentPath: chapterParentPath,
							dispOrder: currentType?.chapterDisplayOrder,
						},
						sectionDTO: {
							...commonData,
							title,
							cqName,
							dispOrder: `${sectionDisplayOrder}`,
							parentPath: pagePath,
							parentContentPath: currentType?.chapterPath,
						},
					};
					break;
				case PAGE_TYPES.ARTICLE_TYPE:
					payload = {
						chapterDTO: {
							...commonData,
							cqName: chapterCqName,
							parentPath: chapterParentPath,
							dispOrder: currentType?.chapterDisplayOrder,
						},
						sectionDTO: {
							...commonData,
							cqName: sectionCqName,
							parentPath: sectionParentPath,
							dispOrder: currentType?.sectionDisplayOrder,
							parentContentPath: currentType?.chapterPath,
						},
						articleDTO: {
							...commonData,
							title,
							cqName,
							dispOrder: `${articleDisplayOrder}`,
							parentPath: pagePath,
							parentContentPath: currentType?.sectionPath,
						},
					};
					break;
				default:
					payload = {
						chapterDTO: {
							...commonData,
							title,
							cqName,
							dispOrder: `${chapterDisplayOrder}`,
							parentPath: pagePath,
							...securityData,
						},
					};
					break;
			}

			return run({
				data: payload,
			}).then(() => {
				handleParagraphs([]);
			});
		},
		[
			clearCurrentPage,
			brand?.parentPath,
			brand.brandId,
			brand.pagePath,
			currentType,
			chapters,
			securityData,
			handleParagraphs,
			run,
		]
	);

	const page = useMemo(() => {
		return response?.data && fromPageService(response.data, pageType);
	}, [response, pageType]);

	return { loading, page, error, createPage };
};
