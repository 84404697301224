import React, { forwardRef, useCallback, useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { FormFieldWithButtons } from '../formField';
import { removeRepeatedElements } from './utils/arrayUtil';
import { addKeywordsPrefix } from './utils/keywordsUtil';
import MultiFieldList from './components/multiFieldList/MultiFieldList';
import './MultiField.scss';

export const MultiField = forwardRef(
	(
		{
			currentItems,
			setCurrentItems,
			itemsKey,
			fieldLabel,
			required,
			disabled,
			itemPrefix,
			showItemPrefix,
			errors,
			hasError,
			title,
			...props
		},
		ref
	) => {
		const [value, setValue] = useState('');

		const addItem = useCallback(
			(e) => {
				const newItems = value
					.split(/[,;]+/)
					?.filter((filterItem) => !!filterItem);
				const itemsWithPrefix = addKeywordsPrefix({
					keywords: newItems,
					prefix: itemPrefix,
				});

				if (
					((e.type === 'keydown' && e.key === 'Enter') ||
						e.type === 'click') &&
					itemsWithPrefix.length > 0
				) {
					const mergedItems = removeRepeatedElements([
						...currentItems,
						...itemsWithPrefix,
					]);
					setCurrentItems(itemsKey, mergedItems, {
						shouldDirty: true,
					});
					setValue('');
				}
			},
			[itemPrefix, value, currentItems, setCurrentItems, itemsKey]
		);

		const removeItem = useCallback(
			(itemToRemove) => {
				const newItems = currentItems.filter(
					(item) => item !== itemToRemove
				);
				setCurrentItems(itemsKey, newItems, { shouldDirty: true });
			},
			[currentItems, setCurrentItems, itemsKey]
		);

		return (
			<div className="MultiField">
				<FormFieldWithButtons
					onKeyDown={addItem}
					disabled={disabled}
					label={fieldLabel}
					title={title}
					required={required}
					hasError={hasError}
					errors={errors}
					onPrimaryButtonClick={addItem}
					primaryButtonChildren={<i className="icon icon-plus" />}
					primaryButtonDisabled={!value.trim()}
					value={value}
					ref={ref}
					{...props}
					onChange={(e) => setValue(e.target.value)}
				/>

				<MultiFieldList
					items={currentItems}
					onRemove={removeItem}
					itemPrefix={itemPrefix}
					showItemPrefix={showItemPrefix}
				/>
			</div>
		);
	}
);

MultiField.propTypes = {
	currentItems: arrayOf(string),
	setCurrentItems: func.isRequired,
	itemsKey: string.isRequired,
	fieldLabel: string.isRequired,
	title: string,
	required: bool,
	disabled: bool,
	hasError: bool,
	itemPrefix: string,
	showItemPrefix: bool,
	errors: shape({}),
};

MultiField.defaultProps = {
	required: false,
	disabled: false,
	hasError: false,
	currentItems: [],
	itemPrefix: '',
	title: '',
	showItemPrefix: false,
	errors: null,
};
