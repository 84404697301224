import React from 'react';
import { arrayOf, bool, func, node, objectOf, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import defaultTranslations from './constants/defaultTranslations';
import { EditMenuPublishOptions } from './components/editMenuPublishOptions/EditMenuPublishOptions';
import { EditMenuApproveOptions } from './components/editMenuApproveOptions/EditMenuApproveOptions';
import { EditMenuGroup } from './components/editMenuGroup/EditMenuGroup';
import './EditMenu.scss';

export const EditMenu = ({
	checkIcon,
	groups,
	isOpen,
	isPageApproved,
	onClearCache,
	onOpen,
	onStopEditing,
	selectedOption,
	setIsOpen,
	handleApproveUnapprovedPage,
	setSelectedOption,
	translations,
	hasMobile,
	style,
	isApproveLoading,
	isPagePublish,
	loadingIcon,
	publishStatus,
	publishOptions,
	groupExpandedIcon,
	groupCollapsedIcon,
	isGroupsCollapsible,
	openApproveModal,
	openPublishModal,
}) => {
	const { CLEAR_CACHE, STOP_EDITING } = translations;

	return (
		<aside
			className={classNames('EditMenu', {
				'EditMenu--mobile': hasMobile,
			})}
			style={style}
		>
			<button
				className={classNames('EditMenu__opener', {
					'EditMenu--red-background': !isPageApproved,
					'EditMenu__is-open': isOpen,
				})}
				onClick={() => {
					setIsOpen(!isOpen);
					onOpen();
				}}
				aria-label={`Edit in Line ${!isOpen ? 'openner' : 'closer'}`}
			>
				{isOpen ? '' : '+'}
			</button>
			<div
				className={classNames('EditMenu__options', {
					'EditMenu__options--open': isOpen,
				})}
			>
				{groups.map(({ headerName, defaultCollapsed, options }) => (
					<EditMenuGroup
						key={headerName}
						headerName={headerName}
						expandedCollapses={defaultCollapsed}
						options={options}
						selectedOption={selectedOption}
						setSelectedOption={setSelectedOption}
						expandedIcon={groupExpandedIcon}
						collapsedIcon={groupCollapsedIcon}
						isCollapsible={isGroupsCollapsible}
					/>
				))}
				<div className="EditMenu__buttonsContainer">
					{onClearCache && (
						<button className="EditMenu__button" onClick={onClearCache}>
							{CLEAR_CACHE}
						</button>
					)}
					<button
						className="EditMenu__button"
						onClick={() => {
							onStopEditing();
							setSelectedOption('');
							setIsOpen(!isOpen);
						}}
					>
						{STOP_EDITING}
					</button>
				</div>
				{handleApproveUnapprovedPage && (
					<EditMenuApproveOptions
						handleApproveUnapprovedPage={handleApproveUnapprovedPage}
						isApproveLoading={isApproveLoading}
						isPageApproved={isPageApproved}
						translations={translations}
						checkIcon={checkIcon}
						loadingIcon={loadingIcon}
						handleOpenModal={openApproveModal}
					/>
				)}

				{publishOptions && (
					<EditMenuPublishOptions
						checkIcon={checkIcon}
						isPagePublish={isPagePublish}
						loadingIcon={loadingIcon}
						publishOptions={publishOptions}
						publishStatus={publishStatus}
						translations={translations}
						handleOpenModal={openPublishModal}
					/>
				)}
			</div>
		</aside>
	);
};

EditMenu.propTypes = {
	checkIcon: node,
	groups: arrayOf(
		shape({
			headerName: string,
			options: arrayOf(
				shape({
					icon: node,
					label: oneOfType([string, node, objectOf(Object)]),
					onClick: func,
				})
			),
		})
	).isRequired,
	isOpen: bool,
	isPageApproved: bool,
	hasMobile: bool,
	onClearCache: func,
	onOpen: func,
	onStopEditing: func.isRequired,
	selectedOption: string,
	selectedValue: string,
	setIsOpen: func.isRequired,
	handleApproveUnapprovedPage: func,
	setIsPageApproved: func,
	style: objectOf(Object),
	setSelectedOption: func.isRequired,
	setSelectedValue: func,
	isApproveLoading: bool,
	translations: shape({
		APPROVE: string,
		APPROVED: string,
		CLEAR_CACHE: string,
		STATUS: string,
		STOP_EDITING: string,
		THIS_PAGE: string,
		UNAPPROVE: string,
		UNAPPROVED: string,
		PUBLISH: string,
		PUBLISHED: string,
		UNPUBLISH: string,
		UNPUBLISHED: string,
		STALE: string,
	}),
	isPagePublish: bool,
	loadingIcon: node,
	publishStatus: string,
	publishOptions: arrayOf(
		shape({
			key: string,
			text: string,
			onClick: func,
			disabled: bool,
			loading: bool,
		})
	),
	groupExpandedIcon: node,
	groupCollapsedIcon: node,
	isGroupsCollapsible: bool,
	openApproveModal: func,
	openPublishModal: func,
};

EditMenu.defaultProps = {
	checkIcon: null,
	isOpen: true,
	isPageApproved: true,
	hasMobile: false,
	onClearCache: null,
	onOpen: () => {},
	handleApproveUnapprovedPage: null,
	selectedOption: '',
	selectedValue: '',
	setIsPageApproved: null,
	style: null,
	setSelectedValue: () => {},
	isApproveLoading: false,
	translations: defaultTranslations,
	isPagePublish: false,
	loadingIcon: null,
	publishStatus: null,
	publishOptions: null,
	groupExpandedIcon: <i className="icon icon-chevron-up" />,
	groupCollapsedIcon: <i className="icon icon-chevron-down" />,
	isGroupsCollapsible: false,
	openApproveModal: () => {},
	openPublishModal: () => {},
};
