import { mediaQueryConstants } from 'constants/mediaQueryConstants';
import { useCallback, useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { legacySaveView } from 'services/analyticsService';
import { logError } from 'utils/logUtil';
import { analyticsObjectTypes } from 'constants/analyticsObjectTypes';
import { localStorageKeys } from 'constants/localStorageConstants';
import { useAuthData } from './useAuthData';
import { useCurrentRouteInstance } from './useCurrentRouteInstance';
import useBrandData from './useBrandData';
import { useLocalStorage } from './useLocalStorage';

export const useAnalytics = (autoRun = true, id = '') => {
	const { brandId } = useBrandData();
	const { user } = useAuthData();

	const isSaved = useRef(false);
	const currentRoute = useCurrentRouteInstance();
	const isMobile = useMediaQuery(mediaQueryConstants.MQ_MOBILE);
	const [storedRoute] = useLocalStorage(localStorageKeys.STORED_ROUTE, { current: '', last: '' });

	const saveView = useCallback(
		async ({
			contentDepartment = '',
			contentId = id,
			contentType = '',
			contentCategory = '',
			typeObject = analyticsObjectTypes.CONTENT_INSTANCE,
			pageAuthor = '',
			pageBrands = '',
			pageFunctions = '',
			pagePublishingDate = '',
			pageRegions = '',
			pageTitle = '',
		} = {}) => {
			if (!currentRoute || isSaved.current) return;

			const body = {
				userIdEL: user.id,
				spiritCodeEL: user.location,
				currentUserType: user.employeeType,
				jobLevelCodeEL: user.jobLevelCode,
				jobLevel: user.jobCode,
				departmentEL: user.departmentName,
				brandIdEL: user.locationObj?.brandId,
				regionIdEL: user.locationObj?.regionId,
				locationType: user.locationObj?.type,
				country: user.locationObj?.country,
				vcmId: contentId,
				pageTitle: pageTitle ?? currentRoute.getPageTitle(),
				visibleToFranchisee: user.locationObj?.visibleToFranchise,
				deptContentEL: contentDepartment,
				typeContent: contentType,
				applicationName: process.env.REACT_APP_ANALYTICS_APP_NAME,
				viewedBy: isMobile ? 'mobile' : 'desktop',
				typeObject,
				subDepartment: '',
				siteBrandId: brandId.toUpperCase(),
				business: contentCategory,

				pageAuthor,
				pageBrands,
				pageRegions,
				pageFunctions,
				pagePublishingDate,
				parentPage: storedRoute.last,
			};

			try {
				await legacySaveView(body);
				isSaved.current = true;
			} catch (e) {
				logError(e);
			}
		},
		[
			id,
			currentRoute,
			user?.id,
			user?.location,
			user?.employeeType,
			user?.jobLevelCode,
			user?.jobCode,
			user?.departmentName,
			user?.locationObj?.brandId,
			user?.locationObj?.regionId,
			user?.locationObj?.type,
			user?.locationObj?.country,
			user?.locationObj?.visibleToFranchise,
			isMobile,
			brandId,
			storedRoute.last,
		]
	);

	const saveViewOnDemand = useCallback(
		async ({
			contentDepartment = '',
			contentId = id,
			contentType = '',
			contentCategory = '',
			typeObject = '',
			pageAuthor = '',
			pageBrands = '',
			pageFunctions = '',
			pagePublishingDate = '',
			pageRegions = '',
			pageTitle = '',
		} = {}) => {
			const body = {
				userIdEL: user.id,
				spiritCodeEL: user.location,
				currentUserType: user.employeeType,
				jobLevelCodeEL: user.jobLevelCode,
				jobLevel: user.jobCode,
				departmentEL: user.departmentName,
				brandIdEL: user.locationObj?.brandId,
				regionIdEL: user.locationObj?.regionId,
				locationType: user.locationObj?.type,
				country: user.locationObj?.country,
				vcmId: contentId,
				pageTitle,
				visibleToFranchisee: user.locationObj?.visibleToFranchise,
				deptContentEL: contentDepartment,
				typeContent: contentType,
				applicationName: process.env.REACT_APP_ANALYTICS_APP_NAME,
				viewedBy: isMobile ? 'mobile' : 'desktop',
				typeObject,
				subDepartment: '',
				siteBrandId: brandId.toUpperCase(),
				business: contentCategory,

				pageAuthor,
				pageBrands,
				pageRegions,
				pageFunctions,
				pagePublishingDate,
				parentPage: storedRoute.last,
			};

			try {
				await legacySaveView(body);
			} catch (e) {
				logError(e);
			}
		},
		[
			id,
			user?.id,
			user?.location,
			user?.employeeType,
			user?.jobLevelCode,
			user?.jobCode,
			user?.departmentName,
			user?.locationObj?.brandId,
			user?.locationObj?.regionId,
			user?.locationObj?.type,
			user?.locationObj?.country,
			user?.locationObj?.visibleToFranchise,
			isMobile,
			brandId,
			storedRoute.last,
		]
	);

	useEffect(() => {
		if (autoRun) saveView();

		return () => {
			isSaved.current = false;
		};
	}, [autoRun, saveView]);

	return { saveView, saveViewOnDemand };
};
