import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { Modal } from 'components/modals/modal';
import useBrandDetails from 'hooks/useBrandDetails';
import usePrintRoute from 'hooks/usePrintRoute';
import { useRowsByQuickSheet } from 'hooks/useRowsByQuickSheet';
import { translations } from 'translation/en';
import { PAGE_TYPES } from 'constants/pageConstants';
import * as S from './PrintModal.style';

const PrintModal = ({ open, onClose }) => {
	const { currentType } = useBrandDetails();
	const { fetchRows } = useRowsByQuickSheet();
	const { getPrintRoute } = usePrintRoute();

	const [domReady, setDomReady] = useState(false);
	const portalElement = document.getElementById('root');

	useEffect(() => {
		if (currentType?.quickSheetPath) {
			fetchRows({
				operation: currentType?.sectionTitle,
				topic: currentType?.articleTitle,
				path: currentType?.quickSheetPath,
			});
		}
	}, [currentType?.articleTitle, currentType?.quickSheetPath, currentType?.sectionTitle, fetchRows]);

	useEffect(() => {
		setDomReady(true);

		return () => {
			setDomReady(false);
		};
	}, []);

	const handleClose = () => {
		onClose();
	};

	const getParamsByType = type => {
		let params = {};

		switch (type) {
			case PAGE_TYPES.CHAPTER_TYPE:
				params = {
					path: currentType.chapterPath,
					type: PAGE_TYPES.CHAPTER_TYPE,
				};
				break;
			case PAGE_TYPES.SECTION_TYPE:
				params = {
					path: currentType.sectionPath,
					type: PAGE_TYPES.SECTION_TYPE,
				};
				break;
			case PAGE_TYPES.ARTICLE_TYPE:
				params = {
					path: currentType.articlePath,
					type: PAGE_TYPES.ARTICLE_TYPE,
				};
				break;
			default:
				break;
		}

		if (currentType.quickSheetPath) {
			params = {
				'quicksheet-path': currentType.quickSheetPath,
				'quicksheet-chapter': currentType.chapterTitle,
				'quicksheet-operation': currentType.sectionTitle,
				'quicksheet-topic': currentType.articleTitle,
			};
		}

		return params;
	};

	const handleRedirectPrintPage = (e, type) => {
		e.preventDefault();

		const params = getParamsByType(type);

		window.open(
			`#${getPrintRoute({
				type,
				params,
			})}`,
			'_blank'
		);
	};

	const renderRow = ({ title, label, icon, onClick }) =>
		title && (
			<S.Row>
				<div>
					<S.StyledButton variant="tertiary-02" onClick={onClick}>
						<i className={icon} />
						{label}
					</S.StyledButton>
				</div>
				<S.Lead>{title}</S.Lead>
			</S.Row>
		);

	const modalComponent = open && (
		<Modal open={open} onClose={handleClose} closeIcon={<i className="icon-close-slim" />} hideExpand>
			<Modal.Header />
			<Modal.Main>
				<S.Content>
					<S.Title>{translations.MODAL_PRINT_TITLE}</S.Title>
					{renderRow({
						title: currentType?.chapterTitle,
						label: translations.CHAPTER,
						icon: 'icon-book-solid',
						onClick: e => handleRedirectPrintPage(e, PAGE_TYPES.CHAPTER_TYPE),
					})}

					{renderRow({
						title: currentType?.sectionTitle,
						label: translations.SECTION,
						icon: 'icon-documents-outline',
						onClick: e => handleRedirectPrintPage(e, PAGE_TYPES.SECTION_TYPE),
					})}

					{renderRow({
						title: currentType?.articleTitle,
						label: translations.ARTICLE,
						icon: 'icon-hamburger-menu',
						onClick: e => handleRedirectPrintPage(e, PAGE_TYPES.ARTICLE_TYPE),
					})}
				</S.Content>
			</Modal.Main>
		</Modal>
	);

	return domReady ? ReactDOM.createPortal(modalComponent, portalElement) : null;
};

PrintModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
};

PrintModal.defaultProps = {
	open: false,
	onClose: () => {},
};

export { PrintModal };
