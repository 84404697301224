import classNames from 'classnames';
import { bool, shape, string } from 'prop-types';
import React, { forwardRef } from 'react';
import './FormField.scss';
import FormFieldDescription from './FormFieldDescription';
import FormFieldError from './FormFieldError';
import FormFieldLabel from './FormFieldLabel';

export const FormField = forwardRef(
  (
    {
      className,
      required,
      hasError,
      label,
      description,
      errors,
      title,
      ...props
    },
    ref
  ) => {
    return (
      <>
        {label && (
          <FormFieldLabel label={label} required={required} title={title} />
        )}
        {description && <FormFieldDescription description={description} />}
        <input
          className={classNames('FormField', className, {
            'FormField--has-error': hasError,
          })}
          required={required}
          {...props}
          ref={ref}
        />
        {hasError && <FormFieldError message={errors?.message} />}
      </>
    );
  }
);

FormField.propTypes = {
  className: string,
  required: bool,
  hasError: bool,
  label: string,
  errors: shape({}),
};

FormField.defaultProps = {
  className: '',
  required: false,
  hasError: false,
  label: null,
  errors: null,
};
