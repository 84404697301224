import PropTypes from 'prop-types';
import React from 'react';
import { BrandSideBarSection } from './BrandSideBarSection';
import './BrandSideBarSections.scss';

const BrandSideBarSections = ({ sections, ...rest }) => {
	if (!sections) {
		return null;
	}

	return (
		<ul className="BrandSideBarSections">
			{sections.map(section => (
				<BrandSideBarSection key={section.vcmId + section.path + section.displayOrder} {...section} {...rest} />
			))}
		</ul>
	);
};

BrandSideBarSections.propTypes = {
	sections: PropTypes.array,
};

BrandSideBarSections.defaultProps = {
	sections: [],
};

export { BrandSideBarSections };
