import { oneOfType, string, node } from 'prop-types';
import React from 'react';
import * as S from './PageSubTitle.style';

const PageSubTitle = ({ children }) => {
	return <S.SubTitle>{children}</S.SubTitle>;
};

PageSubTitle.propTypes = {
	children: oneOfType([string, node]).isRequired,
};

export { PageSubTitle };
