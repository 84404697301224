import classNames from "classnames";
import { endOfDay } from "date-fns";
import { any, bool, func, object, string } from "prop-types";
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { Calendar } from "../datePicker/Calendar";
import { translation } from "./constants/translation";
import "./FormFieldDate.scss";
import FormFieldError from "./FormFieldError";
import FormFieldLabel from "./FormFieldLabel";
import { getFormattedDate } from "./utils/dateUtils";

export const FormFieldDate = forwardRef(
  (
    {
      required,
      hasError,
      control,
      name,
      value,
      label,
      icon,
      errors,
      setValue,
      minDate,
      maxDate,
      disabledDay,
      disabled,
      autoDateSave,
      forTheEndOfTheDay,
      hasClear,
      format = (v) => getFormattedDate(v, "yyyy-MM-dd"),
    },
    ref
  ) => {
    const [date, setDate] = useState(format(value));

    useEffect(() => {
      setDate(format(value));
    }, [value]);

    const [isCalendarVisible, setIsCalendarVisible] = useState(false);
    const buttonRef = useRef();
    const calendarRef = useRef();

    const openCalendar = useCallback(
      (e) => {
        if (e.key === "Enter") {
          setIsCalendarVisible(true);
        }
      },
      [setIsCalendarVisible]
    );

    const closeCalendar = useCallback(
      (e) => {
        const { target } = e;

        if (
          !buttonRef.current?.contains(target) &&
          !calendarRef.current?.contains(target)
        ) {
          setIsCalendarVisible(false);
        }
      },
      [setIsCalendarVisible]
    );

    useEffect(() => {
      if (isCalendarVisible) {
        document.addEventListener("click", closeCalendar);
      }

      return () => document.removeEventListener("click", closeCalendar);
    }, [closeCalendar, isCalendarVisible]);

    const handleDateChange = useCallback(
      (date) => {
        const dateValue = forTheEndOfTheDay
          ? endOfDay(new Date(date))
          : new Date(date);
        setDate(date);
        setValue(name, dateValue.toISOString(), {
          shouldValidate: true,
          shouldDirty: true,
        });
        setIsCalendarVisible(false);

        autoDateSave();
      },
      [setDate, setValue, setIsCalendarVisible]
    );

    const handleClearDate = useCallback(() => {
      setDate("");
      setValue(name, "", {
        shouldValidate: true,
        shouldDirty: true,
      });
      setIsCalendarVisible(false);

      autoDateSave();
    });

    return (
      <>
        {label && <FormFieldLabel label={label} required={required} />}

        <div ref={buttonRef} onClick={() => setIsCalendarVisible(!disabled)}>
          <Controller
            name={name}
            control={control}
            rules={{ required: required ? translation.REQUIRED : false }}
            render={({ field: { value, onChange, ...rest } }) => (
              <div className="FormFieldDate__wrapper">
                <input
                  className={classNames("FormFieldDate", {
                    "FormFieldDate--has-error": hasError,
                    "FormFieldDate--is-active": isCalendarVisible,
                    "FormFieldDate--is-disabled": disabled,
                  })}
                  ref={ref}
                  disabled={disabled}
                  type="text"
                  onKeyDown={openCalendar}
                  onChange={() => {}}
                  value={date}
                  autoComplete="off"
                  {...rest}
                />
                {hasClear && (
                  <button
                    className={classNames("FormFieldDate__clear-button", {
                      "FormFieldDate__clear-button--is-disabled": disabled,
                    })}
                    onClick={handleClearDate}
                    disabled={!date}
                  >
                    <i className="icon icon-close-slim" />
                  </button>
                )}

                {icon ? <i className={classNames(icon)} /> : null}
              </div>
            )}
          />
        </div>

        <Calendar
          ref={calendarRef}
          dateValue={date}
          handleDateChange={handleDateChange}
          isVisible={isCalendarVisible}
          minDate={minDate}
          maxDate={maxDate}
          disabledDay={disabledDay}
        />

        {hasError && <FormFieldError message={errors?.message} />}
      </>
    );
  }
);

FormFieldDate.propTypes = {
  setValue: func.isRequired,
  hasError: bool.isRequired,
  control: any.isRequired,
  errors: any,
  required: bool,
  minDate: object,
  maxDate: object,
  label: string,
  disabledDay: func,
  disabled: bool,
  autoDateSave: func,
  hasClear: bool,
  forTheEndOfTheDay: bool,
};

FormFieldDate.defaultProps = {
  required: false,
  label: null,
  errors: null,
  minDate: new Date(),
  maxDate: undefined,
  disabledDay: () => false,
  disabled: false,
  autoDateSave: () => {},
  hasClear: false,
  forTheEndOfTheDay: false,
};
