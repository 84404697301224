import PropTypes from 'prop-types';
import { EDIT_MENU_TYPES } from 'components/begEditMenu/constants/begEditMenuConstants';
import { ChangeDeleteModule } from './changeDeleteModule';
import { MoveModule } from './moveModule';

const ActionComponent = ({ action, ...props }) =>
	({
		[EDIT_MENU_TYPES.CHANGE_DELETE]: <ChangeDeleteModule {...props} />,
		[EDIT_MENU_TYPES.MOVE]: <MoveModule {...props} />,
	}[action]);

const ModuleMenu = ({ action, children, ...props }) => {
	return (
		<div>
			<ActionComponent action={action} {...props} />
			{children}
		</div>
	);
};

ModuleMenu.propTypes = {
	action: PropTypes.string,
	children: PropTypes.node.isRequired,
};

ModuleMenu.defaultProps = {
	action: EDIT_MENU_TYPES.RESET,
};

export { ModuleMenu };
