import ZivaHorizontalLogo from 'assets/img/logos/ziva-horizontal.svg';
import ZivaLogo from 'assets/img/logos/ziva-logo.svg';
import complementaryTheme from 'themes/complementary/complementary';
import colors from './zivaColors';
import typographyTheme from './zivaTypo';

const logo = {
	footer: ZivaHorizontalLogo, // logo in the footer
	loading: ZivaLogo, // logo in the loading
	sidebar: ZivaLogo, // logo in the sidebar
	mobile: ZivaLogo, // logo in the navbar
	size: {
		sidebar: {
			width: '89px',
			height: '83px',
		},
		footer: {
			width: '215px',
			height: '36px',
		},
		navbar: {
			width: '89px',
			height: '144px',
		},
		loading: {
			width: '95px',
			height: '95px',
		},
	},
};

const ziva = {
	brandId: 'zv',
	name: 'Hyatt Ziva',
	logo,
	color: colors,

	font: {
		family: {
			helveticaNeue: 'Helvetica Neue',
			gothamBook: 'Gotham Book',
			arialSansSerif: 'Arial, Helvetica, sans-serif',
			poynterRegular: 'Poynter Regular',
			verlagBlack: 'Verlag Black',
			verlagBold: 'Verlag Bold',
			verlagBook: 'Verlag Book',
			neutraBold: 'Neutra Bold',
			robotoMonoRegular: 'RobotoMono Regular',
			verlagBookCalibriArial: 'Verlag Book, Calibri, Arial',
		},
	},
};

const zivaTheme = {
	...ziva,
	...typographyTheme(ziva),
	...complementaryTheme,
};

export default zivaTheme;
