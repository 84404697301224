import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import classNames from 'classnames';
import { array, bool, func, oneOfType, shape, string } from 'prop-types';
import {
	BarChartFormWrapper,
	BarChartWrapper,
	DropdownMenuLink,
	HeaderAnalytics,
	Menu,
	ModalBoxAnalytics,
	Overlay,
	PieChartWrapper,
	SideBarMetrics,
	SidePaneAnalytics,
} from './components';
import { AnalyticsProvider } from './context/AnalyticsContext';
import { useAnalyticsContext } from './hooks/useAnalyticsContext';
import { getFormDefaultValues } from './utils/getFormDefaultValues';
import { hasLength } from './utils/arrayUtils';
import { isString } from './utils/stringUtil';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-responsive-modal/styles.css';
import './ModalAnalytics.scss';

const AnalyticsWrapper = props => {
	return (
		<AnalyticsProvider>
			<ModalAnalytics {...props} />
		</AnalyticsProvider>
	);
};

const ModalAnalytics = ({ appConfig, contentId, open, onClose }) => {
	const [openMenu, setOpenMenu] = useState(false);
	const { setConfigApp, setActiveId, locationType } = useAnalyticsContext();

	useEffect(() => {
		setConfigApp(appConfig);

		if (contentId) {
			setActiveId(!isString(contentId) ? contentId[0]?.contentId : contentId);
		}
	}, [appConfig, contentId, setActiveId, setConfigApp]);

	const methods = useForm({
		defaultValues: getFormDefaultValues(),
	});

	const hasGrid = !isString(contentId) && hasLength(contentId);

	return (
		<FormProvider {...methods}>
			<ModalBoxAnalytics
				className="ModalAnalytics__modal"
				open={open}
				onClose={onClose}
				large={!isString(contentId) && hasLength(contentId)}
			>
				<Overlay open={openMenu} onClick={() => setOpenMenu(false)} />

				<SidePaneAnalytics open={openMenu}>
					<Menu onClose={() => setOpenMenu(false)} />
				</SidePaneAnalytics>

				<HeaderAnalytics openMenu={() => setOpenMenu(true)} />

				<div
					className={classNames('ModalAnalytics__body', {
						'ModalAnalytics__body--grid': hasGrid,
					})}
				>
					{hasGrid && <SideBarMetrics listContentIds={contentId} />}

					{!locationType.pageDay ? (
						<div className="bodyContent">
							<div className="WrapperForm">
								<DropdownMenuLink />
							</div>

							<PieChartWrapper />
						</div>
					) : (
						<div className="BarChartWrapperContent">
							<BarChartFormWrapper />
							<BarChartWrapper />
						</div>
					)}
				</div>
			</ModalBoxAnalytics>
		</FormProvider>
	);
};

export { AnalyticsWrapper as ModalAnalytics };

ModalAnalytics.propTypes = {
	appConfig: shape({}).isRequired,
	contentId: oneOfType([string, array]),
	open: bool.isRequired,
	onClose: func.isRequired,
};

ModalAnalytics.defaultProps = {
	contentId: '',
};
