/* eslint-disable no-tabs */
import styled from 'styled-components/macro';

export const EditableInputContainer = styled.div(() => ({
	position: 'relative',
	width: '100%',

	'.tox-tinymce-inline': {
		position: 'absolute',
		top: '-45px',
	},

	'.EditableInputContainer__overlay': {
		zIndex: 10,
	},
}));

export const EditIcon = styled.i(() => ({
	position: 'absolute',
	left: '-35px',
	display: 'block',
	background: '#f0f0f0',
	color: '#b4b4b4',
	textDecoration: 'none',
	fontSize: '16px',
	width: '25px',
	height: '26px',
	padding: '5px 6px',
	cursor: 'pointer',
}));

export const PlaceholderTitleWrapper = styled.h2(() => ({
	position: 'relative',

	'>i': {
		top: '15px',
	},
}));

export const PlaceholderTitle = styled.h2(() => ({
	color: '#c1c1c1 !important',
	lineHeight: '16px',
	fontSize: '16px',
	fontWeight: '400',
	padding: '15px 0 0 0',
	textTransform: 'uppercase',
}));

export const Placeholder = styled.div(() => ({
	color: '#c1c1c1 !important',
	fontFamily: 'Arial',
	lineHeight: '16px',
	fontSize: '16px',
	fontWeight: '400',
	padding: '0 0 20px 0',
}));

export const TitleContainer = styled.div(({ theme: { color, typo } }) => ({
	textAlign: 'left',
	padding: '15px 0px',

	'h1, h2, h3, h4, h5, h6, span': {
		color: color.text.titleModule,
		...typo.headline['01.1'],
	},

	'.EditableInputContainer__overlay': {
		height: '90%',
		top: '15px',
	},
}));

export const ContentContainer = styled.div(({ theme: { color, typo, breakpoint } }) => ({
	textAlign: 'left',

	'*': {
		margin: 0,
	},

	'.WrapperEditableContent': {
		'.InnerHtml': {
			h1: {
				wordWrap: 'break-word',
				color: color.text.headline01,
				...typo.headline['01.2'],

				[breakpoint.lg()]: {
					...typo.headline['01.2']?.lg,
				},
			},
			h2: {
				marginBottom: '15px',
				wordWrap: 'break-word',
				color: color.text.headline02,
				...typo.headline['02.1'],
			},
			h3: {
				marginBottom: '15px',
				color: color.text.headline03,
				...typo.headline['03'],
			},
			h4: {
				margin: 0,
				paddingBottom: '10px',
				letterSpacing: 'initial',
				color: color.text.headline04,
				...typo.headline['04'],
			},
			h5: {
				padding: '15px 0 0',
				color: color.text.headline05,
				marginBottom: 0,
				...typo.headline['05'],
			},
			h6: {
				color: color.text.headline06,
				...typo.headline['06'],
			},
			p: {
				position: 'relative',
				lineHeight: '1.4',
				paddingBottom: '10px',
				color: color.text.paragraph,
				...typo.body['02'],
			},
			ul: {
				marginBottom: '10px !important',
			},
			'*': {
				margin: 0,

				li: {
					marginBottom: '5px !important',
					color: color.text.paragraph,
					...typo.body['07'],
				},
				'li::marker': {
					color: color.text.bullet,
					fontSize: typo.body['07'].bulletSize,
				},
				p: {
					padding: 0,
				},
			},
			table: {
				borderCollapse: 'collapse',
				width: '100%',
				color: color.table.row.cell.label,
				tbody: {
					borderBottom: color.table.row.cell.border,
				},
				tr: {
					'&:nth-child( even )': {
						backgroundColor: color.table.row.background.secondary,
					},
					'&:nth-child( odd )': {
						backgroundColor: color.table.row.background.primary,
					},
				},
				td: {
					borderRight: color.table.row.cell.border,
					padding: '8px',
					verticalAlign: 'top',
				},
				...typo.table?.['01'],
			},
		},
	},
}));
