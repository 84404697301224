import { MODELS_PATHS } from 'constants/aemConstants';
import { findBrandByPagePath } from 'utils/mappedBrandsUtil';
import { PAGE_TYPES } from 'constants/pageConstants';

export const verifyContentType = currentType => {
	const { type } = currentType;

	const isChapter = type === PAGE_TYPES.CHAPTER_TYPE;
	const isSection = type === PAGE_TYPES.SECTION_TYPE;
	const isArticle = type === PAGE_TYPES.ARTICLE_TYPE;

	return { isChapter, isSection, isArticle };
};

export const getContentModel = currentType => MODELS_PATHS[currentType.type.toUpperCase()];

export const getContentRelatorModel = currentType => {
	const { isSection, isArticle } = verifyContentType(currentType);

	if (isSection) return MODELS_PATHS.RELATOR_SECTIONS;
	if (isArticle) return MODELS_PATHS.RELATOR_ARTICLES;

	return null;
};

export const getContentRelatorPath = currentType => {
	const { isSection, isArticle } = verifyContentType(currentType);

	if (isSection) return currentType.sectionRelatorPath;
	if (isArticle) return currentType.articleRelatorPath;

	return null;
};

export const getContentDefaultValues = (content, currentType) => {
	const { isChapter, isSection, isArticle } = verifyContentType(currentType);
	const brandsPagePaths = content?.pagePath?.map(pagePath => findBrandByPagePath(pagePath)).filter(brand => brand);

	const defaultAttributes = {
		allowedDepartments: content.allowedDepartments || [],
		allowedGroups: content.allowedGroups || [],
		brands: content.brands || '',
		regions: content.regions || '',
		supervisoryLevel: content.supervisoryLevel || '',
		title: content.title || '',
		visibleToFranchise: content.visibleToFranchise || false,
		keywords: content.keywords || [],
		pagePath: brandsPagePaths || [],
	};

	if (isChapter) {
		return {
			...defaultAttributes,
			chapterType: content.chapterType || '',
			displayOrder: content.displayOrder || '',
		};
	}

	if (isSection) {
		return {
			...defaultAttributes,
			displayOrder: currentType.sectionDisplayOrder || '',
			overrideSecurityOptions: content.overrideSecurityOptions || false,
		};
	}

	if (isArticle) {
		return {
			...defaultAttributes,
			displayOrder: currentType.articleDisplayOrder || '',
			overrideSecurityOptions: content.overrideSecurityOptions || false,
		};
	}

	return defaultAttributes;
};
