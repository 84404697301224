import { AUTH_SET_USER, AUTH_SET_USER_DETAIL } from '../../actions/actionTypes';

const initialState = {
	user: null,
};

const authSetUser = (state, action) => ({
	...state,
	user: action.user,
});

const authSetUserDetail = (state, action) => ({
	...state,
	user: {
		...state.user,
		...action.userDetail,
	},
});

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case AUTH_SET_USER:
			return authSetUser(state, action);
		case AUTH_SET_USER_DETAIL:
			return authSetUserDetail(state, action);
		default:
			break;
	}
	return state;
};

export default reducer;
