import { useCallback, useEffect } from 'react';
import useApprovePublish from 'hooks/useApprovePublish';
import { useDisapprovePage } from 'hooks/useDisapprovePage';
import { putContentFragment } from 'services/contentFragmentService';
import { getName, getParentPath, updateContentFragmentBaseObj } from 'utils/contentFragmentUtil';
import { showToast } from 'utils/toastUtil';
import { getISONow } from 'utils/dateUtil';
import { MODELS_PATHS } from 'constants/aemConstants';
import { translations } from 'translation/en';
import { useRequest } from './useRequest';

export const useMoveFile = () => {
	const { loading: loadingMove, data: response, error, run, clear } = useRequest(putContentFragment);
	const { handleStaleStatus } = useApprovePublish();
	const { isLoadingDisapprovePage, disapprovePage } = useDisapprovePage();

	useEffect(() => {
		if (response) {
			clear();
		}
	}, [response, clear]);

	useEffect(() => {
		if (error) {
			clear();
		}
	}, [error, clear]);

	const moveFile = useCallback(
		({ type, path, pagePath, displayOrder }) => {
			const updateContentFragmentObj = updateContentFragmentBaseObj({
				name: getName(path),
				parentPath: getParentPath(path),
				model: MODELS_PATHS.RELATOR_FILE,
				master: {
					DispOrder: displayOrder,
					MODIFICATION_DATE: getISONow(),
				},
			});

			const moveFilePromise = run({
				pagePath,
				cfType: type,
				data: updateContentFragmentObj,
			});

			const disapprovePagePromise = disapprovePage([{ path, type }]);

			return Promise.all([moveFilePromise, disapprovePagePromise]).then(responses => {
				const isSuccess = responses.some(res => res?.data);

				if (isSuccess) {
					handleStaleStatus(true);
				}

				return responses;
			});
		},
		[handleStaleStatus, disapprovePage, run]
	);

	const swappingFile = useCallback(
		({ type, pagePath, currentItem, previousItem, onSuccess, onFinished }) => {
			return Promise.all([moveFile({ ...currentItem, type, pagePath }), moveFile({ ...previousItem, type, pagePath })])
				.then(([res]) => {
					if (res?.error) {
						showToast({ type: 'error', message: translations.FAILED_CHANGE_FILE });
					} else {
						onSuccess();
					}
				})
				.finally(() => {
					onFinished();
				});
		},
		[moveFile]
	);

	return { loading: [loadingMove, isLoadingDisapprovePage].some(Boolean), response, error, swappingFile };
};
