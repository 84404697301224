import { useCallback, useEffect, useState } from 'react';
import { InnerHtml } from '@hyatt/inner-html';
import PropTypes from 'prop-types';
import { AddEditFiles } from 'components/addEditFiles';
import { ButtonModalAnalytics } from 'components/buttonModalAnalytics/ButtonModalAnalytics';
import { useDeleteFile } from 'hooks/useDeleteFile';
import { useEditMenuData } from 'hooks/useEditMenuData';
import { useMoveFile } from 'hooks/useMoveFile';
import useParagraphs from 'hooks/useParagraphs';
import { useHighlight } from 'hooks/useHighlight';
import { useAnalytics } from 'hooks/useAnalytics';
import useBrandDetails from 'hooks/useBrandDetails';
import { useFixFilesDisplayOrder } from 'hooks/useFixFilesDisplayOrder';
import { dynamicLinkUrl } from 'utils/urlUtil';
import { EDIT_MENU_TYPES } from 'components/begEditMenu/constants/begEditMenuConstants';
import { deleteFile, extractFileNameFromPath, insertFile, moveFileDown, moveFileUp, updateFile } from 'utils/fileUtil';
import { analyticsObjectTypes } from 'constants/analyticsObjectTypes';
import * as S from './ListFiles.style';

const ListFiles = ({ paragraph, files, isAnalyticsEnabled }) => {
	const { currentType } = useBrandDetails();
	const { onStartedLoadingParagraphs, onFinishedLoadingParagraphs } = useParagraphs();
	const { selectedToEdit } = useEditMenuData();

	const [filesData, setFilesData] = useState([]);
	const [selectedFile, setSelectedFile] = useState();
	const { fetchFixedDisplayOrder } = useFixFilesDisplayOrder();

	const { swappingFile } = useMoveFile();
	const { removeFile } = useDeleteFile();
	const { handleTextHighlight } = useHighlight();
	const { saveViewOnDemand } = useAnalytics(false);

	const isAddEditFiles = selectedToEdit === EDIT_MENU_TYPES.ADD_EDIT_FILES;

	const onFinally = useCallback(() => {
		onFinishedLoadingParagraphs();
	}, [onFinishedLoadingParagraphs]);

	const onOpenFile = (e, filePath) => {
		e.preventDefault();
		window.open(dynamicLinkUrl(filePath), '_blank');
	};

	const onSelectedFile = payload => {
		setSelectedFile(payload);
	};

	const handleClickButtonFile = (e, file) => {
		onOpenFile(e, file?.filePath?._path || file?.filePath);
		let fileName = file?.file?.name || file?.fileName;

		if (file.filePath?._path) {
			fileName = extractFileNameFromPath(file.filePath._path);
		}

		if (file?.vcmId && fileName) {
			saveViewOnDemand({
				contentId: file.vcmId,
				pageTitle: fileName,
				typeObject: analyticsObjectTypes.STATIC_FILE,
				pageAuthor: file?.creator,
				pageBrands: currentType?.brands,
				pageRegions: currentType?.regions,
				pagePublishingDate: currentType?.creationDate,
			});
		}
	};

	const handleAddFile = payload => {
		setFilesData(prev => insertFile(prev, payload));
	};

	const handleEditFile = payload => {
		setFilesData(prev => updateFile(prev, payload));
	};

	const handleRemoveFile = payload => {
		setFilesData(prev => deleteFile(prev, payload));
	};

	const handleDeleteFile = async payload => {
		onStartedLoadingParagraphs();

		return removeFile({
			...currentType,
			pagePath: currentType?.path,
			contentPath: payload.path,
			onSuccess: () => handleRemoveFile(payload),
			onFinished: () => onFinally(),
		});
	};

	const handleSwap = useCallback(
		({ currentItem, previousItem, replacedArr }) => {
			if (!currentItem || !previousItem) return;
			onStartedLoadingParagraphs();

			swappingFile({
				type: currentType?.type,
				pagePath: currentType?.path,
				currentItem,
				previousItem,
				onSuccess: () => {
					setSelectedFile(currentItem);
					setFilesData(replacedArr);
				},
				onFinished: () => onFinally(),
			});
		},
		[currentType?.path, currentType?.type, onStartedLoadingParagraphs, swappingFile, onFinally]
	);

	const handleMoveUp = useCallback(
		file => {
			const payload = moveFileUp(filesData, file);
			handleSwap(payload);
		},
		[filesData, handleSwap]
	);

	const handleMoveDown = useCallback(
		file => {
			const payload = moveFileDown(filesData, file);
			handleSwap(payload);
		},
		[filesData, handleSwap]
	);

	useEffect(() => {
		const fetchFixedDisplayOrderFiles = async () => {
			const filesArr = await fetchFixedDisplayOrder(files);
			setFilesData(filesArr);
		};

		if (files?.length > 0) {
			fetchFixedDisplayOrderFiles();
		}
	}, [files, fetchFixedDisplayOrder]);

	return (
		(filesData.length > 0 || isAddEditFiles) && (
			<S.Container>
				<AddEditFiles show={isAddEditFiles} paragraph={paragraph} files={filesData} onAddFile={handleAddFile}>
					{filesData.map(file => {
						return (
							<S.Box key={file.vcmId}>
								<AddEditFiles.Menu
									show={isAddEditFiles}
									file={file}
									selectedFile={selectedFile}
									paragraph={paragraph}
									onSelectedFile={onSelectedFile}
									onEditFile={handleEditFile}
									onMoveUp={handleMoveUp}
									onMoveDown={handleMoveDown}
									onDeleteFile={handleDeleteFile}
								>
									<S.ButtonsContainer>
										<S.FileButton
											aria-label="file-button"
											variant="primary-05"
											onClick={e => handleClickButtonFile(e, file)}
										>
											<InnerHtml body={handleTextHighlight(file.linkText)} />
										</S.FileButton>
										{isAnalyticsEnabled && (
											<ButtonModalAnalytics aria-label="analytics-button" contentId={file.vcmId} />
										)}
									</S.ButtonsContainer>
								</AddEditFiles.Menu>
							</S.Box>
						);
					})}
				</AddEditFiles>
			</S.Container>
		)
	);
};

ListFiles.propTypes = {
	paragraph: PropTypes.shape({
		files: PropTypes.array,
	}),
	files: PropTypes.array,
	isAnalyticsEnabled: PropTypes.bool,
};

ListFiles.defaultProps = {
	paragraph: {
		files: [],
	},
	files: [],
	isAnalyticsEnabled: true,
};

export { ListFiles };
