import styled from 'styled-components/macro';

export const Container = styled.div(() => ({
	display: 'block',
	margin: 0,
	position: 'relative',
	float: 'left',
	background: '#fff',
	paddingRight: '10px',
}));

export const Options = styled.div(() => ({
	display: 'inline-flex',
	padding: '10px 5px 11px 5px',
	width: 'fit-content',
	background: '#f7f7f7',
	border: '1px solid #ccc',
	height: '40px',
}));

export const MoveOptions = styled.div(() => ({
	display: 'flex',
	position: 'absolute',
	flexDirection: 'column',
	width: 'fit-content',
	background: '#f7f7f7',
	border: '1px solid #ccc',
	height: '50px',
	left: '-40px',
}));

export const IconButton = styled.button(() => ({
	display: 'inline-flex',
	margin: '0 auto',
	justifyContent: 'center',
	alignItems: 'center',
	border: 'none',
	fontSize: '16px',
	width: '40px',
	height: '100%',
	background: 'none',
	borderLeft: '1px solid #ccc',

	'*': {
		color: '#999',
		fill: '#999',
	},

	'&:first-child': {
		borderLeft: 'none',
	},

	'&:hover': {
		'*': {
			color: '#333',
			fill: '#333',
		},
	},
}));

export const MoveIconButton = styled.button(() => ({
	display: 'inline-flex',
	margin: '0 auto',
	justifyContent: 'center',
	alignItems: 'center',
	border: 'none',
	fontSize: '16px',
	width: '40px',
	height: '100%',
	background: 'none',

	'*': {
		color: '#999',
		fill: '#999',
	},

	'&:first-child': {
		borderBottom: '1px solid #ccc',
	},

	'&:hover': {
		'*': {
			color: '#333',
			fill: '#333',
		},
	},
}));
