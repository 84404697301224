import React from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';

import store from 'store/store';
import App from './App';
import ErrorBoundaryComponent from './error/ErrorBoundaryComponent';
import './fonts.scss';
import './icons.scss';
import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<ErrorBoundary FallbackComponent={ErrorBoundaryComponent}>
		<Provider store={store}>
			<HashRouter>
				<App />
			</HashRouter>
		</Provider>
	</ErrorBoundary>
);
