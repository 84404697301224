import React from 'react';
import Skeleton from 'react-loading-skeleton';
import './TermsConditionsSkeleton.scss';

const TermsConditionsSkeleton = () => (
	<div className="TermsConditionsSkeleton">
		<div className="TermsConditionsSkeleton__title-container ">
			<Skeleton className="TermsConditionsSkeleton__title" />
		</div>
		<div className="TermsConditionsSkeleton__content">
			<div className="TermsConditionsSkeleton__sub-title">
				<Skeleton height={30} width={150} />
			</div>
			<div className="TermsConditionsSkeleton__text">
				<Skeleton height={20} count={4} />
			</div>
			<div className="TermsConditionsSkeleton__text-mobile">
				<Skeleton height={20} count={4} />
			</div>
		</div>
	</div>
);

export { TermsConditionsSkeleton };
