import axios, { axiosWithRetry } from 'API/axios/axios';
import { getLastElement } from 'utils/arrayUtil';
import { getName } from 'utils/contentFragmentUtil';
import { logError } from 'utils/logUtil';
import { isEmptyString } from 'utils/stringUtil';

export const postFile = ({ pagePath, cfType, data }) => {
	const url = `${process.env.REACT_APP_API_URL}/v1/asset/httpClient/upload`;

	return axiosWithRetry.post(url, data, {
		headers: {
			pagePath,
			cfType,
		},
	});
};

export const verifyFileStatus = ({ pagePath, cfType, processId }) => {
	const url = `${process.env.REACT_APP_API_URL}/v1/asset/process/${processId}`;

	return axios.get(url, {
		headers: {
			pagePath,
			cfType,
		},
	});
};

export const putFile = ({ pagePath, cfType, data }) => {
	const url = `${process.env.REACT_APP_API_URL}/v1/asset/httpClient/update`;

	return axiosWithRetry.put(url, data, {
		headers: {
			pagePath,
			cfType,
		},
	});
};

export const fromFileService = contentFragment => {
	try {
		const vcmId = isEmptyString(contentFragment['jcr:uuid']);
		const parentPath = isEmptyString(contentFragment['jcr:content']['cq:parentPath']);
		const name = isEmptyString(contentFragment['jcr:content']['cq:name']);
		const master = isEmptyString(contentFragment['jcr:content'].data.master);
		const linkText = isEmptyString(master.FILE_LINK_TEXT);
		const displayOrder = master.DispOrder;
		const filePath = getLastElement(master.file);

		let path = '';
		let file = null;

		if (filePath) {
			file = {
				name: getName(filePath),
			};
		}

		if (parentPath && name) {
			path = `${parentPath}/${name}`;
		}

		return { vcmId, file, path, filePath, parentPath, linkText, displayOrder };
	} catch (e) {
		logError(e.message);
		return null;
	}
};
