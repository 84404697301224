import RegencyHyattWhiteLogo from 'assets/img/logos/hyattRegencyVC.svg';
import RegencyHyattLogo from 'assets/img/logos/regency-footer.svg';
import complementaryTheme from 'themes/complementary/complementary';
import colors from './hyattRegencyColors';
import typographyTheme from './hyattRegencyTypo';

const logo = {
	footer: RegencyHyattLogo,
	loading: RegencyHyattLogo, // logo in the loading
	sidebar: RegencyHyattWhiteLogo,
	mobile: RegencyHyattWhiteLogo, // logo in the navbar
	size: {
		sidebar: {
			width: '130px',
			height: '144px',
		},
		footer: {
			width: '160px',
			height: '70px',
		},
		navbar: {
			width: '130px',
			height: '144px',
		},
		loading: {
			width: '140px',
			height: '154px',
		},
	},
};

const hyattRegency = {
	brandId: 'hr',
	name: 'Hyatt Regency',
	logo,
	color: colors,

	font: {
		family: {
			helveticaNeue: 'Helvetica Neue',
			gothamLight: 'Gotham Light',
			whitneyMedium: 'Whitney Medium',
			whitneyBook: 'Whitney Book',
			arialSansSerif: 'Arial, Helvetica, sans-serif',
			neutraFace: 'Neutra Face',
			calibri: 'Calibri, Arial',
			avenirHeavy: 'Avenir Heavy',
			gothamBook: 'Gotham Book',
			poynterRegular: 'Poynter Regular',
			robotoMonoRegular: 'RobotoMono Regular',
			josefinSans: 'Josefin Sans',
			timesNewRoman: 'Times New Roman',
		},
	},
};

const hyattRegencyTheme = {
	...hyattRegency,
	...typographyTheme(hyattRegency),
	...complementaryTheme,
};

export default hyattRegencyTheme;
