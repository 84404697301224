import TinyMCEEditor from 'components/tinyMceEditor/TinyMCEEditor';
import { func, string } from 'prop-types';

const EditableTitleInput = ({
	containerId,
	value,
	initialContent,
	onChangedContent,
	onChange,
	onConfig,
	toolbarOptions,
	...props
}) => {
	const handleEditorChange = content => {
		onChange(content);
	};

	return (
		<TinyMCEEditor
			containerId={containerId}
			value={value}
			init={{
				fixed_toolbar_container: `#${containerId}`,
				height: 150,
				menubar: false,
				auto_focus: true,
				selector: 'input#editor',
				toolbar: toolbarOptions,
				entity_encoding: 'raw',
				forced_root_block: 'h2',
				setup(editor) {
					editor.on('keydown', e => {
						return e.keyCode !== 13;
					});
					editor.ui.registry.addButton('config', {
						icon: 'preferences',
						onAction: onConfig,
					});
				},
			}}
			onEditorChange={handleEditorChange}
			{...props}
		/>
	);
};

EditableTitleInput.propTypes = {
	value: string,
	containerId: string.isRequired,
	initialContent: string,
	toolbarOptions: string,
	onChangedContent: func.isRequired,
	onChange: func.isRequired,
	onConfig: func,
};

EditableTitleInput.defaultProps = {
	value: '',
	initialContent: '',
	toolbarOptions: 'config',
	onConfig: () => {},
};

export { EditableTitleInput };
