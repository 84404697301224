import AndazLogo from 'assets/img/logos/andaz-black.svg';
import AndazLogoWhite from 'assets/img/logos/andaz-white.svg';
import complementaryTheme from 'themes/complementary/complementary';
import colors from './andazColors';
import typographyTheme from './andazTypo';

const logo = {
	footer: AndazLogo, // logo in the footer
	loading: AndazLogo, // logo in the loading
	sidebar: AndazLogoWhite, // logo in the sidebar
	mobile: AndazLogoWhite, // logo in the navbar
	size: {
		sidebar: {
			width: '175px',
			height: '50px',
		},
		footer: {
			width: '120px',
			height: '60px',
		},
		navbar: {
			width: '175px',
			height: '90px',
		},
		loading: {
			width: '168px',
			height: '91px',
		},
	},
};

const copyrightText = {
	padding: '5px 0 5px 10px',
};

const andaz = {
	brandId: 'az',
	name: 'Andaz',
	logo,
	color: colors,
	copyrightText,

	font: {
		family: {
			helveticaNeue: 'Helvetica Neue',
			gothamBook: 'Gotham Book',
			gothamLight: 'Gotham Light',
			arialSansSerif: 'Arial, Helvetica, sans-serif',
			poynterRegular: 'Poynter Regular',
			didot: 'Didot',
			theanoDidotRegular: 'TheanoDidot Regular',
			robotoMonoRegular: 'RobotoMono Regular',
			interLight: 'Inter-Light',
			inter: 'Inter',
			interSemiBold: 'Inter-SemiBold',
			marcellus: 'Marcellus-Regular',
		},
	},
};

const andazTheme = {
	...andaz,
	...typographyTheme(andaz),
	...complementaryTheme,
};

export default andazTheme;
