export const hasLength = arr => !!(arr && arr.length);

export const mergeUnique = (arrayA = [], arrayB = []) => {
	const mergeArr = [...arrayA, ...arrayB];
	const result = [];
	mergeArr.forEach(item => {
		if (result.indexOf(item) === -1) {
			result.push(item);
		}
	});
	return result;
};

export const getGroupedDataByKey = (arr, key) => {
	const groups = arr.reduce((hashMap, row) => {
		const item = hashMap[row[key]] || {};
		const list = item.list || [];
		list.push(row);
		return {
			...hashMap,
			[row[key]]: {
				group: row,
				list,
			},
		};
	}, {});
	delete groups[''];
	return Object.values(groups).sort();
};

export const groupByKey = (list, key) =>
	list.reduce((hash, obj) => ({ ...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj) }), {});

export const chunk = (array, size) => {
	const remaining = array.length % size;
	const remainingItems = array.slice(array.length - remaining, array.length);

	const arrayNoRemaining = array.filter((e, idx) => {
		return array.length - idx > remaining;
	});

	const chunked = [];
	let index = 0;

	while (index < arrayNoRemaining.length) {
		chunked.push(array.slice(index, size + index));
		index += size;
	}

	chunked[chunked.length - 1] = [...chunked[chunked.length - 1], ...remainingItems];

	return chunked;
};

export const isEqual = (a, b) =>
	Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);

export const removeRepeatedElements = array => array.filter((element, index, arr) => arr.indexOf(element) === index);

export const moveArrayElements = (arr, firstItem, firstItemIndex, secondItem, secondItemIndex) => {
	const replacedArr = [...arr];

	replacedArr[firstItemIndex] = secondItem;
	replacedArr[secondItemIndex] = firstItem;

	return replacedArr;
};

export const moveArrItemUp = ({ arr, itemToMoveUp, itemKey }) => {
	const itemToMoveUpIndex = arr.findIndex(item => item[itemKey] === itemToMoveUp[itemKey]);

	if (itemToMoveUpIndex === 0) return { replacedArr: arr };

	const itemToReplaceIndex = itemToMoveUpIndex - 1;
	const itemToReplace = arr[itemToReplaceIndex];

	const replacedArr = moveArrayElements(arr, itemToMoveUp, itemToMoveUpIndex, itemToReplace, itemToReplaceIndex);

	return { currentItem: itemToMoveUp, previousItem: itemToReplace, replacedArr };
};

export const moveArrItemDown = ({ arr, itemToMoveDown, itemKey }) => {
	const itemToMoveDownIndex = arr.findIndex(item => item[itemKey] === itemToMoveDown[itemKey]);
	const lastItemIndex = arr.length - 1;

	if (itemToMoveDownIndex === lastItemIndex) return { replacedArr: arr };

	const itemToReplaceIndex = itemToMoveDownIndex + 1;
	const itemToReplace = arr[itemToReplaceIndex];

	const replacedArr = moveArrayElements(arr, itemToMoveDown, itemToMoveDownIndex, itemToReplace, itemToReplaceIndex);

	return { currentItem: itemToMoveDown, previousItem: itemToReplace, replacedArr };
};

export const insertItemInArr = ({ arr, payload, index }) => {
	const auxArr = Array.isArray(arr) ? [...arr] : [];
	auxArr.splice(index, 0, payload);
	return auxArr;
};

export const addItemFromArr = ({ arr, payload }) => {
	const auxArr = Array.isArray(arr) ? [...arr] : [];
	auxArr.push(payload);
	return auxArr;
};

export const updateItemFromArr = ({ arr, payload, itemKey }) => {
	const foundedIndex = arr.findIndex(item => item[itemKey] === payload[itemKey]);
	if (foundedIndex === -1) return arr;

	arr.splice(foundedIndex, 1, payload);
	return arr;
};

export const deleteItemFromArr = ({ arr, payload, itemKey }) => {
	return arr?.filter(item => item[itemKey] !== payload[itemKey]);
};

export const getFirstElement = arr => arr[0];

export const getLastElement = arr => arr && arr[arr.length - 1];

export const removeLastElement = arr => arr.slice(0, -1);

export const removeNonElements = array => array.filter(element => element);

export const isLastElement = (arr, index) => index === arr.length - 1;

export const isFirstElement = (arr, element) => arr.indexOf(element) === 0;

export const sortAscendingByKey = (arr = [], key = 'id') => (hasLength(arr) ? [...arr.sort((a, b) => a[key] - b[key])] : []);

export const sortDescendingByKey = (arr = [], key = 'id') => (hasLength(arr) ? [...arr.sort((a, b) => b[key] - a[key])] : []);

export const sortAscending = arr => arr.sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));

export const getPreviousElements = (arr, index) => arr.slice(0, index);

export const getNextElements = (arr, index) => arr.slice(index + 1);

export const sortArrayOfObjectByAnotherArray = (a, b, sortedArr) => {
	const pathA = a.path;
	const pathB = b.path;

	// Find the indices of the paths in the sorted array of strings
	const indexA = sortedArr.indexOf(pathA);
	const indexB = sortedArr.indexOf(pathB);

	// Compare the indices and return the result
	if (indexA < indexB) {
		return -1;
	}

	if (indexA > indexB) {
		return 1;
	}

	return 0;
};

export const isEmpty = arr => {
	return Array.isArray(arr) && arr.length === 0;
};

export const grabAllBeforeString = (inputString, searchString, inclusive = false) => {
	const foundStringIndex = inputString.indexOf(searchString);

	if (foundStringIndex !== -1) {
		if (inclusive) {
			return inputString.substring(0, foundStringIndex + searchString.length);
		}

		return inputString.substring(0, foundStringIndex);
	}

	return '';
};

export const filterUniqueObjectsByIndex = (arr1, arr2, idx) => {
	return arr1.filter(obj1 => !arr2.some(obj2 => obj2[idx] === obj1[idx]));
};

export function groupBy(arr, field) {
	return arr.reduce((acc, obj) => {
		const key = obj[field];
		if (!acc[key]) {
			acc[key] = [];
		}
		acc[key].push(obj);
		return acc;
	}, {});
}

export const isNumArrSequential = arr => {
	let isSequential = true;

	arr.forEach((num, index) => {
		if (index !== arr.length - 1 && num + 1 !== arr[index + 1]) {
			isSequential = false;
		}
	});

	return isSequential;
};
