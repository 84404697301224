import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { analyticsObjectTypes } from 'constants/analyticsObjectTypes';
import { useAnalytics } from 'hooks/useAnalytics';
import { ButtonModalAnalytics } from 'components/buttonModalAnalytics/ButtonModalAnalytics';
import useBrandDetails from 'hooks/useBrandDetails';
import { useHighlight } from 'hooks/useHighlight';
import { InnerHtml } from '@hyatt/inner-html';

const ContentQuickSheetSpecification = ({ linkArray }) => {
	const { currentType } = useBrandDetails();
	const { handleTextHighlight } = useHighlight();
	const { saveViewOnDemand } = useAnalytics(false);

	const handleClickOnLink = useCallback(
		link => {
			saveViewOnDemand({
				contentId: link.id || link.url,
				pageTitle: link.title,
				typeObject: analyticsObjectTypes.LINK,
				pageAuthor: currentType?.creator,
				pageBrands: currentType?.brands,
				pageRegions: currentType?.regions,
				pagePublishingDate: currentType?.creationDate,
			});
		},
		[currentType?.brands, currentType?.creationDate, currentType?.creator, currentType?.regions, saveViewOnDemand]
	);

	return (
		<ul className="ContentQuickSheetSpecification">
			{Array.isArray(linkArray) &&
				linkArray.map(link => {
					return (
						<li key={link.id || link.url}>
							<p>
								<a
									href={link.url}
									target="_blank"
									rel="noopener noreferrer"
									onClick={() => handleClickOnLink(link)}
								>
									<InnerHtml body={handleTextHighlight(link.title)} />
								</a>{' '}
								<InnerHtml body={handleTextHighlight(link.additionalText)} />
								<ButtonModalAnalytics contentId={link.id || link.url} />
							</p>
						</li>
					);
				})}
		</ul>
	);
};

ContentQuickSheetSpecification.propTypes = {
	linkArray: PropTypes.array,
};

ContentQuickSheetSpecification.defaultProps = {
	linkArray: [],
};

export { ContentQuickSheetSpecification };
