export const sizes = {
	LG: 1224,
	SD: 1024,
	MD: 880,
	SM: 768,
	XS: 450,
};

const breakPoints = Object.freeze({
	LG: `${sizes.LG}px`,
	SD: `${sizes.SD}px`,
	MD: `${sizes.MD}px`,
	SM: `${sizes.SM}px`,
	XS: `${sizes.XS}px`,
});

export const mediaQueryConstants = Object.freeze({
	MQ_DESKTOP: { query: `(min-device-width: ${breakPoints.LG})` },
	MQ_LAPTOP_OR_SMALLER: { query: `(max-width: ${breakPoints.SD})` },
	MQ_TABLET_OR_MOBILE: { query: `(max-width: ${breakPoints.MD})` },
	MQ_SMALL_OR_MOBILE: { query: `(max-width: ${breakPoints.SM})` },
	MQ_TABLET: { query: `(min-device-width: ${breakPoints.SM})` },
	MQ_MOBILE: { query: `(max-width: ${breakPoints.XS})` },
});
