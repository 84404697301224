import HyattLogo from 'assets/img/logos/hyatt.svg';
import HyattLogoSidebar from 'assets/img/logos/hyatt_sidebar.svg';
import complementaryTheme from 'themes/complementary/complementary';
import colors from './hyattColors';
import typographyTheme from './hyattTypo';

const logo = {
	footer: HyattLogo,
	loading: HyattLogo, // logo in the loading
	sidebar: HyattLogoSidebar,
	mobile: HyattLogo, // logo in the navbar
	size: {
		sidebar: {
			width: '152px',
			height: '132px',
		},
		footer: {
			width: '100px',
			height: '20px',
		},
		navbar: {
			width: '150px',
			height: '125px',
		},
		loading: {
			width: '77px',
			height: '77px',
		},
	},
};

const hyatt = {
	brandId: 'hy',
	name: 'Hyatt',
	logo,
	color: colors,

	font: {
		family: {
			helveticaNeue: 'Helvetica Neue',
			gothamBook: 'Gotham Book',
			gothamLight: 'Gotham Light',
			arialSansSerif: 'Arial, Helvetica, sans-serif',
			poynterRegular: 'Poynter Regular',
			calibri: 'Calibri, Arial',
			bodoniBoldItalic: 'Bodoni Bold',
			robotoMonoRegular: 'RobotoMono Regular',
		},
	},
};

const hyattTheme = {
	...hyatt,
	...typographyTheme(hyatt),
	...complementaryTheme,
};

export default hyattTheme;
