import { DISCLAIMER_FIELD, EDITABLE_DISCLAIMER_TEST_ID } from 'containers/brandDetails/constants/modulesTypesConstants';
import { translations } from 'translation/en';
import { Disclaimer } from 'containers/brandDetails/components/brandMain/components/template/ModuleTemplate.style';
import { string, func } from 'prop-types';
import { EditableContentWrapper } from '../../editableContent';

const DisclaimerEditInLine = ({ disclaimer, setDisclaimer, path, ...props }) => {
	return (
		<Disclaimer>
			<EditableContentWrapper
				fieldName={DISCLAIMER_FIELD}
				placeholder={translations.INSERT_DISCLAIMER_HERE}
				content={disclaimer}
				setContent={setDisclaimer}
				modulePath={path}
				data-testid={EDITABLE_DISCLAIMER_TEST_ID}
				{...props}
			/>
		</Disclaimer>
	);
};

DisclaimerEditInLine.propTypes = {
	disclaimer: string,
	setDisclaimer: func,
	path: string,
};

DisclaimerEditInLine.defaultProps = {
	disclaimer: '',
	setDisclaimer: () => {},
	path: '',
};

export { DisclaimerEditInLine };
