import styled from 'styled-components/macro';

export const BrandHeader = styled.header(({ theme: { color, breakpoint } }) => ({
	display: 'flex',
	margin: '0 auto',
	padding: '10px',
	marginBottom: '20px',
	alignItems: 'center',
	width: '100%',
	height: '67px',

	...color.background.banner,

	[breakpoint.lg()]: {
		padding: 0,
		marginBottom: 0,
	},
}));

export const NavBar = styled.nav({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	width: '100%',
	height: '100%',
});

export const Actions = styled.div(({ theme: { breakpoint } }) => ({
	display: 'none',
	margin: 0,
	padding: '0 15px 0 0',
	flexShrink: 0,
	alignItems: 'center',
	justifyContent: 'center',
	width: 'fit-content',
	height: '100%',

	[breakpoint.lg()]: {
		display: 'flex',
	},
}));

export const SignOutBox = styled.div({
	display: 'flex',
	position: 'absolute',
	overflow: 'hidden',
	width: 'fit-content',
	maxHeight: 'inherit',
});

export const Label = styled.label(({ theme: { typo } }) => ({
	...typo.button['01'],
}));

export const Arrow = styled.span(({ theme: { color } }) => ({
	display: 'block',
	marginLeft: '13px',
	margin: '0 0 0 14px',
	height: '6px',

	borderTop: '5px dashed',
	borderRight: '5px solid transparent',
	borderLeft: '5px solid transparent',
	color: color.icon.primary,
}));
