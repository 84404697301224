import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useRedirectByBrandID } from './hooks/useRedirectByBrandID';

const RedirectByBrandID = () => {
	const history = useHistory();
	const to = useRedirectByBrandID();

	useEffect(() => {
		history.push(to);
	}, [history, to]);

	return null;
};

export { RedirectByBrandID };
