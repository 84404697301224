import { Button } from 'components/button';
import { Modal } from 'components/modals/modal';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { translations } from 'translation/en';
import * as S from './RemoveModal.style';

const RemoveModal = ({ open, title, onConfirm, onClose }) => {
	const [domReady, setDomReady] = useState(false);
	const portalElement = document.getElementById('root');

	useEffect(() => {
		setDomReady(true);

		return () => {
			setDomReady(false);
		};
	}, []);

	const handleConfirm = e => {
		e.preventDefault();
		onConfirm(e);
	};

	const handleClose = () => {
		onClose();
	};

	const modalComponent = open && (
		<S.RemoveModal open={open} onClick={handleClose} closeIcon={<div />}>
			<Modal.Main>
				<S.Lead>{title}</S.Lead>
			</Modal.Main>
			<Modal.Footer style={{ padding: '32px' }}>
				<Button variant="primary-03" onClick={handleConfirm}>
					{translations.CONFIRM}
				</Button>
				<Button variant="primary-03" onClick={handleClose}>
					{translations.CANCEL}
				</Button>
			</Modal.Footer>
		</S.RemoveModal>
	);

	return domReady ? ReactDOM.createPortal(modalComponent, portalElement) : null;
};

RemoveModal.propTypes = {
	title: PropTypes.string,
	open: PropTypes.bool,
	onConfirm: PropTypes.func,
	onClose: PropTypes.func,
};

RemoveModal.defaultProps = {
	title: '',
	open: false,
	onConfirm: () => {},
	onClose: () => {},
};

export { RemoveModal };
