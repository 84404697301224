import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getLocationData } from 'API/locationAPI';
import { getUserLoginInfo } from 'API/userAPI';
import { useUrlStorage } from 'hooks/useUrlStorage';
import { isExternalUser } from 'utils/externalUserUtil';
import { getJWTUser } from 'utils/jwtUtil';
import { logError } from 'utils/logUtil';
import * as actionCreators from 'actions/actionCreators';
import { redirectToLogin, redirectToUrl } from 'utils/redirectUtil';

export const useAppLoadData = () => {
	const { storedUrl } = useUrlStorage();
	const [isReady, setIsReady] = useState(false);
	const dispatch = useDispatch();

	const loadDataForExternalUser = useCallback(
		async externalUser => {
			await Promise.all([
				dispatch(actionCreators.authSetUser(externalUser)),
				dispatch(actionCreators.locationSetData(externalUser.locationObj)),
				dispatch(actionCreators.fetchSecuritySetGroupsAndDepartments()),
			]);
		},
		[dispatch]
	);

	const loadDataForCommonUser = useCallback(
		async jwtUser => {
			const { globalId, spiritCode } = jwtUser;

			dispatch(actionCreators.authSetUser(jwtUser));

			const [getUserResponse, getLocationDataResponse] = await Promise.all([
				getUserLoginInfo(globalId),
				getLocationData(spiritCode),
				dispatch(actionCreators.fetchSecuritySetGroupsAndDepartments()),
			]);

			dispatch(actionCreators.authSetUserDetail(getUserResponse));
			dispatch(actionCreators.locationSetData(getLocationDataResponse));
		},
		[dispatch]
	);

	const loadData = useCallback(async () => {
		try {
			setIsReady(false);

			const jwtUser = getJWTUser();

			if (!jwtUser) {
				redirectToLogin();
				return;
			}

			const loadFn = isExternalUser(jwtUser)
				? () => loadDataForExternalUser(jwtUser)
				: () => loadDataForCommonUser(jwtUser);

			await loadFn();

			if (storedUrl) {
				redirectToUrl(storedUrl);
			}
		} catch (e) {
			logError(e);
		} finally {
			setIsReady(true);
		}
	}, [storedUrl, loadDataForCommonUser, loadDataForExternalUser]);

	return {
		isReady,
		loadData,
	};
};
