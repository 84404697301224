import { string } from "prop-types";
import "./FormFieldDescription.scss";

const FormFieldDescription = ({ description }) => {
  return <div className="FormFieldDescription">{description}</div>;
};

FormFieldDescription.propTypes = {
  description: string,
};

FormFieldDescription.defaultProps = {
  description: "",
};

export default FormFieldDescription;
