import { func } from 'prop-types';
import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { DAY_VALUE, listMetrics } from '../../constants/modalAnalyticsConstants';
import { translation } from '../../constants/translation';
import { useAnalyticsContext } from '../../hooks/useAnalyticsContext';
import { endDateFormat, startDateFormat } from '../../utils/dateUtils';
import './Menu.scss';

export const Menu = ({ onClose }) => {
	const { setLocationType, locationType, getMetricsForChart } = useAnalyticsContext();
	const context = useFormContext();

	const { watch } = context;

	const startDateWatch = watch('startDate');
	const endDateWatch = watch('endDate');

	const submit = useCallback(
		type => {
			if (type.value === DAY_VALUE) {
				setLocationType(type);
				onClose();
				return false;
			}

			const params = {
				startDate: startDateFormat(startDateWatch),
				endDate: endDateFormat(endDateWatch),
				type: type.value,
			};

			getMetricsForChart(params);
			setLocationType(type);
			return onClose();
		},
		[endDateWatch, getMetricsForChart, onClose, setLocationType, startDateWatch]
	);

	return (
		<div className="Menu">
			<button onClick={onClose}>
				<i className="icon-close-rounded" />
			</button>

			<h2>{translation.METRICS_BY}</h2>

			<div className="Menu__items">
				{listMetrics.map(type => (
					<button key={type.value} onClick={() => submit(type)}>
						{type.text}
					</button>
				))}

				<button onClick={() => submit(locationType)}>{translation.REFRESH}</button>
			</div>
		</div>
	);
};

Menu.propTypes = {
	onClose: func.isRequired,
};
