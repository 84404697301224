import { useState } from 'react';
import PropTypes from 'prop-types';
import { checkMaxFiles } from 'utils/fileUtil';
import { generateDisplayOrder } from 'utils/contentFragmentUtil';
import { AddFileButton } from './components/AddFileButton';
import { FileMenu } from './components/FileMenu';
import { FileModal } from './components/FileModal';
import { FILE_TYPES } from './constants/addEditFilesConstants';
import * as S from './AddEditFiles.style';

const AddEditFiles = ({ show, paragraph, files, children, onAddFile }) => {
	const initialState = {
		data: {
			displayOrder: generateDisplayOrder(files),
			linkText: '',
			file: null,
		},
	};

	const [action, dispatch] = useState({
		type: FILE_TYPES.RESET,
	});

	const onOpenFileModal = () => {
		dispatch({
			type: FILE_TYPES.FILE_MODAL,
		});
	};

	const onClose = () => {
		dispatch({
			type: FILE_TYPES.CLOSE_MODAL,
		});
	};

	return (
		<div>
			{show ? (
				<S.Blockquote aria-hidden="true">
					{children}
					<AddFileButton onClick={onOpenFileModal} />
				</S.Blockquote>
			) : (
				<div>{children}</div>
			)}

			{action.type === FILE_TYPES.FILE_MODAL && (
				<FileModal
					open
					file={initialState.data}
					paragraph={paragraph}
					onSaveAsDraft={onAddFile}
					onClose={onClose}
					hasMaxNumberOfFiles={checkMaxFiles(children.length)}
				/>
			)}
		</div>
	);
};

AddEditFiles.propTypes = {
	show: PropTypes.bool,
	paragraph: PropTypes.shape({
		path: PropTypes.string,
	}).isRequired,
	files: PropTypes.array.isRequired,
	displayOrder: PropTypes.number.isRequired,
	children: PropTypes.node.isRequired,
	onAddFile: PropTypes.func.isRequired,
};

AddEditFiles.defaultProps = {
	show: false,
};

AddEditFiles.Menu = FileMenu;

export { AddEditFiles };
