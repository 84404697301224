import classNames from 'classnames';
import { arrayOf, bool, func, node, oneOfType, string } from 'prop-types';
import React, { useMemo, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import './ModalBoxAnalytics.scss';

export const ModalBoxAnalytics = ({ children, open, onClose, className, overlayClassName, hideExpand, large }) => {
	const [expanded, setExpanded] = useState(false);

	const modalClassNames = useMemo(
		() => ({
			modal: classNames(
				'ModalBoxAnalytics',
				{ 'ModalBoxAnalytics--expanded': expanded },
				{ 'ModalBoxAnalytics--large': large },
				className
			),
			overlay: classNames('ModalBoxAnalytics__overlay', overlayClassName),
		}),
		[expanded, large, className, overlayClassName]
	);

	return (
		<Modal
			open={open}
			onClose={onClose}
			closeIcon={<i className="ModalBoxAnalytics__icon icon-close-slim" />}
			center
			closeOnEsc
			classNames={modalClassNames}
		>
			{children}

			{!hideExpand && (
				<>
					<button className="ModalBoxAnalytics__full-size" onClick={() => setExpanded(prev => !prev)}>
						<i
							className={classNames('ModalBoxAnalytics__icon', [
								expanded ? 'icon-collapse-alt' : 'icon-expand-alt',
							])}
						/>
					</button>
					<span className="ModalBoxAnalytics__separator" />
				</>
			)}
		</Modal>
	);
};

ModalBoxAnalytics.propTypes = {
	children: oneOfType([arrayOf(node), node]),
	open: bool.isRequired,
	onClose: func.isRequired,
	className: string,
	overlayClassName: string,
	hideExpand: bool,
	large: bool,
};

ModalBoxAnalytics.defaultProps = {
	className: '',
	overlayClassName: '',
	children: null,
	hideExpand: false,
	large: false,
};
