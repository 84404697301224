import { getSecurityConfigs } from 'services/securityService';
import { SECURITY_SET_GROUPS_AND_DEPARTMENTS } from './actionTypes';

export const securitySetGroupsAndDepartments = ({ adGroups, adDepartments }) => ({
	type: SECURITY_SET_GROUPS_AND_DEPARTMENTS,
	payload: { adGroups, adDepartments },
});

export const fetchSecuritySetGroupsAndDepartments = () => async dispatch => {
	const { adGroups, adDepartments } = await getSecurityConfigs();
	dispatch(securitySetGroupsAndDepartments({ adGroups, adDepartments }));
};
