import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import useBrandData from 'hooks/useBrandData';
import useBrandDetails from 'hooks/useBrandDetails';
import { useSolrService } from 'hooks/useSolrService';
import { useFixModulesDisplayOrder } from 'hooks/useFixModulesDisplayOrder';
import { PAGE_TYPES } from 'constants/pageConstants';
import { insertParagraph, updateParagraph, deleteParagraph } from 'utils/paragraphUtil';

export const ParagraphsContext = createContext({});

const ParagraphsProvider = ({ children }) => {
	const initialState = useMemo(() => {
		return {
			paragraphs: null,
		};
	}, []);

	const { brandName } = useBrandData();
	const { resetSearchResult } = useSolrService();
	const { currentType, chapterParagraphs, sectionParagraphs, articleParagraphs } = useBrandDetails();
	const { fetchFixedDisplayOrder } = useFixModulesDisplayOrder();

	const [paragraphs, setParagraphs] = useState(initialState);
	const [isParagraphLoading, setIsParagraphLoading] = useState(false);

	const onStartedLoadingParagraphs = useCallback(() => {
		setIsParagraphLoading(true);
	}, [setIsParagraphLoading]);

	const onFinishedLoadingParagraphs = useCallback(() => {
		setIsParagraphLoading(false);
	}, [setIsParagraphLoading]);

	const handleParagraphs = useCallback(
		newParagraphs => {
			setParagraphs(() => ({
				paragraphs: newParagraphs,
			}));
		},
		[setParagraphs]
	);

	const handleSortParagraphs = useCallback(
		async newParagraphs => {
			const sortedParagraphs = await fetchFixedDisplayOrder(newParagraphs);
			handleParagraphs(sortedParagraphs);
		},
		[fetchFixedDisplayOrder, handleParagraphs]
	);

	const handleParagraphsByType = useCallback(
		async ({ type }) => {
			switch (type) {
				case PAGE_TYPES.CHAPTER_TYPE:
					await handleSortParagraphs(chapterParagraphs);
					break;
				case PAGE_TYPES.SECTION_TYPE:
					await handleSortParagraphs(sectionParagraphs);
					break;
				case PAGE_TYPES.ARTICLE_TYPE:
					await handleSortParagraphs(articleParagraphs);
					break;
				default:
					break;
			}
		},
		[chapterParagraphs, sectionParagraphs, articleParagraphs, handleSortParagraphs]
	);

	const handleAddParagraph = useCallback(
		payload => {
			const { position, ...paragraph } = payload;
			const newArray = insertParagraph(paragraphs?.paragraphs, paragraph, position);
			handleSortParagraphs(newArray);
		},
		[paragraphs, handleSortParagraphs]
	);

	const handleUpdateParagraph = useCallback(
		payload => {
			const updateParagraphs = updateParagraph(paragraphs?.paragraphs, payload);
			handleSortParagraphs(updateParagraphs);
		},
		[paragraphs?.paragraphs, handleSortParagraphs]
	);

	const handleSwapParagraph = useCallback(
		swappedParagraphs => {
			handleParagraphs(swappedParagraphs);
		},
		[handleParagraphs]
	);

	const handleDeleteParagraph = useCallback(
		payload => {
			const filteredArray = deleteParagraph(paragraphs?.paragraphs, payload);
			handleSortParagraphs(filteredArray);
		},
		[paragraphs?.paragraphs, handleSortParagraphs]
	);

	const onResetParagraphs = useCallback(() => {
		setParagraphs(initialState);
	}, [initialState]);

	useEffect(() => {
		if (brandName) {
			resetSearchResult();
			onResetParagraphs();
		}
	}, [brandName, onResetParagraphs, resetSearchResult]);

	useEffect(() => {
		if (currentType) {
			resetSearchResult();
			handleParagraphsByType({ type: currentType.type });
		}
	}, [currentType, handleParagraphsByType, resetSearchResult]);

	const valueProvider = useMemo(
		() => ({
			isParagraphLoading,
			paragraphs,

			setIsParagraphLoading,

			onStartedLoadingParagraphs,
			onFinishedLoadingParagraphs,
			onResetParagraphs,

			handleAddParagraph,
			handleUpdateParagraph,
			handleSwapParagraph,
			handleDeleteParagraph,

			handleParagraphs,
		}),
		[
			isParagraphLoading,
			paragraphs,

			setIsParagraphLoading,

			onStartedLoadingParagraphs,
			onFinishedLoadingParagraphs,
			onResetParagraphs,

			handleAddParagraph,
			handleUpdateParagraph,
			handleSwapParagraph,
			handleDeleteParagraph,

			handleParagraphs,
		]
	);

	return <ParagraphsContext.Provider value={valueProvider}>{children}</ParagraphsContext.Provider>;
};

ParagraphsProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export { ParagraphsProvider };
