import { add, endOfDay } from 'date-fns';
import { LOCATION_REGION } from '../constants/modalAnalyticsConstants';

export const getFormDefaultValues = () => {
	return {
		startDate: add(endOfDay(new Date()), { days: -30 }),
		endDate: new Date(),
		type: LOCATION_REGION,
	};
};
