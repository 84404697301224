import styled from 'styled-components/macro';

export const AddModuleContainer = styled.div(({ theme: { breakpoint }, open }) => ({
	position: 'relative',
	flexDirection: 'column',
	paddingBottom: '70px',
	alignItems: 'center',
	justifyContent: 'center',
	display: 'none',

	[breakpoint.md()]: {
		display: open ? 'flex' : 'none',
	},
}));

export const ModuleLine = styled.div({
	display: 'inline-block',
	position: 'relative',
	top: '20px',
	float: 'left',
	height: '1px',
	width: '100%',
	background: '#f0f0f0',
});
