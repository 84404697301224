/* eslint-disable react/prop-types */
import { Footer } from 'containers/chooseBrand/components/footer';
import { Header } from 'containers/chooseBrand/components/header';
import { ErrorPage } from 'containers/errorPage';

const ErrorBoundaryComponent = ({ error, componentStack, resetErrorBoundary }) => {
	return (
		<div>
			<Header />
			<ErrorPage message={error.message} onButtonClick={resetErrorBoundary}>
				{componentStack && <details style={{ whiteSpace: 'pre-wrap' }}>{componentStack}</details>}
			</ErrorPage>
			<Footer />
		</div>
	);
};

export default ErrorBoundaryComponent;
