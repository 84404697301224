import { useMemo } from 'react';
import { useRouteMatch } from 'react-router';
import { routersList } from 'router/router';

export const useCurrentRouteInstance = () => {
	const match = useRouteMatch();

	return useMemo(
		() =>
			routersList.find(item => {
				return item.getRoutePath() === match.path || item.getRouteDefaultPath() === match.path;
			}),
		[match.path]
	);
};
