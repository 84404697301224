import { useCallback } from 'react';
import { showToast } from 'utils/toastUtil';
import { translations } from 'translation/en';
import { PARAGRAPH_PROPS } from 'constants/paragraphConstants';
import useBrandDetails from './useBrandDetails';
import useApprovePublish from './useApprovePublish';
import { useDeleteContentFragment } from './useDeleteContentFragment';
import { useDisapprovePage } from './useDisapprovePage';

export const useDeleteImage = () => {
	const { currentType } = useBrandDetails();
	const {
		loading: loadingDelete,
		response,
		error,
		removeContentFragment,
	} = useDeleteContentFragment({
		successMessage: translations.IMAGE_DELETED_SUCCESS,
		deleteMessage: translations.FAILED_DELETE_IMAGE,
		handleError: true,
	});

	const { isLoadingDisapprovePage, disapprovePage } = useDisapprovePage();
	const { isPageApproved } = useApprovePublish();

	const deleteImage = useCallback(
		async ({ contentPath }) => {
			return removeContentFragment({
				pagePath: currentType?.path,
				cfType: PARAGRAPH_PROPS.RELATOR_IMAGE_KEY,
				data: { contentPath, approved: false },
			})
				.then(result => {
					if (isPageApproved && !result?.error) {
						disapprovePage([currentType]);
					}
					return result;
				})
				.catch(() => {
					showToast({
						message: translations.ERROR_DELETING_IMAGE,
						type: 'error',
					});
				});
		},
		[removeContentFragment, currentType, isPageApproved, disapprovePage]
	);

	return { loadingDelete: [loadingDelete, isLoadingDisapprovePage].some(Boolean), response, error, deleteImage };
};
