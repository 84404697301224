import { BRAND_NAME, LOCATION_TYPE_NAME, REGION_NAME, ROLE_NAME } from '../constants/modalAnalyticsConstants';

export const categoriesLocationType = locationType => {
	if (locationType === REGION_NAME) {
		return [
			{ label: 'Americas', abbr: ['AM'] },
			{ label: 'ASPAC', abbr: ['AP'] },
			{ label: 'EAME', abbr: ['EA'] },
			{ label: 'Corporate', abbr: [null, ''] },
		];
	}

	if (locationType === LOCATION_TYPE_NAME) {
		return [
			{ label: 'Hotel', abbr: ['HOTEL'] },
			{ label: 'Office', abbr: ['OFFICE'] },
			{ label: 'Other', abbr: [null, ''] },
		];
	}

	if (locationType === BRAND_NAME) {
		return [
			{ label: 'Andaz', abbr: ['AZ'] },
			{ label: 'Alila', abbr: ['AL'] },
			{ label: 'Destination', abbr: ['DH'] },
			{ label: 'Exhale', abbr: ['EX'] },
			{ label: 'Joie de Vivre', abbr: ['JD'] },
			{ label: 'Thompson', abbr: ['TH'] },
			{ label: 'Miraval', abbr: ['MV'] },
			{ label: 'Hyatt Centric', abbr: ['HC'] },
			{ label: 'Hyatt', abbr: ['HY'] },
			{ label: 'Hyatt Place', abbr: ['HP'] },
			{ label: 'Ziva', abbr: ['ZV'] },
			{ label: 'Unbound Collection', abbr: ['UB'] },
			{ label: 'Hyatt Regency', abbr: ['HR'] },
			{ label: 'Zilara', abbr: ['ZL'] },
			{ label: 'Grand Hyatt', abbr: ['GH'] },
			{ label: 'Hyatt House', abbr: ['HH'] },
			{ label: 'Park Hyatt', abbr: ['PH'] },
			{ label: 'Corporate Office', abbr: ['71'] },
			{ label: 'No Brand', abbr: [null, ''] },
		];
	}

	if (locationType === ROLE_NAME) {
		return [
			{ label: 'General Manager', abbr: ['4', '16'] },
			{ label: 'Leadership Committee', abbr: ['5'] },
			{ label: 'Department Head', abbr: ['7'] },
			{ label: 'Manager', abbr: ['6', '8', '9', '10', '11', '23'] },
			{ label: 'Admin', abbr: ['12'] },
			{ label: 'Non-mgmt', abbr: ['13'] },
			{ label: 'Corporate VP and above', abbr: ['0', '21', '17', '18', '19'] },
			{ label: 'Corporate Director', abbr: ['1'] },
			{ label: 'Corporate Manager', abbr: ['2'] },
			{ label: 'Corporate Other', abbr: ['3', '14', '20', '24'] },
		];
	}

	return [];
};

export const locationGroup = [
	{
		name: 'Full Service',
		abbr: ['AZ', 'AL', 'DH', 'GH', 'JD', 'HY', 'HC', 'HR', 'PH', 'TH', 'UB', 'OB'],
	},
	{ name: 'Select Service', abbr: ['HP', 'HH', 'CP', 'UR'] },
	{ name: 'Hotel', abbr: ['HOTEL'] },
	{ name: 'Corporate', abbr: ['OFFICE'] },
];
