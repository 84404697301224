import { UserModel } from 'models/UserModel';
import axios from './axios/axios';

export const getUserLoginInfo = globalId => {
	const url = `${process.env.REACT_APP_API_URL}/v1/users/login/${globalId}`;
	return axios.get(url).then(response => new UserModel(response.data || {}));
};

export const getUser = globalId => {
	const url = `${process.env.REACT_APP_API_URL}/v1/users/${globalId}`;
	return axios.get(url).then(response => new UserModel(response.data || {}));
};

export const getUsers = data => {
	const url = `${process.env.REACT_APP_API_URL}/v1/users`;
	return axios.get(url, {
		params: { ...data },
	});
};
