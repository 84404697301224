import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import './ModulesLoading.scss';
import { Loading } from 'components/loadingOverlay';

const ModulesLoading = ({ isLoading }) => {
	const { logo } = useTheme();
	return (
		isLoading && (
			<div className="ModulesLoading">
				<div className="ModulesLoading__container">
					<Loading.LoadingLogo logo={logo?.loading} size={logo?.size.loading} />
				</div>
			</div>
		)
	);
};

ModulesLoading.propTypes = {
	isLoading: PropTypes.bool,
};

ModulesLoading.defaultProps = {
	isLoading: false,
};

export { ModulesLoading };
