import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { translations } from 'translation/en';
import { Collapse } from 'containers/brandDetails/components/brandMain/components/collapse';
import { SpecificationLabel, SpecificationWrapper } from './QuickSheetSpecificationDropdown.style';
import { ContentQuickSheetSpecification } from './contentQuickSheetSpecification/ContentQuickSheetSpecification';

const QuickSheetSpecificationDropdown = ({ content }) => {
	const { color } = useTheme();

	return (
		<SpecificationWrapper>
			<Collapse
				label={<SpecificationLabel>{translations.RESOURCES_TEXT}</SpecificationLabel>}
				icon="icon-chevron-down"
				inverted
				wrapperStyle={{
					display: 'flex',
					alignItems: 'baseline',
					padding: 0,
					gap: '10px',
					color: color.text.specificationTitle,
				}}
				initialOpen
			>
				<ContentQuickSheetSpecification linkArray={content} />
			</Collapse>
		</SpecificationWrapper>
	);
};

QuickSheetSpecificationDropdown.propTypes = {
	content: PropTypes.array,
};

QuickSheetSpecificationDropdown.defaultProps = {
	content: [],
};

export { QuickSheetSpecificationDropdown };
