import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { Button } from 'components/button';
import { Loading } from 'components/loadingOverlay';
import { Modal } from 'components/modals/modal';
import { useUpdateFile } from 'hooks/useUpdateFile';
import { useUploadFile } from 'hooks/useUploadFile';
import useClearCache from 'hooks/useClearCache';
import { fromFileService } from 'services/assetService';
import { logError } from 'utils/logUtil';
import { getOverflow } from 'utils/overflowUtil';
import { showToast } from 'utils/toastUtil';
import { translations } from 'translation/en';
import * as S from './FileModal.style';

const FileModal = ({ open, paragraph, file, hasMaxNumberOfFiles, onSaveAsDraft, onClose }) => {
	const { logo } = useTheme();
	const { clearPageCache } = useClearCache();

	const portalElement = document.getElementById('root');
	const fileInputRef = useRef(null);

	const [domReady, setDomReady] = useState();
	const [fileData, setFileData] = useState(file);

	const { loadingUpload, uploadFile } = useUploadFile();
	const { loadingUpdate, updateFile } = useUpdateFile();

	const isLoadingUploadFile = [loadingUpload, loadingUpdate].some(Boolean);
	const isSaveButtonDisabled = [fileData.linkText, fileData.file].every(Boolean);

	useEffect(() => {
		setDomReady(true);
		const currentOverflowY = portalElement?.style.overflowY;
		if (portalElement) portalElement.style.overflowY = getOverflow(open);

		return () => {
			setDomReady(false);
			if (portalElement) portalElement.style.overflow = currentOverflowY;
		};
	}, [open, portalElement]);

	const onClearCache = async () => {
		try {
			clearPageCache();
		} catch (error) {
			logError(error.message);
		}
	};

	const onResetFile = e => {
		e.preventDefault();
		fileInputRef.current = null;
		setFileData(prev => ({
			...prev,
			file: null,
		}));
	};

	const handleInputValue = e => {
		e.preventDefault();
		setFileData(prev => ({
			...prev,
			linkText: e.target.value,
		}));
	};

	const handleChooseFile = e => {
		e.preventDefault();
		fileInputRef.current.click();
	};

	const handleChange = e => {
		e.preventDefault();

		const asset = e.target.files[0];
		setFileData(prev => ({
			...prev,
			file: asset,
		}));
	};

	const onUploadFile = () => {
		uploadFile({
			fileData,
			relatedPath: paragraph.path,
		}).then(async ([response]) => {
			if (response?.data) {
				if (!response.data?.body?.message) {
					const payloadFile = fromFileService(response.data.body);
					onSaveAsDraft(payloadFile);
					onClearCache();
					onClose();
				} else {
					showToast({
						type: 'error',
						message: response.data?.body?.message,
					});
				}
			}
		});
	};

	const onUpdateFile = () => {
		updateFile({
			fileData,
			relatedPath: paragraph.path,
		}).then(async ([response]) => {
			if (response?.data) {
				if (!response.data?.body?.message) {
					const payloadFile = fromFileService(response.data.body);
					const payload = { ...fileData, ...payloadFile, vcmId: fileData.vcmId };
					onSaveAsDraft(payload);
					onClearCache();
					onClose();
				} else {
					showToast({
						type: 'error',
						message: response.data?.body?.message,
					});
				}
			}
		});
	};

	const handleSaveAsDraft = () => {
		if (hasMaxNumberOfFiles) {
			showToast({
				type: 'error',
				message: translations.ERROR_FILES_LIMIT,
			});
			return;
		}

		if (fileData.vcmId) {
			onUpdateFile();
		} else {
			onUploadFile();
		}
	};

	const modalComponent = open && (
		<S.FileModal
			open={open}
			overlayClassName="ModalBox__white-overlay"
			containerStyle={{ borderTop: 'none', paddingTop: '10px' }}
			onClose={onClose}
			closeIcon={<i className="icon-close-slim" />}
			hideExpand
		>
			{isLoadingUploadFile && (
				<Loading.Overlay>
					<Loading.LoadingLogo logo={logo?.loading} />
				</Loading.Overlay>
			)}

			<Modal.Header role="heading" variant="h2" showDivider={false}>
				{translations.MODAL_FILE_TITLE}
			</Modal.Header>

			<S.FileModalMain>
				<S.Box>
					<S.Row>
						<S.Column>
							<S.Label>{translations.TITLE_SECTION_BUTTON}</S.Label>
							<S.NewButton variant="primary-05">{fileData.linkText || translations.LABEL_BUTTON}</S.NewButton>
						</S.Column>
					</S.Row>
					<S.Row>
						<S.Column>
							<S.Label>{translations.TITLE_SECTION_FIELD}</S.Label>
							<S.Input
								value={fileData.linkText}
								placeholder={translations.TEXT_PLACEHOLDER_NAME_BUTTON}
								onChange={handleInputValue}
							/>
						</S.Column>
					</S.Row>
					<S.Row>
						<S.Column>
							<S.Label>{translations.TITLE_SECTION_FILE}</S.Label>
							{fileData?.file?.name ? (
								<S.FileGroup>
									<S.DeleteButton onClick={onResetFile}>
										<i className="icon icon-delete-circle" />
									</S.DeleteButton>
									<S.FileNameLabel>{fileData?.file?.name}</S.FileNameLabel>
								</S.FileGroup>
							) : (
								<div>
									<input hidden ref={fileInputRef} type="file" onChange={handleChange} />
									<S.ChooseFileButton onClick={handleChooseFile}>
										<i className="icon icon-file-browse" />
										{translations.LABEL_FILE_BUTTON}
									</S.ChooseFileButton>
								</div>
							)}
						</S.Column>
					</S.Row>
				</S.Box>
			</S.FileModalMain>

			<Modal.Footer>
				<Button variant="gray-border-only" onClick={onClose}>
					{translations.CANCEL}
				</Button>
				<Button
					variant="tertiary-03"
					title={translations.SAVE_AS_DRAFT}
					disabled={!isSaveButtonDisabled}
					onClick={handleSaveAsDraft}
				>
					{translations.SAVE_AS_DRAFT}
				</Button>
			</Modal.Footer>
		</S.FileModal>
	);

	return domReady ? ReactDOM.createPortal(modalComponent, portalElement) : null;
};

FileModal.propTypes = {
	open: PropTypes.bool,
	paragraph: PropTypes.shape({
		path: PropTypes.string,
	}),
	file: PropTypes.shape({}),
	hasMaxNumberOfFiles: PropTypes.bool,
	onSaveAsDraft: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
};

FileModal.defaultProps = {
	open: false,
	paragraph: {
		path: '',
	},
	file: {},
	hasMaxNumberOfFiles: false,
};

export { FileModal };
