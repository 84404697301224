import { useEffect, useMemo } from 'react';
import { ModulesSwitch } from 'containers/brandDetails/components/brandMain/components/modules/ModulesSwitch';
import { PrintPageTemplate } from 'containers/printPageTemplate';
import { useArticleByPath } from 'hooks/useArticleByPath';
import { useQueryParams } from 'hooks/useQueryParams';
import { PrintPageSkeleton } from 'components/loaders/printPageSkeleton';
import { useRowsPrintByQuickSheet } from 'hooks/useRowsPrintByQuickSheet';
import { isFirstParagraph } from 'utils/paragraphUtil';

const ArticlePrintPage = () => {
	const { getQueryParam } = useQueryParams();
	const { loadingArticle, article: articleData, fetchArticle } = useArticleByPath();
	const { loadingRows, rowsArticle, fetchRows } = useRowsPrintByQuickSheet();

	const path = getQueryParam('path');
	const quickSheetPath = getQueryParam('quicksheet-path');
	const quickSheetOperation = getQueryParam('quicksheet-operation');
	const quickSheetTopic = getQueryParam('quicksheet-topic');

	useEffect(() => {
		if (path) {
			fetchArticle({ path });
		}
	}, [path, fetchArticle]);

	useEffect(() => {
		if (quickSheetPath) {
			fetchRows({
				path: quickSheetPath,
				operation: quickSheetOperation,
				topic: quickSheetTopic,
			});
		}
	}, [quickSheetPath, quickSheetOperation, quickSheetTopic, fetchRows]);

	const article = useMemo(() => {
		if (rowsArticle) {
			return rowsArticle;
		}

		return articleData;
	}, [articleData, rowsArticle]);

	if ([loadingArticle, loadingRows].some(Boolean)) {
		return <PrintPageSkeleton />;
	}

	return (
		<PrintPageTemplate
			content={{ sections: [{ articles: [{ articleTitle: article?.title, paragraphs: article?.paragraphs }] }] }}
			title={article?.title}
		>
			{article?.paragraphs
				?.sort((a, b) => +a.displayOrder - +b.displayOrder)
				?.map(articleParagraph => {
					return (
						<ModulesSwitch
							paragraph={articleParagraph}
							key={articleParagraph.vcmId}
							id={articleParagraph.vcmId}
							type={articleParagraph.type}
							paragraphId={articleParagraph.vcmId}
							startTitle={articleParagraph.title}
							startDescription={articleParagraph.body}
							startSpecification={articleParagraph.specification}
							path={articleParagraph.path}
							files={articleParagraph.files}
							keywords={articleParagraph.keywords}
							orderValue={articleParagraph + 2}
							imageData={articleParagraph.imageData}
							startIsDropdown={false}
							isFirst={isFirstParagraph(article?.paragraphs, articleParagraph)}
							isLast={false}
							isOnPrintPage
						/>
					);
				})}
		</PrintPageTemplate>
	);
};

ArticlePrintPage.propTypes = {};

export { ArticlePrintPage };
