import { bool, func, object, string } from "prop-types";
import { forwardRef, useState } from "react";
import { Calendar as DateRangeCalendar } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./Calendar.scss";
import { convertToUtc, getFormattedDate } from "./utils/dateUtils";

const Calendar = forwardRef(
  (
    { dateValue, handleDateChange, isVisible, minDate, maxDate, disabledDay },
    ref
  ) => {
    const [date, setDate] = useState(() => {
      if (!dateValue) return new Date();

      return convertToUtc(new Date(dateValue));
    });

    return (
      isVisible && (
        <div ref={ref} className="Calendar">
          <DateRangeCalendar
            onChange={(item) => {
              setDate(item);
              handleDateChange(getFormattedDate(item, "yyyy-MM-dd"));
            }}
            date={date}
            color="#0085ce"
            minDate={minDate}
            maxDate={maxDate}
            editableDateInputs
            disabledDay={disabledDay}
          />
        </div>
      )
    );
  }
);

Calendar.propTypes = {
  dateValue: string,
  handleDateChange: func.isRequired,
  isVisible: bool.isRequired,
  minDate: object,
  maxDate: object,
  disabledDay: func,
};

Calendar.defaultProps = {
  dateValue: "",
  isVisible: false,
  minDate: new Date(),
  maxDate: undefined,
  disabledDay: () => false,
};

export { Calendar };
