export class RouteModel {
	constructor({ path, defaultPath, pageTitle, key, layout, header, footer, component }) {
		this.path = path;
		this.defaultPath = defaultPath;
		this.pageTitle = pageTitle;
		this.key = key;
		this.layout = layout;
		this.component = component;
		this.header = header;
		this.footer = footer;
	}

	getRoutePath() {
		return this.path;
	}

	getRouteDefaultPath() {
		return this.defaultPath;
	}

	renderComponent(options) {
		return this.component(options);
	}
}
