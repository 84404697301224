import { zIndex } from 'constants/zIndexConstants';
import styled from 'styled-components/macro';

export const Container = styled.div(({ theme: { color, border } }) => ({
	display: 'flex',
	position: 'relative',
	justifyContent: 'center',
	textAlign: 'center',

	border: border.width.none,
	background: color.interactive.secondary02.default?.background,
	width: '160px',
	maxHeight: '51px',
	zIndex: zIndex.SIGNOUT_BUTTON,
}));
