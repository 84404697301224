import { useTheme } from 'styled-components';
import { string } from 'prop-types';
import { Collapse } from 'containers/brandDetails/components/brandMain/components/collapse';
import { SPECIFICATION_FIELD } from 'containers/brandDetails/constants/modulesTypesConstants';
import { translations } from 'translation/en';
import { EditableContentWrapper } from '../editableContent';
import { SpecificationLabel } from './SpecificationDropDown.style';

const SpecificationDropDown = ({ content, ...props }) => {
	const { color } = useTheme();

	return (
		<div style={{ margin: '20px 0px' }}>
			<Collapse
				label={<SpecificationLabel>{translations.SPECIFICATIONS_TEXT}</SpecificationLabel>}
				icon="icon-chevron-down"
				inverted
				wrapperStyle={{
					display: 'flex',
					alignItems: 'baseline',
					padding: 0,
					gap: '10px',
					color: color.text.specificationTitle,
				}}
			>
				<EditableContentWrapper
					style={{ margin: '10px', padding: '0 0 0 18px' }}
					fieldName={SPECIFICATION_FIELD}
					placeholder={translations.INSERT_SPECIFICATION_HERE}
					content={content}
					{...props}
				/>
			</Collapse>
		</div>
	);
};

SpecificationDropDown.propTypes = {
	content: string,
};

SpecificationDropDown.defaultProps = {
	content: '',
};

export { SpecificationDropDown };
