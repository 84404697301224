import classNames from 'classnames';
import { arrayOf, bool, node, oneOfType, string } from 'prop-types';
import React from 'react';
import './FormFieldGroup.scss';

export const FormFieldGroup = ({ children, flex, className }) => {
  return (
    <div
      className={classNames(
        'FormFieldGroup',
        {
          'FormFieldGroup--flex': flex,
        },
        className
      )}
    >
      {children}
    </div>
  );
};

FormFieldGroup.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  className: string,
  flex: bool,
};

FormFieldGroup.defaultProps = {
  className: '',
  flex: false,
};
