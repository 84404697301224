import classNames from 'classnames';
import { bool, func } from 'prop-types';
import { translations } from 'translation/en';
import { useBrands } from 'hooks/useBrands';
import { ChooseBrandsSkeleton } from 'components/loaders/chooseBrandsSkeleton/ChooseBrandsSkeleton';
import useBrandDetails from 'hooks/useBrandDetails';
import { BrandsSection } from './brandsSection';
import { BRANDS_BOX_TEST_IDS } from './constants/brandsBoxConstants';

import './BrandsBox.scss';

const BrandsBox = ({ hasMargin, handleClose }) => {
	const { handleSelectedBrand } = useBrandDetails();
	const { brands, loading } = useBrands();

	const brandsBoxClassNames = classNames('BrandsBox__box-guides', {
		'BrandsBox__box-guides--has-margin': hasMargin,
	});

	return (
		<main className="BrandsBox">
			{loading && <ChooseBrandsSkeleton />}

			{!loading && (
				<section className={brandsBoxClassNames}>
					<div style={{ display: 'inline-flex' }}>
						<div className="BrandsBox__title-box" role={BRANDS_BOX_TEST_IDS.TITLE}>
							<span className="BrandsBox__title">{translations.TITLE_BOX}</span>
						</div>
					</div>

					<div className="BrandsBox__box-brands" role={BRANDS_BOX_TEST_IDS.CONTENT}>
						{brands.map(collection => {
							return (
								<BrandsSection
									key={collection.collectionName}
									title={collection.title}
									items={collection}
									onSelected={handleSelectedBrand}
									onClose={handleClose}
								/>
							);
						})}
					</div>
				</section>
			)}
		</main>
	);
};

BrandsBox.propTypes = {
	hasMargin: bool,
	handleClose: func,
};

BrandsBox.defaultProps = {
	hasMargin: false,
	handleClose: () => {},
};

export { BrandsBox };
