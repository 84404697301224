import React, { useEffect, useMemo } from 'react';
import { ModulesSwitch } from 'containers/brandDetails/components/brandMain/components/modules/ModulesSwitch';
import { PrintPageTemplate } from 'containers/printPageTemplate';
import { useParagraphsChapter } from 'hooks/useParagraphsByChapter';
import { useQueryParams } from 'hooks/useQueryParams';
import { useRowsPrintByQuickSheet } from 'hooks/useRowsPrintByQuickSheet';
import { PrintPageSkeleton } from 'components/loaders/printPageSkeleton';
import { isFirstParagraph, hasParagraph, hasContentOnSection } from 'utils/paragraphUtil';
import * as S from './ChapterPrintPage.style';

const ChapterPrintPage = () => {
	const { getQueryParam } = useQueryParams();
	const { loadingParagraphs, paragraphs: paragraphsData, fetchParagraphsByChapter } = useParagraphsChapter();
	const { loadingRows, rowsChapter, fetchRows } = useRowsPrintByQuickSheet();

	const path = getQueryParam('path');
	const quickSheetPath = getQueryParam('quicksheet-path');
	const quickSheetChapter = getQueryParam('quicksheet-chapter');

	useEffect(() => {
		if (path) {
			fetchParagraphsByChapter({ path });
		}
	}, [path, fetchParagraphsByChapter]);

	useEffect(() => {
		if (quickSheetPath) {
			fetchRows({
				path: quickSheetPath,
			});
		}
	}, [quickSheetPath, fetchRows]);

	const paragraphs = useMemo(() => {
		if (rowsChapter) {
			return [rowsChapter];
		}

		return paragraphsData;
	}, [paragraphsData, rowsChapter]);

	if ([loadingParagraphs, loadingRows].some(Boolean)) {
		return <PrintPageSkeleton />;
	}

	return (
		<S.ChapterPrintPageWrapper>
			<PrintPageTemplate content={paragraphs?.[0]?.chapter} title={quickSheetChapter ?? paragraphs?.[0]?.chapter?.title}>
				{paragraphs?.[0]?.chapter?.paragraphs
					?.sort((a, b) => +a.displayOrder - +b.displayOrder)
					.map(chapterParagraph => {
						return (
							<ModulesSwitch
								paragraph={chapterParagraph}
								key={chapterParagraph.vcmId}
								id={chapterParagraph.vcmId}
								paragraphId={chapterParagraph.vcmId}
								type={chapterParagraph.type}
								startTitle={chapterParagraph.title}
								startDescription={chapterParagraph.body}
								startSpecification={chapterParagraph.specification}
								path={chapterParagraph.path}
								keywords={chapterParagraph.keywords}
								orderValue={chapterParagraph?.displayOrder}
								imageData={chapterParagraph.imageData}
								startIsDropdown={false}
								files={chapterParagraph.files}
								isFirst={isFirstParagraph(paragraphs?.[0]?.chapter?.paragraphs, chapterParagraph)}
								isLast={paragraphs?.[0]?.chapter?.paragraphs?.length <= chapterParagraph?.displayOrder}
								isOnPrintPage
							/>
						);
					})}
				{paragraphs?.[0]?.chapter?.sections
					?.sort((a, b) => +a.sectionDisplayOrder - +b.sectionDisplayOrder)
					?.map(sectionsContent => {
						return (
							<React.Fragment key={sectionsContent.sectionTitle}>
								{(hasContentOnSection(sectionsContent) || !rowsChapter) && (
									<S.ContainerSection>
										<S.Title>{sectionsContent.sectionTitle}</S.Title>
										{sectionsContent.paragraphs
											?.sort((a, b) => +a.displayOrder - +b.displayOrder)
											.map(sectionParagraph => {
												return (
													<ModulesSwitch
														paragraph={sectionParagraph}
														key={sectionParagraph.vcmId}
														id={sectionParagraph.vcmId}
														paragraphId={sectionParagraph.vcmId}
														type={sectionParagraph.type}
														startTitle={sectionParagraph.title}
														startDescription={sectionParagraph.body}
														startSpecification={sectionParagraph.specification}
														path={sectionParagraph.path}
														keywords={sectionParagraph.keywords}
														orderValue={sectionParagraph.displayOrder}
														imageData={sectionParagraph.imageData}
														startIsDropdown={false}
														files={sectionParagraph.files}
														isFirst={isFirstParagraph(sectionsContent.paragraphs, sectionParagraph)}
														isLast={false}
														isOnPrintPage
													/>
												);
											})}
									</S.ContainerSection>
								)}
								{sectionsContent?.articles
									?.sort((a, b) => +a.articleDisplayOrder - +b.articleDisplayOrder)
									?.map(article => {
										return (
											<React.Fragment key={article?.articleTitle}>
												{(hasParagraph(article) || !rowsChapter) && (
													<S.ContainerArticle>
														<S.Title>{article?.articleTitle}</S.Title>
														{article?.paragraphs
															?.sort((a, b) => +a.displayOrder - +b.displayOrder)
															.map(articleParagraph => {
																return (
																	<ModulesSwitch
																		paragraph={articleParagraph}
																		key={articleParagraph.vcmId}
																		id={articleParagraph.vcmId}
																		paragraphId={articleParagraph.vcmId}
																		type={articleParagraph.type}
																		startTitle={articleParagraph.title}
																		startDescription={articleParagraph.body}
																		startSpecification={articleParagraph.specification}
																		path={articleParagraph.path}
																		keywords={articleParagraph.keywords}
																		orderValue={articleParagraph.displayOrder}
																		imageData={articleParagraph.imageData}
																		startIsDropdown={false}
																		files={articleParagraph.files}
																		isFirst={isFirstParagraph(
																			article?.paragraphs,
																			articleParagraph
																		)}
																		isLast={false}
																		isOnPrintPage
																	/>
																);
															})}
													</S.ContainerArticle>
												)}
											</React.Fragment>
										);
									})}
							</React.Fragment>
						);
					})}
			</PrintPageTemplate>
		</S.ChapterPrintPageWrapper>
	);
};

ChapterPrintPage.propTypes = {};

export { ChapterPrintPage };
