import { FormFieldGroup } from '@hyatt/form-field';
import styled from 'styled-components/macro';

export const OverrideSecurityOptionsContainer = styled(FormFieldGroup)(({ theme: { color } }) => ({
	display: 'flex',
	alignItems: 'center',
	gap: '10px',

	'.icon': {
		color: color.background.secondary,
	},
}));
