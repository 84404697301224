import { Main, Modal } from 'components/modals/modal/Modal.style';
import styled from 'styled-components/macro';

export const PropertyModal = styled(Modal)(() => ({
	maxWidth: '900px',
}));

export const ModalMain = styled(Main)(() => ({
	overflow: 'auto',

	'::-webkit-scrollbar': {
		width: '5px',
		height: '5px',
		borderRadius: '10px',
		background: '#f0f0f0',
	},

	'::-webkit-scrollbar-thumb': {
		borderRadius: '20px',
		background: '#b4b4b4',
	},

	'::-webkit-scrollbar-track': {
		background: 'rgba(0, 0, 0, 0.1)',
	},
}));

export const Title = styled.label(({ theme: { typo, color } }) => ({
	display: 'block',
	padding: 0,

	color: color.text.quinary02,
	...typo.caption['02'],
}));

export const Keywords = styled.label(({ theme: { typo, color } }) => ({
	display: 'block',
	margin: 0,
	paddingBottom: '0.5rem',

	color: color.text.quinary02,
	...typo.caption['02'],
}));
