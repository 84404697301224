import { clearMapsDeliveryService, clearMapsService } from 'services/cacheServices';
import { translations } from 'translation/en';
import { logError } from './logUtil';
import { showToast } from './toastUtil';

const afterSuccessClear = (message, onSuccess, shouldShowToast = false) => {
	if (shouldShowToast) {
		showToast({
			type: 'success',
			message,
			config: { toastId: 'clear-cache-success' },
		});
	}
	onSuccess?.();
};

const afterErrorClear = () => {
	showToast({ type: 'error', message: translations.CLEAR_CACHE_ERROR, config: { toastId: 'clear-cache-fail' } });
};

export const handleClearCache = async ({ maps, onSuccess, setLoading, shouldShowToast = false }) => {
	try {
		setLoading(true);
		await clearMapsService(maps);
		afterSuccessClear(translations.CLEAR_CACHE_SUCCESS, onSuccess, shouldShowToast);
	} catch (e) {
		logError(e);
		afterErrorClear();
	} finally {
		setLoading(false);
	}
};

export const handleClearDeliveryCache = async ({ maps, onSuccess, setLoading, shouldShowToast = true }) => {
	try {
		setLoading(true);
		await clearMapsDeliveryService(maps);
		afterSuccessClear(translations.CLEAR_CACHE_SUCCESS, onSuccess, shouldShowToast);
	} catch (e) {
		logError(e);
		afterErrorClear();
	} finally {
		setLoading(false);
	}
};
