import styled from 'styled-components/macro';
import { NavLink as RouterLink } from 'react-router-dom';

export const SearchResultsWrapper = styled.div(() => ({
	marginTop: '20px',
	padding: '0px 15px 0px 45px',
	alignItems: 'left',
	justifyContent: 'left',
	width: '100%',
}));

export const AsideBrands = styled.aside(() => ({
	top: '-67px',
	position: 'relative',
	height: 'fit-content',
}));

export const SearchPageTitle = styled.h3(({ theme: { color, typo } }) => ({
	display: 'block',
	width: '100%',
	margin: '15px 0 !important',
	color: color.text.titleModule,
	...typo.headline['01.1'],
}));

export const SearchPageSubTitle = styled.p(() => ({
	display: 'block',
	width: '100%',
	fontSize: '16px',
	marginBottom: '20px !important',
}));

export const SearchPageWrapper = styled.div(() => ({
	display: 'flex',
	margin: ' 0 auto 30px',
	flexGrow: '1',
	width: '100%',
	minHeight: '900px',
}));

export const SearchPageResultTitle = styled.h3(({ theme: { color, typo } }) => ({
	color: color.text.primary02,
	...typo.headline['03'],
}));

export const SearchPageResultDivider = styled.div(() => ({
	margin: '10px 0',
	border: '0',
	borderTop: '1px solid #eee',
}));

export const SearchPageResultList = styled.ul(() => ({
	listStyleType: 'none',
	position: 'relative',
	width: '100%',
	height: '100%;',
	minHeight: '150px',
	overFlow: 'hidden',
	padding: '0',
}));

export const SearchPageResultListItem = styled.li(() => ({
	listStyleType: 'none',
	paddingRight: '20px',
}));

export const Link = styled(RouterLink)(({ theme: { color } }) => ({
	display: 'inline-flex',
	alignItems: 'center',
	width: '100%',
	minHeight: '45px',
	background: 'none',
	textDecoration: 'none !important',

	a: {
		color: `${color.link.secondary.default.label} !important`,
		textDecoration: 'none !important',
	},

	'&:hover': {
		color: color.interactive.lightest,
		textDecoration: 'none !important',

		'*': {
			color: `${color.link.secondary.hover.label} !important`,
			textDecoration: 'none !important',
		},
	},

	'&:active, &:visited, &.active': {
		a: {
			color: `${color.link.secondary.active.label}`,
			textDecoration: 'none !important',
		},
	},
}));
