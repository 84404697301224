/* eslint-disable indent */
import { logError } from './logUtil';
import { isNonValue } from './objectUtils';

export const capitalizeFirstLetter = s => s.charAt(0).toUpperCase() + s.slice(1);

export const toCapitalized = s => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();

export const hasText = text => {
	if (!text || !text.length) {
		return false;
	}
	return text.trim().length > 0;
};

export const stringsCompare = (a, b) => {
	return `${a}` === `${b}`;
};

export const toUpperCaseFirstLetter = str => {
	const result =
		str && str.length
			? str
					.toLowerCase()
					.split(' ')
					.reduce((p, s) => `${p}  ${s.charAt(0).toUpperCase()}${s.slice(1)}`, '')
			: '';
	return result;
};

export const replaceArgs = (str, ...args) => {
	const replacedStr = str.replace(/{[0-9]+}/g, s => {
		const i = Number(s.replace(/{|}/g, ''));
		return !Number.isNaN(i) ? args[i] || s : '';
	});
	return replacedStr;
};

export const removeHTMLFromString = html => {
	if (isNonValue(html)) {
		return '';
	}
	const strhtml = `${html}`;
	if (hasText(strhtml)) {
		const tmp = document.createElement('DIV');
		tmp.innerHTML = strhtml;
		return tmp.textContent || tmp.innerText || '';
	}
	return '';
};

export const createTranslationKey = text => {
	if (!text) {
		return null;
	}
	return `BT_${text.toLocaleUpperCase().trim().replace(/\s+/g, '_').slice(0, 50)}`;
};

export const replaceStringBreaksToHTML = str => {
	if (!hasText(str)) {
		return '';
	}
	return str.replace(/\r?\n/g, '<br />');
};

export const textEllipsis = (text, textLimit) => {
	if (hasText(text) && text.length > textLimit + 1) {
		return `${text.slice(0, textLimit - text.length - 4)}...`;
	}
	return text;
};

export const easyEncrypt = str => {
	if (!str) {
		return str;
	}
	const strCopy = `${str}`.trim();
	const base64 = window.btoa(strCopy.trim());
	const [encodedStr, ...arr] = base64.split('=');
	const count = arr && arr.length ? arr.length : 0;
	return `${encodedStr}${count}e`.split('').reverse().join('');
};

export const easyDecrypt = str => {
	if (!str) {
		return str;
	}
	try {
		const strCopy = `${str}`.trim().split('').reverse().join('');
		const count = Number.parseInt(strCopy.substring(strCopy.length - 2).split('e'), 10);
		let base64 = strCopy.substring(0, strCopy.length - 2);
		for (let index = 0; index < count; index++) {
			base64 += '=';
		}
		return atob(base64);
	} catch (e) {
		logError(e);
		return undefined;
	}
};

export const createJsonString = (data = {}) => JSON.stringify(data);

export const parseJsonString = str => JSON.parse(str);

export const hasHttpProtocol = (domain = '') => {
	const protocol = /^(http|https)/i;

	if (domain && !protocol.test(domain)) {
		return `https://${domain}`;
	}

	return domain;
};

export const isEmptyString = (data = '') => data || '';

export const clearString = str => {
	return str.replace(/([^A-Z\d\s])/gi, '_');
};

export const convertTextToHTML = string => {
	const parser = new DOMParser();
	const document = parser.parseFromString(string, 'text/html');

	return document.body.innerHTML;
};

export const generateSlug = (str = '') => {
	const slug = str
		.replace(/\s+/g, '-') // Replace whitespace characters with hyphens
		.replace(/[^\w\\-]+/g, '') // Remove special characters
		.toLowerCase(); // Convert to lowercase
	return slug;
};

export const normalizedString = str => str || '';

const extractContent = str => {
	// Remove Tags
	const clearTags = str?.replace(/(<([^>]+)>)/gi, '');

	// Remove HTML
	const withoutHTML = removeHTMLFromString(clearTags);

	// Remove line breaks
	const withoutLineBreak = withoutHTML?.replace(/\n/g, '');

	// Return the cleaned string
	return withoutLineBreak;
};

export const compareOnlyContent = (string1, string2) => {
	const cleanString1 = extractContent(string1);
	const cleanString2 = extractContent(string2);
	return `${cleanString1}` === `${cleanString2}`;
};

export const removeTrailingSpaces = str => str.replace(/\s+$/, '');

export const stringsDeepCompare = (a, b) => {
	return JSON.stringify(a) === JSON.stringify(b);
};
