import HyattPlaceFooterLogo from 'assets/img/logos/hyatt-place-footer.svg';
import PlaceHyattLogo from 'assets/img/logos/place.svg';
import HyattPlaceHeaderLogo from 'assets/img/logos/hyatt-place-header.svg';
import complementaryTheme from 'themes/complementary/complementary';
import colors from './hyattPlaceColors';
import typographyTheme from './hyattPlaceTypo';

const logo = {
	footer: HyattPlaceFooterLogo, // footer
	loading: PlaceHyattLogo, // logo in the loading
	sidebar: HyattPlaceHeaderLogo, // sidebar
	mobile: HyattPlaceHeaderLogo, // logo in the navbar
	size: {
		// desktop
		sidebar: {
			width: '105px',
			height: '110px',
		},
		footer: {
			width: '250px',
			height: '28px',
		},
		// mobile
		navbar: {
			width: '104px',
			height: '143px',
		},
		loading: {
			width: '100px',
			height: '100px',
		},
	},
};

const hyattPlace = {
	brandId: 'hp',
	name: 'Hyatt Place',
	logo,
	color: colors,

	font: {
		family: {
			helveticaNeue: 'Helvetica Neue',
			gothamBook: 'Gotham Book',
			gothamLight: 'Gotham Light',
			arialSansSerif: 'Arial, Helvetica, sans-serif',
			poynterRegular: 'Poynter Regular',
			calibri: 'Calibri, Arial',
			bodoniBoldItalic: 'Bodoni Bold',
			righteousRegular: 'Righteous Regular',
			rokkittRegular: 'Rokkitt Regular',
			rokkittBold: 'Rokkitt Bold',
			serif: 'serif',
		},
	},
};

const hyattPlaceTheme = {
	...hyattPlace,
	...typographyTheme(hyattPlace),
	...complementaryTheme,
};

export default hyattPlaceTheme;
