import { useCallback, useEffect, useState } from 'react';
import { fromChaptersService, getChaptersByBrand } from 'services/chaptersByBrandService';
import { useFixChaptersDisplayOrder } from 'hooks/useFixChapterDisplayOrder';
import { showToast } from 'utils/toastUtil';
import { translations } from 'translation/en';
import useBrandData from './useBrandData';
import { useRequest } from './useRequest';

const useChaptersByBrand = () => {
	const { brandId, brand } = useBrandData();
	const { data, error, run, clear } = useRequest(() => getChaptersByBrand({ brandId, pagePath: brand?.pagePath }));
	const { fetchFixedDisplayOrder } = useFixChaptersDisplayOrder();

	const [chapters, setChapters] = useState();
	const [isLoadingChapters, setIsLoadingChapters] = useState(false);

	const fetchChapters = useCallback(async () => {
		setIsLoadingChapters(true);

		return run()
			.then(async response => {
				const mappedChapters = response?.data?.data?.siteChapterByBrands?.items?.map(fromChaptersService);

				return fetchFixedDisplayOrder(mappedChapters).then(replacedChapters => {
					const sortedChapters = replacedChapters?.sort((a, b) => +a.displayOrder - +b.displayOrder);
					setChapters(sortedChapters);
				});
			})
			.finally(() => {
				setIsLoadingChapters(false);
			});
	}, [run]);

	const resetChapters = useCallback(() => {
		setChapters();
		clear();
	}, [clear]);

	useEffect(() => {
		if (data) {
			resetChapters();
		}
	}, [data, resetChapters]);

	useEffect(() => {
		if (error) {
			showToast({ type: 'error', message: translations.FAILED_FETCH_CHAPTERS });
		}
	}, [error]);

	return { loadingChapters: isLoadingChapters, chapters, fetchChapters, resetChapters };
};

export { useChaptersByBrand };
