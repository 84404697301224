import HyattConnectWhiteLogo from 'assets/img/logos/hc-white.svg';
import HyattConnectLogo from 'assets/img/logos/hc.svg';
import complementaryTheme from 'themes/complementary/complementary';
import colors from './corporateColors';
import typographyTheme from './corporateTypo';

const logo = {
	footer: HyattConnectLogo,
	loading: HyattConnectLogo, // logo in the loading
	sidebar: HyattConnectWhiteLogo,
	mobile: HyattConnectWhiteLogo, // logo in the navbar
	size: {
		sidebar: {
			width: '133px',
			height: '50px',
		},
		footer: {
			width: '148px',
			height: '45px',
		},
		navbar: {
			width: '133px',
			height: '50px',
		},
		loading: {
			width: '168px',
			height: '91px',
		},
	},
};

const corporate = {
	brandId: '71',
	name: 'Corporate',
	logo,
	color: colors,

	font: {
		family: {
			helveticaNeue: 'Helvetica Neue',
			gothamBook: 'Gotham Book',
			gothamLight: 'Gotham Light',
			calibri: 'Calibri, Arial',
			arialSansSerif: 'Arial, Helvetica, sans-serif',
			poynterRegular: 'Poynter Regular',
			openSans: 'Open Sans',
			robotoMonoRegular: 'RobotoMono Regular',
		},
	},
};

const corporateTheme = {
	...corporate,
	...typographyTheme(corporate),
	...complementaryTheme,
};

export default corporateTheme;
