import { BREAKPOINT } from 'themes/constants/breakpointsConstants';

const mediaQuery = breakpoint => () => {
	return `@media (min-width: ${BREAKPOINT[breakpoint]}px)`;
};

export const getBreakpoints = () =>
	Object.keys(BREAKPOINT).reduce((accumulator, breakpoint) => {
		accumulator[breakpoint.toLowerCase()] = mediaQuery(breakpoint);
		return accumulator;
	}, {});
