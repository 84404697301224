import { RefObject, useEffect } from 'react';

const removeHash = (str: string) => {
	return str.replace('#', '');
};

const getElementByIdOrName = (id: string): HTMLElement | null | undefined => {
	return document.getElementById(id) || document.getElementsByName(id)[0];
};

export const useAnchors = <T extends HTMLElement>(ref: RefObject<T> | null) => {
	useEffect(() => {
		const eventHandler = (e: MouseEvent, anchor: HTMLElement) => {
			const anchorHref = anchor.getAttribute('href');

			if (!anchorHref) return;

			const name = removeHash(anchorHref);
			const element = getElementByIdOrName(name);

			if (element) {
				e.preventDefault();
				element.scrollIntoView({ behavior: 'smooth' });
				return;
			}

			if (name === 'top') {
				e.preventDefault();
				if (ref && ref.current) {
					ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
				}
				return;
			}
		};

		const anchors = (ref && ref.current && ref.current.querySelectorAll('a')) || [];

		anchors.forEach(anchor => {
			anchor.addEventListener('click', (e: MouseEvent) => eventHandler(e, anchor));
		});

		return () => {
			anchors.forEach(anchor => {
				anchor.removeEventListener('click', (e: MouseEvent) => eventHandler(e, anchor));
			});
		};
	}, [ref]);
};
