import styled from 'styled-components/macro';

export const LoadingOverlay = styled.div(({ isFullScreen }) => ({
	position: isFullScreen ? 'fixed' : 'absolute',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: 'rgba(255, 255, 255, 0.8)',
	backgroundPosition: 'center center',
	backgroundRepeat: 'no-repeat',
	backgroundSize: '20px',
	padding: '20px 0px',
	width: '100%',
	height: '100%',
	zIndex: 9999,

	top: isFullScreen ? 0 : 'default',
	left: isFullScreen ? 0 : 'default',

	'@supports (-webkit-fill-available)': {
		height: '-webkit-fill-available !important',
	},

	'@supports (-moz-available)': {
		height: '-moz-available !important',
	},
}));
