/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/aria-role */
import ErrorImageSrc from 'assets/img/error/broken-image.png';
import { Button } from 'components/button';
import {
	CAPTION_JUSTIFICATION,
	CAPTION_POSITION_PROP,
	CAPTION_PROP,
	LINK_TO_PROP,
	OVERLAY_JUSTIFICATION,
	OVERLAY_POSITION_PROP,
	OVERLAY_PROP,
	ROLE_UPLOAD_FILE_BOX,
	TEST_CAPTION_TEXTAREA_ID,
	TEST_LINK_INPUT_ID,
	TEST_TITLE_INPUT_ID,
} from 'components/imageBox/constants/imageBoxConstants';
import { Modal } from 'components/modals/modal';
import mediumZoom from 'medium-zoom';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { translations } from 'translation/en';
import { checkExtension, checkIfIsBase64File } from 'utils/fileUtil';
import { getOverflow } from 'utils/overflowUtil';
import { showToast } from 'utils/toastUtil';

import TitleLeftActive from 'assets/img/justification/title-left-active.png';
import TitleLeftDefault from 'assets/img/justification/title-left-default.png';
import TitleRightActive from 'assets/img/justification/title-right-active.png';
import TitleRightDefault from 'assets/img/justification/title-right-default.png';

import CaptionCenterActive from 'assets/img/justification/caption-center-active.png';
import CaptionCenterDefault from 'assets/img/justification/caption-center-default.png';
import CaptionLeftActive from 'assets/img/justification/caption-left-active.png';
import CaptionLeftDefault from 'assets/img/justification/caption-left-default.png';
import CaptionRightActive from 'assets/img/justification/caption-right-active.png';
import CaptionRightDefault from 'assets/img/justification/caption-right-default.png';

import { Loading } from 'components/loadingOverlay';
import { useCreateImage } from 'hooks/useCreateImage';
import { usePrevious } from 'hooks/usePrevious';
import { useUpdateImage } from 'hooks/useUpdateImage';
import { fromImageService } from 'services/imageService';
import { isEqual } from 'utils/objectUtils';
import { useTheme } from 'styled-components';
import { hasHttpProtocol, isEmptyString } from 'utils/stringUtil';
import { getImageUrl } from 'utils/urlUtil';
import * as S from './EditImageModal.style';

const EditImageModal = ({ open, paragraph, imageData, imageStyle, onSaveAsDraft, onClose }) => {
	const initialState = useMemo(() => {
		return { ...imageData, file: getImageUrl(imageData) };
	}, [imageData]);

	const { logo } = useTheme();

	const portalElement = document.getElementById('root');
	const [domReady, setDomReady] = useState(false);

	const { loadingCreate, createImage } = useCreateImage();
	const { loadingUpdate, updateImage } = useUpdateImage();

	const fileInputRef = useRef(null);
	const fileRef = useRef(
		mediumZoom({
			scrollOffset: 0,
			background: 'rgba(0, 0, 0, 0.3)',
		})
	);

	const [payloadData, setPayloadData] = useState(initialState);
	const { previousRef } = usePrevious(payloadData);

	const isPlaceholderFile = checkIfIsBase64File(payloadData?.imageVcmId);
	const isEqualsPayloadData = isEqual(previousRef, payloadData);
	const isLoadingImage = [loadingCreate, loadingUpdate].some(Boolean);
	const isSaveButtonDisabled = [isPlaceholderFile, isLoadingImage, isEqualsPayloadData].some(Boolean);

	useEffect(() => {
		setDomReady(true);
		const currentOverflowY = portalElement?.style.overflowY;
		if (portalElement) portalElement.style.overflowY = getOverflow(open);

		return () => {
			setDomReady(false);
			if (portalElement) portalElement.style.overflow = currentOverflowY;
		};
	}, [open, portalElement]);

	const onReset = () => {
		fileInputRef.current = null;
		onClose();
	};

	const handleClose = () => {
		onReset();
	};

	const attachImageZoom = image => {
		fileRef.current.detach(image);
	};

	const handleInputValue = e => {
		setPayloadData(prev => ({
			...prev,
			[e.target.name]: e.target.value,
		}));
	};

	const handleTypeJustification = e => {
		setPayloadData(prev => ({
			...prev,
			[e.target.name]: e.target.id,
		}));
	};

	const handleUpload = e => {
		e.preventDefault();
		fileInputRef.current.click();
	};

	const handleChange = e => {
		e.preventDefault();

		const file = e.target.files[0];
		if (!checkExtension(file.name.split('.').reverse()[0])) {
			showToast({ type: 'error', message: translations.FILE_EXTENSION_NOT_ALLOWED });
			return;
		}

		setPayloadData(prev => ({
			...prev,
			imageType: file.type,
			imageTitle: file.name,
			file: `${URL.createObjectURL(file)}#localFile`,
			orgFile: file,
		}));
	};

	const handleSaveAsDraft = async e => {
		e.preventDefault();

		const normalizedPayload = {
			...payloadData,
			linkTo: hasHttpProtocol(payloadData.linkTo),
		};

		let response = null;

		if (payloadData.imageContentPath) {
			[response] = await updateImage({
				imageData: normalizedPayload,
			});
		} else {
			[response] = await createImage({
				paragraphData: paragraph,
				imageData: normalizedPayload,
			});
		}

		if (response && response.data) {
			const normalizedImage = fromImageService(response.data);
			onSaveAsDraft({ ...normalizedPayload, ...normalizedImage });
			onReset();
		}
	};

	const addDefaultSrc = event => {
		// eslint-disable-next-line no-param-reassign
		event.target.src = ErrorImageSrc;
	};

	const modalComponent = open && (
		<S.EditModal
			open={open}
			overlayClassName="ModalBox__white-overlay"
			containerStyle={{ borderTop: 'none', paddingTop: '10px' }}
			onClose={handleClose}
			closeIcon={<i className="icon-close-slim" />}
			hideExpand
		>
			{isLoadingImage && (
				<Loading.Overlay>
					<Loading.LoadingLogo logo={logo?.loading} size={logo?.size.loading} />
				</Loading.Overlay>
			)}

			<Modal.Header role="heading" variant="h4" showDivider={false}>
				{translations.EDIT_IMAGE}
			</Modal.Header>

			<S.EditModalMain>
				<S.RowImage>
					<S.UploadImage role={ROLE_UPLOAD_FILE_BOX}>
						<img ref={attachImageZoom} src={payloadData?.file} style={imageStyle} onError={addDefaultSrc} alt="" />
						<input ref={fileInputRef} type="file" onChange={handleChange} hidden />
						<S.OverlayButton onClick={handleUpload}>
							<label>{isPlaceholderFile ? translations.UPLOAD_IMAGE : translations.REPLACE_IMAGE}</label>
						</S.OverlayButton>
					</S.UploadImage>
				</S.RowImage>
				<S.Row>
					<S.ColumnInputs>
						<S.InputGroup>
							<S.LabelInput>{translations.IMAGE_TEXT_OVERLAY}</S.LabelInput>
							<S.Input
								name={OVERLAY_PROP}
								value={isEmptyString(payloadData[OVERLAY_PROP])}
								placeholder={translations.ENTER_TEXT}
								disabled={isPlaceholderFile}
								aria-label={TEST_TITLE_INPUT_ID}
								onChange={handleInputValue}
							/>
						</S.InputGroup>
					</S.ColumnInputs>
					<S.ColumnJustification>
						<S.JustificationGroup>
							<S.LabelJustification>{translations.TITLE_JUSTIFICATION}</S.LabelJustification>
							<S.JustificationButtons>
								<S.JustificationButton
									name={OVERLAY_POSITION_PROP}
									id={OVERLAY_JUSTIFICATION.LEFT.value}
									checked={payloadData[OVERLAY_POSITION_PROP] === OVERLAY_JUSTIFICATION.LEFT.value}
									imageDefault={TitleLeftDefault}
									imageActive={TitleLeftActive}
									onClick={handleTypeJustification}
								/>
								<S.JustificationButton
									name={OVERLAY_POSITION_PROP}
									id={OVERLAY_JUSTIFICATION.RIGHT.value}
									checked={payloadData[OVERLAY_POSITION_PROP] === OVERLAY_JUSTIFICATION.RIGHT.value}
									imageDefault={TitleRightDefault}
									imageActive={TitleRightActive}
									onClick={handleTypeJustification}
								/>
							</S.JustificationButtons>
						</S.JustificationGroup>
					</S.ColumnJustification>
				</S.Row>
				<S.Row>
					<S.ColumnInputs>
						<S.InputGroup>
							<S.LabelInput>{translations.SUB_CAPTION_TEXT}</S.LabelInput>
							<S.TextArea
								name={CAPTION_PROP}
								value={isEmptyString(payloadData[CAPTION_PROP])}
								placeholder={translations.ENTER_TEXT}
								disabled={isPlaceholderFile}
								aria-label={TEST_CAPTION_TEXTAREA_ID}
								onChange={handleInputValue}
							/>
						</S.InputGroup>
					</S.ColumnInputs>
					<S.ColumnJustification>
						<S.JustificationGroup>
							<S.LabelJustification>{translations.CAPTION_JUSTIFICATION}</S.LabelJustification>
							<S.JustificationButtons>
								<S.JustificationButton
									name={CAPTION_POSITION_PROP}
									id={CAPTION_JUSTIFICATION.LEFT.value}
									checked={payloadData[CAPTION_POSITION_PROP] === CAPTION_JUSTIFICATION.LEFT.value}
									imageDefault={CaptionLeftDefault}
									imageActive={CaptionLeftActive}
									onClick={handleTypeJustification}
								/>
								<S.JustificationButton
									name={CAPTION_POSITION_PROP}
									id={CAPTION_JUSTIFICATION.CENTER.value}
									checked={payloadData[CAPTION_POSITION_PROP] === CAPTION_JUSTIFICATION.CENTER.value}
									imageDefault={CaptionCenterDefault}
									imageActive={CaptionCenterActive}
									onClick={handleTypeJustification}
								/>
								<S.JustificationButton
									name={CAPTION_POSITION_PROP}
									id={CAPTION_JUSTIFICATION.RIGHT.value}
									checked={payloadData[CAPTION_POSITION_PROP] === CAPTION_JUSTIFICATION.RIGHT.value}
									imageDefault={CaptionRightDefault}
									imageActive={CaptionRightActive}
									onClick={handleTypeJustification}
								/>
							</S.JustificationButtons>
						</S.JustificationGroup>
					</S.ColumnJustification>
				</S.Row>
				<S.Row>
					<S.ColumnInputs>
						<S.InputGroup>
							<S.LabelInput>{translations.LINK_TO}</S.LabelInput>
							<S.Input
								name={LINK_TO_PROP}
								value={isEmptyString(payloadData.linkTo)}
								placeholder={translations.ENTER_LINK}
								disabled={isPlaceholderFile}
								aria-label={TEST_LINK_INPUT_ID}
								onChange={handleInputValue}
							/>
						</S.InputGroup>
					</S.ColumnInputs>
				</S.Row>
			</S.EditModalMain>

			<Modal.Footer style={{ backgroundColor: '#f3f3f3' }}>
				<Button
					variant="tertiary-03"
					title={translations.SELECT_AN_IMAGE_BEFORE_OR}
					onClick={handleSaveAsDraft}
					disabled={isSaveButtonDisabled}
				>
					{translations.SAVE_AS_DRAFT}
				</Button>
				<Button variant="gray-border-only" onClick={handleClose}>
					{translations.CANCEL}
				</Button>
			</Modal.Footer>
		</S.EditModal>
	);

	return domReady ? ReactDOM.createPortal(modalComponent, portalElement) : null;
};

EditImageModal.propTypes = {
	open: PropTypes.bool,
	paragraph: PropTypes.shape({}),
	imageData: PropTypes.shape({
		imageContentPath: PropTypes.string,
		file: PropTypes.string,
	}),
	imageStyle: PropTypes.shape({}),
	onSaveAsDraft: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
};

EditImageModal.defaultProps = {
	open: false,
	paragraph: undefined,
	imageData: undefined,
	imageStyle: { width: '105px', height: '105px' },
};

export { EditImageModal };
