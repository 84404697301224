import { EDIT_MENU_SET_SELECTED_TO_EDIT } from '../../actions/actionTypes';

const initialState = {
	selectedToEdit: '',
};

const editMenuSetSelectedToEdit = (state, action) => ({
	...state,
	...action.selectedToEdit,
});

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case EDIT_MENU_SET_SELECTED_TO_EDIT:
			return editMenuSetSelectedToEdit(state, action);
		default:
			break;
	}
	return state;
};

export default reducer;
