/* eslint-disable no-continue */
/* eslint-disable no-lonely-if */
/* eslint-disable no-else-return */
/* eslint-disable indent */
/* eslint-disable no-await-in-loop */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { fromArticleService, getArticleByPath } from 'services/articleService';
import { fromChapterService, getChapterByPath } from 'services/chapterService';
import { fromSectionService, getSectionByPath } from 'services/sectionService';
import { fromRowsByQuickSheet, getRowsByQuickSheet } from 'services/rowsByQuickSheetService';
import { MODELS } from 'constants/aemConstants';
import { PAGE_TYPES } from 'constants/pageConstants';

const createCurrentByType = ({ type, chapter, section, article }) => {
	let item = null;

	switch (type) {
		case PAGE_TYPES.CHAPTER_TYPE: {
			item = {
				type: PAGE_TYPES.CHAPTER_TYPE,
				chapterId: chapter.vcmId,
				chapterTitle: chapter.title,
				chapterPath: chapter.path,
				chapterDisplayOrder: chapter.displayOrder,
				sectionId: null,
				sectionTitle: null,
				sectionPath: null,
				sectionDisplayOrder: null,
				articleId: null,
				articleTitle: null,
				articlePath: null,
				articleDisplayOrder: null,
				path: chapter.path,
				vcmId: chapter.vcmId,
				quickSheetPath: chapter.quickSheetPath,
				isPageApproved: chapter.approved,
				publishInfo: chapter.publishInfo,
				regions: chapter.regions,
				brands: chapter.brands,
				creationDate: chapter.creationDate,
				creator: chapter.creator,
				unpublishLater: chapter.unpublishLater,
				pagePath: chapter.pagePath,
				model: MODELS.CHAPTER,
			};
			break;
		}
		case PAGE_TYPES.SECTION_TYPE: {
			item = {
				type: PAGE_TYPES.SECTION_TYPE,
				chapterId: chapter.vcmId,
				chapterTitle: chapter.title,
				chapterPath: chapter.path,
				chapterDisplayOrder: chapter.displayOrder,
				sectionId: section.vcmId,
				sectionTitle: section.title,
				sectionPath: section.path,
				sectionRelatorPath: section.relatorPath,
				sectionDisplayOrder: section.displayOrder,
				articleTitle: null,
				articlePath: null,
				articleDisplayOrder: null,
				path: section.path,
				vcmId: section.vcmId,
				quickSheetPath: chapter.quickSheetPath,
				isPageApproved: section.approved,
				publishInfo: section.publishInfo,
				regions: section.regions,
				brands: section.brands,
				creationDate: section.creationDate,
				creator: section.creator,
				unpublishLater: section.unpublishLater,
				pagePath: section.pagePath,
				model: MODELS.SECTION,
			};
			break;
		}
		case PAGE_TYPES.ARTICLE_TYPE: {
			item = {
				type: PAGE_TYPES.ARTICLE_TYPE,
				chapterId: chapter.vcmId,
				chapterTitle: chapter.title,
				chapterPath: chapter.path,
				chapterDisplayOrder: chapter.displayOrder,
				sectionId: section.vcmId,
				sectionTitle: section.title,
				sectionPath: section.path,
				sectionRelatorPath: section.relatorPath,
				sectionDisplayOrder: section.displayOrder,
				articleId: article.vcmId,
				articleTitle: article.title,
				articlePath: article.path,
				articleRelatorPath: article.relatorPath,
				articleDisplayOrder: article.displayOrder,
				path: article.path,
				vcmId: article.vcmId,
				quickSheetPath: chapter.quickSheetPath,
				isPageApproved: article.approved,
				publishInfo: article.publishInfo,
				regions: article.regions,
				brands: article.brands,
				creationDate: article.creationDate,
				creator: article.creator,
				unpublishLater: article.unpublishLater,
				pagePath: article.pagePath,
				model: MODELS.ARTICLE,
			};
			break;
		}
		default:
			break;
	}
	return item;
};

const fetchContentByType = async ({ type, path }) => {
	if (!path) {
		return null;
	}

	let response = null;
	let payload = null;

	switch (type) {
		case PAGE_TYPES.CHAPTER_TYPE: {
			response = await getChapterByPath({ path });
			payload = response?.data?.data?.getChapterByPath?.items?.map(fromChapterService)?.shift();
			break;
		}
		case PAGE_TYPES.SECTION_TYPE: {
			response = await getSectionByPath({ path });
			payload = response?.data?.data?.getSectionByPath?.items?.map(fromSectionService)?.shift();
			break;
		}
		case PAGE_TYPES.ARTICLE_TYPE: {
			response = await getArticleByPath({ path });
			payload = response?.data?.data?.getArticleByPath?.items?.map(fromArticleService)?.shift();
			break;
		}
		default:
			break;
	}

	if (payload?.paragraphs?.length) {
		return payload?.paragraphs;
	}

	return null;
};

const fetchContentByQuickSheet = async ({ brandId, user, operation, topic, path }) => {
	if (!path) {
		return null;
	}

	const response = await getRowsByQuickSheet({ operation, topic, path });
	const payload = response?.data && fromRowsByQuickSheet(response?.data, {}, { brandId }, user);

	if (payload?.paragraphs?.length) {
		return payload?.paragraphs;
	}

	return null;
};

export const fetchFirstItemWithContent = async collection => {
	if (!collection || !collection?.length) {
		return null;
	}

	for (const i in collection) {
		const chapter = collection[i];
		const resContentChapter = await fetchContentByType({ type: PAGE_TYPES.CHAPTER_TYPE, path: chapter?.path });

		if (resContentChapter) {
			return chapter?.path;
		}

		const { sections } = chapter;

		for (const j in sections) {
			const section = sections[j];
			const resContentSession = await fetchContentByType({ type: PAGE_TYPES.SECTION_TYPE, path: section?.path });

			if (resContentSession) {
				return section?.path;
			}

			const { articles } = section;

			for (const k in articles) {
				const article = articles[k];
				const resContentArticle = await fetchContentByType({ type: PAGE_TYPES.ARTICLE_TYPE, path: article?.path });

				if (resContentArticle) {
					return article?.path;
				}
			}
		}
	}

	return null;
};

export const fetchItemByPath = async (
	collection,
	brandId,
	user,
	path,
	objectType = PAGE_TYPES.CHAPTER_TYPE,
	refChapter = null,
	refSection = null,
	fetchServiceByType
) => {
	if (!collection) {
		return null;
	}

	try {
		let contentChapter = null;
		let contentSection = null;
		let contentArticle = null;

		for (const current of collection) {
			if (!path.endsWith(current?.path)) {
				if (objectType === PAGE_TYPES.CHAPTER_TYPE) {
					const result = await fetchItemByPath(
						current?.sections,
						brandId,
						user,
						path,
						PAGE_TYPES.SECTION_TYPE,
						current,
						null,
						fetchServiceByType
					);

					if (result) {
						return result;
					}
				}

				if (objectType === PAGE_TYPES.SECTION_TYPE) {
					const result = await fetchItemByPath(
						current?.articles,
						brandId,
						user,
						path,
						PAGE_TYPES.ARTICLE_TYPE,
						refChapter,
						current,
						fetchServiceByType
					);

					if (result) {
						return result;
					}
				}

				continue;
			}

			switch (objectType) {
				case PAGE_TYPES.CHAPTER_TYPE: {
					const resContentChapter = await fetchServiceByType({ type: PAGE_TYPES.CHAPTER_TYPE, path: current?.path });

					if (resContentChapter) {
						contentChapter = resContentChapter;
						return createCurrentByType({
							type: PAGE_TYPES.CHAPTER_TYPE,
							chapter: current,
						});
					}

					const { sections } = current;

					for (const section of sections || []) {
						const [resContentSection, contentSectionQuicksheet] = await Promise.all([
							fetchServiceByType({ type: PAGE_TYPES.SECTION_TYPE, path: section?.path }),
							fetchContentByQuickSheet({
								operation: section?.title,
								path: current?.quickSheetPath,
								brandId,
								user,
							}),
						]);

						if (resContentSection || contentSectionQuicksheet) {
							contentSection = resContentSection;
							return createCurrentByType({
								type: PAGE_TYPES.SECTION_TYPE,
								chapter: current,
								section,
							});
						}

						const { articles } = section;

						for (const article of articles || []) {
							const [resContentArticle, contentArticleQuicksheet] = await Promise.all([
								fetchServiceByType({ type: PAGE_TYPES.ARTICLE_TYPE, path: article?.path }),
								fetchContentByQuickSheet({
									operation: section?.title,
									topic: article?.title,
									path: current?.quickSheetPath,
									brandId,
									user,
								}),
							]);

							if (resContentArticle || contentArticleQuicksheet) {
								contentArticle = resContentArticle;
								return createCurrentByType({
									type: PAGE_TYPES.ARTICLE_TYPE,
									chapter: current,
									section,
									article,
								});
							}
						}
					}

					if (!contentChapter && !contentSection && !contentArticle) {
						return createCurrentByType({
							type: PAGE_TYPES.CHAPTER_TYPE,
							chapter: current,
						});
					}

					break;
				}

				case PAGE_TYPES.SECTION_TYPE: {
					const [resContentSection, contentSectionQuicksheet] = await Promise.all([
						fetchServiceByType({ type: PAGE_TYPES.SECTION_TYPE, path: current?.path }),
						fetchContentByQuickSheet({
							operation: current?.title,
							path: refChapter?.quickSheetPath,
							brandId,
							user,
						}),
					]);

					if (resContentSection || contentSectionQuicksheet) {
						contentSection = resContentSection;
						return createCurrentByType({
							type: PAGE_TYPES.SECTION_TYPE,
							chapter: refChapter,
							section: current,
						});
					}

					const { articles } = current;

					for (const article of articles || []) {
						const [resContentArticle, contentArticleQuicksheet] = await Promise.all([
							fetchServiceByType({ type: PAGE_TYPES.ARTICLE_TYPE, path: article?.path }),
							fetchContentByQuickSheet({
								operation: current?.title,
								topic: article?.title,
								path: refChapter?.quickSheetPath,
								brandId,
								user,
							}),
						]);

						if (resContentArticle || contentArticleQuicksheet) {
							contentArticle = resContentArticle;
							return createCurrentByType({
								type: PAGE_TYPES.ARTICLE_TYPE,
								chapter: refChapter,
								section: current,
								article,
							});
						}
					}

					if (!contentSection && !contentArticle) {
						return createCurrentByType({
							type: PAGE_TYPES.SECTION_TYPE,
							chapter: refChapter,
							section: current,
						});
					}

					break;
				}

				case PAGE_TYPES.ARTICLE_TYPE: {
					const [resContentArticle, contentArticleQuicksheet] = await Promise.all([
						fetchServiceByType({ type: PAGE_TYPES.ARTICLE_TYPE, path: current?.path }),
						fetchContentByQuickSheet({
							operation: refSection?.title,
							topic: current?.title,
							path: refChapter?.quickSheetPath,
							brandId,
							user,
						}),
					]);

					if (resContentArticle || contentArticleQuicksheet) {
						contentArticle = resContentArticle;
						return createCurrentByType({
							type: PAGE_TYPES.ARTICLE_TYPE,
							chapter: refChapter,
							section: refSection,
							article: current,
						});
					}

					if (!contentArticle) {
						return createCurrentByType({
							type: PAGE_TYPES.ARTICLE_TYPE,
							chapter: refChapter,
							section: refSection,
							article: current,
						});
					}

					break;
				}

				default:
					break;
			}
		}
	} catch (error) {
		return new Error(error);
	}

	return null;
};

export const findItemByPath = (collection, path, objectType = PAGE_TYPES.CHAPTER_TYPE, refChapter = null, refSection = null) => {
	if (!collection || !collection.length) {
		return null;
	}

	for (const current of collection) {
		if (!path.endsWith(current.path)) {
			if (objectType === PAGE_TYPES.CHAPTER_TYPE) {
				const result = findItemByPath(current?.sections, path, PAGE_TYPES.SECTION_TYPE, current);

				if (result) {
					return result;
				}
			}

			if (objectType === PAGE_TYPES.SECTION_TYPE) {
				const result = findItemByPath(current?.articles, path, PAGE_TYPES.ARTICLE_TYPE, refChapter, current);

				if (result) {
					return result;
				}
			}

			continue;
		}

		switch (objectType) {
			case PAGE_TYPES.CHAPTER_TYPE: {
				return createCurrentByType({
					type: PAGE_TYPES.CHAPTER_TYPE,
					chapter: current,
				});
			}
			case PAGE_TYPES.SECTION_TYPE: {
				return createCurrentByType({
					type: PAGE_TYPES.SECTION_TYPE,
					chapter: refChapter,
					section: current,
				});
			}
			case PAGE_TYPES.ARTICLE_TYPE: {
				return createCurrentByType({
					type: PAGE_TYPES.ARTICLE_TYPE,
					chapter: refChapter,
					section: refSection,
					article: current,
				});
			}
			default:
				break;
		}
	}

	return null;
};

export const generateCrumbs = object => {
	if (!object) {
		return [];
	}

	let result = [];

	switch (object.type) {
		case PAGE_TYPES.CHAPTER_TYPE:
			result = [
				...result,
				{
					id: object.chapterId,
					path: object.path,
					label: object.chapterTitle,
				},
			];
			break;
		case PAGE_TYPES.SECTION_TYPE: {
			const chapterArr = [{ id: object.chapterId, path: object.chapterPath, label: object.chapterTitle }];
			const sectionArr = [
				{
					id: object.sectionId,
					path: object.path,
					label: object.sectionTitle,
				},
			];
			result = [...new Set([...chapterArr, ...sectionArr])];
			break;
		}
		case PAGE_TYPES.ARTICLE_TYPE: {
			const chapterArr = [{ id: object.chapterId, path: object.chapterPath, label: object.chapterTitle }];
			const sectionArr = [{ id: object.sectionId, path: object.sectionPath, label: object.sectionTitle }];
			const articleArr = [
				{
					id: object.articleId,
					path: object.path,
					label: object.articleTitle,
				},
			];
			result = [...new Set([...chapterArr, ...sectionArr, ...articleArr])];
			break;
		}

		default:
			break;
	}

	return result;
};
