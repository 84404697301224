const ADD_TAGS = ['iframe'];
const ADD_ATTR = ['allow', 'allowfullscreen', 'frameborder', 'scrolling'];

export const embedConfig = { ADD_TAGS, ADD_ATTR };

export const ALLOWED_SOURCES = [
	'https://hyatt.yapmo.com/',
	'https://www.youtube.com/',
	'https://fast.wistia.net/',
	'https://vimeo.com/',
	'https://web.microsoftstream.com/',
	'https://teamsite.hyattoffice.com/',
	'https://s7privatecdn.konverse.com/',
	'https://www.loom.com/',
	'https://advocate.socialchorus.com/',
	'https://form.jotform.com',
	'https://mysite.hyattoffice.com',
	'https://www.canva.com',
	'https://cdnapisec.kaltura.com',
	'https://survey.co1.qualtrics.com',
	'https://app.smartsheet.com',
];
