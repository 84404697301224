import { Button } from 'components/button';
import styled from 'styled-components/macro';

export const AddFileButton = styled(Button)({
	margin: 0,
	width: 'fit-content',

	'i:first-child': {
		marginRight: '5px',
	},
});

export const LimitFileMessage = styled.p(({ theme: { color, typo } }) => ({
	margin: 0,
	padding: '10px 0px',
	color: color.text.addFilesSubtitle,

	...typo.caption['06'],
}));
