import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { findAnchorElement } from 'utils/elementsUtils';
import { isExternalLink } from 'utils/urlUtil';
import { localStorageKeys } from 'constants/localStorageConstants';

export const useRouteStorage = () => {
	const history = useHistory();
	const [storedRoute, setStoredRoute] = useLocalStorage(localStorageKeys.STORED_ROUTE, {
		last: '',
		current: history.location.pathname,
	});

	useEffect(() => {
		const handleSaveRouteChange = () => {
			if (storedRoute.current !== history.location.pathname)
				setStoredRoute({ last: storedRoute.current, current: history.location.pathname });
		};

		const handleNewTabOpening = e => {
			const el = e.target;
			if (!el) return;

			if (el instanceof HTMLElement) {
				const anchor = findAnchorElement(el);
				if (!anchor) return;

				const { target, hash } = anchor;

				if (target === '_blank' && !isExternalLink(anchor)) {
					setStoredRoute({ last: history.location.pathname, current: hash.replace('#', '') });
				}
			}
		};

		window.addEventListener('click', handleNewTabOpening);
		const unlisten = history.listen(handleSaveRouteChange);

		return () => {
			window.removeEventListener('click', handleNewTabOpening);
			unlisten();
		};
	}, [history, setStoredRoute, storedRoute]);
};
