import { Button } from 'components/button';
import styled from 'styled-components/macro';

export const Content = styled.div(() => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',

	margin: '25px 0',
}));

export const Title = styled.span(({ theme: { color, typo } }) => ({
	marginBottom: '20px',
	color: color.text.quaternary,
	...typo.body['06'],
}));

export const Row = styled.div(({ theme: { breakpoint } }) => ({
	display: 'flex',
	justifyContent: 'space-around',
	flexDirection: 'column-reverse',
	alignItems: 'center',
	gap: '10px',

	textAlign: 'center',
	width: '100%',
	height: 'fit-content',
	marginBottom: '14px',

	':last-child': {
		marginBottom: '0px',
	},

	'> *': {
		width: '50%',
	},

	[breakpoint.sm()]: {
		flexDirection: 'row',
		gap: '1rem',
	},
}));

export const Lead = styled.span(({ theme: { color, typo } }) => ({
	color: color.text.quinary,
	width: '100%',
	...typo.lead['03'],
}));

export const StyledButton = styled(Button)({
	'&&': {
		minWidth: '105px',
	},
});
