import styled from 'styled-components/macro';

export const PrintPageTemplate = styled.div(() => ({
	maxWidth: '1235px',
	width: '100%',
	textAlign: 'center',
	margin: 'auto',
	padding: '40px',

	pageBreakAfter: 'always',

	'@page': {
		display: 'block',
		margin: 0,
		size: 'auto',
	},

	'@media print': {
		thead: { display: 'table-header-group' },
		tfoot: { display: 'table-footer-group' },

		'html, body': {
			'-webkit-print-color-adjust': 'exact',
		},
	},
}));

export const PrintPageTemplateHeader = styled.div(() => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	textAlign: 'center',
}));

export const PrintPageTemplateBody = styled.div(() => ({}));

export const PrintPageTemplateFooter = styled.div(() => ({
	display: 'flex',
	position: 'fixed',
	right: 0,
	bottom: '15px',
	justifyContent: 'end',
	alignItems: 'center',
	textAlign: 'right',
	visibility: 'hidden',
	width: '100%',
	height: '50px',
	color: '#373a3c',
	fontFamily: 'Calibri, arial',
	fontSize: '14px',
	lineHeight: '1.4em',
	zIndex: '9999',

	'@media print': {
		margin: '20px 20px 0px',
		visibility: 'visible',
	},
}));

export const loadingFileMessage = styled.div(() => ({
	position: 'absolute',
	top: '55%',
	left: '50%',
	transform: 'translate(-50%,-50%)',
}));

export const PrintPageTemplateFooterVersion = styled(PrintPageTemplateFooter)(() => ({
	right: '448px',
	bottom: 0,

	'@media print': {
		margin: '0',
		visibility: 'visible',
	},
}));

export const PrintPageTemplateButtonWrapper = styled.div(() => ({
	display: 'flex',
	position: 'relative',
	width: '100%',
	marginBottom: '92px',
	justifyContent: 'center',
	textAlign: 'center',

	'@media print': {
		display: 'none',
	},
}));

export const MenuButton = styled.button(({ theme: { color, border } }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	textAlign: 'center',
	width: '70px',
	height: '48px',
	borderRadius: '5px',
	background: color.interactive.printPageButtons?.default.background,
	border: `${border.width.md} solid ${color.interactive.printPageButtons?.default.border}`,

	'*': {
		color: color.interactive.printPageButtons?.default.label,
	},

	'&:hover': {
		background: color.interactive.printPageButtons?.hover.background,
		border: `${border.width.md} solid ${color.interactive.printPageButtons?.hover.border}`,

		'*': {
			color: color.interactive.printPageButtons?.hover.label,
		},
	},
}));

export const Icon = styled.i(({ theme: { size } }) => ({
	display: 'inline-block',
	position: 'relative',
	marginRight: '5px',
	fontStyle: 'normal',
	fontWeight: 'normal',
	lineHeight: 1,
	fontSize: size['1md'],
}));

export const Arrow = styled.span(() => ({
	display: 'inline-block',
	borderTop: '5px dashed',
	borderRight: '5px solid transparent',
	borderLeft: '5px solid transparent',
}));

export const PrintPageLogo = styled.div(({ theme: { color } }) => ({
	display: 'none',
	justifyContent: 'center',
	alignItems: 'center',
	alignSelf: 'center',

	width: '200px',
	height: '300px',

	padding: '30px',
	margin: '100px 0',

	background: color.background.secondary,

	'@media print': {
		display: 'flex',
	},
}));

export const PrintPageTitle = styled.h2(({ theme: { color, breakpoint, typo } }) => ({
	display: 'flex',
	float: 'left',
	wordWrap: 'break-word',
	paddingBottom: '30px',
	marginLeft: '3px',

	pageBreakBefore: 'avoid',
	pageBreakAfter: 'always',

	color: color.text.primary02,
	...typo.headline['01'],

	[breakpoint.lg()]: {
		...typo.headline['01']?.lg,
	},

	'@media print': {
		paddingBottom: '0px',
		color: color.text.primary02,
		...typo.headline['01'],

		[breakpoint.lg()]: {
			...typo.headline['01']?.lg,
		},
	},
}));
