import PropTypes from 'prop-types';
import * as S from './Label.style';

const Label = ({ variant, children }) => {
	return <S.Label variant={variant}>{children}</S.Label>;
};

Label.propTypes = {
	variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quaternary']),
	children: PropTypes.node,
};

Label.defaultProps = {
	variant: 'primary',
	children: '',
};

export { Label };
