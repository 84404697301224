/* eslint-disable max-len */
import { format } from 'date-fns';
import moment from 'moment';

export const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];

export const getDateNow = () => Date.now();

export const getTimeNow = () => new Date().getTime();

export const getUnixNow = () => Math.floor(Date.now() / 1000);

export const getISONow = () => new Date().toISOString();

export const getUnixTodayStart = () => {
	const today = moment();
	return today.startOf('day').unix();
};

export const getUnixTodayEnd = () => {
	const today = moment();
	return today.endOf('day').unix();
};

export const isMomentEqualToToday = mmDate => {
	if (!mmDate) {
		return false;
	}
	const today = moment();
	return today.isSame(mmDate, 'days');
};

const getWeekNums = momentObj => {
	const clonedMoment = moment(momentObj);
	const first = clonedMoment.startOf('month').week();
	let last = clonedMoment.endOf('month').week();
	if (last === 1) {
		// If the last week starts on January, next year
		last = clonedMoment.add(-1, 'weeks').week() + 1;
	}
	if (first > last) {
		last = first + last;
	}
	return last - first + 1;
};

const getDayData = (index, isCurrMonth = false, month) => {
	const today = moment();
	return {
		isToday: today.isSame(month.clone().date(index), 'day'),
		dayNum: index,
		isCurrMonth,
		d: index,
		m: month.format('M'),
		y: month.format('YYYY'),
	};
};

/**
 * Get an array with all days in a month
 * @param {*} month
 */
export const getMonthDaysArray = month => {
	const currMonth = month ? moment(month) : moment();
	const lastMonth = currMonth.clone().add(-1, 'months');
	const nextMonth = currMonth.clone().add(1, 'months');
	const lastDayOfLastMonth = Number(lastMonth.endOf('month').format('D'));
	const firstWeekDayOfMonth = Number(currMonth.startOf('month').format('d'));
	const lastWeekDayOfMonth = Number(currMonth.endOf('month').format('d'));
	const lastDayOfCurrMonth = Number(currMonth.endOf('month').format('D'));
	const monthFirstWeek = [];
	const monthMap = [];
	for (let i = 0; i < 7; i++) {
		if (firstWeekDayOfMonth <= i) {
			monthFirstWeek[i] = getDayData(i - firstWeekDayOfMonth + 1, true, currMonth);
		} else {
			monthFirstWeek[i] = getDayData(lastDayOfLastMonth - firstWeekDayOfMonth + i + 1, false, lastMonth);
		}
	}
	monthMap.push(monthFirstWeek);
	let lastNum = monthFirstWeek[monthFirstWeek.length - 1].dayNum + 1;
	for (let i = 1; i < getWeekNums(currMonth); i++) {
		const week = [];
		for (let j = 0; j < 7; j++) {
			if (lastDayOfCurrMonth >= lastNum + j) {
				week[j] = getDayData(lastNum + j, true, currMonth);
			} else {
				week[j] = getDayData(j - lastWeekDayOfMonth, false, nextMonth);
			}
		}
		lastNum = week[week.length - 1].dayNum + 1;
		monthMap.push(week);
	}
	return monthMap;
};

/**
 * formatQuarterDate
 * @param {Date, Moment} date
 */

export const formatQuarterDate = date => {
	const dateMoment = moment(date);
	const q = dateMoment.quarter();
	const year = dateMoment.year();
	// const q = quarters[Math.floor(date.getMonth() / 3) % 4];
	// const year = date.getFullYear();
	return `Q${q} ${year}`;
};

const getMoment = i => {
	if (i < moment().month()) {
		return moment().add(1, 'year').month(i);
	}
	return moment().month(i);
};

export const getMonths = () =>
	moment
		.months()
		.map((cur, i) => ({
			value: i,
			label: `${cur} - ${getMoment(i).year()}`,
			moment: getMoment(i),
			year: getMoment(i).year(),
		}))
		.sort((a, b) => a.year - b.year);

export const getYearsBetweenInterval = (start, end) => {
	const startParam = start || 2019;
	const endParam = end || 2101;
	const yearsDiff = endParam - startParam;
	const years = [];
	for (let year = 0; year < yearsDiff; year++) {
		const yearAux = startParam + year;
		years.push({ value: yearAux, label: `${yearAux}` });
	}
	return years;
};

export const getMomentByQuarterAndYear = (quarter, year) => {
	const q = `${quarter}`.slice(1);
	return moment().quarter(q).year(year);
};

export const getResponseDateByQuarterAndYear = (quarter, year) => {
	const responseDateMoment = getMomentByQuarterAndYear(quarter, year);
	return {
		startDate: responseDateMoment.startOf('quarter').format('YYYY-MM-DD'),
		endDate: responseDateMoment.endOf('quarter').format('YYYY-MM-DD'),
	};
};

/* FORMAT DATE EX: 4 HOURS AGO */
export const getTimeSince = date => {
	const seconds = Math.floor((new Date() - date) / 1000);

	let interval = seconds / 31536000;

	if (interval > 1) {
		return `${Math.floor(interval)} years ago`;
	}
	interval = seconds / 2592000;
	if (interval > 1) {
		return `${Math.floor(interval)} months ago`;
	}
	interval = seconds / 86400;
	if (interval > 1) {
		return `${Math.floor(interval)} days ago`;
	}
	interval = seconds / 3600;
	if (interval > 1) {
		return `${Math.floor(interval)} hours ago`;
	}
	interval = seconds / 60;
	if (interval > 1) {
		return `${Math.floor(interval)} minutes ago`;
	}

	return 'just now';
};

/* Format date to -> dd MMM yy */
export const getFormattedDate = date => {
	if (!date) return '';
	return format(new Date(`${date}`), 'dd MMM yy');
};

export const getFormattedDateAndHour = date => {
	if (!date) return '';
	return format(new Date(`${date}`), 'yyyy-MM-dd HH:mm:ss.s');
};

export const sortDateDescByKey = key => (a, b) => {
	return new Date(b[key]).getTime() - new Date(a[key]).getTime();
};

export const getYear = () => new Date().getFullYear();

export const getYearMonth = () => {
	const now = new Date();
	return format(now, 'yyyyMM');
};
