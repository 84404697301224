import styled from 'styled-components/macro';

export const Box = styled.div(({ theme: { size } }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	minWidth: size.lg,
	minHeight: size.lg,
	cursor: 'pointer',
}));

export const Icon = styled.i(({ theme: { color } }) => ({
	color: `${color.icon.sectionArrow} !important`,
}));
