import styled from 'styled-components/macro';

const variantStyle = ({ variant = 'primary', color, typo }) => {
	switch (variant) {
		case 'primary':
			return {
				...typo.link['03'],
				color: `${color.link.primary.default.label} !important`,
			};
		case 'secondary':
			return {
				...typo.link['04'],
				color: `${color.link.secondary.default.label} !important`,
			};
		case 'tertiary':
			return {
				...typo.link['05'],
				color: `${color.link.tertiary.default.label} !important`,
			};
		case 'quaternary':
			return {
				...typo.link['01'],
				flexWrap: 'nowrap',
			};
		default:
			return {};
	}
};

export const Label = styled.span(({ theme: { color, typo }, variant }) => ({
	...variantStyle({ variant, color, typo }),
}));
