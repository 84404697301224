import { useState, useCallback } from 'react';
import { cacheMaps } from 'constants/aemConstants';
import { handleClearCache, handleClearDeliveryCache } from 'utils/cacheUtils';
import useBrandDetails from './useBrandDetails';

const useClearCache = () => {
	const [clearCacheLoading, setClearCacheLoading] = useState(false);
	const [clearCacheDeliveryLoading, setClearCacheDeliveryLoading] = useState(false);
	const { currentType, onResetChapters, fetchChapters, fetchCurrentContent } = useBrandDetails();

	const defaultPreviewSuccessCallback = useCallback(async () => {
		await onResetChapters();
		await fetchChapters();
		await fetchCurrentContent();
	}, [fetchChapters, fetchCurrentContent, onResetChapters]);

	const clearCache = ({ maps, successCallback, shouldShowToast } = {}) => {
		return handleClearCache({
			maps,
			onSuccess: successCallback || defaultPreviewSuccessCallback,
			setLoading: setClearCacheLoading,
			shouldShowToast,
		});
	};

	const clearCacheDelivery = ({ maps, successCallback, shouldShowToast } = {}) => {
		return handleClearDeliveryCache({
			maps,
			onSuccess: successCallback,
			setLoading: setClearCacheDeliveryLoading,
			shouldShowToast,
		});
	};

	const clearPageCache = (successCallback = () => {}) => {
		const maps = [cacheMaps[currentType.type?.toUpperCase()], cacheMaps.PAGE];
		return handleClearCache({ maps, onSuccess: successCallback, setLoading: setClearCacheLoading });
	};

	return {
		clearCacheLoading,
		clearCacheDeliveryLoading,
		clearCache,
		clearCacheDelivery,
		clearPageCache,
	};
};

export default useClearCache;
