import useBrandData from 'hooks/useBrandData';
import PropTypes from 'prop-types';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import './Layout.scss';

const PrintLayout = ({ children }) => {
	const { themeBrand } = useBrandData();

	return (
		<div className="PrintLayout">
			<ThemeProvider theme={themeBrand}>{children}</ThemeProvider>
		</div>
	);
};

PrintLayout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default PrintLayout;
