import { Button } from 'components/button';
import useSignOut from 'hooks/useSignOut';
import { translations } from 'translation/en';
import * as S from './SignOutButton.style';

const SignOutButton = () => {
	const { signOut } = useSignOut();

	return (
		<S.Container>
			<Button variant="secondary-02" onClick={signOut}>
				{translations.SIGN_OUT_TEXT}
			</Button>
		</S.Container>
	);
};

export { SignOutButton };
