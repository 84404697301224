import BackgroundHeader from 'assets/img/background-header-HVC.jpg';
import complementaryTheme from 'themes/complementary/complementary';

const { neutral } = complementaryTheme;

const colors = {
	background: {
		banner: {
			background: `url(${BackgroundHeader}) no-repeat`, // header
		},
		secondary: '#f8f8f8', // sidebar and navbar (mobile)
		tertiary: '#f7f7f7', // footer
	},
	interactive: {
		// terms & conditions primary button modal
		primary: {
			default: {
				background: '#26828E',
				label: ' #FFFFFF',
				border: '#26828E',
			},
			hover: {
				background: '#FFFFFF',
				label: '#26828E',
				border: '#26828E',
			},
		},
		// search button
		primary02: {
			default: {
				background: '#26828E',
				label: '#fff',
				border: '#26828E',
				opacity: '0.65',
			},
		},
		// file button
		primary05: {
			default: {
				background: '#26828E',
				label: '#fff',
				border: '#26828E',
			},
			hover: {
				background: '#fff',
				label: '#26828E',
				border: '#26828E',
			},
		},
		// terms & conditions secondary button modal
		secondary: {
			default: {
				background: '#303268',
				label: ' #FFFFFF',
				border: '#303268',
			},
			hover: {
				background: '#FFFFFF',
				label: '#303268',
				border: '#303268',
			},
		},
		// sign out button
		secondary02: {
			default: {
				background: '#f8f8f8',
				label: '#3e3d40',
			},
			hover: {
				background: '#f8f8f8',
				label: '#3e3d40',
			},
		},
		// button approve modal
		secondary03: {
			default: {
				background: '#696D6F',
				label: '#fff',
				border: '#696D6F',
			},
			hover: {
				background: '#fff',
				label: '#696D6F',
				border: '#696D6F',
			},
		},
		// button help
		tertiary: {
			default: {
				background: '#fff',
				label: '#26828E',
			},
		},
		printModalButtons: {
			default: {
				background: '#26828E',
				label: '#fff',
				border: '#26828E',
			},
			hover: {
				background: '#26828E',
				label: '#fff',
				border: '#26828E',
			},
		},
		// properties and edit image modals buttons
		tertiary03: {
			default: {
				background: '#fff',
				label: '#181818',
				border: '#ccc',
			},
			hover: {
				background: '#fff',
				label: '#181818',
				border: '#181818',
			},
		},
		// mobile
		outlined: {
			default: {
				label: '#696D6F', // mobile search and help buttons icons
				icon: '#696D6F', // mobile menu button icon
				border: '#696D6F', // mobile button border
			},
		},
		// header buttons
		inverse: {
			default: {
				label: '#fff',
			},
		},
		discardChangesButtons: {
			default: {
				background: '#26828E',
				label: '#fff',
				border: '#26828E',
			},
			hover: {
				background: '#26828E',
				label: '#fff',
				border: '#26828E',
			},
		},
		printPageButtons: {
			default: {
				background: '#303268',
				label: '#fff',
				border: '#303268',
			},
			hover: {
				background: '#fff',
				label: '#303268',
				border: '#303268',
			},
		},
		dropDownButtons: {
			default: {
				label: '#fff',
			},
			active: {
				color: '#00000020',
				label: '#262626',
			},
			focusWithin: {
				background: 'transparent',
				label: '#ffffff',
				border: 'none',
			},
			hover: {
				background: 'transparent',
				label: '#fff',
				border: 'none',
			},
		},

		lightest: 'rgba(255, 255, 255, 0.1)',
	},
	field: {
		// search input
		primary: {
			text: '#555',
			placeholder: '#999',
		},
		// inputs edit image modal
		secondary: {
			text: '#676767',
			placeholder: '#676767',
		},
		// input properties modal
		tertiary: {
			text: '#676767',
			placeholder: '#676767',
		},
	},
	text: {
		primary: '#636466', // footer
		primary02: '#696D6F', // title chapter and section page

		titleModule: '#26828E',
		headline01: '#696D6F',
		headline02: '#696D6F',
		headline03: '#26828E', // h3 title content page
		headline04: '#26828E',
		headline05: '#26828E',
		headline06: '#696D6F',

		paragraph: '#696D6F', // paragraph content page
		specificationTitle: '#26828E', // specification title

		approveModalTitle: '#666666',
		approveModalDescription: '#999999',

		addFilesSubtitle: '#999999',

		secondary: '#696D6F', // terms & conditions title modal
		secondary02: '#696D6F', // title discard changes modal
		secondary03: '#181818', // title edit image modal

		tertiary: '#696D6F', // terms & conditions description modal
		tertiary03: '#181818', // title properties modal

		quaternary: '#696D6F', // title print modal
		quaternary02: '#333', // labels subtitle edit image modal
		quaternary03: '#000', // labels justification edit image modal

		quinary: '#696D6F', // titles items print modal
		quinary02: '#333', // subtitles properties modal
		quinary03: '#333', // title remove modal

		senary: '#696D6F', // title article page

		bullet: '#696D6F', // list item bullet

		imageCaption: '#696D6F', // image caption
	},
	link: {
		// sidebar item chapter
		primary: {
			default: {
				label: '#3e3d40',
			},
			hover: {
				label: '#3e3d40',
			},
		},
		// sidebar item section
		secondary: {
			default: {
				label: '#3e3d40',
			},
			hover: {
				background: 'transparent',
				label: '#3e3d40',
			},
			active: {
				label: '#3e3d40',
			},
		},
		// sidebar item article
		tertiary: {
			default: {
				label: '#3e3d40',
			},
			hover: {
				background: 'transparent',
				label: '#3e3d40',

				mobile: {
					label: '#3e3d40',
				},
			},
			active: {
				label: '#696D6F',
			},
		},
		// breadcrumb
		quaternary: {
			default: {
				label: '#696D6F',
			},
			hover: {
				label: '#696D6F',
			},
			active: {
				label: '#CA5010',
			},
		},
	},
	icon: {
		primary: '#fff', // arrow icon'#696D6F'
		primary02: '#696D6F', // print icon
		chapterArrow: '#333333', // chapter arrow icon
		sectionArrow: '#303268', // section arrow icon
		breadcrumbArrow: '#555555', // breadcrumb arrow icon
	},
	border: {
		primary: '#26828E', // search input
		secondary: '#B9b5aa',
		tertiary: '#333', // modal
		quaternary: '#eee', // module divider
	},
	shadow: {
		primary: 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(38, 130, 142, 0.6)', // search input
		secondary: 'inset 0 1px 0 rgba(255, 255, 255, 0.1)', // menu
	},
	neutral,
	table: {
		row: {
			background: {
				primary: '#f2f3f4',
				secondary: 'transparent',
			},
			cell: {
				label: '#333333',
				border: '2px solid #303268',
			},
		},
	},
};

export default colors;
