export const GLOBAL_ID = 'Global Id';
export const SPIRIT_CODE = 'Spirit Code';
export const USER_NAME = 'Name';
export const USER_TITLE = 'Title';
export const JOB_LEVEL_CODE = 'Job Level Code';
export const BRAND = 'Brand';
export const REGION = 'Region';
export const TYPE = 'Type';
export const TITLE = 'Content Title';
export const LAST_VIEWED = 'Last Viewed';

export const pieTableColumns = [
	GLOBAL_ID,
	SPIRIT_CODE,
	USER_NAME,
	USER_TITLE,
	JOB_LEVEL_CODE,
	BRAND,
	REGION,
	TYPE,
	TITLE,
	LAST_VIEWED,
];
