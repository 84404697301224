import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

const getDataFromToken = token => {
	try {
		return jwtDecode(token);
	} catch (error) {
		return null;
	}
};

const isExpired = token => {
	const data = getDataFromToken(token);
	const d = new Date();

	if (!data) {
		return true;
	}

	return data.exp < d.getTime() / 1000;
};

const hasAppValidToken = () => {
	const token = Cookies.get(process.env.REACT_APP_TOKEN_KEY);
	const data = getDataFromToken(token);

	if (!data) {
		return false;
	}

	const expired = isExpired(token);

	if (!expired && data.globalId && data.user && data.user.employeeType && data.user.spiritCode && data.user.spiritCode.length) {
		return true;
	}

	return false;
};

const getJWTUser = () => {
	const data = getDataFromToken(Cookies.get(process.env.REACT_APP_TOKEN_KEY));

	if (!data) return {};

	return {
		globalId: data.globalId,
		spiritCode: data.user.spiritCode,
		email: data.user.email,
		name: data.user.name,
		title: data.user.title,
		mobile: data.user.mobile,
		telephoneNumber: data.user.telephoneNumber,
		department: data.user.department,
		subDept: data.user.subDept,
		subDeptCode: data.user.subDeptCode,
		hasContactorAccessGroup: data.user.hasContactorAccessGroup,
		jobLevelCode: data.user.jobLevelCode,
		jobCode: data.user.jobCode,
		employeeStatus: data.user.employeeStatus,
		employeeType: data.user.employeeType,
		pronouns: data.user.pronouns,
		whenCreated: data.user.whenCreated,
		whenChanged: data.user.whenChanged,
		userAccountControl: data.user.userAccountControl,
		preferredLanguage: data.user.preferredLanguage,
	};
};

export { getDataFromToken, isExpired, hasAppValidToken, getJWTUser };
