import { localStorageKeys } from 'constants/localStorageConstants';
import { hasExtension } from './fileUtil';

export const dynamicLinkUrl = to => {
	const isFile = hasExtension(to);
	if (to?.startsWith('/content/dam') && isFile) {
		return `${process.env.REACT_APP_AEM_URL}${to}`;
	}
	return to;
};

export const getImageUrl = imageData => {
	if (imageData?.file && imageData?.file.includes('localFile')) {
		return imageData.file;
	}
	if (!imageData?.imageContentPath) {
		return imageData?.file;
	}
	return dynamicLinkUrl(imageData?.imageContentPath);
};

export const isExternalLink = anchorElement => {
	const currentHostname = window.location.hostname;

	const targetHostname = anchorElement.hostname;

	return currentHostname !== targetHostname;
};

export const setUrlToStorage = () => {
	sessionStorage.setItem(localStorageKeys.STORED_URL, window.location.href);
};
