import { useCallback } from 'react';
import { translations } from 'translation/en';
import useBrandDetails from './useBrandDetails';
import useApprovePublish from './useApprovePublish';
import { useDeleteContentFragment } from './useDeleteContentFragment';
import { useDisapprovePage } from './useDisapprovePage';

export const useDeleteModule = () => {
	const { currentType } = useBrandDetails();
	const { isPageApproved } = useApprovePublish();
	const { loading, response, error, removeContentFragment } = useDeleteContentFragment({
		successMessage: translations.MODULE_SUCCESS_DELETED,
		deleteMessage: translations.MODULE_DELETE_FAILED,
		handleError: true,
	});
	const { isLoadingDisapprovePage, disapprovePage } = useDisapprovePage();

	const deleteModule = useCallback(
		async ({ path }) => {
			return removeContentFragment({
				pagePath: currentType?.path,
				cfType: currentType?.type,
				data: { contentPath: path },
			}).then(deleteResult => {
				// TODO: remove this !deleteResult?.error when handling throw in useRequest
				if (isPageApproved && !deleteResult?.error) {
					return disapprovePage([currentType]);
				}
				return deleteResult;
			});
		},
		[currentType, disapprovePage, isPageApproved, removeContentFragment]
	);
	return { loading: [loading, isLoadingDisapprovePage].some(Boolean), response, error, deleteModule };
};
