import CaptionLogo from 'assets/img/logos/caption.svg';
import CaptionLogoSidebar from 'assets/img/logos/caption_sidebar.svg';
import complementaryTheme from 'themes/complementary/complementary';
import colors from './captionColors';
import typographyTheme from './captionTypo';

const logo = {
	footer: CaptionLogo, // logo in the footer
	loading: CaptionLogo, // logo in the loading
	sidebar: CaptionLogoSidebar, // logo in the sidebar
	mobile: CaptionLogo, // logo in the navbar
	size: {
		sidebar: {
			width: '232px',
		},
		footer: {
			width: '170px',
		},
		navbar: {
			width: '200px',
		},
		loading: {
			width: '168px',
			height: '91px',
		},
	},
};

const copyrightText = {
	padding: '5px 0 5px 24px',
};

const caption = {
	brandId: 'cp',
	name: 'Caption By Hyatt',
	logo,
	color: colors,
	copyrightText,

	font: {
		family: {
			helveticaNeue: 'Helvetica Neue',
			gothamBook: 'Gotham Book',
			arialSansSerif: 'Arial, Helvetica, sans-serif',
			poynterRegular: 'Poynter Regular',
			jostRegular: 'Jost Regular',
			jostSemiBold: 'Jost SemiBold',
			jostMedium: 'Jost Medium',
			handWrittenCaption: 'Hand Written Caption',
		},
	},
};

const captionTheme = {
	...caption,
	...typographyTheme(caption),
	...complementaryTheme,
};

export default captionTheme;
