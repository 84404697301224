export const EditNavigationTypes = {
	EDIT: 'edit',

	MOVE: 'move',
	ADD: 'add',
	CHECK: 'check',
	PUBLISH: 'publish',
	SAVE: 'save',

	REMOVE: 'remove',

	CLOSE: '',
};
