import { Button } from 'components/button';
import { CheckBox } from 'components/checkBox';
import { Modal } from 'components/modals/modal';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { translations } from 'translation/en';
import { mappingSelectAllData, updateItemById } from 'utils/editNavigationUtil';
import { Loading } from 'components/loadingOverlay';
import { useTheme } from 'styled-components';
import { getFormattedDateAndHour } from 'utils/dateUtil';
import { CheckListModalSkeleton } from 'components/loaders/checkListModalSkeleton';
import { hasLength } from 'utils/arrayUtil';
import { DESCRIPTION_CHECKLIST_MODAL_TEST_ID, LIST_MODIFIED_ITEMS_ID } from './constants/testIdConstants';
import * as S from './CheckListModal.style';

const CheckListModal = ({ loading, loadingSave, open, title, collections, buttonName, description, onClose, onSave }) => {
	const { logo } = useTheme();
	const [domReady, setDomReady] = useState(false);
	const portalElement = document.getElementById('root');

	const [isAllSelected, setIsAllSelected] = useState(false);
	const [selectedItems, setSelectedItems] = useState([]);
	const [items, setItems] = useState(mappingSelectAllData(collections)(false));

	useEffect(() => {
		setItems(mappingSelectAllData(collections)(false));
	}, [collections]);

	useEffect(() => {
		setItems(mappingSelectAllData(collections)(false));
	}, [collections]);

	useEffect(() => {
		setDomReady(true);

		return () => {
			setDomReady(false);
		};
	}, []);

	useEffect(() => {
		if (open) {
			setIsAllSelected(false);
			setSelectedItems([]);
		}
	}, [open]);

	const handleSelectedAll = e => {
		const payload = mappingSelectAllData(items)(e?.target?.checked);

		setItems(payload);
		setIsAllSelected(e?.target?.checked);

		if (e?.target?.checked) {
			const itemsSelected = [];
			payload?.forEach(item => {
				if (!item.disabled) {
					itemsSelected.push(item);
				}
				item.articles?.forEach(article => {
					itemsSelected.push(article);
				});
			});
			setSelectedItems(itemsSelected);
		} else {
			setSelectedItems([]);
		}
	};

	const handleSelectedItem = selectedItem => e => {
		const payload = [...items];

		updateItemById(payload)(selectedItem.id, e?.target?.checked);
		setItems(payload);

		if (e?.target?.checked) {
			setSelectedItems([...selectedItems, selectedItem]);
		} else {
			setSelectedItems(selectedItems.filter(item => item.id !== selectedItem.id));
		}
	};

	const handleSave = async e => {
		e.preventDefault();
		await onSave(selectedItems);
	};

	const renderCheckListItems = () => {
		if (loading) {
			return <CheckListModalSkeleton />;
		}

		return (
			<div>
				{hasLength(collections) && (
					<CheckBox variant="secondary" label={translations.ALL} onChange={handleSelectedAll} checked={isAllSelected} />
				)}

				<S.ModifiedList aria-label={LIST_MODIFIED_ITEMS_ID}>
					{items?.map(
						item =>
							((item.disabled && item?.articles?.length > 0) || !item.disabled) && (
								<div key={item.id}>
									<S.ModifiedItem>
										<S.Wrapper>
											<CheckBox
												variant="secondary"
												checked={item.selected || false}
												onChange={handleSelectedItem(item)}
												label={item.title}
												disabled={item.disabled}
												title={item.disabled ? translations.ALREADY_APPROVED : ''}
											/>
											<S.ModifiedDate>
												{translations.LAST_MODIFIED_TEXT} -{' '}
												{getFormattedDateAndHour(item.modificationDate) || '-'}
											</S.ModifiedDate>
										</S.Wrapper>
									</S.ModifiedItem>

									<S.ModifiedList>
										{item?.articles?.map(article => (
											<S.ModifiedItem key={article.id}>
												<S.Wrapper>
													<CheckBox
														variant="secondary"
														checked={article.selected || false}
														onChange={handleSelectedItem(article)}
														label={<S.ModifiedLabel>{article.title}</S.ModifiedLabel>}
													/>

													<S.ModifiedDate>
														{translations.LAST_MODIFIED_TEXT} -{' '}
														{getFormattedDateAndHour(article.modificationDate) || '-'}
													</S.ModifiedDate>
												</S.Wrapper>
											</S.ModifiedItem>
										))}
									</S.ModifiedList>
								</div>
							)
					)}
				</S.ModifiedList>
			</div>
		);
	};

	const modalComponent = open && (
		<S.CheckListModal
			open={open}
			closeOnOverlayClick={false}
			onClose={onClose}
			closeIcon={<i className="icon-close-slim" />}
			hideExpand
		>
			{loadingSave && (
				<Loading.Overlay>
					<Loading.LoadingLogo logo={logo?.loading} size={logo?.size.loading} />
				</Loading.Overlay>
			)}

			<Modal.Header />
			<S.CheckListModalMain>
				<div>
					<S.Title>{title}</S.Title>
					{!loading && <S.Description data-testid={DESCRIPTION_CHECKLIST_MODAL_TEST_ID}>{description}</S.Description>}
				</div>

				{renderCheckListItems()}
			</S.CheckListModalMain>
			<Modal.Footer style={{ background: '#fff' }}>
				<Button variant="secondary-03" onClick={handleSave} disabled={loadingSave}>
					{buttonName}
				</Button>
			</Modal.Footer>
		</S.CheckListModal>
	);

	return domReady ? ReactDOM.createPortal(modalComponent, portalElement) : null;
};

CheckListModal.propTypes = {
	open: PropTypes.bool,
	loading: PropTypes.bool,
	loadingSave: PropTypes.bool,
	title: PropTypes.string,
	collections: PropTypes.array,
	onSave: PropTypes.func,
	onClose: PropTypes.func,
	buttonName: PropTypes.string,
	description: PropTypes.string,
};

CheckListModal.defaultProps = {
	open: false,
	loading: false,
	loadingSave: false,
	title: '',
	collections: [],
	onSave: () => {},
	onClose: () => {},
	buttonName: '',
	description: '',
};

export { CheckListModal };
