/* eslint-disable max-len */

export const URLRegex =
	/(?:(?:https?|ftp|file):\/\/|www.|ftp.)(?:([-A-Z0-9+&@#/%=~_|$?!:,.]*)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:([-A-Z0-9+&@#/%=~_|$?!:,.]*)|[A-Z0-9+&@#/%=~_|$])/;

export const EMAILRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/i;

export const HTMLRegex = /<[a-z][\s\S]*>/;

export const SpecialCharactersRegex = /[^a-zA-Z0-9]/g;
