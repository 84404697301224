import jdvLogoHorizontal from 'assets/img/logos/jdv-horizontal.svg';
import jdvLogo from 'assets/img/logos/jdv-white.svg';
import JdvHyattLogo from 'assets/img/logos/jdv.svg';
import complementaryTheme from 'themes/complementary/complementary';
import colors from './joieDeVivreColors';
import typographyTheme from './joieDeVivreTypo';

const logo = {
	footer: jdvLogoHorizontal, // logo in the footer
	loading: JdvHyattLogo, // logo in the loading
	sidebar: jdvLogo, // logo in the sidebar
	mobile: jdvLogo, // logo in the navbar
	size: {
		sidebar: {
			width: '100px',
			height: '145px',
		},
		footer: {
			height: '80px',
		},
		navbar: {
			width: '77px',
		},
		loading: {
			width: '107px',
			height: '107px',
		},
	},
};

const joiedeVivre = {
	brandId: 'jd',
	name: 'JdV by HYATT',
	logo,
	color: colors,

	font: {
		family: {
			helveticaNeue: 'Helvetica Neue',
			gothamBook: 'Gotham Book',
			redHatTextBold: 'RedHatText Bold',
			redHatTextMedium: 'RedHatText Medium',
			redHatTextRegular: 'RedHatText Regular',
			arialSansSerif: 'Arial, Helvetica, sans-serif',
			poynterRegular: 'Poynter Regular',
		},
	},
};

const joiedeVivreTheme = {
	...joiedeVivre,
	...typographyTheme(joiedeVivre),
	...complementaryTheme,
};

export default joiedeVivreTheme;
