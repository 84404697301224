import { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { MODULE_ACTION_TYPES } from 'constants/moduleConstants';
import { DeleteModulePopover } from './components/deleteModulePopover';
import { ChangeModulePopover } from './components/changeModulePopover';
import './ChangeDeleteModule.scss';

const ChangeDeleteModule = ({ paragraph }) => {
	const initialState = {
		type: MODULE_ACTION_TYPES.RESET,
	};

	const [selectedParagraph, setSelectedParagraph] = useState();
	const [state, setDispatch] = useState(initialState);

	const isActiveChange = state.type === MODULE_ACTION_TYPES.CHANGE && selectedParagraph.vcmId === paragraph.vcmId;
	const isActiveDelete = state.type === MODULE_ACTION_TYPES.DELETE && selectedParagraph.vcmId === paragraph.vcmId;

	const deleteClassNames = classNames('ChangeDeleteModule__button', { active: isActiveDelete });
	const changeClassNames = classNames('ChangeDeleteModule__button', { active: isActiveChange });

	const handleDelete = payload => {
		setSelectedParagraph(payload);
		setDispatch({
			type: MODULE_ACTION_TYPES.DELETE,
		});
	};

	const handleChange = payload => {
		setSelectedParagraph(payload);
		setDispatch({
			type: MODULE_ACTION_TYPES.CHANGE,
		});
	};

	const onClose = () => {
		setDispatch(initialState);
	};

	return (
		<>
			<div className="ChangeDeleteModule">
				<button aria-label="delete-module-action" className={deleteClassNames} onClick={() => handleDelete(paragraph)}>
					<i className="icon icon-trash" />
				</button>
				<button aria-label="change-module-action" className={changeClassNames} onClick={() => handleChange(paragraph)}>
					<i className="icon-four-squares" />
				</button>
			</div>

			<DeleteModulePopover
				show={state.type === MODULE_ACTION_TYPES.DELETE}
				paragraph={selectedParagraph}
				onClose={onClose}
			/>
			<ChangeModulePopover
				show={state.type === MODULE_ACTION_TYPES.CHANGE}
				paragraph={selectedParagraph}
				onClose={onClose}
			/>
		</>
	);
};

ChangeDeleteModule.propTypes = {
	paragraphId: PropTypes.string.isRequired,
	paragraph: PropTypes.shape({
		vcmId: PropTypes.string,
	}),
};

ChangeDeleteModule.defaultProps = {
	paragraph: null,
};

export { ChangeDeleteModule };
