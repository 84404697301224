import React from 'react';
import Skeleton from 'react-loading-skeleton';
import './ModuleSkeleton.scss';

const ModuleSkeleton = () => (
	<div className="ModuleSkeleton">
		<div className="ModuleSkeleton__wrapper">
			<div className="ModuleSkeleton__header">
				<Skeleton className="ModuleSkeleton__title" />
			</div>
			<div className="ModuleSkeleton__body">
				<div className="ModuleSkeleton__wrapper-text">
					<Skeleton className="ModuleSkeleton__sub-tittle" height={30} width={250} />

					<div className="ModuleSkeleton__container">
						<Skeleton className="ModuleSkeleton__container-item-large" count={4} />
						<Skeleton className="ModuleSkeleton__container-item-small" />
					</div>
					<div className="ModuleSkeleton__container">
						<Skeleton className="ModuleSkeleton__container-item-small" />
						<Skeleton className="ModuleSkeleton__container-item-large" count={4} />
					</div>
				</div>
				<div className="ModuleSkeleton__wrapper-img">
					<Skeleton className="ModuleSkeleton__img" />
				</div>
			</div>
		</div>
	</div>
);

export { ModuleSkeleton };
