import { useCallback, useEffect } from 'react';
import { putImage } from 'services/imageService';
import { getParentPath, normalizedPath } from 'utils/contentFragmentUtil';
import { showToast } from 'utils/toastUtil';
import { normalizedString } from 'utils/stringUtil';
import { executeIfTruthy } from 'utils/functionUtils';
import { translations } from 'translation/en';
import { PAGE_TYPES } from 'constants/pageConstants';
import { PARAGRAPH_PROPS } from 'constants/paragraphConstants';
import useBrandDetails from './useBrandDetails';
import { useRequest } from './useRequest';
import useApprovePublish from './useApprovePublish';
import { useDisapprovePage } from './useDisapprovePage';

export const useUpdateImage = () => {
	const { currentType } = useBrandDetails();
	const { loading: loadingUpdate, data: response, error, run, clear } = useRequest(putImage);
	const { isPageApproved, handleStaleStatus } = useApprovePublish();
	const { isLoadingDisapprovePage, disapprovePage } = useDisapprovePage();

	useEffect(() => {
		if (response) {
			clear();
		}
	}, [response, clear]);

	useEffect(() => {
		if (error) {
			clear();
			showToast({ type: 'error', message: translations.FAILED_UPDATE_IMAGE });
		}
	}, [error, clear]);

	const updateImage = useCallback(
		({ imageData }) => {
			const type = currentType?.type || PAGE_TYPES.CHAPTER_TYPE;
			const baseParentPath = `${getParentPath(currentType?.path)}/${type}`;
			let parentPath = '';

			const chapterName = normalizedString(currentType?.chapterTitle?.toLowerCase());
			const sectionName = normalizedString(currentType?.sectionTitle?.toLowerCase());
			const articleName = normalizedString(currentType?.articleTitle?.toLowerCase());

			switch (type) {
				case PAGE_TYPES.SECTION_TYPE:
					parentPath = executeIfTruthy(sectionName, normalizedPath(`${baseParentPath}/${sectionName}`));
					break;
				case PAGE_TYPES.ARTICLE_TYPE:
					parentPath = executeIfTruthy(articleName, normalizedPath(`${baseParentPath}/${articleName}`));
					break;
				default:
					parentPath = executeIfTruthy(chapterName, normalizedPath(`${baseParentPath}/${chapterName}`));
					break;
			}

			const { relatedPath, ...normalizedImageDataWithoutRelatedPath } = imageData;

			let payload = {
				parentPath,
				relatorPath: imageData?.imagePath,
				...normalizedImageDataWithoutRelatedPath,
			};

			if (imageData.orgFile) {
				payload = {
					imageContent: imageData?.orgFile,
					...payload,
				};

				delete payload['orgFile'];
			}

			const updateImagePromise = run({
				pagePath: currentType?.path,
				cfType: PARAGRAPH_PROPS.RELATOR_IMAGE_KEY,
				data: payload,
			});

			let disapprovePagePromise = Promise.resolve();
			if (isPageApproved) {
				disapprovePagePromise = disapprovePage([currentType]);
			}

			return Promise.all([updateImagePromise, disapprovePagePromise]).then(responses => {
				const isSuccess = responses.some(res => res?.data);

				if (isSuccess) {
					handleStaleStatus(true);
				}

				return responses;
			});
		},
		[currentType, isPageApproved, handleStaleStatus, disapprovePage, run]
	);

	return { loadingUpdate: [loadingUpdate, isLoadingDisapprovePage].some(Boolean), response, error, updateImage };
};
