/* eslint-disable no-restricted-syntax */
export const removeUndefinedFields = obj =>
	Object.keys(obj).reduce((acc, key) => {
		const accAux = acc;
		if (obj[key] !== undefined) {
			accAux[key] = obj[key];
		}
		return accAux;
	}, {});

export const removeEmptyFields = obj => {
	// eslint-disable-next-line no-unused-vars
	return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== ''));
};

/**
 * returnObjectIfInstanceOf
 */
export const returnObjectIfInstanceOf = (value, instanceValue) => {
	if (value instanceof instanceValue) {
		return value;
	}
	throw new Error(`This object is not a instance of ${instanceValue}`);
};

export const toJSONIfExists = obj => {
	if (obj && obj.toJSON instanceof Function) {
		return obj.toJSON();
	}
	return undefined;
};

export const isEmpty = obj => {
	if (!obj) {
		return true;
	}
	return Object.keys(obj).length === 0;
};

export const isNotEmpty = obj => !isEmpty(obj);

export const objectKeys = obj => Object.keys(obj);

export const isNonValue = value => {
	return value === null || value === undefined;
};

export const isEqual = (object1, object2) => JSON.stringify(object1) === JSON.stringify(object2);
