import { translations } from 'translation/en';
import { func } from 'prop-types';
import * as S from './SaveBarOptions.style';

const SaveBarOptions = ({ onSave, onSaveAsDraft, onClose }) => {
	return (
		<S.SaveBarOptions>
			<S.BarContainer>
				<S.ButtonAction onClick={onSave}>{translations.SAVE}</S.ButtonAction>
				<S.ButtonAction onClick={onSaveAsDraft}>{translations.SAVE_AS_DRAFT}</S.ButtonAction>
			</S.BarContainer>

			<S.ButtonOnClose onClick={onClose}>{translations.STOP_EDITING}</S.ButtonOnClose>
		</S.SaveBarOptions>
	);
};

SaveBarOptions.propTypes = {
	onSave: func.isRequired,
	onSaveAsDraft: func.isRequired,
	onClose: func.isRequired,
};

export { SaveBarOptions };
