/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import useBrandDetails from 'hooks/useBrandDetails';
import { translations } from 'translation/en';
import { hasLength } from 'utils/arrayUtil';
import { replaceArgs } from 'utils/stringUtil';
import { CheckListModal } from 'components/modals/checkListModal';
import useListItemsToApprove from 'hooks/useListItemsToApprove';
import useApprovePublish from 'hooks/useApprovePublish';
import { usePublishPage } from 'hooks/usePublishPage';
import { useApprovePage } from 'hooks/useApprovePage';
import { APPROVE_STATUS } from './constants/approvePublishConstants';

const PublishModal = ({ open, title, onClose }) => {
	const { currentType } = useBrandDetails();
	const { isPageApproved } = useApprovePublish();

	const { loadingItems, itemsToApprove, fetchItems } = useListItemsToApprove();
	const { approveLoading, approvedPage, approvePageTree } = useApprovePage();
	const { publishLoading, publishedPage, publishPage } = usePublishPage();

	const isLoading = [approveLoading, publishLoading].some(Boolean);

	const handlePublishModalDescription = useMemo(() => {
		if (hasLength(itemsToApprove?.filter(item => (item.disabled && item?.articles?.length > 0) || !item.disabled))) {
			return replaceArgs(
				`${translations.PAGES_UNDER_THAT_ARE_UNAPPROVED} ${translations.WANT_TO_PUBLISH}`,
				currentType?.type
			);
		}

		if (!isPageApproved) {
			return translations.PAGE_IS_NOT_APPROVED_PUBLISH;
		}

		return replaceArgs(translations.DO_YOU_WANT_TO, translations.PUBLISH, currentType?.type);
	}, [currentType?.type, isPageApproved, itemsToApprove]);

	const onPublish = useCallback(async () => {
		await publishPage({ checkApprove: false });
	}, [publishPage]);

	const onApproveAndPublish = async selectedItems => {
		if (!isPageApproved && currentType) {
			selectedItems.push({ ...currentType, id: currentType?.path });
		}

		if (selectedItems.length > 0) {
			await approvePageTree(selectedItems.map(item => ({ ...item, path: item.id })));
		}
	};

	const onSave = async selectedItems => {
		return !isPageApproved ? onApproveAndPublish(selectedItems) : onPublish();
	};

	useEffect(() => {
		if (open) {
			fetchItems();
		}
	}, [open, fetchItems]);

	useEffect(() => {
		if (approvedPage?.status === APPROVE_STATUS.FINISHED) {
			onPublish();
		}
	}, [approvedPage]);

	useEffect(() => {
		if (publishedPage) {
			onClose();
		}
	}, [publishedPage, onClose]);

	const modalButtonName =
		!isPageApproved || hasLength(itemsToApprove) ? translations.APPROVE_AND_PUBLISH : translations.PUBLISH;

	return (
		<CheckListModal
			open={open}
			loading={loadingItems}
			loadingSave={isLoading}
			title={title}
			description={handlePublishModalDescription}
			collections={itemsToApprove}
			buttonName={modalButtonName}
			onSave={onSave}
			onClose={onClose}
		/>
	);
};

PublishModal.propTypes = {
	open: PropTypes.bool,
	title: PropTypes.string,
	onClose: PropTypes.func,
};

PublishModal.defaultProps = {
	open: false,
	title: '',
	onClose: () => {},
};

export { PublishModal };
