import styled from 'styled-components/macro';

export const Title = styled.h1(({ theme: { color, breakpoint, typo } }) => ({
	flexWrap: 'nowrap',
	wordWrap: 'anywhere',
	lineHeight: 1.1,

	color: color.text.primary02,
	...typo.headline['01'],

	[breakpoint.md()]: {
		...typo.headline['01']?.lg,
	},
}));
