import { ModuleMenu } from 'components/module';
import PropTypes from 'prop-types';
import { Divider, ModuleContainer, ContentWrapper } from './ModuleTemplate.style';

const ModuleTemplate = ({ paragraphId, type, children, isFirst, startTitle, isOnPrintPage, ...props }) => {
	return (
		<ModuleContainer {...props}>
			{!isFirst && startTitle && type && !isOnPrintPage && <Divider />}
			<ModuleMenu paragraphId={paragraphId} {...props}>
				<ContentWrapper>{children}</ContentWrapper>
			</ModuleMenu>
		</ModuleContainer>
	);
};

ModuleTemplate.propTypes = {
	paragraphId: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	type: PropTypes.string.isRequired,
	isFirst: PropTypes.bool.isRequired,
	startTitle: PropTypes.string,
	isOnPrintPage: PropTypes.bool,
};

ModuleTemplate.defaultProps = {
	isOnPrintPage: false,
	startTitle: '',
};

export { ModuleTemplate };
