import classNames from 'classnames';
import { bool } from 'prop-types';
import './ChooseBrandsSkeleton.scss';
import Skeleton from 'react-loading-skeleton';

const ChooseBrandsSkeleton = ({ hasMargin }) => {
	const brandsBoxClassNames = classNames('ChooseBrandsSkeleton', {
		'ChooseBrandsSkeleton--has-margin': hasMargin,
	});

	return (
		<section className={brandsBoxClassNames}>
			<div style={{ display: 'inline-flex' }}>
				<div className="ChooseBrandsSkeleton__container">
					<div className="ChooseBrandsSkeleton__row">
						<Skeleton className="ChooseBrandsSkeleton__item" />
						<Skeleton className="ChooseBrandsSkeleton__item" />
					</div>

					<div className="ChooseBrandsSkeleton__section-title">
						<span className="ChooseBrandsSkeleton__title">
							<Skeleton className="ChooseBrandsSkeleton__title-skeleton" />
						</span>
					</div>
					<div className="ChooseBrandsSkeleton__row">
						<Skeleton className="ChooseBrandsSkeleton__item" />
						<Skeleton className="ChooseBrandsSkeleton__item" />
						<Skeleton className="ChooseBrandsSkeleton__item" />
						<Skeleton className="ChooseBrandsSkeleton__item" />
						<Skeleton className="ChooseBrandsSkeleton__item" />
					</div>
					<div className="ChooseBrandsSkeleton__row">
						<Skeleton className="ChooseBrandsSkeleton__item" />
						<Skeleton className="ChooseBrandsSkeleton__item" />
						<Skeleton className="ChooseBrandsSkeleton__item" />
					</div>

					<div className="ChooseBrandsSkeleton__section-title">
						<span className="ChooseBrandsSkeleton__title">
							<Skeleton className="ChooseBrandsSkeleton__title-skeleton" />
						</span>
					</div>
					<div className="ChooseBrandsSkeleton__row">
						<Skeleton className="ChooseBrandsSkeleton__item" />
						<Skeleton className="ChooseBrandsSkeleton__item" />
						<Skeleton className="ChooseBrandsSkeleton__item" />
						<Skeleton className="ChooseBrandsSkeleton__item" />
					</div>
					<div className="ChooseBrandsSkeleton__row">
						<Skeleton className="ChooseBrandsSkeleton__item" />
						<Skeleton className="ChooseBrandsSkeleton__item" />
						<Skeleton className="ChooseBrandsSkeleton__item" />
					</div>
					<div className="ChooseBrandsSkeleton__section-title">
						<span className="ChooseBrandsSkeleton__title">
							<Skeleton className="ChooseBrandsSkeleton__title-skeleton" />
						</span>
					</div>
					<div className="ChooseBrandsSkeleton__row">
						<Skeleton className="ChooseBrandsSkeleton__item" />
						<Skeleton className="ChooseBrandsSkeleton__item" />
						<Skeleton className="ChooseBrandsSkeleton__item" />
					</div>

					<div className="ChooseBrandsSkeleton__section-title">
						<span className="ChooseBrandsSkeleton__title">
							<Skeleton className="ChooseBrandsSkeleton__title-skeleton" />
						</span>
					</div>
					<div className="ChooseBrandsSkeleton__row">
						<Skeleton className="ChooseBrandsSkeleton__item" />
						<Skeleton className="ChooseBrandsSkeleton__item" />
						<Skeleton className="ChooseBrandsSkeleton__item" />
						<Skeleton className="ChooseBrandsSkeleton__item" />
						<Skeleton className="ChooseBrandsSkeleton__item" />
					</div>
					<div className="ChooseBrandsSkeleton__row">
						<Skeleton className="ChooseBrandsSkeleton__item" />
						<Skeleton className="ChooseBrandsSkeleton__item" />
						<Skeleton className="ChooseBrandsSkeleton__item" />
						<Skeleton className="ChooseBrandsSkeleton__item" />
						<Skeleton className="ChooseBrandsSkeleton__item" />
					</div>
				</div>
			</div>
		</section>
	);
};

ChooseBrandsSkeleton.propTypes = {
	hasMargin: bool,
};

ChooseBrandsSkeleton.defaultProps = {
	hasMargin: false,
};
export { ChooseBrandsSkeleton };
