import { arrayOf, node, oneOfType } from 'prop-types';
import React, { createContext, useCallback, useMemo, useState } from 'react';
import { ROLE_VALUE } from '../constants/modalAnalyticsConstants';
import { translation } from '../constants/translation';
import { categoriesLocationType } from '../utils/categoriesLocationType';

export const AnalyticsContext = createContext();

export const AnalyticsProvider = ({ children }) => {
	const [configApp, setConfigApp] = useState({});
	const [activeId, setActiveId] = useState('');

	const [locationType, setLocationType] = useState({});
	const [metrics, setMetrics] = useState([]);
	const [metricsTable, setMetricsTable] = useState([]);
	const [isLoading, setIsLoading] = useState({ chart: false, table: false });

	const { appName, getMetricsByType, getMetrics } = configApp;
	const { nameKey } = locationType;

	const getMetricsForChart = useCallback(
		(params, contentId) => {
			setIsLoading(prevIsLoading => ({ ...prevIsLoading, chart: true }));
			getMetricsByType({
				appName,
				contentId: activeId || contentId,
				...params,
			})
				.then(response => setMetrics(response))
				.finally(() => {
					setIsLoading(prevIsLoading => ({ ...prevIsLoading, chart: false }));
				});
		},
		[activeId, appName, getMetricsByType]
	);

	const getMetricsForTable = useCallback(
		params => {
			setIsLoading(prevIsLoading => ({ ...prevIsLoading, table: true }));
			getMetrics({
				appName,
				contentId: activeId,
				...params,
			})
				.then(response => setMetricsTable(response))
				.finally(() => {
					setIsLoading(prevIsLoading => ({ ...prevIsLoading, table: false }));
				});
		},
		[activeId, appName, getMetrics]
	);

	const counterMetrics = useCallback(arrayData => {
		return arrayData.reduce((acc, current) => acc + current.total, 0);
	}, []);

	const getTotalEmail = useCallback(() => {
		const result = metrics.filter(item => item.viewedBy === 'email');
		return counterMetrics(result);
	}, [counterMetrics, metrics]);

	const getTotalOthers = useCallback(() => {
		const result = metrics.filter(item => item.viewedBy !== 'email');
		return counterMetrics(result);
	}, [counterMetrics, metrics]);

	const isMetricsRoles = useCallback(
		locationName => {
			if (locationType.value === ROLE_VALUE && locationName === 'Others') {
				return true;
			}

			return false;
		},
		[locationType]
	);

	const getLabelByAbbr = useCallback(
		(currentAbbr, locationType = nameKey) => {
			const categoriesTypes = categoriesLocationType(locationType);

			const location = categoriesTypes.find(item => item.abbr.includes(currentAbbr));
			return location?.label || translation.OTHERS;
		},
		[nameKey]
	);

	const values = useMemo(
		() => ({
			configApp,
			setConfigApp,
			setActiveId,
			activeId,
			getMetricsForChart,
			getMetricsForTable,
			locationType,
			setLocationType,
			metrics,
			setMetrics,
			metricsTable,
			setMetricsTable,
			getTotalEmail,
			getTotalOthers,
			counterMetrics,
			getLabelByAbbr,
			isMetricsRoles,
			isLoading,
		}),
		[
			configApp,
			activeId,
			getMetricsForChart,
			getMetricsForTable,
			locationType,
			metrics,
			metricsTable,
			getTotalEmail,
			getTotalOthers,
			counterMetrics,
			getLabelByAbbr,
			isMetricsRoles,
			isLoading,
		]
	);

	return <AnalyticsContext.Provider value={values}>{children}</AnalyticsContext.Provider>;
};

AnalyticsProvider.propTypes = {
	children: oneOfType([arrayOf(node), node]),
};

AnalyticsProvider.defaultProps = {
	children: null,
};
