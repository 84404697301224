import classNames from 'classnames';
import { bool, func, node, string } from 'prop-types';
import React, { forwardRef, createRef } from 'react';
import './EditMenuOption.scss';

export const EditMenuOption = forwardRef(
	({ disabled, icon, label, onClick, readOnly, redBackground, selectedOption, setSelectedOption, title, value }, ref) => {
		return (
			<div
				ref={ref || createRef()}
				className={classNames('EditMenuOption', {
					'EditMenuOption--selected': selectedOption === value,
					'EditMenuOption--disabled': !!disabled,
					'EditMenuOption--red-background ': redBackground,
					'EditMenuOption--read-only ': readOnly,
				})}
				title={title}
				onClick={event => {
					event.stopPropagation();
					if (disabled) {
						event.preventDefault();
						return;
					}
					if (onClick) {
						onClick();
						return;
					}
					if (selectedOption === value) {
						setSelectedOption('');
					} else {
						setSelectedOption(value);
					}
				}}
				role="button"
				tabIndex={0}
			>
				<p>{icon}</p>
				<div
					className={classNames('EditMenuOption__label', {
						'EditMenuOption__label--read-only': readOnly,
					})}
				>
					{label}
				</div>
			</div>
		);
	}
);

EditMenuOption.propTypes = {
	selectedOption: string,
	setSelectedOption: func.isRequired,
	onClick: func,
	icon: node.isRequired,
	label: string.isRequired,
	disabled: bool,
	redBackground: bool,
	readOnly: bool,
	title: string,
	value: string,
};

EditMenuOption.defaultProps = {
	selectedOption: '',
	onClick: null,
	disabled: false,
	redBackground: false,
	readOnly: false,
	title: '',
	value: '',
};
