import { useHistory } from 'react-router';
import { useTheme } from 'styled-components';
import { EDIT_MENU_NAVIGATION_PORTAL } from 'components/editNavigation/constants/editNavigationConstants';
import { Logo } from 'components/logo';
import useBrandDetails from 'hooks/useBrandDetails';
import useBrandRoute from 'hooks/useBrandRoute';
import { useMenuEvents } from 'hooks/useMenuEvents';
import { BrandSideBarChapters } from './brandSideBarChapters';
import * as S from './BrandSideBar.style';

const BrandSideBar = () => {
	const history = useHistory();
	const { logo } = useTheme();
	const { baseRoute } = useBrandRoute();
	const { isLoadingItems } = useBrandDetails();
	const { chaptersWithIsOpen, onMenuClick, onIconClick } = useMenuEvents();

	const onRefresh = event => {
		event.preventDefault();
		history.replace(baseRoute);
	};

	return (
		<S.BrandSideBar>
			<S.SectionLogo>
				<Logo onClick={onRefresh} image={logo.sidebar} size={logo.size.sidebar} />
			</S.SectionLogo>

			<S.ContainerItems>
				<BrandSideBarChapters
					loading={isLoadingItems}
					chapters={chaptersWithIsOpen}
					onMenuClick={onMenuClick}
					onIconClick={onIconClick}
				/>
			</S.ContainerItems>

			<div id={EDIT_MENU_NAVIGATION_PORTAL} />
		</S.BrandSideBar>
	);
};

export { BrandSideBar };
