import { AUTH_SET_USER, AUTH_SET_USER_DETAIL } from './actionTypes';

export const authSetUser = user => ({
	type: AUTH_SET_USER,
	user,
});

export const authSetUserDetail = userDetail => ({
	type: AUTH_SET_USER_DETAIL,
	userDetail,
});
