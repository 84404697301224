export const TITLE_FIELD = 'title';
export const DESCRIPTION_FIELD = 'body';
export const SPECIFICATION_FIELD = 'specification';
export const DISCLAIMER_FIELD = 'disclaimer';

export const TYPE_MODULE_TEXT = 'type-module-text';
export const TYPE_MODULE_TEXT_THUMBNAIL_GALLERY = 'type-module-text-thumbnail-gallery';
export const TYPE_PLUS_THREE_IMAGES_MODULE = 'type-plus-three-images-module';
export const TYPE_MODULE_TWO_COLUMN_IMAGE_GALLERY = 'type-module-two-colmun-image-gallery';
export const TYPE_MODULE_FULL_WIDTH_THREE_COLUMN_IMAGE = 'full-width-type-3-column-gallery';
export const TYPE_MODULE_TEXT_TWO_COLUMN_IMAGE = 'type-module-text-2-column-image';
export const FULL_WIDTH_TYPE_PLUS_GALLERY_MODULE = 'full-width-type-plus-gallery-module';
export const TYPE_MODULE_TEXT_TWO_COLUMN_IMAGE_WITH_FULL_SPAN = 'type-module-text-two-column-image-with-full-span';
export const TYPE_MODULE_TEXT_TWO_IMAGE_WITH_FULL_SPAN = 'type-module-text-two-image-with-full-span';
export const TYPE_MODULE_TEXT_TWO_COLUMN_IMAGE_GALLERY = 'type-module-text-2-column-image-gallery';
export const TYPE_MODULE_TEXT_HORIZONTAL_IMAGE = 'type-module-text-horizontal-image';
export const TYPE_VERTICAL_IMAGE_MODULE = 'type-vertical-image-module';
export const TYPE_MODULE_TEXT_THREE_COLUMN_IMAGE_WITH_FULL_SPAN = 'type-module-text-three-column-image-with-full-span';
export const TYPE_MODULE_TEXT_FOUR_VARIABLE_IMAGES = 'type-module-text-four-variable-images';
export const TYPE_MODULE_TEXT_THREE_COLUMN_IMAGE = 'type-module-text-three-column-image';

export const EDITABLE_TITLE_TEST_ID = 'editable-title-test-id';
export const EDITABLE_DESCRIPTION_TEST_ID = 'editable-description-test-id';
export const EDITABLE_SPECIFICATION_TEST_ID = 'editable-specification-test-id';
export const EDITABLE_DISCLAIMER_TEST_ID = 'editable-disclaimer-test-id';
export const EDITABLE_DROPDOWN_SPECIFICATION_TEST_ID = 'editable-dropdown-specification-test-id';
export const GRID_IMAGE_TEST_ID = 'grid-image-testid';

export const COPY_ICON_TEST_ID = 'copy-icon-test-id';
export const TOOLTIP_TEST_ID = 'tooltip-test-id';

export const TYPE_QUICKSHEET_MODULE_TEXT = 'type-quicksheet-module-text';
export const ROLE_QUICKSHEET_BODY = 'role-quicksheet-body';
export const ROLE_QUICKSHEET_SPECIFICATION = 'role-quicksheet-specification';
