import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from './icon';
import * as S from './SectionItem.style';

const SectionItem = ({ children, icon, onClick, onArrowClick, ...props }) => {
	const handleClick = e => {
		e.preventDefault();
		e.stopPropagation();
		onClick(e);
	};

	const handleArrowClick = e => {
		e.preventDefault();
		e.stopPropagation();
		onArrowClick(e);
	};

	return (
		<S.Link onClick={e => onClick && handleClick(e)} exact {...props}>
			<S.Wrapper>
				{icon && <Icon icon={icon} onClick={handleArrowClick} />}
				<S.Label >{children}</S.Label>
			</S.Wrapper>
		</S.Link>
	);
};

SectionItem.propTypes = {
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	icon: PropTypes.shape({
		style: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		name: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
	}),
	onClick: PropTypes.func,
	onArrowClick: PropTypes.func,
};

SectionItem.defaultProps = {
	children: '',
	icon: {
		style: '',
		name: '',
	},
	onClick: () => null,
	onArrowClick: () => null,
};

export { SectionItem };
