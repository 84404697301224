import AlilaLogo from 'assets/img/logos/alila.svg';
import complementaryTheme from 'themes/complementary/complementary';
import colors from './alilaColors';
import typographyTheme from './alilaTypo';

const logo = {
	footer: AlilaLogo,
	loading: AlilaLogo,
	sidebar: AlilaLogo,
	mobile: AlilaLogo, // logo in the navbar
	size: {
		sidebar: {
			width: '187px',
			height: '105px',
		},
		footer: {
			width: '130px',
			height: '75px',
		},
		navbar: {
			width: '204px',
			height: '108px',
		},
		loading: {
			width: '168px',
			height: '91px',
		},
	},
};

const copyrightText = {
	padding: '5px 0 5px 20px',
};

const alila = {
	brandId: 'al',
	name: 'Alila',
	logo,
	color: colors,
	copyrightText,

	font: {
		family: {
			arialSansSerif: 'Arial, Helvetica, sans-serif',
			gothamBook: 'Gotham Book',
			gothamLight: 'Gotham Light',
			helveticaNeue: 'Helvetica Neue',
			notoSansLight: 'NotoSans Light',
			notoSansRegular: 'NotoSans Regular',
			notoSansSemiBold: 'NotoSans Semi Bold',
			notoSansBold: 'NotoSans Bold',
			nunitoRegular: 'Nunito Regular',
			nunitoLight: 'Nunito Light',
			nunitoBold: 'Nunito Bold',
			nunitoExtraBold: 'Nunito ExtraBold',
			poynterRegular: 'Poynter Regular',
		},
	},
};

const alilaTheme = {
	...alila,
	...typographyTheme(alila),
	...complementaryTheme,
};

export default alilaTheme;
