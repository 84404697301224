export const CHAPTER_TYPES = {
	BRAND: {
		value: 'BRAND',
		label: 'Brand',
	},
	'CONTACT US': {
		value: 'CONTACT US',
		label: 'Contact Us',
	},
	HOME: {
		value: 'HOME',
		label: 'Home',
	},
	HISTORY: {
		value: 'HISTORY',
		label: 'History',
	},
	'OUR LOCATION': {
		value: 'OUR LOCATION',
		label: 'Our Location',
	},
	'HYATT DIFFERENCE': {
		value: 'HYATT DIFFERENCE',
		label: 'Hyatt Difference',
	},
};
