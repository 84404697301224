import { bool, func, objectOf, oneOf, string, node, oneOfType } from 'prop-types';
import * as S from './CheckBox.style';

const CheckBox = ({ variant, checked, label, onChange, containerStyle, checkBoxStyle, labelStyle, disabled, title }) => {
	return (
		<S.CheckBoxGroup onClick={onChange} style={containerStyle}>
			<S.CheckBox
				variant={variant}
				type="checkbox"
				checked={checked}
				style={checkBoxStyle}
				onChange={e => e}
				disabled={disabled}
				title={title}
			/>
			{label && <S.Label style={labelStyle}>{label}</S.Label>}
		</S.CheckBoxGroup>
	);
};

CheckBox.propTypes = {
	variant: oneOf(['primary', 'secondary']),
	checked: bool,
	label: oneOfType([string, node]),
	onChange: func.isRequired,
	containerStyle: objectOf(Object),
	checkBoxStyle: objectOf(Object),
	labelStyle: objectOf(Object),
	disabled: bool,
	title: string,
};

CheckBox.defaultProps = {
	variant: 'primary',
	containerStyle: null,
	checked: null,
	checkBoxStyle: null,
	labelStyle: null,
	disabled: false,
	title: '',
	label: '',
};

export { CheckBox };
