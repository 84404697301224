import { useCallback, useEffect } from 'react';
import { putContentFragment } from 'services/contentFragmentService';
import { getName, getParentPath, updatedContentFragmentBaseObj } from 'utils/contentFragmentUtil';
import { getISONow } from 'utils/dateUtil';
import { showToast } from 'utils/toastUtil';
import { translations } from 'translation/en';
import { CHAPTERS_TYPE, PAGE_TYPES } from 'constants/pageConstants';
import { MODELS_PATHS } from 'constants/aemConstants';
import useBrandData from './useBrandData';
import useBrandDetails from './useBrandDetails';
import { useRequest } from './useRequest';
import { useDisapprovePage } from './useDisapprovePage';
import useApprovePublish from './useApprovePublish';

export const useUpdatePage = () => {
	const { currentType } = useBrandDetails();
	const { brand } = useBrandData();
	const { isPageApproved, handleStaleStatus } = useApprovePublish();

	const { loading, data: response, error, run, clear } = useRequest(putContentFragment);
	const { disapprovePage } = useDisapprovePage();

	useEffect(() => {
		if (response) {
			clear();
		}
	}, [response, clear]);

	useEffect(() => {
		if (error) {
			clear();
			showToast({ type: 'error', message: translations.ERROR_EDITING_CONTENT });
		}
	}, [error, clear]);

	const updatePage = useCallback(
		({ title, path }) => {
			const type = currentType?.type || PAGE_TYPES.CHAPTER_TYPE;

			const commonData = {
				name: getName(path),
				parentPath: getParentPath(path),
				model: MODELS_PATHS[type.toUpperCase()],
				master: {
					MODIFICATION_DATE: getISONow(),
					brands: brand.brandId,
				},
			};

			let updateData = {};

			switch (type) {
				case PAGE_TYPES.SECTION_TYPE:
					updateData = updatedContentFragmentBaseObj({
						...commonData,
						title,
						master: {
							...commonData.master,
							BEG_SECTION_TITLE: title,
						},
					});
					break;
				case PAGE_TYPES.ARTICLE_TYPE:
					updateData = updatedContentFragmentBaseObj({
						...commonData,
						title,
						master: {
							...commonData.master,
							BEG_ARTICLE_TITLE: title,
						},
					});
					break;
				default:
					updateData = updatedContentFragmentBaseObj({
						...commonData,
						title,
						master: {
							...commonData.master,
							BEG_CHAPTER_TITLE: title,
						},
					});
					break;
			}

			const updatePagePromise = run({
				cfType: CHAPTERS_TYPE,
				pagePath: `beg-${brand.brandId}`,
				data: updateData,
			});

			let approvalPromise = Promise.resolve();
			if (isPageApproved) {
				approvalPromise = disapprovePage([currentType]);
			}

			return Promise.all([updatePagePromise, approvalPromise]).then(responses => {
				const isSuccess = responses.some(res => res?.data);

				if (isSuccess) {
					handleStaleStatus(true);
				}

				return responses;
			});
		},
		[currentType, brand.brandId, isPageApproved, handleStaleStatus, disapprovePage, run]
	);

	return { loading, response, error, updatePage };
};
