import React, { useState } from 'react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { COLORS } from '../../constants/colors';
import { translation } from '../../constants/translation';

import { useBarChartAnalytics } from '../../hooks/useBarChartAnalytics';
import { hasLength, sortStringArrAscendingByKey } from '../../utils/arrayUtils';
import { BarChartTableWrapper } from '../barChartTableWrapper/BarChartTableWrapper';
import './BarChartWrapper.scss';

export const BarChartWrapper = () => {
	const { convertDataToGraphic, convertLabelsToGraphic } = useBarChartAnalytics();

	const data = convertDataToGraphic();
	const labels = convertLabelsToGraphic();
	const DATA_KEY = 'date';

	const [selectedBar, setSelectedBar] = useState(
		data.reduce((acc, { key }) => {
			acc[key] = false;
			return acc;
		}, [])
	);

	const toggleBar = legend => {
		setSelectedBar({
			...selectedBar,
			[legend.dataKey]: !selectedBar[legend.dataKey],
		});
	};

	return (
		<div className="BarChartWrapper">
			{hasLength(data) ? (
				<>
					<div className="BarChartWrapper__content">
						<ResponsiveContainer>
							<BarChart data={sortStringArrAscendingByKey(data, DATA_KEY)} margin={{ right: 40 }}>
								<CartesianGrid strokeDasharray="3 3" />
								<XAxis dataKey={DATA_KEY} />
								<YAxis />
								<Tooltip />
								<Legend
									onClick={toggleBar}
									wrapperStyle={{
										width: '100%',
										left: 0,
										right: 0,
										backgroundColor: '#f5f5f5',
										border: '1px solid #d5d5d5',
										borderRadius: 3,
										padding: '10px',
										lineHeight: '40px',
										fontSize: '12px',
									}}
								/>

								{labels.map((item, index) => (
									<Bar
										key={item}
										dataKey={item}
										stackId={DATA_KEY}
										hide={selectedBar[item] === true}
										fill={COLORS[index]}
									/>
								))}
							</BarChart>
						</ResponsiveContainer>
					</div>

					<BarChartTableWrapper />
				</>
			) : (
				<p className="BarChartWrapper__info">{translation.NO_STATISTICS}</p>
			)}
		</div>
	);
};
