import styled from 'styled-components/macro';

export const SpecificationLabel = styled.label(({ theme: { color, typo } }) => ({
	overflow: 'hidden',
	maxHeight: 'inherit',

	color: color.text.specificationTitle,
	...typo.headline['04.1'],
}));

export const SpecificationWrapper = styled.div(() => ({
	margin: '20px 0 !important',

	'.ContentQuickSheetSpecification': {
		margin: '10px',
		display: 'block',
		paddingLeft: '28px',
		paddingTop: '5px',

		'.ButtonModalAnalytics': {
			margin: '0 0 0 5px',
			transform: 'translateY(2px)',

			i: {
				fontSize: '20px',
			},
		},
	},
}));
